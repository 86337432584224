import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Support(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M13 6.14667C13 2.48667 10.16 0 6.99998 0C3.87331 0 0.99998 2.43333 0.99998 6.18667C0.59998 6.41333 0.333313 6.84 0.333313 7.33333V8.66667C0.333313 9.4 0.933313 10 1.66665 10H2.33331V5.93333C2.33331 3.35333 4.41998 1.26667 6.99998 1.26667C9.57998 1.26667 11.6666 3.35333 11.6666 5.93333V10.6667H6.33331V12H11.6666C12.4 12 13 11.4 13 10.6667V9.85333C13.3933 9.64667 13.6666 9.24 13.6666 8.76V7.22667C13.6666 6.76 13.3933 6.35333 13 6.14667Z"
          fill="currentColor"
        />
        <path
          d="M4.99998 7.33333C5.36817 7.33333 5.66665 7.03486 5.66665 6.66667C5.66665 6.29848 5.36817 6 4.99998 6C4.63179 6 4.33331 6.29848 4.33331 6.66667C4.33331 7.03486 4.63179 7.33333 4.99998 7.33333Z"
          fill="currentColor"
        />
        <path
          d="M8.99998 7.33333C9.36817 7.33333 9.66665 7.03486 9.66665 6.66667C9.66665 6.29848 9.36817 6 8.99998 6C8.63179 6 8.33331 6.29848 8.33331 6.66667C8.33331 7.03486 8.63179 7.33333 8.99998 7.33333Z"
          fill="currentColor"
        />
        <path
          d="M11 5.35333C10.68 3.45333 9.02667 2 7.03333 2C5.01333 2 2.84 3.67333 3.01333 6.3C4.66 5.62667 5.9 4.16 6.25333 2.37333C7.12667 4.12667 8.92 5.33333 11 5.35333Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
