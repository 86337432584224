import { FC, Fragment } from 'react'
import { format } from 'date-fns'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import { getSubIntervalType, PLAN_TYPE } from 'utils/plan'
import { dateTimeFormat } from 'utils/dateTime'
import useStore from 'store'
import { PlanIcon } from 'icons/utils'

const MyPlan: FC<JSX.IntrinsicElements['div']> = ({
  className,
  children,
  ...props
}) => {
  const sub = useStore(state => state.sub.subscription, shallow)

  if (!sub || !sub.subscription || !sub.subscription.plan) {
    return null
  }

  const { plan, billing_end } = sub.subscription
  const isFreePlan = plan.name === PLAN_TYPE.FREE
  const expiredDate = new Date(billing_end)
  const autoRenewal = sub.upcoming_invoice?.date

  return (
    <div className={cx('bg-white rounded-lg p-4', className)} {...props}>
      <div className="flex gap-4">
        <div className="w-[3.25rem] h-[3.25rem] rounded-lg bg-[#fafafa] flex items-center p-2">
          <PlanIcon type={plan.name} />
        </div>
        <div>
          <div className="text-[0.9375rem] font-medium">
            {isFreePlan
              ? 'Free Subscription License'
              : `${getSubIntervalType(plan.interval)} ${
                  plan.label
                } Subscription License`}
          </div>
          {!isFreePlan && (
            <Fragment>
              <div className="text-[0.8125rem] mt-3">
                Expired on {format(expiredDate, dateTimeFormat['MMM dd, yyyy'])}{' '}
                at {format(expiredDate, dateTimeFormat['HH:mm:ss a'])}
              </div>
              <div className="mt-2 text-[0.8125rem]">
                Auto renewal:{' '}
                <span
                  className={cx(
                    'font-semibold',
                    !!autoRenewal ? 'text-success' : 'text-danger'
                  )}
                >
                  {!!autoRenewal ? 'ON' : 'OFF'}
                </span>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      {children}
    </div>
  )
}

export default MyPlan
