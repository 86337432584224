import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useReducer
} from 'react'

import { PlaybackNotif, PlaybackSegment } from 'services/recordingPlayback'
import { getSlotsOfLayout } from 'utils/layout'

interface Player {
  status: 'idle' | 'loading' | 'ready'
  segments: PlaybackSegment[]
  notifs: PlaybackNotif[]
  video?: HTMLVideoElement | null
}

export interface State {
  selectedTime: Date[]
  players: (Player | null)[]
  currentTime: number
  seekTime: number
  status: 'idle' | 'playing' | 'waiting' | 'paused'
}

export interface Action {
  type: string
  payload?: any
}

const newPlayer = (): Player => ({
  segments: [],
  notifs: [],
  status: 'idle',
  video: undefined
})

const fillEmptyPlayers = (
  layout: number,
  prevPlayer: (Player | null)[] = []
) => {
  return Array.from({ length: layout * layout }).map((_, index) =>
    prevPlayer[index] ? { ...prevPlayer[index], status: 'paused' } : null
  )
}

const initState: State = {
  selectedTime: [],
  players: [],
  currentTime: 0,
  seekTime: 0,
  status: 'idle'
}

function reducer(state: State, { type, payload }: Action) {
  switch (type) {
    case 'RESET':
      return {
        ...state,
        selectedTime: state.selectedTime,
        players: fillEmptyPlayers(payload.layout)
      }
    case 'CREATE_PLAYER':
      return {
        ...state,
        status: 'idle' as State['status'],
        currentTime: 0,
        players: state.players.map((p, idx) =>
          idx === payload.index ? newPlayer() : p
        )
      }
    case 'UPDATE_PLAYER':
      return {
        ...state,
        players: state.players.map((p, idx) =>
          idx === payload.index ? (!!p ? { ...p, ...payload.player } : null) : p
        )
      }
    case 'REMOVE_PLAYER':
      return {
        ...state,
        currentTime: 0,
        status: 'idle' as State['status'],
        players: state.players.map((p, idx) =>
          idx === payload.index ? null : p
        )
      }
    case 'SET_TIME_RANGE':
      return {
        ...state,
        currentTime: 0,
        status: 'idle' as State['status'],
        selectedTime: payload
      }
    case 'SET_PLAYBACK_TIME':
      return {
        ...state,
        seekTime: payload,
        currentTime: payload
      }
    case 'SET_PLAYBACK_STATUS':
      return {
        ...state,
        status: payload
      }
    case 'UPDATE_CURRENT_TIME':
      return {
        ...state,
        currentTime: state.currentTime + payload
      }
    case 'CHANGE_LAYOUT': {
      const slotsOfSelectedLayout = getSlotsOfLayout(payload.layout)
      const currentLayout = Math.sqrt(state.players.length)
      return {
        ...initState,
        selectedTime: state.selectedTime,
        players: fillEmptyPlayers(
          payload.layout,
          currentLayout <= slotsOfSelectedLayout ? state.players : []
        )
      }
    }
    default:
      return state
  }
}

const PlaybackContext = createContext<
  (State & { dispatch: Dispatch<Action> }) | undefined
>(undefined)

export const usePlaybackCtx = () => {
  const ctx = useContext(PlaybackContext)
  if (!ctx) {
    throw new Error('Invalid context call')
  }
  return ctx
}

const PlaybackContextProvider = (props: {
  layout: number
  children: ReactNode
}) => {
  const [state, dispatch] = useReducer(reducer, initState)
  const { layout, children } = props

  useEffect(() => {
    dispatch({ type: 'CHANGE_LAYOUT', payload: { layout } })
  }, [layout])

  return (
    <PlaybackContext.Provider value={{ ...state, dispatch }}>
      {children}
    </PlaybackContext.Provider>
  )
}

export default PlaybackContextProvider
