import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Webhook(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.04777 5.86796C6.42224 6.91951 5.82295 7.93781 5.21116 8.94844C5.05404 9.20791 4.97627 9.41927 5.10179 9.74907C5.44834 10.6602 4.95947 11.5468 4.04069 11.7875C3.17421 12.0145 2.33001 11.4451 2.1581 10.5174C2.00575 9.69631 2.64295 8.89137 3.54829 8.76302C3.62411 8.75218 3.70158 8.75094 3.82904 8.74139L5.20615 6.43217C4.34002 5.57094 3.8245 4.56425 3.93858 3.31673C4.01923 2.43491 4.36602 1.67284 4.9998 1.04825C6.21371 -0.147857 8.06565 -0.34153 9.49499 0.576543C10.8677 1.45842 11.4964 3.17618 10.9605 4.64638C10.5564 4.53684 10.1495 4.42635 9.70222 4.30502C9.87048 3.48759 9.74603 2.75358 9.19472 2.12474C8.83049 1.70957 8.36309 1.49196 7.83165 1.41178C6.76619 1.25083 5.72013 1.93532 5.40972 2.98109C5.0574 4.16789 5.59066 5.13743 7.04777 5.86802V5.86796Z"
          fill="#C73A63"
        />
        <path
          d="M8.83409 4.6244C9.27479 5.40187 9.72226 6.19108 10.1657 6.97303C12.4072 6.27951 14.0972 7.52039 14.7035 8.84888C15.4359 10.4536 14.9352 12.3543 13.497 13.3444C12.0208 14.3607 10.1538 14.1871 8.84589 12.8815C9.17922 12.6025 9.5142 12.3222 9.87212 12.0227C11.164 12.8595 12.2938 12.8201 13.1327 11.8292C13.848 10.984 13.8325 9.72363 13.0964 8.89611C12.247 7.94117 11.1092 7.91205 9.73387 8.82872C9.16336 7.81654 8.58294 6.81243 8.03047 5.79294C7.84417 5.44933 7.63854 5.24994 7.21865 5.17725C6.5175 5.05568 6.06484 4.45354 6.03766 3.77895C6.01095 3.11179 6.40401 2.50871 7.01832 2.27377C7.62686 2.04094 8.34099 2.22884 8.75032 2.74625C9.08483 3.16898 9.19113 3.64476 9.01509 4.16612C8.96615 4.31151 8.90278 4.45219 8.83409 4.62446V4.6244Z"
          fill="#4B4B4B"
        />
        <path
          d="M9.88747 10.9798H7.189C6.93035 12.0436 6.37167 12.9025 5.40908 13.4487C4.66074 13.8732 3.8542 14.0171 2.99579 13.8785C1.41526 13.6236 0.122924 12.2008 0.00913625 10.5988C-0.119626 8.78386 1.12785 7.17066 2.79062 6.80832C2.90541 7.22519 3.02143 7.64608 3.13622 8.06195C1.61065 8.84028 1.08263 9.82095 1.5096 11.0472C1.88545 12.1264 2.9531 12.7179 4.11238 12.4891C5.29624 12.2555 5.89312 11.2717 5.82025 9.69273C6.94256 9.69273 8.06581 9.68111 9.18823 9.69844C9.62652 9.70534 9.96488 9.65989 10.295 9.27349C10.8386 8.63771 11.8392 8.69508 12.4246 9.29554C13.0229 9.90921 12.9943 10.8966 12.3611 11.4844C11.7503 12.0515 10.7852 12.0212 10.2137 11.4101C10.0962 11.2842 10.0037 11.1346 9.88747 10.9798Z"
          fill="#4A4A4A"
        />
      </svg>
    </SVGUniqueID>
  )
}
