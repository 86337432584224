import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import {
  Close,
  Delete,
  FloorPlan,
  LocationPin,
  More,
  OtherDevice as OtherDeviceIcon,
  Pencil,
  TemperatureSensor,
  VideoCameraFilled
} from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Drawer, Popover } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalCreateUpdateLocation from 'shared/Modals/Location/CreateUpdateLocation'
import ModalConfirmDelete from 'shared/Modals/Confirm/ConfirmDelete'
import toast from 'utils/toast'
import { cameraTypes, groupDevicesByType, sensorTypes } from 'utils/device'
import { deleteLocation, Location } from 'services/locations'
import useStore, { Store } from 'store'

import CameraDevice from './CameraDevice'
import SensorDevice from './SensorDevice'
import OtherDevice from './OtherDevice'
import { useLocationCtx } from '../LocationContext'

const mapState = (state: Store) => ({
  devices: state.device.devices,
  locations: state.location.locations,
  updateLocation: state.location.updateLocation,
  removeLocation: state.location.removeLocation
})

function LocationDetail() {
  const navigate = useNavigate()
  const [visible, setVisible] = useState('')

  const deleteAsync = useAsync({ showNotifOnError: true })
  const { devices, locations, ...store } = useStore(mapState, shallow)
  const { selectedLocation, showLocationDetail, dispatch } = useLocationCtx()

  const { id, name, lat, lng, address, address_2 } = selectedLocation || {}
  const deviceInLocation = devices.filter(d => d.location_id === id)
  const groupedDevices = groupDevicesByType(deviceInLocation)

  const cameraDevices = groupedDevices[cameraTypes[0]]
  const sensorDevices = groupedDevices[sensorTypes[0]]
  const otherDevices = groupedDevices['Other']

  const handleClose = () => {
    dispatch({ selectedLocation: undefined, showLocationDetail: false })
  }

  const renderLocationMoreOptions = (onClose: () => void) => {
    const onClick = (fn: Function) => () => {
      onClose()
      fn()
    }
    return (
      <Popover.Content className="w-48" onClose={onClose}>
        <MenuItem
          className="mb-1 text-[0.8125rem]"
          onClick={onClick(() => navigate('/devices?tab=floor-plans'))}
        >
          <FloorPlan className="w-3.5 h-3.5 shrink-0" /> View Floor Plan
        </MenuItem>
        <MenuItem
          className="text-[0.8125rem]"
          onClick={onClick(() => setVisible('edit'))}
        >
          <Pencil className="w-3.5 h-3.5 shrink-0" /> Edit Location
        </MenuItem>
        <div className="border-b border-light-stroke my-2" />
        <MenuItem
          className="text-[0.8125rem] text-danger"
          onClick={onClick(() => setVisible('delete'))}
        >
          <Delete className="w-3.5 h-3.5 shrink-0" /> Delete Location
        </MenuItem>
      </Popover.Content>
    )
  }

  const handleUpdateSuccess = (result?: Location) => {
    store.updateLocation(result!)
    dispatch({ selectedLocation: { ...selectedLocation!, ...result! } })
  }

  const handleDeleteLocation = () => {
    if (!selectedLocation?.id) return
    setVisible('')
    deleteAsync.execute(deleteLocation(selectedLocation.id))
    store.removeLocation(selectedLocation.id)
    dispatch({ selectedLocation: undefined, showLocationDetail: false })
    toast.success({ title: 'Location deleted' }, { position: 'bottom-center' })
  }

  return (
    <AnimatePresence initial={false}>
      {!!selectedLocation && showLocationDetail && (
        <Drawer
          width={448}
          onClose={handleClose}
          placement="right"
          mask={false}
        >
          <div
            className="absolute top-[11px] left-[-2rem] w-8 h-10 cursor-pointer"
            onClick={handleClose}
          >
            <div className="h-full flex items-center justify-center bg-white border border-r-0 border-light-stroke rounded-l-lg">
              <Close className="w-3.5 h-3.5" />
            </div>
          </div>
          <div className="p-4 border-b-4 border-light-stroke">
            <div className="flex items-center justify-between mb-2 gap-4">
              <div className="text-xl font-medium">{name}</div>
              <Popover
                placement="bottom-end"
                content={onClose => renderLocationMoreOptions(onClose)}
              >
                <Button>
                  <More />
                </Button>
              </Popover>
            </div>
            <div className="w-fit text-xs border border-light-stroke py-[2px] px-2 rounded-[28px]">
              {deviceInLocation.length} device
              {deviceInLocation.length > 1 ? 's' : ''}
            </div>
          </div>
          <PerfectScrollbar
            options={{ wheelPropagation: false }}
            className="px-4 mt-4 h-[calc(100%_-_7.3125rem)]"
          >
            <div className="flex items-center gap-2 mb-2">
              <LocationPin className="w-4 h-4 text-[#BDBDBD]" />
              <div className="font-medium text-sm">Address</div>
            </div>
            <div className="pl-6 text-[0.8125rem] mb-[0.3125rem]">
              {address}
            </div>
            {address_2 && (
              <div className="pl-6 text-[0.8125rem] mb-[0.3125rem]">
                {address_2}
              </div>
            )}
            <div className="pl-6 text-[0.8125rem] text-light-secondary">
              Lat: {lat} | Lng: {lng}
            </div>
            <div className="border-b border-[#EEEEEE] my-8" />

            {!deviceInLocation.length && (
              <div className="pl-6 text-light-secondary text-[0.8125rem]">
                This location has no device
              </div>
            )}

            {!!cameraDevices && (
              <Fragment>
                <div className="flex items-center gap-2 mb-2">
                  <VideoCameraFilled className="w-4 h-4 text-[#BDBDBD]" />
                  <div className="font-medium text-sm">Camera</div>
                </div>
                <div className="pl-6">
                  {cameraDevices.map((device, idx, self) => (
                    <CameraDevice
                      key={device.id}
                      device={device}
                      className={cx(
                        idx + 1 < self.length &&
                          'border-b border-[#EEEEEE] border-dashed'
                      )}
                    />
                  ))}
                </div>
                {Object.keys(groupedDevices).length > 1 && (
                  <div className="border-b border-[#EEEEEE] my-8" />
                )}
              </Fragment>
            )}

            {!!sensorDevices && (
              <Fragment>
                <div className="flex items-center gap-2 mb-2">
                  <TemperatureSensor className="w-4 h-4 text-[#BDBDBD]" />
                  <div className="font-medium text-sm">Temperature Sensor</div>
                </div>
                <div className="pl-6">
                  {sensorDevices.map((device, idx, self) => (
                    <SensorDevice
                      key={device.id}
                      device={device}
                      className={cx(
                        idx + 1 < self.length &&
                          'border-b border-[#EEEEEE] border-dashed'
                      )}
                    />
                  ))}
                </div>
                {Object.keys(groupedDevices).length > 1 && (
                  <div className="border-b border-[#EEEEEE] my-8" />
                )}
              </Fragment>
            )}

            {!!otherDevices && (
              <Fragment>
                <div className="flex items-center gap-2 mb-2">
                  <OtherDeviceIcon className="w-4 h-4 text-[#BDBDBD]" />
                  <div className="font-medium text-sm">Other Device</div>
                </div>
                <div className="pl-6">
                  {otherDevices.map((device, idx, self) => (
                    <OtherDevice
                      key={device.id}
                      device={device}
                      className={cx(
                        idx + 1 < self.length &&
                          'border-b border-[#EEEEEE] border-dashed'
                      )}
                    />
                  ))}
                </div>
              </Fragment>
            )}
          </PerfectScrollbar>
        </Drawer>
      )}
      {visible === 'edit' && (
        <ModalCreateUpdateLocation
          key="update-location"
          location={selectedLocation}
          onSuccess={handleUpdateSuccess}
          onClose={() => setVisible('')}
        />
      )}
      {visible === 'delete' && (
        <ModalConfirmDelete
          key="confirm-delete"
          modalTitle="Delete Location"
          confirmText="Are you sure you want to delete this location?"
          warnText='All devices belonging to this location will be move to "Unknown Location"'
          onConfirm={handleDeleteLocation}
          onClose={() => setVisible('')}
        />
      )}
    </AnimatePresence>
  )
}

export default LocationDetail
