import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Text(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M5.14285 1.71429H6.85714V2.57143H8.57144V0H0V2.57143H1.71429V1.71429H3.42859V10.2857H1.71429V12H6.85629V10.2857H5.14285V1.71429Z"
          fill="currentColor"
        />
        <path
          d="M6.85693 4.28567V6.8571H8.57123V5.99996H9.42836V10.2856H8.57123V11.9999H11.9998V10.2856H11.1427V5.99994H11.9998V6.85707H13.7141V4.28564H6.85693V4.28567Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
