import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import { ChevronRight, Close } from 'icons/outline'
import { useAsync } from 'hooks'
import { Dot, Drawer } from 'components'
import MenuItem from 'shared/MenuItem'
import LivestreamPlayer from 'shared/LivestreamPlayer'
import ModalConfirmDelete from 'shared/Modals/Confirm/ConfirmDelete'
import toast from 'utils/toast'
import { getDeviceStatus, isCamera, isSensor } from 'utils/device'
import { updateFloorPlan } from 'services/floorPlans'
import { Device } from 'services/devices'
import useStore from 'store'

import { useLocationCtx } from '../LocationContext'
import SensorDevice from 'shared/DeviceCard/SensorDevice'

function PinDetail() {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  const { execute } = useAsync({ showNotifOnError: true })
  const devices = useStore(state => state.device.devices, shallow)
  const { activePinId, activeFloorPlan, dispatch, ...ctx } = useLocationCtx()

  const activePin = devices.find(d => d.id === activePinId)
  const isOnline = !!activePin && getDeviceStatus(activePin) === 'online'

  const handleDelete = () => {
    if (!activeFloorPlan || !activePinId || !activePin) return

    setVisible(false)
    const pinName = activePin.name
    const newPinnedDevices = activeFloorPlan.devices.filter(
      d => d.id !== activePinId
    )
    execute(
      updateFloorPlan(activeFloorPlan.id, {
        linkedDevices: newPinnedDevices.map(d => ({
          device_id: d.id,
          lat: d.lat,
          lng: d.lng
        }))
      })
    )
    dispatch({
      activePinId: undefined,
      floorPlans: ctx.floorPlans.map(fp =>
        fp.id === activeFloorPlan.id
          ? { ...activeFloorPlan, devices: newPinnedDevices }
          : fp
      )
    })
    toast.success(
      { title: `"${pinName}" pin deleted` },
      { position: 'bottom-center' }
    )
  }

  const renderCameraPin = (device: Device) => (
    <div className="w-full h-[16.875rem]">
      {!isOnline && (
        <div className="w-full h-full flex flex-col justify-center items-center text-center bg-[#E9E9E9]">
          <div className="text-xs text-light-secondary">No Preview</div>
        </div>
      )}
      {isOnline && (
        <LivestreamPlayer
          key={device.id}
          showDeviceInfo={false}
          deviceId={device.id}
          className="bg-black"
        />
      )}
    </div>
  )

  const renderSensorPin = (device: Device) => (
    <SensorDevice
      device={device}
      showDeviceName={false}
      className="shadow-none py-3 px-4"
    />
  )

  return (
    <AnimatePresence initial={false}>
      {!!activePin && (
        <Drawer
          width={448}
          onClose={() => dispatch({ activePinId: undefined })}
          placement="right"
          mask={false}
        >
          <div
            className="absolute top-[11px] left-[-2rem] w-8 h-10 cursor-pointer"
            onClick={() => dispatch({ activePinId: undefined })}
          >
            <div className="h-full flex items-center justify-center bg-white border border-r-0 border-light-stroke rounded-l-lg">
              <Close className="w-3.5 h-3.5" />
            </div>
          </div>
          <div className="p-4 border-b-4 border-light-stroke">
            <div className="flex items-center gap-2">
              <Dot type={isOnline ? 'success' : 'danger'} className="w-4 h-4" />
              <div className="text-xl font-medium">{activePin.name}</div>
            </div>
          </div>

          {isCamera(activePin.type) && renderCameraPin(activePin)}
          {isSensor(activePin.type) && renderSensorPin(activePin)}

          <div className="mt-3 px-4">
            <MenuItem
              className="p-3 text-sm justify-between"
              onClick={() =>
                navigate(`/devices/${activePin.id}?tab=device-details`)
              }
            >
              <div>View Device Detail</div>
              <ChevronRight />
            </MenuItem>
            <div className="border-b border-light-stroke my-1" />
            <MenuItem
              className="flex-col !items-start p-3 text-sm gap-1"
              onClick={() => setVisible(true)}
            >
              <div className="text-danger">Remove Pin</div>
              <div className="text-[0.8125rem]">
                Removing a pin doesn't delete the device
              </div>
            </MenuItem>
          </div>
        </Drawer>
      )}
      {visible && (
        <ModalConfirmDelete
          key="confirm-delete"
          modalTitle="Delete Pin"
          confirmText="Are you sure you want to remove this device pin?"
          warnText="Removing a pin doesn't delete the device"
          onConfirm={handleDelete}
          onClose={() => setVisible(false)}
        />
      )}
    </AnimatePresence>
  )
}

export default PinDetail
