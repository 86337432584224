import { Fragment, Suspense, useEffect } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import BroadflowFullVersion from 'assets/images/BroadflowFullversion.png'
import { useAsync } from 'hooks'
import { Button, Spinner } from 'components'
import { instance } from 'services/client'
import { CurrentUser, logout } from 'services/auth'
import useStore, { Store } from 'store'

const mapState = ({ auth }: Store) => ({
  user: auth.currentUser,
  setUser: auth.setCurrentUser
})

function FullPageLayout() {
  const { pathname } = useLocation()
  const { user, setUser } = useStore(mapState, shallow)

  const logoutAsync = useAsync({ showNotifOnError: true })
  const instanceAsync = useAsync<{ data: AxiosResponse<CurrentUser> }>({
    status: 'pending'
  })

  useEffect(() => {
    if (!user) {
      instanceAsync
        .execute(instance.get('/api/users/auth?include=account'))
        .then(data => setUser(data.data.data))
        .catch(() => console.error('Unauthenticated'))
        .finally(() => instanceAsync.setState({ status: 'idle' }))
    } else {
      instanceAsync.setState({ status: 'idle' })
    }
  }, [])

  const renderNavLink = () => {
    if (pathname === '/login') {
      return (
        <Link to="/register" className="text-primary text-sm">
          Sign Up
        </Link>
      )
    }
    if (pathname === '/register') {
      return (
        <Link to="/login" className="text-primary text-sm">
          Log In
        </Link>
      )
    }
    if (!!user) {
      return (
        <span
          className="text-danger text-sm cursor-pointer"
          onClick={handleLogout}
        >
          Log Out
        </span>
      )
    }
  }

  const handleLogout = () => {
    logoutAsync
      .execute(logout())
      .then(() => (window.location.href = window.location.origin + '/login'))
      .catch(console.error)
  }

  const handleContactUs = () => {
    window.open('https://broadflow.co/contact', '_blank')
  }

  if (instanceAsync.isLoading) {
    return <Spinner className="w-screen h-screen overflow-hidden" />
  }

  return (
    <Fragment>
      {(logoutAsync.isLoading || logoutAsync.isSuccess) && (
        <Spinner.Fullscreen zIndex={99} className="bg-white/60" />
      )}
      <div className="w-screen min-h-screen flex flex-col overflow-x-hidden bg-light-bg">
        <div className="px-20 h-16">
          <div className="w-full h-full flex justify-between items-center">
            <Link to="/">
              <img width={120} src={BroadflowFullVersion} alt="logo" />
            </Link>
            <div className="flex items-center gap-4">
              {renderNavLink()}
              <Button onClick={handleContactUs}>Contact Us</Button>
            </div>
          </div>
        </div>
        <div className="px-20 flex-auto flex justify-between items-center">
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </div>
        <div className="px-20 py-4 w-full flex justify-between items-center border-t border-light-stroke">
          <div className="text-[.8125rem] text-light-secondary">
            © {new Date().getFullYear()} Broadflow Corp. All rights reserved.
          </div>
          <div className="inline-flex items-center gap-6 text-primary text-[.8125rem]">
            <a
              href="https://broadflow.co/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms
            </a>
            <a
              href="https://broadflow.co/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy
            </a>
            <a
              href="https://broadflow.co/about"
              target="_blank"
              rel="noreferrer"
            >
              About
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FullPageLayout
