import { ButtonHTMLAttributes, FC } from 'react'
import cx from 'classnames'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  block?: boolean
  circle?: boolean
  innerClassName?: string
  size?: 'small' | 'medium' | 'large'
  variant?: 'primary' | 'secondary' | 'secondary-lighten' | 'ternary'
}

export const Button: FC<Props> = ({
  children,
  className,
  block,
  circle,
  innerClassName,
  size = 'medium',
  variant = 'secondary',
  ...props
}) => {
  return (
    <button
      {...props}
      className={cx(
        `button-${variant} p-px outline-0`,
        `h-${size === 'small' ? 6 : size === 'large' ? 10 : 8}`,
        block ? 'block w-full' : 'inline-block',
        circle ? 'rounded-full' : 'rounded-lg',
        className
      )}
      onClick={props.disabled ? undefined : props.onClick}
    >
      <div
        className={cx(
          'button-inner',
          'w-full h-full flex justify-center items-center p-2 text-sm gap-1.5',
          circle ? 'rounded-full' : 'rounded-lg',
          innerClassName
        )}
      >
        {children}
      </div>
    </button>
  )
}
