import { Pagination } from 'components'

interface Props {
  loading: boolean
  currentPage: number
  totalPage: number
  onPageChange: (page: number) => void
}

function TableToolbar({
  loading,
  currentPage,
  totalPage,
  onPageChange
}: Props) {
  return (
    <div className="my-4 flex justify-end items-center gap-2">
      <Pagination.ButtonGroup
        loading={loading}
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={loading ? undefined : onPageChange}
      />
    </div>
  )
}

export default TableToolbar
