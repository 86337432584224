import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer
} from 'react'
import { FloorPlan } from 'services/floorPlans'

import { Location } from 'services/locations'

interface State {
  selectedLocation?: Location
  showLocationDetail: boolean
  floorPlans: FloorPlan[]
  activeFloorPlanId?: number
  activePinId?: number
}

const initState: State = {
  selectedLocation: undefined,
  showLocationDetail: false,
  floorPlans: [],
  activeFloorPlanId: undefined,
  activePinId: undefined
}

const LocationContext = createContext<
  | (State & {
      activeFloorPlan?: FloorPlan
      dispatch: Dispatch<Partial<State>>
    })
  | undefined
>(undefined)

const LocationContextProvider = (props: { children?: ReactNode }) => {
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const activeFloorPlan = state.floorPlans.find(
    fp => fp.id === state.activeFloorPlanId
  )

  return (
    <LocationContext.Provider value={{ ...state, activeFloorPlan, dispatch }}>
      {props.children}
    </LocationContext.Provider>
  )
}

const useLocationCtx = () => {
  const ctx = useContext(LocationContext)
  if (ctx === undefined) {
    throw Error('Invalid context call')
  }
  return ctx
}

export { LocationContextProvider as default, useLocationCtx }
