import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Email(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <g clipPath="url(#clip0_10629_610219)">
          <path
            d="M13.1667 1.1665H3.83333C2.9496 1.16756 2.10237 1.51909 1.47748 2.14398C0.852588 2.76888 0.501059 3.61611 0.5 4.49984L0.5 12.4998C0.501059 13.3836 0.852588 14.2308 1.47748 14.8557C2.10237 15.4806 2.9496 15.8321 3.83333 15.8332H13.1667C14.0504 15.8321 14.8976 15.4806 15.5225 14.8557C16.1474 14.2308 16.4989 13.3836 16.5 12.4998V4.49984C16.4989 3.61611 16.1474 2.76888 15.5225 2.14398C14.8976 1.51909 14.0504 1.16756 13.1667 1.1665ZM3.83333 2.49984H13.1667C13.5659 2.50062 13.9557 2.62085 14.286 2.84504C14.6163 3.06923 14.8719 3.38714 15.02 3.75784L9.91466 8.86384C9.53895 9.23805 9.03027 9.44816 8.5 9.44816C7.96972 9.44816 7.46104 9.23805 7.08533 8.86384L1.98 3.75784C2.1281 3.38714 2.38374 3.06923 2.71403 2.84504C3.04432 2.62085 3.43414 2.50062 3.83333 2.49984ZM13.1667 14.4998H3.83333C3.3029 14.4998 2.79419 14.2891 2.41912 13.9141C2.04405 13.539 1.83333 13.0303 1.83333 12.4998V5.49984L6.14266 9.80651C6.76841 10.4307 7.61617 10.7812 8.5 10.7812C9.38382 10.7812 10.2316 10.4307 10.8573 9.80651L15.1667 5.49984V12.4998C15.1667 13.0303 14.9559 13.539 14.5809 13.9141C14.2058 14.2891 13.6971 14.4998 13.1667 14.4998Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_10629_610219">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
