import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import BridgeImg from 'assets/images/Bridge.png'
import { Bridge, WifiOff, WifiOn } from 'icons/outline'
import { Button } from 'components'
import ModalSelectBridgeCount from 'shared/Modals/Billing/SelectBridgeCount'
import useStore from 'store'

function BridgeSummary() {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  const bridges = useStore(state => state.bridge.bridges)
  const onlineCount = bridges.filter(b => b.is_online).length
  const offlineCount = bridges.length - onlineCount

  return (
    <div className="mb-10">
      <h4 className="flex justify-between items-center mb-4">
        <div className="text-base font-medium">Bridge Summary</div>
        <Button
          onClick={() => navigate('/devices?tab=bridge-list')}
          variant="secondary-lighten"
        >
          Manage Bridge
        </Button>
      </h4>
      {!!bridges.length && (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(26rem,1fr))] gap-2">
          <div className="flex gap-4 items-center p-4 bg-white border border-[#eeeeee] rounded-lg">
            <div className="relative w-[2.1875rem] h-[2.1875rem] shrink-0">
              <Bridge className="text-primary w-6 h-6" />
              <div className="absolute bottom-0 right-0 bg-white shadow-md p-1 rounded-full">
                <WifiOn className="text-success" />
              </div>
            </div>
            <div className="grow">
              <div className="mb-1 text-[0.8125rem] text-light-secondary">
                Bridges are online
              </div>
              <div className="text-base font-medium">{onlineCount}</div>
            </div>
          </div>
          <div className="flex gap-4 items-center p-4 bg-white border border-[#eeeeee] rounded-lg">
            <div className="relative w-[2.1875rem] h-[2.1875rem] shrink-0">
              <Bridge className="text-primary w-6 h-6" />
              <div className="absolute bottom-0 right-0 bg-white shadow-md p-1 rounded-full">
                <WifiOff className="text-danger" />
              </div>
            </div>
            <div className="grow text-danger">
              <div className="mb-1 text-[0.8125rem]">Bridges are offline</div>
              <div className="text-base font-medium">{offlineCount}</div>
            </div>
          </div>
        </div>
      )}
      {!bridges.length && (
        <div className="flex gap-5 p-4 bg-white border border-[#eeeeee] rounded-lg">
          <div className="mt-2 shrink-0">
            <img src={BridgeImg} alt="bridge" />
          </div>
          <div className="text-[0.8125rem] font-medium">
            <div className="mb-2">You do not have a Stream Bridge</div>
            <a
              href="https://broadflow.co/support"
              target="_blank"
              rel="noreferrer"
              className="text-primary underline font-medium"
            >
              What is a Stream Bridge?
            </a>
            <Button
              className="!block mt-4 w-40"
              variant="primary"
              onClick={() => setVisible(true)}
            >
              Order Now
            </Button>
          </div>
        </div>
      )}
      <AnimatePresence initial={false}>
        {visible && (
          <ModalSelectBridgeCount onClose={() => setVisible(false)} />
        )}
      </AnimatePresence>
    </div>
  )
}

export default BridgeSummary
