import { useEffect, useReducer, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import {
  CheckedCircle,
  Delete,
  EyeOpen,
  Pencil,
  WarningCircle
} from 'icons/outline'
import { useAsync } from 'hooks'
import { Avatar, Button, Table } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import ModalViewRecipientProfile from 'shared/Modals/UserRecipient/ViewRecipientProfile'
import ModalEditRecipientProfile from 'shared/Modals/UserRecipient/EditRecipientProfile'
import ModalConfirmDelete from 'shared/Modals/Confirm/ConfirmDelete'
import toast from 'utils/toast'
import { formatPhone } from 'utils/functions'
import { Recipient, getRecipients, deleteRecipient } from 'services/recipients'

import TableToolbar from './TableToolbar'

interface State {
  sortBy: string
  sortDirection: string
  search: string
  pageSize: PageSizes
  currentPage: number
}

const initState: State = {
  sortBy: 'id',
  sortDirection: 'desc',
  search: '',
  pageSize: 10,
  currentPage: 1
}

function RecipientManagement() {
  const [viewRec, setViewRec] = useState<Recipient | null>(null)
  const [editRec, setEditRec] = useState<Recipient | null>(null)
  const [deleteRec, setDeleteRec] = useState<Recipient | null>(null)

  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const recAsync = useAsync({ status: 'pending', showNotifOnError: true })
  const deleteAsync = useAsync({ showNotifOnError: true })

  useEffect(() => {
    handleGetRecipients()
  }, [...Object.values(state)])

  const handleGetRecipients = () => {
    return recAsync.execute(getRecipients(state))
  }

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const handleDeleteRecipient = async () => {
    if (!deleteRec) return
    const id = deleteRec.id
    setDeleteRec(null)

    try {
      await deleteAsync.execute(deleteRecipient(id))
      toast.success(
        { title: 'Recipient deleted' },
        { position: 'bottom-center' }
      )
      if (recAsync.data?.data.data.length === 1 && state.currentPage > 1) {
        return setState({ currentPage: state.currentPage - 1 })
      }
      handleGetRecipients()
    } catch (err) {
      console.error(err)
    }
  }

  const renderNameCol = ({ name }: Recipient) => (
    <div className="flex items-center gap-2">
      <Avatar className="shrink-0" size={28} username={name} />
      {name}
    </div>
  )

  const renderEmailCol = ({ email, email_verified_at }: Recipient) => (
    <div className="flex items-center gap-1.5">
      {email_verified_at && (
        <div className="shrink-0" title="Verified">
          <CheckedCircle className="text-success" />
        </div>
      )}
      {!email_verified_at && (
        <div className="shrink-0" title="Pending">
          <WarningCircle className="text-warning" />
        </div>
      )}
      <div className="shrink-0">{email}</div>
    </div>
  )

  const renderActionCol = (rec: Recipient) => (
    <div className="flex items-center justify-end gap-2">
      <Button
        variant="ternary"
        className="w-[5.3125rem]"
        onClick={() => setViewRec(rec)}
      >
        <EyeOpen /> View
      </Button>
      <Button
        variant="ternary"
        className="w-[5.3125rem]"
        onClick={() => setEditRec(rec)}
      >
        <Pencil /> Edit
      </Button>
      <Button
        variant="ternary"
        className="w-[5.3125rem]"
        onClick={() => setDeleteRec(rec)}
      >
        <Delete /> Delete
      </Button>
    </div>
  )

  const columns = [
    {
      title: 'NAME',
      key: 'name',
      sorter: true,
      render: renderNameCol
    },
    {
      title: 'PHONE',
      render: ({ phone }: Recipient) => formatPhone(phone)
    },
    {
      title: 'WHATSAPP',
      render: ({ phone }: Recipient) => formatPhone(phone)
    },
    {
      title: 'EMAIL',
      key: 'email',
      sorter: true,
      render: renderEmailCol
    },
    {
      width: 300,
      render: renderActionCol
    }
  ]

  const loading = recAsync.isLoading || deleteAsync.isLoading

  return (
    <div className="p-4">
      <TableToolbar
        loading={loading}
        search={state.search}
        onSearchChange={s => setState({ search: s, currentPage: 1 })}
        currentPage={state.currentPage}
        totalPage={recAsync.data?.data.meta.last_page || 1}
        onPageChange={p => setState({ currentPage: p })}
        onRefetch={handleGetRecipients}
      />
      <Table
        rowKey="id"
        columns={columns}
        scroll={{ x: 1400 }}
        loading={loading}
        data={recAsync.data?.data.data}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={loading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={recAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
      <AnimatePresence initial={false}>
        {!!viewRec && (
          <ModalViewRecipientProfile
            recipient={viewRec}
            onEdit={setEditRec}
            onDelete={setDeleteRec}
            onClose={() => setViewRec(null)}
          />
        )}
        {!!editRec && (
          <ModalEditRecipientProfile
            recipient={editRec}
            onSuccess={handleGetRecipients}
            onClose={() => setEditRec(null)}
          />
        )}
        {!!deleteRec && (
          <ModalConfirmDelete
            modalTitle="delete recipient"
            confirmText="Are you sure you want to delete this recipient?"
            warnText="This recipient will no longer receive any notification after deleted"
            onClose={() => setDeleteRec(null)}
            onConfirm={handleDeleteRecipient}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default RecipientManagement
