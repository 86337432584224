import { Fragment } from 'react'
import shallow from 'zustand/shallow'

import { useAsync, usePageTitle } from 'hooks'
import { Button } from 'components'
import { EmailVerificationLink } from 'shared/EmailVerificationLink'
import { resendVerify } from 'services/auth'
import useStore from 'store'
import toast from 'utils/toast'
import { Navigate } from 'react-router-dom'

function ResendVerificationLink() {
  usePageTitle('Verify Email | Broadflow Streams Cloud Video Surveillance')

  const user = useStore(({ auth }) => auth.currentUser, shallow)
  const { execute, isLoading } = useAsync({ showNotifOnError: true })

  const handleResend = async () => {
    await execute(resendVerify())
    toast.success(
      { title: 'Verification link sent' },
      { position: 'bottom-center' }
    )
  }

  if (!user) {
    return <Navigate to="/login" />
  }

  if (!!user.email_verified_at) {
    return <Navigate to="/" />
  }

  return (
    <div className="m-auto w-[25rem] bg-white p-6 rounded-lg">
      <EmailVerificationLink.Resend
        description={
          <Fragment>
            A verification link has been sent to your email <br />
            {user?.email}
          </Fragment>
        }
        action={
          <Button
            block
            size="large"
            variant="primary"
            disabled={isLoading}
            onClick={handleResend}
          >
            Resend verification link
          </Button>
        }
      />
    </div>
  )
}

export default ResendVerificationLink
