import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Vault({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9.5127 9.72852C10.3622 9.72852 11.0508 9.03989 11.0508 8.19043C11.0508 7.34097 10.3622 6.65234 9.5127 6.65234C8.66323 6.65234 7.97461 7.34097 7.97461 8.19043C7.97461 9.03989 8.66323 9.72852 9.5127 9.72852Z"
          fill="url(#paint0_linear_8557_2052)"
        />
        <path
          d="M0.762695 13.3174H1.27539V14.3428C1.27539 15.1909 1.96534 15.8809 2.81348 15.8809H16.2119C17.06 15.8809 17.75 15.1909 17.75 14.3428V2.03809C17.75 1.18995 17.06 0.5 16.2119 0.5H2.81348C1.96534 0.5 1.27539 1.18995 1.27539 2.03809V3.06348H0.762695C0.479312 3.06348 0.25 3.29279 0.25 3.57617V6.65234C0.25 6.93573 0.479312 7.16504 0.762695 7.16504H1.27539V9.21582H0.762695C0.479312 9.21582 0.25 9.44513 0.25 9.72852V12.8047C0.25 13.0881 0.479312 13.3174 0.762695 13.3174ZM14.6396 7.16504C14.6396 6.88166 14.869 6.65234 15.1523 6.65234C15.4357 6.65234 15.665 6.88166 15.665 7.16504V9.21582C15.665 9.4992 15.4357 9.72852 15.1523 9.72852C14.869 9.72852 14.6396 9.4992 14.6396 9.21582V7.16504ZM6.94922 8.19043C6.94922 7.66885 7.10761 7.18432 7.37629 6.77901L5.04862 4.45134C4.84836 4.25108 4.84836 3.92662 5.04862 3.72636C5.24888 3.5261 5.57335 3.5261 5.77361 3.72636L8.10128 6.05403C8.50658 5.78534 8.99111 5.62695 9.5127 5.62695C10.0343 5.62695 10.5188 5.78534 10.9241 6.05403L13.2518 3.72636C13.452 3.5261 13.7765 3.5261 13.9768 3.72636C14.177 3.92662 14.177 4.25108 13.9768 4.45134L11.6491 6.77901C11.9178 7.18432 12.0762 7.66885 12.0762 8.19043C12.0762 8.71201 11.9178 9.19654 11.6491 9.60185L13.9768 11.9295C14.177 12.1298 14.177 12.4542 13.9768 12.6545C13.8766 12.7546 13.7454 12.8047 13.6143 12.8047C13.4831 12.8047 13.3519 12.7546 13.2518 12.6545L10.9241 10.3268C10.5188 10.5955 10.0343 10.7539 9.5127 10.7539C8.99111 10.7539 8.50658 10.5955 8.10128 10.3268L5.77361 12.6545C5.67346 12.7546 5.54228 12.8047 5.41113 12.8047C5.27999 12.8047 5.14877 12.7546 5.04866 12.6545C4.8484 12.4542 4.8484 12.1298 5.04866 11.9295L7.37633 9.60185C7.10761 9.19654 6.94922 8.71201 6.94922 8.19043Z"
          fill="url(#paint1_linear_8557_2052)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8557_2052"
            x1="7.97461"
            y1="6.65234"
            x2="7.97461"
            y2="9.72852"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8557_2052"
            x1="0.25"
            y1="0.5"
            x2="0.25"
            y2="15.8809"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
