import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import DefaultBrandLogo from 'assets/images/DefaultBrandLogo.png'

import { useAsync } from 'hooks'
import { Avatar, Button } from 'components'
import toast from 'utils/toast'
import { Account, uploadBrandLogo } from 'services/accounts'
import useStore, { Store } from 'store'

const mapState = (state: Store) => ({
  user: state.auth.currentUser,
  updateBrandLogo: state.auth.updateBrandLogo,
  updateUseCustomSMTP: state.auth.updateUseCustomSMTP
})

function General() {
  const navigate = useNavigate()
  const { user, updateBrandLogo } = useStore(mapState, shallow)

  const brandLogoAsync = useAsync<AxiosResponse<{ data: Account }>>({
    showNotifOnError: true
  })

  const handleUploadBrandLogo = (img: File) => {
    brandLogoAsync.execute(uploadBrandLogo(user!.id, img)).then(res => {
      updateBrandLogo({
        logo: res.data.data.brand_logo!,
        logoUrl: res.data.data.brand_logo_url!
      })
      toast.success(
        { title: 'Brand logo updated' },
        { position: 'bottom-center' }
      )
    })
  }

  const canUseBrandLogo = !!user?.account.access.can_use_brand_logo

  return (
    <div className="p-[1.3rem]">
      <div className="text-xl mb-5 font-medium">Gerenal Settings</div>
      <div className="text-[0.9375rem] mb-2 font-medium">Brand Logo</div>
      <div className="text-light-secondary text-[0.8125rem] mb-3">
        Promote your brand by uploading your logo to appear as a header in all
        of your notifications.
      </div>
      {canUseBrandLogo && (
        <Avatar
          size={130}
          username="brand-logo"
          src={user?.account.brand_logo_url || DefaultBrandLogo}
          onChange={canUseBrandLogo ? handleUploadBrandLogo : undefined}
        />
      )}
      {!canUseBrandLogo && (
        <Fragment>
          <div className="text-[0.8125rem] mb-3 text-success">
            Upgrade to a Professional plan to access this feature
          </div>
          <Button
            variant="primary"
            className="w-[8.125rem]"
            onClick={() => navigate('/settings/billing')}
          >
            Upgrade now
          </Button>
        </Fragment>
      )}
    </div>
  )
}

export default General
