import { SetState } from 'zustand'

import { Bridge, ConnectedDevice } from 'services/bridges'
import type { Store } from './'

export interface BridgeSlice {
  bridges: Bridge[]
  setBridges: (payload: Bridge[]) => void
  updateBridge: (payload: Partial<Bridge>) => void
  addConnectedDevice: (bridgeId: number, device: ConnectedDevice) => void
  removeConnectedCamera: (bridgeId: number, deviceId: number) => void
}

const slice = (set: SetState<Store>) => ({
  bridges: [],
  setBridges: (payload: Bridge[]) => {
    return set(({ bridge }) => ({ bridge: { ...bridge, bridges: payload } }))
  },
  updateBridge: (payload: Partial<Bridge>) => {
    return set(({ bridge }) => ({
      bridge: {
        ...bridge,
        bridges: bridge.bridges.map(b =>
          b.id === payload.id ? { ...b, ...payload } : b
        )
      }
    }))
  },
  addConnectedDevice: (bridgeId: number, device: ConnectedDevice) => {
    return set(({ bridge }) => ({
      bridge: {
        ...bridge,
        bridges: bridge.bridges.map(b =>
          b.id === bridgeId
            ? {
                ...b,
                devices: [...b.devices, device],
                devices_count: b.devices_count + 1
              }
            : b
        )
      }
    }))
  },
  removeConnectedCamera: (bridgeId: number, deviceId: number) => {
    return set(({ bridge }) => ({
      bridge: {
        ...bridge,
        bridges: bridge.bridges.map(b =>
          b.id === bridgeId
            ? {
                ...b,
                devices: b.devices.filter(d => d.id !== deviceId),
                devices_count: b.devices_count - 1
              }
            : b
        )
      }
    }))
  }
})

export default slice
