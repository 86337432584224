import { ReactNode } from 'react'

import { Filter, Search } from 'icons/outline'
import { Badge, Button, Input, Pagination, Popover } from 'components'

import FilterForm from './FilterForm'

interface Props {
  loading: boolean
  search: string
  onSearchChange: (s: string) => void
  currentPage: number
  totalPage: number
  onPageChange: (p: number) => void
  filters: {
    device?: { value: number; label: ReactNode }
    dateRange?: [Date | null, Date | null]
    tags?: { value: number; label: string }[]
  }
  onFilter: (filters: Props['filters']) => void
}

function TableToolbar({
  loading,
  search,
  onSearchChange,
  currentPage,
  totalPage,
  onPageChange,
  filters,
  onFilter
}: Props) {
  const handleSearch: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      value !== search && onSearchChange(value)
    }
  }

  return (
    <div className="flex items-center justify-between mb-4">
      <Input
        suffix={<Search />}
        className="w-[22.5rem]"
        placeholder="Seach notification by content"
        onKeyDown={loading ? undefined : handleSearch}
      />
      <div className="inline-flex gap-2">
        <Pagination.ButtonGroup
          loading={loading}
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={loading ? undefined : onPageChange}
        />
        <Popover
          placement="bottom-end"
          content={onClose => (
            <FilterForm
              filters={filters}
              onChange={onFilter}
              onClose={onClose}
            />
          )}
        >
          <Badge
            type="primary"
            count={Object.values(filters).filter(f => !!f).length}
            offset={{ x: '-20%', y: '20%' }}
          >
            <Button className="w-[6.5rem]">
              <Filter /> Filter
            </Button>
          </Badge>
        </Popover>
      </div>
    </div>
  )
}

export default TableToolbar
