import { ReactNode, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { ResourceIcon } from 'icons/utils'
import { ResourceIconType } from 'icons/utils/ResourceIcon'
import { AsyncSelect } from 'components'
import ModalCreateRecipient from 'shared/Modals/UserRecipient/CreateRecipient'
import { getRecipients, Recipient } from 'services/recipients'

export type OptionValue = {
  value: number
  label: string
}

interface Props {
  label: string
  type: ResourceIconType
  placeholder: string
  value: OptionValue[]
  onChange: (value: OptionValue[]) => void
  extra?: ReactNode
}

function SelectRecipient({
  label,
  type,
  placeholder,
  value,
  onChange,
  extra
}: Props) {
  const [visible, setVisible] = useState(false)

  const mapRecipients = (recs: Recipient[]) => {
    return recs.map(rec => ({ label: rec.name, value: rec.id }))
  }

  const handleGetRecipients = async (search: string) => {
    const { data } = await getRecipients({ search })
    return type === 'sms' || type === 'whatsapp'
      ? mapRecipients(data.data.filter(r => !!r.phone))
      : mapRecipients(data.data)
  }

  return (
    <div className="w-full">
      <div className="flex items-center gap-2 mb-4">
        <ResourceIcon
          type={type}
          iconType="primary"
          className="w-5 h-5 shrink-0"
        />
        <div className="text-base font-medium">{label}</div>
        <div
          className="ml-auto text-primary text-[0.8125rem] font-medium cursor-pointer"
          onClick={() => setVisible(true)}
        >
          + Create Recipient
        </div>
      </div>
      <AsyncSelect
        isMulti
        isClearable={false}
        size="large"
        defaultOptions
        loadOptions={handleGetRecipients}
        value={value}
        onChange={(v: any) => onChange(v as OptionValue[])}
        placeholder={placeholder}
      />
      {extra && (
        <div className="mt-2 text-[0.8125rem] text-right text-light-secondary">
          {extra}
        </div>
      )}
      <AnimatePresence initial={false}>
        {visible && <ModalCreateRecipient onClose={() => setVisible(false)} />}
      </AnimatePresence>
    </div>
  )
}

export default SelectRecipient
