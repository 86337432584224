import { Recipient } from 'services/recipients'
import { Schedule } from 'services/schedules'

export interface OptionValue {
  label: string
  value: number
}

export interface FormValues {
  id: number
  scheduleDetails: {
    name: string
    is_24_7: boolean
    start_time: string
    end_time: string
    days: string[]
  }
  recipients: {
    channels: string[]
    webhook: string
    slack_webhook: string
    teams_webbhook: string
    pagerduty_integration_key: string
    email_recipients: OptionValue[]
    phone_recipients: OptionValue[]
    whatsapp_recipients: OptionValue[]
  }
}

export type FormKey = keyof FormValues & string

export const initFormValues: FormValues = {
  id: 0,
  scheduleDetails: {
    name: '',
    is_24_7: false,
    start_time: '',
    end_time: '',
    days: []
  },
  recipients: {
    channels: [],
    webhook: '',
    slack_webhook: '',
    teams_webbhook: '',
    pagerduty_integration_key: '',
    email_recipients: [],
    phone_recipients: [],
    whatsapp_recipients: []
  }
}

export const mapRecipients = (recs: Recipient[]) => {
  return recs.map(({ id, name }) => ({ value: id, label: name }))
}

export const mapInitFormValues = (data: Schedule): FormValues => ({
  id: data.id,
  scheduleDetails: {
    name: data.name,
    is_24_7: !!data.is_24_7,
    start_time: data.start_time || '',
    end_time: data.end_time || '',
    days: data.days.map(d => d.toLowerCase())
  },
  recipients: {
    channels: data.channels,
    phone_recipients: mapRecipients(data.phone_recipients || []),
    email_recipients: mapRecipients(data.email_recipients || []),
    whatsapp_recipients: mapRecipients(data.whatsapp_recipients || []),
    webhook: data.webhook || '',
    slack_webhook: data.slack_webhook || '',
    teams_webbhook: data.teams_webbhook || '',
    pagerduty_integration_key: data.pagerduty_integration_key || ''
  }
})
