import { SetState } from 'zustand'

import { getSystemNotifs, SystemNotif } from 'services/notifications'
import type { Store } from './'

export interface NotifSlice {
  loading: boolean
  notifs: SystemNotif[]
  newNotifs: SystemNotif[]
  currentPage: number
  totalPage: number
  unreadCount: number
  getNotifs: (page?: number) => Promise<void>
  addNewNotif: (notif: SystemNotif) => void
  setNotifs: (payload: SystemNotif[]) => void
  markAllAsRead: () => void
}

const markAllAsRead = (newNotifs: SystemNotif[], notifs: SystemNotif[]) => {
  return [...newNotifs, ...notifs].map(n => ({
    ...n,
    read_at: new Date().toString()
  }))
}

const slice = (set: SetState<Store>) => ({
  loading: false,
  notifs: [],
  newNotifs: [],
  currentPage: 1,
  totalPage: 1,
  unreadCount: 0,
  getNotifs: async (page = 1) => {
    try {
      set(({ notif }) => ({ notif: { ...notif, loading: true } }))
      const response = await getSystemNotifs(page)
      set(({ notif }) => ({
        notif: {
          ...notif,
          loading: false,
          notifs: [...notif.notifs, ...response.data.data],
          totalPage: response.data.meta.last_page,
          unreadCount: response.data.meta.unread_count,
          currentPage: response.data.meta.current_page
        }
      }))
    } catch (e: any) {
      set(({ notif }) => ({ notif: { ...notif, loading: false } }))
    }
  },
  addNewNotif: (newNotif: SystemNotif) => {
    return set(({ notif }) => ({
      notif: {
        ...notif,
        newNotifs: [newNotif, ...notif.newNotifs],
        unreadCount: notif.unreadCount + 1
      }
    }))
  },
  setNotifs: (payload: SystemNotif[]) => {
    return set(({ notif }) => ({ notif: { ...notif, notifs: payload } }))
  },
  markAllAsRead: () => {
    return set(({ notif }) => ({
      notif: {
        ...notif,
        notifs: markAllAsRead(notif.newNotifs, notif.notifs),
        newNotifs: [],
        unreadCount: 0
      }
    }))
  }
})

export default slice
