import { Fragment, ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import shallow from 'zustand/shallow'

import { Button, Slider } from 'components'
import Popular from 'shared/Cards/Popular'
import ModalConfirmDelete from 'shared/Modals/Confirm/ConfirmDelete'
import { Plan as IPlan } from 'services/subscriptions'
import useStore, { Store } from 'store'

interface PlanListProps {
  free: IPlan
  basic: IPlan
  plus: IPlan
  pro: IPlan
  addOn: IPlan
}

interface PlanProps {
  children?: ReactNode
  className?: string
  label: ReactNode
  description: ReactNode
  totalPrice?: number
  cameraCount?: number
  onCameraCountChange?: (count: number) => void
}

const mapState = ({ stat, sub }: Store) => ({
  stats: stat.stats,
  sub: sub.subscription
})

function PlanList({ free, basic, plus, pro, addOn }: PlanListProps) {
  const navigate = useNavigate()
  const { stats, sub } = useStore(mapState, shallow)
  const [visible, setVisible] = useState(false)

  const [cameraCount, setCameraCount] = useState(
    stats?.camera_licenses.total || 0
  )

  const calcTotalPrice = (basePrice: number) => {
    return basePrice / 100 + cameraCount * (addOn.price_in_dollars || 1)
  }

  const isCurrentPlan = (planId: number) => {
    const currentPlanId = sub?.subscription?.plan_id || 1
    const totalCamCount = stats?.camera_licenses.total || 0
    return planId === currentPlanId && totalCamCount === cameraCount
  }

  const handleChoosePlan = (planId: number) => {
    if (!isCurrentPlan(planId)) {
      navigate('/checkout', {
        state: { type: 'plan', planId, count: cameraCount }
      })
    }
  }

  return (
    <Fragment>
      <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 border-b border-light-stroke pb-6 mb-3">
        <div className="font-medium text-base self-center">
          Compare features
        </div>
        {/* FREE PLAN */}
        <Plan
          label="Free"
          description="Notifications with schedules and recipients for 1 device"
          totalPrice={free.price / 100}
        >
          <Button
            variant="primary"
            className="mt-6 w-[7.5rem]"
            disabled={isCurrentPlan(free.id)}
            onClick={() => setVisible(true)}
          >
            {isCurrentPlan(free.id) ? 'Current Plan' : 'Choose Plan'}
          </Button>
        </Plan>
        {/* BASIC PLAN */}
        <Plan
          label="Basic"
          description="Best for individual users"
          totalPrice={calcTotalPrice(basic.price)}
          cameraCount={cameraCount}
          onCameraCountChange={setCameraCount}
        >
          <Button
            variant="primary"
            className="mt-6 w-[7.5rem]"
            disabled={isCurrentPlan(basic.id)}
            onClick={() => handleChoosePlan(basic.id)}
          >
            {isCurrentPlan(basic.id) ? 'Current Plan' : 'Choose Plan'}
          </Button>
        </Plan>
        {/* PLUS PLAN */}
        <Plan
          label={
            <div className="flex items-center justify-center gap-2">
              Plus <Popular />
            </div>
          }
          description="Multiple users, enhanced support"
          totalPrice={calcTotalPrice(plus.price)}
          cameraCount={cameraCount}
          onCameraCountChange={setCameraCount}
        >
          <Button
            variant="primary"
            className="mt-6 w-[7.5rem]"
            disabled={isCurrentPlan(plus.id)}
            onClick={() => handleChoosePlan(plus.id)}
          >
            {isCurrentPlan(plus.id) ? 'Current Plan' : 'Choose Plan'}
          </Button>
        </Plan>
        {/* PRO PLAN */}
        <Plan
          label="Professional"
          description="Best plan for the pros"
          totalPrice={calcTotalPrice(pro.price)}
          cameraCount={cameraCount}
          onCameraCountChange={setCameraCount}
        >
          <Button
            variant="primary"
            className="mt-6 w-[7.5rem]"
            disabled={isCurrentPlan(pro.id)}
            onClick={() => handleChoosePlan(pro.id)}
          >
            {isCurrentPlan(pro.id) ? 'Current Plan' : 'Choose Plan'}
          </Button>
        </Plan>
        {/* ENTERPRISE PLAN */}
        <Plan
          label="Enterprise"
          description="Amplify your notifications and video with one-on-one support and custom integration"
        >
          <Button
            variant="primary"
            className="mt-6 w-[7.5rem]"
            onClick={() =>
              window.open('https://broadflow.co/contact', '_blank')
            }
          >
            Contact Us
          </Button>
        </Plan>
      </div>
      <AnimatePresence initial={false}>
        {visible && (
          <ModalConfirmDelete
            modalTitle="Warning"
            confirmText="Are you sure you want downgrade to free plan?"
            warnText="All of your devices and videos will be deleted"
            confirmButtonText="Ok"
            onConfirm={() =>
              navigate('/checkout', {
                state: { type: 'plan', planId: free.id, count: 0 }
              })
            }
            onClose={() => setVisible(false)}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

function Plan({
  className,
  label,
  description,
  totalPrice,
  cameraCount,
  onCameraCountChange,
  children
}: PlanProps) {
  return (
    <div className={cx('text-center', className)}>
      <div className="mb-2 text-base font-medium">{label}</div>
      <div className="flex items-center justify-center h-[3.2rem]">
        {totalPrice !== undefined && (
          <div className="inline-flex items-end">
            <div className="text-[2rem] font-medium leading-none">
              ${totalPrice}
            </div>
            <div className="text-light-secondary text-sm">/month</div>
          </div>
        )}
      </div>
      <div className="h-[3.75rem] text-xs text-light-secondary">
        {description}
      </div>
      <div className="h-[5.125rem]">
        {cameraCount !== undefined && (
          <Fragment>
            <div className="text-[.8rem]">
              You have selected <b>{cameraCount} IP camera licenses</b>
            </div>
            <div className="text-[.8rem] mt-px mb-2.5">
              <b>$6</b>
              <span className="text-light-secondary"> / mo each</span>
            </div>
            <Slider
              max={16}
              value={cameraCount}
              onChange={v => onCameraCountChange?.(v)}
            />
          </Fragment>
        )}
      </div>
      {children}
    </div>
  )
}

export default PlanList
