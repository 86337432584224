import { usePageTitle } from 'hooks'
import RestrictedRoute from 'shared/RestrictedRoute'

import TableList from './TableList'

function ActivityLog() {
  usePageTitle('Activity Log | Broadflow Streams')

  return (
    <RestrictedRoute
      when="can_use_activity_log"
      title="Upgrade your plan to access your Activity Log"
      description="The Activity Log is used to monitor all system and user actions."
    >
      <div className="p-4">
        <div className="mb-4 text-2xl font-medium mt-2">Activity Log</div>
        <TableList />
      </div>
    </RestrictedRoute>
  )
}

export default ActivityLog
