import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { omit } from 'lodash'
import shallow from 'zustand/shallow'

import { useAsync, useLocalStorage, usePageTitle } from 'hooks'
import { EmailVerificationLink } from 'shared/EmailVerificationLink'
import { register } from 'services/auth'
import useStore, { Store } from 'store'

import RegisterForm, { FormValues } from './RegisterForm'

type RouteParams = {
  planId?: string
  count?: string
}

const mapState = ({ auth }: Store) => ({
  user: auth.currentUser,
  setUser: auth.setCurrentUser
})

function Register() {
  usePageTitle('Register | Broadflow Streams Cloud Video Surveillance')

  const { search } = useLocation()
  const store = useStore(mapState, shallow)
  const { isLoading, isSuccess, execute } = useAsync({ showNotifOnError: true })
  const [plan, setPlan] = useLocalStorage<RouteParams>('APP/SELECTED_PLAN', {})

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const params = Object.fromEntries(searchParams.entries()) as RouteParams
    setPlan(params)
  }, [])

  const handleSubmit = (values: FormValues) => {
    execute(
      register({
        ...omit(values, 'rePassword'),
        password_confirmation: values.rePassword,
        planId: Number(plan.planId) || undefined
      })
    ).catch(console.error)
  }

  if (!!store.user) {
    return <Navigate to="/" />
  }

  return (
    <AnimatePresence initial={false}>
      {!isSuccess && (
        <RegisterForm loading={isLoading} onSubmit={handleSubmit} />
      )}
      {!!isSuccess && (
        <motion.div
          initial={{ x: '100vw' }}
          animate={{ x: 0 }}
          transition={{ type: 'tween', duration: 0.2 }}
          className="m-auto w-[25rem] bg-white p-6 rounded-lg"
        >
          <EmailVerificationLink.Sent />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Register
