import Screenshot from 'assets/screenshots/RecipientManagement.png'
import { ChevronRight } from 'icons/outline'

function RecipientManagement() {
  return (
    <div className="p-4 grow">
      <div className="text-xl font-medium mb-4">Recipient Management</div>
      <img src={Screenshot} alt="screenshot" className="w-full h-[25.625rem]" />
      <div className="mt-12">
        <div className="text-[0.8125rem] mb-3.5">
          When creating device notification schedules, one of the fields is used
          to define which people should receive alerts. These are known as
          recipients. When creating new email recipients, a verification email
          will be sent to them to confirm their willful association with the
          account. Using the Recipient Management dashboard view to see if any
          are unverified.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          By default, the original user who created the Broadflow Streams
          account will be added as a recipient as well.
        </div>
      </div>
      <div className="mt-12">
        <div className="font-medium text-base mb-4">Get Support</div>
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/notifications/recipient-management"
          target="_blank"
          rel="noreferrer"
        >
          How to Add a Recipient
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/notifications/schedules-recipients"
          target="_blank"
          rel="noreferrer"
        >
          How to Assign a Recipient to a Schedule
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/notifications/slack"
          target="_blank"
          rel="noreferrer"
        >
          How to Receive Notifications in Slack
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/notifications/microsoft-teams"
          target="_blank"
          rel="noreferrer"
        >
          How to Receive Notifications in Microsoft Teams
          <ChevronRight />
        </a>
      </div>
    </div>
  )
}

export default RecipientManagement
