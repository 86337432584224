import { useReducer } from 'react'
import cx from 'classnames'

import { useLocalStorage, usePageTitle } from 'hooks'
import { idGenerator } from 'utils/functions'

import DeviceMenuList from './DeviceMenuList'
import LivestreamTabs from './LivestreamTabs'
import LivestreamTabItem from './LivestreamTabs/LivestreamTabItem'
import {
  getSlotsOfLayout,
  renderChildrenClassLayout,
  renderParentClassLayout
} from 'utils/layout'

interface State {
  expanded: boolean
  activeKey: string
  devices: { [key: string]: (number | null)[] }
}

interface Tab {
  key: string
  name: string
  layout: number
}

const genLivestreamId = idGenerator('livestream')

const newTab = (name = 'Page 1', layout = 2): Tab => ({
  key: genLivestreamId.next().value as string,
  name,
  layout
})

const fillEmptyDevices = (
  layout: number,
  prevDevices: (number | null)[] = []
) => {
  return Array.from({ length: layout * layout }).map(
    (_, index) => prevDevices[index] || null
  )
}

const initState = (tabs: Tab[]): State => ({
  expanded: true,
  activeKey: tabs[0].key,
  devices: tabs.reduce((acc, tab) => {
    acc[tab.key] = fillEmptyDevices(tab.layout)
    return acc
  }, {} as State['devices'])
})

function Livestream() {
  usePageTitle('Live Streaming | Broadflow Streams')

  const [tabs, setTabs] = useLocalStorage<Tab[]>('APP/LIVESTREAM', [newTab()])
  const [{ expanded, activeKey, devices }, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState(tabs)
  )

  const handleCreateTab = () => {
    const tab = newTab(`Page ${tabs.length + 1}`)
    setTabs([...tabs, tab])
    setState({
      activeKey: tab.key,
      devices: { ...devices, [tab.key]: fillEmptyDevices(tab.layout) }
    })
  }

  const handleEditTab = (key: string, tab: Partial<Tab>) => {
    setTabs(prev => prev.map(t => (t.key === key ? { ...t, ...tab } : t)))
  }

  const handleTabLayoutChange = (key: string, layout: number) => {
    handleEditTab(key, { layout })
    const activeTab = tabs.find(tab => tab.key === activeKey)!
    const slotsOfActive = getSlotsOfLayout(activeTab.layout)
    const slotsOfCurrent = getSlotsOfLayout(layout)
    setState({
      devices: {
        ...devices,
        [key]: fillEmptyDevices(
          layout,
          slotsOfActive <= slotsOfCurrent ? devices[key] : []
        )
      }
    })
  }

  const handleDeleteTab = (key: string) => {
    const newTabs = tabs.filter(tab => tab.key !== key)
    setTabs(newTabs)
    setState({ activeKey: newTabs[newTabs.length - 1].key })
  }

  const handleDeviceIdChange = (key: string, index: number) => {
    return (deviceId: number | null) => {
      setState({
        devices: {
          ...devices,
          [key]: devices[key].map((id, idx) => (idx === index ? deviceId : id))
        }
      })
    }
  }

  const renderTab = (key: string, layout: number) => {
    const slots = getSlotsOfLayout(layout)
    return (
      <div
        className={cx(
          'bg-white gap-1 h-[calc(100vh_-_3rem)] grid',
          renderParentClassLayout(layout)
        )}
      >
        {Array.from({ length: slots }).map((_, index) => (
          <div
            key={index}
            className={cx(
              'grid gap-[1px]',
              renderChildrenClassLayout(layout, index + 1)
            )}
          >
            <LivestreamTabItem
              key={index}
              index={index}
              selectedDeviceId={devices[key][index]}
              onDeviceIdChange={handleDeviceIdChange(key, index)}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="relative flex h-[calc(100vh_-_3.125rem)] overflow-hidden">
      <DeviceMenuList
        expanded={expanded}
        selectedDeviceIds={devices[activeKey]}
      />
      <LivestreamTabs
        expanded={expanded}
        onToggleExpand={() => setState({ expanded: !expanded })}
        tabs={tabs}
        activeTabKey={activeKey}
        onTabChange={(key: string) => setState({ activeKey: key })}
        onCreateTab={handleCreateTab}
        onEditTab={handleEditTab}
        onTabLayoutChange={handleTabLayoutChange}
        onDeleteTab={handleDeleteTab}
      >
        {renderTab}
      </LivestreamTabs>
    </div>
  )
}

export default Livestream
