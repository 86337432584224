import { Fragment, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import { CreditCardIcon } from 'icons/utils'
import { CreditCard as ICreditCard } from 'icons/filled'
import { Button, Form } from 'components'
import CreditCard from 'shared/Cards/CreditCard'
import LoadGoogleMaps from 'shared/LoadGoogleMaps'
import ModalAddEditCreditCard from 'shared/Modals/Billing/AddEditCreditCard'
import { Coupon } from 'services/subscriptions'
import useStore from 'store'

import CouponForm from './CouponForm'
import LocationForm from './LocationForm'

interface Props {
  disabled?: boolean
  includeLocation?: boolean
  onCouponChange?: (coupon?: Coupon) => void
  onSubmit: (values: FormValues) => void
}

export interface FormValues {
  name: string
  phone: string
  country: { label: string; value: string }
  address: { id: string; label: string; value: string }
  address2?: string
  city: string
  state: string
  zipcode: string
  coupon: string
}

function CheckoutForm({
  disabled,
  onCouponChange,
  includeLocation,
  onSubmit
}: Props) {
  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()
  const sub = useStore(state => state.sub.subscription, shallow)
  if (!sub?.card) return null

  return (
    <Fragment>
      <div className="mb-4">
        <div className="inline-flex items-center gap-2">
          <ICreditCard type="primary" className="w-4 h-4 shrink-0" />
          <div className="text-base font-medium">Enter payment detail</div>
        </div>
      </div>
      <div className="text-sm font-medium mb-2">
        Choose bank account to check out
      </div>
      <div className="flex items-center gap-2">
        <CreditCard onClick={disabled ? undefined : () => setVisible(true)}>
          <div className="text-light-secondary">---</div>
          <div className="text-center text-primary font-medium">New Card</div>
        </CreditCard>
        <CreditCard className="!border-primary">
          <CreditCardIcon className="w-6 h-6 shrink-0" type={sub.card.brand} />
          <div className="">********{sub.card.last_4}</div>
        </CreditCard>
      </div>
      <Form
        form={form}
        onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        onFinish={onSubmit}
      >
        {includeLocation && (
          <LoadGoogleMaps
            spinner={{ size: 'small', className: 'mt-10 w-[15rem]' }}
            errorCls="mt-10"
          >
            <LocationForm form={form} />
          </LoadGoogleMaps>
        )}
        {onCouponChange && (
          <CouponForm form={form} onCouponChange={onCouponChange} />
        )}
        <Button
          type="submit"
          disabled={disabled}
          variant="primary"
          size="large"
          className="mt-10 w-[15rem]"
        >
          Confirm
        </Button>
      </Form>
      <div className="mt-4 text-[0.8125rem] text-light-secondary">
        Plan will be automatically renewed at the end of the billing cycle
      </div>
      <AnimatePresence initial={false}>
        {visible && (
          <ModalAddEditCreditCard
            type="edit"
            onClose={() => setVisible(false)}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default CheckoutForm
