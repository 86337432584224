import { ReactNode, useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'

import { Input } from 'components'
import toast from 'utils/toast'

interface Props {
  children: (props: { disabled: boolean; onClick: () => void }) => ReactNode
  onSubmit: (paymentToken: string) => void
}

const cardOptions = {
  style: {
    base: {
      iconColor: '#303030',
      color: '#303030',
      fontSize: '16px'
    },
    invalid: {
      iconColor: '#cc2020',
      color: '#cc2020'
    }
  }
}

function CreditCard({ onSubmit, children }: Props) {
  const elements = useElements()
  const stripe = useStripe()

  const [loading, setLoading] = useState(false)
  const [isValidCard, setIsValidCard] = useState(false)

  const handleValidateCard = (event: StripeCardElementChangeEvent) => {
    setIsValidCard(event.complete && !event.error)
  }

  const handleSubmit = async () => {
    if (!stripe || !elements || !isValidCard) return
    try {
      setLoading(true)
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)!
      })
      if (!paymentMethod || !paymentMethod.id) {
        // eslint-disable-next-line
        throw 'error'
      }
      onSubmit(paymentMethod.id)
    } catch (err) {
      toast.error({ title: 'Error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="mt-8">
      <div className="mb-4">
        <label className="block font-medium text-[0.8125rem] mb-1.5">
          Card Information
        </label>
        <CardElement
          className="base-transition border border-light-stroke px-[0.6875rem] py-1.5 rounded-lg hover:border-primary"
          onChange={handleValidateCard}
          options={cardOptions}
        />
      </div>
      <div className="mb-4">
        <label className="block font-medium text-[0.8125rem] mb-1.5">
          Name on card
        </label>
        <Input placeholder="Enter name on the card" />
      </div>
      {children({ disabled: loading || !isValidCard, onClick: handleSubmit })}
    </div>
  )
}

export default CreditCard
