import {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useRef,
  useState
} from 'react'
import cx from 'classnames'

interface Props {
  className?: string
  min?: number
  max?: number
  step?: number
  value?: number
  onChange?: (value: number, event: ChangeEvent<HTMLInputElement>) => void
}

export function Slider({
  className,
  min = 0,
  max = 10,
  step = 1,
  value: propValue,
  onChange
}: Props) {
  const sliderRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState(propValue || 0)

  useEffect(() => {
    setValue(propValue || 0)
  }, [propValue])

  useEffect(() => {
    updateProgress()
  }, [value])

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    const v = +event.target.value
    setValue(v)
    onChange?.(v, event)
  }

  const updateProgress = () => {
    const v = (value / max) * 100
    sliderRef.current!.style.background = `linear-gradient(to right, #5156D3 0%, #5156D3 ${v}%, #dedede ${v}%, #dedede 100%)`
  }

  return (
    <div className={cx('relative w-full h-3 slider-wrapper', className)}>
      <input
        ref={sliderRef}
        className="slider absolute top-0 left-0 appearance-none w-full h-1 cursor-pointer bg-[#dedede] rounded-sm z-[1]"
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}
