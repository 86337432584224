import RestrictedRoute from 'shared/RestrictedRoute'
import TableList from './TableList'

function UserManagement() {
  return (
    <RestrictedRoute
      when="can_use_user_mgmt"
      title="Upgrade your plan to access User Management"
      description="Create separate users to keep track of changes and protect sensitive settings."
      className="min-h-[80vh]"
    >
      <div className="p-4">
        <TableList />
      </div>
    </RestrictedRoute>
  )
}

export default UserManagement
