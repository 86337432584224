import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Camera({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9.77014 7.88479C9.77014 9.19872 8.70127 10.2676 7.38734 10.2676C6.07341 10.2676 5.00494 9.19872 5.00494 7.88479C5.00494 6.57086 6.07341 5.50199 7.38734 5.50199C8.70127 5.50199 9.77014 6.57127 9.77014 7.88479ZM14.7747 4.50133V11.2691C14.7747 12.1713 14.0432 12.9028 13.1409 12.9028H1.63373C0.731504 12.9028 0 12.1713 0 11.2691V4.50133C0 3.5991 0.731504 2.86759 1.63373 2.86759H3.64323V2.30232C3.64323 1.51282 4.28283 0.872803 5.07274 0.872803H9.70193C10.4918 0.872803 11.1314 1.51282 11.1314 2.30232V2.86718H13.1409C14.0432 2.86759 14.7747 3.5991 14.7747 4.50133ZM10.9954 7.88479C10.9954 5.89531 9.37682 4.27669 7.38734 4.27669C5.39827 4.27669 3.77964 5.89531 3.77964 7.88479C3.77964 9.87427 5.39827 11.4929 7.38734 11.4929C9.37682 11.4929 10.9954 9.87427 10.9954 7.88479Z"
          fill="url(#paint0_linear_8447_63073)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8447_63073"
            x1="0"
            y1="0.872803"
            x2="0"
            y2="12.9028"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
