import { FC, Fragment, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'

import Upgrade from 'assets/images/Upgrade.png'

import { Button } from 'components'
import { CurrentUser } from 'services/auth'
import useStore from 'store'

type When = keyof CurrentUser['account']['access']

type Props = {
  when: When
  title?: ReactNode
  description?: ReactNode
} & Omit<JSX.IntrinsicElements['div'], 'title'>

const RestrictedRoute: FC<Props> = ({
  children,
  when,
  title = 'Restricted',
  description = 'Upgrade your plan in order to use this route',
  ...props
}) => {
  const navigate = useNavigate()
  const user = useStore(state => state.auth.currentUser)

  if (!user?.account.access[when]) {
    return (
      <div
        {...props}
        className={cx(
          'w-full min-h-[90vh] flex flex-col justify-center items-center p-1 text-center',
          props.className
        )}
      >
        <img src={Upgrade} alt="upgrade" className="w-[7.5rem] mb-3" />
        <div className="text-xl font-medium">{title}</div>
        <div className="text-light-secondary text-sm mt-2.5">{description}</div>
        <Button
          variant="primary"
          size="large"
          className="w-60 mt-6"
          onClick={() => navigate('/settings/billing/plans')}
        >
          Upgrade Plan
        </Button>
      </div>
    )
  }

  return <Fragment>{children}</Fragment>
}

export default RestrictedRoute
