import { Suspense, useCallback, useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { debounce } from 'lodash'
import shallow from 'zustand/shallow'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import { Button, Spinner } from 'components'
import ChatWidgetProvider from 'shared/ChatWidget'
import toast from 'utils/toast'
import { downloadFile } from 'utils/file'
import {
  authorize,
  BroadCastNotif,
  DeviceUpdate,
  SensorUpdate
} from 'services/broadcast'
import {
  getAllDevices,
  getAllSensorDevices,
  getDeviceDetails
} from 'services/devices'
import { getAllBridges } from 'services/bridges'
import { getCurrentUser } from 'services/auth'
import { getConfig } from 'services/config'
import { getAccountStats } from 'services/accountStats'
import { getSubscriptionDetails } from 'services/subscriptions'
import useStore, { Store } from 'store'

import Header from './Header'
import CollapsedMenu from './CollapsedMenu'
import ExpandedMenu from './ExpandedMenu'

window.Pusher = require('pusher-js')

declare global {
  interface Window {
    Echo: Echo
    Pusher: Pusher
  }
}

const mapState = (state: Store) => ({
  user: state.auth.currentUser,
  config: state.conf.config,
  setConfig: state.conf.setConfig,
  setUser: state.auth.setCurrentUser,
  setDevices: state.device.setDevices,
  setSensors: state.device.setSensors,
  setBridges: state.bridge.setBridges,
  updateDevice: state.device.updateDevice,
  updateSensor: state.device.updateSensor,
  setStats: state.stat.setStats,
  setSubscription: state.sub.setSubscription,
  addNewNotif: state.notif.addNewNotif
})

function MainLayout() {
  const [expand, setExpand] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const store = useStore(mapState, shallow)

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (!store.user || window.Echo || !store.config) return

    window.Echo = new Echo({
      broadcaster: 'pusher',
      cluster: store.config.pusher_app_cluster,
      key: store.config.pusher_app_key,
      encrypted: true,
      authorizer: (channel: { name: string }) => authorize(channel.name)
    })

    const { id, account_id } = store.user
    window.Echo.private(`devices.status.account.${account_id}`).listen(
      '.device.status.updated',
      onDeviceUpdate
    )
    window.Echo.private(`temperature_sensors.account.${account_id}`).listen(
      '.temperature_sensor.updated',
      onSensorUpdate
    )
    window.Echo.private(`App.User.${id}`).notification(onBroadCastNotif)

    return () => {
      if (store.user) {
        window.Echo.leave(`devices.status.account.${account_id}`)
        window.Echo.leave(`temperature_sensors.account.${account_id}`)
        window.Echo.leave(`App.User.${id}`)
      }
    }
  }, [store.user, store.config])

  const init = async () => {
    try {
      const user = await getCurrentUser()
      store.setUser(user.data.data)

      const responses = await Promise.all([
        getAllDevices(),
        getAllSensorDevices(),
        getAllBridges(),
        getSubscriptionDetails()
      ])
      store.setDevices(responses[0].data.data)
      store.setSensors(responses[1].data.data)
      store.setBridges(responses[2].data.data)
      store.setSubscription(responses[3].data)

      getConfig().then(res => store.setConfig(res.data.data))
      getAccountStats().then(res => store.setStats(res.data.data))
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const onDeviceUpdate = useCallback(
    debounce(({ device_id }: DeviceUpdate) => {
      store.updateDevice(device_id, { __loading: true })
      getDeviceDetails(device_id).then(res => {
        store.updateDevice(device_id, { ...res.data.data, __loading: false })
      })
    }, 500),
    []
  )

  const onSensorUpdate = (data: SensorUpdate) => {
    store.updateSensor(data)
  }

  const onBroadCastNotif = ({ data }: BroadCastNotif) => {
    store.addNewNotif({
      created_at: new Date().toISOString(),
      data: data,
      read_at: null
    })
    toast[data.level](
      {
        title: data.message,
        action: data.url && (
          <Button variant="primary" onClick={() => downloadFile(data.url!)}>
            Download
          </Button>
        )
      },
      { position: 'bottom-right' }
    )
  }

  if (loading) {
    return <Spinner className="w-screen h-screen overflow-hidden" />
  }

  if (error) {
    return <Navigate to="/500" replace />
  }

  if (store.user && !store.user.email_verified_at) {
    return <Navigate to="/verify-email" />
  }

  return (
    <ChatWidgetProvider>
      <div className="w-full h-full flex">
        <div className="flex-none">
          <CollapsedMenu onExpand={() => setExpand(true)} />
        </div>
        <Header />
        <div className="flex-1 ml-[3.4375rem] mt-[3.125rem] overflow-x-hidden">
          <Suspense
            fallback={<Spinner className="min-h-[calc(100vh_-_3.125rem)]" />}
          >
            <Outlet />
          </Suspense>
        </div>
        <AnimatePresence initial={false}>
          {expand && <ExpandedMenu onClose={() => setExpand(false)} />}
        </AnimatePresence>
      </div>
    </ChatWidgetProvider>
  )
}

export default MainLayout
