import { DeviceIconType } from 'icons/utils/DeviceIcon'
import { Bridge } from 'services/bridges'
import { Device } from 'services/devices'

export const cameraTypes: DeviceIconType[] = ['Security Camera']
export const videoServerTypes: DeviceIconType[] = [
  'Digital Video Recorder',
  'Network Video Recorder'
]
export const networkTypes: DeviceIconType[] = [
  'Surge Protector',
  'IoT Gateway',
  'Network Appliance'
]
export const securityTypes: DeviceIconType[] = [
  'Fire Alarm',
  'Emergency Button',
  'IDS'
]
export const scannerTypes: DeviceIconType[] = ['Scanner']
export const sensorTypes: DeviceIconType[] = ['Temperature Sensor']

export const isCamera = (type: string) => !!cameraTypes.find(t => t === type)
export const isSecurity = (type: string) =>
  !!securityTypes.find(t => t === type)
export const isNetwork = (type: string) => !!networkTypes.find(t => t === type)
export const isScanner = (type: string) => !!scannerTypes.find(t => t === type)
export const isSensor = (type: string) => !!sensorTypes.find(t => t === type)

export const getDeviceType = (type: string) => {
  if (isCamera(type)) return 'camera'
  if (isNetwork(type)) return 'network'
  if (isSecurity(type)) return 'security'
  if (isScanner(type)) return 'scanner'
  if (isSensor(type)) return 'sensor'
  return 'other'
}

export const getDeviceStatus = (device: Device) => {
  const { type, is_licensed, deviceLiveStream: ls } = device
  if (isSensor(type)) {
    return getSensorStatus(device)
  }
  if (!!is_licensed && ls && ls.playlist_url && ls.is_streaming) {
    return 'online'
  }
  return 'offline'
}

type Groupped<T> = { [key: string]: { locationName: string; devices: T[] } }
export const groupDevicesByLocation = <T extends Device | Bridge>(
  devices: T[]
): Groupped<T> => {
  const grouped = devices.reduce<Groupped<T>>((acc, device) => {
    const key = device?.location?.name || 'Unknown'
    acc[key] = {
      locationName: key,
      devices: [...(acc[key]?.devices || []), device]
    }
    return acc
  }, {})

  const { Unknown, ...rest } = grouped
  const sorted = Object.keys(rest)
    .sort()
    .reduce<Groupped<T>>((acc, key) => {
      acc[key] = rest[key]
      return acc
    }, {})

  return !!Unknown ? { Unknown, ...sorted } : sorted
}

export const groupDevicesByType = (devices: Device[]) => {
  return devices.reduce((acc, cur) => {
    let type = ''
    if (isCamera(cur.type)) {
      type = cameraTypes[0]
    } else if (isSensor(cur.type)) {
      type = sensorTypes[0]
    } else {
      type = 'Other'
    }
    acc[type] = [...(acc[type] || []), cur]
    return acc
  }, {} as { [key: string]: Device[] })
}

// ------- CAMERA -------

export const MAX_VIDEO_LENGTH = 4 * 60
export const MAX_VIDEO_LENGTH_SEC = 4 * 60 * 60

export const isH265 = <T extends Device>(device: T) => {
  return device.deviceLiveStream?.meta?.codec === 'H265'
}

// ------- SENSOR -------

export const DEGREE_SYMBOL = {
  c: '°C',
  f: '°F'
}

export const SENSOR_PRODUCT_NAME = 'Temperature Sensor Device'

export const ftoc = (f: number) => {
  return (f - 32) / 1.8
}

export const ctof = (c: number) => {
  return +(c * 1.8 + 32).toFixed(2)
}

export const getSensorStatus = ({ temperatureSensor }: Device) => {
  return !!temperatureSensor?.is_online ? 'online' : 'offline'
}

export const formatSensorTemperature = (t: number, type: 'c' | 'f') => {
  return type === 'c' ? t + DEGREE_SYMBOL.c : ctof(t) + DEGREE_SYMBOL.f
}

// ------- BRIDGE -------

export enum ONVIF_DEVICE_STATUS {
  READY = 'READY_TO_AUTHENTICATE',
  SUCCESS = 'AUTHENTICATION_SUCCESS',
  FAILED = 'AUTHENTICATION_FAILED'
}

export const isActiveBridge = ({ is_provisioned, name }: Bridge) => {
  return !!is_provisioned && !!name
}

export const getBridgeName = ({ id, name }: Bridge) => {
  return name || `Streams Bridge ${id}`
}

// ------- PRODUCT -------

export const SHIPPING_COUNTRIES = [
  { label: 'United States', value: 'us' },
  { label: 'Canada', value: 'ca' }
]
