import { Navigate, useParams } from 'react-router-dom'

import { usePageTitle } from 'hooks'
import LivestreamPlayer from 'shared/LivestreamPlayer'

type RouteParams = {
  url: string
}

function EmbedPlayer() {
  usePageTitle('Broadflow Embedded Livestream')

  const { url } = useParams<RouteParams>()

  const getDecodedUrl = () => {
    try {
      if (url) return decodeURIComponent(atob(url))
      return undefined
    } catch {
      return undefined
    }
  }

  const decodedUrl = getDecodedUrl()
  if (!decodedUrl) {
    return <Navigate to="/404" />
  }

  return (
    <LivestreamPlayer
      className="min-w-screen min-h-screen bg-black"
      streamUrl={decodedUrl}
    />
  )
}

export default EmbedPlayer
