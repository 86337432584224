import { Fragment } from 'react'

import { ArrowRight, ClockCircle } from 'icons/outline'
import { SelectButtonItem } from 'components/SelectButton/SelectButtonItem'
import { formatTime, shortDay } from 'utils/dateTime'
import { Schedule } from 'services/schedules'

interface Props {
  schedule: Schedule
}

function ScheduleDetails({ schedule }: Props) {
  return (
    <div className="pt-4">
      <div className="text-[0.9375rem] font-semibold uppercase mb-3">
        Schedule Name
      </div>
      <div>{schedule.name}</div>
      <div className="border-b border-dashed my-6 border-light-stroke" />
      <div className="text-[0.9375rem] font-semibold uppercase mb-3">
        Schedule Date & Time
      </div>
      {!!schedule.is_24_7 && (
        <SelectButtonItem value="1" active className="px-2">
          All the time (24/7)
        </SelectButtonItem>
      )}
      {!schedule.is_24_7 && (
        <Fragment>
          <div className="flex items-center gap-1">
            {schedule.days.map(d => (
              <SelectButtonItem key={d} value={d} active className="px-2">
                {shortDay(d)}
              </SelectButtonItem>
            ))}
          </div>
          <div className="mt-2 flex items-center gap-3">
            <SelectButtonItem value="1" active className="px-2">
              <ClockCircle /> {formatTime(schedule.start_time!)}
            </SelectButtonItem>
            <ArrowRight />
            <SelectButtonItem value="1" active className="px-2">
              <ClockCircle /> {formatTime(schedule.end_time!)}
            </SelectButtonItem>
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default ScheduleDetails
