import { Fragment, useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import BridgeImg from 'assets/images/Bridge-lg.png'
import { Button } from 'components'
import ModalSelectBridgeCount from 'shared/Modals/Billing/SelectBridgeCount'
import { isActiveBridge } from 'utils/device'
import useStore from 'store'

import SideList from './SideList'
import MainContent from './MainContent'
import { useLocationCtx } from '../LocationContext'

function BridgeList({ activeKey }: { activeKey: string }) {
  const bridges = useStore(state => state.bridge.bridges, shallow)
  const activatedBridges = bridges.filter(isActiveBridge)
  const pendingBridges = bridges.filter(b => !isActiveBridge(b))

  const { dispatch } = useLocationCtx()
  const [visible, setVisible] = useState(false)
  const [activeId, setActiveId] = useState(activatedBridges[0]?.id)

  useEffect(() => {
    if (activeKey === 'bridge-list') {
      dispatch({
        selectedLocation: undefined,
        showLocationDetail: false,
        activePinId: undefined
      })
    }
  }, [activeKey])

  return (
    <Fragment>
      {!bridges.length && (
        <div className="flex flex-col h-[calc(100vh_-_6.75rem_-_3.125rem)] justify-center items-center">
          <img src={BridgeImg} alt="bridge" className="max-w-[15rem]" />
          <div className="text-base font-medium mt-5 mb-2">
            You do not have a Stream Bridge
          </div>
          <a
            href="https://broadflow.co/support"
            target="_blank"
            rel="noreferrer"
            className="text-primary underline text-[0.8125rem] font-medium"
          >
            What is a Stream Bridge?
          </a>
          <Button
            variant="primary"
            size="large"
            className="mt-6"
            onClick={() => setVisible(true)}
          >
            Order Now
          </Button>
        </div>
      )}
      {!!bridges.length && (
        <div className="flex h-[calc(100vh_-_6.75rem_-_3.125rem)]">
          <SideList
            activeBridgeId={activeId}
            setActiveBridgeId={setActiveId}
            pendingBridges={pendingBridges}
            activatedBridges={activatedBridges}
          />
          {!!activeId && <MainContent activeBridgeId={activeId} />}
        </div>
      )}
      <AnimatePresence initial={false}>
        {visible && (
          <ModalSelectBridgeCount onClose={() => setVisible(false)} />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default BridgeList
