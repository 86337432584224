import { useNavigate } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { Button } from 'components'
import SensorDeviceCard from 'shared/DeviceCard/SensorDevice'
import { isSensor } from 'utils/device'
import useStore, { Store } from 'store'

const mapState = (state: Store) => {
  return state.device.devices.filter(d => isSensor(d.type) && !!d.is_licensed)
}

function SensorsSummary() {
  const navigate = useNavigate()
  const sensors = useStore(mapState, shallow)

  if (!sensors.length) {
    return null
  }

  return (
    <div className="mb-10">
      <h4 className="flex justify-between items-center mb-4">
        <div className="text-base font-medium">Sensor</div>
        <Button
          variant="secondary-lighten"
          onClick={() =>
            navigate('/devices?tab=device-list', {
              state: 'Temperature Sensor'
            })
          }
        >
          Manage Sensor
        </Button>
      </h4>
      <div className="grid grid-cols-[repeat(auto-fill,minmax(22rem,1fr))] gap-4">
        {sensors.map(d => (
          <SensorDeviceCard key={d.id} device={d} />
        ))}
      </div>
    </div>
  )
}

export default SensorsSummary
