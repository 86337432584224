import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Slack(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.12268 1C5.35034 1.00057 4.72527 1.62735 4.72584 2.39969C4.72527 3.17204 5.35091 3.79882 6.12325 3.79939H7.52065V2.40026C7.52122 1.62792 6.89559 1.00114 6.12268 1C6.12325 1 6.12325 1 6.12268 1V1ZM6.12268 4.73328H2.39741C1.62507 4.73385 0.999431 5.36063 1 6.13297C0.99886 6.90531 1.6245 7.53209 2.39684 7.53323H6.12268C6.89502 7.53266 7.52065 6.90588 7.52008 6.13354C7.52065 5.36063 6.89502 4.73385 6.12268 4.73328Z"
          fill="#36C5F0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9722 6.13297C14.9728 5.36063 14.3471 4.73385 13.5747 4.73328C12.8024 4.73385 12.1767 5.36063 12.1773 6.13297V7.53323H13.5747C14.3471 7.53266 14.9728 6.90588 14.9722 6.13297ZM11.2463 6.13297V2.39969C11.2468 1.62792 10.6218 1.00114 9.8494 1C9.07704 1.00057 8.4514 1.62735 8.45197 2.39969V6.13297C8.45083 6.90531 9.07647 7.53209 9.84883 7.53323C10.6212 7.53266 11.2468 6.90588 11.2463 6.13297Z"
          fill="#2EB67D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.84858 15C10.6209 14.9994 11.2466 14.3726 11.246 13.6003C11.2466 12.8279 10.6209 12.2011 9.84858 12.2006H8.45117V13.6003C8.4506 14.3721 9.07624 14.9989 9.84858 15ZM9.84858 11.2661H13.5744C14.3468 11.2655 14.9724 10.6387 14.9718 9.86638C14.973 9.09403 14.3474 8.46724 13.575 8.46609H9.84915C9.07681 8.46667 8.45117 9.09346 8.45174 9.86581C8.45117 10.6387 9.07624 11.2655 9.84858 11.2661Z"
          fill="#ECB22E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.00024 9.86618C0.999674 10.6385 1.62532 11.2653 2.39767 11.2659C3.17002 11.2653 3.79566 10.6385 3.79509 9.86618V8.46646H2.39767C1.62532 8.46703 0.999674 9.09382 1.00024 9.86618ZM4.72613 9.86618V13.5995C4.72499 14.3719 5.35063 14.9987 6.12298 14.9998C6.89533 14.9992 7.52097 14.3724 7.5204 13.6001V9.86732C7.52155 9.09496 6.8959 8.46817 6.12355 8.46703C5.35063 8.46703 4.72556 9.09382 4.72613 9.86618C4.72613 9.86618 4.72613 9.86675 4.72613 9.86618Z"
          fill="#E01E5A"
        />
      </svg>
    </SVGUniqueID>
  )
}
