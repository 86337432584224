import { FC } from 'react'
import cx from 'classnames'

import { InfoCircle, CheckedCircle, Warning, WarningCircle } from 'icons/filled'

type Props = {
  showIcon?: boolean
  type: 'info' | 'success' | 'warning' | 'danger'
} & JSX.IntrinsicElements['div']

export const Alert: FC<Props> = ({ showIcon, type, children, ...props }) => {
  const bg =
    type === 'info'
      ? 'bg-blue'
      : type === 'success'
      ? 'bg-[#D7F6DF]'
      : type === 'warning'
      ? 'bg-[#fff0d6]'
      : 'bg-red'

  const textColor =
    type === 'info'
      ? 'text-black'
      : type === 'success'
      ? 'text-[#0B9830]'
      : type === 'warning'
      ? 'text-[#CE8606]'
      : 'text-black'

  const Icon =
    type === 'info'
      ? InfoCircle
      : type === 'success'
      ? CheckedCircle
      : type === 'warning'
      ? Warning
      : WarningCircle

  return (
    <div
      {...props}
      className={cx(
        bg,
        'px-3 py-2 flex items-center gap-3 rounded-lg',
        props.className
      )}
    >
      {showIcon !== false && <Icon type={type} className="w-4 h-4 shrink-0" />}
      <div className={cx(textColor, 'text-sm')}>{children}</div>
    </div>
  )
}
