import { FC, SVGProps } from 'react'

import { CreditCard } from 'icons/filled'
import { Discover, MasterCard, Visa, Dinner } from 'icons/logos'

type Props = {
  type: string
} & SVGProps<SVGSVGElement>

export function CreditCardIcon({ type, ...props }: Props) {
  let Icon: FC<any> | null = null

  if (type === 'visa') {
    Icon = Visa
  }
  if (type === 'mastercard') {
    Icon = MasterCard
  }
  if (type === 'discover') {
    Icon = Discover
  }
  if (type === 'dinner') {
    Icon = Dinner
  }

  if (!!Icon) {
    return <Icon {...props} />
  }

  return <CreditCard type="primary" {...props} />
}
