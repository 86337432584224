import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Camera(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="19"
        height="16"
        viewBox="0 0 19 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M12.4661 9.26498C12.4661 10.9074 11.13 12.2435 9.48759 12.2435C7.84518 12.2435 6.5096 10.9074 6.5096 9.26498C6.5096 7.62257 7.84518 6.28648 9.48759 6.28648C11.13 6.28648 12.4661 7.62308 12.4661 9.26498ZM18.7218 5.03565V13.4953C18.7218 14.6231 17.8074 15.5375 16.6796 15.5375H2.29559C1.1678 15.5375 0.253418 14.6231 0.253418 13.4953V5.03565C0.253418 3.90787 1.1678 2.99349 2.29559 2.99349H4.80745V2.2869C4.80745 1.30002 5.60696 0.5 6.59435 0.5H12.3808C13.3682 0.5 14.1677 1.30002 14.1677 2.2869V2.99298H16.6796C17.8074 2.99349 18.7218 3.90787 18.7218 5.03565ZM13.9977 9.26498C13.9977 6.77813 11.9744 4.75486 9.48759 4.75486C7.00125 4.75486 4.97797 6.77813 4.97797 9.26498C4.97797 11.7518 7.00125 13.7751 9.48759 13.7751C11.9744 13.7751 13.9977 11.7518 13.9977 9.26498Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
