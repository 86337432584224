import cx from 'classnames'
import shallow from 'zustand/shallow'

import { useAsync } from 'hooks'
import { Switch } from 'components'
import toast from 'utils/toast'
import { updatePromoNotif } from 'services/auth'
import useStore, { Store } from 'store'

const mapState = (state: Store) => ({
  user: state.auth.currentUser,
  updateUser: state.auth.updateUser
})

function PromoNotif() {
  const { user, updateUser } = useStore(mapState, shallow)
  const promoNotifEnabled = !!user?.enable_promotional_notifications

  const promoNotifAsync = useAsync({ showNotifOnError: true })

  const handlePromoNotifChange = (checked: boolean) => {
    const isEnabled = checked ? 1 : 0
    promoNotifAsync.execute(updatePromoNotif(isEnabled))
    updateUser({ enable_promotional_notifications: isEnabled })
    toast.success(
      { title: 'Promotional notification setting updated' },
      { position: 'bottom-center' }
    )
  }

  return (
    <div className="p-6 bg-white rounded-lg border border-[#eee] shadow-[0_1px_2px_rgba(0,0,0,.16)] mb-4">
      <div className="text-[0.9375rem] mb-4 font-medium">
        Promotional Notifications
      </div>
      <div className="flex items-center gap-2 text-sm">
        <Switch
          className="!gap-4"
          checked={promoNotifEnabled}
          onChange={handlePromoNotifChange}
        >
          <div
            className={cx('font-semibold', promoNotifEnabled && 'text-success')}
          >
            {promoNotifEnabled ? 'On' : 'Off'}
          </div>
        </Switch>
        <div className="text-light-secondary">
          {promoNotifEnabled
            ? 'You will receive marketing & promotional email from Broadflow'
            : 'You will no longer receive marketing & promotional email from Broadflow'}
        </div>
      </div>
    </div>
  )
}

export default PromoNotif
