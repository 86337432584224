import cx from 'classnames'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import { useAsync } from 'hooks'
import toast from 'utils/toast'
import { Account, changeSMTPServer } from 'services/accounts'
import useStore, { Store } from 'store'

const mapState = (state: Store) => ({
  user: state.auth.currentUser,
  updateUseCustomSMTP: state.auth.updateUseCustomSMTP
})

function SmtpServer() {
  const { user, updateUseCustomSMTP } = useStore(mapState, shallow)
  const isCustomSmtp = !!user?.account.use_custom_smtp

  const smtpAsync = useAsync<AxiosResponse<{ data: Account }>>({
    showNotifOnError: true
  })

  const handleSmtpChange = () => {
    const payload = user!.account.use_custom_smtp ? 0 : 1
    smtpAsync.execute(changeSMTPServer(user!.id, payload)).then(res => {
      updateUseCustomSMTP(res.data.data.use_custom_smtp)
      toast.success(
        { title: 'SMTP setting updated' },
        { position: 'bottom-center' }
      )
    })
  }

  return (
    <div className="p-6 bg-white rounded-lg border border-[#eee] shadow-[0_1px_2px_rgba(0,0,0,.16)] mb-4">
      <div className="text-[0.9375rem] mb-1 font-medium">SMTP Server</div>
      <div className="text-light-secondary text-[0.8125rem] mb-2">
        {isCustomSmtp
          ? 'You are using your own SMTP server.'
          : 'You are currently using BroadFlow default SMTP server'}
      </div>
      <div
        className={cx(
          'text-primary text-[0.8125rem] cursor-pointer font-medium underline',
          smtpAsync.isLoading && 'opacity-50 pointer-events-none'
        )}
        onClick={handleSmtpChange}
      >
        {isCustomSmtp
          ? 'Use Broadflow Streams as your SMTP server.'
          : 'Use my own SMTP server'}
      </div>
    </div>
  )
}

export default SmtpServer
