import { AxiosResponse } from 'axios'
import { useState } from 'react'

import { Close, Crown } from 'icons/outline'
import { useAsync } from 'hooks'
import {
  Avatar,
  Button,
  Form,
  FormField,
  Input,
  Modal,
  ModalProps,
  SelectButton
} from 'components'
import toast from 'utils/toast'
import { required } from 'utils/rules'
import { User, editUser, EditUserResponse } from 'services/users'

interface Props extends ModalProps {
  user: User
  onSuccess?: (updatedUser: User) => void
}

interface FormValues {
  name: string
  role: 'admin' | 'standard'
}

function ModalEditUserProfile({ user, onSuccess, ...props }: Props) {
  const [avatar, setAvatar] = useState<File | undefined>(undefined)
  const { execute, isLoading } = useAsync<AxiosResponse<EditUserResponse>>({
    showNotifOnError: true
  })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleSubmit = async ({ name, role }: FormValues) => {
    try {
      const response = await execute(
        editUser(user.id, { name, avatar, is_admin: role === 'admin' ? 1 : 0 })
      )
      toast.success({ title: 'User updated' }, { position: 'bottom-center' })
      onSuccess?.(response.data.data)
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal {...props} className="px-6 w-[34.375rem]" onClose={handleClose}>
      <div className="flex items-center justify-between gap-4 mb-6">
        <div className="text-base font-medium">Edit User Profile</div>
        <Close className="cursor-pointer" onClick={handleClose} />
      </div>
      <div className="flex gap-4">
        <Avatar
          className="shrink-0"
          size={120}
          src={user.avatar_url}
          username={user.name}
          onChange={setAvatar}
        />
        <Form
          className="w-full"
          onFinish={handleSubmit}
          initialValues={{
            name: user.name,
            email: user.email,
            role: user.is_admin ? 'admin' : 'standard'
          }}
        >
          <FormField name="name" label="Name" requiredMask rules={[required]}>
            <Input block placeholder="Enter your name" />
          </FormField>
          <FormField name="email" label="Email">
            <Input block disabled />
          </FormField>
          <FormField name="role" label="User Role">
            <SelectButton>
              <SelectButton.Item value="standard">
                Standard User
              </SelectButton.Item>
              <SelectButton.Item value="admin">
                <Crown className="text-warning" />
                Admin
              </SelectButton.Item>
            </SelectButton>
          </FormField>
          <div className="border-b border-dashed border-light-stroke mb-6" />
          <div className="flex items-center gap-2">
            <Button
              disabled={isLoading}
              type="submit"
              variant="primary"
              className="w-[7.5rem]"
            >
              Save
            </Button>
            <Button
              disabled={isLoading}
              type="button"
              className="w-[5rem]"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default ModalEditUserProfile
