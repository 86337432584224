import { Fragment, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'

import NoResult from 'assets/images/NoResult.png'
import { MobilePhone } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Spinner } from 'components'
import {
  deleteMobileSession,
  getMobileSessions,
  MobileSession
} from 'services/2fa'

function MobileSessions() {
  const [showAll, setShowAll] = useState(false)
  const [data, setData] = useState<MobileSession[]>([])

  const sessionAsync = useAsync<AxiosResponse<MobileSession[]>>({
    showNotifOnError: true
  })
  const deleteAsync = useAsync({
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetMobileSessions()
  }, [])

  const handleGetMobileSessions = async () => {
    const res = await sessionAsync.execute(getMobileSessions())
    setData(res.data || [])
  }

  const handleDeleteSession = async (id: number) => {
    setData(prev => prev.filter(s => s.id !== id))
    deleteAsync.execute(deleteMobileSession(id))
  }

  const renderSession = (session: MobileSession) => (
    <div
      key={session.id}
      className="flex flex-wrap items-center gap-3.5 pb-3 mb-3 border-b border-light-stroke border-dashed last:pb-0 last:border-none last:mb-0"
    >
      <MobilePhone color="#bdbdbd" className="w-[1.875rem] shrink-0" />
      <div className="grow">
        <div className="font-medium text-sm">{session.name}</div>
        <div className="text-[0.8125rem]">
          Last used at {session.last_used || 'unknown'}
        </div>
      </div>
      <div
        className="text-danger ml-auto text-[0.8125rem] cursor-pointer"
        onClick={() => handleDeleteSession(session.id)}
      >
        Delete
      </div>
    </div>
  )

  return (
    <Fragment>
      <div className="flex flex-wrap items-center justify-between gap-3 mb-3">
        <div className="text-base font-medium">
          Manage Logged In Mobile Devices
        </div>
        <div className="inline-flex items-center gap-2">
          <Button
            disabled={sessionAsync.isLoading}
            onClick={() => setShowAll(prev => !prev)}
          >
            Show {showAll ? 'Less' : 'All'}
          </Button>
        </div>
      </div>
      <div className="bg-white rounded-lg p-4">
        {sessionAsync.isLoading ? (
          <Spinner size="small" className="h-[10rem]" />
        ) : !data.length ? (
          <div className="flex flex-col justify-center items-center my-4 opacity-60">
            <img src={NoResult} alt="empty" className="w-[3.5rem] grayscale" />
            <div className="mt-3 text-sm text-light-secondary">No Data</div>
          </div>
        ) : (
          data.slice(0, showAll ? data.length : 5).map(renderSession)
        )}
      </div>
    </Fragment>
  )
}

export default MobileSessions
