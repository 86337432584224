import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import { Close } from 'icons/outline'
import { useAsync } from 'hooks'
import {
  Alert,
  Button,
  Form,
  FormField,
  Input,
  Modal,
  ModalProps
} from 'components'
import toast from 'utils/toast'
import { email, required } from 'utils/rules'
import {
  Recipient,
  CreateRecipientResponse,
  createRecipient
} from 'services/recipients'
import useStore from 'store'

interface Props extends ModalProps {
  onSuccess?: (createdRec: Recipient) => void
}

interface FormValues {
  name: string
  email: string
  phone?: string
}

function ModalCreateRecipient({ onSuccess, ...props }: Props) {
  const user = useStore(state => state.auth.currentUser, shallow)
  const createAsync = useAsync<AxiosResponse<CreateRecipientResponse>>({
    showNotifOnError: true
  })

  const handleClose = () => {
    !createAsync.isLoading && props.onClose()
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      const response = await createAsync.execute(
        createRecipient({
          ...values,
          phone: values.phone || undefined,
          wants_email: true,
          wants_sms: !!values.phone,
          wants_whatsapp: !!values.phone,
          account_id: user!.account_id
        })
      )
      toast.success(
        { title: 'Recipient created' },
        { position: 'bottom-center' }
      )
      onSuccess?.(response.data.data)
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal {...props} className="w-[32rem]" onClose={handleClose}>
      <div className="flex justify-between items-center gap-4 mb-6">
        <div className="text-base font-medium">Create Recipient</div>
        <Close onClick={handleClose} className="cursor-pointer" />
      </div>
      <Alert type="success" showIcon={false} className="mb-4">
        A link will be sent to this email address for verification
      </Alert>
      <Form onFinish={handleSubmit}>
        <FormField
          name="name"
          label="Recipient Name"
          rules={[required]}
          requiredMask
        >
          <Input placeholder="Enter the full name of the recipient" />
        </FormField>
        <FormField
          name="email"
          label="Email"
          rules={[required, email]}
          requiredMask
        >
          <Input placeholder="Enter the email address of the recipient" />
        </FormField>
        <FormField name="phone" label="Phone">
          <Input placeholder="Enter the phone number of the recipient" />
        </FormField>
        <div className="flex items-center justify-between gap-4">
          <Button
            disabled={createAsync.isLoading}
            type="button"
            className="w-[5rem]"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={createAsync.isLoading}
            type="submit"
            variant="primary"
            className="w-[9.375rem]"
          >
            Create Recipient
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalCreateRecipient
