import { FC, Fragment, useRef } from 'react'
import cx from 'classnames'

import { useOutsideClick } from 'hooks'

export type Props = {
  className?: string
  onClose?: () => void
} & JSX.IntrinsicElements['div']

export const PopoverContent: FC<Props> = ({
  children,
  onClose,
  className,
  ...props
}) => {
  const popoverContentRef = useRef<HTMLDivElement>(null)
  useOutsideClick(popoverContentRef, onClose)

  return (
    <Fragment>
      <div
        ref={popoverContentRef}
        {...props}
        style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)' }}
        className={cx(
          'text-sm text-light-primary',
          'bg-white rounded-lg p-3 border border-light-stroke',
          className
        )}
      >
        {children}
      </div>
    </Fragment>
  )
}
