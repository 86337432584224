import DeviceNotif from './DeviceNotif'
import SmtpServer from './SmtpServer'
import PromoNotif from './PromoNotif'

function Notifications() {
  return (
    <div className="p-[1.3rem]">
      <div className="text-xl mb-5 font-medium">Notification Settings</div>
      <DeviceNotif />
      <SmtpServer />
      <PromoNotif />
    </div>
  )
}

export default Notifications
