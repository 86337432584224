import cx from 'classnames'

import { LocationPinAlt } from 'icons/filled'
import { DeviceIcon, DeviceIconProps } from './DeviceIcon'

type Props = DeviceIconProps & {
  iconColor?: [start: string, stop: string] | string
  iconType?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
}

export function FloorPlanPin({
  type,
  iconColor,
  iconType,
  className,
  ...props
}: Props) {
  return (
    <div className={cx('relative inline-block', className)}>
      <LocationPinAlt
        {...props}
        color={iconColor}
        type={iconType}
        className="w-[2.7rem] h-[2.7rem]"
      />
      <div className="absolute top-[3px] left-0 w-full h-[1.125rem] flex justify-center items-center">
        <DeviceIcon
          type={type}
          className={cx('w-2.5 h-2.5', `text-${iconType}`)}
        />
      </div>
    </div>
  )
}
