import cx from 'classnames'

import { Button } from 'components'
import { ChevronLeft, ChevronRight } from 'icons/outline'

export interface Props {
  loading: boolean
  currentPage: number
  totalPage: number
  onPageChange?: (page: number) => void
}

export function ButtonGroup({
  loading,
  currentPage,
  totalPage,
  onPageChange
}: Props) {
  const handlePageChange = (num: number) => {
    onPageChange?.(currentPage + num)
  }

  const prevDisabled = currentPage === 1
  const nextDisabled = currentPage >= totalPage

  return (
    <div className="flex items-center">
      <Button
        className={cx('rounded-r-none', prevDisabled && 'pointer-events-none')}
        innerClassName="rounded-r-none"
        onClick={
          prevDisabled || loading ? undefined : () => handlePageChange(-1)
        }
      >
        <ChevronLeft className={cx(prevDisabled && 'opacity-30')} />
      </Button>
      <Button
        className="rounded-none min-w-[4.5rem] pointer-events-none"
        innerClassName="rounded-none"
      >
        Page: {currentPage}
      </Button>
      <Button
        className={cx('rounded-l-none', nextDisabled && 'pointer-events-none')}
        innerClassName="rounded-l-none"
        onClick={
          nextDisabled || loading ? undefined : () => handlePageChange(1)
        }
      >
        <ChevronRight className={cx(nextDisabled && 'opacity-30')} />
      </Button>
    </div>
  )
}
