import { FC, SVGProps } from 'react'

import Bridge from 'assets/images/Bridge.png'
import CameraLicense from 'assets/images/CameraLicense.png'
import VaultStorage from 'assets/images/VaultStorage.png'

import { Whatsapp, Webhook, Slack, MSTeam, Voice, PagerDuty } from 'icons/logos'
import { VideoCamera, Cloud, Email, Sms, ClockCircle } from 'icons/filled'

type IconTypeImg = 'bridge' | 'camera-license' | 'vault-storage'
type IconTypeSvg =
  | 'device'
  | 'cloud-storage'
  | 'email'
  | 'sms'
  | 'whatsapp'
  | 'webhook'
  | 'slack'
  | 'ms-teams'
  | 'voice'
  | 'pager-duty'
  | 'time'

export type ResourceIconType = IconTypeImg | IconTypeSvg

export type Props<T extends ResourceIconType> = {
  type?: ResourceIconType
  iconColor?: [start: string, stop: string] | string
  iconType?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & (T extends IconTypeSvg
  ? SVGProps<SVGSVGElement>
  : JSX.IntrinsicElements['img'])

export function ResourceIcon<T extends ResourceIconType>({
  type,
  iconColor,
  iconType,
  ...props
}: Props<T>) {
  let Icon: FC<any> | null = null

  if (type === 'device') {
    Icon = VideoCamera
  }
  if (type === 'email') {
    Icon = Email
  }
  if (type === 'sms') {
    Icon = Sms
  }
  if (type === 'cloud-storage') {
    Icon = Cloud
  }
  if (type === 'time') {
    Icon = ClockCircle
  }

  let Logo: any = null
  if (type === 'whatsapp') {
    Logo = Whatsapp
  }
  if (type === 'webhook') {
    Logo = Webhook
  }
  if (type === 'slack') {
    Logo = Slack
  }
  if (type === 'ms-teams') {
    Logo = MSTeam
  }
  if (type === 'voice') {
    Logo = Voice
  }
  if (type === 'pager-duty') {
    Logo = PagerDuty
  }

  let imgSrc: string | null = null
  if (type === 'bridge') {
    imgSrc = Bridge
  }
  if (type === 'camera-license') {
    imgSrc = CameraLicense
  }
  if (type === 'vault-storage') {
    imgSrc = VaultStorage
  }

  if (!!imgSrc) {
    const imgProps = props as JSX.IntrinsicElements['img']
    return <img src={imgSrc} alt={type} {...imgProps} />
  }

  const Comp = Icon || Logo
  return <Comp color={iconColor} type={iconType} {...props} />
}
