import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Voice(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_478_1199)">
          <path
            d="M4.2 12.8H5.8V3.2H4.2V12.8ZM7.4 16H9V0H7.4V16ZM1 9.6H2.6V6.4H1V9.6ZM10.6 12.8H12.2V3.2H10.6V12.8ZM13.8 6.4V9.6H15.4V6.4H13.8Z"
            fill="url(#paint0_linear_478_1199)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_478_1199"
            x1="1"
            y1="0"
            x2="1"
            y2="16"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6065EA" />
            <stop offset="1" stopColor="#3A3EA6" />
          </linearGradient>
          <clipPath id="clip0_478_1199">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
