import Screenshot from 'assets/screenshots/Playback.png'
import { ChevronRight } from 'icons/outline'

function Playback() {
  return (
    <div className="p-4 grow">
      <div className="text-xl font-medium mb-4">Playback</div>
      <img src={Screenshot} alt="screenshot" className="w-full h-[25.625rem]" />
      <div className="mt-12">
        <div className="text-[0.8125rem] mb-3.5">
          Recording can be toggled on and off. When your security camera is
          recording, the video is being encrypted and stored redundantly in at
          least 3 AWS availability zones. This redundancy provides additional
          protection in case of a disaster. Recorded video can be accessed in
          the web browser, on desktop and on mobile.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Modern browsers do not support playback of HEVC / H.265 encoded
          videos. If you are recording surveillance footage with this codec,
          download it first and then play it on your local Mac or PC using VLC
          Player.
        </div>
      </div>
      <div className="mt-12">
        <div className="font-medium text-base mb-4">Get Support</div>
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/cloud-recording/video-playback"
          target="_blank"
          rel="noreferrer"
        >
          How to Play Recorded Video
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/live-viewing/intro-to-dual-streaming"
          target="_blank"
          rel="noreferrer"
        >
          Using Dual Streaming to Optimize Recording Capacity
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/cloud-recording/event-tagging-video-playback"
          target="_blank"
          rel="noreferrer"
        >
          Linking Events with Recorded Video
          <ChevronRight />
        </a>
      </div>
    </div>
  )
}

export default Playback
