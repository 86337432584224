import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function RecordStop(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M11.5213 -1.52588e-05H0.481213C0.216896 -1.52588e-05 -0.00012207 0.205313 -0.00012207 0.471276V11.5259C-0.00012207 11.7864 0.215505 12 0.481213 12H11.5213C11.7856 12 11.9999 11.7878 11.9999 11.5259V0.471276C11.9985 0.206691 11.7856 -1.52588e-05 11.5213 -1.52588e-05Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
