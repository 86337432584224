import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { Close, Minus, Plus } from 'icons/outline'
import { Button, Modal, ModalProps, Spinner } from 'components'
import { ADD_ON } from 'utils/plan'
import useStore, { Store } from 'store'

const mapState = ({ sub, plan }: Store) => ({
  planLoading: plan.loading,
  plans: plan.plans,
  getPlans: plan.getPlans,
  sub: sub.subscription
})

function ModalSelectCameraLicenseCount(props: ModalProps) {
  const navigate = useNavigate()

  const { sub, plans, planLoading, getPlans } = useStore(mapState, shallow)
  const [count, setCount] = useState(sub?.subscription?.camera_licenses || 0)

  useEffect(() => {
    if (!plans.length && !planLoading) getPlans()
  }, [])

  const addOn = plans.find(p => p.name === ADD_ON.CAMERA_LICENSE)
  const disabled = (sub?.subscription?.camera_licenses || 0) === count

  const handleCountChange = (num: number) => () => {
    setCount(prev => {
      const newCount = prev + num
      return newCount > 16 ? prev : newCount < 1 ? 1 : newCount
    })
  }

  const handleCheckout = () => {
    navigate('/checkout', {
      state: { type: 'plan', planId: sub!.subscription!.plan_id, count }
    })
  }

  const renderBillSummary = () => (
    <Fragment>
      <div className="text-sm text-light-primary font-medium mb-4">
        Bill Summary
      </div>
      <div className="flex gap-4 mb-3">
        <span className="text-light-secondary text-[0.8125rem] leading-normal w-20">
          Selected
        </span>
        <span className="text-[0.8125rem] leading-normal">
          <span className="font-semibold">{count} </span>
          license x{' '}
          <span className="font-semibold">${addOn!.price_in_dollars} </span>/
          month
        </span>
      </div>
      <div className="border-b border-light-stroke my-2" />
      <div className="flex gap-4 items-center">
        <span className="text-light-secondary text-[0.8125rem] leading-normal w-20">
          New Total
        </span>
        <span className="text-xl font-medium leading-normal">
          ${count * (addOn!.price_in_dollars || 1)}
        </span>
      </div>
    </Fragment>
  )

  return (
    <Modal {...props} className="!p-0 w-[25rem]">
      <div className="font-medium flex justify-between items-center px-4 py-3 border-b border-light-stroke mb-6">
        <div>Add or Remove camera licenses</div>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      {planLoading && <Spinner size="small" className="h-40" />}
      {!!addOn && !!sub && (
        <div className="px-4 pb-6">
          <div className="flex justify-between">
            <div>
              <h4 className="text-sm font-medium mb-2">Select quantity</h4>
              <div className="flex gap-2">
                <div className="w-[5rem] h-[4.7rem] p-1 rounded-lg border border-light-stroke flex items-center justify-center">
                  <span className="text-5xl leading-normal">{count}</span>
                </div>
                <div className="flex flex-col justify-between">
                  <Button className="w-8 h-8" onClick={handleCountChange(1)}>
                    <Plus />
                  </Button>
                  <Button className="w-8 h-8" onClick={handleCountChange(-1)}>
                    <Minus />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 mb-12 text-[0.8125rem]">
            <span className="text-success">Need more than 16 licenses?</span>{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://broadflow.co/contact"
              className="text-primary underline font-medium"
            >
              Contact Us
            </a>
          </div>
          {!disabled && renderBillSummary()}
          <Button
            size="large"
            variant="primary"
            className="mt-12 w-full"
            disabled={disabled}
            onClick={disabled ? undefined : handleCheckout}
          >
            Checkout
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default ModalSelectCameraLicenseCount
