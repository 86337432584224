import { Navigate, useParams } from 'react-router-dom'

import { More } from 'icons/outline'
import { Button, Dot, Tabs } from 'components'
import RouteTabs, { RouteTab } from 'shared/RouteTabs'

import ConditionAction from './ConditionAction'
import Recipients from './Recipients'
import AffectedDevices from './AffectedDevices'

type RouteParams = {
  id?: string
}

const tabs = [
  {
    key: 'condition-action',
    tab: 'Condition & Action',
    Component: ConditionAction
  },
  {
    key: 'recipients',
    tab: 'Recipients',
    Component: Recipients
  },
  {
    key: 'affected-devices',
    tab: 'Affected Devices',
    Component: AffectedDevices
  }
]

function IotAutomationDetail() {
  const { id } = (useParams() || {}) as RouteParams

  if (!id || isNaN(+id) || +id < 1) {
    return <Navigate to="/404" replace />
  }

  const renderTab = (tab: RouteTab) => {
    return (
      <Tabs.TabPane tab={tab.tab} key={tab.key}>
        <tab.Component />
      </Tabs.TabPane>
    )
  }

  return (
    <div className="relative w-full">
      <div className="flex items-center justify-between gap-4 px-4 pt-6 pb-4">
        <div className="inline-flex items-center gap-2">
          <Dot className="w-2.5 h-2.5" type="success" />
          <div className="text-[1.5rem] font-medium">Face Detected</div>
        </div>
        <div className="inline-flex items-center gap-2">
          <Button variant="secondary">Turn Off</Button>
          <Button variant="secondary">
            <More />
          </Button>
        </div>
      </div>
      <RouteTabs tabs={tabs}>{routeTabs => routeTabs.map(renderTab)}</RouteTabs>
    </div>
  )
}

export default IotAutomationDetail
