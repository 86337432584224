import { Fragment, ChangeEventHandler } from 'react'
import DatePicker, * as DProps from 'react-datepicker'
import { format, setHours, setMinutes } from 'date-fns'
import cx from 'classnames'

import { ChevronLeft, ChevronRight } from 'icons/outline'
import { Button, Popover } from 'components'
import MenuItem from 'shared/MenuItem'
import { datePickerPresets } from 'utils/dateTime'

interface Props extends DProps.ReactDatePickerProps<any, any> {
  showPresets?: boolean
  showTime?: boolean
  onClose: () => void
}

function Picker({
  showPresets,
  showTime,
  onClose,
  selectsRange,
  selectsStart,
  selectsEnd,
  ...props
}: Props) {
  const { selected, startDate, endDate } = props
  const target = !selectsRange ? selected : selectsStart ? startDate : endDate

  const customHeader = (params: DProps.ReactDatePickerCustomHeaderProps) => (
    <div className="flex items-center justify-between">
      <Button
        disabled={params.prevMonthButtonDisabled}
        className="w-[1.875rem] h-[1.625rem]"
        onClick={params.decreaseMonth}
      >
        <ChevronLeft />
      </Button>
      <div className="text-[0.8125rem] font-medium">
        {format(params.date, 'MMM, yyyy')}
      </div>
      <Button
        disabled={params.nextMonthButtonDisabled}
        className="w-[1.875rem] h-[1.625rem]"
        onClick={params.increaseMonth}
      >
        <ChevronRight />
      </Button>
    </div>
  )

  const handlePresetClick = (value: Date | [Date, Date]) => {
    props.onChange(
      selectsRange && !('length' in value) ? [value, value] : value,
      undefined
    )
    onClose()
  }

  const renderPresets = () => {
    return datePickerPresets.map(({ label, value }, idx) => (
      <Fragment key={label}>
        <MenuItem
          onClick={() => handlePresetClick(value)}
          className={cx(
            'text-[0.8125rem]',
            'length' in value &&
              !selectsRange &&
              'pointer-events-none text-[#c1c1c1]'
          )}
        >
          {label}
        </MenuItem>
        {(idx === 0 || idx === 3) && (
          <div key={idx} className="border-b border-[#EDEDED] my-1" />
        )}
      </Fragment>
    ))
  }

  const handleHourChange: ChangeEventHandler<HTMLInputElement> = e => {
    const value = +e.target.value
    if (!target) return
    const updated = setHours(target, value > 23 ? 23 : value)
    if (!selectsRange) {
      return props.onChange(updated, undefined)
    }
    props.onChange(
      [
        selectsStart ? updated : startDate || null,
        selectsEnd ? updated : endDate || null
      ],
      undefined
    )
  }

  const handleMinuteChange: ChangeEventHandler<HTMLInputElement> = e => {
    const value = +e.target.value
    if (!target) return
    const updated = setMinutes(target, value > 59 ? 59 : value)
    if (!selectsRange) {
      return props.onChange(updated, undefined)
    }
    props.onChange(
      [
        selectsStart ? updated : startDate || null,
        selectsEnd ? updated : endDate || null
      ],
      undefined
    )
  }

  const renderTimeInputs = () => {
    const h = target ? target.getHours() || '0' : ''
    const m = target ? target.getMinutes() || '0' : ''

    return (
      <div className="flex flex-col h-full justify-center items-center">
        <div className="font-medium text-[0.8125rem] mb-2">Choose Time</div>
        <div className="inline-flex items-center gap-1">
          <input
            type="number"
            placeholder="hh"
            value={h}
            onChange={handleHourChange}
            className="w-12 h-12 border border-light-stroke rounded-lg outline-0 text-center p-2"
          />
          :
          <input
            type="number"
            placeholder="mm"
            value={m}
            onChange={handleMinuteChange}
            className="w-12 h-12 border border-light-stroke rounded-lg outline-0 text-center p-2"
          />
        </div>
      </div>
    )
  }

  return (
    <Popover.Content onClose={onClose} className="w-fit p-4">
      <div className="flex">
        {showPresets === true && (
          <div className="w-[9.375rem] border-r border-[#EDEDED] mr-4 pr-4">
            {renderPresets()}
          </div>
        )}
        {/* @ts-ignore */}
        <DatePicker
          {...props}
          inline
          selectsRange={selectsRange}
          renderCustomHeader={customHeader}
        />
        {showTime && (
          <div className="w-32 border-l border-[#EDEDED] ml-4 pl-4">
            {renderTimeInputs()}
          </div>
        )}
      </div>
    </Popover.Content>
  )
}

export default Picker
