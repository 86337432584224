import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Status(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M2.09882 6.9834H0.524751C0.234941 6.9834 0 7.21837 0 7.50815V13.5388C0 13.8286 0.234941 14.0636 0.524751 14.0636H2.09882C2.3886 14.0636 2.62354 13.8286 2.62354 13.5388V7.50799C2.6237 7.21818 2.3886 6.9834 2.09882 6.9834Z"
          fill="currentColor"
        />
        <path
          d="M5.88956 4.72168H4.31552C4.02571 4.72168 3.79077 4.95662 3.79077 5.24643V13.5388C3.79077 13.8286 4.02571 14.0635 4.31552 14.0635H5.88956C6.17937 14.0635 6.41431 13.8286 6.41431 13.5388V5.24627C6.41429 4.95665 6.17934 4.72168 5.88956 4.72168Z"
          fill="currentColor"
        />
        <path
          d="M9.68 2.46021H8.10578C7.816 2.46021 7.58105 2.69515 7.58105 2.98493V13.539C7.58105 13.8288 7.816 14.0637 8.10578 14.0637H9.67984C9.96963 14.0637 10.2046 13.8288 10.2046 13.539V2.98477C10.2046 2.69499 9.96963 2.46021 9.68 2.46021Z"
          fill="currentColor"
        />
        <path
          d="M13.4754 0.5H11.9013C11.6115 0.5 11.3766 0.734941 11.3766 1.02475V13.539C11.3766 13.8288 11.6115 14.0637 11.9013 14.0637H13.4754C13.7652 14.0637 14.0001 13.8288 14.0001 13.539V1.02459C14.0001 0.734781 13.7652 0.5 13.4754 0.5Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
