import { useEffect } from 'react'
import shallow from 'zustand/shallow'
import { AxiosResponse } from 'axios'

import { useAsync, usePageTitle } from 'hooks'
import { Spinner } from 'components'
import RouteTabs from 'shared/RouteTabs'
import { getAllLocations, LocationResponse } from 'services/locations'
import useStore, { Store } from 'store'

import DeviceList from './DeviceList'
import BridgeList from './BridgeList'
import DeviceLocations from './DeviceLocations'
import FloorPlans from './FloorPlans'
import LocationCtxProvider from './LocationContext'

const mapState = (state: Store) => ({
  betaFeatures: state.auth.accessBetaFeatures(),
  setLocations: state.location.setLocations
})

function DeviceManagement() {
  usePageTitle('Device Management | Broadflow Streams')
  const { betaFeatures, setLocations } = useStore(mapState, shallow)

  const { isLoading, execute } = useAsync<AxiosResponse<LocationResponse>>({
    showNotifOnError: true,
    status: 'pending'
  })

  useEffect(() => {
    execute(getAllLocations()).then(res => setLocations(res.data.data))
  }, [])

  let tabs = [
    {
      key: 'device-list',
      tab: 'Devices',
      Component: DeviceList
    },
    {
      key: 'device-location',
      tab: 'Locations',
      Component: DeviceLocations
    },
    {
      key: 'floor-plans',
      tab: 'Floor Plans',
      Component: FloorPlans
    }
  ]

  const bridgeTab = {
    key: 'bridge-list',
    tab: 'Bridges',
    Component: BridgeList
  }

  if (!!betaFeatures) {
    tabs = [tabs[0], bridgeTab, ...tabs.slice(1)]
  }

  if (isLoading) {
    return <Spinner className="h-[90vh]" />
  }

  return (
    <LocationCtxProvider>
      <div className="text-2xl font-medium p-4 pt-6">Devices Managment</div>
      <RouteTabs tabs={tabs} className="mt-2" />
    </LocationCtxProvider>
  )
}

export default DeviceManagement
