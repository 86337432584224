import { useEffect, useRef, useState } from 'react'

const options = {
  serialize: JSON.stringify,
  deserialize: JSON.parse
}

export function useLocalStorage<T>(
  key: string,
  defaultValue: T | (() => T),
  { serialize, deserialize } = options
) {
  const [state, setState] = useState(() => {
    const value = window.localStorage.getItem(key)
    if (value) return deserialize(value) as T
    return defaultValue instanceof Function ? defaultValue() : defaultValue
  })

  const prevKeyRef = useRef(key)

  useEffect(() => {
    if (prevKeyRef.current !== key) {
      window.localStorage.removeItem(prevKeyRef.current)
    }
    prevKeyRef.current = key
    window.localStorage.setItem(key, serialize(state))
  }, [key, serialize, state])

  return [state, setState] as const
}
