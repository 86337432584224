import { ReactNode } from 'react'

import { Button, Modal, ModalProps } from 'components'

interface Props extends ModalProps {
  imageUrl: string
  title: ReactNode
  description: ReactNode
}

function ModalCheckoutSuccess({
  imageUrl,
  title,
  description,
  ...props
}: Props) {
  const handleRedirect = () => {
    window.location.href = '/'
  }

  return (
    <Modal {...props} className="w-[19.375rem]">
      <div className="w-[7.5rem] mx-auto mt-[2.125rem] mb-10">
        <img src={imageUrl} alt="ty" />
      </div>
      <div className="mb-2 text-base font-medium text-center">{title}</div>
      <div className="text-[0.8125rem] mb-8 text-center">{description}</div>
      <Button variant="primary" size="large" block onClick={handleRedirect}>
        Ok
      </Button>
    </Modal>
  )
}

export default ModalCheckoutSuccess
