import { useLayoutEffect } from 'react'

export function useElementClassName(selector: string, ...classnames: string[]) {
  useLayoutEffect(() => {
    const elements = document.querySelectorAll(selector)
    elements.forEach(el => el.classList.add(...classnames))
    return () => {
      elements.forEach(el => el.classList.remove(...classnames))
    }
  }, [])
}
