import { useState } from 'react'
import { omit, pick } from 'lodash'
import { useIntercom } from 'react-use-intercom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import { AxiosResponse } from 'axios'

import { ChevronLeft, Close, InfoCircle } from 'icons/outline'
import { useAsync } from 'hooks'
import { Modal, ModalProps, Popover, Tooltip } from 'components'
import ModalGuideBook from 'shared/Modals/GuideBook'
import PopoverHelpMenu from 'shared/Popovers/HelpMenu'
import toast from 'utils/toast'
import { days } from 'utils/dateTime'
import { createDevice, Device, getAllDevices } from 'services/devices'
import useStore, { Store } from 'store'

import FormContextProvider, { State, useFormCtx } from './FormContext'

interface Props extends ModalProps {
  onSuccess?: () => void
}

const mapState = (state: Store) => ({
  user: state.auth.currentUser,
  setDevices: state.device.setDevices,
  updateUsedCount: state.stat.updateUsedCount
})

function ModalCreateDevice({ onSuccess, ...props }: Props) {
  const { show } = useIntercom()
  const [showGuideBook, setShowGuideBook] = useState(false)

  const createAsync = useAsync<AxiosResponse<{ device: Device }>>({
    showNotifOnError: true
  })
  const deviceAsync = useAsync<AxiosResponse<{ data: Device[] }>>({
    showNotifOnError: true
  })

  const { stepIdx, steps, dispatch, ...ctx } = useFormCtx()
  const { user, setDevices, updateUsedCount } = useStore(mapState, shallow)

  const handleClose = () => {
    !createAsync.isLoading && props.onClose()
  }

  const handleGoBack = () => {
    if (!stepIdx) return handleClose()
    dispatch({ stepIdx: stepIdx - 1 })
  }

  const mapSchedule = (values: State['schedule']) => {
    const { is_24_7, start_time, end_time, ...s } = values
    const selectedDays = (is_24_7 ? days : s.days).map(d => d.toUpperCase())
    return {
      is_active: true,
      name: s.name,
      is_24_7,
      days: selectedDays,
      start_time: is_24_7 ? undefined : start_time,
      end_time: is_24_7 ? undefined : end_time,
      email_recipient_ids: s.email_recipients.map(r => r.value),
      phone_recipient_ids: s.phone_recipients.map(r => r.value),
      whatsapp_recipient_ids: s.whatsapp_recipients.map(r => r.value),
      ...pick(s, [
        'channels',
        'webhook',
        'slack_webhook',
        'teams_webbhook',
        'pagerduty_integration_key'
      ])
    }
  }

  const handleCreateDevice = async (
    key: 'cloudSurveillance' | 'schedule',
    values: State['cloudSurveillance'] | State['schedule']
  ) => {
    if (!!key && !!values) dispatch({ [key]: values })
    const { deviceDetail, cloudSurveillance, schedule } = ctx

    const device = {
      ...omit(deviceDetail, 'location', 'timezone'),
      ...cloudSurveillance,
      ...('is_licensed' in (values || {}) ? values : {}),
      timezone: deviceDetail.timezone?.value || '',
      account_id: user!.account_id,
      location_id: deviceDetail.location?.value
    }

    const deviceSchedule = {
      ...schedule,
      ...('channels' in (values || {}) ? values : {})
    }

    await createAsync.execute(
      createDevice({
        device,
        deviceSchedules: deviceSchedule.name
          ? [mapSchedule(deviceSchedule)]
          : undefined
      })
    )

    const devices = await deviceAsync.execute(getAllDevices())
    setDevices(devices.data.data)
    updateUsedCount({
      devices: 1,
      camera_licenses: device.is_licensed ? 1 : 0
    })

    handleClose()
    toast.success({ title: 'Device Created' }, { position: 'bottom-center' })
  }

  const renderPopoverHelpMenu = (onClose: () => void) => (
    <PopoverHelpMenu
      onContactSupport={() => show()}
      onOpenGuideBook={() => setShowGuideBook(true)}
      onClose={onClose}
    />
  )

  const { label, tooltip, Component } = steps[stepIdx]
  const loading = createAsync.isLoading || deviceAsync.isLoading

  return (
    <Modal
      {...props}
      wrapperClassName="!p-0"
      className="w-[100vw] min-h-[100vh] rounded-none !p-0"
      onClose={handleClose}
    >
      <div className="flex items-center gap-4 justify-between px-[5.3125rem] py-2 border-b border-light-stroke">
        <div className="inline-flex items-center gap-4">
          <ChevronLeft
            className="shrink-0 cursor-pointer"
            onClick={handleGoBack}
          />
          <div>
            <div className="text-base font-medium">Create Device</div>
            <div className="flex items-center gap-2 text-xs text-light-secondary mt-[2px]">
              Step {stepIdx + 1}: {label}
              {tooltip && (
                <Tooltip content={tooltip}>
                  <InfoCircle className="w-3.5 h-3.5" />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className="text-[0.8125rem] inline-flex items-center gap-5">
          <Popover
            placement="bottom-end"
            content={onClose => renderPopoverHelpMenu(onClose)}
          >
            <div className="text-primary text-[0.9125rem] font-medium cursor-pointer">
              Help
            </div>
          </Popover>
          <Close className="shrink-0 cursor-pointer" onClick={handleClose} />
        </div>
      </div>
      <Component loading={loading} onSubmit={handleCreateDevice} />
      <AnimatePresence initial={false}>
        {showGuideBook && (
          <ModalGuideBook onClose={() => setShowGuideBook(false)} />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default function ModalCreateDeviceWrapper(props: Props) {
  return (
    <FormContextProvider>
      <ModalCreateDevice {...props} />
    </FormContextProvider>
  )
}
