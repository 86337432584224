import { FloorPlan } from './floorPlans'
import client, { ResponseMeta } from './client'

export interface Location {
  id: number
  account_id: number
  address: string | null
  address_2: string | null
  created_at: string
  name: string
  lat: string
  lng: string
  location_floor_plans: FloorPlan[]
  updated_at: string
  devices_count: number
  zipcode: string | null
}

export type LocationResponse = {
  data: Location[]
  meta: ResponseMeta
}

export const getAllLocations = () => {
  return client.get<LocationResponse>(`/api/locations?limit=300`)
}

export const getLocationDetail = (id: number, include = '') => {
  return client.get<{ data: Location }>(
    `/api/locations/${id}?include=${include}`
  )
}

export const createLocation = async (payload: {
  account_id: number
  name: string
  address: string
  address_2?: string
  lat: number
  lng: number
  zipcode?: string
}) => {
  return client.post('/api/locations', payload)
}

export const updateLocation = async (
  id: number,
  payload: {
    account_id: number
    name: string
    address: string
    address_2?: string
    lat: number
    lng: number
    zipcode?: string
  }
) => {
  return client.put(`/api/locations/${id}`, payload)
}

export const deleteLocation = (id: number) => {
  return client.delete(`/api/locations/${id}`)
}
