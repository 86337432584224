import { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import { LICENSE_TAG_COLOR } from 'configs/tagColors'
import { Bridge } from 'icons/outline'
import { Button, Dot, Tag, Tooltip } from 'components'
import { getDeviceStatus, isCamera } from 'utils/device'
import { Device } from 'services/devices'
import useStore, { Store } from 'store'

const mapState = ({ device, bridge }: Store) => ({
  devices: device.devices,
  bridges: bridge.bridges
})

function CameraSummary() {
  const navigate = useNavigate()

  const { devices, bridges } = useStore(mapState, shallow)
  const cameraDevices = devices.filter(
    d => isCamera(d.type) && getDeviceStatus(d) === 'online'
  )

  const renderToolTipContent = ({ name, enable_recording: r }: Device) => {
    return (
      <div className="min-w-[6.25rem] text-[0.8125rem]">
        <div className="font-medium mb-1">{name}</div>
        <div className="flex items-center gap-1">
          <div className="text-light-secondary">Recording:</div>
          <div className={cx('font-bold', r ? 'text-success' : 'text-danger')}>
            {!!r ? 'ON' : 'OFF'}
          </div>
        </div>
      </div>
    )
  }

  if (!cameraDevices.length) {
    return null
  }

  return (
    <div className="mb-10">
      <h4 className="flex justify-between items-center mb-4">
        <div className="text-base font-medium">Camera</div>
        <Button
          variant="secondary-lighten"
          onClick={() => navigate('/devices?tab=device-list')}
        >
          Manage Camera
        </Button>
      </h4>
      <div className="grid grid-cols-[repeat(auto-fill,minmax(22rem,1fr))] gap-4">
        {cameraDevices.map(d => {
          const bridge = bridges.find(b => b.id === d.bridge_id)
          return (
            <Link
              key={d.id}
              to={`/devices/${d.id}?tab=device-details`}
              className="relative w-full rounded-lg shadow-[0_1px_2px_rgba(0,0,0,0.16)]"
            >
              <Tooltip
                content={renderToolTipContent(d)}
                placement="top"
                className="w-full h-[10.5rem] bg-black flex items-center justify-center rounded-lg relative"
              >
                <div className="absolute top-2 left-3 inline-flex items-center gap-2">
                  <Dot type="success" className="w-3 h-3 border" />
                  {!!d.is_licensed && (
                    <Tag {...LICENSE_TAG_COLOR}>Licensed</Tag>
                  )}
                  {!!bridge && (
                    <Tooltip
                      content={
                        <Fragment>
                          This camera is connect to{' '}
                          <span className="font-medium">{bridge.name}</span>
                        </Fragment>
                      }
                    >
                      <div className="bg-white p-1.5 rounded-3xl border border-light-stroke">
                        <Bridge className="text-light-secondary" />
                      </div>
                    </Tooltip>
                  )}
                </div>
                {d.thumbnail_url && (
                  <img
                    className="h-full rounded-lg"
                    src={d.thumbnail_url}
                    alt="thumb"
                  />
                )}
                {!d.thumbnail_url && (
                  <span className="relative text-light-secondary text-[0.8125rem]">
                    No Preview
                  </span>
                )}
              </Tooltip>
              {d.__loading && (
                <div className="absolute top-0 left-0 bg-white/90 w-full h-full rounded-lg">
                  <div className="flex h-full justify-center items-center">
                    Please wait...
                  </div>
                </div>
              )}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default CameraSummary
