import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function ShareAlt(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M12.75 16H2.2501C1.28528 16 0.500122 15.2149 0.500122 14.25V6.08348C0.500122 5.11866 1.28528 4.3335 2.2501 4.3335H4.00008C4.32264 4.3335 4.58337 4.59422 4.58337 4.91679C4.58337 5.23935 4.32264 5.50008 4.00008 5.50008H2.2501C1.92807 5.50008 1.6667 5.76144 1.6667 6.08348V14.25C1.6667 14.572 1.92807 14.8334 2.2501 14.8334H12.75C13.0719 14.8334 13.3333 14.572 13.3333 14.25V9.58344C13.3333 9.26087 13.594 9.00004 13.9165 9.00004C14.2392 9.00004 14.4999 9.26087 14.4999 9.58344V14.25C14.4999 15.2149 13.7148 16 12.75 16Z"
          fill="currentColor"
        />
        <path
          d="M14.3817 3.06068C14.3817 2.96219 14.3623 2.86466 14.3247 2.77366C14.287 2.68266 14.2317 2.59998 14.1621 2.53033C14.0924 2.46069 14.0097 2.40544 13.9187 2.36775C13.8277 2.33006 13.7302 2.31066 13.6317 2.31066H9.63175C9.43284 2.31066 9.24208 2.38968 9.10142 2.53033C8.96077 2.67099 8.88175 2.86175 8.88175 3.06066C8.88175 3.25957 8.96077 3.45034 9.10142 3.59099C9.24208 3.73164 9.43284 3.81066 9.63175 3.81066H11.8211L6.03035 9.6014C5.88969 9.74205 5.81068 9.93282 5.81068 10.1317C5.81068 10.3306 5.88969 10.5214 6.03035 10.6621C6.171 10.8027 6.36176 10.8817 6.56068 10.8817C6.75959 10.8817 6.95035 10.8027 7.09101 10.6621L12.8817 4.87132V7.06065C12.8817 7.25957 12.9608 7.45033 13.1014 7.59098C13.2421 7.73163 13.4328 7.81065 13.6317 7.81065C13.8307 7.81065 14.0214 7.73163 14.1621 7.59098C14.3027 7.45033 14.3817 7.25957 14.3817 7.06065V3.06068Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
