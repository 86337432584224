import { useEffect, useState } from 'react'

import { useElementClassName, usePageTitle } from 'hooks'
import { formatInTz, dateTimeFormat } from 'utils/dateTime'
import useStore from 'store'

import LatestNotifs from './LatestNotifs'
import CameraSummary from './CameraSummary'
import SensorsSummary from './SensorsSummary'
import NotifSummary from './NotifSummary'
import NotifTagSummary from './NotifTagSummary'
import ResourceSummary from './ResourceSummary'
import BridgeSummary from './BridgeSummary'

function CurrentDateTime() {
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="inline-flex flex-col text-right gap-[2px]">
      <span className="text-sm font-medium">
        {formatInTz(now, undefined, `${dateTimeFormat['hh:mm a']} z`)}
      </span>
      <span className="text-[0.8125rem] text-light-secondary">
        {formatInTz(now, undefined, `${dateTimeFormat['MMM dd, yyyy']}`)}
      </span>
    </div>
  )
}

function Dashboard() {
  usePageTitle('Dashboard | Broadflow Streams')
  useElementClassName('body', 'bg-light-bg')

  const betaFeatures = useStore(state => state.auth.accessBetaFeatures())

  return (
    <div className="p-4 bg-light-bg min-h-screen">
      <div className="mt-2 mb-5 flex flex-wrap items-center justify-between">
        <h1 className="text-2xl font-medium">Dashboard</h1>
        <CurrentDateTime />
      </div>
      <LatestNotifs />
      <CameraSummary />
      {betaFeatures && <SensorsSummary />}
      <ResourceSummary />
      <NotifSummary />
      <NotifTagSummary />
      {betaFeatures && <BridgeSummary />}
      <div className="text-xs text-center text-light-secondary mt-6">
        Broadflow Corp © {new Date().getFullYear()}
      </div>
    </div>
  )
}

export default Dashboard
