import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function SurgeProtector(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="9"
        height="18"
        viewBox="0 0 9 18"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M0.250046 0.254883V9.87988H2.87505V17.7549L9.00004 7.25488H5.50004L8.12504 0.254883H0.250046Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
