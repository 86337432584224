import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import shallow from 'zustand/shallow'

import Bridge from 'assets/images/Bridge-lg.png'
import { Close, Minus, Plus } from 'icons/outline'
import { Button, Modal, ModalProps, SelectButton, Spinner } from 'components'
import { Product } from 'services/products'
import useStore, { Store } from 'store'

const mapState = (state: Store) => ({
  loading: state.product.loading,
  products: state.product.products,
  getProducts: state.product.getProducts
})

function ModalSelectBridgeCount(props: ModalProps) {
  const navigate = useNavigate()
  const [count, setCount] = useState(1)
  const [selectedProduct, setSelectedProduct] = useState<Product>()

  const { loading, products, getProducts } = useStore(mapState, shallow)

  useEffect(() => {
    if (!products.length && !loading) getProducts()
  }, [])

  const handleCountChange = (num: number) => () => {
    setCount(prev => {
      const newCount = prev + num
      return newCount < 1 ? 1 : newCount
    })
  }

  const handleProductChange = (productId: string) => {
    setSelectedProduct(products.find(p => p.id === +productId))
  }

  const handleCheckout = () => {
    navigate('/checkout', {
      state: { type: 'bridge', count, id: selectedProduct!.id }
    })
  }

  const renderBillSummary = () => (
    <div className="mt-8">
      <div className="text-sm text-light-primary font-medium mb-4">
        Bill Summary
      </div>
      <div className="flex gap-4 mb-3">
        <span className="text-light-secondary text-[0.8125rem] leading-normal basis-5/12">
          Price
        </span>
        <span className="text-[0.8125rem] font-semibold leading-normal">
          {count} x ${selectedProduct!.price_in_dollars}
        </span>
      </div>
      <div className="border-b border-light-stroke my-2" />
      <div className="flex gap-4 items-center">
        <span className="text-light-secondary text-[0.8125rem] leading-normal basis-5/12">
          Total
        </span>
        <span className="text-xl font-medium leading-normal">
          ${count * selectedProduct!.price_in_dollars}
        </span>
      </div>
    </div>
  )

  return (
    <Modal {...props} className="!p-0 w-[25rem]">
      <div className="font-medium flex justify-between items-center px-4 py-3 border-b border-light-stroke mb-6">
        <div>Buy Bridge</div>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      {!!loading && <Spinner size="small" className="h-80 mb-6" />}
      {!!products.length && (
        <div className="px-4 pb-6">
          <div className="flex justify-between mb-12">
            <div>
              <h4 className="text-sm font-medium mb-2">Select quantity</h4>
              <div className="flex gap-2">
                <div className="w-[5rem] h-[4.7rem] p-1 rounded-lg border border-light-stroke flex items-center justify-center">
                  <span className="text-5xl leading-normal">{count}</span>
                </div>
                <div className="flex flex-col justify-between">
                  <Button className="w-8 h-8" onClick={handleCountChange(1)}>
                    <Plus />
                  </Button>
                  <Button className="w-8 h-8" onClick={handleCountChange(-1)}>
                    <Minus />
                  </Button>
                </div>
              </div>
            </div>
            <img
              src={Bridge}
              alt="bridge"
              className="mt-5 w-[7.5rem] h-[3.875rem] shrink-0 self-center"
            />
          </div>
          <div className="text-sm text-light-primary font-medium mb-2">
            Select Bridge Type
          </div>
          <SelectButton
            value={selectedProduct?.id + ''}
            onChange={handleProductChange}
          >
            {products
              .filter(p => p.name.toLowerCase().includes('bridge'))
              .map(b => (
                <SelectButton.Item key={b.id} value={b.id}>
                  <div className="w-full flex flex-col items-start gap-2">
                    <div className="font-medium">
                      {b.name.match(/([\d a-zA-Z]+)/gm)?.[1]}
                    </div>
                    <div className="text-light-secondary">
                      ${b.price_in_dollars}
                    </div>
                  </div>
                </SelectButton.Item>
              ))}
          </SelectButton>
          {!!selectedProduct && renderBillSummary()}
          <Button
            size="large"
            variant="primary"
            className="mt-12 w-full"
            disabled={!selectedProduct}
            onClick={!selectedProduct ? undefined : handleCheckout}
          >
            Checkout
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default ModalSelectBridgeCount
