import { Navigate, useLocation, useParams } from 'react-router-dom'

import { usePageTitle } from 'hooks'
import ViewSharedFile from './ViewSharedFile'

type RouteQuery = {
  email: string
  signature: string
}

function SharedFile() {
  usePageTitle('Broadflow View Shared File')

  const { id } = useParams<{ id: string }>()
  const { search } = useLocation()

  const query = Object.fromEntries(new URLSearchParams(search)) as RouteQuery
  const isValidUrl = id && !isNaN(+id) && !!query.email && !!query.signature

  if (!isValidUrl) {
    return <Navigate to="/404" replace />
  }

  return <ViewSharedFile id={+id} {...query} />
}

export default SharedFile
