import { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { AnimatePresence } from 'framer-motion'

import { ChevronDown, Close } from 'icons/outline'
import { Modal, ModalProps, Popover } from 'components'
import LoadGoogleMaps from 'shared/LoadGoogleMaps'
import ModalGuideBook from 'shared/Modals/GuideBook'
import PopoverHelpMenu from 'shared/Popovers/HelpMenu'
import { Location } from 'services/locations'

import LocationForm from './LocationForm'

interface Props extends ModalProps {
  location?: Location
  onSuccess?: (l?: Location) => void
}

function ModalCreateUpdateLocation({ location, onSuccess, ...props }: Props) {
  const { show } = useIntercom()

  const [loading, setLoading] = useState(false)
  const [showGuideBook, setShowGuideBook] = useState(false)

  const handleClose = () => {
    !loading && props.onClose()
  }

  const renderPopoverHelpMenu = (onClose: () => void) => (
    <PopoverHelpMenu
      onContactSupport={() => show()}
      onOpenGuideBook={() => setShowGuideBook(true)}
      onClose={onClose}
    />
  )

  return (
    <Modal
      {...props}
      wrapperClassName="!p-0"
      className="w-[100vw] min-h-[100vh] rounded-none !p-0"
      onClose={handleClose}
    >
      <div className="flex items-center gap-4 justify-between px-[5.3125rem] py-4 border-b border-light-stroke">
        <div className="font-medium text-base">
          {location?.id ? 'Update' : 'Create'} Location
        </div>
        <div className="text-[0.8125rem] inline-flex items-center gap-4">
          <Popover
            placement="bottom-end"
            content={onClose => renderPopoverHelpMenu(onClose)}
          >
            <div className="text-primary inline-flex items-center gap-1 mr-4 text-[0.8125rem] font-medium cursor-pointer">
              Help <ChevronDown />
            </div>
          </Popover>
          <Close className="shrink-0 cursor-pointer" onClick={handleClose} />
        </div>
      </div>
      <div className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center">
        <div className="w-[28rem] m-auto">
          <LoadGoogleMaps errorCls="text-center">
            <LocationForm
              loading={loading}
              setLoading={setLoading}
              location={location}
              onSuccess={onSuccess}
              onCloseModal={handleClose}
            />
          </LoadGoogleMaps>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {!!showGuideBook && (
          <ModalGuideBook onClose={() => setShowGuideBook(false)} />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default ModalCreateUpdateLocation
