import { useState } from 'react'
import { format } from 'date-fns'

import { Bell, Note, Share } from 'icons/filled'
import { NotifDetailIcon } from 'icons/utils'
import { Switch, Table, Tag, Tooltip } from 'components'
import { dateTimeFormat } from 'utils/dateTime'
import { isImgFile, getAttachmentName, attachmentUrl } from 'utils/file'
import { Note as INote } from 'services/notes'
import { InboundNotif } from 'services/notifications'

import PreviewMode from './PreviewMode'

interface Props {
  notif: InboundNotif
}

function DetailAndNote({ notif }: Props) {
  const [previewMode, setPreviewMode] = useState(false)

  const renderImages = () => {
    return (notif.attachments || [])
      .filter(a => isImgFile(a))
      .map(url => (
        <img
          key={url}
          alt={url}
          className="w-full max-w-[13.75rem] max-h-[7.5rem] rounded-lg object-cover"
          src={`${attachmentUrl}/${url}`}
        />
      ))
  }

  const renderTimestamp = (date?: string) => {
    return format(
      new Date(date || notif.created_at),
      `${dateTimeFormat['MMM dd, yyyy']} ${dateTimeFormat['hh:mm a']}`
    )
  }

  const renderTags = () => {
    if (!!notif.tags && !!notif.tags.length) {
      return notif.tags.map(tag => (
        <Tag
          key={tag.id}
          strokeColor={tag.stroke_color}
          textColor={tag.text_color}
          bgColor={tag.color}
        >
          {tag.name}
        </Tag>
      ))
    }
    return '---'
  }

  const renderAttachments = () => {
    if (!!notif.attachments && !!notif.attachments.length) {
      return notif.attachments.map(a => (
        <a
          key={a}
          href={`${attachmentUrl}/${a}`}
          target="_blank"
          rel="noreferrer"
          className="bg-[#efefef] py-1.5 px-2 rounded-lg inline-flex items-center gap-1"
        >
          <NotifDetailIcon
            type="attachment"
            color="#808080"
            className="w-3 h-3"
          />
          <div className="leading-none text-[0.6875rem]">
            {getAttachmentName(a)}
          </div>
        </a>
      ))
    }
    return '---'
  }

  const columns = [
    {
      title: 'timestamp',
      render: (note: INote) => renderTimestamp(note.created_at)
    },
    {
      title: 'note content',
      render: (note: INote) => (
        <div className="flex items-center gap-2">
          <div className="leading-none">{note.body}</div>
          <Tooltip content="This note was sent to all recipients of this device">
            {!!note.notify && (
              <Share className="shrink-0 mb-[3px]" type="success" />
            )}
          </Tooltip>
        </div>
      )
    },
    {
      title: 'creator',
      render: (note: INote) => note.created_by.name
    }
  ]

  if (previewMode) {
    return (
      <PreviewMode
        notif={notif}
        onPreviewModeChange={() => setPreviewMode(false)}
      />
    )
  }

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between mb-2.5">
        <div className="inline-flex items-center gap-1">
          <Bell className="w-4 h-4 shrink-0" type="primary" />
          <div className="font-semibold uppercase">Notification Detail</div>
        </div>
        <Switch checked={previewMode} onChange={setPreviewMode}>
          Preview Mode
        </Switch>
      </div>
      <div className="bg-[#fafafa] p-4 rounded-lg">
        {!!notif.attachments?.find(a => isImgFile(a)) && (
          <div className="flex gap-4 mb-5">
            <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
              <NotifDetailIcon
                type="image"
                className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
              />
              <div className="font-medium text-[0.8125rem]">Images</div>
            </div>
            <div className="inline-flex flex-wrap gap-2">{renderImages()}</div>
          </div>
        )}
        <div className="flex gap-4 mb-5">
          <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
            <NotifDetailIcon
              type="text"
              className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
            />
            <div className="font-medium text-[0.8125rem]">Title</div>
          </div>
          <div className="text-[0.8125rem]">{notif.subject}</div>
        </div>
        <div className="flex gap-4 mb-5">
          <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
            <NotifDetailIcon
              type="text"
              className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
            />
            <div className="font-medium text-[0.8125rem]">Content</div>
          </div>
          <div
            className="text-[0.8125rem]"
            dangerouslySetInnerHTML={{ __html: notif.message }}
          />
        </div>
        <div className="flex gap-4 mb-5">
          <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
            <NotifDetailIcon
              type="time"
              iconColor="#BABBBF"
              className="w-3.5 h-3.5 shrink-0"
            />
            <div className="font-medium text-[0.8125rem]">Timestamp</div>
          </div>
          <div className="text-[0.8125rem]">{renderTimestamp()}</div>
        </div>
        <div className="flex gap-4 mb-5">
          <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
            <NotifDetailIcon
              type="camera"
              className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
            />
            <div className="font-medium text-[0.8125rem]">Device Name</div>
          </div>
          <div className="text-[0.8125rem]">{notif.device?.name || '---'}</div>
        </div>
        <div className="flex gap-4 mb-5">
          <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
            <NotifDetailIcon
              type="location"
              className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
            />
            <div className="font-medium text-[0.8125rem]">Location</div>
          </div>
          <div className="text-[0.8125rem]">
            {notif.device?.location?.name || '---'}
          </div>
        </div>
        <div className="flex gap-4 mb-5">
          <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
            <NotifDetailIcon
              type="tag"
              className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
            />
            <div className="font-medium text-[0.8125rem]">Tags</div>
          </div>
          <div className="inline-flex items-center flex-wrap gap-1">
            {renderTags()}
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
            <NotifDetailIcon
              type="attachment"
              className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
            />
            <div className="font-medium text-[0.8125rem]">Attachments</div>
          </div>
          <div className="inline-flex items-center flex-wrap gap-1">
            {renderAttachments()}
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="flex items-center gap-1 mb-2.5">
          <Note className="w-4 h-4 shrink-0" type="warning" />
          <div className="font-semibold uppercase">Note</div>
        </div>
        <Table rowKey="id" data={notif.notes} columns={columns} />
      </div>
    </div>
  )
}

export default DetailAndNote
