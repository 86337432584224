import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Share(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="13"
        viewBox="0 0 14 13"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M0.58248 12.4346C0.539756 12.4346 0.496888 12.4299 0.454164 12.4189C0.190552 12.3576 0 12.132 0 11.8614V10.6947C0 6.51338 3.40203 3.11136 7.58335 3.11136H7.77775V0.583478C7.77775 0.345501 7.92244 0.131593 8.14333 0.042156C8.36336 -0.0464266 8.61615 0.00712174 8.78107 0.179018L13.8367 5.42903C14.0544 5.65462 14.0544 6.01237 13.8367 6.23795L8.78107 11.488C8.61615 11.6599 8.3618 11.7128 8.14333 11.6248C7.92244 11.5354 7.77775 11.3215 7.77775 11.0835V8.55577H6.85376C4.40691 8.55577 2.20801 9.91527 1.11455 12.1032C1.01414 12.3054 0.803367 12.4346 0.58248 12.4346ZM7.58335 4.27803C4.31662 4.27803 1.61158 6.73185 1.21566 9.89348C2.62956 8.31622 4.65728 7.3891 6.85376 7.3891H8.36108C8.68309 7.3891 8.94442 7.65043 8.94442 7.97243V9.63685L12.6069 5.83349L8.94442 2.03014V3.69469C8.94442 4.01669 8.68309 4.27803 8.36108 4.27803H7.58335Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
