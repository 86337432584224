import { Navigate, useNavigate, useParams, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import shallow from 'zustand/shallow'

import { useAsync, useLocalStorage, usePageTitle } from 'hooks'
import { Button, Spinner } from 'components'
import { EmailVerificationLink } from 'shared/EmailVerificationLink'
import { verifyEmail } from 'services/auth'
import useStore from 'store'

type RouteParams = { id: string; hash: string }
type SelectedPlan = { planId?: string; count?: string }

function VerifyEmail() {
  usePageTitle('Verify Email | Broadflow Streams Cloud Video Surveillance')

  const { search } = useLocation()
  const { id, hash } = useParams<RouteParams>()
  const navigate = useNavigate()

  const [{ planId, count }] = useLocalStorage<SelectedPlan>(
    'APP/SELECTED_PLAN',
    {}
  )
  const { execute, isLoading, isSuccess, setState } = useAsync({
    status: 'pending'
  })

  useEffect(() => {
    if (id && hash && search) {
      execute(verifyEmail({ id, hash: hash + search })).catch(console.error)
    } else {
      setState({ status: 'rejected' })
    }
  }, [])

  const handleRedirect = () => {
    if (!!planId) {
      return navigate('/checkout', {
        state: { type: 'plan', planId: +planId, count: +(count || 0) }
      })
    }
    return navigate('/')
  }

  if (isLoading) {
    return <Spinner className="w-full h-full" />
  }

  if (isSuccess) {
    localStorage.removeItem('APP/LAST_PATH')
    return (
      <div className="m-auto w-[25rem] bg-white p-6 rounded-lg">
        <EmailVerificationLink.Success
          action={
            <Button
              block
              size="large"
              variant="primary"
              onClick={handleRedirect}
            >
              {!!planId ? 'Continue to Checkout' : 'Back to Dashboard'}
            </Button>
          }
        />
      </div>
    )
  }

  return (
    <EmailVerificationLink.Expired
      titleClassName="text-[2rem] !font-normal"
      descriptionClassName="!text-base text-light-secondary"
    />
  )
}

function VerifyEmailWrapper() {
  const user = useStore(({ auth }) => auth.currentUser, shallow)

  if (!user) {
    localStorage.setItem('APP/LAST_PATH', window.location.href)
    return <Navigate to="/login" />
  }

  if (!!user.email_verified_at) {
    localStorage.removeItem('APP/LAST_PATH')
    return <Navigate to="/" />
  }

  return <VerifyEmail />
}

export default VerifyEmailWrapper
