import client from './client'

export interface BrowserSession {
  browser: string
  ip_address: string
  is_current_device: boolean
  is_desktop: boolean
  last_active: string
  last_activity_timestamp: string
  platform: string
}

export const getBrowserSessions = () => {
  return client.get<BrowserSession[]>('/api/browserSessions')
}

export const logOutBrowserSessions = () => {
  return client.delete('/api/browserSessions')
}

// ------------------------------------------------------------------------- //

export interface MobileSession {
  id: number
  last_used: string | null
  last_used_at: string | null
  name: string
}

export const getMobileSessions = () => {
  return client.get<MobileSession[]>('/api/accessTokens')
}

export const deleteMobileSession = (id: number) => {
  return client.delete(`/api/accessTokens/${id}`)
}

// ------------------------------------------------------------------------- //

export const verify2FACode = (payload: {
  code?: string
  recovery_code?: string
}) => {
  return client.post('/api/two-factor-challenge', payload)
}

export const enable2FA = () => {
  return client.post('/api/two-factor-authentication')
}

export const disable2FA = () => {
  return client.delete('/api/two-factor-authentication')
}

export const verifyPassword = (password: string) => {
  return client.post('/api/confirm-password', { password })
}

export const checkConfirmPassword = () => {
  return client.get<{ confirmed: boolean }>('/api/confirmed-password-status')
}

export const getQrCode = () => {
  return client.get<{ svg: string }>('/api/two-factor-qr-code')
}

export const getRecoveryCodes = () => {
  return client.get<string[]>('/api/two-factor-recovery-codes')
}

export const regenRecoveryCodes = () => {
  return client.post('/api/two-factor-recovery-codes')
}
