import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Filter(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <g clipPath="url(#clip0_8153_30148)">
          <path
            d="M14.2 0H1.3C0.8 0 0.5 0.3 0.5 0.8C0.5 2.4 1.2 3.9 2.4 4.9L4.8 7C5.2 7.3 5.4 7.8 5.4 8.3V13.6C5.4 13.9 5.6 14.1 5.8 14.3C5.9 14.4 6 14.4 6.2 14.4C6.3 14.4 6.5 14.4 6.6 14.3L9.8 12.1C10 12 10.1 11.7 10.1 11.5V8.3C10.1 7.8 10.3 7.3 10.7 7L13.1 4.9C14.3 3.8 15 2.3 15 0.8C14.9 0.3 14.6 0 14.2 0ZM5.8 5.9L3.4 3.8C2.7 3.2 2.3 2.4 2.1 1.5H13.3C13.1 2.4 12.7 3.2 12 3.8L9.7 5.9C9 6.5 8.6 7.4 8.6 8.3V11.1L6.9 12.2V8.3C6.9 7.4 6.5 6.5 5.8 5.9Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_8153_30148">
            <rect
              width="14.4"
              height="14.4"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
