import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Backward(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M3.5 9.13397C2.83333 9.51887 2.83333 10.4811 3.5 10.866L15.5 17.7942C16.1667 18.1791 17 17.698 17 16.9282V3.0718C17 2.302 16.1667 1.82087 15.5 2.20577L3.5 9.13397Z"
          fill="currentColor"
        />
        <rect
          x="2"
          y="18"
          width="2"
          height="16"
          rx="1"
          transform="rotate(-180 2 18)"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
