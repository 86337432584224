import Screenshot from 'assets/screenshots/LiveViewing.png'
import { ChevronRight } from 'icons/outline'

function LiveViewing() {
  return (
    <div className="p-4 grow">
      <div className="text-xl font-medium mb-4">Live Viewing</div>
      <img src={Screenshot} alt="screenshot" className="w-full h-[25.625rem]" />
      <div className="mt-12">
        <div className="text-[0.8125rem] mb-3.5">
          Live Viewing refers to viewing your camera streams in real time. This
          feature is supported on both web and mobile.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Web browsers only support the H.264 codec at this time. If your camera
          is streaming using the HEVC / H.265 codec, you will not be able to
          live view it on web. To check which codec your camera is currently
          streaming at, navigate to the “Device Details” view, and look at the
          value for “Video codec” in the “Camera Stats” section.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Contact technical support for assistance with your camera settings.
        </div>
      </div>
      <div className="mt-12">
        <div className="font-medium text-base mb-4">Get Support</div>
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/live-viewing/how-it-works"
          target="_blank"
          rel="noreferrer"
        >
          How Live Viewing Works
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/live-viewing/intro-to-dual-streaming"
          target="_blank"
          rel="noreferrer"
        >
          Using Dual Streaming to Optimize Live Viewing
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/live-viewing/google-casting-security-camera-to-android-tv"
          target="_blank"
          rel="noreferrer"
        >
          Using Google Cast to Send Live Stream to your Android TV
          <ChevronRight />
        </a>
      </div>
    </div>
  )
}

export default LiveViewing
