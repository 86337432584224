import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Box({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_8322_536836)">
          <path
            d="M8.45229 0H8.69878C8.76657 0.0347799 8.83394 0.0708019 8.90214 0.104754C11.2005 1.25525 13.4997 2.40437 15.7997 3.5521C16.0738 3.68833 16.1855 3.87175 16.1847 4.17317C16.1787 6.7168 16.1787 9.26029 16.1847 11.8036C16.1847 12.1225 16.0594 12.3137 15.7804 12.452C13.4596 13.6039 11.1414 14.7612 8.82573 15.9238C8.73545 15.9736 8.63408 15.9995 8.53116 15.9989C8.42824 15.9983 8.32716 15.9713 8.23744 15.9205C5.93276 14.7595 3.62685 13.6002 1.3197 12.4425C1.07321 12.3183 0.965167 12.1378 0.965577 11.8595C0.969686 9.28499 0.969686 6.71031 0.965577 4.1355C0.965577 3.85601 1.07485 3.68005 1.3234 3.55583C2.22363 3.1128 3.12058 2.66315 4.01425 2.20687C4.05097 2.1855 4.09282 2.17473 4.13522 2.17576C4.17761 2.17678 4.21891 2.18955 4.25457 2.21266C6.58938 3.42996 8.92542 4.6445 11.2627 5.85627C11.3744 5.91424 11.4159 5.978 11.4155 6.1047C11.4102 7.28266 11.4122 8.46063 11.4122 9.63859C11.4122 9.96983 11.4989 10.0191 11.7943 9.86383C12.1833 9.65888 12.5691 9.44689 12.9631 9.2527C13.1348 9.16989 13.2009 9.05934 13.1997 8.86432C13.1915 7.66524 13.1915 6.46644 13.1997 5.26791C13.1997 5.06544 13.136 4.96318 12.961 4.87333C10.6744 3.69854 8.38985 2.51961 6.10736 1.33654C6.0597 1.31211 6.01451 1.28313 5.94303 1.24214L8.45229 0Z"
            fill="url(#paint0_linear_8322_536836)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_8322_536836"
            x1="0.965576"
            y1="0"
            x2="0.965576"
            y2="15.9989"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[0]} />
          </linearGradient>
          <clipPath id="clip0_8322_536836">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.967529)"
            />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
