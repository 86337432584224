import {
  ArrowDown,
  ClockCircleFilled,
  Logic,
  Pencil,
  Tag,
  Text,
  Video
} from 'icons/outline'
import { VideoPlayer } from 'icons/filled'
import { Button, Tag as CTag } from 'components'

function ConditionAction() {
  return (
    <div className="relative w-full min-h-[calc(100vh_-_9.6875rem)] bg-[rgba(104,113,116,0.08)] py-6 px-48">
      <div className="relative bg-white mb-4 p-4 rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.16)]">
        <div className="flex items-center justify-between gap-2 mb-1">
          <div className="inline-flex items-center gap-2">
            <Text className="text-primary w-4 h-4 shrink-0" />
            <div className="font-medium">Name</div>
          </div>
          <Button variant="ternary">
            <Pencil /> Edit
          </Button>
        </div>
        <div className="ml-6 text-[0.8125rem]">Face Detected</div>
      </div>
      <div className="relative bg-white mb-4 p-4 rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.16)]">
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="inline-flex items-center gap-2">
            <Logic className="text-primary w-4 h-4 shrink-0" />
            <div className="font-medium">Condition & Action</div>
          </div>
          <Button variant="ternary">
            <Pencil /> Edit
          </Button>
        </div>
        <div className="ml-6 text-[0.8125rem]">
          <div className="bg-[#fafafa] py-3 px-4 rounded-lg border border-dashed border-light-stroke flex flex-wrap items-center justify-center gap-2">
            <div className="p-1.5 bg-white shadow-[inset_0px_-1px_0px_rgba(0,0,0,0.16)] rounded-lg">
              Any new notification
            </div>
            from specified
            <div className="p-1.5 bg-white shadow-[inset_0px_-1px_0px_rgba(0,0,0,0.16)] rounded-lg">
              devices
            </div>
            during specified
            <div className="p-1.5 bg-white shadow-[inset_0px_-1px_0px_rgba(0,0,0,0.16)] rounded-lg">
              durations
            </div>
          </div>
          <div className="flex justify-center my-2">
            <ArrowDown className="w-4 h-4 text-light-secondary" />
          </div>
          <div className="bg-[#fafafa] py-3 px-4 rounded-lg border border-dashed border-light-stroke flex flex-wrap items-center justify-center gap-2">
            <VideoPlayer className="shrink-0 w-4 h-4" type="info" />
            Grab a video clip & store in My Vault
          </div>
        </div>
      </div>
      <div className="relative bg-white mb-4 p-4 rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.16)]">
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="inline-flex items-center gap-2">
            <Video className="text-primary w-4 h-4 shrink-0" />
            <div className="font-medium">We will grab a video</div>
          </div>
          <Button variant="ternary">
            <Pencil /> Edit
          </Button>
        </div>
        <div className="ml-6 text-[0.8125rem]">All time</div>
      </div>
      <div className="relative bg-white mb-4 p-4 rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.16)]">
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="inline-flex items-center gap-2">
            <Tag className="text-primary w-4 h-4 shrink-0" />
            <div className="font-medium">Tag</div>
          </div>
          <Button variant="ternary">
            <Pencil /> Edit
          </Button>
        </div>
        <div className="ml-6 text-[0.8125rem] flex items-center gap-2">
          <CTag>Face Recognized</CTag>
          <CTag>Motion</CTag>
          <CTag>Low Temperature</CTag>
        </div>
      </div>
      <div className="relative bg-white mb-4 p-4 rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.16)]">
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="inline-flex items-center gap-2">
            <ClockCircleFilled className="text-primary w-4 h-4 shrink-0" />
            <div className="font-medium">Duration</div>
          </div>
          <Button variant="ternary">
            <Pencil /> Edit
          </Button>
        </div>
        <div className="ml-6 text-[0.8125rem]">All time</div>
      </div>
    </div>
  )
}

export default ConditionAction
