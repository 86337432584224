import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import { LICENSE_TAG_COLOR } from 'configs/tagColors'
import {
  Delete,
  EyeOpen,
  MinusCircle,
  More,
  Pencil,
  Play,
  Plus
} from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Popover, Tag } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalLivestream from 'shared/Modals/Players/Livestream'
import ModalEditDevice from 'shared/Modals/Device/EditDevice'
import ModalConfirmDeleteWithInput from 'shared/Modals/Confirm/ConfirmDeleteWithInput'
import toast from 'utils/toast'
import { getDeviceStatus } from 'utils/device'
import { deleteDevice, Device, updateDevice } from 'services/devices'
import useStore, { Store } from 'store'

interface Props {
  className?: string
  device: Device
}

const mapState = ({ stat, device }: Store) => ({
  stats: stat.stats,
  removeDevice: device.removeDevice,
  updateDevice: device.updateDevice,
  updateUsedCount: stat.updateUsedCount
})

function CameraDevice({ device, className }: Props) {
  const navigate = useNavigate()
  const [visible, setVisible] = useState('')

  const updateAsync = useAsync({ showNotifOnError: true })
  const deleteAsync = useAsync({ showNotifOnError: true })
  const store = useStore(mapState, shallow)

  const { used = 0, total = 0 } = store.stats?.camera_licenses || {}
  const licenseRemaining = total - used
  const { id, name, thumbnail_url, is_licensed } = device
  const status = getDeviceStatus(device)

  const handleToggleLicense = (license: boolean) => {
    setVisible('')
    updateAsync
      .execute(updateDevice(device.id, { is_licensed: license }))
      .then(() => {
        store.updateDevice(device.id, { is_licensed: license ? 1 : 0 })
        store.updateUsedCount({ camera_licenses: license ? 1 : -1 })
        toast.success(
          {
            title: license
              ? `Assigned Camera License to “${device.name}”`
              : 'Camera License Removed'
          },
          { position: 'bottom-center' }
        )
      })
  }

  const handleDeleteDevice = () => {
    setVisible('')
    deleteAsync.execute(deleteDevice(+id!))
    store.removeDevice(+id!)
    toast.success({ title: 'Device deleted' }, { position: 'bottom-center' })
  }

  const renderMoreOptions = (onClose: () => void) => {
    const onClick = (fn: Function) => () => {
      onClose()
      fn()
    }
    return (
      <Popover.Content className="w-48" onClose={onClose}>
        {!!is_licensed && status === 'online' && (
          <MenuItem
            className="mb-1 text-[0.8125rem]"
            onClick={onClick(() => setVisible('livestream'))}
          >
            <Play className="w-3.5 h-3.5 shrink-0" /> View Livestream
          </MenuItem>
        )}
        {!is_licensed && (
          <MenuItem
            className={cx(
              'mb-1 text-[0.8125rem]',
              !licenseRemaining && 'opacity-40 pointer-events-none'
            )}
            onClick={
              !!licenseRemaining
                ? onClick(() => handleToggleLicense(true))
                : undefined
            }
          >
            <Plus className="w-3.5 h-3.5 shrink-0" /> Assign License
          </MenuItem>
        )}
        <MenuItem
          className="mb-1 text-[0.8125rem]"
          onClick={onClick(() => navigate(`/devices/${id}?tab=device-details`))}
        >
          <EyeOpen className="w-3.5 h-3.5 shrink-0" /> View Device Detail
        </MenuItem>
        <MenuItem
          className="mb-1 text-[0.8125rem]"
          onClick={onClick(() => setVisible('edit'))}
        >
          <Pencil className="w-3.5 h-3.5 shrink-0" /> Edit Device
        </MenuItem>
        <div className="border-b border-light-stroke my-2" />
        {!!is_licensed && (
          <MenuItem
            className="text-[0.8125rem] text-danger"
            onClick={onClick(() => setVisible('remove-license'))}
          >
            <MinusCircle className="w-3.5 h-3.5 shrink-0" />
            Remove License
          </MenuItem>
        )}
        <MenuItem
          className="text-[0.8125rem] text-danger"
          onClick={onClick(() => setVisible('delete'))}
        >
          <Delete className="w-3.5 h-3.5 shrink-0" /> Delete Device
        </MenuItem>
      </Popover.Content>
    )
  }

  return (
    <Fragment>
      <MenuItem
        className={cx(
          'py-3 flex items-center gap-2 w-full',
          className,
          updateAsync.isLoading && 'opacity-40 !pointer-events-none'
        )}
      >
        <div className="relative w-[4.5rem] h-10 shrink-0 inline-flex items-center justify-center bg-light-hover">
          <div
            className={cx(
              'absolute w-2 h-2 top-1 right-1 rounded-full border border-white',
              status === 'online' ? 'bg-success' : 'bg-danger'
            )}
          />
          {!!thumbnail_url ? (
            <img
              src={thumbnail_url}
              alt="thumbnail"
              className="w-full h-full"
            />
          ) : (
            <div className="text-xs text-light-secondary">Offline</div>
          )}
        </div>
        <div className="text-[0.8125rem]">{name}</div>
        {!!is_licensed && <Tag {...LICENSE_TAG_COLOR}>Licensed</Tag>}
        <Popover
          className="ml-auto"
          placement="bottom-end"
          content={onClose => renderMoreOptions(onClose)}
        >
          <Button>
            <More />
          </Button>
        </Popover>
      </MenuItem>
      <AnimatePresence initial={false}>
        {visible === 'livestream' && (
          <ModalLivestream device={device} onClose={() => setVisible('')} />
        )}
        {visible === 'edit' && (
          <ModalEditDevice device={device} onClose={() => setVisible('')} />
        )}
        {visible === 'delete' && (
          <ModalConfirmDeleteWithInput
            modalTitle="Delete Device"
            warnText="All data related to this device will also be deleted"
            value={name}
            inputLabel={
              <Fragment>
                Enter device name <b>"{name}"</b> to delete
              </Fragment>
            }
            onConfirm={handleDeleteDevice}
            onClose={() => setVisible('')}
          />
        )}
        {visible === 'remove-license' && (
          <ModalConfirmDeleteWithInput
            modalTitle="Remove camera license"
            warnText="After removed the license, this device's recorded videos will be deleted"
            value={device.name}
            inputLabel={
              <Fragment>
                Enter device name <b>"{device.name}"</b> to remove license
              </Fragment>
            }
            okText="Remove License"
            onConfirm={() => handleToggleLicense(false)}
            onClose={() => setVisible('')}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default CameraDevice
