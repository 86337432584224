import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function DoubleArrowLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <g clipPath="url(#clip0_1_89)">
          <path
            d="M0.204618 7.49966L6.50038 13.7933C6.77663 14.0689 7.22421 14.0689 7.50116 13.7933C7.77741 13.5178 7.77741 13.0702 7.50116 12.7946L1.70477 7.00035L7.50046 1.20605C7.77672 0.930496 7.77672 0.482921 7.50046 0.206667C7.22421 -0.0688891 6.77594 -0.0688891 6.49968 0.206667L0.20392 6.50028C-0.0680896 6.77299 -0.0680896 7.2276 0.204618 7.49966Z"
            fill="currentColor"
          />
          <path
            d="M6.64786 7.49966L12.9436 13.7933C13.2199 14.0689 13.6674 14.0689 13.9444 13.7933C14.2207 13.5178 14.2207 13.0702 13.9444 12.7946L8.14801 7.00035L13.9437 1.20605C14.22 0.930496 14.22 0.482921 13.9437 0.206667C13.6674 -0.0688891 13.2192 -0.0688891 12.9429 0.206667L6.64716 6.50028C6.37515 6.77299 6.37515 7.2276 6.64786 7.49966Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_89">
            <rect width="14.1516" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
