import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Upload(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M8.53025 0.969687C8.46061 0.900039 8.37792 0.844791 8.28693 0.807098C8.19593 0.769405 8.0984 0.750004 7.99991 0.750004C7.90141 0.750003 7.80388 0.769405 7.71288 0.807098C7.62189 0.844791 7.53921 0.900039 7.46956 0.969687L4.64116 3.79809C4.5005 3.93874 4.42149 4.12951 4.42149 4.32842C4.42149 4.52733 4.5005 4.7181 4.64116 4.85875C4.78181 4.9994 4.97257 5.07842 5.17148 5.07842C5.3704 5.07842 5.56116 4.9994 5.70181 4.85875L7.24991 3.31066L7.24991 11.5C7.24991 11.6989 7.32892 11.8897 7.46958 12.0303C7.61023 12.171 7.80099 12.25 7.99991 12.25C8.19882 12.25 8.38958 12.171 8.53024 12.0303C8.67089 11.8897 8.74991 11.6989 8.74991 11.5L8.74991 3.31066L10.298 4.85875C10.4387 4.9994 10.6294 5.07842 10.8283 5.07842C11.0272 5.07842 11.218 4.9994 11.3587 4.85875C11.4993 4.7181 11.5783 4.52733 11.5783 4.32842C11.5783 4.12951 11.4993 3.93875 11.3587 3.79809L8.53025 0.969687Z"
          fill="currentColor"
        />
        <path
          d="M14.5 7.25C14.3011 7.25 14.1103 7.32902 13.9697 7.46967C13.829 7.61032 13.75 7.80109 13.75 8V13.75H2.25V8C2.25 7.80109 2.17098 7.61032 2.03033 7.46967C1.88968 7.32902 1.69891 7.25 1.5 7.25C1.30109 7.25 1.11032 7.32902 0.96967 7.46967C0.829018 7.61032 0.75 7.80109 0.75 8V14C0.75 14.3315 0.881696 14.6495 1.11612 14.8839C1.35054 15.1183 1.66848 15.25 2 15.25H14C14.3315 15.25 14.6495 15.1183 14.8839 14.8839C15.1183 14.6495 15.25 14.3315 15.25 14V8C15.25 7.80109 15.171 7.61032 15.0303 7.46967C14.8897 7.32902 14.6989 7.25 14.5 7.25Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
