import { Link } from 'react-router-dom'

import { Button, Form, FormField, Input, InputPassword } from 'components'
import { required, email } from 'utils/rules'

export type FormValues = {
  email: string
  password: string
}

interface Props {
  loading: boolean
  onSubmit: (values: FormValues) => void
}

function LoginForm({ loading, onSubmit }: Props) {
  const lastPath = localStorage.getItem('APP/LAST_PATH') || ''
  const cameFromVerify =
    !!lastPath &&
    typeof lastPath === 'string' &&
    lastPath.includes('verify-email')

  return (
    <div className="w-[25.625rem] m-auto bg-white p-6 rounded-lg">
      <h3 className="text-[1.3125rem] font-medium mb-2.5">
        {cameFromVerify ? 'Please login' : 'Welcome back!'}
      </h3>
      <div className="text-[1.1rem] mb-8">
        {cameFromVerify ? 'to verify your account' : 'Please login to continue'}
      </div>
      <Form onFinish={onSubmit}>
        <FormField name="email" rules={[required, email]} label="Email">
          <Input block placeholder="Enter your email" />
        </FormField>
        <FormField
          name="password"
          rules={[required]}
          label={
            <div className="w-100 flex justify-between">
              <span>Password</span>
              <Link className="text-primary font-normal" to="/reset-password">
                Forgot password?
              </Link>
            </div>
          }
        >
          <InputPassword block placeholder="Enter your password" />
        </FormField>
        <Button
          block
          type="submit"
          size="large"
          variant="primary"
          disabled={loading}
        >
          Sign In
        </Button>
      </Form>
    </div>
  )
}

export default LoginForm
