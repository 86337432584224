import { Dispatch } from 'react'
import { Link } from 'react-router-dom'
import shallow from 'zustand/shallow'

import ResourceSummaryAlt from 'shared/Cards/ResourceSummaryAlt'
import ChannelRecipientForm from 'shared/Forms/ChannelRecipientForm'
import { NotifChannel } from 'services/tags'
import useStore from 'store'

import { FormValues } from './FormHelpers'

interface Props {
  values: FormValues['recipients']
  setValues: Dispatch<Partial<FormValues>>
}

function Recipients({ values, setValues }: Props) {
  const stats = useStore(state => state.stat.stats, shallow)
  const { email, sms, whatsapp } = stats || {}

  const handleRecipientChange = (
    c: NotifChannel[],
    recs: Omit<FormValues['recipients'], 'is_blocked' | 'channels'>
  ) => {
    setValues({ recipients: { ...values, channels: c, ...recs } })
  }

  return (
    <div className="min-h-[calc(100vh_-_5.15rem)] py-4 flex flex-col justify-center">
      <div className="w-[55rem] m-auto">
        <div className="mb-10">
          <div className="text-base font-medium mb-3">Notification Channel</div>
          <div className="text-success text-[0.8125rem]">
            Number of notification you have used
          </div>
          <div className="flex items-center gap-1 mt-2">
            <ResourceSummaryAlt
              type="sms"
              title={<div className="text-light-secondary">SMS</div>}
              iconType="primary"
              iconClassName="!w-3.5 !h-3.5"
              className="!p-2 !text-[0.8125rem]"
              summary={`${Math.abs(sms?.used || 0)}/${sms?.total || 0}`}
            />
            <ResourceSummaryAlt
              type="email"
              title={<div className="text-light-secondary">Email</div>}
              iconType="primary"
              iconClassName="!w-3.5 !h-3.5"
              className="!p-2 !text-[0.8125rem]"
              summary={`${Math.abs(email?.used || 0)}/${email?.total || 0}`}
            />
            <ResourceSummaryAlt
              type="whatsapp"
              title={<div className="text-light-secondary">WhatsApp</div>}
              iconClassName="!w-3.5 !h-3.5"
              className="!p-2 !text-[0.8125rem]"
              summary={`${Math.abs(whatsapp?.used || 0)}/${
                whatsapp?.total || 0
              }`}
            />
            <Link
              to="/settings/billing/plans"
              className="ml-1 text-sm text-primary"
            >
              Upgrade plan
            </Link>
          </div>
        </div>
        <ChannelRecipientForm
          values={values}
          onChange={handleRecipientChange}
        />
      </div>
    </div>
  )
}

export default Recipients
