import { FC } from 'react'
import cx from 'classnames'

const MenuItem: FC<JSX.IntrinsicElements['div']> = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className={cx(
        'w-full flex items-center gap-2 p-2 text-sm rounded',
        'base-transition cursor-pointer hover:bg-light-hover',
        props.className
      )}
    >
      {children}
    </div>
  )
}

export default MenuItem
