import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function DoubleArrowDown(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M6.0628 12.5788L0.555852 7.06999C0.31474 6.82827 0.31474 6.43664 0.555852 6.19431C0.796963 5.95258 1.18859 5.95258 1.4297 6.19431L6.4997 11.2661L11.5697 6.19492C11.8108 5.95319 12.2024 5.95319 12.4442 6.19492C12.6853 6.43664 12.6853 6.82888 12.4442 7.0706L6.93726 12.5794C6.69865 12.8174 6.30086 12.8174 6.0628 12.5788Z"
          fill="currentColor"
        />
        <path
          d="M6.0628 6.94108L0.555852 1.43229C0.31474 1.19057 0.31474 0.798943 0.555852 0.556611C0.796963 0.314888 1.18859 0.314888 1.4297 0.556611L6.4997 5.62844L11.5697 0.557221C11.8108 0.315499 12.2024 0.315499 12.4442 0.557221C12.6853 0.798943 12.6853 1.19118 12.4442 1.4329L6.93726 6.94169C6.69865 7.1797 6.30086 7.1797 6.0628 6.94108Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
