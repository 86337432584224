import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Search({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_8557_2043)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.7656 13.7408L20.6959 18.8686C21.113 19.3024 21.0992 19.9941 20.6657 20.4112C20.4617 20.6076 20.193 20.7156 19.9097 20.7156C19.6104 20.7156 19.3311 20.5967 19.1235 20.381L14.1557 15.2143C12.7451 16.2056 11.0952 16.7278 9.36391 16.7278C4.75212 16.7278 1 12.9757 1 8.36391C1 3.75212 4.75212 0 9.36391 0C13.9757 0 17.7278 3.75212 17.7278 8.36391C17.7278 10.3378 17.0332 12.2339 15.7656 13.7408ZM15.5459 8.36391C15.5459 4.95508 12.7727 2.1819 9.36384 2.1819C5.955 2.1819 3.18182 4.95508 3.18182 8.36391C3.18182 11.7728 5.955 14.5459 9.36384 14.5459C12.7727 14.5459 15.5459 11.7728 15.5459 8.36391Z"
            fill="url(#paint0_linear_8557_2043)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_8557_2043"
            x="0"
            y="0"
            width="22"
            height="22.7156"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_8557_2043"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_8557_2043"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_8557_2043"
            x1="1"
            y1="0"
            x2="1"
            y2="20.7156"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
