import cx from 'classnames'

import { Pagination, Select } from 'components'

export type PageSizes = 10 | 20 | 50

interface Props {
  className?: string
  loading: boolean
  pageSize: PageSizes
  currentPage: number
  totalItem: number
  onPageChange: (page: number) => void
  onPageSizeChange: (size: PageSizes) => void
}

const pageSizes = {
  10: { label: 10, value: 10 },
  20: { label: 20, value: 20 },
  50: { label: 50, value: 50 }
}

function TableFooter({
  className,
  loading,
  pageSize,
  currentPage,
  totalItem,
  onPageChange,
  onPageSizeChange
}: Props) {
  const handlePageSizeChange = (v: any) => {
    !loading && onPageSizeChange(v.value)
  }

  const handlePageChange = (page: number) => {
    !loading && onPageChange(page)
  }

  return (
    <div
      className={cx(
        'flex flex-wrap gap-4 items-center justify-between',
        className
      )}
    >
      <div className="inline-flex items-center gap-2 text-[0.8125rem]">
        Show
        <Select
          menuPlacement="top"
          isSearchable={false}
          className="w-[4.5rem]"
          value={pageSizes[pageSize]}
          options={Object.values(pageSizes)}
          onChange={handlePageSizeChange}
        />
        of {totalItem} results
      </div>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalItem}
        onChange={handlePageChange}
      />
    </div>
  )
}

export default TableFooter
