import { Dispatch, Fragment, ReactNode, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'

import '../index.css'
import {
  Delete,
  DoubleArrowLeft,
  DoubleArrowRight,
  Download,
  DropdownDown,
  Fullscreen,
  FullscreenExit,
  More,
  Pencil,
  Plus
} from 'icons/outline'
import { Live } from 'icons/filled'
import { useFullscreen } from 'hooks'
import { Button, Popover, Tabs } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalEditTabName from 'shared/Modals/Tab/EditTabName'
import { ChangeLayout } from 'shared/Popovers/ChangeLayout'

type Tab = { key: string; name: string; layout: number }

interface Props {
  expanded: boolean
  onToggleExpand: () => void
  tabs: Tab[]
  activeTabKey: string
  onTabChange: (key: string) => void
  onCreateTab: () => void
  onEditTab: (tabKey: string, tab: Partial<Tab>) => void
  onTabLayoutChange: (tabKey: string, layout: number) => void
  onDeleteTab: (tabKey: string) => void
  children: (props: {
    tabKey: string
    layout: number
    exportTabKey: string
    setExportTabKey: Dispatch<React.SetStateAction<string>>
  }) => ReactNode
}

function PlaybackTabs({ expanded, tabs, activeTabKey, ...props }: Props) {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [exportTabKey, setExportTabKey] = useState('')
  const playbackRef = useRef<HTMLDivElement>(null)

  const activeTab = tabs.find(tab => tab.key === activeTabKey)!
  const { isEnabled, toggle, isFullscreen } = useFullscreen({})

  const renderLayoutOptions = (onClose: () => void) => (
    <ChangeLayout
      selectedLayout={activeTab.layout}
      onClose={onClose}
      onLayoutSelect={(selected: number) => {
        props.onTabLayoutChange(activeTabKey, selected)
      }}
    />
  )

  const renderTabOptions = (onClose: () => void, tabKey: string) => (
    <Popover.Content className="!p-3 w-[12rem]" onClose={onClose}>
      <MenuItem
        onClick={() => {
          setVisible(true)
          onClose()
        }}
      >
        <Pencil className="w-4 h-4 scale-90" /> Edit Page Name
      </MenuItem>
      {tabs.length > 1 && (
        <Fragment>
          <div className="my-1 border-b border-light-stroke" />
          <MenuItem
            className="text-danger"
            onClick={() => props.onDeleteTab(tabKey)}
          >
            <Delete className="w-4 h-4 " /> Delete Page
          </MenuItem>
        </Fragment>
      )}
    </Popover.Content>
  )

  const renderTabExtraLeft = () => (
    <div className="ml-2 mr-3 inline-flex items-center gap-2">
      <Button disabled={isFullscreen} onClick={props.onToggleExpand}>
        {expanded ? <DoubleArrowLeft /> : <DoubleArrowRight />}
      </Button>
      <Button disabled={isFullscreen} onClick={props.onCreateTab}>
        <Plus /> New
      </Button>
      <div className="border-r border-light-stroke h-[32px] ml-1" />
    </div>
  )

  const renderTabExtraRight = () => (
    <div className="inline-flex items-center gap-2 ml-3">
      <Button className="w-[11rem]" onClick={() => navigate('/livestream')}>
        <Live type="danger" className="w-[1.5rem]" /> View Livestream
      </Button>
      <Popover
        placement="bottom"
        content={onClose => renderLayoutOptions(onClose)}
      >
        <Button disabled={isFullscreen} className="w-[10rem]">
          Change Layout
          <DropdownDown className="scale-75" />
        </Button>
      </Popover>
      <Button
        disabled={isFullscreen}
        className="w-[5.6rem]"
        onClick={() => setExportTabKey(activeTabKey)}
      >
        <Download /> Export
      </Button>
      <Button
        disabled={!isEnabled}
        className="w-[7.8125rem]"
        onClick={() => toggle(playbackRef.current!)}
      >
        {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
        Fullscreen
      </Button>
    </div>
  )

  return (
    <div
      ref={playbackRef}
      className={cx('w-full h-full', expanded && '!w-[calc(100%_-_16.25rem)]')}
    >
      <Tabs
        activeKey={activeTabKey}
        onChange={props.onTabChange}
        className="playback-tabs-wrapper"
        tabBarExtraContent={{
          left: renderTabExtraLeft(),
          right: renderTabExtraRight()
        }}
      >
        {tabs.map(({ key, name, layout }) => (
          <Tabs.TabPane
            key={key}
            tab={
              <div className="group inline-flex items-center">
                <div>{name}</div>
                {key === activeTabKey && (
                  <Popover
                    className="ml-1.5"
                    placement="bottom-end"
                    content={onClose => renderTabOptions(onClose, key)}
                  >
                    <More className="w-4 h-4" />
                  </Popover>
                )}
              </div>
            }
          >
            {props.children({
              tabKey: key,
              layout,
              exportTabKey,
              setExportTabKey
            })}
          </Tabs.TabPane>
        ))}
      </Tabs>
      <AnimatePresence initial={false}>
        {visible && !!activeTab && (
          <ModalEditTabName
            value={activeTab.name}
            onEdit={value => props.onEditTab(activeTabKey, { name: value })}
            onClose={() => setVisible(false)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default PlaybackTabs
