import { Fragment, useEffect, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { format } from 'date-fns'
import cx from 'classnames'

import { Backward, DropdownDown, Forward, Pause, Play } from 'icons/outline'
import ModalSelectPlaybackTime from 'shared/Modals/Playback/SelectPlaybackTime'
import { dateTimeFormat, secondsToHms } from 'utils/dateTime'
import { MAX_VIDEO_LENGTH_SEC } from 'utils/device'

import { State, usePlaybackCtx } from '../PlaybackContext'
import { Device } from 'services/devices'

const PLAYBACK_INTERVAL = 250

interface Props {
  devices: (Device | null)[]
}
function PlaybackControls({devices} : Props) {
  const [isReady, setIsReady] = useState(false)
  const [visible, setVisible] = useState(false)

  const { status, players, currentTime, selectedTime, dispatch } =
    usePlaybackCtx()

  const intervalRef = useRef<NodeJS.Timer>()
  const progressRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsReady(!!players.length && players.every(p => p?.status !== 'loading'))
  }, [players])

  useEffect(() => {
    if (currentTime === 0) handlePause('idle')
    if (currentTime >= MAX_VIDEO_LENGTH_SEC) handlePause()
  }, [currentTime])

  useEffect(() => {
    if (!isReady && status === 'playing') handlePause('waiting')
    if (isReady && status === 'waiting') handlePlay()
  }, [isReady, status])

  const handlePlay = () => {
    if (currentTime >= MAX_VIDEO_LENGTH_SEC) {
      dispatch({ type: 'SET_PLAYBACK_TIME', payload: 0 })
    }

    dispatch({ type: 'SET_PLAYBACK_STATUS', payload: 'playing' })
    for (const player of players) {
      player?.video?.play()
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = setInterval(() => {
      dispatch({ type: 'UPDATE_CURRENT_TIME', payload: 0.25 })
    }, PLAYBACK_INTERVAL)
  }

  const handlePause = (status: State['status'] = 'paused') => {
    dispatch({ type: 'SET_PLAYBACK_STATUS', payload: status })
    for (const player of players) {
      player?.video?.pause()
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }

  const handleRewind = (sec: number) => () => {
    const newCurrentTime = currentTime + sec
    dispatch({
      type: 'SET_PLAYBACK_TIME',
      payload:
        newCurrentTime < 0
          ? 0
          : newCurrentTime > MAX_VIDEO_LENGTH_SEC
          ? MAX_VIDEO_LENGTH_SEC
          : newCurrentTime
    })
  }

  const handleSeek = (pageX: number) => {
    const { left, right } = progressRef.current!.getBoundingClientRect()
    dispatch({
      type: 'SET_PLAYBACK_TIME',
      payload:
        pageX >= right
          ? MAX_VIDEO_LENGTH_SEC
          : pageX <= left
          ? 0
          : (pageX - left) / widthPerSec
    })
  }
  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = event => {
    event.preventDefault()
    handleMouseUp(event.nativeEvent)
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
  }
  const handleMouseMove = (event: MouseEvent) => {
    event.preventDefault()
    handleSeek(event.pageX)
  }
  const handleMouseUp = (event: MouseEvent) => {
    event.preventDefault()
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', handleMouseUp)
    handleSeek(event.pageX)
  }

  const formatDateTime = (date: Date) => {
    return format(
      date,
      `${dateTimeFormat['MM-dd-yyyy']} ${dateTimeFormat['hh:mm a']}`
    )
  }

  const progressWidth = progressRef.current?.clientWidth || 0
  const widthPerSec = progressWidth / MAX_VIDEO_LENGTH_SEC
  const progress = widthPerSec * currentTime

  return (
    <div className="h-[4.0625rem] py-1 pr-3 pl-2 bg-white flex items-center">
      <div className="w-[11.5rem] shrink-0 font-medium text-base">
        Playback Mode
      </div>
      <div className="grow flex items-center">
        <div className="inline-flex items-center gap-[0.4375rem]">
          <Backward
            className={cx(
              'text-[#AEAEAE] w-5 h-5 cursor-pointer',
              !(isReady && currentTime > 0) && 'cursor-not-allowed'
            )}
            onClick={isReady && currentTime > 0 ? handleRewind(-10) : undefined}
          />
          {status === 'playing' ? (
            <Pause
              className={cx(
                'text-light-secondary w-[1.7rem] h-[1.7rem] cursor-pointer',
                !isReady && 'cursor-not-allowed'
              )}
              onClick={isReady ? () => handlePause() : undefined}
            />
          ) : (
            <Play
              className={cx(
                'text-light-secondary w-[1.7rem] h-[1.7rem] cursor-pointer',
                !isReady && 'cursor-not-allowed'
              )}
              onClick={isReady ? handlePlay : undefined}
            />
          )}
          <Forward
            className={cx(
              'text-[#AEAEAE] w-5 h-5 cursor-pointer',
              !(isReady && currentTime < MAX_VIDEO_LENGTH_SEC) &&
                'cursor-not-allowed'
            )}
            onClick={
              isReady && currentTime < MAX_VIDEO_LENGTH_SEC
                ? handleRewind(10)
                : undefined
            }
          />
        </div>
        <div
          ref={progressRef}
          onMouseDown={handleMouseDown}
          className="relative mx-[1.1rem] w-[25rem] h-[4px] bg-[rgba(145,145,145,.3)] rounded-sm cursor-pointer"
        >
          <div
            style={{ width: progress }}
            className="w-full h-full bg-[#7c80de] rounded-sm pointer-events-none"
          />
          <div
            style={{ left: `calc(${progress}px - 5px)` }}
            className="absolute top-[-5px] w-3.5 h-3.5 bg-primary-gradient rounded-full pointer-events-none"
          />
        </div>
        <div className="text-sm">{secondsToHms(currentTime)}</div>
        <div
          onClick={() => setVisible(true)}
          className="base-transition h-[3.5625rem] ml-auto cursor-pointer border border-light-stroke py-[5px] px-2 flex items-center gap-4 rounded-lg hover:border-primary"
        >
          <div className="min-w-[16.4375rem]">
            <div className="mb-px text-sm font-medium">Selected Time Range</div>
            <div className="inline-block text-xs">
              {!!selectedTime.length ? (
                <Fragment>
                  {formatDateTime(selectedTime[0])} {' - '}
                  {formatDateTime(selectedTime[1])}
                </Fragment>
              ) : (
                '----'
              )}
            </div>
          </div>
          <DropdownDown className="w-4 shrink-0" />
        </div>
      </div>
      <AnimatePresence initial={false}>
        {visible && (
          <ModalSelectPlaybackTime
            timeRange={selectedTime}
            devices={devices}
            onChange={value =>
              dispatch({ type: 'SET_TIME_RANGE', payload: value })
            }
            onClose={() => setVisible(false)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default PlaybackControls
