import { FC, SVGProps } from 'react'

import {
  RecordStart,
  RecordStop,
  WifiOn,
  WifiOff,
  Delete,
  Plus,
  Pencil,
  Login
} from 'icons/outline'

type Props = {
  log: string
} & SVGProps<SVGSVGElement>

export function ActivityLogIcon({ log, ...props }: Props) {
  let Icon: FC<any> | null = null

  if (log.endsWith('started')) {
    Icon = RecordStart
  }
  if (log.endsWith('stopped')) {
    Icon = RecordStop
  }
  if (log.endsWith('online')) {
    Icon = WifiOn
  }
  if (log.endsWith('offline')) {
    Icon = WifiOff
  }
  if (log.startsWith('deleted')) {
    Icon = Delete
  }
  if (log.startsWith('created')) {
    Icon = Plus
  }
  if (log.startsWith('updated')) {
    Icon = Pencil
  }
  if (log.startsWith('Login')) {
    Icon = Login
  }

  return Icon ? <Icon {...props} /> : null
}
