import Screenshot from 'assets/screenshots/DeviceNotification.png'
import { ChevronRight } from 'icons/outline'

function Notifications() {
  return (
    <div className="p-4 grow">
      <div className="text-xl font-medium mb-4">Notifications</div>
      <img src={Screenshot} alt="screenshot" className="w-full h-[25.625rem]" />
      <div className="mt-12">
        <div className="font-medium text-base mb-4">
          What are Device Notifications?
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Use the Device Notifications tab to view your device notification
          history.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Use the filter tool to group notifications by device, date range and
          tags.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          The Add Note feature provides you with the means to log the context of
          a given notification and includes the option to alert its original
          recipients as needed.
        </div>
      </div>
      <div className="mt-12">
        <div className="font-medium text-base mb-4">Get Support</div>
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/notifications/what-are-device-notifications"
          target="_blank"
          rel="noreferrer"
        >
          What are device notifications?
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/notifications/how-do-device-notifications-work"
          target="_blank"
          rel="noreferrer"
        >
          How do device notifications work?
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/notifications/what-are-system-notifications"
          target="_blank"
          rel="noreferrer"
        >
          What are system notifications?
          <ChevronRight />
        </a>
      </div>
    </div>
  )
}

export default Notifications
