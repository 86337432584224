import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'

import { New } from 'icons/filled'
import { Bridge, Plus, Search } from 'icons/outline'
import { Button, Collapse, Input } from 'components'
import ModalSetupBridge from 'shared/Modals/Bridge/SetupBridge'
import ModalSelectBridgeCount from 'shared/Modals/Billing/SelectBridgeCount'
import { getBridgeName, groupDevicesByLocation } from 'utils/device'
import { Bridge as IBridge } from 'services/bridges'

interface Props {
  activeBridgeId?: number
  setActiveBridgeId: (id: number) => void
  pendingBridges: IBridge[]
  activatedBridges: IBridge[]
}

function SideList({
  activeBridgeId,
  setActiveBridgeId,
  pendingBridges,
  activatedBridges
}: Props) {
  const [search, setSearch] = useState('')
  const [setupBridge, setSetupBridge] = useState<IBridge>()
  const [visible, setVisible] = useState(false)

  const renderPendingBridges = () => {
    return (
      <div className="relative text-[.8125rem] border border-[#eee] rounded-lg p-4 pt-2 shadow-[0_1px_2px_rgba(0,0,0,0.16)]">
        <div className="font-medium mb-4">Pending Activation</div>
        <div className="absolute top-0 right-0">
          <New type="primary" />
        </div>
        {pendingBridges.map(bridge => (
          <div
            key={bridge.id}
            className="flex items-center justify-between gap-1 border-b border-dashed border-light-stroke pb-2 mb-2 last:border-0 last:pb-0 last:mb-0"
          >
            <div className="inline-flex items-center gap-2">
              <Bridge className="w-4 h-4 shrink-0 mt-px" color="#bdbdbd" />
              {getBridgeName(bridge)}
            </div>
            {!!bridge.is_provisioned && !bridge.name ? (
              <div
                className="font-medium text-primary underline cursor-pointer hover:no-underline"
                onClick={() => setSetupBridge(bridge)}
              >
                Setup
              </div>
            ) : (
              <div className="text-light-secondary text-xs">Provisioning</div>
            )}
          </div>
        ))}
      </div>
    )
  }

  const renderBridges = (bridge: IBridge) => {
    return (
      <div
        key={bridge.id}
        onClick={() => setActiveBridgeId(bridge.id)}
        className={cx(
          'base-transition py-2 px-4 flex gap-2 rounded-lg border-b border-dashed border-light-stroke cursor-pointer hover:bg-light-hover last:border-0',
          activeBridgeId === bridge.id && 'bg-light-bg'
        )}
      >
        <Bridge
          className={cx(
            'w-4 h-4 shrink-0 mt-px',
            bridge.is_online ? 'text-success' : 'text-danger'
          )}
        />
        <div className="grow text-[0.8125rem]">
          <div className="mb-1">{getBridgeName(bridge)}</div>
          <div className="text-light-secondary">
            {bridge.devices_count}/{bridge.channel} cameras added
          </div>
        </div>
      </div>
    )
  }

  const groupedActiveBridges = groupDevicesByLocation(activatedBridges)

  return (
    <div className="w-[19.5625rem] shrink-0 border-r border-light-stroke">
      <div className="m-4">
        <Button block onClick={() => setVisible(true)}>
          <Plus /> Purchare Bridge
        </Button>
        <div className="mt-3 mb-4">
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            suffix={<Search />}
            placeholder="Search location by name"
          />
        </div>
        {!!pendingBridges.length && renderPendingBridges()}
        {!!pendingBridges.length && !!activatedBridges.length && (
          <div className="border-b border-light-stroke my-4" />
        )}
        {!!activatedBridges.length && (
          <PerfectScrollbar
            options={{ wheelPropagation: false }}
            className="h-[calc(100vh_-_27.1875rem)]"
          >
            {Object.keys(groupedActiveBridges).map((key, idx, self) => (
              <Collapse
                key={key}
                defaultActive
                className={cx(
                  'border-b border-light-stroke pb-3 mb-3',
                  idx + 1 === self.length && '!mb-0 !pb-0 !border-0'
                )}
                panelClassName="font-medium !p-0 text-[.8125rem]"
                contentClassName="pt-1.5 px-0 pb-0"
                panel={groupedActiveBridges[key].locationName}
              >
                {groupedActiveBridges[key].devices.map(renderBridges)}
              </Collapse>
            ))}
          </PerfectScrollbar>
        )}
      </div>
      <AnimatePresence initial={false}>
        {visible && (
          <ModalSelectBridgeCount onClose={() => setVisible(false)} />
        )}
        {!!setupBridge && (
          <ModalSetupBridge
            bridge={setupBridge}
            onClose={() => setSetupBridge(undefined)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default SideList
