import PerfectScrollbar from 'react-perfect-scrollbar'
import cx from 'classnames'

import { Popover } from 'components'
import MenuItem from 'shared/MenuItem'
import { lead0 } from 'utils/functions'

interface Props {
  hour?: number
  minute?: number
  onChange?: (value: number, type: 'hour' | 'minute') => void
  onClose: () => void
}

function Picker({ hour, minute, onChange, onClose }: Props) {
  return (
    <Popover.Content onClose={onClose} className="w-fit h-[13.75rem] p-0 pt-1">
      <div className="flex h-full overflow-hidden">
        <PerfectScrollbar
          options={{ wheelPropagation: false }}
          className="w-[3.75rem] shrink-0"
        >
          {Array.from({ length: 24 }).map((_, idx) => (
            <MenuItem
              key={idx}
              onClick={() => onChange?.(idx, 'hour')}
              className={cx(
                'mb-1 h-[1.75rem] cursor-pointer justify-center',
                idx === hour && '!bg-primary-lighten text-primary'
              )}
            >
              {lead0(idx)}
            </MenuItem>
          ))}
        </PerfectScrollbar>
        <div className="border-r border-light-stroke" />
        <PerfectScrollbar
          options={{ wheelPropagation: false }}
          className="w-[3.75rem] shrink-0"
        >
          {Array.from({ length: 60 }).map((_, idx) => (
            <MenuItem
              key={idx}
              onClick={() => onChange?.(idx, 'minute')}
              className={cx(
                'mb-1 h-[1.75rem] cursor-pointer justify-center',
                idx === minute && '!bg-primary-lighten text-primary'
              )}
            >
              {lead0(idx)}
            </MenuItem>
          ))}
        </PerfectScrollbar>
      </div>
    </Popover.Content>
  )
}

export default Picker
