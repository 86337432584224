import { useEffect, useState } from 'react'

declare global {
  interface Document {
    webkitFullscreenEnabled?: boolean
    webkitFullscreenElement?: Element
    webkitExitFullscreen?: () => Promise<void>
    mozFullScreenEnabled?: boolean
    mozFullScreenElement?: Element
    mozCancelFullScreen?: () => Promise<void>
    msFullscreenEnabled?: boolean
    msFullscreenElement?: Element
    msExitFullscreen?: () => Promise<void>
  }
  interface HTMLElement {
    webkitRequestFullscreen?: () => Promise<void>
    mozRequestFullScreen?: () => Promise<void>
    msRequestFullscreen?: () => Promise<void>
  }
}

type MethodMap = {
  isEnabled:
    | 'fullscreenEnabled'
    | 'webkitFullscreenEnabled'
    | 'mozFullScreenEnabled'
    | 'msFullscreenEnabled'
  request:
    | 'requestFullscreen'
    | 'webkitRequestFullscreen'
    | 'mozRequestFullScreen'
    | 'msRequestFullscreen'
  exit:
    | 'exitFullscreen'
    | 'webkitExitFullscreen'
    | 'mozCancelFullScreen'
    | 'msExitFullscreen'
  change:
    | 'fullscreenchange'
    | 'webkitfullscreenchange'
    | 'mozfullscreenchange'
    | 'MSFullscreenChange'
  isFullscreen:
    | 'fullscreenElement'
    | 'webkitFullscreenElement'
    | 'mozFullScreenElement'
    | 'msFullscreenElement'
}

type MethodMapKey = keyof MethodMap & string

const methodMap = {
  isEnabled: [
    'fullscreenEnabled',
    'webkitFullscreenEnabled',
    'mozFullScreenEnabled',
    'msFullscreenEnabled'
  ],
  request: [
    'requestFullscreen',
    'webkitRequestFullscreen',
    'mozRequestFullScreen',
    'msRequestFullscreen'
  ],
  exit: [
    'exitFullscreen',
    'webkitExitFullscreen',
    'mozCancelFullScreen',
    'msExitFullscreen'
  ],
  change: [
    'fullscreenchange',
    'webkitfullscreenchange',
    'mozfullscreenchange',
    'MSFullscreenChange'
  ],
  isFullscreen: [
    'fullscreenElement',
    'webkitFullscreenElement',
    'mozFullScreenElement',
    'msFullscreenElement'
  ]
}

const defaultEl = document.body

const fullscreenAPI = (() => {
  const output: MethodMap = {
    isEnabled: 'fullscreenEnabled',
    request: 'requestFullscreen',
    exit: 'exitFullscreen',
    change: 'fullscreenchange',
    isFullscreen: 'fullscreenElement'
  }

  for (const key of Object.keys(methodMap)) {
    for (const method of methodMap[key as MethodMapKey]) {
      if (key === 'request' && method in defaultEl) {
        // @ts-ignore
        output.request = method
        break
      }
      if (method in document) {
        // @ts-ignore
        output[key] = method
        break
      }
    }
  }

  return output
})()

const isFullscreen = () => {
  return !!document[fullscreenAPI.isFullscreen]
}

interface Props {
  onChange?: (e: Event) => void
}

export function useFullscreen({ onChange }: Props) {
  const [fullscreen, setFullscreen] = useState(isFullscreen)

  useEffect(() => {
    const handler = (e: Event) => {
      setFullscreen(isFullscreen())
      onChange?.(e)
    }
    document.addEventListener(fullscreenAPI.change, handler, false)
    return () => {
      document.removeEventListener(fullscreenAPI.change, handler, false)
    }
  }, [])

  const request = async (el: HTMLElement, options?: FullscreenOptions) => {
    return el[fullscreenAPI.request]?.(options)
  }

  const exit = async () => {
    return document[fullscreenAPI.exit]?.()
  }

  const toggle = async (el: HTMLElement, options?: FullscreenOptions) => {
    return fullscreen ? exit() : request(el, options)
  }

  return {
    isEnabled: !!document[fullscreenAPI.isEnabled],
    isFullscreen: fullscreen,
    request,
    exit,
    toggle
  }
}
