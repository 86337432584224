import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Warning({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M6 2.5L8 1.5L9.5 2L15.5 12L15 14L13.5 15H3L1 14L0.5 12L6 2.5Z"
          fill="url(#paint0_linear_1_1611)"
        />
        <path
          d="M7.99981 11.2783C7.5525 11.2783 7.17755 11.6532 7.17755 12.1005C7.17755 12.5478 7.5525 12.9228 7.99981 12.9228C8.43067 12.9228 8.82206 12.5478 8.80233 12.1203C8.82206 11.6499 8.4504 11.2783 7.99981 11.2783Z"
          fill="white"
        />
        <path
          d="M15.6107 14.1924C16.127 13.301 16.1303 12.2387 15.6172 11.3506L10.4666 2.43074C9.95679 1.53283 9.03585 1 8.00309 1C6.97033 1 6.0494 1.53611 5.53959 2.42745L0.382368 11.3572C-0.130724 12.2551 -0.127435 13.3241 0.392235 14.2154C0.905326 15.0969 1.82297 15.6264 2.84915 15.6264H13.1373C14.1668 15.6264 15.091 15.0903 15.6107 14.1924ZM14.4924 13.5477C14.2062 14.0411 13.6997 14.3338 13.134 14.3338H2.84586C2.28673 14.3338 1.7835 14.0477 1.50393 13.5642C1.22107 13.0741 1.21779 12.4886 1.50064 11.9953L6.65787 3.06881C6.93744 2.57874 7.43737 2.28931 8.00309 2.28931C8.56552 2.28931 9.06874 2.58203 9.34831 3.0721L14.5022 11.9986C14.7785 12.4788 14.7752 13.0576 14.4924 13.5477Z"
          fill="url(#paint1_linear_1_1611)"
        />
        <path
          d="M7.79592 5.506C7.40452 5.61783 7.16113 5.97305 7.16113 6.40391C7.18087 6.66375 7.19731 6.92687 7.21705 7.18671C7.27296 8.17671 7.32887 9.14698 7.38479 10.137C7.40452 10.4725 7.66436 10.7159 7.99984 10.7159C8.33532 10.7159 8.59845 10.456 8.61489 10.1172C8.61489 9.91333 8.61489 9.72585 8.63463 9.51864C8.67081 8.88385 8.71027 8.24907 8.74645 7.61428C8.76619 7.20315 8.80237 6.79202 8.8221 6.38089C8.8221 6.23288 8.80237 6.10132 8.74645 5.96976C8.57871 5.60138 8.18732 5.41391 7.79592 5.506Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_1611"
            x1="8"
            y1="1.5"
            x2="8"
            y2="15"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_1611"
            x1="8"
            y1="1"
            x2="8"
            y2="15.6264"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
