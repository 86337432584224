import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Attachment(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M3.17996 14C3.13643 14 3.09179 13.9991 3.0474 13.9975C2.23553 13.9654 1.44745 13.6239 0.885291 13.062C-0.294996 11.8815 -0.295097 9.96189 0.884989 8.78125L6.75599 2.91115C7.50483 2.16231 8.72356 2.16221 9.47285 2.911C10.222 3.66134 10.222 4.88088 9.47285 5.62971L5.42908 9.67302L4.43749 8.68138L8.48135 4.63787C8.68431 4.43537 8.68421 4.10615 8.48115 3.90259C8.27926 3.70094 7.94953 3.70089 7.74733 3.90289L1.87673 9.77294C1.24323 10.4064 1.24338 11.4367 1.87704 12.0702C2.1927 12.3866 2.64018 12.5777 3.10348 12.596C3.56536 12.6145 3.97989 12.4628 4.27379 12.17L10.1565 6.28715C10.6894 5.75331 11.0076 5.00528 11.0281 4.23423C11.0487 3.45441 10.7743 2.73883 10.2557 2.21975C9.16504 1.13041 7.17918 1.13041 6.08949 2.21975L2.03797 6.27163L1.04612 5.27978L5.0982 1.22796C6.73554 -0.409278 9.60884 -0.409328 11.247 1.22786C12.0409 2.02199 12.4612 3.10292 12.4295 4.27252C12.3993 5.39799 11.9321 6.49373 11.1482 7.27849L5.26558 13.1614C4.72152 13.7043 3.98523 14 3.17996 14Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
