import ServerError from 'assets/images/Server-Error.png'
import { ClockCircle, Email } from 'icons/outline'

function InternalServerError() {
  return (
    <div className="w-[35rem] m-auto">
      <img className="d-block w-40" src={ServerError} alt="500" />
      <div className="mt-10">
        <div className="text-[1.75rem] font-medium mb-4">
          Well, this is embarrassing...
        </div>
        <div className="text-sm">
          <div className="mb-6">
            Sorry, this is not working properly. We now know about this mistake
            and are working to fix it
          </div>
          <div className="mb-3">In the meantime, here are what you can do:</div>
          <div className="mb-3 flex items-center gap-2">
            <ClockCircle className="w-3.5 h-3.5 shrink-0" />
            <div>
              <span className="font-medium">Try again</span> in 30 minutes
            </div>
          </div>
          <div className="mb-3 flex items-center gap-2">
            <Email className="w-3.5 h-3.5 shrink-0" />
            <div>
              <span className="font-medium">Email us at </span>
              <a
                href="mailto:support@broadflow.com"
                className="text-primary font-medium"
              >
                support@broadflow.com
              </a>{' '}
              and the us what happened
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InternalServerError
