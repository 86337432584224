import { Modal, ModalProps } from 'components'

interface Props extends ModalProps {
  url: string
}

function ModalPlayback({ url, ...props }: Props) {
  return (
    <Modal
      {...props}
      className="!p-0  w-[57vw] h-[55vh] bg-black overflow-hidden"
    >
      <video className="w-full h-full rounded-lg" controls muted src={url} />
    </Modal>
  )
}

export default ModalPlayback
