import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function WarningCircle({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx="14" cy="14" r="14" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 0C6.2685 0 0 6.2685 0 14C0 21.7315 6.2685 28 14 28C21.7315 28 28 21.7315 28 14C28 6.2685 21.7315 0 14 0ZM14 22.9367C12.9056 22.9367 12.1566 22.0932 12.1566 21C12.1566 19.8753 12.9371 19.0633 14 19.0633C15.1235 19.0633 15.8433 19.8753 15.8433 21C15.8433 22.0943 15.1235 22.9367 14 22.9367ZM13.2755 15.5412C13.5683 16.5083 14.4468 16.492 14.7256 15.5412C15.0161 14.5518 16.2785 10.2083 16.2796 7.44217C16.2796 3.79634 11.7401 3.81384 11.7401 7.44217C11.7401 10.192 12.9383 14.4258 13.2755 15.5412Z"
          fill="url(#paint0_linear_875_163)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_875_163"
            x1="14"
            y1="0"
            x2="14"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
