import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function DropdownDown(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 6L10 0H0"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
