import { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import { LICENSE_TAG_COLOR } from 'configs/tagColors'
import { DeviceIcon } from 'icons/utils'
import { WarningCircle } from 'icons/filled'
import { Bridge, EyeOpen, More, Pencil, Play } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Dot, Popover, Tag, Tooltip } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalLivestream from 'shared/Modals/Players/Livestream'
import ModalConfirmDeleteWithInput from 'shared/Modals/Confirm/ConfirmDeleteWithInput'
import ModalEditDevice from 'shared/Modals/Device/EditDevice'
import toast from 'utils/toast'
import { getDeviceStatus } from 'utils/device'
import { deleteDevice, Device, updateDevice } from 'services/devices'
import useStore, { Store } from 'store'

type Props = {
  device: Device
} & JSX.IntrinsicElements['div']

const mapState = ({ stat, device, bridge }: Store) => ({
  stats: stat.stats,
  removeDevice: device.removeDevice,
  updateDevice: device.updateDevice,
  updateUsedCount: stat.updateUsedCount,
  bridges: bridge.bridges,
  removeConnectedCamera: bridge.removeConnectedCamera
})

function CameraDeviceCard({ device, ...props }: Props) {
  const navigate = useNavigate()
  const [showMore, setShowMore] = useState(false)
  const [visible, setVisible] = useState('')
  const store = useStore(mapState, shallow)

  const { isLoading, execute } = useAsync<AxiosResponse<{ data: Device }>>({
    showNotifOnError: true
  })

  const { used = 0, total = 0 } = store.stats?.camera_licenses || {}
  const licenseRemaining = total - used

  const status = getDeviceStatus(device)
  const isLicensed = device.is_licensed
  const isRecording = device.deviceLiveStream?.is_recording
  const bridge = store.bridges.find(b => b.id === device.bridge_id)
  const detailPath = `/devices/${device.id}?tab=device-details`
  const loading = isLoading || device.__loading

  const handleToggleLicense = (license: boolean) => {
    setVisible('')
    execute(updateDevice(device.id, { is_licensed: license })).then(() => {
      store.updateDevice(device.id, { is_licensed: license ? 1 : 0 })
      store.updateUsedCount({ camera_licenses: license ? 1 : -1 })
      toast.success(
        {
          title: license
            ? `Assigned Camera License to “${device.name}”`
            : 'Camera License Removed'
        },
        { position: 'bottom-center' }
      )
    })
  }

  const handleToggleRecording = (recording: boolean) => {
    execute(updateDevice(device.id, { enable_recording: recording }))
  }

  const handleDelete = () => {
    setVisible('')
    deleteDevice(device.id)
    store.removeDevice(device.id)
    bridge && store.removeConnectedCamera(bridge.id, device.id)
    toast.success({ title: 'Device Deleted' }, { position: 'bottom-center' })
  }

  const renderOptions = (onClose: () => void) => {
    const onClick = (fn: Function) => () => {
      fn()
      onClose()
    }
    return (
      <Popover.Content className="!p-2 w-60" onClose={onClose}>
        <MenuItem
          className="text-[0.8125rem]"
          onClick={() => navigate(detailPath)}
        >
          <EyeOpen className="w-3.5 h-3.5" /> View Detail
        </MenuItem>
        <MenuItem
          className="text-[0.8125rem]"
          onClick={onClick(() => setVisible('edit'))}
        >
          <Pencil className="w-3.5 h-3.5 scale-[.8]" /> Edit Device
        </MenuItem>
        <div className="border-b border-light-stroke my-[2px]" />
        {!isLicensed && (
          <MenuItem
            className={cx(
              'text-[0.8125rem]',
              !licenseRemaining && 'opacity-40 pointer-events-none'
            )}
            onClick={
              !!licenseRemaining
                ? onClick(() => handleToggleLicense(true))
                : undefined
            }
          >
            <div className="w-3.5" />
            Assign Camera License
          </MenuItem>
        )}
        {isRecording && (
          <MenuItem
            className="text-[0.8125rem]"
            onClick={onClick(() => handleToggleRecording(false))}
          >
            <div className="w-3.5" />
            Turn Off Recording
          </MenuItem>
        )}
        {!isRecording && (
          <MenuItem
            className={cx(
              'text-[0.8125rem]',
              !isLicensed && 'opacity-40 pointer-events-none'
            )}
            onClick={onClick(() => handleToggleRecording(true))}
          >
            <div className="w-3.5" />
            Turn On Recording
          </MenuItem>
        )}
        <div className="border-b border-light-stroke my-[2px]" />
        {!!isLicensed && (
          <MenuItem
            className="text-[0.8125rem] text-danger"
            onClick={onClick(() => setVisible('remove-license'))}
          >
            <div className="w-3.5" />
            Unassign Camera License
          </MenuItem>
        )}
        <MenuItem
          className="text-[0.8125rem] text-danger"
          onClick={onClick(() => setVisible('delete'))}
        >
          <div className="w-3.5" />
          Delete Device
        </MenuItem>
      </Popover.Content>
    )
  }

  const renderNolicencedMessage = () => {
    return (
      <div className="text-center">
        <div className="mb-2">Device has no licence</div>
        {!!licenseRemaining ? (
          <Button onClick={() => handleToggleLicense(true)}>
            Assign License
          </Button>
        ) : (
          <Button
            className="text-light-primary"
            onClick={() => navigate('/settings/billing/plans')}
          >
            Buy License
          </Button>
        )}
      </div>
    )
  }

  const renderStreamErrorMessage = () => (
    <div className="max-w-[22.5rem] break-words">{device.stream_error}</div>
  )

  return (
    <Fragment>
      <div
        {...props}
        className={cx(
          'group relative w-full rounded-lg shadow-[0_1px_2px_rgba(0,0,0,0.16)]',
          status === 'offline' && 'border border-danger',
          loading && 'pointer-events-none',
          props.className
        )}
      >
        <div className="h-[10.5rem] bg-black flex items-center justify-center rounded-t-lg relative">
          <div className="absolute top-2 left-3 inline-flex items-center gap-2">
            <Dot
              type={status === 'online' ? 'success' : 'danger'}
              className="w-3 h-3 border"
            />
            {!!isLicensed && <Tag {...LICENSE_TAG_COLOR}>Licensed</Tag>}
            {!!bridge && (
              <Tooltip
                content={
                  <Fragment>
                    This camera is connect to{' '}
                    <span className="font-medium">{bridge.name}</span>
                  </Fragment>
                }
              >
                <div className="bg-white p-1.5 rounded-3xl border border-light-stroke">
                  <Bridge className="text-light-secondary" />
                </div>
              </Tooltip>
            )}
          </div>
          <div
            className={cx(
              'base-transition absolute top-2 right-3 opacity-0 group-hover:opacity-100',
              showMore && '!opacity-100'
            )}
          >
            <div className="flex items-center gap-2">
              {status === 'online' && (
                <Button onClick={() => setVisible('live')}>
                  <Play /> View Live
                </Button>
              )}
              <Popover
                placement="right-start"
                onVisibleChange={setShowMore}
                content={renderOptions}
              >
                <Button children={<More />} />
              </Popover>
            </div>
          </div>
          {!!isLicensed && !!device.thumbnail_url && status === 'online' ? (
            <img
              className="w-full h-full rounded-t-lg"
              src={device.thumbnail_url}
              alt="thumbnail"
            />
          ) : (
            <span className="text-light-secondary text-[0.8125rem]">
              {!!isLicensed && status === 'offline' && 'Device is offline'}
              {!!isLicensed && status === 'online' && 'No preview'}
              {!isLicensed && renderNolicencedMessage()}
            </span>
          )}
        </div>
        <div
          className={cx(
            'p-3 rounded-b-lg',
            status === 'offline' ? 'bg-[#FFEDED]' : 'bg-white'
          )}
        >
          <div className="mb-1.5 font-medium flex items-center gap-1.5">
            <DeviceIcon type={device.type} className="w-5 h-5 text-[#dedede]" />
            <Link to={detailPath} className="hover:underline">
              {device.name}
            </Link>
          </div>
          <div className="text-[0.8125rem] text-light-secondary pl-7 flex items-center gap-2">
            <span>Recording</span>
            <span
              className={cx(
                'font-bold uppercase',
                isRecording ? 'text-success' : 'text-danger'
              )}
            >
              {isRecording ? 'on' : 'off'}
            </span>
            {device.stream_error && (
              <Tooltip content={renderStreamErrorMessage()} className="ml-auto">
                <WarningCircle className="w-4 h-4" type="danger" />
              </Tooltip>
            )}
          </div>
        </div>
        {loading && (
          <div className="absolute top-0 left-0 bg-white/90 w-full h-full rounded-lg">
            <div className="flex h-full justify-center items-center">
              Please wait...
            </div>
          </div>
        )}
      </div>
      <AnimatePresence initial={false}>
        {visible === 'live' && (
          <ModalLivestream device={device} onClose={() => setVisible('')} />
        )}
        {visible === 'edit' && (
          <ModalEditDevice device={device} onClose={() => setVisible('')} />
        )}
        {visible === 'delete' && (
          <ModalConfirmDeleteWithInput
            modalTitle="Delete Device"
            warnText="After removed the device, all data of this device will be deleted"
            value={device.name}
            inputLabel={
              <Fragment>
                Enter device name <b>"{device.name}"</b> to delete
              </Fragment>
            }
            onConfirm={handleDelete}
            onClose={() => setVisible('')}
          />
        )}
        {visible === 'remove-license' && (
          <ModalConfirmDeleteWithInput
            modalTitle="Remove camera license"
            warnText="After removed the license, this device's recorded videos will be deleted"
            value={device.name}
            inputLabel={
              <Fragment>
                Enter device name <b>"{device.name}"</b> to remove license
              </Fragment>
            }
            okText="Remove License"
            onConfirm={() => handleToggleLicense(false)}
            onClose={() => setVisible('')}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default CameraDeviceCard
