import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function ChevronRight(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path d="M7.79538 7.49966L1.49962 13.7933C1.22337 14.0689 0.77579 14.0689 0.498838 13.7933C0.222585 13.5178 0.222585 13.0702 0.498838 12.7946L6.29523 7.00035L0.499537 1.20605C0.223283 0.930496 0.223283 0.482921 0.499537 0.206667C0.77579 -0.0688891 1.22406 -0.0688891 1.50032 0.206667L7.79608 6.50028C8.06809 6.77299 8.06809 7.2276 7.79538 7.49966Z" />
      </svg>
    </SVGUniqueID>
  )
}
