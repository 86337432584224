export const stepLabels = [
  'Enter Schedule Details',
  'Add Notification Channels'
]

export interface OptionValue {
  label: string
  value: number
}

export interface FormValues {
  scheduleDetails: {
    name: string
    is_24_7: boolean
    start_time: string
    end_time: string
    days: string[]
  }
  recipients: {
    channels: string[]
    webhook: string
    slack_webhook: string
    teams_webbhook: string
    pagerduty_integration_key: string
    email_recipients: OptionValue[]
    phone_recipients: OptionValue[]
    whatsapp_recipients: OptionValue[]
  }
}

export type FormKey = keyof FormValues & string

export const initFormValues: FormValues = {
  scheduleDetails: {
    name: '',
    is_24_7: false,
    start_time: '',
    end_time: '',
    days: []
  },
  recipients: {
    channels: [],
    webhook: '',
    slack_webhook: '',
    teams_webbhook: '',
    pagerduty_integration_key: '',
    email_recipients: [],
    phone_recipients: [],
    whatsapp_recipients: []
  }
}
