import cx from 'classnames'

import { Tag } from 'icons/filled'

function Popular({ className, ...props }: JSX.IntrinsicElements['div']) {
  return (
    <div
      {...props}
      className={cx(
        'bg-warning rounded-lg flex items-center justify-center gap-1 px-2 py-[5px]',
        className
      )}
    >
      <Tag type="primary" className="w-3.5 h-3.5 shrink-0" />
      <div className="text-xs leading-none text-primary font-semibold">
        Popular
      </div>
    </div>
  )
}

export default Popular
