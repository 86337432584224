import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'

import { useAsync } from 'hooks'
import { Plus } from 'icons/outline'
import { Button, Spinner, Tag } from 'components'
import ModalCreateNewTag from 'shared/Modals/Tag/CreateNewTag'
import { getTagSummary, TagSummary } from 'services/tags'

function NotifTagSummary() {
  const navigate = useNavigate()
  const [sort, setSort] = useState('desc')
  const [visible, setVisible] = useState(false)

  const tagSum = useAsync<AxiosResponse<{ data: TagSummary[] }>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    tagSum.execute(getTagSummary())
  }, [])

  const renderTags = (tags: TagSummary[]) => (
    <div className="grid grid-cols-[repeat(auto-fill,minmax(24rem,1fr))] gap-3">
      {tags
        .slice(0, 8)
        .sort((a, b) =>
          sort === 'desc'
            ? b.notifications_count - a.notifications_count
            : a.notifications_count - b.notifications_count
        )
        .map(tag => (
          <div
            key={tag.id}
            className="bg-light-bg rounded-lg p-3 flex items-center gap-4"
          >
            <div className="text-base font-semibold">
              {tag.notifications_count}
            </div>
            <Tag
              bgColor={tag.color}
              strokeColor={tag.stroke_color}
              textColor={tag.text_color}
            >
              {tag.name}
            </Tag>
          </div>
        ))}
    </div>
  )

  return (
    <div className="mb-10">
      <div className="flex flex-wrap justify-between items-center gap-3">
        <div className="flex items-center gap-3">
          <div className="text-base font-medium">
            Device Notification By Tags
          </div>
          <div
            className="text-[0.8125rem] font-medium text-primary pt-[1px] cursor-pointer"
            onClick={() => setSort(prev => (prev === 'desc' ? 'asc' : 'desc'))}
          >
            Sort: {sort === 'desc' ? 'High to Low' : 'Low to High'}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="secondary-lighten"
            onClick={() => navigate('/notifications?tab=notification-tag')}
          >
            Manage Tag
          </Button>
          <Button variant="secondary-lighten" onClick={() => setVisible(true)}>
            <Plus /> Add Tag
          </Button>
        </div>
      </div>
      <div className="mt-3 bg-white rounded-lg p-4 border border-[#eeeeee]">
        {tagSum.isLoading ? (
          <Spinner className="h-28" />
        ) : !!tagSum.data?.data.data.length ? (
          renderTags(tagSum.data.data.data)
        ) : (
          <div className="text-[0.8125rem]">
            <span className="text-light-secondary mr-1">No tags to show</span>
            <span
              className="text-primary font-medium cursor-pointer"
              onClick={() => setVisible(true)}
            >
              Create New Tag
            </span>
          </div>
        )}
      </div>
      <AnimatePresence initial={false}>
        {visible && <ModalCreateNewTag onClose={() => setVisible(false)} />}
      </AnimatePresence>
    </div>
  )
}

export default NotifTagSummary
