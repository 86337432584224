import { useState } from 'react'

import tagColors, { DEFAULT_TAG_COLOR } from 'configs/tagColors'
import { Checked, ChevronRight, InfoCircle } from 'icons/outline'
import { Button, Form, FormField, Input, Tag } from 'components'
import { required } from 'utils/rules'

import { FormValues } from './FormHelpers'

interface Props {
  values: FormValues['tagDetails']
  onSubmit: (values: FormValues['tagDetails']) => void
  onStepChange: (step: number) => void
}

function TagDetails({ values, onSubmit, onStepChange }: Props) {
  const [tagColor, setTagColor] = useState({
    strokeColor: values.stroke_color || DEFAULT_TAG_COLOR.strokeColor,
    textColor: values.text_color || DEFAULT_TAG_COLOR.textColor,
    bgColor: values.color || DEFAULT_TAG_COLOR.bgColor
  })

  const handleSubmit = (v: { name: string }) => {
    onSubmit({
      name: v.name,
      stroke_color: tagColor.strokeColor,
      text_color: tagColor.textColor,
      color: tagColor.bgColor
    })
    onStepChange(1)
  }

  return (
    <div className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center">
      <div className="w-[37.5rem] m-auto">
        <div className="text-base font-medium mb-6">Enter tag detail</div>
        <Form initialValues={values} onFinish={handleSubmit}>
          <FormField
            name="name"
            label="Tag Name"
            requiredMask
            rules={[required]}
          >
            <Input
              placeholder="Name your tag"
              size="large"
              extra={
                <div className="flex items-center gap-1 text-light-secondary">
                  <InfoCircle className="shrink-0 w-3.5 h-3.5" />
                  <div className="text-[0.8125rem]">
                    Keep the name short and meaningful for quickly categorize
                    and scan
                  </div>
                </div>
              }
            />
          </FormField>
          <FormField name="color" label="Tag Color">
            <div className="flex items-center gap-2">
              {tagColors.map(color => (
                <Tag
                  key={color.bgColor}
                  {...color}
                  className="w-[2.5rem] h-[2.5rem] cursor-pointer"
                  onClick={() => setTagColor(color)}
                >
                  {tagColor?.bgColor === color.bgColor && <Checked />}
                </Tag>
              ))}
            </div>
          </FormField>
          <Button
            type="submit"
            variant="primary"
            size="large"
            className="w-40 mt-4"
          >
            Next <ChevronRight />
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default TagDetails
