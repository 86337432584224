import { Fragment } from 'react'

import { CheckedCircle } from 'icons/outline'
import { Dot } from 'components'
import { Plan } from 'services/subscriptions'

interface Props {
  free: Plan
  basic: Plan
  plus: Plan
  pro: Plan
  enterprise: Plan
}

function Features({ free, basic, plus, pro, enterprise }: Props) {
  const isSupported = (feature: boolean) => {
    return (
      feature && (
        <div className="inline-flex items-center justify-center h-full">
          <CheckedCircle className="text-success w-4 h-4 text-center shrink-0" />
        </div>
      )
    )
  }

  return (
    <Fragment>
      <div className="relative w-full mb-6">
        <div className="pb-3 border-b border-light-stroke font-medium text-sm">
          Video Streaming
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">IP Camera Licences</div>
          </div>
          <div className="py-2" />
          <div className="py-2">Up to 16</div>
          <div className="py-2">Up to 16</div>
          <div className="py-2">Up to 16</div>
          <div className="py-2">Customizable</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Device</div>
          </div>
          <div className="py-2">{free.device_count}</div>
          <div className="py-2">{basic.device_count}</div>
          <div className="py-2">{plus.device_count}</div>
          <div className="py-2">{pro.device_count}</div>
          <div className="py-2">Customizable</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Livestream</div>
          </div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Video Sharing</div>
          </div>
          <div className="py-2">{isSupported(free.has_vault)}</div>
          <div className="py-2">{isSupported(basic.has_vault)}</div>
          <div className="py-2">{isSupported(plus.has_vault)}</div>
          <div className="py-2">{isSupported(pro.has_vault)}</div>
          <div className="py-2">Customizable</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">
              Concierge Device Provisioning
            </div>
          </div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(true)}</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">
              Live Streaming into Your Website
            </div>
          </div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">Coming soon</div>
          <div className="py-2">Coming soon</div>
          <div className="py-2">Coming soon</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Timelapse</div>
          </div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">Coming soon</div>
          <div className="py-2">Coming soon</div>
        </div>
      </div>

      <div className="relative w-full mb-6">
        <div className="pb-3 border-b border-light-stroke font-medium text-sm">
          Notification
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">
              Notification Retention Days
            </div>
          </div>
          <div className="py-2">{free.inbound_log_days}</div>
          <div className="py-2">{basic.inbound_log_days}</div>
          <div className="py-2">{plus.inbound_log_days}</div>
          <div className="py-2">{pro.inbound_log_days}</div>
          <div className="py-2">Customizable</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Email Notifications</div>
          </div>
          <div className="py-2">{free.email_count}</div>
          <div className="py-2">{basic.email_count}</div>
          <div className="py-2">{plus.email_count}</div>
          <div className="py-2">{pro.email_count}</div>
          <div className="py-2">Customizable</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">SMS Notifications</div>
          </div>
          <div className="py-2">{free.email_count}</div>
          <div className="py-2">{basic.email_count}</div>
          <div className="py-2">{plus.email_count}</div>
          <div className="py-2">{pro.email_count}</div>
          <div className="py-2">Customizable</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">WhatsApp Notifications</div>
          </div>
          <div className="py-2">{free.whatsapp_count}</div>
          <div className="py-2">{basic.whatsapp_count}</div>
          <div className="py-2">{plus.whatsapp_count}</div>
          <div className="py-2">{pro.whatsapp_count}</div>
          <div className="py-2">Customizable</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Multiple Schedules</div>
          </div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Webhook Notifications</div>
          </div>
          <div className="py-2">
            {isSupported(free.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(basic.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(plus.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(pro.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(enterprise.third_party_integrations)}
          </div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Slack Notifications</div>
          </div>
          <div className="py-2">
            {isSupported(free.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(basic.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(plus.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(pro.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(enterprise.third_party_integrations)}
          </div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">
              Microsoft Teams Notifications
            </div>
          </div>
          <div className="py-2">
            {isSupported(free.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(basic.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(plus.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(pro.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(enterprise.third_party_integrations)}
          </div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">PagerDuty Notifications</div>
          </div>
          <div className="py-2">
            {isSupported(free.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(basic.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(plus.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(pro.third_party_integrations)}
          </div>
          <div className="py-2">
            {isSupported(enterprise.third_party_integrations)}
          </div>
        </div>
      </div>

      <div className="relative w-full mb-6">
        <div className="pb-3 border-b border-light-stroke font-medium text-sm">
          Video Storage
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Inbound Log Days</div>
          </div>
          <div className="py-2">{free.inbound_log_days}</div>
          <div className="py-2">{basic.inbound_log_days}</div>
          <div className="py-2">{plus.inbound_log_days}</div>
          <div className="py-2">{pro.inbound_log_days}</div>
          <div className="py-2">Customizable</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Cloud Recording</div>
          </div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Video Vault</div>
          </div>
          <div className="py-2">{isSupported(free.has_vault)}</div>
          <div className="py-2">{isSupported(basic.has_vault)}</div>
          <div className="py-2">{isSupported(plus.has_vault)}</div>
          <div className="py-2">{isSupported(pro.has_vault)}</div>
          <div className="py-2">Customizable</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Custom Recording Schedules</div>
          </div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">Coming soon</div>
          <div className="py-2">Coming soon</div>
          <div className="py-2">Coming soon</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Long Term Video Archival</div>
          </div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">Coming soon</div>
          <div className="py-2">Coming soon</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Cloud Storage</div>
          </div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">Coming soon</div>
          <div className="py-2">Coming soon</div>
        </div>
      </div>

      <div className="relative w-full mb-6">
        <div className="pb-3 border-b border-light-stroke font-medium text-sm">
          Management
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">User Management</div>
          </div>
          <div className="py-2">{isSupported(free.has_user_mgmt)}</div>
          <div className="py-2">{isSupported(basic.has_user_mgmt)}</div>
          <div className="py-2">{isSupported(plus.has_user_mgmt)}</div>
          <div className="py-2">{isSupported(pro.has_user_mgmt)}</div>
          <div className="py-2">{isSupported(enterprise.has_user_mgmt)}</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Activity Log</div>
          </div>
          <div className="py-2">{isSupported(free.has_audit_trails)}</div>
          <div className="py-2">{isSupported(basic.has_audit_trails)}</div>
          <div className="py-2">{isSupported(plus.has_audit_trails)}</div>
          <div className="py-2">{isSupported(pro.has_audit_trails)}</div>
          <div className="py-2">{isSupported(enterprise.has_audit_trails)}</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Custom Brand Logo</div>
          </div>
          <div className="py-2">{isSupported(!!free.use_brand_logo)}</div>
          <div className="py-2">{isSupported(!!basic.use_brand_logo)}</div>
          <div className="py-2">{isSupported(!!plus.use_brand_logo)}</div>
          <div className="py-2">{isSupported(!!pro.use_brand_logo)}</div>
          <div className="py-2">{isSupported(!!enterprise.use_brand_logo)}</div>
        </div>
      </div>

      <div className="relative w-full">
        <div className="pb-3 border-b border-light-stroke font-medium text-sm">
          Support
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] border-b border-light-stroke text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Email/Chat Support</div>
          </div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
          <div className="py-2">{isSupported(true)}</div>
        </div>
        <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] gap-4 text-[0.8125rem] text-light-secondary text-center">
          <div className="py-2 flex items-center gap-2">
            <Dot className="w-1.5 h-1.5 bg-gray-400" />
            <div className="text-light-primary">Phone Support</div>
          </div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(false)}</div>
          <div className="py-2">{isSupported(true)}</div>
        </div>
      </div>
    </Fragment>
  )
}

export default Features
