import {
  Fragment,
  MouseEventHandler,
  useEffect,
  useReducer,
  useRef
} from 'react'
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef
} from 'react-zoom-pan-pinch'
import cx from 'classnames'
import shallow from 'zustand/shallow'
import { AnimatePresence } from 'framer-motion'

import {
  Delete,
  Pin,
  Minus,
  Pencil,
  Plus,
  Refresh,
  Upload
} from 'icons/outline'
import { LocationPinAlt } from 'icons/filled'
import { FloorPlanPin } from 'icons/utils'
import { Popover, Spinner } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalEditFloorName from 'shared/Modals/FloorPlans/EditFloorName'
import ModalConfirmDelete from 'shared/Modals/Confirm/ConfirmDelete'
import ModalReplaceFloorPlanImage from 'shared/Modals/FloorPlans/ReplaceFloorPlanImage'
import toast from 'utils/toast'
import { getDeviceStatus } from 'utils/device'
import { FloorPlan, deleteFloorPlan } from 'services/floorPlans'
import useStore, { Store } from 'store'

import DeviceList from './DeviceList'
import { useLocationCtx } from '../LocationContext'

interface State {
  loading: boolean
  visible: string
  pinMode: boolean
  pinX: number
  pinY: number
}

const initState: State = {
  loading: true,
  visible: '',
  pinMode: false,
  pinX: -1,
  pinY: -1
}

const dropShadow =
  'drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.15)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25))'

const mapState = (state: Store) => ({
  devices: state.device.devices
})

function MainContent() {
  const [{ loading, visible, pinMode, pinX, pinY }, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const wrapperRef = useRef<ReactZoomPanPinchRef>(null)
  const store = useStore(mapState, shallow)
  const { floorPlans, activeFloorPlan, dispatch, ...ctx } = useLocationCtx()

  useEffect(() => {
    if (!!activeFloorPlan) {
      setState({ loading: true, pinMode: false, pinX: -1, pinY: -1 })
    }
  }, [activeFloorPlan?.id, activeFloorPlan?.image_url])

  if (!activeFloorPlan) {
    return (
      <div className="w-full h-full flex justify-center items-center bg-light-bg">
        <div className="text-light-secondary text-[0.8125rem]">
          Floor plan detail will be displayed here
        </div>
      </div>
    )
  }

  const handleUpdateFloorPlan = (fp: FloorPlan) => {
    dispatch({
      floorPlans: floorPlans.map(f => (f.id === fp.id ? { ...f, ...fp } : f))
    })
  }

  const handleDeleteFloorPlan = () => {
    deleteFloorPlan(activeFloorPlan.id)
    dispatch({
      floorPlans: floorPlans.filter(fp => fp.id !== activeFloorPlan.id),
      activeFloorPlanId: undefined
    })
    toast.success(
      { title: 'Floor plan deleted' },
      { position: 'bottom-center' }
    )
    setState({ visible: '' })
  }

  const handlePinClick: MouseEventHandler<HTMLDivElement> = event => {
    event.preventDefault()
    const { clientX, clientY, target: t } = event
    const target = t as HTMLDivElement
    const targetCls = 'react-transform-component'
    if (target.classList.contains(targetCls) && wrapperRef.current) {
      const { contentComponent } = wrapperRef.current.instance
      const { x, y } = contentComponent!.parentElement!.getBoundingClientRect()
      setState({ pinX: clientX - x, pinY: clientY - y })
    }
  }

  const renderEditMenu = (onClose: () => void) => {
    const onClick = (fn: () => void) => () => {
      fn()
      onClose()
      setState({ pinX: -1, pinY: -1 })
    }
    return (
      <Popover.Content className="w-[12rem] !p-2" onClose={onClose}>
        <MenuItem onClick={onClick(() => setState({ visible: 'edit-name' }))}>
          <Pencil className="w-3.5 shrink-0" /> Edit Name
        </MenuItem>
        <MenuItem
          onClick={onClick(() => setState({ visible: 'replace-floor' }))}
        >
          <Upload className="w-3.5 shrink-0" /> Replace Floor Plan
        </MenuItem>
      </Popover.Content>
    )
  }

  const renderEditControls = () => (
    <div className="absolute z-[2] top-4 left-4 flex gap-2">
      <Popover
        content={onClose => renderEditMenu(onClose)}
        placement="bottom-start"
        className="button-secondary min-w-[4rem] h-8 p-px rounded-lg cursor-pointer"
        style={{ filter: dropShadow }}
      >
        <div
          className="w-full h-full button-inner rounded-lg inline-flex justify-center items-center gap-1.5 px-2 select-none"
          onClick={() => setState({ pinX: -1, pinY: -1 })}
        >
          <Pencil /> Edit Floor Plan
        </div>
      </Popover>
      <div
        title="Delete Floor Plan"
        className="button-secondary w-8 h-8 p-px rounded-lg cursor-pointer"
        style={{ filter: dropShadow }}
        onClick={() => setState({ visible: 'delete', pinX: -1, pinY: -1 })}
      >
        <div className="w-full h-full button-inner rounded-lg inline-flex justify-center items-center">
          <Delete className="w-4 h-4" />
        </div>
      </div>
    </div>
  )

  const renderControls = (zoomRef: ReactZoomPanPinchRef) => (
    <div className="absolute z-[2] top-4 right-4 flex flex-col">
      <div
        style={{ filter: dropShadow }}
        className="inline-flex flex-col button-secondary w-8 p-px rounded-lg"
      >
        <div className="w-full button-inner rounded-lg">
          <div
            title="Zoom In"
            className={cx(
              'w-full h-8 inline-flex items-center justify-center cursor-pointer',
              zoomRef.state.scale === 8 && 'opacity-20 pointer-events-none'
            )}
            onClick={() => {
              zoomRef.zoomIn()
              setState({ pinX: -1, pinY: -1 })
            }}
          >
            <Plus className="w-4 h-4" />
          </div>
          <div className="border-b border-light-stroke my-px" />
          <div
            title="Zoom Out"
            className={cx(
              'w-full h-8 inline-flex items-center justify-center cursor-pointer',
              zoomRef.state.scale === 1 && 'opacity-20 pointer-events-none'
            )}
            onClick={() => {
              zoomRef.zoomOut()
              setState({ pinX: -1, pinY: -1 })
            }}
          >
            <Minus className="w-4 h-4" />
          </div>
        </div>
      </div>
      <div
        title="Reset"
        className="button-secondary w-8 h-8 p-px rounded-lg mt-2 cursor-pointer"
        style={{ filter: dropShadow }}
        onClick={() => {
          zoomRef.resetTransform()
          setState({ pinX: -1, pinY: -1 })
        }}
      >
        <div className="w-full h-full button-inner rounded-lg inline-flex justify-center items-center">
          <Refresh className="w-4 h-4" />
        </div>
      </div>
      <div
        title="Pin"
        style={{ filter: dropShadow }}
        onClick={() => setState({ pinMode: !pinMode, pinX: -1, pinY: -1 })}
        className={cx(
          pinMode ? 'button-primary' : 'button-secondary',
          'w-8 h-8 p-px rounded-lg mt-6 cursor-pointer'
        )}
      >
        <div className="w-full h-full button-inner rounded-lg inline-flex justify-center items-center">
          <Pin className={cx(!pinMode && 'rotate-45', 'w-4 h-4')} />
        </div>
      </div>
    </div>
  )

  const renderPins = () => {
    return activeFloorPlan.devices.map(({ id, lat, lng, type }, idx) => {
      const device = store.devices.find(d => d.id === id)
      return !!device ? (
        <div
          key={idx}
          style={{ left: +lat - 21.5, top: +lng - 32 }}
          onClick={() => dispatch({ activePinId: id })}
          className={cx(
            'absolute cursor-pointer',
            ctx.activePinId === id && 'scale-150'
          )}
        >
          <FloorPlanPin
            type={type}
            iconType={
              getDeviceStatus(device) === 'online' ? 'success' : 'danger'
            }
          />
        </div>
      ) : undefined
    })
  }

  const renderPinSelection = (s: ReactZoomPanPinchRef['state']) => {
    if (!pinMode || pinX === -1 || pinY === -1) return

    const { positionX, positionY, scale } = s
    const lat = Math.abs(positionX - pinX) / scale
    const lng = Math.abs(positionY - pinY) / scale

    return (
      <Popover
        key={pinX + pinY}
        visible
        placement="bottom"
        content={onClose => (
          <DeviceList
            position={{ lat, lng }}
            onClose={() => {
              onClose()
              setState({ pinX: -1, pinY: -1 })
            }}
          />
        )}
        className="absolute"
        style={{ left: lat - 17, top: lng - 31 }}
      >
        <LocationPinAlt className="w-[2.125rem] h-[2.25rem]" />
      </Popover>
    )
  }

  return (
    <Fragment>
      <div
        key={activeFloorPlan.id}
        onClick={pinMode ? handlePinClick : undefined}
        className="relative flex w-[calc(100%_-_19.5625rem)] bg-light-bg"
      >
        {loading && (
          <Spinner className="absolute top-0 left-0 z-[3] bg-light-bg" />
        )}
        <TransformWrapper ref={wrapperRef}>
          {props => (
            <Fragment>
              {renderEditControls()}
              {renderControls(props)}
              <TransformComponent
                wrapperClass="max-w-full max-h-full !m-auto"
                contentClass={cx(pinMode && 'cursor-pointer')}
              >
                {renderPins()}
                {renderPinSelection(props.state)}
                <img
                  className="max-w-none h-none"
                  src={activeFloorPlan.image_url}
                  onLoad={() => setState({ loading: false })}
                  alt="floor-plan"
                />
              </TransformComponent>
            </Fragment>
          )}
        </TransformWrapper>
      </div>
      <AnimatePresence initial={false}>
        {visible === 'edit-name' && (
          <ModalEditFloorName
            floorPlan={activeFloorPlan}
            onSuccess={handleUpdateFloorPlan}
            onClose={() => setState({ visible: '' })}
          />
        )}
        {visible === 'replace-floor' && (
          <ModalReplaceFloorPlanImage
            floorPlan={activeFloorPlan}
            onSuccess={handleUpdateFloorPlan}
            onClose={() => setState({ visible: '' })}
          />
        )}
        {visible === 'delete' && (
          <ModalConfirmDelete
            modalTitle="Delete Floor Plan"
            confirmText="Are you sure you want to remove this floor plan?"
            warnText="This action cannot be undone"
            onConfirm={handleDeleteFloorPlan}
            onClose={() => setState({ visible: '' })}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default MainContent
