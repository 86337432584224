import { FC, ReactNode } from 'react'
import cx from 'classnames'

import styles from './layout.module.css'
import { Collapse } from 'components'

export const MenuItem: FC<JSX.IntrinsicElements['div']> = ({
  children,
  ...props
}) => (
  <div
    {...props}
    className={cx(
      styles.menu_item,
      'base-transition',
      'text-light-secondary hover:bg-light-hover',
      'w-full flex items-center cursor-pointer relative',
      props.className
    )}
  >
    {children}
  </div>
)

interface CollapseMenuItemProps {
  children?: ReactNode
  Icon: (props: any) => JSX.Element
  label: string
  active: boolean
}

export const CollapseMenuItem: FC<CollapseMenuItemProps> = ({
  children,
  Icon,
  label,
  active
}) => {
  return (
    <Collapse
      defaultActive={active}
      className={cx(
        styles.collapse_menu_item,
        'hover:bg-light-hover base-transition'
      )}
      panelClassName={cx('px-4 py-0', styles.menu_item)}
      panel={
        <div className="flex gap-2.5">
          <Icon
            style={{ width: 24, height: 20 }}
            className={cx(active ? 'text-primary' : 'text-light-secondary')}
          />
          <span className={cx(active ? 'text-primary' : 'text-light-primary')}>
            {label}
          </span>
        </div>
      }
    >
      {children}
    </Collapse>
  )
}
