import { ReactNode, useEffect, useRef, useState } from 'react'

import { Close } from 'icons/outline'
import { Alert, Button, Input, Modal, ModalProps } from 'components'

interface Props extends ModalProps {
  modalTitle: ReactNode
  warnText?: ReactNode
  value: string
  inputLabel: ReactNode
  okText?: ReactNode
  onConfirm: () => void
}

function ModalConfirmDeleteWithInput({
  modalTitle,
  warnText,
  value: propValue,
  inputLabel,
  okText = 'Delete',
  onConfirm,
  ...props
}: Props) {
  const [value, setValue] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const disabled = value !== propValue

  return (
    <Modal {...props} className="w-[26rem] px-6">
      <div className="flex items-center justify-between mb-6">
        <div className="text-base font-medium capitalize">{modalTitle}</div>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      {warnText && (
        <Alert type="warning" className="mb-4">
          <div className="">{warnText}</div>
        </Alert>
      )}
      <div className="text-[0.8125rem] mb-2">{inputLabel}</div>
      <Input
        ref={inputRef}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <div className="mt-6 flex items-center justify-end gap-2">
        <Button className="w-[5.3125rem]" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          disabled={disabled}
          variant="primary"
          className="min-w-[6.25rem]"
          onClick={disabled ? undefined : onConfirm}
        >
          {okText}
        </Button>
      </div>
    </Modal>
  )
}

export default ModalConfirmDeleteWithInput
