import Screenshot from 'assets/screenshots/CloudSearch.png'
import { ChevronRight } from 'icons/outline'

function CloudSearch() {
  return (
    <div className="p-4 grow">
      <div className="text-xl font-medium mb-4">Cloud Search</div>
      <img src={Screenshot} alt="screenshot" className="w-full h-[25.625rem]" />
      <div className="mt-12">
        <div className="text-[0.8125rem] mb-3.5">
          Every licensed cloud surveillance camera added to your Streams account
          includes 100GB of video storage. Use the cloud search interface to
          request video clips up to 4 hours in length for any of your cameras.
          Upon requesting a large video clip, the system will work on preparing
          and fetching it in the background. A notification will be sent as soon
          as it's ready.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Once your clip has been prepared, it can be downloaded, moved to the
          Video Vault or shared with a 3rd party.
        </div>
      </div>
      <div className="mt-12">
        <div className="font-medium text-base mb-4">Get Support</div>
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/cloud-recording/how-cloud-search-works"
          target="_blank"
          rel="noreferrer"
        >
          How Cloud Search Works
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/cloud-recording/video-sharing"
          target="_blank"
          rel="noreferrer"
        >
          Introduction to Video Sharing
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/cloud-recording/watch-videos-offline"
          target="_blank"
          rel="noreferrer"
        >
          Watching Videos Offline
          <ChevronRight />
        </a>
      </div>
    </div>
  )
}

export default CloudSearch
