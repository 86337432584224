import { Close, Delete, Pencil } from 'icons/outline'
import { ResourceIcon } from 'icons/utils'
import { Avatar, Button, Modal, ModalProps } from 'components'
import { formatPhone } from 'utils/functions'
import { Recipient } from 'services/recipients'

interface Props extends ModalProps {
  recipient: Recipient
  onEdit: (rec: Recipient) => void
  onDelete: (rec: Recipient) => void
}

function ModalViewRecipientProfile({
  recipient,
  onEdit,
  onDelete,
  ...props
}: Props) {
  const handleEdit = () => {
    onEdit(recipient)
    props.onClose()
  }

  const handleDelete = () => {
    onDelete(recipient)
    props.onClose()
  }

  return (
    <Modal {...props} className="w-[34.375rem] px-6">
      <div className="flex justify-between items-center mb-6">
        <div className="text-base font-medium">Recipient Profile</div>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      <div className="flex gap-4">
        <Avatar className="shrink-0" size={120} username={recipient.name} />
        <div className="w-full">
          <div className="text-base font-medium mb-4">{recipient.name}</div>
          <div className="flex items-center text-[0.8125rem] gap-4">
            <div className="flex items-center gap-1.5 w-[6.6rem]">
              <ResourceIcon
                type="email"
                iconType="primary"
                className="w-4 h-4"
              />
              <div className="font-medium">Email</div>
            </div>
            <div>{recipient.email}</div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-3" />
          <div className="flex items-center text-[0.8125rem] gap-4">
            <div className="flex items-center gap-1.5 w-[6.6rem]">
              <ResourceIcon type="sms" iconType="primary" className="w-4 h-4" />
              <div className="font-medium">Phone</div>
            </div>
            <div>{formatPhone(recipient.phone) || '---'}</div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-3" />
          <div className="flex items-center text-[0.8125rem] gap-4">
            <div className="flex items-center gap-1.5 w-[6.6rem]">
              <ResourceIcon
                type="whatsapp"
                iconType="primary"
                className="w-4 h-4"
              />
              <div className="font-medium">WhatsApp</div>
            </div>
            <div>{formatPhone(recipient.phone) || '---'}</div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-3" />
          <div className="flex items-center gap-2">
            <Button className="w-[6.25rem]" onClick={handleEdit}>
              <Pencil /> Edit
            </Button>
            <Button className="w-[6.25rem]" onClick={handleDelete}>
              <Delete /> Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalViewRecipientProfile
