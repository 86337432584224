import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Checked(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="15"
        height="11"
        viewBox="0 0 15 11"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.173077 6C0.0576923 5.89091 0 5.72727 0 5.61818C0 5.50909 0.0576923 5.34545 0.173077 5.23636L0.980769 4.47273C1.21154 4.25455 1.55769 4.25455 1.78846 4.47273L1.84615 4.52727L5.01923 7.74545C5.13462 7.85455 5.30769 7.85455 5.42308 7.74545L13.1538 0.163636H13.2121C13.4423 -0.0545455 13.789 -0.0545455 14.0192 0.163636L14.8269 0.927273C15.0577 1.14545 15.0577 1.47273 14.8269 1.69091L5.59615 10.7455C5.48077 10.8545 5.36538 10.9091 5.19231 10.9091C5.01923 10.9091 4.90385 10.8545 4.78846 10.7455L0.288462 6.16364L0.173077 6Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
