import { FC, SVGProps } from 'react'

import {
  RecordStart,
  RecordStop,
  WifiOn,
  WifiOff,
  Download
} from 'icons/outline'
import { CheckedCircle, WarningCircle, InfoCircle } from 'icons/filled'

type Props = {
  message: string
  level: string
  iconColor?: [start: string, stop: string] | string
  iconType?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & SVGProps<SVGSVGElement>

export function NotifIcon({
  message,
  level,
  iconColor,
  iconType,
  ...props
}: Props) {
  let OutlineIcon: FC<any> | null = null
  if (message?.endsWith('started')) {
    OutlineIcon = RecordStart
  }
  if (message?.endsWith('stopped')) {
    OutlineIcon = RecordStop
  }
  if (message?.endsWith('online')) {
    OutlineIcon = WifiOn
  }
  if (message?.endsWith('offline')) {
    OutlineIcon = WifiOff
  }
  if (message?.endsWith('ready for download')) {
    OutlineIcon = Download
  }

  let FilledIcon: FC<any> | null = null
  if (level === 'success') {
    FilledIcon = CheckedCircle
  }
  if (level === 'info') {
    FilledIcon = InfoCircle
  }
  if (level === 'warning') {
    FilledIcon = WarningCircle
  }

  if (OutlineIcon) {
    return <OutlineIcon {...props} />
  } else if (FilledIcon) {
    return <FilledIcon color={iconColor} type={iconType} {...props} />
  } else {
    return null
  }
}
