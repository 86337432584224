import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Burger(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <rect width="20" height="2" rx="1" />
        <rect y="6" width="20" height="2" rx="1" />
        <rect y="12" width="20" height="2" rx="1" />
      </svg>
    </SVGUniqueID>
  )
}
