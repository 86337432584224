import Screenshot from 'assets/screenshots/CloudRecording.png'
import { ChevronRight } from 'icons/outline'

function CloudRecording() {
  return (
    <div className="p-4 grow">
      <div className="text-xl font-medium mb-4">Cloud Recording</div>
      <img src={Screenshot} alt="screenshot" className="w-full h-[25.625rem]" />
      <div className="mt-12">
        <div className="text-[0.8125rem] mb-3.5">
          Each camera license includes 100GB of video storage. All of
          your video is encrypted end-to-end, and stored redundantly in multiple data centers.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          The number of days of retention time will depend on the bitrate
          settings of the camera. For example, a 720P HD camera will provide
          approximately 2-3 weeks of retention time.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Contact support for help with optimization of your streaming and
          storage capacity.
        </div>
      </div>
      <div className="mt-12">
        <div className="font-medium text-base mb-4">Get Support</div>
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/cloud-recording/how-it-works"
          target="_blank"
          rel="noreferrer"
        >
          How Cloud Recording Works
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/cloud-recording/secure-storage"
          target="_blank"
          rel="noreferrer"
        >
          How Video is Stored Securely
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/cloud-recording/codecs-supported"
          target="_blank"
          rel="noreferrer"
        >
          Codecs Supported
          <ChevronRight />
        </a>
      </div>
    </div>
  )
}

export default CloudRecording
