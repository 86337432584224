import { forwardRef, InputHTMLAttributes, ReactNode } from 'react'
import cx from 'classnames'

export type Props = {
  size?: 'small' | 'default' | 'large'
  block?: boolean
  suffix?: ReactNode
  addonAfter?: ReactNode
  extra?: ReactNode
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>

const sizes = {
  small: 'h-6 py-0 px-[0.4375rem]',
  default: 'h-8 py-1 px-2.5',
  large: 'h-10 px-2.5 py-1.5'
}

const disabled = 'pointer-events-none bg-light-disabled !text-light-primary/50'

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ size = 'default', block, suffix, addonAfter, extra, ...props }, ref) => {
    return (
      <div className={cx('relative', block && 'block w-full')}>
        <div className="flex items-center w-full">
          <input
            {...props}
            ref={ref}
            className={cx(
              'border border-light-stroke rounded-lg w-full text-sm',
              'base-transition hover:border-primary focus:border-primary outline-0',
              'placeholder:text-light-secondary placeholder:font-normal',
              sizes[size],
              suffix && '!pr-8',
              addonAfter && 'rounded-r-none',
              props.disabled && disabled,
              props.className
            )}
          />
          {suffix && (
            <div
              className={cx(
                sizes[size],
                'absolute w-8 top-0 right-0 !p-0 flex justify-center items-center'
              )}
            >
              {suffix}
            </div>
          )}
          {addonAfter && (
            <div
              className={cx(
                sizes[size],
                '!p-0 inline-flex items-center',
                'border border-l-0 rounded-r-lg bg-[#fafafa]',
                props.disabled && disabled,
                props.className?.includes('border-danger') && props.className
              )}
            >
              {addonAfter}
            </div>
          )}
        </div>
        {extra && <div className="mt-1 text-sm">{extra}</div>}
      </div>
    )
  }
)
