import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function MinusCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M8 15C6.13026 15 4.37236 14.2719 3.05025 12.9498C1.72813 11.6276 1 9.86974 1 8C1 6.13026 1.72813 4.37236 3.05025 3.05025C4.37236 1.72813 6.13026 1 8 1C9.86974 1 11.6276 1.72813 12.9498 3.05025C14.2719 4.37236 15 6.13026 15 8C15 9.86974 14.2719 11.6276 12.9498 12.9498C11.6276 14.2719 9.86974 15 8 15ZM8 2.09375C4.74332 2.09375 2.09375 4.74332 2.09375 8C2.09375 11.2567 4.74332 13.9062 8 13.9062C11.2567 13.9062 13.9062 11.2567 13.9062 8C13.9062 4.74332 11.2567 2.09375 8 2.09375ZM11.0078 7.45259H4.99219V8.54634H11.0078V7.45259Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.5"
        />
      </svg>
    </SVGUniqueID>
  )
}
