import { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import SensorImg from 'assets/images/Sensors.png'
import SensorCheckedImg from 'assets/images/SensorChecked.png'

import { InfoCircle } from 'icons/filled'
import { useAsync } from 'hooks'
import { Spinner, Tooltip } from 'components'
import CheckoutForm, { FormValues } from 'shared/Forms/CheckoutForm'
import ModalCheckoutSuccess from 'shared/Modals/Billing/CheckoutSuccess'
import { ADD_ON } from 'utils/plan'
import { createOrder } from 'services/orders'
import useStore, { Store } from 'store'

type RouteState = {
  type: 'sensor'
  count: number
  id: number
}

const mapState = ({ product, plan }: Store) => ({
  planLoading: plan.loading,
  plans: plan.plans,
  productLoading: product.loading,
  products: product.products,
  getPlans: plan.getPlans,
  getProducts: product.getProducts
})

function CheckoutSensor() {
  const { count, id } = (useLocation().state || {}) as RouteState
  const [visible, setVisible] = useState(false)

  const store = useStore(mapState, shallow)
  const orderAsync = useAsync({ showNotifOnError: true })

  useEffect(() => {
    if (!store.plans.length && !store.planLoading) store.getPlans()
    if (!store.products.length && !store.productLoading) store.getProducts()
  }, [])

  if (store.planLoading || store.productLoading) {
    return <Spinner className="h-[80vh]" />
  }

  const sensor = store.products.find(p => p.id === id)
  const addOn = store.plans.find(p => p.name === ADD_ON.TEMPERATURE_SENSOR)
  if (!sensor || !addOn || !count || count <= 0) return null

  const handleSubmit = async (values: FormValues) => {
    await orderAsync.execute(
      createOrder({
        full_name: values.name,
        phone_no: values.phone,
        street_address: values.address.value,
        street_address_2: values.address2,
        city: values.city,
        state: values.state,
        zip: values.zipcode,
        order_items: [{ product_id: sensor.id, quantity: count }]
      })
    )
    setVisible(true)
  }

  return (
    <Fragment>
      <div className="grid grid-cols-[27rem_1rem_auto]">
        <div className="bg-white p-6 rounded-lg border border-[#eee] shadow-[0px_1px_2px_rgba(0,0,0,0.16)] self-start">
          <div className="text-base font-medium mb-5">Selected product</div>
          <div className="flex items-center gap-2 mb-6 bg-[#fafafa] p-4 rounded-lg">
            <img className="w-10 shrink-0" src={SensorImg} alt="sensor" />
            <div className="font-medium text-sm">
              Temperature Sensor <span className="text-success">x{count}</span>
            </div>
          </div>
          <div className="flex items-center gap-1 text-[0.8125rem]">
            <div className="w-44">Price</div>
            <div className="font-medium">
              <span className="text-success">1</span> x $
              {sensor.price_in_dollars}
            </div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-2" />
          <div className="flex items-center gap-1 text-[0.8125rem]">
            <div className="w-44 inline-flex items-center gap-1">
              Monthly monitoring
              <Tooltip content="Cloud monitoring subscription" placement="top">
                <InfoCircle
                  color="#bdbdbd"
                  className="cursor-pointer w-[0.875rem] h-[0.875rem]"
                />
              </Tooltip>
            </div>
            <div className="font-medium">
              <span className="text-success">1</span> x $
              {addOn.price_in_dollars}
              <span className="font-normal text-light-secondary">/month</span>
            </div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-2" />
          <div className="flex items-center gap-1 text-[0.8125rem]">
            <div className="w-44 inline-flex items-center gap-1">Shipping</div>
            <div className="font-medium text-success">Free</div>
          </div>
          <div className="border-b border-light-stroke my-2" />
          <div className="flex items-center gap-1 text-[0.8125rem]">
            <div className="w-44">Total to pay</div>
            <div className="font-medium text-[1.25rem]">
              $
              {(sensor.price_in_dollars + (addOn.price_in_dollars || 1)) *
                count}
            </div>
          </div>
        </div>
        <div />
        <div className="bg-white p-6 rounded-lg border border-[#eee] shadow-[0px_1px_2px_rgba(0,0,0,0.16)]">
          <CheckoutForm
            includeLocation
            disabled={orderAsync.isLoading}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
      <img
        src={SensorCheckedImg}
        alt="sensor"
        className="w-0 h-0 fixed bottom-0 right-0 z-[-9999] invisible pointer-events-none"
      />
      <AnimatePresence initial={false}>
        {visible && (
          <ModalCheckoutSuccess
            onClose={() => void 0}
            imageUrl={SensorCheckedImg}
            title="Thank you for using Broadflow"
            description={
              <Fragment>
                <div className="text-light-secondary">
                  Your {count} temperature sensors will be shipped in the next
                  business day. A confirmation email will follow shortly.
                </div>
              </Fragment>
            }
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default CheckoutSensor
