import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function ArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M0.666666 6.50016H13.9663L8.87367 1.6495C8.607 1.3955 8.59667 0.973496 8.85067 0.706829C9.10433 0.440496 9.52633 0.429829 9.79333 0.683829L15.6093 6.22383C15.861 6.47583 16 6.8105 16 7.16683C16 7.52283 15.861 7.85783 15.5977 8.12083L9.793 13.6495C9.664 13.7725 9.49867 13.8335 9.33333 13.8335C9.15733 13.8335 8.98133 13.7642 8.85033 13.6265C8.59633 13.3598 8.60667 12.9382 8.87333 12.6842L13.9873 7.8335H0.666666C0.298666 7.8335 0 7.53483 0 7.16683C0 6.79883 0.298666 6.50016 0.666666 6.50016Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
