import client from './client'

export interface PlaybackSegment {
  endtime: string
  starttime: string
  url: string
}

export interface PlaybackNotif {
  id: number
  starttime: string
  subject: string
  tags: {
    color: string
    id: number
    name: string
    pivot: any
    stroke_color: string
    text_color: string
  }[]
}

export interface RecordPlayback {
  notifications: PlaybackNotif[]
  playback: PlaybackSegment[]
}

export type RecordingPlaybackResponse = {
  data: {
    playback: PlaybackSegment[]
    notifications: PlaybackNotif[]
  }
}

export const getRecordingPlayback = (
  id: number,
  payload: { start_timestamp: string; end_timestamp: string }
) => {
  return client.post<RecordingPlaybackResponse>(
    `/api/devices/${id}/recordingPlayback/v2`,
    payload
  )
}

// ------------------------------------------------------------------------- //

export const getRecordsCalendar = ({
  start_date,
  end_date,
  device_ids
}: {
  start_date: string
  end_date: string
  device_ids: number[]
}) => {
  return client.get<{ data: { [key: string]: number } }>(
    `api/recordingsCalender?start_date=${start_date}&end_date=${end_date}&device_ids=${device_ids.join(
      ','
    )}`
  )
}
