import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Logic(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M9.00152 4.95105C8.2993 4.95105 7.73041 5.51994 7.73041 6.22216C7.73041 6.92438 8.2993 7.49327 9.00152 7.49327C9.70374 7.49327 10.2726 6.92438 10.2726 6.22216C10.2726 5.51994 9.70374 4.95105 9.00152 4.95105Z"
          fill="currentColor"
        />
        <path
          d="M9.00152 0C5.66819 0 2.95707 2.61333 2.79707 5.90222L1.09041 8.17778C0.868186 8.47111 1.08152 8.88889 1.44596 8.88889H2.7793V11.5556C2.7793 12.5333 3.5793 13.3333 4.55707 13.3333H5.44596V16H11.6682V11.84C13.766 10.8444 15.2237 8.70222 15.2237 6.22222C15.2237 2.78222 12.4415 0 9.00152 0ZM11.6682 6.22222C11.6682 6.33778 11.6593 6.45333 11.6504 6.56889L12.3882 7.15556C12.4593 7.20889 12.4771 7.29778 12.4326 7.37778L11.7215 8.61333C11.6771 8.69333 11.5793 8.72 11.5082 8.69333L10.6282 8.33778C10.4415 8.48 10.246 8.59556 10.0326 8.68445L9.89041 9.62667C9.88152 9.71556 9.80152 9.77778 9.71263 9.77778H8.29041C8.20152 9.77778 8.13041 9.71556 8.11263 9.62667L7.9793 8.68445C7.75707 8.59556 7.56152 8.48 7.37485 8.33778L6.49485 8.69333C6.41485 8.72 6.31707 8.69333 6.27263 8.61333L5.56152 7.37778C5.51707 7.30667 5.53485 7.20889 5.60596 7.15556L6.35263 6.56889C6.34374 6.45333 6.33485 6.33778 6.33485 6.22222C6.33485 6.10667 6.35263 5.98222 6.37041 5.87556L5.61485 5.28889C5.54374 5.23556 5.52596 5.14667 5.57041 5.05778L6.28152 3.83111C6.32596 3.75111 6.41485 3.72444 6.49485 3.75111L7.38374 4.10667C7.56152 3.97333 7.76596 3.84889 7.9793 3.76L8.11263 2.81778C8.13041 2.72889 8.20152 2.66667 8.29041 2.66667H9.71263C9.80152 2.66667 9.87263 2.72889 9.89041 2.81778L10.0237 3.76C10.2371 3.84889 10.4326 3.96444 10.6193 4.10667L11.5082 3.75111C11.5882 3.72444 11.686 3.75111 11.7215 3.83111L12.4326 5.05778C12.4771 5.13778 12.4593 5.23556 12.3882 5.28889L11.6326 5.87556C11.6593 5.98222 11.6682 6.09778 11.6682 6.22222Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
