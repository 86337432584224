import { Link, useLocation } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import cx from 'classnames'

import { Burger } from 'icons/outline'
import { collapsedMenu, Navigation } from 'configs/navigation'
import { Tooltip } from 'components'
import useStore from 'store'

import styles from './layout.module.css'
import { MenuItem } from './MenuItem'

function CollapsedMenu({ onExpand }: { onExpand: () => void }) {
  const { pathname } = useLocation()
  const betaFeatures = useStore(state => state.auth.accessBetaFeatures())

  const renderMenuItem = (menuItem: Navigation) => {
    const { key, label, Icon, navLink, query = '' } = menuItem
    let active = false
    if (pathname === navLink) active = true

    if (!!menuItem.betaFeature && !betaFeatures) return null
    return (
      <Tooltip key={key} content={label} placement="right">
        <MenuItem className={cx('justify-center', active && styles.active)}>
          <Link
            to={navLink! + query}
            children={<Icon />}
            className="w-full h-full flex justify-center items-center"
          />
        </MenuItem>
      </Tooltip>
    )
  }

  return (
    <div className="top-0 left-0 w-[3.4375rem] overflow-hidden fixed h-full border-r border-[#E7E7E7] bg-white z-[2]">
      <MenuItem
        onClick={onExpand}
        children={<Burger />}
        className="justify-center hover:!bg-transparent"
      />
      <PerfectScrollbar
        options={{ wheelPropagation: false }}
        className="h-[calc(100%_-_3.125rem)] flex flex-col"
      >
        <div className="mb-8">{collapsedMenu.map(renderMenuItem)}</div>
      </PerfectScrollbar>
    </div>
  )
}

export default CollapsedMenu
