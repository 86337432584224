import { Fragment, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'

import { Monitor } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Spinner } from 'components'
import {
  BrowserSession,
  getBrowserSessions,
  logOutBrowserSessions
} from 'services/2fa'

function BrowserSessions() {
  const [showAll, setShowAll] = useState(false)

  const sessionAsync = useAsync<AxiosResponse<BrowserSession[]>>({
    showNotifOnError: true
  })
  const logoutAsync = useAsync({
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetBrowerSessions()
  }, [])

  const handleGetBrowerSessions = () => {
    return sessionAsync.execute(getBrowserSessions())
  }

  const handleLogout = async () => {
    await logoutAsync.execute(logOutBrowserSessions())
    await handleGetBrowerSessions()
  }

  const renderSession = (session: BrowserSession, idx: number) => (
    <div
      key={idx}
      className="flex flex-wrap items-center gap-3.5 pb-3 mb-3 border-b border-light-stroke border-dashed last:pb-0 last:border-none last:mb-0"
    >
      <Monitor color="#bdbdbd" className="w-[1.875rem] shrink-0" />
      <div className="grow">
        <div className="font-medium text-sm">
          {session.platform} - {session.browser}
        </div>
        <div className="inline-flex flex-wrap items-center gap-1 text-[0.8125rem]">
          <div className="text-light-secondary">{session.ip_address}</div>
          <div>&#8231;</div>
          {session.is_current_device ? (
            <div className="text-success">Active now</div>
          ) : (
            <div className="text-light-secondary">
              Last active {session.last_active}
            </div>
          )}
        </div>
      </div>
    </div>
  )

  const data = sessionAsync.data?.data || []
  const disabled = sessionAsync.isLoading || logoutAsync.isLoading

  return (
    <Fragment>
      <div className="flex flex-wrap items-center justify-between gap-3 mb-3">
        <div className="text-base font-medium">Where You're Logged In</div>
        <div className="inline-flex items-center gap-2">
          <Button disabled={disabled} onClick={handleLogout}>
            Log Out Other Browser Sessions
          </Button>
          <Button disabled={disabled} onClick={() => setShowAll(prev => !prev)}>
            Show {showAll ? 'Less' : 'All'}
          </Button>
        </div>
      </div>
      <div className="bg-white rounded-lg p-4">
        {sessionAsync.isLoading ? (
          <Spinner size="small" className="h-[10rem]" />
        ) : (
          data.slice(0, showAll ? data.length : 5).map(renderSession)
        )}
      </div>
    </Fragment>
  )
}

export default BrowserSessions
