import { Device } from 'services/devices'
import client, { ResponseMeta } from './client'

export interface Vault {
  account_id: number
  created_at: string
  device: Device
  device_id: number
  file_name: string
  file_path: string
  file_size_mb: string
  file_type: string
  file_url: string
  id: number
  updated_at: string
}

export type VaultFileResponse = {
  data: Vault[]
  meta: ResponseMeta
}

export const getVaultFiles = ({
  deviceId = undefined,
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = 10,
  currentPage = 1
}: {
  deviceId?: number
  search?: string
  sortBy?: string
  sortDirection?: string
  pageSize?: number
  currentPage?: number
}) => {
  return client.get<VaultFileResponse>(
    `/api/vaultFiles?include=device&filters[search]=${search}&filters[device_id]=${
      deviceId || ''
    }&order_by=${sortBy}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}`
  )
}

export const addRecordToVault = async (id: number) => {
  await client.get('/api/csrf-cookie')
  return client.post('/api/vaultFiles', { recording_download_id: id })
}

export const deleteVaultFile = (id: number) => {
  return client.delete(`/api/vaultFiles/${id}`)
}

export const shareVaultFile = async (
  vaultId: number,
  payload: {
    expiry_number: number
    expiry_type: string
    message?: string
    viewers: { email: string; recipient_id?: number; user_id?: number }[]
  }
) => {
  await client.get('/api/csrf-cookie')
  return client.post(`/api/vaultFiles/${vaultId}/share`, payload)
}

// ------------------------------------------------------------------------- //

export interface SharedVaultFile {
  id: number
  account_id: number
  device_id: number
  file_name: string
  file_path: string
  file_type: string
  file_size_mb: string
  created_at: string
  updated_at: string
  file_url: string
  device_name: string
}

export const getSharedVaultFile = (payload: {
  id: number
  email: string
  signature: string
}) => {
  return client.get<{ data: SharedVaultFile }>(
    `/api/external/sharedVaultFiles/${payload.id}?email=${encodeURIComponent(
      payload.email
    )}&signature=${payload.signature}`
  )
}
