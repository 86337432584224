import { useNavigate } from 'react-router-dom'

import { Box, Shield, Transaction, User } from 'icons/filled'
import { Popover } from 'components'
import MenuItem from 'shared/MenuItem'

interface Props {
  onClose: () => void
  onViewProfile: () => void
  onChangePassword: () => void
  onLogout: () => void
}

function UserMenu({
  onClose,
  onViewProfile,
  onChangePassword,
  onLogout
}: Props) {
  const navigate = useNavigate()

  const onClick = (fn: Function) => {
    return (...args: any) => {
      onClose()
      fn(...args)
    }
  }

  return (
    <Popover.Content className="w-[14.375rem] !p-2" onClose={onClose}>
      <MenuItem onClick={onClick(onViewProfile)}>
        <div className="w-8 h-8 rounded-full bg-primary-lighten flex items-center justify-center">
          <User type="primary" className="w-4 h-4 shrink-0" />
        </div>
        <div className="text-[0.8125rem]">My Profile</div>
      </MenuItem>
      <MenuItem onClick={onClick(() => navigate('/transaction-history'))}>
        <div className="w-8 h-8 rounded-full bg-primary-lighten flex items-center justify-center">
          <Transaction type="primary" className="w-4 h-4 shrink-0" />
        </div>
        <div className="text-[0.8125rem]">Transaction History</div>
      </MenuItem>
      <MenuItem onClick={onClick(() => navigate('/order-history'))}>
        <div className="w-8 h-8 rounded-full bg-primary-lighten flex items-center justify-center">
          <Box type="primary" className="w-4 h-4 shrink-0" />
        </div>
        <div className="text-[0.8125rem]">Order History</div>
      </MenuItem>
      <MenuItem onClick={onClick(onChangePassword)}>
        <div className="w-8 h-8 rounded-full bg-primary-lighten flex items-center justify-center">
          <Shield type="primary" className="w-4 h-4 shrink-0" />
        </div>
        <div className="text-[0.8125rem]">Change Password</div>
      </MenuItem>
      <div className="border-b border-light-border my-2.5" />
      <MenuItem className="text-danger" onClick={onClick(onLogout)}>
        Log out
      </MenuItem>
    </Popover.Content>
  )
}

export default UserMenu
