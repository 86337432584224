import { SetState } from 'zustand'

import toast from 'utils/toast'
import { getPlans, Plan } from 'services/subscriptions'
import { ResponseError } from 'services/client'

import { Store } from './'

export interface PlanSlice {
  loading: boolean
  plans: Plan[]
  getPlans: () => void
  setPlans: (payload: Plan[]) => void
}

const slice = (set: SetState<Store>) => ({
  loading: false,
  plans: [],
  getPlans: async () => {
    try {
      set(({ plan }) => ({ plan: { ...plan, loading: true } }))
      const response = await getPlans()
      set(({ plan }) => ({
        plan: { ...plan, plans: response.data.data, loading: false }
      }))
    } catch (e: any) {
      const error = e as ResponseError
      toast.error({ title: error.message, description: error.errors })
      set(({ plan }) => ({ plan: { ...plan, loading: false } }))
    }
  },
  setPlans: (payload: Plan[]) => {
    return set(({ plan }) => ({ plan: { ...plan, plans: payload } }))
  }
})

export default slice
