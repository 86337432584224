import { Navigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import { usePageTitle, useAsync } from 'hooks'
import { login } from 'services/auth'
import useStore, { Store } from 'store'

import LoginForm, { FormValues } from './LoginForm'
import TwoFAForm from './TwoFAForm'

type LoginResponse = AxiosResponse<{ two_factor: boolean }>

const mapState = ({ auth }: Store) => ({
  user: auth.currentUser,
  setUser: auth.setCurrentUser
})

function Login() {
  usePageTitle('Login | Broadflow Streams Cloud Video Surveillance')

  const store = useStore(mapState, shallow)
  const { execute, data, isLoading } = useAsync<LoginResponse>({
    showNotifOnError: true
  })

  const handleSubmit = (values: FormValues) => {
    execute(login(values))
      .then(res => !res.data.two_factor && handleSuccess())
      .catch(console.error)
  }

  const handleSuccess = () => {
    const lastPath = localStorage.getItem('APP/LAST_PATH')
    localStorage.removeItem('APP/LAST_PATH')
    const { href, origin } = window.location
    window.location.href = !!lastPath && lastPath !== href ? lastPath : origin
  }

  if (!!store.user) {
    return <Navigate to="/" />
  }

  return (
    <AnimatePresence initial={false}>
      {!data && <LoginForm loading={isLoading} onSubmit={handleSubmit} />}
      {!!data?.data.two_factor && <TwoFAForm onSuccess={handleSuccess} />}
    </AnimatePresence>
  )
}

export default Login
