import { Fragment, useEffect, useReducer, useRef } from 'react'
import { AxiosResponse } from 'axios'
import Hls, { ErrorData, Events } from 'hls.js'
import cx from 'classnames'

import { Close } from 'icons/outline'
import { DeviceIcon } from 'icons/utils'
import { useAsync } from 'hooks'
import { Spinner } from 'components'
import { getDeviceStatus } from 'utils/device'
import { ResponseError } from 'services/client'
import { Device, getDeviceDetails } from 'services/devices'

import LivestreamUI from '../LivestreamPlayer/LivestreamUI'

type Props = {
  deviceId?: number
  streamUrl?: string
  onRemove?: () => void
  showDeviceInfo?: boolean
} & JSX.IntrinsicElements['div']

const initState = {
  loading: false,
  error: '',
  muted: true,
  playing: false,
  currentTime: 0,
  retryCount: 0
}

type State = typeof initState

function LivestreamPlayer({
  deviceId,
  streamUrl,
  onRemove,
  showDeviceInfo,
  ...props
}: Props) {
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const deviceAsync = useAsync<AxiosResponse<{ data: Device }>>({
    status: 'pending'
  })

  const livestreamRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const hlsRef = useRef<Hls | null>(null)

  const device = deviceAsync.data?.data.data
  const url = device?.deviceLiveStream?.playlist_url || streamUrl

  useEffect(() => {
    if (!deviceId) return deviceAsync.setState({ status: 'resolved' })
    handleGetDeviceDetails()
  }, [deviceId])

  useEffect(() => {
    if (!url) return
    initPlayer()
    return () => cleanUp()
  }, [url])

  const handleGetDeviceDetails = async () => {
    deviceAsync
      .execute(getDeviceDetails(deviceId!))
      .then(res => {
        const device = res.data.data
        if (getDeviceStatus(device) === 'offline') {
          setState({ error: 'This device is offline' })
        }
        if (device.deviceLiveStream?.meta?.codec === 'H265') {
          setState({ error: 'This device does not support live streaming.' })
        }
      })
      .catch((err: ResponseError) => {
        // prettier-ignore
        setState({ error: err?.status === 404 ? `Device with id: ${deviceId} does not exist.` : 'Something went wrong, please try again.' })
      })
  }

  const initPlayer = () => {
    const videoEl = videoRef.current
    if (!videoEl || !url) return

    setState({ loading: true })
    if (!Hls.isSupported()) {
      videoEl.src = url
    } else {
      const newHls = new Hls()
      newHls.loadSource(url)
      newHls.attachMedia(videoEl)
      newHls.on(Events.ERROR, handleRetry)
      hlsRef.current = newHls
    }
  }

  const cleanUp = () => {
    hlsRef.current?.stopLoad()
    hlsRef.current?.detachMedia()
    hlsRef.current?.destroy()
    hlsRef.current = null
  }

  const handleRetry = (_: any, errData: ErrorData) => {
    if (!errData.fatal) return
    cleanUp()

    const newRetry = state.retryCount + 1
    if (newRetry > 3 || !deviceId) {
      return setState({ loading: false, error: 'Cannot load this livestream' })
    }
    setState({ ...initState, retryCount: newRetry, error: 'Reconnecting...' })
    deviceAsync.setState({ status: 'pending' })
    setTimeout(handleGetDeviceDetails, 500)
  }

  const handleReady = () => {
    videoRef.current!.play().catch(console.error)
    setState({ loading: false, error: '' })
  }

  const handleTimeUpdate = () => {
    setState({ currentTime: videoRef.current?.currentTime || 0 })
  }

  return (
    <div
      {...props}
      ref={livestreamRef}
      className={cx(
        'group relative w-full h-full overflow-hidden',
        props.className
      )}
    >
      {!!device && (
        <Fragment>
          {showDeviceInfo !== false && (
            <div className="base-transition absolute z-[3] top-0 left-0 bg-black/50 backdrop-blur-[20px] rounded-br-md py-0.5 px-1.5 text-[0.8125rem] opacity-0 group-hover:opacity-100">
              <div className="inline-flex items-center gap-1">
                {/* prettier-ignore */}
                <DeviceIcon type={device.type} className={cx('w-4 h-4 shrink-0', getDeviceStatus(device) === 'online' ? 'text-success' : 'text-danger')} />
                <div className="text-white">{device.name}</div>
              </div>
            </div>
          )}
          {!state.error && <LivestreamUI.LiveIcon className="!z-[3]" />}
          {onRemove && (
            <div
              onClick={onRemove}
              className="base-transition absolute z-[3] top-0 right-0 w-10 h-10 bg-black/50 inline-flex items-center justify-center backdrop-blur-[20px] cursor-pointer rounded-bl-md hover:bg-primary opacity-0 group-hover:opacity-100"
            >
              <Close className="w-4 h-4 pointer-events-none" />
            </div>
          )}
        </Fragment>
      )}

      {(deviceAsync.isLoading || state.loading) && (
        <Spinner className="absolute z-[4] top-0 left-0 w-full h-full bg-black/70" />
      )}
      {!!state.error && (
        <div className="absolute z-[2] top-0 left-0 w-full h-full flex justify-center items-center text-center pointer-events-none">
          <div className="text-light-secondary text-base tracking-[.2px]">
            {state.error}
          </div>
        </div>
      )}

      <video
        ref={videoRef}
        className="absolute z-[1] top-0 left-0 w-full h-full"
        muted={state.muted}
        onLoadedMetadata={handleReady}
        onTimeUpdate={handleTimeUpdate}
        onPlaying={() => setState({ playing: true })}
        onPause={() => setState({ playing: false })}
        onCanPlay={() => setState({ loading: false })}
        onWaiting={() => setState({ loading: true })}
      />

      {!state.error && (
        <Fragment>
          <LivestreamUI.CurrentDateTime />
          <LivestreamUI.Controls
            className="opacity-0 group-hover:opacity-100"
            videoEl={videoRef.current!}
            muted={state.muted}
            onVolumeChange={() => setState({ muted: !state.muted })}
            fullscreenEl={livestreamRef.current!}
          />
        </Fragment>
      )}
    </div>
  )
}

export default LivestreamPlayer
