import client from './client'

export interface Config {
  pusher_app_cluster: string
  pusher_app_key: string
  stripe_key: string
}

export const getConfig = () => {
  return client.get<{ data: Config }>('/api/config')
}
