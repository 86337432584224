import client from './client'

export interface Product {
  id: number
  name: string
  price: number
  price_in_dollars: number
}

export type ProductResponse = { data: Product[] }

export const getProducts = () => {
  return client.get<ProductResponse>('/api/products')
}
