import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Cloud({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M21.0362 7.79145C21.1056 7.41378 21.1406 7.02856 21.1406 6.64062C21.1406 3.15111 18.302 0.3125 14.8125 0.3125C12.585 0.3125 10.5203 1.49905 9.38597 3.38455C7.85475 2.88814 6.28369 3.101 4.98938 3.99294C3.69506 4.88417 2.92256 6.29525 2.86008 7.83814C1.15856 8.64631 0 10.367 0 12.2656C0 12.5952 0.0798281 12.9166 0.140906 13.2283L0.141609 13.229C0.596203 15.5231 2.62659 17.1875 4.96875 17.1875H19.0312C21.7449 17.1875 24 14.9793 24 12.2656C24 10.3465 22.8256 8.61884 21.0362 7.79145Z"
          fill="url(#paint0_linear_7965_18308)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7965_18308"
            x1="0"
            y1="0.3125"
            x2="0"
            y2="17.1875"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
