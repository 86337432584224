import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function VideoPlayer({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M13.9068 0.625H11.1718L6.48407 4.14062H9.21911L13.9068 0.625Z"
          fill="url(#paint0_linear_11328_4201)"
        />
        <path
          d="M18.6333 0.625H15.8593L11.1716 4.14062H13.9066L18.6333 0.625Z"
          fill="url(#paint1_linear_11328_4201)"
        />
        <path
          d="M15.8591 4.14057H20V1.21088C20 1.16568 19.984 1.12576 19.9744 1.0835L15.8591 4.14057Z"
          fill="url(#paint2_linear_11328_4201)"
        />
        <path
          d="M1.75751 4.14062H4.53161L9.21935 0.625H6.48427L1.75751 4.14062Z"
          fill="url(#paint3_linear_11328_4201)"
        />
        <path
          d="M0 18.7891C0 19.1129 0.26207 19.375 0.585938 19.375H19.4141C19.7379 19.375 20 19.1129 20 18.7891V5.3125H0V18.7891ZM7.07031 8.24219C7.07031 8.03105 7.18359 7.83648 7.3673 7.73234C7.55102 7.6282 7.77645 7.63105 7.95781 7.73977L13.8172 11.2554C13.9934 11.3612 14.1016 11.5518 14.1016 11.7578C14.1016 11.9637 13.9934 12.1543 13.8172 12.2602L7.95781 15.7758C7.77633 15.8845 7.55082 15.8873 7.3673 15.7832C7.18359 15.6791 7.07031 15.4846 7.07031 15.2734V8.24219Z"
          fill="url(#paint4_linear_11328_4201)"
        />
        <path
          d="M0.585938 0.625C0.26207 0.625 0 0.88707 0 1.21094V3.99441L4.53188 0.625H0.585938Z"
          fill="url(#paint5_linear_11328_4201)"
        />
        <path
          d="M8.24219 9.27734V14.2384L12.377 11.7579L8.24219 9.27734Z"
          fill="url(#paint6_linear_11328_4201)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_11328_4201"
            x1="10.1955"
            y1="0.625"
            x2="10.1955"
            y2="4.14062"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_11328_4201"
            x1="14.9024"
            y1="0.625"
            x2="14.9024"
            y2="4.14062"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_11328_4201"
            x1="17.9296"
            y1="1.0835"
            x2="17.9296"
            y2="4.14057"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint3_linear_11328_4201"
            x1="5.48843"
            y1="0.625"
            x2="5.48843"
            y2="4.14062"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint4_linear_11328_4201"
            x1="10"
            y1="5.3125"
            x2="10"
            y2="19.375"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint5_linear_11328_4201"
            x1="2.26594"
            y1="0.625"
            x2="2.26594"
            y2="3.99441"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint6_linear_11328_4201"
            x1="10.3096"
            y1="9.27734"
            x2="10.3096"
            y2="14.2384"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
