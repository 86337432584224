import {
  format,
  add,
  lastDayOfMonth,
  lastDayOfYear,
  set,
  startOfMonth,
  startOfYear,
  startOfWeek,
  endOfWeek
} from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import { leadZero } from './functions'

export const MAX_PLAYBACK_HOUR = 4

export const timezones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Adak'
]

export const dateTimeFormat = {
  'MM-dd-yyyy': 'MM-dd-yyyy',
  'MMM dd, yyyy': 'MMM dd, yyyy',
  'yyyy-MM-dd': 'yyyy-MM-dd',
  'MM/dd/yyyy': 'MM/dd/yyyy',
  'hh:mm a': 'hh:mm a',
  'HH:mm': 'HH:mm',
  'HH:mm:ss': 'HH:mm:ss',
  'HH:mm:ss a': 'HH:mm:ss a'
}

export const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

export const shortDay = (day: string) => {
  return day.substring(0, 1).toUpperCase() + day.substring(1, 3).toLowerCase()
}

export const formatTime = (timeStr: string, f = dateTimeFormat['hh:mm a']) => {
  const [h = 0, m = 0] = timeStr.split(':')
  return format(set(new Date(), { hours: +h, minutes: +m }), f)
}

export const formatInTz = (
  date: Date | number,
  tz = 'America/New_York',
  f = `${dateTimeFormat['MM/dd/yyyy']} ${dateTimeFormat['HH:mm:ss']}`
) => {
  return formatInTimeZone(date, tz, f, { timeZone: tz })
}

export const getFirstLastDayOfWeek = (weeks = 0): [Date, Date] => {
  const date = add(new Date(), { weeks })
  return [
    startOfWeek(date, { weekStartsOn: 1 }),
    endOfWeek(date, { weekStartsOn: 1 })
  ]
}

export const getFirstLastDayOfMonth = (months = 0): [Date, Date] => {
  const date = add(new Date(), { months })
  return [startOfMonth(date), lastDayOfMonth(date)]
}

export const getFirstLastDayOfYear = (years = 0): [Date, Date] => {
  const date = add(new Date(), { years })
  return [startOfYear(date), lastDayOfYear(date)]
}

export const datePickerPresets = [
  { label: 'Today', value: new Date() },
  { label: 'This Week', value: getFirstLastDayOfWeek() },
  { label: 'This Month', value: getFirstLastDayOfMonth() },
  { label: 'This Year', value: getFirstLastDayOfYear() },
  { label: 'Last Week', value: getFirstLastDayOfWeek(-1) },
  { label: 'Last Month', value: getFirstLastDayOfMonth(-1) },
  { label: 'Last Year', value: getFirstLastDayOfYear(-1) }
]

export const dateRangePickerPresets = [
  { label: 'All Time', value: [null, null] },
  { label: 'This Week', value: getFirstLastDayOfWeek() },
  { label: 'This Month', value: getFirstLastDayOfMonth() },
  { label: 'This Year', value: getFirstLastDayOfYear() },
  { label: 'Last Week', value: getFirstLastDayOfWeek(-1) },
  { label: 'Last Month', value: getFirstLastDayOfMonth(-1) },
  { label: 'Last Year', value: getFirstLastDayOfYear(-1) }
]

export const secondsToHms = (secs: number) => {
  const h = Math.floor(secs / 3600)
  const m = Math.floor((secs - h * 3600) / 60)
  const s = Math.floor(secs - h * 3600 - m * 60)
  return `${leadZero(h)}:${leadZero(m)}:${leadZero(s)}`
}

export const hmsToSeconds = (hms: string) => {
  const [h, m, s] = hms.split(':')
  const hSecs = +h * 60 * 60
  const mSecs = +m * 60
  return hSecs + mSecs + +s
}
