import { useReducer, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import { ChevronDown } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Form, Modal, ModalProps, Popover, Tabs } from 'components'
import PopoverHelpMenu from 'shared/Popovers/HelpMenu'
import ModalGuideBook from 'shared/Modals/GuideBook'
import toast from 'utils/toast'
import { isCamera } from 'utils/device'
import {
  Device,
  checkStreamUrl,
  updateDevice,
  getDeviceDetails
} from 'services/devices'
import useStore, { Store } from 'store'

import DeviceDetail from './DeviceDetail'
import CloudSurveillance from './CloudSurveillance'
import { FormValues, mapInitFormValues } from './FormHelpers'

interface Props extends ModalProps {
  device: Device
}

const mapState = (state: Store) => ({
  updateDevice: state.device.updateDevice
})

function ModalEditDevice({ device, ...props }: Props) {
  const { show } = useIntercom()
  const [deviceDetailForm] = Form.useForm()
  const store = useStore(mapState, shallow)

  const [showGuideBook, setShowGuideBook] = useState(false)
  const [formValues, setFormValues] = useReducer(
    (s: FormValues, a: Partial<FormValues>) => ({ ...s, ...a }),
    mapInitFormValues(device)
  )

  const updateAsync = useAsync({ showNotifOnError: true })
  const checkAsync = useAsync({ showNotifOnError: true })

  const handleClose = () => {
    ;(!updateAsync.isLoading || !checkAsync.isLoading) && props.onClose()
  }

  const renderPopoverHelpMenu = (onClose: () => void) => (
    <PopoverHelpMenu
      onContactSupport={() => show()}
      onOpenGuideBook={() => setShowGuideBook(true)}
      onClose={onClose}
    />
  )

  const handleSubmit = () => {
    const deviceDetailErrors = []

    if (!formValues.deviceDetail.name) {
      deviceDetailErrors.push({
        name: 'name',
        errors: ['This field is required']
      })
    }

    if (!!deviceDetailErrors.length) {
      toast.error({
        title: 'Invalid form',
        description: 'The provided data is not valid'
      })
      return deviceDetailForm.setFields(deviceDetailErrors)
    }

    handleUpdateDevice()
  }

  const handleUpdateDevice = async () => {
    const { deviceDetail, cloudSurveillance } = formValues
    const streamUrl = cloudSurveillance.stream_url || device.stream_url || ''

    if (!!streamUrl && streamUrl !== (device.stream_url || '')) {
      await checkAsync.execute(checkStreamUrl(streamUrl))
    }

    await updateAsync.execute(
      updateDevice(device.id, {
        name: deviceDetail.name,
        timezone: deviceDetail.timezone?.value || '',
        location_id: (deviceDetail.location?.value || '').toString(),
        stream_url: streamUrl
      })
    )

    store.updateDevice(device.id, { __loading: true })
    getDeviceDetails(device.id).then(res =>
      store.updateDevice(device.id, { ...res.data.data, __loading: false })
    )

    toast.success({ title: 'Device Updated' }, { position: 'bottom-center' })
    handleClose()
  }

  return (
    <Modal
      {...props}
      wrapperClassName="!p-0"
      className="w-[100vw] min-h-[100vh] rounded-none !p-0"
      onClose={handleClose}
    >
      <div className="pt-3 px-[5.3125rem] flex items-center justify-between gap-4 mb-3">
        <div className="text-base font-medium">Update Device</div>
        <div className="inline-flex items-center gap-2">
          <Popover
            placement="bottom-end"
            content={onClose => renderPopoverHelpMenu(onClose)}
          >
            <div className="text-primary inline-flex items-center gap-1 mr-4 text-[0.8125rem] font-medium cursor-pointer">
              Help <ChevronDown />
            </div>
          </Popover>
          <Button
            className="w-20"
            disabled={updateAsync.isLoading || checkAsync.isLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="w-[7.5rem]"
            disabled={updateAsync.isLoading || checkAsync.isLoading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
      <Tabs className="modal-tabs">
        <Tabs.TabPane key={1} tab="Device Detail">
          <DeviceDetail
            form={deviceDetailForm}
            values={formValues.deviceDetail}
            setValues={setFormValues}
          />
        </Tabs.TabPane>
        {isCamera(device.type) && (
          <Tabs.TabPane key={2} tab="Cloud Surveillance">
            <CloudSurveillance
              loading={checkAsync.isLoading}
              values={formValues.cloudSurveillance}
              setValues={setFormValues}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
      <AnimatePresence initial={false}>
        {showGuideBook && (
          <ModalGuideBook onClose={() => setShowGuideBook(false)} />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default ModalEditDevice
