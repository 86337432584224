import { useEffect, useReducer } from 'react'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import cx from 'classnames'

import {
  CheckedCircle,
  ClockCircle,
  Search,
  WarningCircle
} from 'icons/outline'
import { useAsync } from 'hooks'
import { Input, Pagination, Table } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import { dateTimeFormat } from 'utils/dateTime'
import {
  getOutboundNotifs,
  OutboundNotif,
  OutboundNotifResponse
} from 'services/notifications'

interface Props {
  inboundNotifId: number
}

interface State {
  sortBy: string
  sortDirection: string
  search: string
  pageSize: PageSizes
  currentPage: number
}

const initState: State = {
  sortBy: 'id',
  sortDirection: 'desc',
  search: '',
  pageSize: 10,
  currentPage: 1
}

function OutboundNotification({ inboundNotifId }: Props) {
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const outboundAsync = useAsync<AxiosResponse<OutboundNotifResponse>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    outboundAsync.execute(getOutboundNotifs(inboundNotifId, state))
  }, [...Object.values(state)])

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const handleSearch: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      setState({ search: value, currentPage: 1 })
    }
  }

  const renderTimestampCol = (notif: OutboundNotif) => {
    return format(
      new Date(notif.created_at),
      `${dateTimeFormat['MMM dd, yyyy']} ${dateTimeFormat['hh:mm a']}`
    )
  }

  const renderStatusCol = ({ status }: OutboundNotif) => {
    return (
      <div
        className={cx('flex items-center gap-1', {
          'text-success': status === 'success',
          'text-warning': status === 'initiated',
          'text-danger': status === 'failed'
        })}
      >
        {status === 'success' && (
          <CheckedCircle className="w-3.5 h-3.5 shrink-0" />
        )}
        {status === 'initiated' && (
          <ClockCircle className="w-3.5 h-3.5 shrink-0" />
        )}
        {status === 'failed' && (
          <WarningCircle className="w-3.5 h-3.5 shrink-0" />
        )}
        {status === 'success'
          ? 'Sent'
          : status === 'initiated'
          ? 'Pending'
          : 'Failed'}
      </div>
    )
  }

  const columns = [
    {
      key: 'created_at',
      title: 'timestamp',
      sorter: true,
      render: renderTimestampCol
    },
    {
      key: 'via',
      title: 'channel',
      dataIndex: 'via',
      sorter: true
    },
    {
      key: 'to',
      title: 'recipient',
      dataIndex: 'to',
      sorter: true
    },
    {
      key: 'status',
      title: 'status',
      sorter: true,
      render: renderStatusCol
    }
  ]

  const loading = outboundAsync.isLoading

  return (
    <div className="mt-4">
      <div className="mb-4 flex flex-wrap justify-between items-center gap-4">
        <Input
          suffix={<Search />}
          className="w-[22.5rem]"
          onKeyDown={loading ? undefined : handleSearch}
          placeholder="Search notifications by recipient"
        />
        <Pagination.ButtonGroup
          loading={loading}
          currentPage={state.currentPage}
          totalPage={outboundAsync.data?.data.meta.last_page || 1}
          onPageChange={loading ? undefined : p => setState({ currentPage: p })}
        />
      </div>
      <Table
        rowKey="id"
        loading={loading}
        data={outboundAsync.data?.data.data}
        columns={columns}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={loading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={outboundAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
    </div>
  )
}

export default OutboundNotification
