import { useNavigate } from 'react-router-dom'

import { useAsync } from 'hooks'
import { ChevronLeft } from 'icons/outline'
import { Button, Form, FormField, Input } from 'components'
import { EmailVerificationLink } from 'shared/EmailVerificationLink'
import { email, required } from 'utils/rules'
import { forgotPassword } from 'services/auth'
import { AnimatePresence, motion } from 'framer-motion'

type FormValues = {
  email: string
}

function SendResetLinkForm() {
  const navigate = useNavigate()
  const { execute, isLoading, isSuccess } = useAsync({ showNotifOnError: true })

  const handleSubmit = (values: FormValues) => {
    execute(forgotPassword(values))
  }

  return (
    <AnimatePresence initial={false}>
      {!isSuccess && (
        <div className="m-auto bg-white w-[25.625rem] p-6 rounded-lg">
          <div className="flex items-center gap-[0.8125rem]">
            <Button onClick={() => navigate('/login')}>
              <ChevronLeft />
            </Button>
            <h4 className="font-medium text-base">Recover password</h4>
          </div>
          <div className="mt-8 mb-2 text-[0.8125rem]">
            Please enter your email to get reset password link
          </div>
          <Form onFinish={handleSubmit}>
            <FormField name="email" rules={[required, email]}>
              <Input block placeholder="Enter your email address" />
            </FormField>
            <Button disabled={isLoading} block size="large" variant="primary">
              Email me recovery link
            </Button>
          </Form>
        </div>
      )}
      {isSuccess && (
        <motion.div
          initial={{ x: '100vw' }}
          animate={{ x: 0 }}
          transition={{ type: 'tween', duration: 0.2 }}
          className="m-auto w-[25.625rem] bg-white p-6 rounded-lg"
        >
          <EmailVerificationLink.Sent
            title="Reset password link sent"
            action={
              <Button
                block
                size="large"
                variant="primary"
                onClick={() => navigate('/login')}
              >
                Back to Login page
              </Button>
            }
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default SendResetLinkForm
