import { ReactNode } from 'react'
import { toast as toastify, ToastOptions, TypeOptions } from 'react-toastify'
import { ToastContentProps } from 'react-toastify/dist/types'
import cx from 'classnames'

import { Close } from 'icons/outline'
import { CheckedCircle, InfoCircle, WarningCircle } from 'icons/filled'

const defaultConfig: ToastOptions = {
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined
}

type Message = {
  icon?: ReactNode
  title: string | ReactNode
  description?: string | { [key: string]: string[] }
  action?: ReactNode
}

const renderMessage = (message: Message) => (props: ToastContentProps<any>) => {
  const { title, description: desc, action, icon } = message
  return (
    <div className="flex gap-2">
      {icon && (
        <div className="toast-icon w-[1.625rem] h-[1.625rem] shrink-0">
          {icon}
        </div>
      )}
      <div className={cx('toast-body mr-2 grow', !desc && 'self-center')}>
        <div className={cx('title text-sm', desc && 'font-semibold')}>
          {title}
        </div>
        {desc && (
          <div className="description text-[0.8125rem] mt-[4px]">
            {typeof desc === 'string'
              ? desc
              : Object.keys(desc).map(k => <div key={k}>{desc[k][0]}</div>)}
          </div>
        )}
        {action && <div className="action mt-2">{action}</div>}
      </div>
      <Close
        className="shrink-0 w-3 h-3 text-light-primary mt-[6px]"
        onClick={props.closeToast}
      />
    </div>
  )
}

const getToastIcon = (type: TypeOptions, icon?: ReactNode) => {
  if (icon) return icon
  if (type === 'info') return <InfoCircle type="info" />
  if (type === 'success') return <CheckedCircle type="success" />
  if (type === 'warning') return <WarningCircle type="warning" />
  if (type === 'error') return <WarningCircle type="danger" />
  return undefined
}

const _toast = (message: Message, config?: ToastOptions) => {
  const { type = 'info', className } = config || {}
  const icon = getToastIcon(type, message.icon)
  return toastify(renderMessage({ ...message, icon }), {
    ...defaultConfig,
    ...config,
    className: cx('custom-toast', `toast-${type}`, className as string)
  })
}

const genToast = (type: TypeOptions) => {
  return (message: Message, config?: ToastOptions) => {
    return _toast(message, { ...config, type })
  }
}

const toast = {
  info: genToast('info'),
  success: genToast('success'),
  warning: genToast('warning'),
  error: genToast('error')
}

export default toast
