import { FC, ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import cx from 'classnames'

import { Tabs } from 'components'

export interface RouteTab {
  key: string
  tab: ReactNode
  Component: FC<any>
}

interface Props {
  tabs: RouteTab[]
  className?: string
  children?: ReactNode | ((tabs: RouteTab[], activeKey: string) => ReactNode)
  destroyInactiveTabPane?: boolean
}

const RouteTabs: FC<Props> = ({
  tabs,
  className,
  children,
  destroyInactiveTabPane
}) => {
  const navigate = useNavigate()
  const { pathname, search, state } = useLocation()
  const [activeKey, setActiveKey] = useState('')

  useEffect(() => {
    const keyFromSearch = search.replace('?tab=', '')
    const activeTab = tabs.find(tab => tab.key === keyFromSearch) || tabs[0]
    handleTabChange(activeTab.key)
  }, [search])

  const handleTabChange = (tabKey: string) => {
    setActiveKey(tabKey)
    navigate(`${pathname}?tab=${tabKey}`, { replace: true, state })
  }

  const renderTabs = () => {
    return tabs.map(({ key, tab, Component }) => (
      <Tabs.TabPane key={key} tab={tab}>
        <Component activeKey={activeKey} />
      </Tabs.TabPane>
    ))
  }

  return (
    <Tabs
      activeKey={activeKey}
      onChange={handleTabChange}
      className={cx('padding-nav-wrap', className)}
      destroyInactiveTabPane={destroyInactiveTabPane}
    >
      {typeof children === 'function'
        ? children(tabs, activeKey)
        : children || renderTabs()}
    </Tabs>
  )
}

export default RouteTabs
