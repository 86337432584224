import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function LocationPinAlt({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="26"
        height="32"
        viewBox="0 0 26 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_f_2_14)">
          <path
            d="M12.8656 24.6139C15.7893 24.6139 18.1594 23.4985 18.1594 22.1226C18.1594 20.7468 15.7893 19.6314 12.8656 19.6314C9.9419 19.6314 7.57178 20.7468 7.57178 22.1226C7.57178 23.4985 9.9419 24.6139 12.8656 24.6139Z"
            fill="black"
            fillOpacity="0.5"
          />
        </g>
        <mask
          id="mask0_2_14"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="0"
          width="18"
          height="23"
        >
          <path d="M22 0.411102H4.69996V22.5552H22V0.411102Z" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.39196 8.86592C5.39196 4.58504 8.84991 1.10312 13.0991 1.10312C17.3484 1.10312 20.8063 4.58504 20.8063 8.86592C20.8063 14.9486 13.8236 21.2291 13.5265 21.4937C13.404 21.6016 13.2516 21.6556 13.0991 21.6556C12.9467 21.6556 12.7942 21.6016 12.6718 21.4929C12.3746 21.2291 5.39196 14.9486 5.39196 8.86592ZM8.81711 8.81029C8.81711 11.1713 10.7379 13.092 13.0989 13.092C15.4598 13.092 17.3806 11.1713 17.3806 8.81029C17.3806 6.44932 15.4598 4.52853 13.0989 4.52853C10.7379 4.52853 8.81711 6.44932 8.81711 8.81029Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_2_14)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.39196 8.86592C5.39196 4.58504 8.84991 1.10312 13.0991 1.10312C17.3484 1.10312 20.8063 4.58504 20.8063 8.86592C20.8063 14.9486 13.8236 21.2291 13.5265 21.4937C13.404 21.6016 13.2516 21.6556 13.0991 21.6556C12.9467 21.6556 12.7942 21.6016 12.6718 21.4929C12.3746 21.2291 5.39196 14.9486 5.39196 8.86592ZM8.81711 8.81029C8.81711 11.1713 10.7379 13.092 13.0989 13.092C15.4598 13.092 17.3806 11.1713 17.3806 8.81029C17.3806 6.44932 15.4598 4.52853 13.0989 4.52853C10.7379 4.52853 8.81711 6.44932 8.81711 8.81029Z"
            fill="url(#paint0_linear_2_14)"
          />
          <path
            d="M13.5265 21.4937L13.9839 22.0129L13.9866 22.0105L13.5265 21.4937ZM12.6718 21.4929L13.1313 20.9755L13.1312 20.9753L12.6718 21.4929ZM13.0991 0.411102C8.4631 0.411102 4.69996 4.20749 4.69996 8.86591H6.08396C6.08396 4.96257 9.23674 1.79511 13.0991 1.79511V0.411102ZM21.4983 8.86591C21.4983 4.20749 17.7351 0.411102 13.0991 0.411102V1.79511C16.9616 1.79511 20.1143 4.96257 20.1143 8.86591H21.4983ZM13.9866 22.0105C14.1512 21.864 16.0086 20.1934 17.8303 17.7783C19.6333 15.388 21.4983 12.143 21.4983 8.86591H20.1143C20.1143 11.6716 18.4879 14.6081 16.7254 16.9449C14.9814 19.2569 13.1989 20.8588 13.0663 20.9769L13.9866 22.0105ZM13.0991 22.3476C13.4152 22.3476 13.7316 22.2352 13.9839 22.0129L13.069 20.9745C13.0721 20.9718 13.0768 20.9687 13.0826 20.9665C13.0881 20.9645 13.0937 20.9636 13.0991 20.9636V22.3476ZM12.2123 22.0103C12.4654 22.235 12.7828 22.3476 13.0991 22.3476V20.9636C13.1045 20.9636 13.1104 20.9645 13.1164 20.9667C13.1229 20.9692 13.128 20.9725 13.1313 20.9755L12.2123 22.0103ZM4.69996 8.86591C4.69996 12.143 6.56493 15.388 8.368 17.7782C10.1896 20.193 12.047 21.8635 12.2124 22.0104L13.1312 20.9753C12.9995 20.8584 11.217 19.2568 9.47292 16.9448C7.71033 14.6081 6.08396 11.6716 6.08396 8.86591H4.69996ZM13.0989 12.4C11.1201 12.4 9.50911 10.7891 9.50911 8.81027H8.1251C8.1251 11.5534 10.3557 13.784 13.0989 13.784V12.4ZM16.6886 8.81027C16.6886 10.7891 15.0776 12.4 13.0989 12.4V13.784C15.842 13.784 18.0726 11.5534 18.0726 8.81027H16.6886ZM13.0989 5.22051C15.0776 5.22051 16.6886 6.83149 16.6886 8.81027H18.0726C18.0726 6.06713 15.842 3.83651 13.0989 3.83651V5.22051ZM9.50911 8.81027C9.50911 6.83149 11.1201 5.22051 13.0989 5.22051V3.83651C10.3557 3.83651 8.1251 6.06713 8.1251 8.81027H9.50911Z"
            fill="white"
          />
          <path
            d="M13.177 15.2719C16.6166 15.2719 19.405 12.4835 19.405 9.04384C19.405 5.6042 16.6166 2.81583 13.177 2.81583C9.73735 2.81583 6.94897 5.6042 6.94897 9.04384C6.94897 12.4835 9.73735 15.2719 13.177 15.2719Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_2_14"
            x="0.571777"
            y="12.6314"
            width="24.5876"
            height="18.9824"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="3.5"
              result="effect1_foregroundBlur_2_14"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2_14"
            x1="13.0991"
            y1="1.10312"
            x2="13.0991"
            y2="21.6556"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
