import { useEffect, useReducer, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'

import { Delete, Download, EyeOpen, Share } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Table } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import ModalPlayback from 'shared/Modals/Players/Playback'
import ModalShareRecord from 'shared/Modals/Record/ShareRecord'
import ModalConfirmDelete from 'shared/Modals/Confirm/ConfirmDelete'
import toast from 'utils/toast'
import { downloadFile } from 'utils/file'
import {
  deleteVaultFile,
  getVaultFiles,
  Vault,
  VaultFileResponse
} from 'services/vaults'

import TableToolbar from './TableToolbar'

interface Props {
  deviceId: number
}

interface State {
  sortBy: string
  sortDirection: string
  search: string
  currentPage: number
  pageSize: PageSizes
}

const initState: State = {
  sortBy: 'id',
  sortDirection: 'desc',
  search: '',
  currentPage: 1,
  pageSize: 10
}

function VideoVault({ deviceId }: Props) {
  const [viewVaultUrl, setViewVaultUrl] = useState<string | null>(null)
  const [shareVaultId, setShareVaultId] = useState<number | null>(null)
  const [deleteVaultId, setDeleteVaultId] = useState<number | null>(null)

  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const vaultAsync = useAsync<AxiosResponse<VaultFileResponse>>({
    status: 'pending',
    showNotifOnError: true
  })
  const deleteVaultAsync = useAsync({
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetVaultFiles()
  }, [...Object.values(state)])

  const handleGetVaultFiles = () => {
    return vaultAsync.execute(getVaultFiles({ ...state, deviceId }))
  }

  const handleDeleteRecord = async () => {
    if (!deleteVaultId) return
    const id = deleteVaultId
    setDeleteVaultId(null)
    try {
      await deleteVaultAsync.execute(deleteVaultFile(id))
      toast.success({ title: 'Record Deleted' }, { position: 'bottom-center' })
      if (vaultAsync.data?.data.data.length === 1 && state.currentPage > 1) {
        return setState({ currentPage: state.currentPage - 1 })
      }
      handleGetVaultFiles()
    } catch (err) {
      console.error(err)
    }
  }

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const renderActionCol = ({ id, file_url }: Vault) => (
    <div className="flex justify-end items-center gap-2">
      <Button variant="ternary" onClick={() => setViewVaultUrl(file_url)}>
        <EyeOpen /> View
      </Button>
      <Button variant="ternary" onClick={() => setShareVaultId(id)}>
        <Share /> Share
      </Button>
      <Button variant="ternary" onClick={() => downloadFile(file_url)}>
        <Download /> Download
      </Button>
      <Button variant="ternary" onClick={() => setDeleteVaultId(id)}>
        <Delete /> Delete
      </Button>
    </div>
  )

  const columns = [
    {
      key: 'file_name',
      title: 'record',
      sorter: true,
      render: (row: Vault) => row.file_name
    },
    {
      key: 'file_type',
      title: 'file type',
      sorter: true,
      render: (row: Vault) => row.file_type
    },
    {
      key: 'file_size_mb',
      title: 'file size',
      sorter: true,
      render: (row: Vault) => `${row.file_size_mb} MB`
    },
    {
      width: 400,
      render: renderActionCol
    }
  ]

  const loading = vaultAsync.isLoading || deleteVaultAsync.isLoading

  return (
    <div className="p-4">
      <TableToolbar
        loading={loading}
        search={state.search}
        onSearchChange={s => setState({ search: s, currentPage: 1 })}
        currentPage={state.currentPage}
        totalPage={vaultAsync.data?.data.meta.last_page || 1}
        onPageChange={p => setState({ currentPage: p })}
      />
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        data={vaultAsync.data?.data.data}
        scroll={{ x: 1400 }}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={loading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={vaultAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
      <AnimatePresence initial={false}>
        {!!viewVaultUrl && (
          <ModalPlayback
            url={viewVaultUrl}
            onClose={() => setViewVaultUrl(null)}
          />
        )}
        {!!shareVaultId && (
          <ModalShareRecord
            vaultId={shareVaultId}
            onClose={() => setShareVaultId(null)}
          />
        )}
        {!!deleteVaultId && (
          <ModalConfirmDelete
            modalTitle="delete record"
            confirmText="Are you sure you want to remove this record?"
            warnText="This action cannot be undone"
            onConfirm={handleDeleteRecord}
            onClose={() => setDeleteVaultId(null)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default VideoVault
