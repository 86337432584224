import { AxiosResponse } from 'axios'
import { omit } from 'lodash'

import { Close, Crown } from 'icons/outline'
import { AddPeople } from 'icons/filled'
import { useAsync } from 'hooks'
import {
  Alert,
  Button,
  Form,
  FormField,
  Input,
  InputPassword,
  Modal,
  ModalProps,
  SelectButton
} from 'components'
import toast from 'utils/toast'
import { email, required } from 'utils/rules'
import { matchPasswords, validatePassword } from 'utils/validation'
import { createUser, CreateUserResponse } from 'services/users'
import useStore from 'store'

interface Props extends ModalProps {
  onSuccess?: (createdUser: CreateUserResponse['data']) => void
}

interface FormValues {
  name: string
  email: string
  password: string
  rePassword: string
  role: 'standard' | 'admin'
}

function ModalCreateUser({ onSuccess, ...props }: Props) {
  const [form] = Form.useForm()

  const user = useStore(state => state.auth.currentUser)
  const { execute, isLoading } = useAsync<AxiosResponse<CreateUserResponse>>({
    showNotifOnError: true
  })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const onSubmit = async (values: FormValues) => {
    const { password, rePassword } = values

    const matchPasswordsResult = matchPasswords(password, rePassword)
    if (matchPasswordsResult !== true) {
      return form.setFields([
        { name: 'password', errors: [matchPasswordsResult] },
        { name: 'rePassword', errors: [matchPasswordsResult] }
      ])
    }
    const passwordResult = validatePassword(password)
    if (passwordResult !== true) {
      return form.setFields([{ name: 'password', errors: [passwordResult] }])
    }
    const rePasswordResult = validatePassword(rePassword)
    if (rePasswordResult !== true) {
      return form.setFields([
        { name: 'rePassword', errors: [rePasswordResult] }
      ])
    }

    handleCreateUser(values)
  }

  const handleCreateUser = async (values: FormValues) => {
    try {
      const response = await execute(
        createUser({
          ...omit(values, ['rePassword', 'role']),
          is_super_admin: false,
          is_admin: values.role === 'admin',
          account_id: user!.account_id
        })
      )
      toast.success(
        { title: `${values.name} was created & pending for accept` },
        { position: 'bottom-center' }
      )
      handleClose()
      onSuccess?.(response.data.data)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal {...props} className="w-[33.75rem]" onClose={handleClose}>
      <div className="flex justify-between items-center gap-4 mb-6">
        <div className="inline-flex items-center gap-2">
          <AddPeople type="primary" className="w-4 h-4" />
          <div className="text-base font-medium">Create User</div>
        </div>
        <Close onClick={handleClose} className="cursor-pointer" />
      </div>
      <Alert type="success" showIcon={false} className="mb-4">
        An email will be sent to the recipient to verify their address
      </Alert>
      <Form
        form={form}
        initialValues={{ role: 'standard' }}
        onFinish={onSubmit}
      >
        <FormField
          name="name"
          label="Full Name"
          rules={[required]}
          requiredMask
        >
          <Input placeholder="Enter user full name" />
        </FormField>
        <FormField
          name="email"
          label="Email Address"
          rules={[required, email]}
          requiredMask
        >
          <Input placeholder="Enter user email address" />
        </FormField>
        <FormField
          name="password"
          label="Password"
          rules={[required]}
          requiredMask
        >
          <InputPassword placeholder="Enter user password" />
        </FormField>
        <FormField
          name="rePassword"
          label="Re-enter Password"
          rules={[required]}
          requiredMask
        >
          <InputPassword placeholder="Re-enter password" />
        </FormField>
        <FormField name="role" label="User Role">
          <SelectButton className="!gap-2">
            <SelectButton.Item value="standard">
              Standard User
            </SelectButton.Item>
            <SelectButton.Item value="admin">
              <Crown className="!text-warning" /> Admin
            </SelectButton.Item>
          </SelectButton>
        </FormField>
        <div className="mt-10 flex justify-between items-center">
          <Button
            disabled={isLoading}
            type="button"
            className="w-20"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            variant="primary"
            className="w-[7.5rem]"
          >
            Create User
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalCreateUser
