import { PlanIcon } from 'icons/utils'
import { ReactNode } from 'react'

import { PLAN_TYPE } from 'utils/plan'

interface Props {
  planType: PLAN_TYPE
  children?: ReactNode
  label: ReactNode
  description: ReactNode
  totalPrice?: number
}

function PlanCard({
  children,
  planType,
  label,
  description,
  totalPrice
}: Props) {
  return (
    <div className="basis-2/6 px-3 mb-6">
      <div className="relative inline-flex flex-col w-full h-full text-center p-4 pb-6 bg-white rounded-lg shadow-[0_1px_2px_rgba(0,0,0,0.25)]">
        <div className="text-center mb-4">
          <PlanIcon type={planType} className="max-h-20 mx-auto" />
        </div>
        <div className="mb-1 text-xl font-medium">{label}</div>
        <div className="flex items-center justify-center h-[3.2rem]">
          {totalPrice !== undefined && (
            <div className="inline-flex items-end">
              <div className="text-xl leading-none">${totalPrice}</div>
              <div className="text-light-secondary text-[0.815rem] leading-tight">
                /month
              </div>
            </div>
          )}
        </div>
        <div className="text-light-secondary text-[0.8125rem] leading-tight mb-6">
          {description}
        </div>
        <div className="mt-auto">{children}</div>
      </div>
    </div>
  )
}

export default PlanCard
