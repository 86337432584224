import { CSSProperties, FC, Fragment, ReactNode, useState } from 'react'
import { usePopper, PopperProps } from 'react-popper'
import { motion } from 'framer-motion'
import cx from 'classnames'

import { Portal } from 'components/Portal'

interface Props {
  children?: ReactNode
  visible?: boolean
  content: ReactNode
  className?: string
  style?: CSSProperties
  popupClassName?: string
  popupContainer?: () => HTMLElement
  onVisibleChange?: (visible: boolean) => void
  modifiers?: PopperProps<any>['modifiers']
  placement?: PopperProps<any>['placement']
  zIndex?: number
}

export const Tooltip: FC<Props> = ({
  children,
  visible: propVisible,
  className,
  style,
  popupClassName,
  popupContainer,
  content,
  onVisibleChange,
  placement = 'top',
  modifiers = [],
  zIndex
}) => {
  const [visible, setVisible] = useState<boolean>(propVisible || false)
  const [refElement, setRefElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const { styles, attributes } = usePopper(refElement, popperElement, {
    placement,
    modifiers: [...(modifiers || [])]
  })

  const toggleVisible = (v: boolean) => {
    if (propVisible === undefined) {
      setVisible(v)
    }
    onVisibleChange?.(v)
  }

  return (
    <Fragment>
      <div
        ref={setRefElement}
        style={style}
        className={cx(className)}
        onMouseEnter={() => toggleVisible(true)}
        onMouseLeave={() => toggleVisible(false)}
      >
        {children}
      </div>
      {visible && (
        <Portal popupContainer={popupContainer}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            ref={setPopperElement}
            onMouseEnter={() => toggleVisible(true)}
            onMouseLeave={() => toggleVisible(false)}
            className={cx(popupClassName, 'p-2 z-tooltip')}
            style={{ ...styles.popper, zIndex }}
            {...attributes.popper}
          >
            <div
              className={cx(
                'bg-black rounded-lg text-white min-w-[2rem] min-h-[2rem] text-[0.8125rem] p-2.5'
              )}
            >
              {content}
            </div>
          </motion.div>
        </Portal>
      )}
    </Fragment>
  )
}
