import { useReducer } from 'react'
import shallow from 'zustand/shallow'

import { DeviceIconType } from 'icons/utils/DeviceIcon'
import { Button, Popover, Select, SelectButton } from 'components'
import { isCamera, isSensor } from 'utils/device'
import { Location } from 'services/locations'
import useStore from 'store'

export type Filters = {
  recording?: 'enable' | 'disable'
  cameraLicense?: 'licensed' | 'noLicense'
  deviceState?: 'online' | 'offline' | 'error'
  locations?: Location[]
}

type SelectedLocation = {
  label: string
  value: number
  location: Location
}

interface Props {
  deviceType: DeviceIconType
  filters: Filters
  onChange: (filters: Filters) => void
  onClose: () => void
}

function FilterForm({
  deviceType,
  filters: propFilters,
  onChange,
  onClose
}: Props) {
  const locations = useStore(state => state.location.locations, shallow)
  const [filters, setFilters] = useReducer(
    (s: Filters, a: Partial<Filters>) => ({ ...s, ...a }),
    propFilters
  )

  const mapLocations = (options: Location[]) => {
    return options.map(l => ({ label: l.name, value: l.id, location: l }))
  }

  const handleSelectLocation = (locations: SelectedLocation[]) => {
    setFilters({
      locations: (locations as SelectedLocation[]).map(l => l.location)
    })
  }

  const handleChange = (reset = false) => {
    return () => {
      onChange(reset ? {} : filters)
      onClose()
    }
  }

  const type = isCamera(deviceType) ? 'Camera' : 'Sensor'

  return (
    <Popover.Content
      id="filter-devices"
      onClose={onClose}
      className="!p-0 w-[550px]"
    >
      <div className="p-4 text-base font-medium">Quick Filters</div>
      {isCamera(deviceType) && (
        <div className="flex px-4 items-center gap-5 mb-4">
          <div className="text-[0.8125rem] font-medium w-[6.875rem]">
            Recording
          </div>
          <SelectButton
            value={filters.recording}
            onChange={v => setFilters({ recording: v as Filters['recording'] })}
          >
            <SelectButton.Item value="enable">Enable</SelectButton.Item>
            <SelectButton.Item value="disable">Disable</SelectButton.Item>
          </SelectButton>
        </div>
      )}
      {(isCamera(deviceType) || isSensor(deviceType)) && (
        <div className="flex px-4 items-center gap-5 mb-4">
          <div className="text-[0.8125rem] font-medium w-[6.875rem]">
            {type} License
          </div>
          <SelectButton
            value={filters.cameraLicense}
            onChange={v =>
              setFilters({ cameraLicense: v as Filters['cameraLicense'] })
            }
          >
            <SelectButton.Item value="licensed">Licensed</SelectButton.Item>
            <SelectButton.Item value="noLicense">No License</SelectButton.Item>
          </SelectButton>
        </div>
      )}
      <div className="flex px-4 items-center gap-5 mb-4">
        <div className="text-[0.8125rem] font-medium w-[6.875rem]">
          Device State
        </div>
        <SelectButton
          value={filters.deviceState}
          onChange={v =>
            setFilters({ deviceState: v as Filters['deviceState'] })
          }
        >
          <SelectButton.Item value="online">Online</SelectButton.Item>
          <SelectButton.Item value="offline">Offline</SelectButton.Item>
          <SelectButton.Item value="error">Error</SelectButton.Item>
        </SelectButton>
      </div>
      <div className="flex px-4 items-center gap-5 mb-6">
        <div className="text-[0.8125rem] font-medium w-[6.875rem] shrink-0">
          Location
        </div>
        <Select
          isMulti
          isClearable={false}
          placeholder="Select device location to filter"
          options={mapLocations(locations)}
          value={mapLocations(filters.locations || [])}
          onChange={(v: any) => handleSelectLocation(v)}
        />
      </div>
      <div className="border-b border-light-stroke my-3" />
      <div className="px-4 pb-3 flex items-center gap-2">
        <Button className="w-20" onClick={onClose}>
          Cancel
        </Button>
        <Button className="w-[6.25rem]" onClick={handleChange(true)}>
          Reset Filter
        </Button>
        <Button
          variant="primary"
          className="ml-auto w-[7.5rem]"
          onClick={handleChange()}
        >
          Apply Filter
        </Button>
      </div>
    </Popover.Content>
  )
}

export default FilterForm
