import { Fragment, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { Spinner } from 'components'
import { ADD_ON, PLAN_TYPE } from 'utils/plan'
import useStore, { Store } from 'store'

import CheckoutPlan from './CheckoutPlan'

interface RouteState {
  planId?: number
  count?: number
}

const mapState = ({ conf, plan, sub }: Store) => ({
  config: conf.config,
  sub: sub.subscription,
  plans: plan.plans,
  planLoading: plan.loading,
  getPlans: plan.getPlans
})

function CheckoutPlanWrapper() {
  const { planId, count = 0 } = (useLocation().state || {}) as RouteState
  const { config, plans, sub, planLoading, getPlans } = useStore(
    mapState,
    shallow
  )

  useEffect(() => {
    if (!plans.length && !planLoading) getPlans()
  }, [])

  if (planLoading || !config) {
    return <Spinner className="h-[80vh]" />
  }

  if (!planId || !sub?.subscription?.plan_id) {
    return <Navigate to="/" replace />
  }

  const planInfo = plans.find(p => p.id === planId)
  const addOn = plans.find(p => p.name === ADD_ON.CAMERA_LICENSE)
  if (!planInfo || !addOn) return <Navigate to="/" replace />

  const { id, name } = planInfo
  const isFreePlan = id === 1 || name === PLAN_TYPE.FREE

  return (
    <Fragment>
      <CheckoutPlan
        plan={planInfo}
        count={isFreePlan ? 0 : count}
        cameraLicensePrice={addOn.price_in_dollars || 1}
      />
    </Fragment>
  )
}

export default CheckoutPlanWrapper
