import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Calendar(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M14.3929 2.67379H12.7857V1.60237C12.7857 1.3065 12.5459 1.06665 12.25 1.06665C11.9541 1.06665 11.7143 1.3065 11.7143 1.60237V2.67379H5.2857V1.60237C5.2857 1.3065 5.04585 1.06665 4.75001 1.06665C4.45416 1.06665 4.21428 1.3065 4.21428 1.60237V2.67379H2.60714C1.71954 2.67379 1 3.39333 1 4.28093V14.4595C1 15.3471 1.71954 16.0667 2.60714 16.0667H14.3929C15.2805 16.0667 16 15.3471 16 14.4595V4.28093C16 3.39333 15.2805 2.67379 14.3929 2.67379ZM14.9286 14.4595C14.9286 14.7554 14.6887 14.9952 14.3928 14.9952H2.60714C2.31126 14.9952 2.07142 14.7554 2.07142 14.4595V7.49522H14.9286V14.4595ZM14.9286 6.4238H2.07142V4.28093C2.07142 3.98506 2.31126 3.74521 2.60714 3.74521H4.21428V4.81663C4.21428 5.1125 4.45413 5.35235 4.75001 5.35235C5.04589 5.35235 5.28573 5.1125 5.28573 4.81663V3.74521H11.7143V4.81663C11.7143 5.1125 11.9541 5.35235 12.25 5.35235C12.5459 5.35235 12.7857 5.1125 12.7857 4.81663V3.74521H14.3929C14.6888 3.74521 14.9286 3.98506 14.9286 4.28093V6.4238H14.9286Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.5"
        />
      </svg>
    </SVGUniqueID>
  )
}
