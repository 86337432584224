import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import CameraLicenseBill from 'assets/images/CameraLicenseBill.png'
import Sensor from 'assets/images/Sensors.png'
import CreditCard from 'assets/images/CreditCard.png'

import { Tooltip } from 'components'
import MyPlan from 'shared/Cards/MyPlan'
import ModalSelectCameraLicenseCount from 'shared/Modals/Billing/SelectCameraLicenseCount'
import ModalSelectSensorCount from 'shared/Modals/Billing/SelectSensorCount'
import ModalCancelPlan from 'shared/Modals/Billing/CancelPlan'
import useStore, { Store } from 'store'

interface Props {
  isFreePlan: boolean
}

const mapState = ({ auth, stat, sub }: Store) => ({
  betaFeatures: auth.accessBetaFeatures(),
  stats: stat.stats,
  sub: sub.subscription
})

function PlanInfo({ isFreePlan }: Props) {
  const navigate = useNavigate()
  const [visible, setVisible] = useState('')
  const { betaFeatures, stats, sub } = useStore(mapState, shallow)

  const credit = sub?.customer?.balance

  return (
    <div className="flex flex-wrap gap-2.5">
      <div className="inline-flex flex-col bg-white xl:w-fit rounded-lg w-full shadow-[0_2px_2px_rgba(0,0,0,0.25),inset_0_-1px_rgba(255,255,255,0.1)]">
        <div className="flex items-center gap-4 p-4">
          <div className="w-[3.25rem] h-[3.25rem] rounded-lg bg-[#fafafa] flex items-center justify-center">
            <img
              className="w-8 h-[1.5625rem] shrink-0"
              src={CameraLicenseBill}
              alt="camera-license"
            />
          </div>
          <div className="font-medium">
            <p className="text-[0.9375rem]">Camera License</p>
            <p className="text-2xl mb-0">{stats?.camera_licenses.total || 0}</p>
          </div>
        </div>
        <div
          className="mt-auto rounded-b-lg bg-[#e6e7ff] cursor-pointer text-primary font-medium text-[0.8125rem] px-4 py-[0.875rem] block"
          onClick={() => setVisible('camera-license')}
        >
          Add/Remove
        </div>
      </div>
      {betaFeatures && (
        <div className="inline-flex flex-col bg-white xl:w-fit rounded-lg w-full shadow-[0_2px_2px_rgba(0,0,0,0.25),inset_0_-1px_rgba(255,255,255,0.1)]">
          <div className="flex items-center gap-4 p-4">
            <div className="w-[3.25rem] h-[3.25rem] rounded-lg bg-[#fafafa] flex items-center justify-center">
              <img
                className="w-10 shrink-0"
                src={Sensor}
                alt="sensor-license"
              />
            </div>
            <div className="font-medium">
              <p className="text-[0.9375rem]">Sensor License</p>
              <p className="text-2xl mb-0">
                {stats?.temperature_sensors.total || 0}
              </p>
            </div>
          </div>
          <div
            className="mt-auto rounded-b-lg bg-[#e6e7ff] cursor-pointer text-primary font-medium text-[0.8125rem] px-4 py-[0.875rem] block"
            onClick={() => setVisible('sensor')}
          >
            Buy Sensor
          </div>
        </div>
      )}
      {!!credit && (
        <div className="inline-flex flex-col bg-white xl:w-fit rounded-lg w-full shadow-[0_2px_2px_rgba(0,0,0,0.25),inset_0_-1px_rgba(255,255,255,0.1)]">
          <div className="flex items-center gap-4 p-4">
            <div className="w-[3.25rem] h-[3.25rem] rounded-lg bg-[#fafafa] flex items-center justify-center">
              <img
                className="w-8 h-8 shrink-0"
                src={CreditCard}
                alt="camera-license"
              />
            </div>
            <div className="font-medium text-[0.9375rem]">
              <p className="text-[0.9375rem]">Remaining Credit</p>
              <p className="text-2xl mb-0">${Math.abs(credit) / 100}</p>
            </div>
          </div>
          <Tooltip
            placement="top"
            className="mt-auto text-primary font-medium rounded-b-lg bg-[#fafafa] text-[0.8125rem] px-4 py-[0.875rem] cursor-pointer"
            content="This credit will be applied to your upcoming bill"
          >
            What is it?
          </Tooltip>
        </div>
      )}
      <MyPlan className="w-full xl:w-[26.25rem] shadow-[0_2px_2px_rgba(0,0,0,0.25),inset_0_-1px_rgba(255,255,255,0.1)] relative pb-[4.5rem]">
        {isFreePlan && (
          <div
            className="text-primary font-medium absolute bottom-0 left-0 right-0 rounded-b-lg bg-[#fafafa] text-[0.8125rem] px-4 py-[0.875rem] cursor-pointer"
            onClick={() => navigate('/settings/billing/plans')}
          >
            Upgrade Plan
          </div>
        )}
        {!isFreePlan && (
          <div
            className="text-danger font-medium absolute bottom-0 left-0 right-0 rounded-b-lg bg-[#fafafa] text-[0.8125rem] px-4 py-[0.875rem] cursor-pointer"
            onClick={() => setVisible('cancel')}
          >
            Cancel Plan
          </div>
        )}
      </MyPlan>
      <AnimatePresence initial={false}>
        {visible === 'camera-license' && (
          <ModalSelectCameraLicenseCount onClose={() => setVisible('')} />
        )}
        {visible === 'sensor' && (
          <ModalSelectSensorCount onClose={() => setVisible('')} />
        )}
        {visible === 'cancel' && (
          <ModalCancelPlan
            billingEndDate={sub!.subscription!.billing_end}
            onClose={() => setVisible('')}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default PlanInfo
