import cx from 'classnames'
import { Dot } from 'components'

function PasswordRequirements(props: JSX.IntrinsicElements['div']) {
  return (
    <div
      {...props}
      className={cx(
        'bg-light-bg p-3 rounded-lg text-sm text-light-secondary',
        props.className
      )}
    >
      <h4 className="mb-2">
        In order to{' '}
        <span className="text-light-primary font-medium">
          protect your account
        </span>
        , make sure your password:
      </h4>
      <div className="flex items-center gap-2 pl-3 mb-1">
        <Dot className="w-2 h-2 bg-light-secondary" />
        <div>Is at least 8 characters</div>
      </div>
      <div className="flex items-center gap-2 pl-3 mb-1">
        <Dot className="w-2 h-2 bg-light-secondary" />
        <div>Contains at least 1 number (0 1 2 3 ...)</div>
      </div>
      <div className="flex items-center gap-2 pl-3 mb-1">
        <Dot className="w-2 h-2 bg-light-secondary" />
        <div>Contains at least 1 upper case letter (A B C ...)</div>
      </div>
      <div className="flex items-center gap-2 pl-3">
        <Dot className="w-2 h-2 bg-light-secondary" />
        <div>Contains at least 1 special character (! @ # $ ...)</div>
      </div>
    </div>
  )
}

export default PasswordRequirements
