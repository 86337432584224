import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function IgnoreCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 0C3.14025 0 0 3.14025 0 7C0 10.8598 3.14025 14 7 14C10.8598 14 14 10.8598 14 7C14 3.14025 10.8597 0 7 0ZM1.74999 7C1.74999 5.8679 2.11378 4.82121 2.72551 3.96281L10.0372 11.2745C9.17877 11.8862 8.13207 12.25 6.99997 12.25C4.10499 12.25 1.74999 9.89501 1.74999 7ZM3.96278 2.72551L11.2745 10.0372C11.8862 9.17879 12.25 8.1321 12.25 6.99997C12.25 4.10496 9.89498 1.74999 6.99997 1.74999C5.86787 1.74999 4.82118 2.11381 3.96278 2.72551Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
