import { DragEventHandler } from 'react'

import { DragDrop } from 'icons/outline'
import { isH265 } from 'utils/device'
import { Device } from 'services/devices'

import { usePlaybackCtx } from '../PlaybackContext'
import PlaybackPlayer from '../PlaybackPlayer'

interface Props {
  index: number
  device: Device | null
  onDeviceChange: (device: Device | null) => void
}

function LivestreamTabItem({ index, device, ...props }: Props) {
  const { dispatch } = usePlaybackCtx()

  const handleDragOver: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragEnter: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    ;(e.target as HTMLElement).classList.add('outline', 'outline-primary')
  }

  const handleDragLeave: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    ;(e.target as HTMLElement).classList.remove('outline', 'outline-primary')
  }

  const handleDrop: DragEventHandler<HTMLDivElement> = e => {
    ;(e.target as HTMLElement).classList.remove('outline', 'outline-primary')
    const droppedDevice = JSON.parse(e.dataTransfer.getData('device')) as Device
    if (device?.id !== droppedDevice.id) {
      props.onDeviceChange(droppedDevice)
      dispatch({ type: 'CREATE_PLAYER', payload: { index } })
    }
  }

  const handleRemove = () => {
    props.onDeviceChange(null)
    dispatch({ type: 'REMOVE_PLAYER', payload: { index } })
  }

  return (
    <div
      className="relative w-full h-full bg-black text-white select-none overflow-hidden"
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {!device?.id && (
        <div className="w-full h-full flex flex-col justify-center items-center gap-4 pointer-events-none text-light-secondary">
          <DragDrop className="w-12 h-12 shrink-0" />
          <div className="text-base text-center tracking-[.2px]">
            Drag & drop your device here to view
          </div>
        </div>
      )}
      {!!device && isH265(device) && (
        <div className="w-full h-full flex flex-col justify-center items-center gap-4 pointer-events-none">
          <div className="text-light-secondary text-center text-base tracking-[.2px]">
            H265 is not supported by browsers
          </div>
        </div>
      )}
      {!!device && (
        <PlaybackPlayer
          key={device.id}
          index={index}
          device={device}
          onRemove={handleRemove}
        />
      )}
    </div>
  )
}

export default LivestreamTabItem
