import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Login(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M12.7294 0.00875853C12.7149 0.00758362 12.7026 0 12.6874 0H6.41663C5.4518 0 4.66663 0.785172 4.66663 1.75V2.3333C4.66663 2.65533 4.92789 2.9167 5.24992 2.9167C5.57196 2.9167 5.83322 2.65533 5.83322 2.3333V1.75C5.83322 1.4286 6.09512 1.1667 6.41663 1.1667H9.13434L8.95639 1.2262C8.48396 1.38951 8.16663 1.83459 8.16663 2.3333V11.0833H6.41663C6.09512 11.0833 5.83322 10.8214 5.83322 10.5V9.3333C5.83322 9.01137 5.57196 8.75 5.24992 8.75C4.92789 8.75 4.66663 9.01137 4.66663 9.3333V10.5C4.66663 11.4648 5.4518 12.25 6.41663 12.25H8.16663V12.8333C8.16663 13.4767 8.68979 14 9.33322 14C9.45808 14 9.57654 13.9819 9.70482 13.9422L13.2095 12.7738C13.6826 12.6105 13.9999 12.1654 13.9999 11.6667V1.1667C13.9999 0.486526 13.4136 -0.0466766 12.7294 0.00875853Z"
          fill="currentColor"
        />
        <path
          d="M6.2457 5.42078L3.9124 3.08749C3.74556 2.92065 3.49477 2.87045 3.27655 2.96091C3.05898 3.05128 2.9167 3.26426 2.9167 3.49989V5.24989H0.583298C0.261368 5.24989 0 5.51125 0 5.83318C0 6.15522 0.261368 6.41659 0.583298 6.41659H2.9167V8.16659C2.9167 8.40221 3.05898 8.61509 3.27655 8.70556C3.49477 8.79592 3.74556 8.74583 3.9124 8.57899L6.2457 6.24558C6.47385 6.01754 6.47385 5.64893 6.2457 5.42078Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
