import { format } from 'date-fns'

import { dateTimeFormat } from 'utils/dateTime'
import client, { ResponseMeta } from './client'

export interface OrderItem {
  amount: number
  id: number
  item_price: number
  product_name: string
  quantity: number
}

export interface OrderHistory {
  account_id: number
  amount: number
  city: string
  created_at: string
  full_name: string
  id: number
  order_items: OrderItem[]
  phone_no: string
  receipt_url: string
  state: string
  status: string | null
  street_address: string
  street_address_2: string | null
  tracking_url: string
  zip: string
}

export interface OrderHistoryResponse {
  data: OrderHistory[]
  meta: ResponseMeta
}

export const getOrderHistory = ({
  pageSize = 10,
  currentPage = 1,
  search,
  dateRange
}: {
  sortBy?: string
  sortDirection?: string
  pageSize?: number
  currentPage?: number
  search?: string
  dateRange?: [Date | null, Date | null]
}) => {
  let filters = ''

  if (search) {
    filters += `&filters[search]=${search}`
  }

  if (dateRange && !!dateRange[0] && !!dateRange[1]) {
    const from = format(dateRange[0], dateTimeFormat['yyyy-MM-dd'])
    const to = format(dateRange[1], dateTimeFormat['yyyy-MM-dd'])
    filters += `&filters[date_range]=${from},${to}`
  }

  return client.get<OrderHistoryResponse>(
    `/api/billing/orders?limit=${pageSize}&page=${currentPage}${filters}`
  )
}

// ------------------------------------------------------------------------- //

export const createOrder = (payload: {
  full_name: string
  phone_no: string
  street_address: string
  street_address_2?: string
  city: string
  state: string
  zip: string
  order_items: {
    product_id: number
    quantity: number
  }[]
}) => {
  return client.post('/api/billing/orders', payload)
}
