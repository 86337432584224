import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Emergency(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M15.15 5.69167L13.4833 2.80833L9.49167 5.11667V0.5H6.15833V5.11667L2.16667 2.80833L0.5 5.69167L4.49167 8L0.5 10.3083L2.16667 13.1917L6.15833 10.8833V15.5H9.49167V10.8833L13.4833 13.1917L15.15 10.3083L11.1583 8L15.15 5.69167Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
