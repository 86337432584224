import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function OtherDevice(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="20"
        height="12"
        viewBox="0 0 20 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M17.9825 3.27128C17.4 1.56264 15.7802 0.329834 13.8769 0.329834C11.9736 0.329834 10.354 1.56264 9.77139 3.27128H0V10.0202H1.92655V11.6798H4.31812V10.0202H15.6819V11.6798H18.0734V10.0202H20V3.27128H17.9825ZM8.32557 8.22251H2.12293V6.98241H8.32557V8.22251ZM8.32557 5.86069H2.12293V4.62059H8.32557V5.86069ZM13.8769 7.21073C12.4743 7.21073 11.3333 6.06968 11.3333 4.66712C11.3333 3.26456 12.4743 2.1235 13.8769 2.1235C15.2795 2.1235 16.4206 3.26456 16.4206 4.66712C16.4206 6.06968 15.2795 7.21073 13.8769 7.21073Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
