import { Link } from 'react-router-dom'

import ResourceSummaryAlt from 'shared/Cards/ResourceSummaryAlt'
import useStore from 'store'

function ResourcesSummary() {
  const stats = useStore(state => state.stat.stats)

  const upgradeLink = () => (
    <Link to="/settings/billing/plans" className="text-primary">
      Upgrade
    </Link>
  )

  const {
    email,
    sms,
    whatsapp: wa,
    slack_enabled,
    teams_enabled,
    webhook_enabled,
    pagerduty_enabled
  } = stats || {}

  return (
    <div className="flex flex-wrap items-center gap-2 mb-6">
      <ResourceSummaryAlt
        type="email"
        title="Email:"
        summary={`${Math.abs(email?.used || 0)}/${email?.total || 0}`}
        iconType="primary"
      />
      <ResourceSummaryAlt
        type="sms"
        title="SMS:"
        summary={`${Math.abs(sms?.used || 0)}/${sms?.total || 0}`}
        iconType="primary"
      />
      <ResourceSummaryAlt
        type="whatsapp"
        title="WhatsApp:"
        summary={
          wa?.enabled
            ? `${Math.abs(wa?.used || 0)}/${wa?.total || 0}`
            : upgradeLink()
        }
      />
      <ResourceSummaryAlt
        type="slack"
        title="Slack:"
        summary={slack_enabled ? 'Unlimited' : upgradeLink()}
      />
      <ResourceSummaryAlt
        type="ms-teams"
        title="Microsoft Teams:"
        summary={teams_enabled ? 'Unlimited' : upgradeLink()}
      />
      <ResourceSummaryAlt
        type="webhook"
        title="Webhook:"
        summary={webhook_enabled ? 'Unlimited' : upgradeLink()}
      />
      <ResourceSummaryAlt
        type="pager-duty"
        title="PagerDuty:"
        summary={pagerduty_enabled ? 'Unlimited' : upgradeLink()}
      />
      <Link
        to="/settings/billing/plans"
        className="font-medium text-primary text-[0.8125rem]"
      >
        Upgrade Plan
      </Link>
    </div>
  )
}

export default ResourcesSummary
