import { FC, ReactNode, useRef } from 'react'
import { motion, MotionProps } from 'framer-motion'
import cx from 'classnames'

import { Overlay, Portal } from 'components'
import { idGenerator } from 'utils/functions'

const genModalId = idGenerator('modal')

const getModalRoot = () => {
  const id = 'modal-root'
  let modalRoot = document.getElementById(id)
  if (!modalRoot) {
    const div = document.createElement('div')
    div.id = id
    modalRoot = div
  }
  return modalRoot
}

export interface Props {
  children?: ReactNode
  onClose: () => void
  className?: string
  wrapperClassName?: string
  rounded?: boolean
  onAnimateComplete?: MotionProps['onAnimationComplete']
  overlayZIndex?: number
}

export const Modal: FC<Props> = ({
  onClose,
  className,
  wrapperClassName,
  rounded,
  onAnimateComplete,
  overlayZIndex,
  children
}) => {
  const modalIdRef = useRef(genModalId.next().value as string)

  const handleClose: React.MouseEventHandler<HTMLDivElement> = event => {
    const target = event.target as HTMLDivElement
    target.id === modalIdRef.current && onClose()
  }

  return (
    <Portal popupContainer={getModalRoot}>
      <Overlay lockScroll zIndex={overlayZIndex}>
        <motion.div
          id={modalIdRef.current}
          initial={{ top: '100vh', overflowY: 'hidden' }}
          animate={{ top: 0, transitionEnd: { overflowY: 'auto' } }}
          exit={{ top: '100vh', overflowY: 'hidden' }}
          transition={{ duration: 0.2, delay: 0 }}
          onAnimationComplete={onAnimateComplete}
          onMouseDown={handleClose}
          className={cx(
            'p-4 fixed top-0 left-0 right-0 bottom-0 cursor-pointer w-screen max-w-screen max-h-screen z-modal',
            'flex flex-wrap justify-center items-center bg-transparent overflow-x-hidden',
            wrapperClassName
          )}
        >
          <div
            className={cx(
              'p-4 bg-white min-h-[5rem] cursor-auto text-light-primary',
              rounded !== false && 'rounded-lg',
              className
            )}
          >
            {children}
          </div>
        </motion.div>
      </Overlay>
    </Portal>
  )
}
