import { FC, InputHTMLAttributes, useEffect, useState } from 'react'
import cx from 'classnames'

type Props = { wrapperClassName?: string; size?: 'medium' | 'large' } & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'size'
>

const sizes = {
  medium: 'w-5 h-5',
  large: 'w-6 h-6'
}

export const Checkbox: FC<Props> = ({
  children,
  size = 'medium',
  wrapperClassName,
  ...props
}) => {
  const [checked, setChecked] = useState(props.checked || false)

  useEffect(() => {
    if (props.checked !== undefined) {
      setChecked(props.checked)
    }
  }, [props.checked])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setChecked(e.target.checked)
    props.onChange?.(e)
  }

  return (
    <div className={cx('flex items-center gap-2', wrapperClassName)}>
      <div className={cx(sizes[size], 'relative shrink-0')}>
        <input
          {...props}
          checked={checked}
          type="checkbox"
          onChange={props.disabled ? undefined : handleChange}
          className={cx(
            'w-full h-full rounded border border-light-checkbox bg-white',
            'appearance-none checked:bg-primary checked:border-primary',
            'base-transition cursor-pointer hover:border-primary',
            props.disabled && 'pointer-events-none opacity-30',
            props.className
          )}
        />
        <div
          className={cx(
            'w-full h-full absolute top-0 left-0 pointer-events-none',
            "after:content-[''] after:absolute after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-60%]",
            'after:w-[5px] after:h-[10px] after:border-solid after:border-white after:border-r-[2px] after:border-b-[2px] after:rotate-45',
            checked ? 'after:block' : 'after:hidden'
          )}
        />
      </div>
      <div className="text-[0.8125rem]">{children}</div>
    </div>
  )
}
