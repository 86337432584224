import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function LocationPin(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="11"
        height="15"
        viewBox="0 0 11 15"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5.53972C0 2.48478 2.46767 0 5.5 0C8.53233 0 11 2.48478 11 5.53972C11 9.88044 6.017 14.3623 5.80494 14.5512C5.71756 14.6282 5.60878 14.6667 5.5 14.6667C5.39122 14.6667 5.28244 14.6282 5.19506 14.5506C4.983 14.3623 0 9.88044 0 5.53972ZM2.44426 5.5C2.44426 7.18483 3.81498 8.55556 5.49981 8.55556C7.18465 8.55556 8.55537 7.18483 8.55537 5.5C8.55537 3.81517 7.18465 2.44444 5.49981 2.44444C3.81498 2.44444 2.44426 3.81517 2.44426 5.5Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
