import { SetState } from 'zustand'

import { Subscription } from 'services/subscriptions'
import type { Store } from './'

export interface SubscriptionSlice {
  subscription: Subscription | null
  setSubscription: (payload: Subscription) => void
  updateSubscription: (payload: Partial<Subscription>) => void
}

const slice = (set: SetState<Store>) => ({
  subscription: null,
  setSubscription: (payload: Subscription) => {
    return set(({ sub }) => ({ sub: { ...sub, subscription: payload } }))
  },
  updateSubscription: (payload: Partial<Subscription>) => {
    return set(({ sub }) => ({
      sub: {
        ...sub,
        subscription: { ...sub.subscription, ...payload } as Subscription
      }
    }))
  }
})

export default slice
