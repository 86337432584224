import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function MSTeam(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.43742 4.66675C8.56506 4.66675 9.4792 3.7526 9.4792 2.62494C9.4792 1.49728 8.56506 0.58313 7.43742 0.58313C6.30977 0.58313 5.39563 1.49728 5.39563 2.62494C5.39563 3.7526 6.30977 4.66675 7.43742 4.66675Z"
          fill="#7B83EB"
        />
        <path
          opacity="0.1"
          d="M7.29169 3.0625H5.44244L5.45163 3.10319L5.45251 3.10669L5.46213 3.14431C5.5965 3.65388 5.92251 4.09184 6.3721 4.36674C6.82168 4.64164 7.36008 4.73223 7.87486 4.61959V3.6457C7.8744 3.49117 7.81281 3.3431 7.70355 3.23382C7.59428 3.12455 7.44622 3.06296 7.29169 3.0625V3.0625Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M6.85428 3.5H5.59259C5.75818 3.84913 6.01944 4.1441 6.346 4.35064C6.67257 4.55718 7.05105 4.66681 7.43745 4.66679V4.08317C7.43698 3.92865 7.3754 3.78058 7.26613 3.67132C7.15687 3.56205 7.00881 3.50046 6.85428 3.5V3.5Z"
          fill="black"
        />
        <path
          d="M12.0312 4.8125C12.8769 4.8125 13.5624 4.12694 13.5624 3.28125C13.5624 2.43556 12.8769 1.75 12.0312 1.75C11.1855 1.75 10.5 2.43556 10.5 3.28125C10.5 4.12694 11.1855 4.8125 12.0312 4.8125Z"
          fill="#5059C9"
        />
        <path
          d="M13.3438 5.25H9.90459C9.83033 5.25 9.75911 5.2795 9.7066 5.33201C9.65409 5.38452 9.62459 5.45574 9.62459 5.53V9.07812C9.61511 9.59164 9.78235 10.0928 10.0983 10.4977C10.4143 10.9027 10.8597 11.1867 11.3602 11.3024C11.6789 11.3692 12.0085 11.3642 12.325 11.2876C12.6415 11.211 12.937 11.0648 13.1899 10.8596C13.4427 10.6544 13.6467 10.3954 13.7868 10.1015C13.927 9.80754 13.9998 9.48603 14 9.16037V5.90625C14 5.7322 13.9309 5.56528 13.8078 5.44221C13.6847 5.31914 13.5178 5.25 13.3438 5.25Z"
          fill="#5059C9"
        />
        <path
          d="M10.9375 5.90618V10.0624C10.9379 10.8326 10.6841 11.5814 10.2154 12.1927C9.74675 12.8039 9.08942 13.2433 8.34545 13.4428C7.60149 13.6422 6.81249 13.5906 6.1009 13.2957C5.38931 13.0009 4.79493 12.4795 4.41 11.8124C4.32581 11.6724 4.25267 11.5262 4.19125 11.3749C4.13399 11.2322 4.08577 11.0861 4.04688 10.9374C3.97475 10.6513 3.93802 10.3574 3.9375 10.0624V5.90618C3.93739 5.81997 3.95428 5.73458 3.98722 5.65491C4.02016 5.57524 4.06849 5.50285 4.12946 5.44189C4.19042 5.38093 4.26281 5.3326 4.34248 5.29966C4.42215 5.26672 4.50754 5.24982 4.59375 5.24994H10.2812C10.3675 5.24982 10.4529 5.26672 10.5325 5.29966C10.6122 5.3326 10.6846 5.38093 10.7455 5.44189C10.8065 5.50285 10.8548 5.57524 10.8878 5.65491C10.9207 5.73458 10.9376 5.81997 10.9375 5.90618V5.90618Z"
          fill="#7B83EB"
        />
        <path
          opacity="0.2"
          d="M6.85428 3.5H5.59259C5.75818 3.84913 6.01944 4.1441 6.346 4.35064C6.67257 4.55718 7.05105 4.66681 7.43745 4.66679V4.08317C7.43698 3.92865 7.3754 3.78058 7.26613 3.67132C7.15687 3.56205 7.00881 3.50046 6.85428 3.5V3.5Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M7.87495 5.24994V10.7931C7.87495 10.925 7.82981 11.0529 7.74704 11.1555C7.66426 11.2582 7.54884 11.3295 7.41995 11.3574C7.3788 11.3697 7.33601 11.3756 7.29308 11.3749H4.19125C4.13398 11.2323 4.08576 11.0862 4.04687 10.9374C3.97475 10.6513 3.93802 10.3575 3.9375 10.0624V5.90619C3.93739 5.81998 3.95428 5.73459 3.98722 5.65492C4.02016 5.57525 4.06849 5.50286 4.12945 5.44189C4.19041 5.38093 4.2628 5.3326 4.34247 5.29966C4.42214 5.26672 4.50753 5.24982 4.59374 5.24994H7.87495Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M7.43745 5.24994V11.2305C7.43809 11.2734 7.43218 11.3162 7.41995 11.3573C7.39197 11.4862 7.32072 11.6016 7.21805 11.6844C7.11538 11.7672 6.98747 11.8123 6.85558 11.8123H4.40999C4.32581 11.6724 4.25267 11.5261 4.19125 11.3748C4.13398 11.2322 4.08576 11.0861 4.04687 10.9373C3.97475 10.6513 3.93802 10.3574 3.9375 10.0624V5.90618C3.93739 5.81997 3.95428 5.73458 3.98722 5.65491C4.02016 5.57524 4.06849 5.50285 4.12945 5.44189C4.19041 5.38093 4.2628 5.3326 4.34247 5.29966C4.42214 5.26672 4.50753 5.24982 4.59374 5.24994H7.43745Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M7.43745 5.24994V10.3556C7.43676 10.5097 7.37523 10.6573 7.26626 10.7663C7.15729 10.8753 7.00969 10.9368 6.85558 10.9375H4.04687C3.97475 10.6514 3.93802 10.3575 3.9375 10.0625V5.9062C3.93739 5.81998 3.95428 5.73459 3.98722 5.65492C4.02016 5.57525 4.06849 5.50286 4.12945 5.4419C4.19041 5.38093 4.2628 5.3326 4.34247 5.29966C4.42214 5.26672 4.50753 5.24982 4.59374 5.24994H7.43745Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M4.59375 5.24994C4.50754 5.24982 4.42215 5.26672 4.34248 5.29966C4.26281 5.3326 4.19042 5.38093 4.12946 5.4419C4.06849 5.50286 4.02016 5.57525 3.98722 5.65492C3.95428 5.73459 3.93739 5.81998 3.9375 5.9062V10.0625C3.93802 10.3575 3.97475 10.6514 4.04688 10.9375H6.41813C6.57224 10.9368 6.71984 10.8753 6.82881 10.7663C6.93778 10.6573 6.99931 10.5097 7 10.3556V5.24994H4.59375Z"
          fill="black"
        />
        <path
          d="M0.583187 3.5H6.41681C6.57148 3.5 6.71982 3.56144 6.82919 3.67081C6.93856 3.78018 7 3.92852 7 4.08319V9.91681C7 10.0715 6.93856 10.2198 6.82919 10.3292C6.71982 10.4386 6.57148 10.5 6.41681 10.5H0.583187C0.428517 10.5 0.280181 10.4386 0.170812 10.3292C0.0614428 10.2198 0 10.0715 0 9.91681L0 4.08319C0 3.92852 0.0614428 3.78018 0.170812 3.67081C0.280181 3.56144 0.428517 3.5 0.583187 3.5V3.5Z"
          fill="#4B53BC"
        />
        <path
          d="M5.24117 5.67665H3.93304V9.18536H3.07467V5.67665H1.75867V4.81479H5.24117V5.67665Z"
          fill="white"
        />
      </svg>
    </SVGUniqueID>
  )
}
