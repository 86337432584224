import { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import { Close } from 'icons/outline'
import { useAsync } from 'hooks'
import { Modal, ModalProps } from 'components'
import toast from 'utils/toast'
import { Device, getAllDevices } from 'services/devices'
import {
  OnvifDevice,
  connectOnvifDevice,
  ConnectedDevice
} from 'services/bridges'
import useStore, { Store } from 'store'

import DeviceAuthForm from './DeviceAuthForm'
import DeviceDetailForm from './DeviceDetailForm'

interface Props extends ModalProps {
  bridgeId: number
  onvifDevice: OnvifDevice
  onSuccess: () => void
}

const mapState = ({ bridge, device, stat }: Store) => ({
  addConnectedDevice: bridge.addConnectedDevice,
  setDevices: device.setDevices,
  updateUsedCount: stat.updateUsedCount
})

const steps = ['Enter Camera Username & Password', 'Enter Camera Details']

function ModalConnectCameraToBridge({
  bridgeId,
  onvifDevice,
  onSuccess,
  ...props
}: Props) {
  const { show } = useIntercom()
  const [stepIdx, setStepIdx] = useState(0)

  const { addConnectedDevice, setDevices, updateUsedCount } = useStore(
    mapState,
    shallow
  )

  const connectAsync = useAsync<AxiosResponse<{ data: ConnectedDevice }>>({
    showNotifOnError: true
  })
  const deviceAsync = useAsync<AxiosResponse<{ data: Device[] }>>({
    showNotifOnError: true
  })
  const isLoading = connectAsync.isLoading || deviceAsync.isLoading

  const handleConnect = async (values: {
    name: string
    timezone: string
    location_id: number
    ip_address?: string
    manufacturer?: string
  }) => {
    const connected = await connectAsync.execute(
      connectOnvifDevice({
        ...values,
        id: onvifDevice.id,
        type: 'Security Camera',
        is_licensed: true
      })
    )
    addConnectedDevice(bridgeId, connected.data.data)

    const devices = await deviceAsync.execute(getAllDevices())
    setDevices(devices.data.data)
    updateUsedCount({
      devices: 1,
      camera_licenses: 1
    })

    toast.success(
      { title: 'Camera connected & set up successfully' },
      { position: 'bottom-center' }
    )
    onSuccess()
    handleClose()
  }

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  return (
    <Modal
      {...props}
      onClose={handleClose}
      wrapperClassName="!p-0"
      className="w-[100vw] min-h-[100vh] rounded-none !p-0"
    >
      <div className="flex items-center gap-4 justify-between px-20 py-2 border-b border-light-stroke">
        <div className="inline-flex flex-col gap-[2px]">
          <div className="text-base font-medium">Connect & Set Up Camera</div>
          <div className="text-xs text-light-secondary">
            Step {stepIdx + 1}. {steps[stepIdx]}
          </div>
        </div>
        <div className="inline-flex items-center gap-9">
          <div
            onClick={show}
            className="text-[0.8125rem] text-primary cursor-pointer font-medium"
          >
            Get Support
          </div>
          <Close
            onClick={handleClose}
            className="w-4 h-4 shrink-0 cursor-pointer"
          />
        </div>
      </div>
      <div className="mx-20 mt-20 mb-8">
        {stepIdx === 0 && (
          <DeviceAuthForm onvifDevice={onvifDevice} onStepChange={setStepIdx} />
        )}
        {stepIdx === 1 && (
          <DeviceDetailForm
            loading={isLoading}
            onvifDevice={onvifDevice}
            handleConnect={handleConnect}
          />
        )}
      </div>
    </Modal>
  )
}

export default ModalConnectCameraToBridge
