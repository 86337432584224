import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function ArrowFall(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M9.0002 12L5.56054 8.56054L12 2.12107L9.87893 -1.8543e-07L3.43946 6.43946L7.86822e-07 2.9998L0 12L9.0002 12Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
