import { useState } from 'react'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import { useAsync } from 'hooks'
import { Close } from 'icons/outline'
import {
  Avatar,
  Button,
  Form,
  FormField,
  Input,
  Modal,
  ModalProps
} from 'components'
import toast from 'utils/toast'
import { required } from 'utils/rules'
import { CurrentUser, uploadAvatar, updateUsername } from 'services/auth'
import useStore, { Store } from 'store'

const mapState = ({ auth }: Store) => ({
  user: auth.currentUser,
  updateUser: auth.updateUser
})

function ModalEditUserProfile(props: ModalProps) {
  const [avatar, setAvatar] = useState<File | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const { user, updateUser } = useStore(mapState, shallow)

  const updateNameAsync = useAsync({ showNotifOnError: true })
  const uploadAsync = useAsync<AxiosResponse<{ data: CurrentUser }>>({
    showNotifOnError: true
  })

  if (!user) {
    return null
  }

  const handleSubmit = async ({ name }: { name: string }) => {
    try {
      setLoading(true)
      if (!!avatar) {
        const { data } = await uploadAsync.execute(uploadAvatar(avatar))
        updateUser({
          avatar: data.data.avatar,
          avatar_url: data.data.avatar_url
        })
      }
      if (!!name && name !== user!.name) {
        await updateNameAsync.execute(updateUsername({ name }))
        updateUser({ name })
      }
      setLoading(false)
      props.onClose()
      toast.success({ title: 'Profile updated' }, { position: 'bottom-center' })
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  return (
    <Modal {...props} className="px-6">
      <div className="text-base font-medium flex justify-between items-center">
        <h4>Edit Profile</h4>
        <Close
          className="cursor-pointer"
          onClick={loading ? undefined : props.onClose}
        />
      </div>
      <div className="mt-6 grid grid-cols-[7.5rem_25rem] gap-4">
        <Avatar
          size={120}
          username={user.name}
          src={user.avatar_url}
          onChange={setAvatar}
        />
        <Form
          onFinish={handleSubmit}
          initialValues={{
            name: user.name,
            email: user.email,
            role: user.is_admin || user.is_super ? 'admin' : 'standard'
          }}
        >
          <FormField name="name" label="Name" rules={[required]} requiredMask>
            <Input block placeholder="Enter your name" />
          </FormField>
          <FormField name="email" label="Email">
            <Input disabled block />
          </FormField>
          <FormField name="role" label="User Role">
            <Input disabled block />
          </FormField>
          <div className="flex items-center gap-2">
            <Button
              type="submit"
              size="large"
              variant="primary"
              className="w-[7.5rem]"
              disabled={loading}
            >
              Save
            </Button>
            <Button
              type="button"
              size="large"
              className="w-[5.125rem]"
              disabled={loading}
              onClick={loading ? undefined : props.onClose}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default ModalEditUserProfile
