import { Fragment } from 'react'

import { Pencil } from 'icons/outline'
import { ResourceIcon } from 'icons/utils'
import { Button } from 'components'

function WebhookRecipients() {
  return (
    <Fragment>
      <div className="flex items-center justify-between gap-4 mb-4">
        <div className="inline-flex items-center gap-2">
          <ResourceIcon
            type="webhook"
            className="w-5 h-5 shrink-0"
            iconType="primary"
          />
          <div className="text-base font-medium">Webhook Recipient</div>
        </div>
        <div className="inline-flex items-center gap-2">
          <Button variant="ternary" className="w-20">
            <Pencil /> Edit
          </Button>
        </div>
      </div>
      <div className="bg-white rounded-lg py-2 px-4 shadow-[0px_1px_2px_rgba(0,0,0,0.16)]">
        <div className="text-[0.8125rem] mb-1">
          We will send notification to below Webhook
        </div>
        <div className="text-xs text-light-secondary">
          https://hooks.com/services/T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX
        </div>
      </div>
    </Fragment>
  )
}

export default WebhookRecipients
