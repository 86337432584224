import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Whatsapp(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.00175 0H6.99825C3.13863 0 0 3.1395 0 7C0 8.53125 0.4935 9.9505 1.33263 11.1029L0.46025 13.7034L3.15088 12.8433C4.25775 13.5765 5.57812 14 7.00175 14C10.8614 14 14 10.8596 14 7C14 3.14038 10.8614 0 7.00175 0Z"
          fill="#4CAF50"
        />
        <g filter="url(#filter0_d_1_601)">
          <path
            d="M11.0749 9.88487C10.906 10.3617 10.2358 10.7572 9.70117 10.8727C9.33542 10.9506 8.85767 11.0127 7.24942 10.346C5.19229 9.49375 3.86754 7.40337 3.76429 7.26775C3.66542 7.13212 2.93304 6.16088 2.93304 5.15638C2.93304 4.15188 3.44317 3.66275 3.64879 3.45275C3.81767 3.28038 4.09679 3.20163 4.36454 3.20163C4.45117 3.20163 4.52904 3.206 4.59904 3.2095C4.80467 3.21825 4.90792 3.2305 5.04354 3.55513C5.21242 3.962 5.62367 4.9665 5.67267 5.06975C5.72254 5.173 5.77242 5.313 5.70242 5.44863C5.6368 5.58863 5.57904 5.65075 5.47579 5.76975C5.37254 5.88875 5.27454 5.97975 5.17129 6.1075C5.07679 6.21863 4.97004 6.33763 5.08904 6.54325C5.20805 6.7445 5.61929 7.41562 6.22479 7.95462C7.00617 8.65025 7.63967 8.8725 7.86629 8.967C8.03517 9.037 8.23642 9.02037 8.3598 8.88912C8.51642 8.72025 8.7098 8.44025 8.90667 8.16462C9.04667 7.96687 9.22342 7.94237 9.40892 8.01237C9.59792 8.078 10.598 8.57237 10.8037 8.67475C11.0093 8.778 11.1449 8.827 11.1948 8.91362C11.2438 9.00025 11.2438 9.40712 11.0749 9.88487Z"
            fill="#FAFAFA"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1_601"
            x="1.93304"
            y="3.20163"
            width="10.29"
            height="9.71774"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0238889 0 0 0 0 0.179167 0 0 0 0 0.0301627 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1_601"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1_601"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
