import client from './client'

export interface Note {
  body: string | null
  created_at: string
  created_by: {
    account_id: number
    avatar: string | null
    created_at: string
    email: string
    email_verified_at: string | null
    id: number
    is_admin: number
    name: string
    two_factor_recovery_codes: string | null
    two_factor_secret: string | null
    updated_at: string | null
  }
  device_inbound_notification_id: number
  id: number
  notify: number
  updated_at: string
}

export const addNotifNote = async (
  notifId: number,
  payload: { body: string; notify: boolean }
) => {
  await client.get('/api/csrf-cookie')
  return client.post(
    `/api/deviceInboundNotifications/${notifId}/notes`,
    payload
  )
}
