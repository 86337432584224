import { Link } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { Button } from 'components'
import ResourceSummaryAlt from 'shared/Cards/ResourceSummaryAlt'
import ChannelRecipientForm from 'shared/Forms/ChannelRecipientForm'
import toast from 'utils/toast'
import { NotifChannel } from 'services/tags'
import useStore from 'store'

import { FormValues } from './FormHelpers'

interface Props {
  loading: boolean
  values: FormValues['recipients']
  onSubmit: (values: FormValues['recipients']) => void
}

function Recipients({ loading, values, onSubmit }: Props) {
  const stats = useStore(state => state.stat.stats, shallow)
  const { email, sms, whatsapp } = stats || {}

  const handleSubmitClick = () => {
    document.getElementById('btn-create-schedule-recs')?.click()
  }

  const handleSubmit = (
    channels: NotifChannel[],
    recs: Omit<FormValues['recipients'], 'channels'>
  ) => {
    if (!channels.length) {
      return toast.error({ title: 'Please select atleast 1 channel' })
    }
    onSubmit({ channels, ...recs })
  }

  return (
    <div className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center">
      <div className="w-[55rem] m-auto">
        <div className="mb-10">
          <div className="text-base font-medium mb-3">
            We will send notification through
          </div>
          <div className="text-success text-[0.8125rem]">
            Number of notification you have used
          </div>
          <div className="flex items-center gap-1 mt-2">
            <ResourceSummaryAlt
              type="sms"
              title={<div className="text-light-secondary">SMS</div>}
              iconType="primary"
              iconClassName="!w-3.5 !h-3.5"
              className="!p-2 !text-[0.8125rem]"
              summary={`${Math.abs(sms?.used || 0)}/${sms?.total || 0}`}
            />
            <ResourceSummaryAlt
              type="email"
              title={<div className="text-light-secondary">Email</div>}
              iconType="primary"
              iconClassName="!w-3.5 !h-3.5"
              className="!p-2 !text-[0.8125rem]"
              summary={`${Math.abs(email?.used || 0)}/${email?.total || 0}`}
            />
            <ResourceSummaryAlt
              type="whatsapp"
              title={<div className="text-light-secondary">WhatsApp</div>}
              iconClassName="!w-3.5 !h-3.5"
              className="!p-2 !text-[0.8125rem]"
              summary={`${Math.abs(whatsapp?.used || 0)}/${
                whatsapp?.total || 0
              }`}
            />
            <Link
              to="/settings/billing/plans"
              className="ml-1 text-sm text-primary"
            >
              Upgrade plan
            </Link>
          </div>
        </div>
        <ChannelRecipientForm
          values={values}
          submitId="btn-create-schedule-recs"
          onSubmit={handleSubmit}
        />
        <Button
          disabled={loading}
          onClick={loading ? undefined : handleSubmitClick}
          variant="primary"
          size="large"
          className="w-40 mt-10"
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default Recipients
