import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Copy(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M13.8759 3.27307H11.985V1.59523C11.985 1.2665 11.7185 1 11.3897 1H2.59523C2.2665 1 2 1.2665 2 1.59523V12.1317C2 12.4604 2.2665 12.7269 2.59523 12.7269H4.48612V14.4047C4.48612 14.7335 4.75263 15 5.08135 15H13.8759C14.2046 15 14.4711 14.7335 14.4711 14.4047V3.8683C14.4711 3.53957 14.2046 3.27307 13.8759 3.27307ZM3.19046 11.5365V2.19046H10.7945V3.27307H5.08139C4.75267 3.27307 4.48616 3.53957 4.48616 3.8683V11.5365H3.19046ZM13.2807 13.8095H5.67663V4.46353H13.2807V13.8095Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
