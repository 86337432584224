import { usePageTitle } from 'hooks'
import RouteTabs from 'shared/RouteTabs'

import UserManagement from './UserManagement'
import RecipientManagement from './RecipientManagement'

const tabs = [
  {
    key: 'user-management',
    tab: 'User Management',
    Component: UserManagement
  },
  {
    key: 'recipient-management',
    tab: 'Recipient Management',
    Component: RecipientManagement
  }
]

function UserRecipient() {
  usePageTitle('User & Recipient Management | Broadflow Streams')

  return (
    <div className="overflow-hidden">
      <div className="text-2xl font-medium p-4 pt-6">Users & Recipients</div>
      <RouteTabs destroyInactiveTabPane className="mt-2" tabs={tabs} />
    </div>
  )
}

export default UserRecipient
