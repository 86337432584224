import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import Available from 'assets/images/Available.png'
import DeviceImg from 'assets/images/Device.png'
import NoResult from 'assets/images/NoResult.png'

import { DeviceIcon } from 'icons/utils'
import { Bridge, Close, Plus, Search } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Input, Modal, ModalProps, Spinner, Table } from 'components'
import { getBridgeName } from 'utils/device'
import {
  OnvifDevice,
  Bridge as IBridge,
  getBridgeDetail
} from 'services/bridges'
import useStore, { Store } from 'store'

import ModalConnectCameraToBridge from './ConnectCameraToBridge'

type BridgeResponse = AxiosResponse<{ data: IBridge }>

interface Props extends ModalProps {
  bridge: IBridge
}

const mapState = ({ stat }: Store) => ({
  camLicense: stat.stats?.camera_licenses,
  devices: stat.stats?.devices
})

function ModalOnvifDevices({ bridge, ...props }: Props) {
  const { show } = useIntercom()
  const [search, setSearch] = useState('')
  const [selectedOnvifDevice, setSelectedOnvifDevice] = useState<OnvifDevice>()

  const { camLicense, devices } = useStore(mapState, shallow)
  const { execute, isLoading, data } = useAsync<BridgeResponse>({
    showNotifOnError: true,
    status: 'resolved'
  })

  useEffect(() => {
    handleGetOnvifDevices()
  }, [])

  const handleGetOnvifDevices = () => {
    return execute(getBridgeDetail(bridge.id))
  }

  const renderNameCol = (device: OnvifDevice) => (
    <div className="flex items-center gap-1.5 text-[0.8125rem]">
      <DeviceIcon
        type="Security Camera"
        className={cx(
          'w-5 h-5 shrink-0',
          device.is_online ? 'text-success' : 'text-danger'
        )}
      />
      <div className="pt-px">{device.name}</div>
    </div>
  )

  const renderActionCol = (device: OnvifDevice) => {
    if (!availableDevice || !availableLicense) {
      return (
        <Link
          to="/settings/billing/plans"
          className="text-primary font-medium underline"
        >
          Upgrade plan
        </Link>
      )
    }

    if (!!device.device || !!device.connected_device) {
      return (
        <Button disabled variant="secondary" className="w-28">
          Connected
        </Button>
      )
    }

    const canConnect = !!(bridge.channel - bridge.devices_count)
    return (
      <Button
        disabled={!canConnect}
        variant="primary"
        className="w-28"
        onClick={!canConnect ? undefined : () => setSelectedOnvifDevice(device)}
      >
        <Plus /> Connect
      </Button>
    )
  }

  const columns = [
    {
      title: 'Name',
      render: renderNameCol
    },
    {
      title: 'Manufacturer',
      render: (device: OnvifDevice) => device.meta.manufacturer || '---'
    },
    {
      title: 'Model',
      render: (device: OnvifDevice) => device.meta.model || '---'
    },
    {
      title: 'IP',
      render: (device: OnvifDevice) => device.ip_address || '---'
    },
    {
      title: 'Profiles',
      render: (device: OnvifDevice) =>
        JSON.parse(device.meta.profile || '').join(', ') || '---'
    },
    {
      title: 'MAC',
      render: (device: OnvifDevice) => device.mac_address || '---'
    },
    {
      width: 125,
      title: 'Action',
      render: renderActionCol
    }
  ]

  const onvifDevices = data?.data.data.onvif_devices
  const availableLicense = (camLicense?.total || 0) - (camLicense?.used || 0)
  const availableDevice = (devices?.total || 0) - (devices?.used || 0)

  return (
    <Modal
      {...props}
      wrapperClassName="!p-0"
      className="w-[100vw] min-h-[100vh] rounded-none !p-0"
    >
      <div className="flex items-center justify-between px-20 py-4 border-b border-light-stroke">
        <div className="text-base font-medium">Select camera to connect to</div>
        <div className="inline-flex items-center gap-9">
          <div
            onClick={show}
            className="text-[0.8125rem] text-primary cursor-pointer font-medium"
          >
            Get Support
          </div>
          <Close
            onClick={props.onClose}
            className="w-4 h-4 shrink-0 cursor-pointer"
          />
        </div>
      </div>
      <div className="mt-6 mb-5 px-20 flex flex-wrap justify-between items-center gap-2">
        <div className="flex flex-wrap items-center gap-2">
          <div className="h-10 inline-flex items-center gap-2 text-[0.8125rem] bg-[#fafafa] py-2 px-4 rounded-lg border border-light-stroke">
            <img className="w-5 shrink-0" src={Available} alt="license" />
            <div>Available License:</div>
            <span className="font-medium">{availableLicense}</span>
            <Link
              to="/settings/billing/plans"
              className="text-primary font-medium"
            >
              Buy more
            </Link>
          </div>
          <div className="h-10 inline-flex items-center gap-2 text-[0.8125rem] bg-[#fafafa] py-2 px-4 rounded-lg border border-light-stroke">
            <img className="w-4 shrink-0" src={DeviceImg} alt="device" />
            <div>Available Device:</div>
            <span className="font-medium">{availableDevice}</span>
          </div>
          <div className="h-10 inline-flex items-center gap-2 text-[0.8125rem] bg-[#fafafa] py-2 px-4 rounded-lg border border-light-stroke">
            <Bridge className="w-4 shrink-0 text-[#BDBDBD]" />
            <div>Selected Bridge:</div>
            <span className="font-medium">{getBridgeName(bridge)}</span>
          </div>
          <div className="h-10 inline-flex items-center gap-2 text-[0.8125rem] bg-[#fafafa] py-2 px-4 rounded-lg border border-light-stroke">
            <DeviceIcon
              type="Security Camera"
              className="w-4 shrink-0 text-[#BDBDBD]"
            />
            <div>Cameras Added:</div>
            <span className="font-medium">
              {bridge.devices_count}/{bridge.channel}
            </span>
          </div>
        </div>
        <Input
          className="w-72"
          placeholder="Search device by name"
          size="large"
          suffix={<Search />}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      {isLoading && (
        <div className="h-[80vh] flex flex-col items-center justify-center">
          <Spinner className="h-auto mb-4" />
          <div className="text-light-secondary text-[0.8125rem]">
            Scanning for cameras, this progress can take about a minute...
          </div>
        </div>
      )}
      {!isLoading && (!onvifDevices || !onvifDevices.length) && (
        <div className="h-[80vh] flex flex-col justify-center items-center">
          <img className="w-20 mb-6" src={NoResult} alt="no-result" />
          <div className="text-base font-medium mb-2">No camera found</div>
          <div className="text-[0.8125rem] text-light-secondary">
            In case you got any problem with the Bridge,{' '}
            <a
              href="https://broadflow.co/contact"
              target="_blank"
              rel="noreferrer"
              className="text-primary font-medium underline"
            >
              Contact Us
            </a>
          </div>
          <Button
            size="large"
            variant="primary"
            className="w-40 mt-6"
            onClick={handleGetOnvifDevices}
          >
            Retry
          </Button>
        </div>
      )}
      {!isLoading && !!onvifDevices && !!onvifDevices.length && (
        <Table
          className="px-20 mb-4"
          rowKey="id"
          columns={columns}
          scroll={{ x: 1200 }}
          data={onvifDevices.filter(d =>
            d.name.toLowerCase().includes(search.toLowerCase())
          )}
        />
      )}
      <AnimatePresence initial={false}>
        {!!selectedOnvifDevice && (
          <ModalConnectCameraToBridge
            bridgeId={bridge.id}
            onvifDevice={selectedOnvifDevice}
            onSuccess={handleGetOnvifDevices}
            onClose={() => setSelectedOnvifDevice(undefined)}
          />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default ModalOnvifDevices
