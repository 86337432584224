import { Search } from 'icons/outline'
import { Input, Pagination, RangePicker } from 'components'

interface Props {
  loading: boolean
  search: string
  onSearch: (search: string) => void
  dateRange: [Date | null, Date | null]
  onDateChange: (range: [Date | null, Date | null]) => void
  currentPage: number
  totalPage: number
  onPageChange: (page: number) => void
}

function TableToolbar({
  loading,
  search,
  onSearch,
  dateRange,
  onDateChange,
  currentPage,
  totalPage,
  onPageChange
}: Props) {
  const handleSearch: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      value !== search && onSearch(value)
    }
  }

  const handleDateChange = ([from, to]: [Date | null, Date | null]) => {
    if ((!from && !to) || (!!from && !!to)) {
      onDateChange([from, to])
    }
  }

  return (
    <div className="mb-4 flex flex-wrap justify-between items-center gap-4">
      <Input
        suffix={<Search />}
        className="w-[22.5rem]"
        onKeyDown={loading ? undefined : handleSearch}
        placeholder="Search activity by name"
      />
      <div className="inline-flex gap-2">
        <Pagination.ButtonGroup
          loading={loading}
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={loading ? undefined : onPageChange}
        />
        <RangePicker
          allowClear
          showPresets
          className="w-80"
          value={dateRange}
          placeholder={['Start Date', 'End Date']}
          onChange={(_, range) => handleDateChange(range)}
        />
      </div>
    </div>
  )
}

export default TableToolbar
