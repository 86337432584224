import { useState } from 'react'

import { Button, Checkbox } from 'components'
import ChannelRecipientForm from 'shared/Forms/ChannelRecipientForm'

import { FormValues, initFormValues } from './FormHelpers'

interface Props {
  loading: boolean
  values: FormValues['recipients']
  onSubmit: (values: FormValues['recipients']) => void
}

function Recipients({ loading, values, onSubmit }: Props) {
  const [isBlocked, setIsBlocked] = useState(values.is_blocked || false)

  const handleSubmitClick = () => {
    if (isBlocked) {
      return onSubmit({ ...initFormValues.recipients, is_blocked: true })
    }
    document.getElementById('btn-create-tag-recs')?.click()
  }

  return (
    <div className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center">
      <div className="w-[55rem] m-auto">
        <div className="text-base font-medium mb-6">
          Add Notification Channels
        </div>
        <div className="mb-4">
          <Checkbox
            size="large"
            checked={isBlocked}
            onChange={e => setIsBlocked(e.target.checked)}
          >
            Do <span className="text-danger">NOT</span> forward any notifications
            matching this tag
          </Checkbox>
        </div>
        {!isBlocked && (
          <ChannelRecipientForm
            values={values}
            submitId="btn-create-tag-recs"
            onSubmit={(c, r) => {
              onSubmit({ is_blocked: false, ...r, channels: c })
            }}
          />
        )}
        <Button
          disabled={loading}
          onClick={loading ? undefined : handleSubmitClick}
          variant="primary"
          size="large"
          className="w-40 mt-10"
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default Recipients
