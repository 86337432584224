import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function InfoCircle({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx="14" cy="14" r="14" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9994 0C6.26844 0 0 6.26844 0 13.9994C0 21.7304 6.26844 28 13.9994 28C21.7304 28 28 21.7304 28 13.9994C28 6.26844 21.7304 0 13.9994 0ZM16.9138 21.6972C16.1932 21.9817 15.6195 22.1974 15.1893 22.3467C14.7603 22.496 14.2613 22.5707 13.6936 22.5707C12.8213 22.5707 12.1422 22.3574 11.6587 21.9319C11.1751 21.5064 10.9345 20.9672 10.9345 20.3118C10.9345 20.0569 10.9523 19.7962 10.9878 19.5307C11.0246 19.2652 11.0827 18.9666 11.1621 18.6312L12.064 15.4454C12.1434 15.1396 12.2121 14.8492 12.2667 14.579C12.3212 14.3064 12.3473 14.0563 12.3473 13.8288C12.3473 13.4235 12.2631 13.139 12.096 12.979C11.9265 12.819 11.6077 12.7408 11.1324 12.7408C10.9001 12.7408 10.6607 12.7752 10.4154 12.8475C10.1724 12.9221 9.96147 12.9897 9.78844 13.056L10.0267 12.0747C10.6169 11.8341 11.1822 11.6279 11.7215 11.4572C12.2607 11.2842 12.7704 11.1989 13.2504 11.1989C14.1167 11.1989 14.7852 11.4098 15.2557 11.827C15.7238 12.2454 15.9597 12.7894 15.9597 13.4578C15.9597 13.5965 15.9431 13.8406 15.9111 14.1891C15.8791 14.5387 15.8187 14.8575 15.731 15.1503L14.8338 18.3266C14.7603 18.5814 14.6951 18.8729 14.6358 19.1989C14.5778 19.5248 14.5493 19.7737 14.5493 19.9408C14.5493 20.3627 14.643 20.6507 14.8326 20.8036C15.0198 20.9565 15.3481 21.0335 15.8127 21.0335C16.032 21.0335 16.2773 20.9944 16.5547 20.9186C16.8296 20.8427 17.0287 20.7752 17.1544 20.7171L16.9138 21.6972ZM15.2439 9.38783C15.833 9.38783 16.3367 9.19345 16.7551 8.80471C17.1734 8.41597 17.3832 7.94427 17.3832 7.39079C17.3832 6.83968 17.1734 6.3656 16.7551 5.97331C16.3379 5.57983 15.833 5.38427 15.2439 5.38427C14.6561 5.38427 14.1488 5.57983 13.7269 5.97331C13.3085 6.3656 13.0952 6.83968 13.0952 7.39079C13.0952 7.94308 13.3074 8.41597 13.7269 8.80471C14.1488 9.19345 14.6561 9.38783 15.2439 9.38783Z"
          fill="url(#paint0_linear_875_10)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_875_10"
            x1="14"
            y1="0"
            x2="14"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
