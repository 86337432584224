import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function NetworkVideoRecorder(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="17"
        height="15"
        viewBox="0 0 17 15"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M13.8491 7.13452H13.1445V5.86499H13.8491C14.0585 5.86499 14.2172 5.92212 14.3251 6.03638C14.433 6.14852 14.487 6.30404 14.487 6.50293C14.487 6.70182 14.433 6.85734 14.3251 6.96948C14.2193 7.07951 14.0606 7.13452 13.8491 7.13452Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0.00488281C0.895431 0.00488281 0 0.900313 0 2.00488V12.0049C0 13.1095 0.89543 14.0049 2 14.0049H15C16.1046 14.0049 17 13.1095 17 12.0049V2.00488C17 0.900313 16.1046 0.00488281 15 0.00488281H2ZM5.2085 9.62598H4.10083L2.46313 6.75049V9.62598H1.34912V5.00488H2.46313L4.09766 7.88037V5.00488H5.2085V9.62598ZM8.6163 8.36914L9.53671 5.00488H10.7808L9.22567 9.62598H8.00692L6.46444 5.00488H7.69906L8.6163 8.36914ZM13.1445 7.99463H13.7475L14.5632 9.62598H15.7565V9.57837L14.7917 7.71533C15.0689 7.58415 15.272 7.41276 15.4011 7.20117C15.5322 6.98958 15.5978 6.72298 15.5978 6.40137C15.5978 5.95703 15.4434 5.6132 15.1345 5.36987C14.8255 5.12655 14.3971 5.00488 13.8491 5.00488H12.0304V9.62598H13.1445V7.99463Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
