import shallow from 'zustand/shallow'

import { Bell, Cloud } from 'icons/filled'
import { ChevronRight } from 'icons/outline'
import { Button } from 'components'
import ExtraFeatureSelectOption from 'shared/ExtraFeatureSelectOption'
import { isCamera } from 'utils/device'
import useStore from 'store'

import { extraSteps, useFormCtx } from './FormContext'

interface Props {
  loading: boolean
  onSubmit: () => void
}

function ExtraFeatures({ loading, onSubmit }: Props) {
  const stats = useStore(state => state.stat.stats, shallow)
  const {
    onStepChange,
    steps,
    extraFeatures,
    deviceDetail,
    cloudSurveillance,
    dispatch
  } = useFormCtx()

  const { total = 0, used = 0 } = stats?.camera_licenses || {}
  const isCameraType = isCamera(deviceDetail.type)

  const handleChange = (key: 'schedule' | 'livestream') => (v: boolean) => {
    dispatch({
      steps: v ? [...steps, extraSteps[key]] : steps.filter(s => s.key !== key),
      extraFeatures: { ...extraFeatures, [key]: v },
      cloudSurveillance: {
        ...cloudSurveillance,
        is_licensed: key === 'livestream',
        enable_recording: key === 'livestream'
      }
    })
  }

  return (
    <div className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center">
      <div className="w-[25rem] m-auto">
        <div className="text-base font-medium mb-6">Select extra features</div>
        <ExtraFeatureSelectOption
          className="mb-3"
          active={extraFeatures.schedule}
          onChange={handleChange('schedule')}
        >
          <Bell className="w-5 h-5 shrink-0" type="primary" />
          <div className="text-sm">Set up Notification Schedule</div>
        </ExtraFeatureSelectOption>
        <ExtraFeatureSelectOption
          active={extraFeatures.livestream}
          onChange={handleChange('livestream')}
          disabled={!(used < total) || !isCameraType}
          showUpgrade={isCameraType}
        >
          <Cloud className="w-5 h-5 shrink-0" type="primary" />
          <div className="text-sm">Activate Livestream & Playback</div>
        </ExtraFeatureSelectOption>

        {Object.values(extraFeatures).every(v => !v) ? (
          <Button
            size="large"
            block
            variant="primary"
            className="mt-8"
            disabled={loading}
            onClick={loading ? undefined : onSubmit}
          >
            Skip & Save
          </Button>
        ) : (
          <Button
            size="large"
            block
            variant="primary"
            className="mt-8"
            onClick={() => onStepChange(1)}
          >
            Next <ChevronRight />
          </Button>
        )}
      </div>
    </div>
  )
}

export default ExtraFeatures
