import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function SpeakerLouder(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M8.93423 0.124903L3.26543 4.272H0.67921C0.306951 4.272 0 4.57895 0 4.95121V10.9988C0 11.3711 0.306951 11.678 0.67921 11.678H3.31115L8.93749 15.8218C9.21505 16.0994 10.0249 15.959 10.0249 15.3124V0.67023C10.0216 0.00408154 9.23464 -0.139598 8.93423 0.124903ZM2.81807 10.3522H1.32577V5.5945H2.82133V10.3522H2.81807ZM8.66319 13.9867L4.21241 10.718V5.23204L8.66319 1.96008V13.9867Z"
          fill="currentColor"
        />
        <path
          d="M3.55084 4.94941H0.532288V11.1641H3.55084L9.41039 15.0705V0.865479L3.55084 4.94941Z"
          fill="currentColor"
        />
        <path
          d="M14.2661 3.52396C13.995 3.25293 13.5869 3.25293 13.3158 3.4913C13.0448 3.76233 13.0448 4.17051 13.2832 4.44154C15.1183 6.4106 15.1183 9.57154 13.2832 11.5079C13.0448 11.779 13.0448 12.2198 13.3158 12.4582C13.5444 12.6868 13.9852 12.7031 14.2661 12.3896C16.578 9.9438 16.578 5.96977 14.2661 3.52396Z"
          fill="currentColor"
        />
        <path
          d="M12.2646 5.6304C11.9936 5.35937 11.5854 5.35937 11.3144 5.59775C11.0433 5.86878 11.0433 6.27696 11.3144 6.54799C12.0621 7.32843 12.0621 8.62154 11.3144 9.40198C11.076 9.67301 11.076 10.1138 11.347 10.3522C11.5233 10.5286 11.9479 10.633 12.2646 10.3196C13.5218 9.05911 13.4859 6.92025 12.2646 5.6304Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
