import { Close } from 'icons/outline'
import { Button, Input, Modal, ModalProps } from 'components'
import { useState } from 'react'

interface Props extends ModalProps {
  value: string
  onEdit: (value: string) => void
}

function ModalEditTabName({ value: v, onEdit, ...props }: Props) {
  const [value, setValue] = useState(v)

  const handleSave = () => {
    onEdit(value)
    props.onClose()
  }

  return (
    <Modal {...props} className="w-[31.25rem] px-6">
      <div className="flex items-center justify-between gap-2 mb-6">
        <div className="text-lg font-medium">Edit Tab Name</div>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      <Input value={value} onChange={e => setValue(e.target.value)} />
      <div className="mt-6 flex items-center justify-between">
        <Button className="w-[5rem]" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="primary" className="w-[5rem]" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default ModalEditTabName
