import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Addons({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g
          clipPath="url(#clip0_11223_1197)"
          filter="url(#filter0_d_11223_1197)"
        >
          <path
            d="M18.619 9.52381H17.1904V5.71428C17.1904 4.66666 16.3333 3.80952 15.2857 3.80952H11.4761V2.38095C11.4761 1.06666 10.4095 -3.8147e-06 9.09518 -3.8147e-06C7.78089 -3.8147e-06 6.71422 1.06666 6.71422 2.38095V3.80952H2.9047C1.85708 3.80952 1.00946 4.66666 1.00946 5.71428V9.33333H2.42851C3.84756 9.33333 4.99994 10.4857 4.99994 11.9048C4.99994 13.3238 3.84756 14.4762 2.42851 14.4762H0.999939V18.0952C0.999939 19.1429 1.85708 20 2.9047 20H6.52375V18.5714C6.52375 17.1524 7.67613 16 9.09518 16C10.5142 16 11.6666 17.1524 11.6666 18.5714V20H15.2857C16.3333 20 17.1904 19.1429 17.1904 18.0952V14.2857H18.619C19.9333 14.2857 20.9999 13.219 20.9999 11.9048C20.9999 10.5905 19.9333 9.52381 18.619 9.52381Z"
            fill="transparent"
          />
          <path
            d="M18.619 9.52381H17.1904V5.71428C17.1904 4.66666 16.3333 3.80952 15.2857 3.80952H11.4761V2.38095C11.4761 1.06666 10.4095 -3.8147e-06 9.09518 -3.8147e-06C7.78089 -3.8147e-06 6.71422 1.06666 6.71422 2.38095V3.80952H2.9047C1.85708 3.80952 1.00946 4.66666 1.00946 5.71428V9.33333H2.42851C3.84756 9.33333 4.99994 10.4857 4.99994 11.9048C4.99994 13.3238 3.84756 14.4762 2.42851 14.4762H0.999939V18.0952C0.999939 19.1429 1.85708 20 2.9047 20H6.52375V18.5714C6.52375 17.1524 7.67613 16 9.09518 16C10.5142 16 11.6666 17.1524 11.6666 18.5714V20H15.2857C16.3333 20 17.1904 19.1429 17.1904 18.0952V14.2857H18.619C19.9333 14.2857 20.9999 13.219 20.9999 11.9048C20.9999 10.5905 19.9333 9.52381 18.619 9.52381Z"
            fill="url(#paint0_linear_11223_1197)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_11223_1197"
            x="0"
            y="0"
            width="22"
            height="22"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_11223_1197"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_11223_1197"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_11223_1197"
            x1="0.999939"
            y1="-3.8147e-06"
            x2="0.999939"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <clipPath id="clip0_11223_1197">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(1)"
            />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
