import { createRoot } from 'react-dom/client'
import { ToastContainer } from 'react-toastify'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'

import './bootstrap'
import App from './App'

import history from 'configs/history'
import { isMobile } from 'utils/functions'

if (isMobile()) {
  const newOrigin = process.env.REACT_APP_MOBILE_BASE_URL
  window.location.href =
    newOrigin + window.location.pathname + window.location.search
} else {
  createRoot(document.getElementById('root')!).render(
    <HistoryRouter history={history}>
      <App />
      <ToastContainer
        newestOnTop
        hideProgressBar
        pauseOnFocusLoss={false}
        icon={false}
        closeButton={false}
      />
    </HistoryRouter>
  )
}
