import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function DigitalVideoRecorder(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="17"
        height="14"
        viewBox="0 0 17 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M4.2187 5.88257H2.68462V8.1837H4.2187V5.88257Z"
          fill="currentColor"
        />
        <path
          d="M15.341 0.129883H1.53408C0.686504 0.129883 0 0.81642 0 1.66399V12.4026C0 13.2502 0.686504 13.9367 1.53408 13.9367H15.3409C16.1885 13.9367 16.875 13.2502 16.875 12.4026V1.66399C16.875 0.81642 16.1885 0.129883 15.341 0.129883ZM5.36933 8.18389C5.36933 8.83588 4.87075 9.33445 4.21876 9.33445H1.53408V4.73218H4.21876C4.87075 4.73218 5.36933 5.23076 5.36933 5.88275V8.18389ZM8.91308 9.33445H7.76252L6.42019 4.73218H7.57076L8.33782 7.36314L9.10487 4.73218H10.2554L8.91308 9.33445ZM15.341 6.64981C15.341 7.11005 15.0341 7.53192 14.6506 7.72368L15.341 9.33449H14.1904L13.5384 7.80041H12.6563V9.33449H11.5057V4.73218H14.1904C14.8424 4.73218 15.341 5.23076 15.341 5.88275V6.64981Z"
          fill="currentColor"
        />
        <path
          d="M14.1904 5.88257H12.6563V6.64962H14.1904V5.88257Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
