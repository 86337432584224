import { format } from 'date-fns'

import { dateTimeFormat } from 'utils/dateTime'

import { Device } from './devices'
import client, { ResponseMeta } from './client'

export interface VideoRecord {
  device: Device
  device_id: number
  end_timestamp: string
  expiry: string
  failed_reason: string | null
  file_urls: string[] | null
  files: { path: string; size: number }[] | null
  id: number
  start_timestamp: string
  status: string
  user_id: number
}

export type VideoRecordResponse = {
  data: VideoRecord[]
  meta: ResponseMeta
}

export const getVideoRecords = ({
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = 10,
  currentPage = 1
}: {
  sortBy?: string
  sortDirection?: string
  pageSize?: number
  currentPage?: number
}) => {
  return client.get<VideoRecordResponse>(
    `/api/recordingDownloads?include=device&order_by=${sortBy}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}`
  )
}

export const searchRecords = async (payload: {
  device_id: number
  start_timestamp: Date | string
  end_timestamp: Date | string
}) => {
  const formatStr = `${dateTimeFormat['yyyy-MM-dd']} ${dateTimeFormat['HH:mm']}`
  const start = format(new Date(payload.start_timestamp), formatStr)
  const end = format(new Date(payload.end_timestamp), formatStr)

  await client.get('/api/csrf-cookie')
  return client.post('/api/recordingDownloads', {
    ...payload,
    start_timestamp: start,
    end_timestamp: end
  })
}
