import { useState } from 'react'
import { AxiosResponse } from 'axios'

import { Close } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Form, FormField, Input, Modal, ModalProps } from 'components'
import { required } from 'utils/rules'
import toast from 'utils/toast'
import { FloorPlan, updateFloorPlan } from 'services/floorPlans'

interface Props extends ModalProps {
  floorPlan: FloorPlan
  onSuccess?: (floorPlan: FloorPlan) => void
}

function ModalEditFloorName({ floorPlan, onSuccess, ...props }: Props) {
  const [form] = Form.useForm()
  const [name, setName] = useState('')

  const { isLoading, execute } = useAsync<AxiosResponse<{ data: FloorPlan }>>({
    showNotifOnError: true
  })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value
    setName(prev => {
      if (value.length > 30) {
        form.setFieldsValue({ name: prev })
        return prev
      }
      form.setFieldsValue({ name: value })
      return value
    })
  }

  const handleSubmit = async ({ name }: { name?: string }) => {
    if (isLoading) return
    const res = await execute(updateFloorPlan(floorPlan.id, { name }))
    onSuccess?.(res.data.data)
    toast.success(
      { title: 'Floor plan name updated' },
      { position: 'bottom-center' }
    )
    handleClose()
  }

  return (
    <Modal className="!p-0 w-[28.125rem]" {...props} onClose={handleClose}>
      <div className="p-4 flex items-center justify-between border-b border-light-stroke mb-1">
        <div className="text-base font-medium">Edit Floor Name</div>
        <Close className="shrink-0 cursor-pointer" onClick={handleClose} />
      </div>
      <Form
        className="p-4"
        form={form}
        initialValues={{ name: floorPlan.name }}
        onFinish={handleSubmit}
      >
        <FormField
          name="name"
          label="Floor Plan Name"
          rules={[required]}
          requiredMask
        >
          <Input
            onChange={handleNameChange}
            placeholder="Name your floor plan"
            extra={
              <div className="text-right text-[0.8125rem] text-light-secondary">
                {30 - name.length}
              </div>
            }
          />
        </FormField>
        <div className="text-right">
          <Button
            disabled={isLoading}
            type="submit"
            variant="primary"
            className="w-[7.5rem]"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalEditFloorName
