import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import {
  CheckedCircle,
  ClockCircle,
  Close,
  Crown,
  Pencil,
  Status
} from 'icons/outline'
import { Email, User, ClockCircle as ClockFilled } from 'icons/filled'
import { Avatar, Button, Modal, ModalProps } from 'components'
import { dateTimeFormat } from 'utils/dateTime'
import { User as IUser } from 'services/users'

interface Props extends ModalProps {
  user: IUser
  onEdit: (user: IUser) => void
}

function ModalViewUserProfile({ user, onEdit, ...props }: Props) {
  const navigate = useNavigate()

  const handleNagivate = () => {
    navigate('/activity-log', {
      state: { userId: user.id, username: user.name }
    })
  }

  const handleEdit = () => {
    onEdit(user)
    props.onClose()
  }

  return (
    <Modal {...props} className="w-[34.375rem] px-6">
      <div className="flex items-center justify-between gap-4 mb-6">
        <div className="text-base font-medium">User Profile</div>
        <Close onClick={props.onClose} className="cursor-pointer" />
      </div>
      <div className="flex gap-4">
        <Avatar
          className="flex-shrink-0"
          size={120}
          src={user.avatar_url}
          username={user.name}
        />
        <div className="w-full">
          <div className="text-base font-medium mb-4">{user.name}</div>
          <div className="flex items-center text-[0.8125rem] gap-4">
            <div className="flex items-center gap-1.5 w-[6.6rem]">
              <Email className="w-4 h-4" color="#BABBBF" />
              <div className="font-medium">Email</div>
            </div>
            <div>{user.email}</div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-3" />
          <div className="flex items-center text-[0.8125rem] gap-4">
            <div className="flex items-center gap-1.5 w-[6.6rem]">
              <User className="w-4 h-4" color="#BABBBF" />
              <div className="font-medium">User Role</div>
            </div>
            <div className="inline-flex items-center gap-1">
              {!!user.is_admin && <Crown className="text-warning" />}
              {!!user.is_admin ? 'Admin' : 'Standard User'}
            </div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-3" />
          <div className="flex items-center text-[0.8125rem] gap-4">
            <div className="flex items-center gap-1.5 w-[6.6rem]">
              <Status className="w-4 h-4" color="#BABBBF" />
              <div className="font-medium">Status</div>
            </div>
            {!!user.email_verified_at && (
              <div className="inline-flex items-center gap-1 text-success">
                <CheckedCircle className="w-3 h-3 text-success" />
                <span>Verified</span>
              </div>
            )}
            {!user.email_verified_at && (
              <div className="inline-flex items-center gap-1 text-warning">
                <ClockCircle className="w-3 h-3" />
                <span>Verification pending</span>
              </div>
            )}
          </div>
          <div className="border-b border-dashed border-light-stroke my-3" />
          <div className="flex items-center text-[0.8125rem] gap-4">
            <div className="flex items-center gap-1.5 w-[6.6rem]">
              <ClockFilled className="w-4 h-4" color="#BABBBF" />
              <div className="font-medium">Created At</div>
            </div>
            <div className="inline-flex items-center gap-1">
              {format(new Date(user.created_at), dateTimeFormat['MM-dd-yyyy'])}
            </div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-3" />
          <div className="flex items-center gap-2">
            <Button
              variant="primary"
              className="w-[10.625rem]"
              onClick={handleNagivate}
            >
              <ClockFilled color="#fff" /> View Activity Log
            </Button>
            <Button className="w-[6.25rem]" onClick={handleEdit}>
              <Pencil /> Edit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalViewUserProfile
