import { Fragment, useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import { Broadflow } from 'icons/logos'
import { DeviceIcon } from 'icons/utils'
import { DeviceIconType } from 'icons/utils/DeviceIcon'
import ModalSelectSensorCount from 'shared/Modals/Billing/SelectSensorCount'
import {
  cameraTypes,
  isSensor,
  networkTypes,
  scannerTypes,
  securityTypes,
  sensorTypes,
  videoServerTypes
} from 'utils/device'
import useStore from 'store'

import { useFormCtx, initState } from './FormContext'

function SelectDeviceType() {
  const [visible, setVisible] = useState(false)
  const { onStepChange, deviceDetail, dispatch } = useFormCtx()

  const betaFeature = useStore(
    state => state.auth.accessBetaFeatures(),
    shallow
  )

  useEffect(() => {
    dispatch(initState)
  }, [])

  const handleSelect = (type: DeviceIconType) => {
    if (isSensor(type)) return setVisible(true)
    dispatch({ deviceDetail: { ...deviceDetail, type } })
    onStepChange(1)
  }

  const renderDeviceType = (type: DeviceIconType) => {
    return (
      <div
        key={type}
        onClick={() => handleSelect(type)}
        className={cx(
          'group base-transition border border-light-stroke text-center w-[calc(100%_/_3)] rounded-lg cursor-pointer',
          'hover:bg-primary-lighten hover:light-primary-stroke'
        )}
      >
        <div className="flex flex-col justify-center items-center gap-2 px-2 py-6 relative">
          {isSensor(type) && (
            <Broadflow className="w-4 h-auto absolute left-3 top-[0.625rem]" />
          )}
          <DeviceIcon
            type={type}
            className="base-transition w-5 h-5 shrink-0 text-[#bdbdbd] group-hover:text-primary"
          />
          <div className="base-transition text-[0.8125rem] group-hover:text-primary">
            {type}
          </div>
        </div>
      </div>
    )
  }

  const scannerAndSensorTypes = [
    ...scannerTypes,
    ...(betaFeature ? sensorTypes : []),
    'Other'
  ] as DeviceIconType[]

  return (
    <Fragment>
      <div className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center">
        <div className="w-[39rem] m-auto">
          <div className="text-base font-medium mb-6">
            Please select device type
          </div>
          <div className="mb-6">
            <div className="text-[0.8125rem] font-medium mb-2">
              Camera & Video Servers
            </div>
            <div className="flex gap-2.5">
              {[...cameraTypes, ...videoServerTypes].map(renderDeviceType)}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-[0.8125rem] font-medium mb-2">
              Network Devices
            </div>
            <div className="flex gap-2.5">
              {networkTypes.map(renderDeviceType)}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-[0.8125rem] font-medium mb-2">
              Security Systems
            </div>
            <div className="flex gap-2.5">
              {securityTypes.map(renderDeviceType)}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-[0.8125rem] font-medium mb-2">
              Scanners & Sensors
            </div>
            <div className="flex gap-2.5">
              {scannerAndSensorTypes.map(renderDeviceType)}
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {visible && (
          <ModalSelectSensorCount onClose={() => setVisible(false)} />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default SelectDeviceType
