import { useEffect } from 'react'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import { useAsync } from 'hooks'
import { Spinner } from 'components'
import { FloorPlanResponse, getAllFloorPlans } from 'services/floorPlans'
import useStore from 'store'

import SideList from './SideList'
import MainContent from './MainContent'
import PinDetail from './PinDetail'
import { useLocationCtx } from '../LocationContext'

function FloorPlans({ activeKey }: { activeKey: string }) {
  const locations = useStore(state => state.location.locations, shallow)
  const { selectedLocation, dispatch } = useLocationCtx()

  const floorPlanAsync = useAsync<AxiosResponse<FloorPlanResponse>>({
    showNotifOnError: true,
    status: 'pending'
  })

  useEffect(() => {
    if (activeKey === 'floor-plans') {
      dispatch({
        showLocationDetail: false,
        selectedLocation: selectedLocation || locations[0]
      })
    }
  }, [activeKey])

  useEffect(() => {
    floorPlanAsync
      .execute(getAllFloorPlans())
      .then(res => dispatch({ floorPlans: res.data.data }))
  }, [])

  if (floorPlanAsync.isLoading) {
    return <Spinner className="h-[calc(100vh_-_6.75rem_-_3.125rem)]" />
  }

  return (
    <div className="flex h-[calc(100vh_-_6.75rem_-_3.125rem)]">
      <SideList />
      <MainContent />
      <PinDetail />
    </div>
  )
}

export default FloorPlans
