import Screenshot from 'assets/screenshots/DeviceManagement.png'
import { ChevronRight } from 'icons/outline'

function DeviceManagement() {
  return (
    <div className="p-4 grow">
      <div className="text-xl font-medium mb-4">Device Management</div>
      <img src={Screenshot} alt="screenshot" className="w-full h-[25.625rem]" />
      <div className="mt-12">
        <div className="font-medium text-base mb-4">
          What is Device Management?
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Conveniently manage all of your devices using Streams Device
          Management.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Use this feature to add all of your cameras and devices, whether to
          receive notifications, record video to the cloud, live stream or all
          of the above.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Organize your devices by location using the Device Location tab. If
          you have deployed a Streams Bridge to your local network, visit the
          Bridge Management tab for easy access.
        </div>
      </div>
      <div className="mt-12">
        <div className="font-medium text-base mb-4">Get Support</div>
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/devices/what-is-a-device"
          target="_blank"
          rel="noreferrer"
        >
          What is a device?
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/devices/how-to-add-device"
          target="_blank"
          rel="noreferrer"
        >
          How to add a device
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/devices/how-to-add-location"
          target="_blank"
          rel="noreferrer"
        >
          How to add a location
          <ChevronRight />
        </a>
      </div>
    </div>
  )
}

export default DeviceManagement
