import { Dispatch, useState } from 'react'
import { FormInstance } from 'rc-field-form'
import { FieldData } from 'rc-field-form/lib/interface'

import { Form, FormField, Input } from 'components'
import { required } from 'utils/rules'
import ScheduleDateTimeInput from 'shared/ScheduleDateTimeInput'

import { FormValues } from './FormHelpers'

interface Props {
  form: FormInstance
  values: FormValues['scheduleDetails']
  setValues: Dispatch<Partial<FormValues>>
}

function ScheduleDetails({ form, values, setValues }: Props) {
  const [dateTime, setDateTime] = useState({
    is247: values.is_24_7,
    startTime: values.start_time,
    endTime: values.end_time,
    days: values.days
  })

  const handleFieldChange = (field: FieldData[]) => {
    const name = (
      field[0].name as string[]
    )[0] as keyof FormValues['scheduleDetails']
    setValues({ scheduleDetails: { ...values, [name]: field[0].value } })
  }

  const handleDateTimeChange = (value: typeof dateTime) => {
    setDateTime(value)
    setValues({
      scheduleDetails: {
        ...values,
        is_24_7: value.is247,
        start_time: value.startTime,
        end_time: value.endTime,
        days: value.days
      }
    })
  }

  return (
    <div className="min-h-[calc(100vh_-_5.15rem)] py-4 flex flex-col justify-center">
      <Form
        form={form}
        initialValues={values}
        className="w-[37.5rem] m-auto"
        onFieldsChange={handleFieldChange}
      >
        <div className="text-base font-medium mb-6">Schedule details</div>
        <FormField
          name="name"
          label="Schedule name"
          rules={[required]}
          requiredMask
        >
          <Input size="large" placeholder="Name your schedule" />
        </FormField>
        <FormField
          name="dateTime"
          label="We will send notifications during the following times"
          requiredMask
        >
          <ScheduleDateTimeInput
            {...dateTime}
            onChange={handleDateTimeChange}
          />
        </FormField>
      </Form>
    </div>
  )
}

export default ScheduleDetails
