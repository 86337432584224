import { FC, SVGProps } from 'react'

import { ClockCircle } from 'icons/filled'
import {
  Camera,
  LocationPin,
  Text,
  Tag,
  Attachment,
  Image
} from 'icons/outline'

type IconType =
  | 'camera'
  | 'location'
  | 'text'
  | 'tag'
  | 'attachment'
  | 'image'
  | 'time'

type Props = {
  type: IconType
  iconColor?: [start: string, stop: string] | string
  iconType?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & SVGProps<SVGSVGElement>

export function NotifDetailIcon({
  type,
  iconColor,
  iconType,
  ...props
}: Props) {
  let Icon: FC<any> | null = null

  if (type === 'camera') {
    Icon = Camera
  }
  if (type === 'location') {
    Icon = LocationPin
  }
  if (type === 'text') {
    Icon = Text
  }
  if (type === 'tag') {
    Icon = Tag
  }
  if (type === 'attachment') {
    Icon = Attachment
  }
  if (type === 'image') {
    Icon = Image
  }

  let FilledIcon: FC<any> | null = null
  if (type === 'time') {
    FilledIcon = ClockCircle
  }

  if (Icon) {
    return <Icon {...props} />
  } else if (FilledIcon) {
    return <FilledIcon color={iconColor} type={iconType} {...props} />
  } else {
    return null
  }
}
