import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { pick } from 'lodash'

import shallow from 'zustand/shallow'
import { ChevronRight, Plus } from 'icons/outline'
import { Button, Form, FormField, Input, Select } from 'components'
import ModalCreateUpdateLocation from 'shared/Modals/Location/CreateUpdateLocation'
import { timezones } from 'utils/dateTime'
import { required } from 'utils/rules'
import useStore from 'store'

import { State, useFormCtx } from './FormContext'

function DeviceDetail() {
  const [visible, setVisible] = useState(false)

  const locations = useStore(state => state.location.locations, shallow)
  const { onStepChange, deviceDetail, dispatch } = useFormCtx()

  const handleSubmit = (
    values: Pick<State['deviceDetail'], 'name' | 'timezone' | 'location'>
  ) => {
    dispatch({ deviceDetail: { ...deviceDetail, ...values } })
    onStepChange(1)
  }

  return (
    <div
      id="device-details"
      className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center"
    >
      <div className="w-[26rem] m-auto">
        <div className="text-base font-medium mb-6">Enter device details</div>
        <Form
          initialValues={pick(deviceDetail, ['name', 'timezone', 'location'])}
          onFinish={handleSubmit}
        >
          <FormField
            name="name"
            label="Device Name"
            rules={[required]}
            requiredMask
          >
            <Input size="large" placeholder="Name your device" />
          </FormField>
          <FormField
            name="timezone"
            label="Device Timezone"
            rules={[required]}
            requiredMask
          >
            <Select
              size="large"
              placeholder="Choose device timezone"
              options={timezones.map(tz => ({ label: tz, value: tz }))}
            />
          </FormField>
          <FormField
            name="location"
            label={
              <div className="flex items-center justify-between">
                <div>Device Location</div>
                <div
                  className="inline-flex items-center text-primary cursor-pointer gap-1"
                  onClick={() => setVisible(true)}
                >
                  <Plus className="w-3 h-3" /> Create Location
                </div>
              </div>
            }
          >
            <Select
              size="large"
              placeholder="Select device location"
              options={locations.map(l => ({ label: l.name, value: l.id }))}
            />
          </FormField>
          <Button
            type="submit"
            size="large"
            block
            variant="primary"
            className="mt-8"
          >
            Next <ChevronRight />
          </Button>
        </Form>
      </div>
      <AnimatePresence initial={false}>
        {visible && (
          <ModalCreateUpdateLocation onClose={() => setVisible(false)} />
        )}
      </AnimatePresence>
    </div>
  )
}

export default DeviceDetail
