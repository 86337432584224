import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import { Broadflow } from 'icons/logos'
import { Bell, Gear, QuestionCircle, Support } from 'icons/outline'
import { useAsync } from 'hooks'
import {
  Avatar,
  Badge,
  Overlay,
  Popover,
  Portal,
  Spinner,
  Tooltip
} from 'components'
import ModalGuideBook from 'shared/Modals/GuideBook'
import PopoverSystemNotifications from 'shared/Popovers/SystemNotifications'
import PopoverUserMenu from 'shared/Popovers/UserMenu'
import ModalViewUserProfile from 'shared/Modals/AuthUser/ViewProfile'
import ModalEditUserProfile from 'shared/Modals/AuthUser/EditProfile'
import ModalChangePassword from 'shared/Modals/AuthUser/ChangePassword'
import { logout } from 'services/auth'
import useStore, { Store } from 'store'

import { MenuItem } from './MenuItem'

const mapState = ({ auth, notif }: Store) => ({
  user: auth.currentUser,
  notifs: notif.notifs,
  getNotifs: notif.getNotifs,
  unreadCount: notif.unreadCount
})

function Header() {
  const [visible, setVisible] = useState('')
  const { show } = useIntercom()

  const { isLoading, isSuccess, execute } = useAsync({ showNotifOnError: true })
  const { user, unreadCount, getNotifs } = useStore(mapState, shallow)

  useEffect(() => {
    if (!!user) getNotifs(1)
  }, [user?.id])

  const handleLogout = async () => {
    await execute(logout())
    window.location.href = window.location.origin + '/login'
  }

  return (
    <Fragment>
      <div className="fixed top-0 right-0 left-0 w-full pl-[3.4375rem] h-[3.125rem] border-b bg-white border-[#E7E7E7] z-[1]">
        <div className="w-full h-full px-4 flex items-center justify-between">
          <MenuItem className="w-fit hover:!bg-transparent">
            <Link
              to="/"
              className="flex justify-center items-center"
              children={<Broadflow />}
            />
          </MenuItem>
          <div className="flex items-center">
            <Tooltip content="Contact Support" placement="bottom">
              <MenuItem
                className="justify-center w-[3.125rem]"
                children={<Support className="w-5 h-5" />}
                onClick={show}
              />
            </Tooltip>
            <Tooltip content="Guide" placement="bottom">
              <MenuItem
                className="justify-center w-[3.125rem]"
                children={<QuestionCircle />}
                onClick={() => setVisible('guide-book')}
              />
            </Tooltip>
            <Tooltip content="Settings" placement="bottom">
              <MenuItem className="justify-center w-[3.125rem]">
                <Link
                  to="/settings"
                  className="w-full h-full flex items-center justify-center"
                  children={<Gear />}
                />
              </MenuItem>
            </Tooltip>
            <Popover
              placement="bottom-end"
              content={onClose => (
                <PopoverSystemNotifications onClose={onClose} />
              )}
            >
              <Tooltip content="Notifications" placement="bottom">
                <MenuItem className="justify-center w-[3.125rem]">
                  <Badge count={unreadCount}>
                    <Bell />
                  </Badge>
                </MenuItem>
              </Tooltip>
            </Popover>
            {user && (
              <Popover
                placement="bottom-end"
                content={onClose => (
                  <PopoverUserMenu
                    onClose={onClose}
                    onViewProfile={() => setVisible('view-profile')}
                    onChangePassword={() => setVisible('change-password')}
                    onLogout={handleLogout}
                  />
                )}
              >
                <Tooltip content="Account" placement="bottom">
                  <MenuItem
                    className="justify-center w-[3.125rem] ml-1"
                    children={
                      <Avatar
                        className="w-7 h-7"
                        key={user.avatar_url}
                        size="small"
                        username={user.name}
                        src={user.avatar_url}
                      />
                    }
                  />
                </Tooltip>
              </Popover>
            )}
          </div>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {visible === 'guide-book' && (
          <ModalGuideBook onClose={() => setVisible('')} />
        )}
        {visible === 'view-profile' && (
          <ModalViewUserProfile
            onEdit={() => setVisible('edit-profile')}
            onChangePassword={() => setVisible('change-password')}
            onClose={() => setVisible('')}
          />
        )}
        {visible === 'edit-profile' && (
          <ModalEditUserProfile onClose={() => setVisible('')} />
        )}
        {visible === 'change-password' && (
          <ModalChangePassword onClose={() => setVisible('')} />
        )}
      </AnimatePresence>
      {(isLoading || isSuccess) && (
        <Portal>
          <Overlay zIndex={9999} lockScroll className="bg-white/60">
            <Spinner.Fullscreen />
          </Overlay>
        </Portal>
      )}
    </Fragment>
  )
}

export default Header
