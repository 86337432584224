import { useEffect, useReducer, useRef } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import cx from 'classnames'
import { AxiosResponse } from 'axios'

import {
  CheckedCircle,
  Download,
  ShareAlt,
  Spinner,
  WarningCircle
} from 'icons/outline'
import { DeviceIcon } from 'icons/utils'
import { useAsync } from 'hooks'
import { Button, Table } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import toast from 'utils/toast'
import { downloadFile } from 'utils/file'
import { dateTimeFormat } from 'utils/dateTime'
import { addRecordToVault } from 'services/vaults'
import {
  getVideoRecords,
  VideoRecord,
  VideoRecordResponse
} from 'services/recordingDownloads'

import SearchForm from './SearchForm'
import TableToolbar from './TableToolbar'
import useStore from 'store'

interface State {
  sortBy: string
  sortDirection: string
  currentPage: number
  pageSize: PageSizes
}

const initState: State = {
  sortBy: 'id',
  sortDirection: 'desc',
  currentPage: 1,
  pageSize: 10
}

function VideoSearch() {
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const newNotifs = useStore(state => state.notif.newNotifs)
  const lastNotifUrlRef = useRef('')

  const recordAsync = useAsync<AxiosResponse<VideoRecordResponse>>({
    status: 'pending',
    showNotifOnError: true
  })
  const addToVaultAsync = useAsync({
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetVideoRecords()
  }, [...Object.values(state)])

  useEffect(() => {
    if (!!newNotifs.length) {
      const notif = newNotifs.find(n =>
        n.data.message.endsWith('ready for download')
      )
      if (!!notif && notif.data.url !== lastNotifUrlRef.current) {
        handleGetVideoRecords()
        lastNotifUrlRef.current = notif.data.url!
      }
    }
  }, [newNotifs])

  const handleGetVideoRecords = () => {
    return recordAsync.execute(getVideoRecords(state))
  }

  const handleAddToVault = async (id: number) => {
    await addToVaultAsync.execute(addRecordToVault(id))
    await handleGetVideoRecords()
    toast.success(
      { title: 'Record added to My Vault' },
      { position: 'bottom-center' }
    )
  }

  const handleDownload = (fileUrls: string[] | null) => {
    if (!!fileUrls?.length) {
      downloadFile(fileUrls[0])
    }
  }

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const formatDateCol = (dateStr: string) => {
    return format(
      new Date(dateStr),
      `${dateTimeFormat['MM-dd-yyyy']} ${dateTimeFormat['hh:mm a']}`
    )
  }

  const renderDeviceCol = ({ device }: VideoRecord) => {
    if (!device) return '---'
    return (
      <div className="flex items-center gap-1">
        <DeviceIcon type={device.type} className="text-[#bdbdbd]" />
        <Link
          className="text-sm text-primary font-medium"
          to={`/devices/${device.id}?tab=device-details`}
        >
          {device.name}
        </Link>
      </div>
    )
  }

  const renderStatusCol = ({ status }: VideoRecord) => {
    let Icon = Spinner
    if (status === 'ready') Icon = CheckedCircle
    if (status === 'failed') Icon = WarningCircle
    return (
      <div
        className={cx(
          'flex items-center gap-1',
          status === 'ready' && 'text-success',
          status === 'failed' && 'text-danger'
        )}
      >
        <Icon
          className={cx(
            'w-3.5 h-3.5',
            status !== 'ready' && status !== 'failed' && 'animate-spin'
          )}
        />
        <div className="capitalize">{status}</div>
      </div>
    )
  }

  const renderActionCol = ({ id, status, file_urls }: VideoRecord) => {
    return (
      status === 'ready' && (
        <div className="flex items-center justify-end gap-2">
          <Button variant="ternary" onClick={() => handleAddToVault(id)}>
            <ShareAlt /> Add to My Vault
          </Button>
          <Button variant="ternary" onClick={() => handleDownload(file_urls)}>
            <Download /> Download
          </Button>
        </div>
      )
    )
  }

  const columns = [
    {
      title: 'device',
      render: renderDeviceCol
    },
    {
      key: 'start_timestamp',
      title: 'start date',
      sorter: true,
      render: (record: VideoRecord) => formatDateCol(record.start_timestamp)
    },
    {
      key: 'end_timestamp',
      title: 'end date',
      sorter: true,
      render: (record: VideoRecord) => formatDateCol(record.end_timestamp)
    },
    {
      key: 'expiry',
      title: 'expire date',
      sorter: true,
      render: ({ expiry }: VideoRecord) => formatDateCol(expiry)
    },
    {
      key: 'file_size_mb',
      title: 'file size',
      render: ({ files }: VideoRecord) => `${(files || [])[0]?.size || 0} MB`
    },
    {
      key: 'status',
      title: 'status',
      sorter: true,
      render: renderStatusCol
    },
    {
      width: 300,
      render: renderActionCol
    }
  ]

  const loading = recordAsync.isLoading || addToVaultAsync.isLoading

  return (
    <div className="p-4">
      <SearchForm onRefresh={handleGetVideoRecords} />
      <TableToolbar
        loading={loading}
        currentPage={state.currentPage}
        totalPage={recordAsync.data?.data.meta.last_page || 1}
        onPageChange={p => setState({ currentPage: p })}
      />
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        data={recordAsync.data?.data.data}
        scroll={{ x: 1400 }}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={loading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={recordAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
    </div>
  )
}

export default VideoSearch
