import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Minus(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="2"
        viewBox="0 0 14 2"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M13.3 1.4H0.7C0.3 1.4 0 1.1 0 0.7C0 0.3 0.3 0 0.7 0H13.3C13.7 0 14 0.3 14 0.7C14 1.1 13.7 1.4 13.3 1.4Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
