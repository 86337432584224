import { useEffect, useRef } from 'react'
import { AxiosResponse } from 'axios'
import cx from 'classnames'

import { DeviceIcon } from 'icons/utils'
import { ChevronRight } from 'icons/outline'
import { useAsync } from 'hooks'
import {
  Button,
  Form,
  FormField,
  Input,
  InputPassword,
  Spinner
} from 'components'
import { required } from 'utils/rules'
import { ONVIF_DEVICE_STATUS } from 'utils/device'
import {
  getOnvifDeviceDetail,
  OnvifDevice,
  onvifDeviceAuth
} from 'services/bridges'
import toast from 'utils/toast'

interface Props {
  onvifDevice: OnvifDevice
  onStepChange: (step: number) => void
}

const REFETCH_TIMEOUT = 12000

function DeviceAuthForm({ onvifDevice, onStepChange }: Props) {
  const timerRef = useRef<NodeJS.Timer>()

  const authAsync = useAsync<AxiosResponse<{ data: OnvifDevice }>>({
    showNotifOnError: true
  })
  const deviceDetailAsync = useAsync<AxiosResponse<{ data: OnvifDevice }>>({
    showNotifOnError: true
  })
  const isLoading = authAsync.isLoading || deviceDetailAsync.isLoading

  useEffect(() => {
    return () => clearTimeout(timerRef.current)
  }, [])

  const handleWatchAuthStatusChange = async () => {
    const response = await authAsync.execute(
      getOnvifDeviceDetail(onvifDevice.id)
    )

    const status = response.data.data.status
    if (status === ONVIF_DEVICE_STATUS.SUCCESS) {
      return onStepChange(1)
    }
    if (status === ONVIF_DEVICE_STATUS.FAILED) {
      return toast.error({
        title: `Can't connect to ${onvifDevice.name}`,
        description: 'Please check if your Onvif is turned on'
      })
    }

    authAsync.setState({ status: 'pending' })
    timerRef.current = setTimeout(handleWatchAuthStatusChange, REFETCH_TIMEOUT)
  }

  const handleSubmit = async (values: {
    device_username: string
    device_password: string
  }) => {
    if (isLoading) return
    await authAsync.execute(onvifDeviceAuth({ id: onvifDevice.id, ...values }))
    authAsync.setState({ status: 'pending' })
    timerRef.current = setTimeout(handleWatchAuthStatusChange, REFETCH_TIMEOUT)
  }

  return (
    <div className="w-[33rem] mx-auto">
      <div className="text-base font-medium mb-2">
        Please enter this camera username & password to connect
      </div>
      <div className="text-light-secondary text-[0.8125rem]">
        Connecting to a Bridge will also assign license to this camera
      </div>
      <div className="mt-4 mb-6 p-4 bg-[#fafafa] rounded-lg flex gap-3.5 text-[0.8125rem] border border-[#eee]">
        <DeviceIcon
          type="Security Camera"
          className={cx(
            'w-5 h-5',
            onvifDevice.is_online ? 'text-success' : 'text-danger'
          )}
        />
        <div>
          <div className="mb-1">{onvifDevice.name}</div>
          <div className="text-light-secondary">
            {onvifDevice.meta.manufacturer || '---'}
          </div>
        </div>
      </div>
      <Form autoComplete="off" onFinish={handleSubmit}>
        <FormField
          name="device_username"
          label="Username"
          rules={[required]}
          requiredMask
        >
          <Input placeholder="Enter device username" size="large" />
        </FormField>
        <FormField
          name="device_password"
          label="Password"
          rules={[required]}
          requiredMask
        >
          <InputPassword
            autoComplete="new-password"
            placeholder="Enter device password"
            size="large"
          />
        </FormField>
        <div className="mt-8">
          {isLoading && (
            <Button type="button" disabled block variant="primary" size="large">
              <Spinner className="text-white w-auto scale-75" size="small" />
              Connecting...
            </Button>
          )}
          {!isLoading && (
            <Button block variant="primary" size="large">
              Next <ChevronRight />
            </Button>
          )}
        </div>
      </Form>
    </div>
  )
}

export default DeviceAuthForm
