import { FC, ReactNode } from 'react'
import { motion } from 'framer-motion'
import cx from 'classnames'

import { Overlay, Portal } from 'components'

interface Props {
  children?: ReactNode
  width?: number
  className?: string
  placement?: 'left' | 'right'
  mask?: boolean
  maskCloseable?: boolean
  onClose: () => void
}

const OVERLAY_ID = 'overlay'

export const Drawer: FC<Props> = ({
  children,
  width = 250,
  className,
  placement = 'left',
  mask,
  maskCloseable,
  onClose
}) => {
  const handleClose: React.MouseEventHandler = event => {
    if (maskCloseable === false) return
    if ((event.target as HTMLDivElement).id !== OVERLAY_ID) return
    onClose()
  }

  const renderDrawer = () => (
    <motion.div
      style={{ width }}
      className={cx(
        'bg-white fixed top-0 h-full border-light-stroke z-drawer',
        placement === 'left' ? 'border-r' : 'border-l',
        className
      )}
      transition={{ duration: 0.2, type: 'tween' }}
      initial={{ [placement]: -width, opacity: 0 }}
      animate={{ [placement]: 0, opacity: 1 }}
      exit={{ [placement]: -width, opacity: 0 }}
    >
      {children}
    </motion.div>
  )

  return (
    <Portal>
      {mask !== false ? (
        <Overlay
          lockScroll
          id={OVERLAY_ID}
          className="!z-drawer"
          onClickCapture={maskCloseable !== false ? handleClose : undefined}
        >
          {renderDrawer()}
        </Overlay>
      ) : (
        renderDrawer()
      )}
    </Portal>
  )
}
