import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { Spinner } from 'components'

function BlankLayout() {
  return (
    <div className="min-w-screen min-h-screen flex flex-col overflow-x-hidden">
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </div>
  )
}

export default BlankLayout
