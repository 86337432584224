import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Filter({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.546904 0.936401H13.4532C13.7552 0.936401 14 1.18121 14 1.48328C14 3.0062 13.3471 4.46012 12.2088 5.47195L9.81373 7.60071C9.39842 7.96985 9.16022 8.50028 9.16022 9.05602V12.2379C9.16022 12.4208 9.06878 12.5915 8.91667 12.693L5.69005 14.8438C5.32698 15.086 4.83989 14.8247 4.83989 14.3889V9.05602C4.83989 8.50028 4.60169 7.96985 4.18638 7.60071L1.79122 5.47195C0.652867 4.46012 0 3.0062 0 1.48328C0 1.18121 0.244825 0.936401 0.546904 0.936401ZM9.08752 6.78328L11.4826 4.65441C12.2503 3.9721 12.7401 3.03707 12.8713 2.03004H1.12964C1.26081 3.03707 1.75078 3.9721 2.51837 4.65452L4.91343 6.78328C5.56202 7.35985 5.93406 8.18817 5.93406 9.05602V13.367L8.06688 11.9453V9.05591C8.06688 8.18817 8.43893 7.35985 9.08752 6.78328Z"
          fill="url(#paint0_linear_8447_59023)"
        />
        <path
          d="M0.500092 1.4364H13.5008L12.5007 4.4364L10.0006 6.4364L8.5005 8.4364V12.4364L5.50035 14.4364V9.4364L4.5003 7.4364L1.50014 4.4364L0.500092 1.4364Z"
          fill="url(#paint1_linear_8447_59023)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8447_59023"
            x1="0"
            y1="0.936401"
            x2="0"
            y2="14.9364"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8447_59023"
            x1="0.500092"
            y1="1.4364"
            x2="0.500092"
            y2="14.4364"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
