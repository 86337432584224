import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Crown(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M15.3705 4.00164C15.3729 4.04567 15.3697 4.09047 15.3586 4.13497L14.3717 8.08263C14.3219 8.28151 14.1437 8.42135 13.9388 8.42243L8.01729 8.45234C8.01651 8.45234 8.01579 8.45234 8.01501 8.45234H2.09346C1.88746 8.45234 1.70796 8.31201 1.6582 8.11212L0.671282 4.1495C0.659918 4.1038 0.656628 4.05775 0.659499 4.01259C0.277712 3.89231 0 3.53504 0 3.11402C0 2.59454 0.422579 2.17196 0.942056 2.17196C1.46153 2.17196 1.88411 2.59454 1.88411 3.11402C1.88411 3.40656 1.75007 3.66831 1.54019 3.84122L2.77581 5.08617C3.08809 5.40085 3.52144 5.58125 3.96477 5.58125C4.48897 5.58125 4.98877 5.33231 5.30351 4.91523L7.33416 2.2246C7.16357 2.05413 7.05794 1.81859 7.05794 1.55888C7.05794 1.0394 7.48052 0.616821 8 0.616821C8.51948 0.616821 8.94206 1.0394 8.94206 1.55888C8.94206 1.81081 8.84205 2.03948 8.68037 2.20869C8.68091 2.2094 8.68157 2.21 8.68211 2.21078L10.698 4.90883C11.0127 5.32991 11.5143 5.58131 12.04 5.58131C12.4874 5.58131 12.9081 5.40707 13.2244 5.09066L14.4678 3.84727C14.2535 3.67441 14.1159 3.41015 14.1159 3.11402C14.1159 2.59454 14.5385 2.17196 15.0579 2.17196C15.5774 2.17196 16 2.59454 16 3.11402C16 3.5238 15.7365 3.87233 15.3705 4.00164ZM14.2754 9.85794C14.2754 9.6102 14.0746 9.40934 13.8268 9.40934H2.22307C1.97533 9.40934 1.77447 9.6102 1.77447 9.85794V10.9346C1.77447 11.1823 1.97533 11.3832 2.22307 11.3832H13.8268C14.0746 11.3832 14.2754 11.1823 14.2754 10.9346V9.85794Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
