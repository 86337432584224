import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'

import { DeviceIcon } from 'icons/utils'
import { Close, Play } from 'icons/outline'
import { Button, Modal, ModalProps, Table } from 'components'
import ModalLivestream from 'shared/Modals/Players/Livestream'
import { getDeviceStatus, isCamera } from 'utils/device'
import { Device } from 'services/devices'
import useStore from 'store'

interface Props extends ModalProps {
  status: 'online' | 'offline'
}

function ModalViewCameraDevice({ status, ...props }: Props) {
  const [viewLive, setViewLive] = useState<Device>()

  const devices = useStore(state => state.device.devices)
  const filtered = devices.filter(
    d => isCamera(d.type) && getDeviceStatus(d) === status
  )

  const renderDeviceRow = ({ type, name, thumbnail_url }: Device) => (
    <div className="flex items-center gap-1">
      <div className="w-[5.3125rem] h-[3rem] mr-2 shrink-0 inline-flex justify-center items-center bg-[#F5F5F5]">
        {status === 'online' && !!thumbnail_url && (
          <img
            src={thumbnail_url}
            alt="device-thumbnail"
            className="w-full h-full"
          />
        )}
        {(status === 'offline' || !thumbnail_url) && (
          <div
            className="text-xs text-light-secondary"
            children={status === 'offline' ? 'Offline' : 'No Preview'}
          />
        )}
      </div>
      <DeviceIcon
        type={type}
        className={cx(
          'shrink-0 max-w-[1.25rem]',
          status === 'online' ? 'text-success' : 'text-danger'
        )}
      />
      {name}
    </div>
  )

  const renderLocationRow = ({ location }: Device) => {
    if (!location) return '---'
    return (
      <Link
        to="/devices?tab=device-location"
        className="text-primary font-medium"
        children={location.name}
      />
    )
  }

  const renderActionRow = (device: Device) => (
    <Button onClick={() => setViewLive(device)}>
      <Play /> View Live
    </Button>
  )

  let columns = [
    {
      title: 'name',
      render: renderDeviceRow
    },
    {
      title: 'timezone',
      render: (row: Device) => row.timezone
    },
    {
      title: 'location',
      render: renderLocationRow
    }
  ]

  if (status === 'online') {
    columns = [...columns, { title: '', render: renderActionRow }]
  }

  return (
    <Modal {...props} className="!p-0 w-[68.75rem]">
      <div className="bg-white flex items-center justify-between capitalize p-4 border-b border-light-stroke rounded-t-lg">
        <div className="text-base font-medium">{status} Devices List</div>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      <Table rowKey="id" className="p-4" columns={columns} data={filtered} />
      <AnimatePresence initial={false}>
        {!!viewLive && (
          <ModalLivestream
            device={viewLive}
            onClose={() => setViewLive(undefined)}
          />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default ModalViewCameraDevice
