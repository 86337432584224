import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function VideoCamera({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="24"
        height="15"
        viewBox="0 0 24 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M15.0106 0.5H1.61458C0.723199 0.501051 0.00105116 1.2232 0 2.11458V12.7397C0.00105116 13.6311 0.723199 14.3533 1.61458 14.3543H15.0106C15.9018 14.3533 16.6241 13.6311 16.6252 12.7397V2.11458C16.6241 1.2232 15.9018 0.501051 15.0106 0.5Z"
          fill="url(#paint0_linear_7965_18271)"
        />
        <path
          d="M17.7014 9.63861L23.2499 12.6678V2.22119L17.7014 5.25043V9.63861Z"
          fill="url(#paint1_linear_7965_18271)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7965_18271"
            x1="0"
            y1="0.5"
            x2="0"
            y2="14.3543"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_7965_18271"
            x1="17.7014"
            y1="2.22119"
            x2="17.7014"
            y2="12.6678"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
