import { GetState, SetState } from 'zustand'

import { AccountStats } from 'services/accountStats'
import type { Store } from './'

type StatHasUsed = {
  [k in keyof AccountStats as AccountStats[k] extends { used: number }
    ? k
    : never]?: number
}

export interface StatSlice {
  stats: AccountStats | null
  setStats: (stats: AccountStats) => void
  updateUsedCount: (stats: StatHasUsed) => void
}

const slice = (set: SetState<Store>, get: GetState<Store>) => ({
  stats: null,
  setStats: (stats: AccountStats) => {
    return set(({ stat }) => ({ stat: { ...stat, stats } }))
  },
  updateUsedCount: (stats: StatHasUsed) => {
    const s = get().stat.stats
    return set(({ stat }) => ({
      stat: {
        ...stat,
        stats: {
          ...s,
          ...Object.keys(stats).reduce((acc, key) => {
            const k = key as keyof StatHasUsed
            acc[k] = { ...s?.[k], used: (s?.[k].used || 0) + (stats[k] || 0) }
            return acc
          }, {} as any)
        }
      }
    }))
  }
})

export default slice
