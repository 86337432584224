import { Fragment, ReactNode } from 'react'
import cx from 'classnames'

import EmailNotif from 'assets/images/EmailNotif.png'
import EmailNotifChecked from 'assets/images/EmailNotifChecked.png'
import BrokenLink from 'assets/images/BrokenLink.png'

type Props = {
  imgSrc: string
  title: ReactNode
  titleClassName?: string
  description: ReactNode
  descriptionClassName?: string
  action?: ReactNode
  actionClassName?: string
} & JSX.IntrinsicElements['div']

export function EmailVerification({
  imgSrc,
  title,
  titleClassName,
  description,
  descriptionClassName,
  action,
  actionClassName,
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={cx(
        'w-full h-full flex flex-col items-center justify-center text-center',
        props.className
      )}
    >
      <div className="w-[7.5rem] mb-8">
        <img src={imgSrc} alt="email-verification" />
      </div>
      <h4 className={cx('text-base font-medium mb-4', titleClassName)}>
        {title}
      </h4>
      <span className={cx('text-sm text-[0.8125rem]', descriptionClassName)}>
        {description}
      </span>
      {action && (
        <div className={cx('mt-8 w-full', actionClassName)}>{action}</div>
      )}
    </div>
  )
}

function Sent(props: Partial<Props>) {
  return (
    <EmailVerification
      imgSrc={EmailNotif}
      title="Verification link sent"
      description={
        <Fragment>
          Check your email inbox and follow the verification link to confirm ownership.
        </Fragment>
      }
      {...props}
    />
  )
}

function Success(props: Partial<Props>) {
  return (
    <EmailVerification
      imgSrc={EmailNotifChecked}
      title="Your account is verified"
      description="Thank you for using Broadflow."
      {...props}
    />
  )
}

function Resend(props: Partial<Props>) {
  return (
    <EmailVerification
      imgSrc={EmailNotif}
      title="Please verify your email"
      description=""
      {...props}
    />
  )
}

function Expired(props: Partial<Props>) {
  return (
    <EmailVerification
      imgSrc={BrokenLink}
      title="This link has expired"
      description="The link you are trying to access has expired."
      {...props}
    />
  )
}

export const EmailVerificationLink = {
  Sent,
  Success,
  Resend,
  Expired
}
