import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Share({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="13"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M14 6.37985L8.18187 0V3.80634H6.94489C3.10928 3.80634 0 6.91562 0 10.7512V12.7684L0.549438 12.1663C2.41747 10.1195 5.06062 8.95337 7.83174 8.95337H8.18187V12.7597L14 6.37985Z"
          fill="url(#paint0_linear_8407_3273)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8407_3273"
            x1="7"
            y1="0"
            x2="7"
            y2="12.7684"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
