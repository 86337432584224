import { Link, useLocation } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import cx from 'classnames'

import BroaflowFullversion from 'assets/images/BroadflowFullversion.png'
import { Close } from 'icons/outline'
import { expandedMenu, Navigation } from 'configs/navigation'
import { Drawer } from 'components'
import useStore from 'store'

import styles from './layout.module.css'
import { MenuItem, CollapseMenuItem } from './MenuItem'

function ExpandedMenu({ onClose }: { onClose: () => void }) {
  const { pathname, search } = useLocation()
  const betaFeatures = useStore(state => state.auth.accessBetaFeatures())

  const renderCollapseMenuItem = (menuItem: Navigation) => {
    const { key, children, ...rest } = menuItem
    const active = pathname === '/' + key
    return (
      <CollapseMenuItem key={key} active={active} {...rest}>
        {children!.map(c => (
          <Link
            key={c.key}
            to={c.navLink!}
            className={cx(
              'text-light-primary',
              'flex items-center w-full h-12 hover:text-primary base-transition text-sm',
              pathname + search === c.navLink && 'text-primary'
            )}
          >
            {c.label}
          </Link>
        ))}
      </CollapseMenuItem>
    )
  }

  const renderMenuItem = (menuItem: Navigation) => {
    const { key, label, Icon, navLink } = menuItem
    let active = false
    if (pathname === navLink) active = true
    if (pathname.startsWith('/settings') && navLink!.startsWith('/settings'))
      active = true

    if (!!menuItem.betaFeature && !betaFeatures) return null
    return (
      <MenuItem key={key} className={cx(active && styles.active)}>
        <Link
          to={navLink!}
          className="pl-4 flex w-full h-full items-center gap-2.5"
        >
          <Icon style={{ width: 24, height: 20 }} />
          <span className={cx(active ? 'text-primary' : 'text-light-primary')}>
            {label}
          </span>
        </Link>
      </MenuItem>
    )
  }

  return (
    <Drawer width={245} onClose={onClose}>
      <MenuItem
        onClick={onClose}
        className="justify-start pl-4 hover:!bg-transparent"
        children={<div className="text-light-primary" children={<Close />} />}
      />
      <MenuItem className="mb-1.5 hover:!bg-transparent">
        <Link to="/" className="w-full pl-4">
          <img
            alt="logo"
            src={BroaflowFullversion}
            className={styles.logo_expanded}
          />
        </Link>
      </MenuItem>
      <PerfectScrollbar
        options={{ wheelPropagation: false }}
        className={cx(styles.scrollbar_container, 'flex flex-col')}
      >
        <div className="mb-8">
          {expandedMenu.map(item =>
            item.children ? renderCollapseMenuItem(item) : renderMenuItem(item)
          )}
        </div>
      </PerfectScrollbar>
    </Drawer>
  )
}

export default ExpandedMenu
