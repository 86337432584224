import { ChangeEventHandler, Fragment, useReducer } from 'react'
import cx from 'classnames'

import { ArrowRight } from 'icons/outline'
import { Checkbox, TimePicker } from 'components'
import { SelectButtonItem } from 'components/SelectButton/SelectButtonItem'
import { days, shortDay } from 'utils/dateTime'

type Value = {
  is247: boolean
  startTime: string
  endTime: string
  days: string[]
}

interface Props extends Partial<Value> {
  className?: string
  onChange?: (value: Value) => void
}

const initValue: Value = {
  is247: false,
  startTime: '',
  endTime: '',
  days: []
}

function ScheduleDateTimeInput({ onChange, className, ...propValue }: Props) {
  const [value, setValue] = useReducer(
    (s: Value, a: Partial<Value>) => ({ ...s, ...a }),
    { ...initValue, ...propValue }
  )

  const handle247Change: ChangeEventHandler<HTMLInputElement> = e => {
    const checked = e.target.checked
    setValue({ is247: checked })
    onChange?.({ ...value, is247: checked })
  }

  const handleDayChange = (day: string) => {
    const newDays = value.days.includes(day)
      ? value.days.filter(d => d !== day)
      : [...value.days, day]
    setValue({ days: newDays })
    onChange?.({ ...value, days: newDays })
  }

  const handleTimeChange = (time: string, type: 'startTime' | 'endTime') => {
    setValue({ [type]: time })
    onChange?.({ ...value, [type]: time })
  }

  return (
    <div className={cx(className)}>
      <Checkbox size="large" checked={value.is247} onChange={handle247Change}>
        <span className="inline-block mt-px">24/7 (All the time)</span>
      </Checkbox>
      {!value.is247 && (
        <Fragment>
          <div className="mt-4 flex items-center gap-[5px]">
            {days.map(day => (
              <div key={day} onClick={() => handleDayChange(day)}>
                <SelectButtonItem
                  value={day}
                  active={value.days.includes(day)}
                  className="w-[3.75rem] h-[2.5rem]"
                >
                  {shortDay(day)}
                </SelectButtonItem>
              </div>
            ))}
          </div>
          <div className="mt-3 flex items-center gap-4">
            <TimePicker
              size="large"
              placeholder="From"
              value={value.startTime}
              onChange={v => handleTimeChange(v, 'startTime')}
            />
            <ArrowRight className="shrink-0" />
            <TimePicker
              size="large"
              placeholder="To"
              value={value.endTime}
              onChange={v => handleTimeChange(v, 'endTime')}
            />
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default ScheduleDateTimeInput
