import { FC, ReactNode, useState } from 'react'
import { motion } from 'framer-motion'
import cx from 'classnames'

interface Props {
  children?: ReactNode
  className?: string
  checked?: boolean
  onChange?: (checked: boolean, event: Event) => void
}

const Switch: FC<Props> = ({
  checked: propChecked,
  children,
  className,
  onChange,
  ...props
}) => {
  const [checked, setChecked] = useState(propChecked || false)

  const handleCheck = (e: any) => {
    setChecked(prev => {
      const checked = !prev
      onChange?.(checked, e)
      return checked
    })
  }

  return (
    <div
      {...props}
      className={cx(
        !children && 'inline-block',
        !!children && 'inline-flex items-center gap-3 text-sm',
        className
      )}
    >
      <button
        role="switch"
        aria-checked={checked}
        onClick={handleCheck}
        className={cx(
          'base-transition relative m-0 p-0 h-[0.5625rem] min-w-[1.6rem] rounded-lg',
          !checked && 'bg-[#D8D8D8] shadow-[inset_0_0_3px_rgba(0,0,0,0.5)]',
          checked && 'bg-[#7C80DE] shadow-[inset_0_0_2px_#4D51C7]'
        )}
      >
        <motion.div
          animate={{ left: !checked ? 0 : 'calc(100% - 9px)' }}
          transition={{ type: 'tween', duration: 0.2 }}
          className={cx(
            'absolute top-0 w-[0.5625rem] h-[0.5625rem] rounded-full scale-[2]',
            !checked && 'bg-[#ECECEC] shadow-[0_0_2px_rgba(0,0,0,0.5)]',
            checked && 'bg-primary-gradient shadow-[0_0_1px_#070829]'
          )}
        />
      </button>
      {children}
    </div>
  )
}

export { Switch }
