import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function VideoCameraFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="20"
        height="12"
        viewBox="0 0 20 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M12.5088 0.313721H1.34549C0.602666 0.314597 0.000875966 0.916387 0 1.65921V10.5135C0.000875966 11.2563 0.602666 11.8581 1.34549 11.859H12.5088C13.2515 11.8581 13.8534 11.2563 13.8543 10.5135V1.65921C13.8534 0.916387 13.2515 0.314597 12.5088 0.313721Z"
          fill="currentColor"
        />
        <path
          d="M14.7512 7.92923L19.3749 10.4536V1.74805L14.7512 4.27241V7.92923Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
