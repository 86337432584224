import { ReactNode, useEffect } from 'react'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { format } from 'date-fns'
import shallow from 'zustand/shallow'

import { dateTimeFormat } from 'utils/dateTime'
import useStore from 'store'

const ChatWidget = () => {
  const { boot, hardShutdown } = useIntercom()
  const { user, sub } = useStore(
    state => ({
      user: state.auth.currentUser,
      sub: state.sub.subscription
    }),
    shallow
  )

  useEffect(() => {
    if (!user) return

    boot({
      hideDefaultLauncher: true,
      name: user.name,
      email: user.email,
      userId: user.id.toString(),
      actionColor: '#5156D3',
      backgroundColor: '#5156D3',
      customAttributes: {
        'Plan Type': sub?.subscription?.plan?.label || 'SUPER ADMIN',
        'Camera Count': sub?.subscription?.camera_licenses || 'SUPER ADMIN',
        'Registration Date': format(
          new Date(user.created_at),
          `${dateTimeFormat['yyyy-MM-dd']}`
        )
      }
    })

    return () => hardShutdown()
  }, [user?.id])

  return null
}

const ChatWidgetProvider = ({ children }: { children?: ReactNode }) => {
  return (
    <IntercomProvider
      appId={process.env.REACT_APP_INTERCOM_APP_ID!}
      initializeDelay={3000}
    >
      {children}
      <ChatWidget />
    </IntercomProvider>
  )
}

export default ChatWidgetProvider
