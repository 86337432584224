import { format } from 'date-fns'

import { dateTimeFormat } from 'utils/dateTime'
import client, { ResponseMeta } from './client'

export interface ActivityLog {
  account_id: number
  causer_id: number | null
  causer_ip: string | null
  causer_name: string | null
  causer_type: string | null
  created_at: string
  description: string
  id: number
  log_name: string | null
  properties: { ip: string } | null
  subject_id: number
  subject_type: string
  updated_at: string
}

export type ActivityLogResponse = {
  data: ActivityLog[]
  meta: ResponseMeta
}

export const getAllLogs = ({
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = 10,
  currentPage = 1,
  search,
  userId,
  accountId,
  dateRange
}: {
  sortBy?: string
  sortDirection?: string
  pageSize?: number
  currentPage?: number
  search?: string
  userId?: number
  accountId?: number
  dateRange?: [Date | null, Date | null]
}) => {
  let filters = ''

  if (search) {
    filters += `&filters[search]=${search}`
  }
  if (userId) {
    filters += `&filters[user_ids]=${userId}`
  }
  if (accountId) {
    filters += `&filters[account_id]=${accountId}`
  }
  if (dateRange && !!dateRange[0] && !!dateRange[1]) {
    const from = format(dateRange[0], dateTimeFormat['yyyy-MM-dd'])
    const to = format(dateRange[1], dateTimeFormat['yyyy-MM-dd'])
    filters += `&filters[date_range]=${from},${to}`
  }

  return client.get<ActivityLogResponse>(
    `/api/activityLogs?order_by=${sortBy}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}${filters}`
  )
}

export const getDeviceLogs = (
  id: number,
  {
    sortBy = 'id',
    sortDirection = 'desc',
    pageSize = 10,
    currentPage = 1,
    search,
    dateRange
  }: {
    sortBy?: string
    sortDirection?: string
    pageSize?: number
    currentPage?: number
    search?: string
    dateRange?: [Date | null, Date | null]
  }
) => {
  let filters = ''

  if (search) {
    filters += `&filters[search]=${search}`
  }
  if (dateRange && !!dateRange[0] && !!dateRange[1]) {
    const from = format(dateRange[0], dateTimeFormat['yyyy-MM-dd'])
    const to = format(dateRange[1], dateTimeFormat['yyyy-MM-dd'])
    filters += `&filters[date_range]=${from},${to}`
  }

  return client.get<ActivityLogResponse>(
    `/api/activityLogs/devices/${id}?order_by=${sortBy}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}${filters}`
  )
}
