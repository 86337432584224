import shallow from 'zustand/shallow'
import cx from 'classnames'

import { LocationPin } from 'icons/outline'
import { Dot, Tooltip } from 'components'
import MenuItem from 'shared/MenuItem'
import { Location } from 'services/locations'
import useStore from 'store'

import { useLocationCtx } from '../LocationContext'

interface Props {
  location: Location
}

function LocationListItem({ location }: Props) {
  const devices = useStore(state => state.device.devices, shallow)
  const { selectedLocation, dispatch } = useLocationCtx()

  const deviceInLocation = devices.filter(d => d.location_id === location.id)
  const summary = deviceInLocation.reduce((acc, { type }) => {
    acc[type] = (acc[type] || 0) + 1
    return acc
  }, {} as { [key: string]: number })

  const renderDeviceSummary = () => (
    <div className="p-1.5 w-[11.875rem]">
      <div className="text-sm font-medium mb-3">Device Summary</div>
      {!deviceInLocation.length && (
        <div className="text-[0.8125rem] text-[#B6B6B6]">
          This location has no device
        </div>
      )}
      {Object.keys(summary).map((key, idx, self) => (
        <div
          key={key}
          className={cx(
            'flex items-center justify-between text-[0.8125rem] text-[#B6B6B6]',
            idx + 1 < self.length && 'mb-4'
          )}
        >
          <div className="font-medium">{key}</div>
          <div>{summary[key]}</div>
        </div>
      ))}
    </div>
  )

  return (
    <MenuItem
      onClick={() =>
        dispatch({ selectedLocation: location, showLocationDetail: true })
      }
      className={cx(
        'h-10 !rounded-lg',
        selectedLocation?.id === location.id && 'bg-light-hover'
      )}
    >
      <LocationPin className="text-light-secondary shrink-0" />
      <div>{location.name}</div>
      <Tooltip
        className="ml-auto"
        placement="left"
        content={renderDeviceSummary()}
      >
        <Dot className="border border-light-stroke px-[5px] py-[3px] text-xs min-w-[22px]">
          {deviceInLocation.length}
        </Dot>
      </Tooltip>
    </MenuItem>
  )
}

export default LocationListItem
