import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function QuestionCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <circle cx="10" cy="10" r="9.25" fill="transparent" strokeWidth="1.5" />
        <path d="M9.65955 13.8125C9.02418 13.8125 8.50977 14.342 8.50977 14.9774C8.50977 15.5977 9.00903 16.1424 9.65955 16.1424C10.3101 16.1424 10.8245 15.5977 10.8245 14.9774C10.8245 14.342 10.295 13.8125 9.65955 13.8125Z" />
        <path d="M9.85636 4.9375C7.81395 4.9375 6.87598 6.14779 6.87598 6.96476C6.87598 7.55482 7.3752 7.82713 7.7837 7.82713C8.60063 7.82713 8.26782 6.6622 9.81096 6.6622C10.5674 6.6622 11.1726 6.99505 11.1726 7.69097C11.1726 8.50799 10.3254 8.97695 9.82611 9.40056C9.38739 9.77878 8.81248 10.3991 8.81248 11.7002C8.81248 12.4868 9.02424 12.7138 9.64455 12.7138C10.3859 12.7138 10.5371 12.381 10.5371 12.0935C10.5371 11.3068 10.5523 10.853 11.3844 10.2024C11.7929 9.88472 13.0788 8.85595 13.0788 7.43381C13.0788 6.01168 11.7929 4.9375 9.85636 4.9375Z" />
      </svg>
    </SVGUniqueID>
  )
}
