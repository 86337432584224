import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import LocationImg from 'assets/images/Location.png'

import {
  DropdownDown,
  LocationPin,
  Plus,
  Search,
  FloorPlan
} from 'icons/outline'
import { Button, Dot, Input, Popover } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalCreateFloorPlan from 'shared/Modals/FloorPlans/CreateFloorPlan'
import ModalCreateUpdateLocation from 'shared/Modals/Location/CreateUpdateLocation'
import { getDeviceStatus } from 'utils/device'
import { FloorPlan as IFloorPlan } from 'services/floorPlans'
import useStore, { Store } from 'store'

import LocationList from './LocationList'
import { useLocationCtx } from '../LocationContext'

const mapState = ({ device, location }: Store) => ({
  devices: device.devices,
  locations: location.locations
})

function SideList() {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [visible, setVisible] = useState('')

  const store = useStore(mapState, shallow)
  const { selectedLocation, dispatch, ...ctx } = useLocationCtx()
  const floorPlans = ctx.floorPlans.filter(
    fp => +fp.location_id === selectedLocation?.id
  )

  if (!selectedLocation) {
    return null
  }

  const handleViewLocation = () => {
    dispatch({ showLocationDetail: true })
    navigate('/devices?tab=device-location')
  }

  const handleAddFloorPlan = (fp: IFloorPlan) => {
    dispatch({ floorPlans: [{ ...fp, devices: [] }, ...ctx.floorPlans] })
  }

  const renderFloorPlans = () => {
    const filtered = floorPlans.filter(fp =>
      fp.name.toLowerCase().includes(search.toLowerCase())
    )

    if (!filtered.length) {
      return (
        <div className="text-[0.8125rem] text-light-secondary">
          No result match your search. Please try other keyword
        </div>
      )
    }

    return filtered.map(fp => {
      const devices = store.devices.filter(d =>
        fp.devices.find(dFp => dFp.id === d.id)
      )

      const onlineCount = devices.filter(
        d => getDeviceStatus(d) === 'online'
      ).length
      const offlineCount = devices.filter(
        d => getDeviceStatus(d) === 'offline'
      ).length

      return (
        <MenuItem
          key={fp.id}
          onClick={() => dispatch({ activeFloorPlanId: fp.id })}
          className={cx(
            '!rounded-lg h-10 border-b border-dashed border-light-stroke last:border-none',
            fp.id === ctx.activeFloorPlanId && 'bg-light-hover'
          )}
        >
          <FloorPlan className="w-3.5 h-3.5 text-light-secondary" />
          <div className="text-[0.8125rem]">{fp.name}</div>
          <div className="ml-auto inline-flex gap-1">
            <div className="py-[3px] px-1.5 border rounded-full bg-white inline-flex items-center gap-2">
              <Dot className="w-2 h-2 bg-success-gradient" />
              <div className="text-xs">{onlineCount}</div>
            </div>
            <div className="py-[3px] px-1.5 border rounded-full bg-white inline-flex items-center gap-2">
              <Dot className="w-2 h-2 bg-danger-gradient" />
              <div className="text-xs">{offlineCount}</div>
            </div>
          </div>
        </MenuItem>
      )
    })
  }

  return (
    <Fragment>
      {!store.locations.length && (
        <div className="w-[19.5625rem] shrink-0  border-r border-light-stroke p-4">
          <div className="flex flex-col justify-center items-center text-center p-4 mt-12">
            <img className="mb-4" src={LocationImg} alt="location" />
            <div className="text-base font-medium mb-2">
              Please create a location before adding a floor plan
            </div>
            <div className="text-[0.8125rem] text-light-secondary">
              There are no location yet
            </div>
            <Button
              size="large"
              className="mt-6"
              variant="primary"
              onClick={() => setVisible('create-location')}
            >
              <Plus /> Create Location
            </Button>
          </div>
        </div>
      )}
      {!!store.locations.length && (
        <div className="w-[19.5625rem] shrink-0  border-r border-light-stroke p-4">
          <Popover
            placement="bottom-start"
            content={onClose => <LocationList onClose={onClose} />}
            className="font-medium flex items-center gap-1.5 cursor-pointer"
          >
            <div className="text-base">{selectedLocation.name}</div>
            <DropdownDown className="w-3 h-3 shrink-0 pt-[2px]" />
          </Popover>
          <div className="mt-3 flex items-center gap-2">
            <Button block onClick={handleViewLocation}>
              <LocationPin className="shrink-0" />
              <div className="!text-[0.8125rem]">View Location</div>
            </Button>
            <Button block onClick={() => setVisible('create-floor')}>
              <Plus className="shrink-0" />
              <div className="!text-[0.8125rem]">New Floor Plan</div>
            </Button>
          </div>
          {!floorPlans.length && (
            <div className="flex flex-col justify-center items-center text-center p-4 mt-12">
              <img className="mb-4" src={LocationImg} alt="location" />
              <div className="text-base font-medium mb-2">
                Add a new floor plan image to get started
              </div>
              <div className="text-[0.8125rem] text-light-secondary">
                Use floor plans to visualize your device map and site layout
              </div>
            </div>
          )}
          {!!floorPlans.length && (
            <Fragment>
              <div className="mt-4 mb-3">
                <Input
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Search floor plan"
                  suffix={<Search />}
                />
              </div>
              <PerfectScrollbar
                className="h-[calc(100%_-_8rem)]"
                options={{ wheelPropagation: false }}
              >
                {renderFloorPlans()}
              </PerfectScrollbar>
            </Fragment>
          )}
        </div>
      )}
      <AnimatePresence initial={false}>
        {visible === 'create-location' && (
          <ModalCreateUpdateLocation onClose={() => setVisible('')} />
        )}
        {visible === 'create-floor' && (
          <ModalCreateFloorPlan
            locationId={selectedLocation.id}
            onSuccess={handleAddFloorPlan}
            onClose={() => setVisible('')}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default SideList
