import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Pause(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <rect
          width="4"
          height="20"
          rx="1"
          transform="matrix(-1 0 0 1 19.7692 1)"
          fill="currentColor"
        />
        <rect
          width="4"
          height="20"
          rx="1"
          transform="matrix(-1 0 0 1 11.7692 1)"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
