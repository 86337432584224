import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Notification(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M16.9375 14.2812C16.9375 15.7483 15.7483 16.9375 14.2812 16.9375H4.71875C3.25174 16.9375 2.0625 15.7483 2.0625 14.2812V4.71875C2.0625 3.25174 3.25174 2.0625 4.71875 2.0625H10.0312V1H4.71875C2.66494 1 1 2.66494 1 4.71875V14.2812C1 16.3351 2.66494 18 4.71875 18H14.2812C16.3351 18 18 16.3351 18 14.2812V8.96875H16.9375V14.2812Z"
          strokeWidth="1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 4.71875C18 6.77256 16.3351 8.4375 14.2812 8.4375C12.2274 8.4375 10.5625 6.77256 10.5625 4.71875C10.5625 2.66494 12.2274 1 14.2812 1C16.3351 1 18 2.66494 18 4.71875ZM16.9375 4.71875C16.9375 3.25174 15.7483 2.0625 14.2812 2.0625C12.8142 2.0625 11.625 3.25174 11.625 4.71875C11.625 6.18576 12.8142 7.375 14.2812 7.375C15.7483 7.375 16.9375 6.18576 16.9375 4.71875Z"
        />
        <path d="M14.2812 8.9375C16.6112 8.9375 18.5 7.04871 18.5 4.71875H17.5C17.5 6.49642 16.0589 7.9375 14.2812 7.9375V8.9375ZM10.0625 4.71875C10.0625 7.04871 11.9513 8.9375 14.2812 8.9375V7.9375C12.5036 7.9375 11.0625 6.49642 11.0625 4.71875H10.0625ZM14.2812 0.5C11.9513 0.5 10.0625 2.3888 10.0625 4.71875H11.0625C11.0625 2.94108 12.5036 1.5 14.2812 1.5V0.5ZM18.5 4.71875C18.5 2.3888 16.6112 0.5 14.2812 0.5V1.5C16.0589 1.5 17.5 2.94108 17.5 4.71875H18.5ZM14.2812 2.5625C15.4721 2.5625 16.4375 3.52788 16.4375 4.71875H17.4375C17.4375 2.97559 16.0244 1.5625 14.2812 1.5625V2.5625ZM12.125 4.71875C12.125 3.52788 13.0904 2.5625 14.2812 2.5625V1.5625C12.5381 1.5625 11.125 2.97559 11.125 4.71875H12.125ZM14.2812 6.875C13.0904 6.875 12.125 5.90962 12.125 4.71875H11.125C11.125 6.46191 12.5381 7.875 14.2812 7.875V6.875ZM16.4375 4.71875C16.4375 5.90962 15.4721 6.875 14.2812 6.875V7.875C16.0244 7.875 17.4375 6.46191 17.4375 4.71875H16.4375Z" />
      </svg>
    </SVGUniqueID>
  )
}
