import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import shallow from 'zustand/shallow'

import { Bridge, Pencil } from 'icons/outline'
import { Button } from 'components'
import ModalEditBridge from 'shared/Modals/Bridge/EditBridge'
import ModalOnvifDevices from 'shared/Modals/Bridge/OnvifDevices'
import { getBridgeName } from 'utils/device'
import useStore from 'store'

import BridgeDetail from './BridgeDetail'
import ConnectedCamera from './ConnectedCamera'

interface Props {
  activeBridgeId: number
}

function MainContent({ activeBridgeId }: Props) {
  const [visible, setVisible] = useState('')

  const bridges = useStore(state => state.bridge.bridges, shallow)
  const bridge = bridges.find(b => b.id === activeBridgeId)
  if (!bridge) return null

  const canConnect = bridge.channel > bridge.devices_count && bridge.is_online

  return (
    <div className="relative w-full h-full overflow-hidden">
      <div className="px-3 py-4 border-light-stroke flex items-center justify-between h-16">
        <div className="flex items-center gap-3">
          <div className="inline-flex items-center gap-2">
            <Bridge
              className={cx(
                'w-4 h-4 shink-0',
                bridge.is_online ? 'text-success' : 'text-danger'
              )}
            />
            <div className="font-medium text-xl">{getBridgeName(bridge)}</div>
          </div>
          <Button variant="ternary" onClick={() => setVisible('edit')}>
            <Pencil /> Edit
          </Button>
        </div>
        <Button
          variant="primary"
          disabled={!canConnect}
          onClick={canConnect ? () => setVisible('connect') : undefined}
        >
          Connect to camera
        </Button>
      </div>
      <PerfectScrollbar
        options={{ wheelPropagation: false }}
        className="py-4 px-3 h-[calc(100%_-_4rem)]"
      >
        <BridgeDetail bridge={bridge} />
        <ConnectedCamera bridge={bridge} />
      </PerfectScrollbar>
      <AnimatePresence initial={false}>
        {visible === 'edit' && (
          <ModalEditBridge bridge={bridge} onClose={() => setVisible('')} />
        )}
        {visible === 'connect' && (
          <ModalOnvifDevices bridge={bridge} onClose={() => setVisible('')} />
        )}
      </AnimatePresence>
    </div>
  )
}

export default MainContent
