import { Search } from 'icons/outline'
import { AsyncSelect, Input, Pagination } from 'components'
import { getUsers } from 'services/users'
import { DoubleRangePicker } from 'components/DatePicker/DoubleRangePicker'

interface UserOption {
  label: string
  value: number
}

interface Props {
  loading: boolean
  user?: UserOption
  onUserChange: (user: UserOption) => void
  search: string
  onSearch: (search: string) => void
  dateRange: [Date | null, Date | null]
  onDateChange: (range: [Date | null, Date | null]) => void
  currentPage: number
  totalPage: number
  onPageChange: (page: number) => void
}

function TableToolbar({
  loading,
  user,
  onUserChange,
  search,
  onSearch,
  dateRange,
  onDateChange,
  currentPage,
  totalPage,
  onPageChange
}: Props) {
  const handleGetUsers = async (search: string) => {
    const { data } = await getUsers({ search })
    return data.data.map(user => ({ label: user.name, value: user.id }))
  }

  const handleSearch: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      value !== search && onSearch(value)
    }
  }

  const handleSelectUser = (value: UserOption) => {
    !loading && onUserChange(value)
  }

  const handleDateChange = ([from, to]: (Date | null)[]) => {
    if ((!from && !to) || (!!from && !!to)) {
      onDateChange([from, to])
    }
  }

  return (
    <div className="mb-4 flex flex-wrap justify-between items-center gap-4">
      <Input
        suffix={<Search />}
        className="w-[22.5rem]"
        onKeyDown={loading ? undefined : handleSearch}
        placeholder="Search activity by name, user, device"
      />
      <div className="inline-flex gap-2">
        <Pagination.ButtonGroup
          loading={loading}
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={loading ? undefined : onPageChange}
        />
        <AsyncSelect
          isClearable
          value={user}
          cacheOptions
          defaultOptions
          loadOptions={handleGetUsers}
          onChange={v => handleSelectUser(v as UserOption)}
          className="w-[14.375rem]"
          placeholder="All Users"
        />
        <DoubleRangePicker
          allowClear
          showPresets
          className="w-80"
          value={dateRange}
          placeholder={['Start Date', 'End Date']}
          onChange={date => handleDateChange(date)}
        />
      </div>
    </div>
  )
}

export default TableToolbar
