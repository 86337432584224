import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import Camera from 'assets/images/Camera.png'
import NoResult from 'assets/images/NoResult.png'

import { Filter, LocationPin, Plus, Refresh, Search } from 'icons/outline'
import { Badge, Button, Collapse, Input, Popover } from 'components'
import DeviceCard from 'shared/DeviceCard'
import ModalCreateDevice from 'shared/Modals/Device/CreateDevice'
import { getDeviceStatus, groupDevicesByLocation, isCamera } from 'utils/device'
import useStore, { Store } from 'store'

import FilterForm, { Filters } from './FilterForm'

const mapState = (state: Store) => {
  return state.device.devices.filter(d => isCamera(d.type))
}

function CameraList() {
  const [visible, setVisible] = useState(false)
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState<Filters>({})
  const devices = useStore(mapState, shallow)

  const handleReset = () => {
    setSearch('')
    setFilters({})
  }

  const renderEmptySearchMessage = () => (
    <div className="w-[12.5rem] h-[90%] flex flex-col justify-center items-center mx-auto">
      <img src={NoResult} alt="no-result" className="w-[5.625rem]" />
      <div className="text-sm font-medium mt-6 mb-2">
        No result matches your filter
      </div>
      <div className="text-[0.8125rem] text-light-secondary">
        You can try other filters or
      </div>
      <Button
        block
        size="large"
        variant="primary"
        className="mt-6"
        onClick={handleReset}
      >
        <Refresh /> Reset All Filters
      </Button>
    </div>
  )

  const filterDevices = () => {
    let filtered = devices.filter(d =>
      d.name.toLowerCase().includes(search.toLowerCase())
    )

    if (filters.recording) {
      filtered = filtered.filter(d => {
        const isRecording = !!d.deviceLiveStream?.is_recording
        return filters.recording === 'enable' ? isRecording : !isRecording
      })
    }

    if (filters.cameraLicense) {
      filtered = filtered.filter(d => {
        const isLicensed = !!d.is_licensed
        return filters.cameraLicense === 'licensed' ? isLicensed : !isLicensed
      })
    }

    if (filters.deviceState) {
      filtered = filtered.filter(d => {
        const status = getDeviceStatus(d)
        return filters.deviceState === 'error'
          ? !!d.stream_error
          : filters.deviceState === 'online'
          ? status === 'online'
          : status === 'offline'
      })
    }
    if (!!filters.locations && filters.locations.length) {
      const locations = filters.locations
      filtered = filtered.filter(d => {
        return !!locations.find(l => +l.id === +(d.location_id || 0))
      })
    }

    return filtered
  }

  if (!devices.length) {
    return (
      <div className="flex-1 bg-light-bg">
        <div className="w-[17.5rem] h-[90%] flex flex-col justify-center items-center mx-auto">
          <img src={Camera} alt="camera" className="w-[6.25rem]" />
          <div className="mt-[2.125rem] text-base font-medium mb-2">
            Add cameras to get started
          </div>
          <div className="text-[0.8125rem] text-light-secondary">
            Manage your cameras in one place
          </div>
          <Button
            block
            size="large"
            variant="primary"
            className="mt-6"
            onClick={() => setVisible(true)}
          >
            <Plus /> Create Device
          </Button>
        </div>
        <AnimatePresence initial={false}>
          {visible && <ModalCreateDevice onClose={() => setVisible(false)} />}
        </AnimatePresence>
      </div>
    )
  }

  const filteredDevices = filterDevices()
  const groupedDevices = groupDevicesByLocation(filteredDevices)

  const renderCollapsePanel = (key: string) => (
    <span className="flex items-center gap-2">
      <LocationPin className="text-light-secondary" />
      {groupedDevices[key].locationName} ({groupedDevices[key].devices.length})
    </span>
  )

  return (
    <div className="flex-1">
      <div className="p-3 border-b border-light-stroke flex items-center justify-between gap-4">
        <Input
          size="large"
          suffix={<Search />}
          className="w-[31.25rem]"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Search device by name"
        />
        <Popover
          placement="bottom-end"
          content={onClose => (
            <FilterForm
              deviceType="Security Camera"
              filters={filters}
              onChange={setFilters}
              onClose={onClose}
            />
          )}
        >
          <Badge
            type="primary"
            count={Object.values(filters).length}
            offset={{ x: '-20%', y: '20%' }}
          >
            <Button size="large" className="w-[6.25rem]">
              <Filter /> Filter
            </Button>
          </Badge>
        </Popover>
      </div>
      {!filteredDevices.length ? (
        renderEmptySearchMessage()
      ) : (
        <PerfectScrollbar
          options={{ wheelPropagation: false }}
          className="py-4 px-3 bg-light-bg h-[calc(100%_-_4rem)]"
        >
          {Object.keys(groupedDevices).map((key, idx, self) => (
            <Collapse
              key={key}
              defaultActive
              className={cx('mb-10', idx + 1 === self.length && '!mb-0')}
              panelClassName="font-medium px-0 pt-0"
              contentClassName="px-0"
              panel={renderCollapsePanel(key)}
            >
              <div className="grid grid-cols-[repeat(auto-fill,minmax(22rem,1fr))] gap-4">
                {groupedDevices[key].devices.map(device => (
                  <DeviceCard key={device.id} device={device} />
                ))}
              </div>
            </Collapse>
          ))}
        </PerfectScrollbar>
      )}
    </div>
  )
}

export default CameraList
