import { ONVIF_DEVICE_STATUS } from 'utils/device'

import client from './client'
import { Location } from './locations'
import { Device } from './devices'

export interface OnvifDeviceMetaEncoder {
  bitrate: number | null
  bitrate_type: string | null
  encoding: string | null
  frame_rate: number | null
  profile_name: string | null
  profile_token: string | null
  resolution: { width: number; height: number }
}

export interface OnvifDeviceMeta {
  firmware_version: string | null
  hardware: string | null
  hardware_id: string | null
  local_date_time: string | null
  manufacturer: string | null
  model: string | null
  profile: string | null
  rtsp_link: string | null
  rules: { name: string; sensitivity: string }[]
  serial_number: string | null
  timezone: string | null
  type: string | null
  video_encoders: OnvifDeviceMetaEncoder[]
  xaddr: string | null
}

export interface OnvifDevice {
  connected_device?: { is_licensed: boolean; rtmp_url: string }
  device: ConnectedDevice | null
  device_password: string | null
  device_username: string | null
  errors: null
  id: number
  ip_address: string | null
  is_online: boolean
  mac_address: string | null
  meta: OnvifDeviceMeta
  name: string
  settings: [] | null
  status: ONVIF_DEVICE_STATUS | null
}

export interface ConnectedDevice
  extends Omit<
    Device,
    | 'deviceLiveStream'
    | 'location'
    | 'stream_url'
    | 'thumbnail_url'
    | 'username'
    | 'password'
    | 'temperatureSensor'
    | '__loading'
  > {
  deleted_at: string | null
  laravel_through_key: number
  onvif_device_id: number
  rtmp_password: string | null
  rtmp_username: string | null
  thumbnail: string | null
  updated_at: string
  use_cdn: number
}

export interface Bridge {
  channel: number
  created_at: string
  devices: ConnectedDevice[]
  devices_count: number
  errors: null
  id: number
  ip_address: string | null
  is_online: boolean
  is_provisioned: boolean
  last_ping_at: string | null
  location: Location | null
  location_id: number | null
  mac_address: string | null
  model_number: string | null
  name: string | null
  onvif_devices: OnvifDevice[]
  product_id: number
  serial_number: string | null
  status: string | null
}

export function getAllBridges() {
  return client.get<{ data: Bridge[] }>(
    '/api/bridges?include=location,devices&limit=300'
  )
}

export function getBridgeDetail(id: number, query = '') {
  return client.get<{ data: Bridge }>(`/api/bridges/${id}?${query}`)
}

export function updateBridge({
  id,
  ...payload
}: {
  id: number
  name: string
  attach_id?: string
  location_id?: number
}) {
  return client.put<{ data: Bridge }>(`/api/bridges/${id}`, payload)
}

export function getOnvifDeviceDetail(id: number) {
  return client.get(`/api/onvifDevices/${id}`)
}

export function onvifDeviceAuth({
  id,
  ...payload
}: {
  id: number
  device_username: string
  device_password: string
}) {
  return client.put(`/api/onvifDevices/${id}`, payload)
}

export function connectOnvifDevice({
  id,
  ...payload
}: {
  id: number
  name: string
  type: string
  timezone: string
  location_id: number
  is_licensed: boolean
  ip_address?: string
  manufacturer?: string
}) {
  return client.post<{ data: ConnectedDevice }>(
    `/api/onvifDevices/${id}/connect`,
    payload
  )
}
