import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function More(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="4"
        viewBox="0 0 14 4"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <ellipse
          cx="1.95192"
          cy="1.63942"
          rx="1.51442"
          ry="1.51442"
          fill="currentColor"
        />
        <ellipse
          cx="7.00002"
          cy="1.63942"
          rx="1.51442"
          ry="1.51442"
          fill="currentColor"
        />
        <circle cx="12.0481" cy="1.63942" r="1.51442" fill="currentColor" />
      </svg>
    </SVGUniqueID>
  )
}
