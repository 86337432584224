import { Fragment } from 'react'
import { format } from 'date-fns'
import cx from 'classnames'

import { Tooltip } from 'components'
import { dateTimeFormat } from 'utils/dateTime'
import { ctof, DEGREE_SYMBOL } from 'utils/device'
import { SensorDevice } from 'services/devices'
import useStore from 'store'

interface Props {
  type: 'temperature' | 'humidity'
  data: SensorDevice['temperatureSensorLogs']
}

function SensorChart({ type, data }: Props) {
  const { unit_temperature } = useStore(state => state.auth.currentUser)!

  const isF = type === 'temperature' && unit_temperature === 'f'
  const maxHeight = isF ? ctof(100) : 100

  const renderTooltip = (value: number, date: string) => {
    return (
      <Fragment>
        <div className="text-[0.8125rem] text-[#A6A6A6] mb-1">
          {format(
            new Date(date),
            `${dateTimeFormat['MMM dd, yyyy']} • ${dateTimeFormat['hh:mm a']}`
          )}
        </div>
        <div className="text-base font-medium">
          {value}
          {type === 'humidity' ? '%' : DEGREE_SYMBOL[unit_temperature]}
        </div>
      </Fragment>
    )
  }

  return (
    <div className="relative grid grid-cols-[repeat(35,1fr)] gap-px w-full h-[2.8rem] items-end overflow-hidden">
      {data.map(({ id, created_at, ...d }, idx) => {
        const value = isF ? ctof(d[type]) : d[type]
        return (
          <Tooltip
            key={idx}
            content={renderTooltip(value, created_at)}
            style={{ height: Math.min((value / maxHeight) * 100, 100) + '%' }}
            className={cx(
              'base-transition cursor-pointer',
              d.alarms.some(a => a.condition_parameter === type)
                ? 'bg-[#FFAFAF] hover:bg-danger'
                : 'bg-[#D7F7DF] hover:bg-success'
            )}
          />
        )
      })}
      <div className="absolute bottom-[80%] left-0 right-0 border-b border-light-primary/25 border-dashed" />
      <div className="absolute bottom-[20%] left-0 right-0 border-b border-light-primary/25 border-dashed" />
      <div className="absolute bottom-0 left-0 right-0 h-px w-full bg-[#C2E5CB]" />
    </div>
  )
}

export default SensorChart
