import { ReactNode } from 'react'
import { format } from 'date-fns'

import { dateTimeFormat } from 'utils/dateTime'
import { Tag } from 'services/tags'
import { Device } from 'services/devices'
import { Note } from 'services/notes'

import client, { ResponseMeta } from './client'

export interface SystemNotif {
  created_at: string
  data: {
    level: 'info' | 'success' | 'warning'
    message: string
    target: string
    url: string | null
  }
  read_at: string | null
}

export interface SystemNotifResponse {
  data: SystemNotif[]
  meta: ResponseMeta & { unread_count: number }
}

export const getSystemNotifs = (currentPage: number) => {
  return client.get<SystemNotifResponse>(
    `/api/notifications?page=${currentPage}`
  )
}

export const markAllNotifAsRead = () => {
  return client.get('/api/notifications?mark_as_read=1')
}

// ------------------------------------------------------------------------- //

export interface InboundNotif {
  attachment_urls: string[]
  attachments: string[] | null
  code: string
  created_at: string
  device_id: number
  id: number
  message: string
  notes: Note[]
  recipients_count: number
  status: string
  subject: string
  tags: Tag[] | undefined
  updated_at: string
  device: Device | null
}

export interface InboundNotifResponse {
  data: InboundNotif[]
  meta: ResponseMeta
}

export const getLastestInboundNotifs = () => {
  return client.get<InboundNotifResponse>(
    '/api/deviceInboundNotifications?include=tags,device'
  )
}

export const getInboundNotifs = async ({
  device = undefined,
  tags = undefined,
  dateRange = undefined,
  sortBy = 'id',
  sortDirection = 'desc',
  currentPage = 1,
  pageSize = 10,
  search = ''
}: {
  device?: { value: number; label: ReactNode }
  tags?: { value: number; label: string }[]
  dateRange?: [Date | null, Date | null]
  sortBy?: string
  sortDirection?: string
  currentPage?: number
  pageSize?: number
  search?: string
}) => {
  let filters = ''

  if (!!device) {
    filters += `&filters[device_id]=${device.value}`
  }
  if (!!tags) {
    filters += `&filters[tag_ids]=${tags.map(t => t.value).join(',')}`
  }
  if (dateRange && !!dateRange[0] && !!dateRange[1]) {
    const from = format(dateRange[0], dateTimeFormat['yyyy-MM-dd'])
    const to = format(dateRange[1], dateTimeFormat['yyyy-MM-dd'])
    filters += `&filters[date_range]=${from},${to}`
  }

  return client.get<InboundNotifResponse>(
    `/api/deviceInboundNotifications?include=tags,device,device.location,notes&filters[search]=${search}&order_by=${sortBy}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}${filters}`
  )
}

export const getInboundNotifDetails = (id: number) => {
  return client.get<{ data: InboundNotif }>(
    `/api/deviceInboundNotifications/${id}?include=tags,device,device.location,notes`
  )
}

// ------------------------------------------------------------------------- //

export interface OutboundNotif {
  batch_id: string
  created_at: string
  device_id: number
  device_inbound_notification_id: number
  id: number
  is_sent: number
  name: string
  recipient_id: number
  response: string
  status: string
  to: string
  updated_at: string
  via: string
}

export interface OutboundNotifResponse {
  data: OutboundNotif[]
  meta: ResponseMeta
}

export const getOutboundNotifs = (
  inboundNotifId: number,
  {
    sortBy = 'id',
    sortDirection = 'desc',
    pageSize = 10,
    currentPage = 1,
    search = ''
  }: {
    sortBy?: string
    sortDirection?: string
    pageSize?: number
    currentPage?: number
    search?: string
  }
) => {
  return client.get<OutboundNotifResponse>(
    `/api/deviceInboundNotifications/${inboundNotifId}/outboundNotificationLogs?filters[search]=${search}&page=${currentPage}&order_by=${sortBy}&sort=${sortDirection}&limit=${pageSize}`
  )
}

// ------------------------------------------------------------------------- //

export type NotifSumType = 'inbound' | 'outbound'
export interface NotifSumResponse {
  data: {
    [key: string]: number
  }
}

export const getNotifSum = (type: NotifSumType, deviceId?: number) => {
  return client.get<NotifSumResponse>(
    `/api/cards/${type}Notifications?device_id=${deviceId || ''}`
  )
}
