import { SetState } from 'zustand'

import { Config } from 'services/config'
import { Store } from './'

export interface ConfigSlice {
  config: Config | null
  setConfig: (config: Config) => void
}

const slice = (set: SetState<Store>) => ({
  config: null,
  setConfig: (config: Config) => {
    return set(({ conf }) => ({ conf: { ...conf, config } }))
  }
})

export default slice
