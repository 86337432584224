import { Close, Delete, Pencil } from 'icons/outline'
import { Button, Modal, ModalProps, Tabs } from 'components'
import { Tag as ITag } from 'services/tags'

import TagDetails from './TagDetails'
import AffectedDevices from './AffectedDevices'
import Recipients from './Recipients'

interface Props extends ModalProps {
  tag: ITag
  onEdit: () => void
  onDelete: () => void
}

function ModalViewTagDetail({ tag, onEdit, onDelete, ...props }: Props) {
  return (
    <Modal {...props} className="w-[43.75rem]">
      <div className="flex items-center justify-between gap-4 mb-5">
        <div className="text-base font-medium">Tag Details</div>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      <Tabs>
        <Tabs.TabPane key={1} tab="Tag Details">
          <TagDetails tag={tag} />
        </Tabs.TabPane>
        <Tabs.TabPane key={2} tab="Affected Devices">
          <AffectedDevices tag={tag} />
        </Tabs.TabPane>
        <Tabs.TabPane key={3} tab="Recipients">
          <Recipients tag={tag} />
        </Tabs.TabPane>
      </Tabs>
      <div className="border-b border-light-stroke my-4" />
      <div className="flex items-center justify-between gap-4">
        <Button className="w-20" onClick={onDelete}>
          <Delete /> Delete
        </Button>
        <div className="inline-flex items-center gap-1">
          <Button className="w-20" onClick={onEdit}>
            <Pencil /> Edit
          </Button>
          <Button
            variant="primary"
            className="w-[7.5rem]"
            onClick={props.onClose}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalViewTagDetail
