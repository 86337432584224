import { Link } from 'react-router-dom'

function Dev() {
  return (
    <div className="w-full h-full p-4">
      <h1 className="text-2xl font-medium mb-4">Dev</h1>
      <div className="flex gap-4">
        <Link to="/dev/assets" className="text-primary">
          Assets
        </Link>
        <Link to="/dev/icons" className="text-primary">
          Icons
        </Link>
        <Link to="/dev/test" className="text-primary">
          Test Component
        </Link>
      </div>
    </div>
  )
}

export default Dev
