import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Humidity(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M7.59991 1.43869C3.86891 4.62369 1.99991 7.37469 1.99991 9.69869C1.99991 13.1847 4.65991 15.4387 7.59991 15.4387C10.5399 15.4387 13.1999 13.1847 13.1999 9.69869C13.1999 7.37469 11.3309 4.62369 7.59991 1.43869ZM4.68091 9.83869C4.93991 9.83869 5.14991 10.0207 5.19891 10.2727C5.48591 11.8267 6.79491 12.3587 7.74691 12.2817C8.04791 12.2677 8.29991 12.5057 8.29991 12.8067C8.29991 13.0867 8.07591 13.3177 7.79591 13.3317C6.30491 13.4227 4.56191 12.5687 4.16291 10.4477C4.10691 10.1327 4.35891 9.83869 4.68091 9.83869Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
