import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Delete(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M0.666626 10.6667C0.666626 11.4033 1.26328 12 1.99997 12H7.33331C8.06997 12 8.66663 11.4033 8.66663 10.6667V2.66666H0.666626V10.6667Z"
          fill="currentColor"
        />
        <path
          d="M7 0.666656L6.33331 0H3L2.33331 0.666656H0V2H9.33331V0.666656H7Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
