import { Dispatch, useState } from 'react'
import shallow from 'zustand/shallow'

import { Checkbox, Select } from 'components'
import useStore from 'store'

import { FormValues, mapDevices, OptionValue } from './FormHelpers'

interface Props {
  values: FormValues['affectedDevices']
  setValues: Dispatch<Partial<FormValues>>
}

function AffectedDevices({ values, setValues }: Props) {
  const [applyAll, setApplyAll] = useState(values.apply_to_all || false)
  const [selectedDevices, setSelectedDevices] = useState(values.devices || [])

  const devices = useStore(state => state.device.devices, shallow)

  const handleApplyAllChange = (checked: boolean) => {
    setApplyAll(checked)
    setValues({ affectedDevices: { ...values, apply_to_all: checked } })
  }

  const handleDeviceChange = (devices: OptionValue[]) => {
    setSelectedDevices(devices)
    setValues({ affectedDevices: { ...values, devices } })
  }

  return (
    <div
      id="affected-devices"
      className="min-h-[calc(100vh_-_5.15rem)] py-4 flex flex-col justify-center"
    >
      <div className="w-[37.5rem] m-auto">
        <div className="text-base font-medium mb-6">Affected Devices</div>
        <div className="mb-4">
          <Checkbox
            size="large"
            checked={applyAll}
            onChange={e => handleApplyAllChange(e.target.checked)}
          >
            Apply to all devices
          </Checkbox>
        </div>
        {!applyAll && (
          <Select
            isMulti
            size="large"
            value={selectedDevices}
            onChange={v => handleDeviceChange(v as OptionValue[])}
            placeholder="Begin typing to search..."
            options={mapDevices(devices)}
          />
        )}
      </div>
    </div>
  )
}

export default AffectedDevices
