import { useCallback, useEffect, useReducer, useRef } from 'react'

import toast from 'utils/toast'
import { ResponseError } from 'services/client'

type Status = 'idle' | 'pending' | 'resolved' | 'rejected'

interface State<T, E> {
  status: Status
  data: T | null
  error: E | null
  showNotifOnError?: boolean
}

export function useAsync<T = any, E = ResponseError>(
  initState?: Partial<State<T, E>> & { showNotifOnError?: boolean }
) {
  const mounted = useRef(false)
  const initialStateRef = useRef<State<T, E>>({
    status: 'idle' as Status,
    data: null,
    error: null,
    showNotifOnError: false,
    ...initState
  })
  const [state, _setState] = useReducer(
    (s: State<T, E>, a: Partial<State<T, E>>) => ({ ...s, ...a }),
    initialStateRef.current
  )

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const setState = useCallback(
    (args: Partial<State<T, E>>) =>
      mounted.current ? _setState(args) : void 0,
    [_setState]
  )

  const reset = useCallback(() => setState(initialStateRef.current), [setState])

  const execute = useCallback(
    (asyncFn: Promise<T>) => {
      setState({ status: 'pending' })
      return asyncFn.then(
        data => {
          setState({ data, status: 'resolved', error: null })
          return data
        },
        error => {
          setState({ status: 'rejected', data: null, error })
          state.showNotifOnError &&
            toast.error({
              title: error.message || 'An error occurred',
              description: error.errors
            })
          return Promise.reject(error)
        }
      )
    },
    [setState, state.showNotifOnError]
  )

  return {
    isIdle: state.status === 'idle',
    isLoading: state.status === 'pending',
    isError: state.status === 'rejected',
    isSuccess: state.status === 'resolved',
    setState,
    ...state,
    execute,
    reset
  }
}
