import { useEffect, useRef, useState } from 'react'
import { update } from 'jdenticon'
import cx from 'classnames'

import { Camera } from 'icons/outline'

interface Props {
  size?: 'large' | 'medium' | 'small' | number
  username: string
  src?: string | null
  className?: string
  onChange?: (image: File) => void
}

const sizes = {
  small: 'w-8 h-8',
  medium: 'w-10 h-10',
  large: 'w-16 h-16'
}

export function Avatar({
  size = 'medium',
  username,
  src: propSrc,
  className,
  onChange
}: Props) {
  const [src, setSrc] = useState(propSrc)

  const iconRef = useRef<SVGSVGElement>(null)
  const pickerRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (!src && !!iconRef.current) {
      update(iconRef.current, username, { padding: 0 })
    }
  }, [username, src])

  useEffect(() => {
    if (src?.startsWith('blob:')) {
      setTimeout(() => {
        URL.revokeObjectURL(src)
      }, 1000)
    }
  }, [src])

  const handleOpenFileDialog = () => {
    if (pickerRef.current) {
      pickerRef.current.click()
    }
  }

  const handlePickerChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { files } = e.target
    if (!files || !files.length) {
      return
    }
    const newSrc = URL.createObjectURL(files[0])
    setSrc(newSrc)
    onChange && onChange(files[0])
  }

  return (
    <div
      style={typeof size === 'number' ? { width: size, height: size } : {}}
      className={cx(
        typeof size === 'string' && sizes[size],
        'box-border rounded-full m-0 p-0 relative inline-block overflow-hidden bg-hover relative',
        className
      )}
    >
      {src ? (
        <img src={src} alt={username} className="w-full h-full object-cover" />
      ) : (
        <svg ref={iconRef} className="p-0.5" data-jdenticon-value={username} />
      )}
      {onChange && (
        <div
          onClick={handleOpenFileDialog}
          className="absolute bottom-0 left-0 w-full text-center py-[0.6875rem] bg-black/50 backdrop-blur-xl flex justify-center cursor-pointer"
        >
          <input
            type="file"
            accept="image/*"
            className="absolute hidden pointer-events-none"
            ref={pickerRef}
            onChange={handlePickerChange}
          />
          <Camera className="text-white" />
        </div>
      )}
    </div>
  )
}
