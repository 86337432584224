import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useReducer
} from 'react'

import DeviceDetail from './DeviceDetails'
import ExtraFeatures from './ExtraFeatures'
import SelectDeviceType from './SelectDeviceType'
import CloudSurveillance from './CloudSurveillance'
import NotifSchedule from './NotifSchedule'

export interface OptionValue {
  label: string
  value: any
}

export interface Step {
  key: string
  label: string
  tooltip?: ReactNode
  Component: FC<any>
}

export interface State {
  stepIdx: number
  steps: Step[]
  extraFeatures: {
    schedule: boolean
    livestream: boolean
  }
  deviceDetail: {
    name: string
    type: string
    timezone: OptionValue | null
    location: OptionValue | null
  }
  cloudSurveillance: {
    enable_recording: boolean
    is_licensed: boolean
    stream_url: string
  }
  schedule: {
    name: string
    is_24_7: boolean
    start_time: string
    end_time: string
    days: string[]
    channels: string[]
    webhook: string
    slack_webhook: string
    teams_webbhook: string
    pagerduty_integration_key: string
    email_recipients: OptionValue[]
    phone_recipients: OptionValue[]
    whatsapp_recipients: OptionValue[]
  }
}

export const extraSteps = {
  schedule: {
    key: 'schedule',
    label: 'Set up Notifications Schedule',
    tooltip: (
      <div className="max-w-[25rem]">
        <div className="text-base font-medium mb-4">
          What is a Notification?
        </div>
        <div className="mb-3 text-sm">
          A Notification is defined as one or more schedules, recipients and
          channels which combine to form a workflow for to alert teams when a
          particular event has been triggered.
        </div>
        <div className="text-sm">
          Notification schedules provide the flexibility to define who should
          receive a given notification for a given time period.
        </div>
      </div>
    ),
    Component: NotifSchedule
  },
  livestream: {
    key: 'livestream',
    label: 'Set up Cloud Surveillance',
    tooltip: (
      <div className="max-w-[25rem]">
        <div className="text-base font-medium mb-4">
          What is Cloud Surveillance?
        </div>
        <div className="mb-3 text-sm">
          Cloud Surveillance provides users with an easy way to manage and
          monitor security cameras without the need for any local recorders.
        </div>
        <div className="text-sm">
          Camera licenses include support for recording and livestreaming your
          cameras securely in the AWS cloud.
        </div>
      </div>
    ),
    Component: CloudSurveillance
  }
}

const defaultSteps: Step[] = [
  {
    key: 'type',
    label: 'Select Device Type',
    Component: SelectDeviceType
  },
  {
    key: 'details',
    label: 'Enter Device Details',
    Component: DeviceDetail
  },
  {
    key: 'features',
    label: 'Select Extra Features',
    Component: ExtraFeatures
  }
]

export const initState: State = {
  stepIdx: 0,
  steps: defaultSteps,
  extraFeatures: {
    schedule: false,
    livestream: false
  },
  deviceDetail: {
    name: '',
    type: '',
    timezone: null,
    location: null
  },
  cloudSurveillance: {
    is_licensed: false,
    enable_recording: false,
    stream_url: ''
  },
  schedule: {
    name: '',
    is_24_7: false,
    start_time: '',
    end_time: '',
    days: [],
    channels: [],
    webhook: '',
    slack_webhook: '',
    teams_webbhook: '',
    pagerduty_integration_key: '',
    email_recipients: [],
    phone_recipients: [],
    whatsapp_recipients: []
  }
}

const FormContext = createContext<
  | (State & {
      onStepChange: (step: number) => void
      isFinalStep: boolean
      dispatch: Dispatch<Partial<State>>
    })
  | undefined
>(undefined)

export const useFormCtx = () => {
  const ctx = useContext(FormContext)
  if (ctx === undefined) {
    throw Error('Invalid context call')
  }
  return ctx
}

const FormContextProvider = ({ children }: { children?: ReactNode }) => {
  const [state, dispatch] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const setStepIdx = (step: number) => {
    dispatch({ stepIdx: state.stepIdx + step })
  }

  const ctx = {
    ...state,
    onStepChange: setStepIdx,
    isFinalStep: state.stepIdx + 1 === state.steps.length,
    dispatch
  }

  return <FormContext.Provider value={ctx}>{children}</FormContext.Provider>
}

export default FormContextProvider
