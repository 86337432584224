import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Bell(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2 13.0909H14.4V6.54546C14.4 2.93646 11.5288 0 8 0C4.47121 0 1.60001 2.93646 1.60001 6.54546V13.0909H0.800007C0.357602 13.0909 0 13.4567 0 13.9091C0 14.3616 0.357602 14.7273 0.800007 14.7273H1.60001H4.80001C4.80001 16.5322 6.23519 18 8 18C9.76481 18 11.2 16.5322 11.2 14.7273H14.4H15.2C15.6424 14.7273 16 14.3615 16 13.9091C16 13.4566 15.6424 13.0909 15.2 13.0909ZM7.99988 16.3863C7.11748 16.3863 6.3999 15.6524 6.3999 14.75H9.5999C9.59986 15.6524 8.88229 16.3863 7.99988 16.3863ZM11.1999 13.0795H12.7999V6.53408C12.7999 3.82673 10.6471 1.625 7.99992 1.625C5.35275 1.625 3.19995 3.82673 3.19995 6.53408V13.0795H4.79993H11.1999Z"
        />
      </svg>
    </SVGUniqueID>
  )
}
