import { Book, Support } from 'icons/outline'
import { Popover } from 'components'
import MenuItem from 'shared/MenuItem'

interface Props {
  onContactSupport: () => void
  onOpenGuideBook: () => void
  onClose: () => void
}

function PopoverHelpMenu({
  onContactSupport,
  onOpenGuideBook,
  onClose
}: Props) {
  const onClick = (fn: Function) => {
    fn()
    onClose()
  }

  return (
    <Popover.Content onClose={onClose}>
      <MenuItem onClick={() => onClick(onContactSupport)}>
        <Support className="w-4 h-4 shrink-0 text-light-secondary" /> Contact
        Support
      </MenuItem>
      <MenuItem onClick={() => onClick(onOpenGuideBook)}>
        <Book className="w-4 h-4 shrink-0 text-light-secondary" /> Open Guide
        Book
      </MenuItem>
    </Popover.Content>
  )
}

export default PopoverHelpMenu
