import { useState } from 'react'
import { FormInstance } from 'rc-field-form'
import { AxiosResponse } from 'axios'

import { Coupon } from 'icons/filled'
import { useAsync } from 'hooks'
import { Button, FormField, Input } from 'components'
import { Coupon as ICoupon, getCouponInfo } from 'services/subscriptions'

interface Props {
  form: FormInstance
  onCouponChange?: (code?: ICoupon) => void
}

function CouponForm({ form, onCouponChange }: Props) {
  const [couponCode, setCoupon] = useState('')

  const { execute, isLoading, data, setState } = useAsync<
    AxiosResponse<{ data: ICoupon }>
  >({ showNotifOnError: true })

  const handleResetCoupon = () => {
    setCoupon('')
    onCouponChange?.()
    form.setFieldsValue({ coupon: undefined })
    setState({ data: undefined, status: 'idle' })
  }

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) return
    const response = await execute(getCouponInfo(couponCode))
    const coupon = response.data.data
    onCouponChange?.(coupon)
    form.setFieldsValue({ coupon: coupon.code })
  }

  const isValidCoupon = !!data?.data.data

  return (
    <div className="mt-10">
      <div className="flex items-center gap-2 mb-2">
        <Coupon type="primary" />
        <div className="text-base font-medium">Coupon Code</div>
      </div>
      <div className="flex">
        <Input
          disabled={isValidCoupon}
          placeholder="Enter your coupon (optional)"
          value={couponCode}
          onChange={e => setCoupon(e.target.value)}
          className="w-60 mr-2"
        />
        {!isValidCoupon ? (
          <Button
            type="button"
            disabled={isLoading}
            onClick={handleApplyCoupon}
          >
            Apply code
          </Button>
        ) : (
          <Button
            type="button"
            className="text-danger ml-1"
            onClick={handleResetCoupon}
          >
            Remove coupon
          </Button>
        )}
      </div>
      <FormField name="coupon" className="hidden">
        <div>hi</div>
      </FormField>
    </div>
  )
}

export default CouponForm
