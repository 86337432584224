import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function IDS(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M17.3057 5.78012C17.1088 5.66037 16.8863 5.57484 16.668 5.49786C15.9062 5.23698 15.1144 5.10012 14.3227 4.98465C14.2071 4.96755 14.1429 4.9205 14.1044 4.81358C13.946 4.40302 13.7834 4.00101 13.6208 3.59472C13.3169 2.84202 13.0344 2.08077 12.6963 1.3409C12.3283 0.52833 11.468 0.117766 10.6206 0.305941C10.2055 0.400029 9.85451 0.613864 9.57204 0.938893C9.38801 1.15273 9.16118 1.29814 8.88299 1.20833C8.70323 1.15273 8.5406 1.0116 8.40365 0.879019C7.84299 0.331601 7.18389 0.11349 6.43492 0.348708C5.83574 0.536883 5.44628 0.98166 5.21517 1.55901C4.77434 2.6453 4.34208 3.72303 3.91409 4.80931C3.86702 4.92478 3.8071 4.97182 3.68298 4.98893C2.88693 5.10012 2.09516 5.2327 1.33763 5.50641C1.0252 5.6176 0.721335 5.77157 0.447426 5.95974C0.173516 6.14791 0.194915 6.42162 0.455985 6.63118C0.550142 6.70389 0.648578 6.77231 0.755574 6.82791C1.25204 7.08879 1.79557 7.22564 2.33911 7.34539C3.93121 7.69608 5.54899 7.8201 7.16677 7.88853C9.48217 7.9869 11.789 7.91419 14.0873 7.6191C14.9561 7.50791 15.8206 7.3625 16.6552 7.08024C17.0746 6.9391 17.4983 6.79797 17.7551 6.39169C17.7551 6.32326 17.7551 6.25483 17.7551 6.18641C17.6053 6.04527 17.4726 5.88704 17.3057 5.78012Z"
          fill="currentColor"
        />
        <path
          d="M15.4524 8.56006C11.1469 9.1759 6.86708 9.18018 2.57012 8.56006C2.54872 8.74396 2.52732 8.94068 2.50164 9.13314C2.35185 10.3178 2.49736 11.4725 2.89539 12.593C3.40469 14.0214 4.24354 15.2317 5.39482 16.2196C6.09243 16.8184 6.8628 17.2974 7.75301 17.5497C8.074 17.6395 8.40354 17.6908 8.73309 17.755C8.91712 17.755 9.09688 17.755 9.28091 17.755C9.30659 17.7464 9.33227 17.7336 9.35795 17.7293C10.0256 17.6737 10.6462 17.4599 11.2368 17.152C12.2554 16.6216 13.0942 15.886 13.7961 14.9837C14.9902 13.4441 15.6151 11.7034 15.5594 9.7447C15.5466 9.34697 15.4867 8.94496 15.4524 8.56006ZM10.6505 10.1125C11.01 10.1082 11.3652 10.1082 11.7247 10.1125C12.0457 10.1168 12.2811 10.3563 12.2811 10.6599C12.2811 10.9636 12.0414 11.1945 11.7204 11.2031C11.5449 11.2073 11.3695 11.2031 11.1897 11.2031C11.0057 11.2031 10.8259 11.2073 10.6419 11.2031C10.3295 11.1945 10.0984 10.9593 10.0941 10.6642C10.0941 10.3563 10.3338 10.1168 10.6505 10.1125ZM6.27646 10.1125C6.63597 10.1082 6.9912 10.1082 7.3507 10.1125C7.67169 10.1168 7.90708 10.3563 7.90708 10.6599C7.90708 10.9636 7.66741 11.1945 7.34642 11.2031C7.17095 11.2073 6.99548 11.2031 6.81572 11.2031C6.63169 11.2031 6.45194 11.2073 6.2679 11.2031C5.95548 11.1945 5.72436 10.9593 5.72008 10.6642C5.72008 10.3563 5.95975 10.1168 6.27646 10.1125ZM13.4024 13.6237C12.7519 14.6672 11.9045 15.5268 10.8174 16.1255C10.1069 16.519 9.36223 16.7713 8.53194 16.6216C7.78725 16.4891 7.13243 16.1512 6.53325 15.7064C5.38198 14.8511 4.53457 13.7605 4.00387 12.4262C3.99103 12.392 3.97819 12.3535 3.96107 12.3064C7.3293 12.3064 10.6847 12.3064 14.0957 12.3064C13.8561 12.7641 13.6549 13.2088 13.4024 13.6237Z"
          fill="currentColor"
        />
        <path
          d="M9.4907 14.6715C9.22107 14.4576 8.92148 14.4191 8.65185 14.5859C8.27523 14.8083 8.08691 15.1718 7.92 15.5696C8.64757 15.5696 9.35802 15.5696 10.077 15.5696C9.97432 15.2317 9.73037 14.8639 9.4907 14.6715Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
