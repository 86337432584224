import { Device } from './devices'
import { Recipient } from './recipients'
import client, { ResponseMeta } from './client'

export type NotifChannel =
  | 'email'
  | 'phone'
  | 'whatsapp'
  | 'webhook'
  | 'slack'
  | 'microsoft_teams'
  | 'pager_duty'

export interface Tag {
  id: number
  account_id: number
  name: string
  is_active: number
  search_in: string
  subject_keywords: string
  body_keywords: string
  color: string | null
  stroke_color: string | null
  text_color: string | null
  created_at: string
  updated_at: string
  is_blocked: number
  channels: NotifChannel[]
  apply_to_all: number
  has_attachments: number
  devices?: Device[] | null
  email_recipients?: Recipient[] | null
  phone_recipients?: Recipient[] | null
  whatsapp_recipients?: Recipient[] | null
  slack_webhook?: string | null
  teams_webbhook?: string | null
  webhook?: string | null
  pagerduty_integration_key?: string | null
}

export interface TagResponse {
  data: Tag[]
  meta: ResponseMeta
}

export const getTags = async ({
  pageSize = 10,
  sortBy = 'id',
  sortDirection = 'desc',
  search = '',
  currentPage = 1
}: {
  pageSize?: number
  sortBy?: string
  sortDirection?: string
  search?: string
  currentPage?: number
}) => {
  return client.get<TagResponse>(
    `/api/tags?filters[search]=${search}&order_by=${sortBy}&sort=${sortDirection}&page=${currentPage}&limit=${pageSize}`
  )
}

export const getTagDetail = (id: number, include: string) => {
  return client.get<{ data: Tag }>(`api/tags/${id}?include=${include}`)
}

export const deleteTag = (id: number) => {
  return client.delete(`/api/tags/${id}`)
}

export const createTag = async (payload: {
  name: string
  is_active: boolean
  is_blocked: boolean
  has_attachments: boolean
  apply_to_all: boolean
  search_in: string
  subject_keywords?: string
  body_keywords?: string
  account_id: number
  channels: string[]
  webhook?: string
  slack_webhook?: string
  teams_webbhook?: string
  email_recipient_ids?: number[]
  phone_recipient_ids?: number[]
  whatsapp_recipient_ids?: number[]
  device_ids: number[]
  stroke_color: string
  text_color: string
  color: string
  pagerduty_integration_key: string
}) => {
  await client.get('/api/csrf-cookie')
  return client.post('/api/tags', payload)
}

export const updateTag = async (
  id: number,
  payload: {
    name?: string
    is_active?: boolean
    is_blocked?: boolean
    has_attachments?: boolean
    apply_to_all?: boolean
    search_in?: string
    subject_keywords?: string
    body_keywords?: string
    account_id?: number
    channels?: string[]
    webhook?: string
    slack_webhook?: string
    teams_webbhook?: string
    email_recipient_ids?: number[]
    phone_recipient_ids?: number[]
    whatsapp_recipient_ids?: number[]
    device_ids?: number[]
    stroke_color?: string
    text_color?: string
    color?: string
    pagerduty_integration_key?: string
  }
) => {
  await client.get('/api/csrf-cookie')
  return client.put(`/api/tags/${id}`, payload)
}

// ------------------------------------------------------------------------- //

export interface TagSummary {
  color: string
  id: number
  name: string
  notifications_count: number
  stroke_color: string
  text_color: string
}

export const getTagSummary = () => {
  return client.get<{ data: TagSummary[] }>('/api/cards/tags')
}
