import { GetState, SetState } from 'zustand'

import { Device, SensorDevice } from 'services/devices'
import { SensorUpdate } from 'services/broadcast'
import type { Store } from './'

export interface DeviceSlice {
  devices: Device[]
  sensors: SensorDevice[]
  setDevices: (payload: Device[]) => void
  setSensors: (payload: SensorDevice[]) => void
  updateDevice: (id: number, payload: Partial<Device>) => void
  updateSensor: (payload: SensorUpdate) => void
  removeDevice: (id: number) => void
}

const slice = (set: SetState<Store>, get: GetState<Store>) => ({
  devices: [],
  sensors: [],
  setDevices: (payload: Device[]) => {
    return set(({ device }) => ({ device: { ...device, devices: payload } }))
  },
  setSensors: (payload: SensorDevice[]) => {
    return set(({ device }) => ({
      device: {
        ...device,
        sensors: payload.map(sensor => ({
          ...sensor,
          temperatureSensorLogs: sensor.temperatureSensorLogs.slice().reverse()
        }))
      }
    }))
  },
  updateDevice: (id: number, payload: Partial<Device>) => {
    return set(({ device }) => ({
      device: {
        ...device,
        devices: device.devices.map(d =>
          d.id === id ? { ...d, ...payload } : d
        )
      }
    }))
  },
  updateSensor: ({ device_id, ...payload }: SensorUpdate) => {
    const sensor = get().device.sensors.find(s => s.id === device_id)
    if (!sensor) return

    const logs = sensor.temperatureSensorLogs
    const newLogs = [
      ...logs.slice(Math.max(logs.length - 34, 0)),
      { ...payload, id: device_id }
    ]

    set(({ device }) => ({
      device: {
        ...device,
        sensors: device.sensors.map(s => ({
          ...s,
          temperatureSensorLogs:
            s.id === sensor.id ? newLogs : s.temperatureSensorLogs
        }))
      }
    }))
  },
  removeDevice: (id: number) => {
    const removedDevice = get().device.devices.find(d => d.id === id)
    if (!!removedDevice) {
      set(({ device }) => ({
        device: { ...device, devices: device.devices.filter(d => d.id !== id) }
      }))
      get().stat.updateUsedCount({
        devices: -1,
        camera_licenses: !!removedDevice.is_licensed ? -1 : 0
      })
    }
  }
})

export default slice
