import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function WifiOff(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M5.2403 6.32704C5.17043 6.58323 5.13161 6.85494 5.13161 7.13442C5.13161 7.98837 5.48096 8.76469 6.03991 9.33141L4.93753 10.4338C4.0991 9.58759 3.57897 8.42311 3.57897 7.13442C3.57897 6.41244 3.74199 5.73704 4.02923 5.12374L2.88804 3.98255C2.33685 4.90637 2.02632 5.98546 2.02632 7.13442C2.02632 8.85009 2.72501 10.4027 3.85068 11.5206L2.7483 12.623C1.34316 11.2179 0.473679 9.27707 0.473679 7.13442C0.473679 5.55072 0.947235 4.08347 1.76237 2.85688L0.00012207 1.09463L1.09474 1.52588e-05L15.3635 14.2688L14.2689 15.3634L5.2403 6.32704ZM12.4446 9.14509C12.7318 8.5318 12.8948 7.8564 12.8948 7.13442C12.8948 5.84572 12.3747 4.68124 11.5285 3.84281L10.4261 4.94519C10.9928 5.50414 11.3422 6.28046 11.3422 7.13442C11.3422 7.41389 11.3034 7.6856 11.2335 7.94179L12.4446 9.14509ZM14.4475 7.13442C14.4475 8.28337 14.1369 9.36246 13.5858 10.2863L14.7114 11.412C15.5266 10.1854 16.0001 8.71811 16.0001 7.13442C16.0001 4.99177 15.1306 3.05096 13.7255 1.64582L12.6231 2.7482C13.7488 3.8661 14.4475 5.41874 14.4475 7.13442Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
