import { useState } from 'react'
import { add } from 'date-fns'

import { ClockCircle } from 'icons/filled'
import { ArrowRight, Close, InfoCircle } from 'icons/outline'
import { Button, Modal, ModalProps, RecordingCalendar } from 'components'
import { validateTimeRange } from 'utils/validation'
import toast from 'utils/toast'
import { Device } from 'services/devices'

interface Props extends ModalProps {
  timeRange?: Date[]
  onChange?: (range: Date[]) => void
  devices: (Device | null)[]
}

function ModalSelectPlaybackTime({
  timeRange,
  onChange,
  devices,
  ...props
}: Props) {
  const [start, setStart] = useState(timeRange?.[0] || null)
  const [end, setEnd] = useState(timeRange?.[1] || null)

  const handleSubmit = () => {
    const validateResult = validateTimeRange(start, end)
    if (validateResult !== true) {
      return toast.error({ title: validateResult })
    }
    onChange?.([start!, end!])
    props.onClose()
  }

  const renderSelectedDevices = () =>
    devices.map(d => d?.id).filter(item => item) as number[]

  return (
    <Modal {...props} className="w-[40.625rem]">
      <div className="flex items-center justify-between gap-4 mb-6">
        <div className="text-base font-medium">Change Playback Time Range</div>
        <Close className="cursor-pointer shrink-0" onClick={props.onClose} />
      </div>
      <div className="mb-6">
        <div className="flex items-center gap-1 mb-2.5">
          <ClockCircle type="primary" className="w-3.5 h-3.5" />
          <div className="uppercase font-medium text-[0.8125rem]">
            Select time range you want to view playback
            <span className="text-danger">*</span>
          </div>
        </div>
        <div className="text-light-secondary flex items-center gap-1 mb-3">
          <InfoCircle className="w-3 h-3" />
          <div className="text-xs">Maximum time range is 4 hours</div>
        </div>
        <div className="flex items-center gap-2">
          <RecordingCalendar
            showTime
            value={start}
            onChange={(_, v) => {
              setStart(v)
              !!v && setEnd(add(v, { hours: 1 }))
            }}
            deviceId={renderSelectedDevices()}
            placeholder="Start time"
          />
          <ArrowRight className="shrink-0 text-light-secondary" />
          <RecordingCalendar
            showTime
            value={end}
            deviceId={renderSelectedDevices()}
            onChange={(_, v) => setEnd(v)}
            placeholder="End time"
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <Button className="w-20" onClick={props.onClose}>
          Close
        </Button>
        <Button className="w-20" variant="primary" onClick={handleSubmit}>
          Select
        </Button>
      </div>
    </Modal>
  )
}

export default ModalSelectPlaybackTime
