import { useState } from 'react'
import { add } from 'date-fns'
import { omit } from 'lodash'
import shallow from 'zustand/shallow'

import { InfoCircle } from 'icons/filled'
import { Search } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Form, FormField, Select, RecordingCalendar } from 'components'
import toast from 'utils/toast'
import { required } from 'utils/rules'
import { isCamera } from 'utils/device'
import { MAX_PLAYBACK_HOUR } from 'utils/dateTime'
import { searchRecords } from 'services/recordingDownloads'
import useStore, { Store } from 'store'

interface Props {
  onRefresh: () => void
}

interface FormValues {
  device: { label: string; value: number }
  start_timestamp: string
  end_timestamp: string
}

const mapState = (state: Store) => {
  return state.device.devices.filter(d => isCamera(d.type))
}

function SearchForm({ onRefresh }: Props) {
  const [form] = Form.useForm()
  const [selectedDeviceId, setSelectedDeviceId] = useState<number>()

  const { execute, isLoading } = useAsync({ showNotifOnError: true })
  const devices = useStore(mapState, shallow)

  const handleDeviceChange = (device: FormValues['device']) => {
    setSelectedDeviceId(device.value)
  }

  const handleStartTimeChange = (_: any, date: Date | null) => {
    if (!date) return
    form.setFieldsValue({ end_timestamp: add(date, { hours: 1 }) })
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      await execute(
        searchRecords({
          ...omit(values, 'device'),
          device_id: values.device.value
        })
      )
      onRefresh()
      toast.success(
        { title: 'Download request submitted' },
        { position: 'bottom-center' }
      )
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="bg-[#f5f6ff] rounded-lg p-8">
      <div className="flex items-center gap-2 mb-2.5">
        <Search className="w-4 h-4 shrink-0" />
        <span className="uppercase font-semibold">Search Records</span>
      </div>
      <div className="flex items-center gap-1 mb-6">
        <InfoCircle className="w-3 h-3 shrink-0" color="#808080" />
        <span className="text-light-secondary text-[0.8125rem]">
          Select a device and time range to access your cloud recordings.
          Maximum duration allowed is {MAX_PLAYBACK_HOUR} hours.
        </span>
      </div>
      <Form
        form={form}
        className="flex flex-wrap items-start gap-2.5"
        onFinish={handleSubmit}
      >
        <FormField
          className="mb-0"
          name="device"
          label="Device"
          rules={[required]}
          requiredMask
        >
          <Select
            className="w-[25rem]"
            placeholder="Select a device, type to search"
            onChange={v => handleDeviceChange(v as FormValues['device'])}
            options={devices.map(d => ({ value: d.id, label: d.name }))}
          />
        </FormField>
        <FormField
          className="mb-0"
          name="start_timestamp"
          label="Start Time"
          rules={[required]}
          requiredMask
        >
          <RecordingCalendar
            showTime
            deviceId={selectedDeviceId ? [selectedDeviceId] : []}
            className="w-[17.5rem]"
            placeholder="Select start time"
            onChange={handleStartTimeChange}
          />
        </FormField>
        <FormField
          className="mb-0"
          name="end_timestamp"
          label="End Time"
          rules={[required]}
          requiredMask
        >
          <RecordingCalendar
            showTime
            deviceId={selectedDeviceId ? [selectedDeviceId] : []}
            className="w-[17.5rem]"
            placeholder="Select end time"
          />
        </FormField>
        <Button
          type="submit"
          variant="primary"
          className="mt-[1.5625rem] w-[6.25rem]"
          disabled={isLoading}
        >
          Search
        </Button>
      </Form>
    </div>
  )
}

export default SearchForm
