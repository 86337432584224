import { ReactNode } from 'react'

import { Device } from 'services/devices'

export interface OptionValue {
  label: ReactNode
  value: any
}

export interface FormValues {
  deviceDetail: {
    name: string
    type: string
    timezone: OptionValue | null
    location: OptionValue | null
  }
  cloudSurveillance: {
    stream_url?: string
  }
}

export type FormKey = keyof FormValues & string

export const mapInitFormValues = (data: Device): FormValues => ({
  deviceDetail: {
    name: data.name,
    type: data.type,
    timezone: data.timezone
      ? { label: data.timezone, value: data.timezone }
      : null,
    location: data.location
      ? { value: data.location.id, label: data.location.name }
      : null
  },
  cloudSurveillance: {
    stream_url: data.stream_url || undefined
  }
})
