import { Fragment } from 'react'
import { format } from 'date-fns'

import { Camera, Cloud, LocationPin } from 'icons/filled'
import { dateTimeFormat } from 'utils/dateTime'
import { Bridge } from 'services/bridges'

interface Props {
  bridge: Bridge
}

function BridgeDetail({ bridge }: Props) {
  return (
    <Fragment>
      <div className="text-sm font-medium mb-3">Bridge Detail</div>
      <div className="bg-light-bg p-4 rounded-lg grid grid-cols-[repeat(auto-fit,minmax(30rem,1fr))] gap-5 overflow-hidden">
        <div className="flex flex-col">
          <div className="inline-flex">
            <div className="w-64 shrink-0 inline-flex items-center gap-2">
              <Cloud className="w-4 h-4 shrink-0 opacity-50" color="#808080" />
              <div className="font-medium">Status</div>
            </div>
            <div>{bridge.status || '---'}</div>
          </div>
          <div className="my-3 border-b border-dashed border-light-stroke" />
          <div className="inline-flex">
            <div className="w-64 shrink-0 inline-flex items-center gap-2">
              <Camera className="w-4 h-4 shrink-0 opacity-50" color="#808080" />
              <div className="font-medium">Device added/available</div>
            </div>
            <div>
              {bridge.devices_count}/{bridge.channel}
            </div>
          </div>
          <div className="my-3 border-b border-dashed border-light-stroke" />
          <div className="inline-flex">
            <div className="w-64 shrink-0 inline-flex items-center gap-2">
              <LocationPin
                className="w-4 h-4 shrink-0 opacity-50"
                color="#808080"
              />
              <div className="font-medium">Location</div>
            </div>
            <div>{bridge.location?.name || 'Unknown'}</div>
          </div>
          <div className="my-3 border-b border-dashed border-light-stroke" />
          <div className="inline-flex">
            <div className="w-64 shrink-0 inline-flex items-center gap-2">
              <div className="w-4 h-4 shrink-0" />
              <div className="font-medium">Online since</div>
            </div>
            <div>
              {bridge.last_ping_at
                ? format(
                    new Date(bridge.last_ping_at),
                    `${dateTimeFormat['HH:mm']}, ${dateTimeFormat['MMM dd, yyyy']}`
                  )
                : '---'}
            </div>
          </div>
          <div className="my-3 border-b border-dashed border-light-stroke" />
          <div className="inline-flex">
            <div className="w-64 shrink-0 inline-flex items-center gap-2">
              <div className="w-4 h-4 shrink-0" />
              <div className="font-medium">Date Added</div>
            </div>
            <div>
              {format(
                new Date(bridge.created_at),
                `${dateTimeFormat['HH:mm']}, ${dateTimeFormat['MMM dd, yyyy']}`
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="inline-flex">
            <div className="w-64 shrink-0 inline-flex items-center gap-2">
              <div className="w-4 h-4 shrink-0" />
              <div className="font-medium">Serial Number</div>
            </div>
            <div>{bridge.serial_number || '---'}</div>
          </div>
          <div className="my-3 border-b border-dashed border-light-stroke" />
          <div className="inline-flex">
            <div className="w-64 shrink-0 inline-flex items-center gap-2">
              <div className="w-4 h-4 shrink-0" />
              <div className="font-medium">Model</div>
            </div>
            <div>{bridge.model_number || '---'}</div>
          </div>
          <div className="my-3 border-b border-dashed border-light-stroke" />
          <div className="inline-flex">
            <div className="w-64 shrink-0 inline-flex items-center gap-2">
              <div className="w-4 h-4 shrink-0" />
              <div className="font-medium">IP address</div>
            </div>
            <div>{bridge.ip_address || '---'}</div>
          </div>
          <div className="my-3 border-b border-dashed border-light-stroke" />
          <div className="inline-flex">
            <div className="w-64 shrink-0 inline-flex items-center gap-2">
              <div className="w-4 h-4 shrink-0" />
              <div className="font-medium">MAC Address</div>
            </div>
            <div>{bridge.mac_address || 'Unavailable'}</div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default BridgeDetail
