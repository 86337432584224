import shallow from 'zustand/shallow'
import cx from 'classnames'

import { ResourceIcon } from 'icons/utils'
import MenuItem from 'shared/MenuItem'
import { channelMap } from 'utils/channel'
import { NotifChannel } from 'services/tags'
import useStore from 'store'

interface Props {
  activeChannel: NotifChannel
  onChannelChange: (channel: NotifChannel) => void
}

function RecipientChannels({ activeChannel, onChannelChange }: Props) {
  const stats = useStore(state => state.stat.stats, shallow)

  const renderChannel = (channelName: NotifChannel) => (
    <MenuItem
      className={cx(
        'mb-1 h-10',
        activeChannel === channelName && 'bg-light-hover'
      )}
      onClick={() => onChannelChange(channelName)}
    >
      <ResourceIcon
        type={channelMap[channelName].iconType}
        iconType="primary"
        className="w-4 h-4 shrink-0"
      />
      <div>{channelMap[channelName].label}</div>
    </MenuItem>
  )

  return (
    <div className="w-64 p-6 pl-0 pb-4 border-r border-light-stroke shrink-0">
      <div className="text-xs text-light-secondary uppercase mb-4">
        Recipient Channels
      </div>
      {renderChannel('phone')}
      {renderChannel('email')}
      {!!stats?.whatsapp.enabled && renderChannel('whatsapp')}
      {!!stats?.webhook_enabled && renderChannel('webhook')}
      {!!stats?.slack_enabled && renderChannel('slack')}
      {!!stats?.teams_enabled && renderChannel('microsoft_teams')}
      {!!stats?.pagerduty_enabled && renderChannel('pager_duty')}
    </div>
  )
}

export default RecipientChannels
