import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Book(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M11.6667 0C10.3667 0 8.96669 0.266667 8.00002 1C7.03335 0.266667 5.63335 0 4.33335 0C3.03335 0 1.63335 0.266667 0.666687 1V10.7667C0.666687 11.2 1.15335 11.0667 1.16669 11.0667C2.06669 10.6333 3.36669 10.3333 4.33335 10.3333C5.63335 10.3333 7.03335 10.6 8.00002 11.3333C8.90002 10.7667 10.5334 10.3333 11.6667 10.3333C12.7667 10.3333 13.9 10.5333 14.8334 11.0333C15.1067 11.1733 15.3334 10.9067 15.3334 10.7333V1C14.34 0.253333 12.9134 0 11.6667 0ZM14 9.33333C13.2667 9.1 12.4667 9 11.6667 9C10.5334 9 8.90002 9.43333 8.00002 10V2.33333C8.90002 1.76667 10.5334 1.33333 11.6667 1.33333C12.4667 1.33333 13.2667 1.43333 14 1.66667V9.33333Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
