import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Image(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M14 12.4444V1.55556C14 0.7 13.3 0 12.4444 0H1.55556C0.7 0 0 0.7 0 1.55556V12.4444C0 13.3 0.7 14 1.55556 14H12.4444C13.3 14 14 13.3 14 12.4444ZM4.27778 8.16667L6.22222 10.5078L8.94444 7L12.4444 11.6667H1.55556L4.27778 8.16667Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
