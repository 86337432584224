import { DragEventHandler, Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import { DeviceIcon } from 'icons/utils'
import { Delete, EyeOpen, More, Pencil } from 'icons/outline'
import { Popover, Tooltip } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalEditDevice from 'shared/Modals/Device/EditDevice'
import ModalConfirmDeleteWithInput from 'shared/Modals/Confirm/ConfirmDeleteWithInput'
import toast from 'utils/toast'
import { getDeviceStatus } from 'utils/device'
import { deleteDevice, Device } from 'services/devices'
import useStore, { Store } from 'store'

interface Props {
  device: Device
  selectedDevices: (Device | null)[]
}

const mapState = (state: Store) => ({
  removeDevice: state.device.removeDevice
})

function DeviceMenuItem({ device, selectedDevices }: Props) {
  const navigate = useNavigate()
  const [showMore, setShowMore] = useState(false)
  const [visible, setVisible] = useState('')

  const { removeDevice } = useStore(mapState, shallow)

  const { id, name, type } = device
  const status = getDeviceStatus(device)
  const color = status === 'online' ? 'text-success' : 'text-danger'

  const handleDelete = () => {
    setVisible('')
    deleteDevice(id)
    removeDevice(id)
    toast.success({ title: 'Device Deleted' }, { position: 'bottom-center' })
  }

  const renderMoreOptions = (onClose: () => void) => {
    const onClick = (fn: () => void) => () => {
      fn()
      onClose()
    }
    return (
      <Popover.Content onClose={onClose} className="w-[14.375rem]">
        <MenuItem onClick={() => navigate(`/devices/${id}?tab=device-details`)}>
          <EyeOpen className="w-4 h-4" /> View Device Detail
        </MenuItem>
        <MenuItem onClick={onClick(() => setVisible('edit'))}>
          <Pencil className="w-4 h-4 scale-90" /> Edit Device
        </MenuItem>
        <div className="border-b border-light-stroke my-2" />
        <MenuItem
          className="text-danger"
          onClick={onClick(() => setVisible('delete'))}
        >
          <Delete className="w-4 h-4" /> Delete Device
        </MenuItem>
      </Popover.Content>
    )
  }

  const handleDragStart: DragEventHandler<HTMLDivElement> = event => {
    event.dataTransfer!.setData('device', JSON.stringify(device))
  }

  return (
    <MenuItem
      draggable
      onDragStart={handleDragStart}
      className={cx(
        'group !gap-1.5 select-none !rounded-none !pr-0',
        selectedDevices.find(d => d?.id === device.id) && '!bg-light-bg'
      )}
    >
      <Tooltip
        className="w-5 h-5 ml-1.5 shrink-0"
        content={
          <div>
            This device is{' '}
            <span className={cx('font-semibold capitalize', color)}>
              {status}
            </span>
          </div>
        }
      >
        <DeviceIcon type={type} className={cx('w-5 h-5', color)} />
      </Tooltip>
      <div className="text-[0.8125rem] w-[10.625rem] whitespace-nowrap overflow-hidden text-ellipsis">
        {name}
      </div>
      <Popover
        className="shrink-0"
        placement="bottom-end"
        onVisibleChange={setShowMore}
        content={onClose => renderMoreOptions(onClose)}
      >
        <div className="w-4 h-4 flex items-center justify-center">
          <More
            className={cx(
              'base-transition ml-auto mr-[2px] opacity-0',
              'group-hover:opacity-100',
              showMore && '!opacity-100'
            )}
          />
        </div>
      </Popover>
      <AnimatePresence initial={false}>
        {visible === 'edit' && (
          <ModalEditDevice device={device} onClose={() => setVisible('')} />
        )}
        {visible === 'delete' && (
          <ModalConfirmDeleteWithInput
            modalTitle="Delete Device"
            warnText="After removed the device, all data of this device will be deleted"
            value={device.name}
            inputLabel={
              <Fragment>
                Enter device name <b>"{name}"</b> to delete
              </Fragment>
            }
            onConfirm={handleDelete}
            onClose={() => setVisible('')}
          />
        )}
      </AnimatePresence>
    </MenuItem>
  )
}

export default DeviceMenuItem
