import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Note({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M16 0H0V16H9.33333L16 9.33333V0Z"
          fill="url(#paint0_linear_1_1111)"
        />
        <rect
          x="2.66669"
          y="11.3333"
          width="10.6667"
          height="1.33333"
          fill="white"
          fillOpacity="0.7"
        />
        <path
          d="M9.33331 9.33334H16L9.33331 16V9.33334Z"
          fill="url(#paint1_linear_1_1111)"
        />
        <rect
          x="2.66669"
          y="3.33334"
          width="10.6667"
          height="1.33333"
          fill="white"
          fillOpacity="0.7"
        />
        <rect
          x="2.66669"
          y="7.33334"
          width="10.6667"
          height="1.33333"
          fill="white"
          fillOpacity="0.7"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_1111"
            x1="8"
            y1="0"
            x2="8"
            y2="16"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_1111"
            x1="9.66665"
            y1="9.66668"
            x2="12.6666"
            y2="12.6667"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
