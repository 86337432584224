import { Fragment } from 'react'
import { format } from 'date-fns'
import cx from 'classnames'
import shallow from 'zustand/shallow'

import { Humidity, TemperatureSensor } from 'icons/outline'
import { Tooltip } from 'components'
import { ctof, DEGREE_SYMBOL, formatSensorTemperature } from 'utils/device'
import { dateTimeFormat } from 'utils/dateTime'
import { Device } from 'services/devices'
import useStore, { Store } from 'store'

interface Props {
  device: Device
}

const mapState = (state: Store, deviceId: number) => ({
  sensor: state.device.sensors.find(({ id }) => deviceId === id),
  user: state.auth.currentUser
})

function TemperatureReport({ device }: Props) {
  const { sensor, user } = useStore(
    state => mapState(state, device.id),
    shallow
  )

  if (!sensor || !user) {
    return null
  }

  const { unit_temperature } = user
  const { temperatureSensorLogs: logs = [] } = sensor
  const latestLogs = logs.slice(Math.max(logs.length - 1, 0))
  const latestLog = latestLogs[0] || {}
  const isF = unit_temperature === 'f'
  const maxHeight = isF ? ctof(100) : 100

  const renderTooltip = (
    value: number,
    date: string,
    type: 'temperature' | 'humidity'
  ) => {
    return (
      <Fragment>
        <div className="text-[0.8125rem] text-[#A6A6A6] mb-1">
          {format(
            new Date(date),
            `${dateTimeFormat['MMM dd, yyyy']} • ${dateTimeFormat['hh:mm a']}`
          )}
        </div>
        <div className="text-base font-medium">
          {value}
          {type === 'humidity' ? '%' : DEGREE_SYMBOL[unit_temperature]}
        </div>
      </Fragment>
    )
  }

  return (
    <div className="relative w-full p-4" key={latestLog.created_at}>
      <div className="flex flex-col">
        <div className="flex items-center gap-1.5 mb-2">
          <TemperatureSensor className="w-4 h-4 shrink-0 text-[#dedede]" />
          <div className="text-base font-medium">Temperature Report</div>
        </div>
        <div className="text-2xl font-medium mb-3">
          {!latestLog.temperature
            ? '---'
            : formatSensorTemperature(latestLog.temperature, unit_temperature)}
        </div>
        <div className="relative grid grid-cols-[repeat(35,1fr)] gap-px w-full h-[130px] items-end overflow-hidden bg-[#fafafa]">
          {logs.map(({ id, created_at, ...d }, idx) => {
            const value = isF ? ctof(d.temperature) : d.temperature
            return (
              <Tooltip
                key={idx}
                content={renderTooltip(value, created_at, 'temperature')}
                style={{
                  height: Math.min((value / maxHeight) * 100, 100) + '%'
                }}
                className={cx(
                  'base-transition cursor-pointer',
                  d.alarms.some(a => a.condition_parameter === 'temperature')
                    ? 'bg-[#FFAFAF] hover:bg-danger'
                    : 'bg-[#D7F7DF] hover:bg-success'
                )}
              />
            )
          })}
          <div className="absolute bottom-[80%] left-0 right-0 border-b border-light-primary/25 border-dashed" />
          <div className="absolute bottom-[20%] left-0 right-0 border-b border-light-primary/25 border-dashed" />
          <div className="absolute bottom-0 left-0 right-0 h-px w-full bg-[#C2E5CB]" />
        </div>
      </div>
      <div className="flex flex-col mt-10">
        <div className="flex items-center gap-1.5 mb-2">
          <Humidity className="w-4 h-4 shrink-0 text-[#dedede]" />
          <div className="text-base font-medium">Humidity Report</div>
        </div>
        <div className="text-2xl font-medium mb-3">
          {!latestLog.humidity ? '---' : latestLog.humidity + '%'}
        </div>
        <div className="relative grid grid-cols-[repeat(35,1fr)] gap-px w-full h-[130px] items-end overflow-hidden bg-[#fafafa]">
          {logs.map(({ id, created_at, ...d }, idx) => {
            const value = d.humidity
            return (
              <Tooltip
                key={idx}
                content={renderTooltip(value, created_at, 'humidity')}
                style={{
                  height: Math.min((value / maxHeight) * 100, 100) + '%'
                }}
                className={cx(
                  'base-transition cursor-pointer',
                  d.alarms.some(a => a.condition_parameter === 'humidity')
                    ? 'bg-[#FFAFAF] hover:bg-danger'
                    : 'bg-[#D7F7DF] hover:bg-success'
                )}
              />
            )
          })}
          <div className="absolute bottom-[80%] left-0 right-0 border-b border-light-primary/25 border-dashed" />
          <div className="absolute bottom-[20%] left-0 right-0 border-b border-light-primary/25 border-dashed" />
          <div className="absolute bottom-0 left-0 right-0 h-px w-full bg-[#C2E5CB]" />
        </div>
      </div>
    </div>
  )
}

export default TemperatureReport
