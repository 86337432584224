import client from './client'

export interface BroadCastNotif {
  id: string
  read_at: string | null
  type: 'App\\Notifications\\PushNotification'
  created_at: {
    date: string
    timezone: string
    timezone_type: number
  }
  data: {
    level: 'info' | 'success' | 'warning'
    message: string
    target: string
    url: string | null
  }
}

export interface DeviceUpdate {
  device_id: number
  is_recording: boolean
  is_streaming: boolean
}

export interface SensorUpdate {
  created_at: string
  device_id: number
  humidity: number
  temperature: number
  alarms: {
    condition_parameter: 'temperature' | 'humidity'
    created_at: string
    device_id: number
    enable_device_notifications: number
    enable_push_notifications: number
    id: number
    operator: string
    pivot: any
    target_value: string
    updated_at: string
  }[]
}

export const authorize = (channelName: string) => {
  return {
    authorize: (socketId: string, callback: any) => {
      client
        .post('/api/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channelName
        })
        .then(response => callback(false, response.data))
        .catch(error => callback(true, error))
    }
  }
}
