import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Ruler({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M17.075 17.075L15.75 18.4L14.8625 17.5125L16.1875 16.1875L13.7625 13.7625L12.4375 15.0875L11.55 14.2L12.875 12.875L10.45 10.45L9.125 11.775L8.2375 10.8875L9.5625 9.5625L7.125 7.125L5.8 8.45L4.9125 7.5625L6.2375 6.2375L3.8125 3.8125L2.4875 5.1375L1.6 4.25L2.925 2.925L0 0V17.5C0 18.875 1.125 20 2.5 20H20L17.075 17.075ZM3.75 16.25V9.05L10.95 16.25H3.75Z"
          fill="#303030"
        />
        <path
          d="M17.075 17.075L15.75 18.4L14.8625 17.5125L16.1875 16.1875L13.7625 13.7625L12.4375 15.0875L11.55 14.2L12.875 12.875L10.45 10.45L9.125 11.775L8.2375 10.8875L9.5625 9.5625L7.125 7.125L5.8 8.45L4.9125 7.5625L6.2375 6.2375L3.8125 3.8125L2.4875 5.1375L1.6 4.25L2.925 2.925L0 0V17.5C0 18.875 1.125 20 2.5 20H20L17.075 17.075ZM3.75 16.25V9.05L10.95 16.25H3.75Z"
          fill="url(#paint0_linear_9779_1172)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_9779_1172"
            x1="0"
            y1="0"
            x2="0"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
