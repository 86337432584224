import { DeviceIconType } from 'icons/utils/DeviceIcon'

import { Location } from './locations'
import client from './client'

export interface DeviceLiveStream {
  audio_codec: null | string
  bitrate: null | number
  created_at: string
  device_id: number
  id: number
  is_recording: boolean
  is_streaming: boolean
  meta: null | {
    codec: null | string
    displaySize: null | string
    frameRate: null | string
    frameSize: null | string
    level: null | string
    profile: null | string
  }
  name: string
  playlist_url: null | string
  playlist_url_expiry_ms: number
  recording_start_timestamp: null | string
  recording_stop_timestamp: null | string
  stream_id: null | string
  streaming_start_timestamp: null | string
  streaming_stop_timestamp: null | string
  updated_at: null | string
  video_codec: null | string
}

export interface Device {
  account_id: number
  bridge_id: number | null
  code: string
  created_at: string
  deviceLiveStream: null | DeviceLiveStream
  email: string
  enable_recording: number
  id: number
  ip_address: string | null
  is_licensed: number
  location: null | Location
  location_id: number | null
  manufacturer: string | null
  name: string
  onvif_device_id: number | null
  password: string | null
  stream_error: null | string
  stream_url: null | string
  temperatureSensor?: {
    is_online: boolean
    latest_log: {
      created_at: string
      humidity: number
      id: number
      temperature: number
    }
  }
  thumbnail_url: null | string
  timezone: string
  type: DeviceIconType
  username: string | null
  __loading: boolean
}

export const getAllDevices = () => {
  return client.get<{ data: Device[] }>(
    '/api/devices?include=deviceLiveStream,location&limit=300'
  )
}

export const getDeviceDetails = (id: number) => {
  return client.get<{ data: Device }>(
    `/api/devices/${id}?include=deviceLiveStream,location`
  )
}

export const createDevice = async (payload: {
  device: {
    name: string
    type: string
    timezone: string
    location_id?: number
    account_id: number
    stream_url: string
    is_licensed: boolean
    enable_recording: boolean
  }
  deviceSchedules?: {
    is_active: boolean
    name: string
    is_24_7: boolean
    start_time?: string
    end_time?: string
    days: string[]
    channels: string[]
    webhook: string
    slack_webhook: string
    teams_webbhook: string
    pagerduty_integration_key: string
    email_recipient_ids: number[]
    phone_recipient_ids: number[]
    whatsapp_recipient_ids: number[]
  }[]
}) => {
  await client.get('/api/csrf-cookie')
  return client.post('/api/deviceCreateWizard', payload)
}

export const updateDevice = async (
  id: number,
  payload: {
    name?: string
    type?: string
    manufacturer?: string
    timezone?: string
    location_id?: string
    ip_address?: string
    account_id?: string
    stream_url?: string
    is_licensed?: boolean
    enable_recording?: boolean
  }
) => {
  await client.get('/api/csrf-cookie')
  return client.put(`api/devices/${id}`, payload)
}

export const deleteDevice = (id: number) => {
  return client.delete(`api/devices/${id}`)
}

// ------------------------------------------------------------------------- //

export interface SensorDevice {
  id: number
  is_online: boolean
  name: string
  type: string
  temperatureSensorLogs: {
    created_at: string
    humidity: number
    id: number
    temperature: number
    alarms: {
      condition_parameter: 'temperature' | 'humidity'
      created_at: string
      device_id: number
      enable_device_notifications: number
      enable_push_notifications: number
      id: number
      operator: string
      pivot: any
      target_value: string
      updated_at: string
    }[]
  }[]
}

export const getAllSensorDevices = () => {
  return client.get<{ data: SensorDevice[] }>(
    '/api/cards/temperatureSensorLogs'
  )
}

// ------------------------------------------------------------------------- //

export interface DeviceCloudStorage {
  cloud_storage_gb: {
    total: null | number
    used: null | number
  }
  estimated_retention_time: string | null
}

export const getDeviceCloudStorage = (id: number) => {
  return client.get<{ data: DeviceCloudStorage }>(
    `api/cards/devices/${id}/cloudStorage`
  )
}

// ------------------------------------------------------------------------- //

export interface DeviceSMTP {
  host: string
  password: string
  port: string
  sender_email: string
  to_email: string
  use_custom: number
  username: string
}

export const getDeviceSMTP = (id: number) => {
  return client.get<{ data: DeviceSMTP }>(`/api/cards/devices/${id}/smtpCard`)
}

// ------------------------------------------------------------------------- //

export const checkStreamUrl = (streamUrl: string) => {
  return client.post('/api/checkStream', { stream_url: streamUrl })
}
