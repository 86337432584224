import { usePageTitle } from 'hooks'
import TableList from './TableList'

function TransactionHistory() {
  usePageTitle('Transaction History | Broadflow Streams')
  return (
    <div className="p-4">
      <div className="text-2xl font-medium my-2">Transaction History</div>
      <TableList />
    </div>
  )
}

export default TransactionHistory
