import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import PoweredByStripe from 'assets/images/PoweredByStripe.png'
import { Shield } from 'icons/filled'
import { ChevronLeft } from 'icons/outline'
import { Button } from 'components'
import ModalAddEditCreditCard from 'shared/Modals/Billing/AddEditCreditCard'
import useStore from 'store'

import CheckoutPlan from './Plan'
import CheckoutSensor from './Sensor'
import CheckoutBridge from './Bridge'

interface RouteState {
  type?: 'plan' | 'sensor' | 'bridge'
}

function Checkout() {
  const { type } = (useLocation().state || {}) as RouteState
  const navigate = useNavigate()
  const sub = useStore(state => state.sub.subscription, shallow)

  if (!type) {
    return <Navigate to="/" replace />
  }

  if (!sub?.card) {
    return (
      <AnimatePresence initial={false}>
        <ModalAddEditCreditCard type="add" onClose={() => {}} />
      </AnimatePresence>
    )
  }

  return (
    <div className="relative w-full p-4 min-h-[calc(100vh_-_3.125rem)] bg-[#fafafa]">
      <div className="flex items-center justify-between gap-4 mb-6 mt-1">
        <div className="inline-flex items-center gap-2">
          <Button onClick={() => navigate(-1)}>
            <ChevronLeft />
          </Button>
          <div className="text-2xl font-medium">Checkout</div>
        </div>
        <div className="inline-flex items-center gap-6">
          <div className="inline-flex items-center gap-1">
            <Shield color="#bdbdbd" className="w-4 h-4 shrink-0" />
            <span className="text-[.8125rem] text-light-secondary">
              Your transaction is encrypted and secure
            </span>
          </div>
          <img
            className="max-w-[8.125rem] shrink-0"
            src={PoweredByStripe}
            alt="stripe"
          />
        </div>
      </div>
      {type === 'plan' && <CheckoutPlan />}
      {type === 'sensor' && <CheckoutSensor />}
      {type === 'bridge' && <CheckoutBridge />}
    </div>
  )
}

export default Checkout
