import { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { pick } from 'lodash'
import shallow from 'zustand/shallow'
import { AnimatePresence } from 'framer-motion'

import { useAsync } from 'hooks'
import { ChevronDown, ChevronLeft, Close } from 'icons/outline'
import { Modal, ModalProps, Popover } from 'components'
import ModalGuideBook from 'shared/Modals/GuideBook'
import PopoverHelpMenu from 'shared/Popovers/HelpMenu'
import toast from 'utils/toast'
import { createTag } from 'services/tags'
import useStore from 'store'

import TagDetails from './TagDetails'
import MatchingCriterias from './MatchingCriterias'
import AffectedDevices from './AffectedDevices'
import Recipients from './Recipients'
import { FormValues, FormKey, initFormValues, stepLabels } from './FormHelpers'

interface Props extends ModalProps {
  onSuccess?: () => void
}

function ModalCreateNewTag({ onSuccess, ...props }: Props) {
  const { show } = useIntercom()

  const [showGuideBook, setShowGuideBook] = useState(false)
  const [formValues, setFormValues] = useState(initFormValues)
  const [currentStep, setCurrentStep] = useState(0)

  const user = useStore(state => state.auth.currentUser, shallow)
  const { execute, isLoading } = useAsync({ showNotifOnError: true })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleStepChange = (step: number) => {
    setCurrentStep(prev => prev + step)
  }

  const handleGoBack = () => {
    currentStep === 0 ? handleClose() : handleStepChange(-1)
  }

  const handleSetValues = (key: FormKey) => (values: FormValues[FormKey]) => {
    setFormValues(prev => ({
      ...prev,
      [key]: values
    }))
  }

  const handleCreateTag = async (recs: FormValues['recipients']) => {
    handleSetValues('recipients')(recs)
    try {
      await execute(
        createTag({
          is_active: true,
          account_id: user!.account_id,
          ...formValues.tagDetails,
          ...formValues.matchingCriterias,
          apply_to_all: formValues.affectedDevices.apply_to_all,
          device_ids: formValues.affectedDevices.devices.map(d => d.value),
          email_recipient_ids: recs.email_recipients.map(r => r.value),
          phone_recipient_ids: recs.phone_recipients.map(r => r.value),
          whatsapp_recipient_ids: recs.whatsapp_recipients.map(r => r.value),
          ...pick(recs, [
            'is_blocked',
            'channels',
            'webhook',
            'slack_webhook',
            'teams_webbhook',
            'pagerduty_integration_key'
          ])
        })
      )
      toast.success({ title: 'Tag created' }, { position: 'bottom-center' })
      onSuccess?.()
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  const renderPopoverHelpMenu = (onClose: () => void) => (
    <PopoverHelpMenu
      onContactSupport={() => show()}
      onOpenGuideBook={() => setShowGuideBook(true)}
      onClose={onClose}
    />
  )

  return (
    <Modal
      {...props}
      wrapperClassName="!p-0"
      className="w-[100vw] min-h-[100vh] rounded-none !p-0"
      onClose={handleClose}
    >
      <div className="flex items-center gap-4 justify-between px-[5.3125rem] py-2 border-b border-light-stroke">
        <div className="inline-flex items-center gap-3">
          <ChevronLeft
            className="shrink-0 cursor-pointer"
            onClick={handleGoBack}
          />
          <div>
            <div className="text-base font-medium">Create Tag</div>
            <div className="text-xs text-light-secondary mt-[2px]">
              Step {currentStep + 1}: {stepLabels[currentStep]}
            </div>
          </div>
        </div>
        <div className="text-[0.8125rem] inline-flex items-center gap-4">
          <Popover
            placement="bottom-end"
            content={onClose => renderPopoverHelpMenu(onClose)}
          >
            <div className="text-primary inline-flex items-center gap-1 mr-4 text-[0.8125rem] font-medium cursor-pointer">
              Help <ChevronDown />
            </div>
          </Popover>
          <Close className="shrink-0 cursor-pointer" onClick={handleClose} />
        </div>
      </div>
      {currentStep === 0 && (
        <TagDetails
          values={formValues.tagDetails}
          onSubmit={handleSetValues('tagDetails')}
          onStepChange={handleStepChange}
        />
      )}
      {currentStep === 1 && (
        <MatchingCriterias
          values={formValues.matchingCriterias}
          onSubmit={handleSetValues('matchingCriterias')}
          onStepChange={handleStepChange}
        />
      )}
      {currentStep === 2 && (
        <AffectedDevices
          values={formValues.affectedDevices}
          onSubmit={handleSetValues('affectedDevices')}
          onStepChange={handleStepChange}
        />
      )}
      {currentStep === 3 && (
        <Recipients
          loading={isLoading}
          values={formValues.recipients}
          onSubmit={handleCreateTag}
        />
      )}
      <AnimatePresence initial={false}>
        {showGuideBook && (
          <ModalGuideBook onClose={() => setShowGuideBook(false)} />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default ModalCreateNewTag
