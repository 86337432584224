import { useState } from 'react'

import { ChevronRight } from 'icons/outline'
import { Button, Form, FormField, Input } from 'components'
import ScheduleDateTimeInput from 'shared/ScheduleDateTimeInput'
import { required } from 'utils/rules'

import { FormValues } from './FormHelpers'

interface Props {
  values: FormValues['scheduleDetails']
  onSubmit: (values: FormValues['scheduleDetails']) => void
  onStepChange: (step: number) => void
}

function ScheduleDetails({ values, onSubmit, onStepChange }: Props) {
  const [form] = Form.useForm()

  const [dateTime, setDateTime] = useState({
    is247: values.is_24_7,
    startTime: values.start_time,
    endTime: values.end_time,
    days: values.days
  })

  const handleSubmit = ({ name }: { name: string }) => {
    const { is247, startTime, endTime, days } = dateTime
    if (!is247 && (!startTime || !endTime || !days.length)) {
      return form.setFields([
        {
          name: 'dateTime',
          errors: ['Please tell us when you can received notification']
        }
      ])
    }
    onSubmit({
      name,
      is_24_7: is247,
      start_time: startTime,
      end_time: endTime,
      days
    })
    onStepChange(1)
  }

  return (
    <div className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center">
      <div className="w-[37.5rem] m-auto">
        <div className="text-base font-medium mb-6">Enter schedule detail</div>
        <Form form={form} initialValues={values} onFinish={handleSubmit}>
          <FormField
            name="name"
            label="Schedule name"
            rules={[required]}
            requiredMask
          >
            <Input size="large" placeholder="Name your schedule" />
          </FormField>
          <FormField
            name="dateTime"
            label="We will send notifications during the following times"
            requiredMask
          >
            <ScheduleDateTimeInput {...dateTime} onChange={setDateTime} />
          </FormField>
          <Button
            type="submit"
            variant="primary"
            size="large"
            className="w-40 mt-4"
          >
            Next <ChevronRight />
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default ScheduleDetails
