import { useCallback, useEffect, useRef, useState } from 'react'
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import LocationPin from 'assets/images/LocationPin.png'
import { libraries, defaultCenter, mapOptions } from 'configs/googleMaps'
import { Spinner } from 'components'
import { Location } from 'services/locations'
import useStore from 'store'

import { useLocationCtx } from '../LocationContext'

function LocationMap() {
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(defaultCenter)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    libraries
  })

  const locations = useStore(state => state.location.locations, shallow)
  const { selectedLocation, dispatch } = useLocationCtx()
  const mapRef = useRef<google.maps.Map>()

  useEffect(() => {
    navigator?.geolocation?.getCurrentPosition(({ coords }) =>
      setCenter({ lat: coords.latitude, lng: coords.longitude })
    )
  }, [])

  useEffect(() => {
    if (!!selectedLocation?.lat && !!selectedLocation?.lng) {
      panTo({ lat: +selectedLocation.lat, lng: +selectedLocation.lng })
    }
  })

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map
  }, [])

  const panTo = (latLng: google.maps.LatLngLiteral) => {
    mapRef.current?.panTo(latLng)
  }

  const handleMarkerClick = (location: Location) => {
    panTo({ lat: +location.lat, lng: +location.lng })
    dispatch({ selectedLocation: location, showLocationDetail: true })
  }

  const renderMarker = (location: Location) => {
    const { id, lat, lng, name } = location
    if (!!lat && !!lng) {
      const selected = selectedLocation?.id === id
      const scaledSize = selected ? 55 : 45
      return (
        <Marker
          key={id}
          position={{ lat: +lat, lng: +lng }}
          onClick={() => handleMarkerClick(location)}
          icon={{
            url: LocationPin,
            scaledSize: new google.maps.Size(scaledSize, scaledSize)
          }}
          label={{
            text: name,
            className: cx(
              'bg-white py-[0.1875rem] px-[0.375rem] !text-xs !text-light-primary shadow-[0_2px_2px_rgba(0,0,0,0.25)] rounded-3xl',
              selected
                ? 'translate-y-[-2.75rem] !text-sm'
                : 'translate-y-[-2.375rem]'
            )
          }}
        />
      )
    }
  }

  if (!!loadError || !isLoaded) {
    return (
      <div className="w-full bg-light-bg">
        {!!loadError && (
          <div className="flex items-center justify-center h-full">
            Map cannot be loaded right now, sorry.
          </div>
        )}
        {!isLoaded && <Spinner size="small" />}
      </div>
    )
  }

  return (
    <div className="w-full">
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        zoom={14}
        center={{
          lat: +(locations[0]?.lat || center.lat),
          lng: +(locations[0]?.lng || center.lng)
        }}
        options={mapOptions}
        onLoad={onMapLoad}
      >
        {locations.map(renderMarker)}
      </GoogleMap>
    </div>
  )
}

export default LocationMap
