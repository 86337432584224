import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Fullscreen(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <g clipPath="url(#clip0_738_85623)">
          <path
            d="M0.962672 -0.00146484C0.432597 -0.00146484 0.000244141 0.430888 0.000244141 0.960963L0.000244141 5.17196H1.92214L1.92214 1.92043L5.1707 1.92043V-0.00146484L0.962672 -0.00146484Z"
            fill="currentColor"
          />
          <path
            d="M15.0405 -0.000244141H10.8295V1.92165H14.0781V5.17022H16V0.962184C16 0.432108 15.5706 -0.000244141 15.0405 -0.000244141Z"
            fill="currentColor"
          />
          <path
            d="M14.0779 10.8296V14.0782H10.8293V16H15.0403C15.5704 16 16.0028 15.5677 16.0028 15.0376V10.8296H14.0779Z"
            fill="currentColor"
          />
          <path
            d="M1.9219 14.0782V10.8296H0L0 15.0406C0 15.5707 0.432352 16.003 0.962428 16.003H5.17342V14.0811H1.9219V14.0782Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_738_85623">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
