import { useState } from 'react'
import { Link } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import Available from 'assets/images/Available.png'
import DeviceImg from 'assets/images/Device.png'
import SensorLicenseImg from 'assets/images/SensorLicense.png'

import { Plus } from 'icons/outline'
import { DeviceIconType, DeviceIcon } from 'icons/utils/DeviceIcon'
import { Button, Dot } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalCreateDevice from 'shared/Modals/Device/CreateDevice'
import ModalSelectCameraLicenseCount from 'shared/Modals/Billing/SelectCameraLicenseCount'
import ModalSelectSensorCount from 'shared/Modals/Billing/SelectSensorCount'
import { cameraTypes, groupDevicesByType, sensorTypes } from 'utils/device'
import useStore, { Store } from 'store'

const types = [cameraTypes[0], sensorTypes[0], 'Other'] as DeviceIconType[]

const mapState = ({ auth, stat, device }: Store) => ({
  betaFeatures: auth.accessBetaFeatures(),
  stats: stat.stats,
  devices: device.devices
})

interface Props {
  selectedType: DeviceIconType
  onTypeChange: (type: DeviceIconType) => void
}

function SideList({ selectedType, onTypeChange }: Props) {
  const [visible, setVisible] = useState('')
  const { betaFeatures, stats, devices } = useStore(mapState, shallow)
  const grouppedDevices = groupDevicesByType(devices)

  const renderType = (type: DeviceIconType) => {
    const selected = selectedType === type
    return (
      <MenuItem
        key={type}
        onClick={() => onTypeChange(type)}
        className={cx(
          'mb-1 justify-between gap-2 flex-wrap',
          selected && 'bg-light-hover'
        )}
      >
        <div className="inline-flex items-center gap-2">
          <DeviceIcon
            type={type}
            className={cx(
              'base-transition w-5 h-5 shrink-0',
              selected ? 'text-primary' : 'text-[#BDBDBD]'
            )}
          />
          <div className="text-[0.8125rem]">{type}</div>
        </div>
        <Dot className="border border-light-stroke py-[3px] px-1.5 text-xs min-w-[1.375rem] shrink-0">
          {(grouppedDevices[type] || []).length}
        </Dot>
      </MenuItem>
    )
  }

  return (
    <div className="w-[19.5625rem] shrink-0 border-r border-light-stroke">
      <div className="m-4">
        <Button block onClick={() => setVisible('create-device')}>
          <Plus /> Create New Device
        </Button>
      </div>
      <PerfectScrollbar
        options={{ wheelPropagation: false }}
        className="p-4 pt-0 h-[calc(100%_-_4rem)]"
      >
        <div className="mb-8">
          <div className="uppercase text-xs text-light-secondary font-medium mb-2">
            Device Type
          </div>
          {types.map(type =>
            type !== 'Temperature Sensor' || betaFeatures
              ? renderType(type)
              : null
          )}
        </div>
        <div>
          <div className="uppercase text-xs text-light-secondary font-medium mb-2">
            Summary
          </div>
          <div className="p-2 text-[0.8125rem] flex justify-between items-center h-10 mb-1.5">
            <div className="inline-flex items-center gap-2">
              <img className="w-5" src={DeviceImg} alt="total devices" />
              <div>Total Devices:</div>
              <div className="font-semibold">
                {stats?.devices.used || 0}/{stats?.devices.total || 0}
              </div>
            </div>
            <Link
              to="/settings/billing/plans"
              className="text-primary font-medium cursor-pointer hover:underline"
            >
              Update
            </Link>
          </div>
          <div className="p-2 text-[0.8125rem] flex justify-between items-center h-10 mb-1.5">
            <div className="inline-flex items-center gap-2">
              <img className="w-5" src={Available} alt="camera licenses" />
              <div>Camera Licenses:</div>
              <div className="font-semibold">
                {stats?.camera_licenses.used || 0}/
                {stats?.camera_licenses.total || 0}
              </div>
            </div>
            <div
              className="text-primary font-medium cursor-pointer hover:underline"
              onClick={() => setVisible('buy-camera-license')}
            >
              Update
            </div>
          </div>
          {betaFeatures && (
            <div className="p-2 text-[0.8125rem] flex justify-between items-center h-10">
              <div className="inline-flex items-center gap-2">
                <img className="w-5" src={SensorLicenseImg} alt="sensor" />
                <div>Sensor Licenses:</div>
                <div className="font-semibold">
                  {stats?.temperature_sensors.used || 0}/
                  {stats?.temperature_sensors.total || 0}
                </div>
              </div>
              <div
                className="text-primary font-medium cursor-pointer hover:underline"
                onClick={() => setVisible('buy-sensor-license')}
              >
                Update
              </div>
            </div>
          )}
        </div>
      </PerfectScrollbar>
      <AnimatePresence initial={false}>
        {visible === 'create-device' && (
          <ModalCreateDevice onClose={() => setVisible('')} />
        )}
        {visible === 'buy-camera-license' && (
          <ModalSelectCameraLicenseCount onClose={() => setVisible('')} />
        )}
        {visible === 'buy-sensor-license' && (
          <ModalSelectSensorCount onClose={() => setVisible('')} />
        )}
      </AnimatePresence>
    </div>
  )
}

export default SideList
