import { useEffect, useState } from 'react'
import { format as formatDate } from 'date-fns'
import * as DProps from 'react-datepicker'
import cx from 'classnames'

import { Calendar, Close } from 'icons/outline'
import { Popover } from 'components'
import { dateTimeFormat } from 'utils/dateTime'

import Picker from './Picker'

export interface Props {
  showPresets?: boolean
  showTime?: boolean
  allowClear?: boolean
  size?: 'small' | 'default' | 'large'
  block?: boolean
  className?: string
  format?: string
  value?: Date | null
  placeholder?: string
  onChange?: (dateStr: string | null, date: Date | null) => void
  popupContainer?: () => HTMLElement
  pickerProps?: Partial<DProps.ReactDatePickerProps<any, any>>
}

const sizes = {
  small: 'h-6 py-0 pl-[0.4375rem] text-sm',
  default: 'h-8 py-1 pl-2.5 text-sm',
  large: 'h-10 pl-2.5 py-1.5 text-md'
}

export function DatePicker({
  showPresets,
  showTime,
  allowClear,
  size = 'default',
  block,
  className,
  format,
  value,
  placeholder,
  onChange,
  popupContainer,
  pickerProps
}: Props) {
  const [date, setDate] = useState<typeof value>(value)

  useEffect(() => {
    if (!!value) setDate(new Date(value))
  }, [(value || '').toString()])

  const defaultFormat =
    format ||
    `${dateTimeFormat['MM-dd-yyyy']} ${showTime ? dateTimeFormat['HH:mm'] : ''}`

  const handleDateChange = (date: Date | null) => {
    if (!date) return handleClear()
    setDate(date)
    onChange?.(formatDate(date, defaultFormat), date)
  }

  const handleClear = () => {
    setDate(null)
    onChange?.(null, null)
  }

  return (
    <div
      className={cx(
        'relative border border-light-stroke rounded-lg w-full',
        'base-transition hover:border-primary focus:border-primary outline-0',
        'flex items-center cursor-text bg-white',
        sizes[size],
        block && 'block',
        className
      )}
    >
      <input
        readOnly
        value={!!date ? formatDate(date, defaultFormat) : ''}
        placeholder={placeholder}
        className={cx(
          'appearance-none outline-0 w-full mr-1.5 pointer-events-none',
          'placeholder:text-light-secondary placeholder:font-normal'
        )}
      />
      {allowClear && !!date && (
        <div className="w-3 h-full shrink-0 inline-flex justify-center items-center z-[1]">
          <Close
            className="w-3 h-3 rounded-full bg-[#a7a7a7] p-[3px] text-white cursor-pointer"
            onClick={handleClear}
          />
        </div>
      )}
      <div className="w-8 h-full shrink-0 inline-flex justify-center items-center">
        <Calendar className="w-4 h-4 " />
      </div>
      <Popover
        popupContainer={popupContainer}
        placement="bottom-start"
        className="absolute top-0 left-0 w-full h-full"
        content={onClose => (
          <Picker
            {...pickerProps}
            showTime={showTime}
            showPresets={showPresets}
            selected={date}
            onChange={handleDateChange}
            onClose={onClose}
          />
        )}
      />
    </div>
  )
}
