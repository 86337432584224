import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Download(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M7.46958 12.0303C7.53923 12.1 7.62191 12.1552 7.7129 12.1929C7.8039 12.2306 7.90143 12.25 7.99993 12.25C8.09842 12.25 8.19595 12.2306 8.28695 12.1929C8.37794 12.1552 8.46063 12.1 8.53027 12.0303L11.3587 9.20191C11.4993 9.06125 11.5783 8.87049 11.5783 8.67158C11.5783 8.47267 11.4993 8.2819 11.3587 8.14125C11.218 8.0006 11.0273 7.92158 10.8283 7.92158C10.6294 7.92158 10.4387 8.0006 10.298 8.14125L8.74993 9.68934V1.5C8.74993 1.30109 8.67091 1.11032 8.53026 0.96967C8.3896 0.829018 8.19884 0.75 7.99993 0.75C7.80101 0.75 7.61025 0.829018 7.4696 0.96967C7.32894 1.11032 7.24993 1.30109 7.24993 1.5V9.68934L5.70183 8.14125C5.56118 8.0006 5.37042 7.92158 5.1715 7.92158C4.97259 7.92158 4.78183 8.0006 4.64118 8.14125C4.50053 8.2819 4.42151 8.47267 4.42151 8.67158C4.42151 8.87049 4.50053 9.06125 4.64118 9.20191L7.46958 12.0303Z"
          fill="currentColor"
        />
        <path
          d="M14.5 7.25C14.3011 7.25 14.1103 7.32902 13.9697 7.46967C13.829 7.61032 13.75 7.80109 13.75 8V13.75H2.25V8C2.25 7.80109 2.17098 7.61032 2.03033 7.46967C1.88968 7.32902 1.69891 7.25 1.5 7.25C1.30109 7.25 1.11032 7.32902 0.96967 7.46967C0.829018 7.61032 0.75 7.80109 0.75 8V14C0.75 14.3315 0.881696 14.6495 1.11612 14.8839C1.35054 15.1183 1.66848 15.25 2 15.25H14C14.3315 15.25 14.6495 15.1183 14.8839 14.8839C15.1183 14.6495 15.25 14.3315 15.25 14V8C15.25 7.80109 15.171 7.61032 15.0303 7.46967C14.8897 7.32902 14.6989 7.25 14.5 7.25Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
