import { Fragment, useEffect, useReducer, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import cx from 'classnames'

import {
  CheckedCircle,
  ClockCircle,
  Crown,
  Delete,
  EyeOpen,
  IgnoreCircle,
  Pencil
} from 'icons/outline'
import { useAsync } from 'hooks'
import { Avatar, Button, Table } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import ModalViewUserProfile from 'shared/Modals/UserRecipient/ViewUserProfile'
import ModalEditUserProfile from 'shared/Modals/UserRecipient/EditUserProfile'
import ModalConfirmDelete from 'shared/Modals/Confirm/ConfirmDelete'
import toast from 'utils/toast'
import { dateTimeFormat } from 'utils/dateTime'
import { getUsers, User, UserResponse, deleteUser } from 'services/users'

import TableToolbar from './TableToolbar'

interface State {
  sortBy: string
  sortDirection: string
  search: string
  pageSize: PageSizes
  currentPage: number
}

const initState: State = {
  sortBy: 'id',
  sortDirection: 'desc',
  search: '',
  pageSize: 10,
  currentPage: 1
}

function TableList() {
  const [viewUser, setViewUser] = useState<User | null>(null)
  const [editUser, setEditUser] = useState<User | null>(null)
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null)
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const userAsync = useAsync<AxiosResponse<UserResponse>>({
    status: 'pending',
    showNotifOnError: true
  })
  const deleteAsync = useAsync({
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetUsers()
  }, [...Object.values(state)])

  const handleGetUsers = () => {
    return userAsync.execute(getUsers(state))
  }

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const handleDeleteUser = async () => {
    if (!deleteUserId) return
    const id = deleteUserId
    setDeleteUserId(null)

    try {
      await deleteAsync.execute(deleteUser(id))
      toast.success({ title: 'User deleted' }, { position: 'bottom-center' })
      if (userAsync.data?.data.data.length === 1 && state.currentPage > 1) {
        return setState({ currentPage: state.currentPage - 1 })
      }
      handleGetUsers()
    } catch (err) {
      console.error(err)
    }
  }

  const renderUserCol = ({ avatar_url, name }: User) => (
    <div className="flex items-center gap-2">
      <Avatar key={avatar_url} size={28} src={avatar_url} username={name} />
      <span>{name}</span>
    </div>
  )

  const renderUserRoleCol = ({ is_admin }: User) => (
    <div className="flex items-center gap-1">
      {!!is_admin && <Crown className="shrink-0 text-warning" />}
      {is_admin ? 'Admin' : 'Standard User'}
    </div>
  )

  const render2FaCol = ({ enabled_2fa }: User) => (
    <div
      className={cx(
        'flex items-center gap-1 text-sm',
        enabled_2fa ? 'text-success' : 'text-light-secondary'
      )}
    >
      {enabled_2fa ? <CheckedCircle /> : <IgnoreCircle />}
      {enabled_2fa ? 'On' : 'Off'}
    </div>
  )

  const renderStatusCol = ({ email_verified_at: v }: User) => (
    <div
      className={cx(
        'flex items-center gap-1',
        v ? 'text-success' : 'text-warning'
      )}
    >
      {v ? <CheckedCircle /> : <ClockCircle className="w-3.5" />}
      {v ? 'Verified' : 'Verification pending'}
    </div>
  )

  const renderCreatedAtCol = ({ created_at }: User) => {
    return format(
      new Date(created_at),
      `${dateTimeFormat['MM-dd-yyyy']} ${dateTimeFormat['hh:mm a']}`
    )
  }

  const renderActionCol = (user: User) => (
    <div className="flex justify-end items-center gap-2">
      <Button
        variant="ternary"
        className="w-[5.3125rem]"
        onClick={() => setViewUser(user)}
      >
        <EyeOpen /> View
      </Button>
      <Button
        variant="ternary"
        className="w-[5.3125rem]"
        onClick={() => setEditUser(user)}
      >
        <Pencil className="scale-95" /> Edit
      </Button>
      <Button
        variant="ternary"
        className="w-[5.3125rem]"
        onClick={() => setDeleteUserId(user.id)}
      >
        <Delete /> Delete
      </Button>
    </div>
  )

  const columns = [
    {
      key: 'name',
      title: 'name',
      sorter: true,
      render: renderUserCol
    },
    {
      key: 'email',
      title: 'email',
      dataIndex: 'email'
    },
    {
      key: 'is_admin',
      title: 'user role',
      sorter: true,
      render: renderUserRoleCol
    },
    {
      key: 'enabled_2fa',
      title: '2fa',
      render: render2FaCol
    },
    {
      key: 'email_verified_at',
      title: 'status',
      sorter: true,
      render: renderStatusCol
    },
    {
      key: 'created_at',
      title: 'created at',
      sorter: true,
      render: renderCreatedAtCol
    },
    {
      width: 300,
      render: renderActionCol
    }
  ]

  const loading = userAsync.isLoading || deleteAsync.isLoading

  return (
    <Fragment>
      <TableToolbar
        loading={loading}
        search={state.search}
        onSearchChange={s => setState({ search: s, currentPage: 1 })}
        currentPage={state.currentPage}
        totalPage={userAsync.data?.data.meta.last_page || 1}
        onPageChange={p => setState({ currentPage: p })}
        onRefetch={handleGetUsers}
      />
      <Table
        rowKey="id"
        columns={columns}
        scroll={{ x: 1400 }}
        loading={loading}
        data={userAsync.data?.data.data}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={loading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={userAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
      <AnimatePresence initial={false}>
        {!!viewUser && (
          <ModalViewUserProfile
            user={viewUser}
            onEdit={setEditUser}
            onClose={() => setViewUser(null)}
          />
        )}
        {!!editUser && (
          <ModalEditUserProfile
            user={editUser}
            onSuccess={handleGetUsers}
            onClose={() => setEditUser(null)}
          />
        )}
        {!!deleteUserId && (
          <ModalConfirmDelete
            modalTitle="delete user"
            confirmText="Are you sure you want to delete this user?"
            warnText="Invitation link will be expired after this user is deleted"
            onClose={() => setDeleteUserId(null)}
            onConfirm={handleDeleteUser}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default TableList
