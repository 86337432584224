import { useCallback } from 'react'
import { GroupBase } from 'react-select'
import ReactAsyncSelect, { AsyncProps } from 'react-select/async'
import { debounce } from 'lodash'
import cx from 'classnames'

import { customComponents, Option } from './Select'

export interface Props
  extends Omit<AsyncProps<Option, boolean, GroupBase<Option>>, 'loadOptions'> {
  size?: 'large' | 'default'
  wait?: number
  loadOptions: (search: string) => Promise<Option[]>
}

export function AsyncSelect({
  size = 'default',
  wait = 300,
  className,
  loadOptions,
  ...props
}: Props) {
  const handleLoadOptions = useCallback(
    debounce((search, callback) => {
      loadOptions(search)
        .then(callback)
        .catch(() => callback([]))
    }, wait),
    []
  )

  return (
    <ReactAsyncSelect
      className={cx(
        'custom-select',
        size,
        props.isSearchable !== false && 'allow-search',
        className
      )}
      classNamePrefix="custom-select"
      menuPlacement="auto"
      closeMenuOnSelect={!!props.isMulti ? false : props.closeMenuOnSelect}
      components={customComponents}
      {...props}
      loadOptions={handleLoadOptions}
    />
  )
}
