import { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import BridgeImg from 'assets/images/Bridge-lg.png'
import BridgeCheckedImg from 'assets/images/BridgeChecked.png'

import { useAsync } from 'hooks'
import { Spinner } from 'components'
import CheckoutForm, { FormValues } from 'shared/Forms/CheckoutForm'
import ModalCheckoutSuccess from 'shared/Modals/Billing/CheckoutSuccess'
import { createOrder } from 'services/orders'
import useStore, { Store } from 'store'

type RouteState = {
  type: 'bridge'
  count: number
  id: number
}

const mapState = ({ product }: Store) => ({
  loading: product.loading,
  products: product.products,
  getProducts: product.getProducts
})

function CheckoutBridge() {
  const { count, id } = (useLocation().state || {}) as RouteState
  const [visible, setVisible] = useState(false)

  const { loading, products, getProducts } = useStore(mapState, shallow)
  const orderAsync = useAsync({ showNotifOnError: true })

  useEffect(() => {
    if (!products.length && !loading) getProducts()
  }, [])

  if (loading) {
    return <Spinner className="h-[80vh]" />
  }

  const selectedBridge = products.find(p => p.id === id)
  if (!selectedBridge || !count || count <= 0) return null

  const handleSubmit = async (values: FormValues) => {
    await orderAsync.execute(
      createOrder({
        full_name: values.name,
        phone_no: values.phone,
        street_address: values.address.value,
        street_address_2: values.address2,
        city: values.city,
        state: values.state,
        zip: values.zipcode,
        order_items: [{ product_id: selectedBridge.id, quantity: count }]
      })
    )
    setVisible(true)
  }

  return (
    <Fragment>
      <div className="grid grid-cols-[27rem_1rem_auto]">
        <div className="bg-white p-6 rounded-lg border border-[#eee] shadow-[0px_1px_2px_rgba(0,0,0,0.16)] self-start">
          <div className="text-base font-medium mb-5">Selected product</div>
          <div className="flex items-center gap-4 mb-6 bg-[#fafafa] p-4 rounded-lg">
            <img className="w-12 shrink-0" src={BridgeImg} alt="bridge" />
            <div className="font-medium text-sm">
              <div className="mb-1">
                Bridge <span className="text-success">x{count}</span>
              </div>
              <div className="text-light-secondary text-[0.8125rem]">
                {selectedBridge.name.match(/([\d a-zA-Z]+)/gm)?.[1]}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-1 text-[0.8125rem] mb-2.5">
            <div className="w-40">Price</div>
            <div className="font-medium">
              <span className="text-success">{count}</span> x $
              {selectedBridge.price_in_dollars}
            </div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-2" />
          <div className="flex items-center gap-1 text-[0.8125rem] mb-2.5">
            <div className="w-40">Shipping</div>
            <div className="font-medium text-success">Free</div>
          </div>
          <div className="border-b border-light-stroke my-2" />
          <div className="flex items-center gap-1 text-[0.8125rem]">
            <div className="w-40">Total to pay</div>
            <div className="font-medium text-[1.25rem]">
              ${selectedBridge.price_in_dollars * count}
            </div>
          </div>
        </div>
        <div />
        <div className="bg-white p-6 rounded-lg border border-[#eee] shadow-[0px_1px_2px_rgba(0,0,0,0.16)]">
          <CheckoutForm
            includeLocation
            disabled={orderAsync.isLoading}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
      <img
        src={BridgeCheckedImg}
        alt="bridge"
        className="w-0 h-0 fixed bottom-0 right-0 z-[-9999] invisible pointer-events-none"
      />
      <AnimatePresence initial={false}>
        {visible && (
          <ModalCheckoutSuccess
            onClose={() => void 0}
            imageUrl={BridgeCheckedImg}
            title="Thank you for using Broadflow"
            description={
              <div className="text-light-secondary">
                Your {count} bridge will be shipped in the next business day. A
                confirmation email will follow shortly.
              </div>
            }
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default CheckoutBridge
