import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Chart(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="10"
        viewBox="0 0 16 10"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M15.9022 8.34742L9.78827 0.19547C9.69594 0.0723891 9.55116 0 9.39733 0C9.24349 0 9.09865 0.0723891 9.00639 0.19547L6.47153 3.57534L5.46505 2.23338C5.37279 2.1103 5.22795 2.03791 5.07411 2.03791C4.92028 2.03791 4.7755 2.1103 4.68318 2.23338L0.097708 8.34742C-0.013319 8.49546 -0.031172 8.69366 0.051577 8.85923C0.134326 9.02472 0.303538 9.1293 0.488648 9.1293H15.5113C15.6964 9.1293 15.8656 9.02472 15.9484 8.85923C16.0312 8.6936 16.0133 8.49546 15.9022 8.34742ZM1.466 8.15195L5.07411 3.34104L6.08052 4.68301C6.17279 4.80609 6.31763 4.87848 6.47147 4.87848C6.6253 4.87848 6.77008 4.80609 6.86241 4.68301L9.39726 1.30313L14.534 8.15195H1.466Z"
          fill="currentColor"
        />
        <path d="M5.5 2.5L1 8.5H16L10 0.5L7 4.5L5.5 2.5Z" fill="currentColor" />
      </svg>
    </SVGUniqueID>
  )
}
