import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function FullscreenExit(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <g clipPath="url(#clip0_8627_2872)">
          <path
            d="M5.53708 6.46582C6.19967 6.46582 6.74011 5.92538 6.74011 5.26278L6.74011 -0.000957489L4.33774 -0.000957489L4.33774 4.06345L0.277036 4.06345L0.277036 6.46582L5.53708 6.46582Z"
            fill="currentColor"
          />
          <path
            d="M15.0131 6.46289L20.2769 6.46289V4.06052H16.2161V-0.00018549H13.8138V5.25986C13.8138 5.92245 14.3505 6.46289 15.0131 6.46289Z"
            fill="currentColor"
          />
          <path
            d="M16.2198 20V15.9393H20.2805V13.5369H15.0168C14.3542 13.5369 13.8137 14.0774 13.8137 14.74V20H16.2198Z"
            fill="currentColor"
          />
          <path
            d="M4.34116 15.9432V20.0039H6.74353L6.74353 14.7402C6.74353 14.0776 6.20309 13.5371 5.54049 13.5371H0.276752V15.9395H4.34116V15.9432Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_8627_2872">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0.276855)"
            />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
