import axios from 'axios'

import history from 'configs/history'
import useStore from 'store'

const BASE_URL = process.env.REACT_APP_BASE_URL
const AUTH_TOKEN = '5|J5bpgLU9soLbNAv9cxBPhRLf4hvKWshcF7pXtgbp'
// const TEST_TOKEN = '7|eazTZebe17pUohK4VVAtTl2OMzEHmdTEUcHXeFvj'

export interface ResponseError {
  status: number
  message: string
  errors?: { [key: string]: string[] }
}

export interface ResponseMeta {
  current_page: number
  from: number
  last_page: number
  links: any[]
  path: string
  per_page: number
  to: number
  total: number
}

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

const authInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

authInstance.interceptors.response.use(
  response => response,
  ({ response: { status, data } }) => {
    if (status === 401 || status === 419) {
      localStorage.setItem('APP/LAST_PATH', window.location.href)
      useStore.setState(({ auth }) => ({
        auth: { ...auth, currentUser: null }
      }))
      history.push('/login')
    }
    return Promise.reject<ResponseError>({
      status,
      message: data.message || data.error || '',
      errors: data.errors || undefined
    })
  }
)

if (process.env.NODE_ENV === 'development') {
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN
  authInstance.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN
}

export { authInstance as default, instance }
