import { AxiosResponse } from 'axios'
import { pick } from 'lodash'
import shallow from 'zustand/shallow'

import { Close } from 'icons/outline'
import { useAsync } from 'hooks'
import {
  Avatar,
  Button,
  Form,
  FormField,
  Input,
  Modal,
  ModalProps
} from 'components'
import toast from 'utils/toast'
import { email, required } from 'utils/rules'
import {
  Recipient,
  editRecipient,
  EditRecipientResponse
} from 'services/recipients'
import useStore from 'store'

interface Props extends ModalProps {
  recipient: Recipient
  onSuccess?: (updatedRec: Recipient) => void
}

interface FormValues {
  name: string
  email: string
  phone?: string
}

function ModalEditRecipientProfile({ recipient, onSuccess, ...props }: Props) {
  const user = useStore(state => state.auth.currentUser, shallow)
  const { execute, isLoading } = useAsync<AxiosResponse<EditRecipientResponse>>(
    { showNotifOnError: true }
  )

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      const response = await execute(
        editRecipient(recipient.id, {
          ...values,
          phone: values.phone || undefined,
          account_id: user!.account_id
        })
      )
      toast.success(
        { title: 'Recipient updated' },
        { position: 'bottom-center' }
      )
      onSuccess?.(response.data.data)
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal {...props} className="px-6 w-[34.375rem]" onClose={handleClose}>
      <div className="flex items-center justify-between gap-4 mb-6">
        <div className="text-base font-medium">Edit Recipient Profile</div>
        <Close className="cursor-pointer" onClick={handleClose} />
      </div>
      <div className="flex gap-4">
        <Avatar className="shrink-0" size={120} username={recipient.name} />
        <Form
          className="w-full"
          onFinish={handleSubmit}
          initialValues={pick(recipient, ['name', 'email', 'phone'])}
        >
          <FormField name="name" label="Name" rules={[required]} requiredMask>
            <Input block placeholder="Enter the full name of the recipient" />
          </FormField>
          <FormField
            name="email"
            label="Email"
            rules={[required, email]}
            requiredMask
          >
            <Input block placeholder="Enter the email address of the recipient" />
          </FormField>
          <FormField name="phone" label="Phone">
            <Input block placeholder="Enter the phone number of the recipient" />
          </FormField>
          <div className="flex items-center gap-2">
            <Button
              disabled={isLoading}
              type="submit"
              variant="primary"
              className="w-[7.5rem]"
            >
              Save
            </Button>
            <Button
              disabled={isLoading}
              type="button"
              className="w-[5rem]"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default ModalEditRecipientProfile
