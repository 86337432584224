import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { Pencil, Plus } from 'icons/outline'
import { CreditCardIcon } from 'icons/utils'
import { Button } from 'components'
import ModalAddEditCreditCard from 'shared/Modals/Billing/AddEditCreditCard'
import { Subscription } from 'services/subscriptions'

interface Props {
  card: Subscription['card']
}

function PaymentMethod({ card }: Props) {
  const [visible, setVisible] = useState<'add' | 'edit' | null>(null)

  return (
    <div className="mt-12">
      <div className="text-[0.9375rem] mb-4 font-medium">Payment Method</div>
      {!card && (
        <Button variant="primary" onClick={() => setVisible('add')}>
          <Plus /> Add Payment Method
        </Button>
      )}
      {card && (
        <div className="bg-white rounded-lg w-full xl:w-[15rem] shadow-[0_2px_2px_rgba(0,0,0,0.25),inset_0_-1px_rgba(255,255,255,0.1)]">
          <div className="p-4 flex items-center justify-between gap-3">
            <div className="inline-flex items-center gap-2">
              <CreditCardIcon
                type={card.brand}
                className="max-w-[2rem] shrink-0"
              />
              <div className="text-[0.9375rem] font-medium capitalize">
                {card.brand}
              </div>
            </div>
            <Button variant="ternary" onClick={() => setVisible('edit')}>
              <Pencil className="scale-[.8]" />
            </Button>
          </div>
          <div className="w-full h-6 bg-primary-lighten" />
          <div className="p-4 text-[0.8125rem]">********{card.last_4}</div>
        </div>
      )}
      <AnimatePresence initial={false}>
        {!!visible && (
          <ModalAddEditCreditCard
            type={visible}
            onClose={() => setVisible(null)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default PaymentMethod
