import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import { DoubleArrowDown } from 'icons/outline'
import { Button } from 'components'
import Popular from 'shared/Cards/Popular'
import PlanCard from 'shared/Cards/PlanCard'
import ModalConfirmDelete from 'shared/Modals/Confirm/ConfirmDelete'
import { Plan as IPlan } from 'services/subscriptions'
import useStore, { Store } from 'store'
import { PLAN_TYPE } from 'utils/plan'

interface Props {
  free: IPlan
  basic: IPlan
  plus: IPlan
  pro: IPlan
}

const mapState = ({ stat, sub }: Store) => ({
  stats: stat.stats,
  sub: sub.subscription
})

function PlanListCard({ free, basic, plus, pro }: Props) {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const { sub, stats } = useStore(mapState, shallow)

  const calcTotalPrice = (basePrice: number) => {
    return basePrice / 100
  }

  const isCurrentPlan = (planId: number) => {
    const currentPlanId = sub?.subscription?.plan_id || 1
    return planId === currentPlanId
  }

  const handleChoosePlan = (id: number) => {
    if (!isCurrentPlan(id)) {
      navigate('/checkout', {
        state: {
          type: 'plan',
          planId: id,
          count: stats?.camera_licenses.total || 0
        }
      })
    }
  }

  return (
    <Fragment>
      <div className="mt-[3rem] mb-[9.5rem]">
        <div className="flex justify-center flex-wrap max-w-5xl mx-auto">
          {/* FREE PLAN */}
          <PlanCard
            planType={PLAN_TYPE.FREE}
            label="Free"
            description="Notifications with schedules and recipients for 1 device"
            totalPrice={free.price / 100}
          >
            <Button
              size="large"
              variant={isCurrentPlan(free.id) ? 'secondary' : 'primary'}
              className="w-[11.5rem]"
              disabled={isCurrentPlan(free.id)}
              onClick={() => setVisible(true)}
            >
              {isCurrentPlan(free.id) ? 'Current Plan' : 'Choose Plan'}
            </Button>
          </PlanCard>
          {/* BASIC PLAN */}
          <PlanCard
            planType={PLAN_TYPE.BASIC}
            label="Basic"
            description="Best for individual users"
            totalPrice={calcTotalPrice(basic.price)}
          >
            <Button
              size="large"
              variant={isCurrentPlan(basic.id) ? 'secondary' : 'primary'}
              className="w-[11.5rem]"
              disabled={isCurrentPlan(basic.id)}
              onClick={() => handleChoosePlan(basic.id)}
            >
              {isCurrentPlan(basic.id) ? 'Current Plan' : 'Choose Plan'}
            </Button>
          </PlanCard>
          {/* PLUS PLAN */}
          <PlanCard
            planType={PLAN_TYPE.PLUS}
            label={
              <div className="flex items-center justify-center gap-2">
                Plus <Popular />
              </div>
            }
            description="Multiple users, enhanced support"
            totalPrice={calcTotalPrice(plus.price)}
          >
            <Button
              size="large"
              variant={isCurrentPlan(plus.id) ? 'secondary' : 'primary'}
              className="w-[11.5rem]"
              disabled={isCurrentPlan(plus.id)}
              onClick={() => handleChoosePlan(plus.id)}
            >
              {isCurrentPlan(plus.id) ? 'Current Plan' : 'Choose Plan'}
            </Button>
          </PlanCard>
          {/* PRO PLAN */}
          <PlanCard
            planType={PLAN_TYPE.PRO}
            label="Professional"
            description="Best plan for the pros"
            totalPrice={calcTotalPrice(pro.price)}
          >
            <Button
              size="large"
              variant={isCurrentPlan(pro.id) ? 'secondary' : 'primary'}
              className="w-[11.5rem]"
              disabled={isCurrentPlan(pro.id)}
              onClick={() => handleChoosePlan(pro.id)}
            >
              {isCurrentPlan(pro.id) ? 'Current Plan' : 'Choose Plan'}
            </Button>
          </PlanCard>
          {/* ENTERPRISE PLAN */}
          <PlanCard
            planType={PLAN_TYPE.ENTERPRISE}
            label="Enterprise"
            description="Amplify your notifications and video with one-on-one support and custom integration"
          >
            <Button
              size="large"
              variant="primary"
              className="w-[11.5rem]"
              onClick={() =>
                window.open('https://broadflow.co/contact', '_blank')
              }
            >
              Contact Us
            </Button>
          </PlanCard>
        </div>
        <div className="text-light-secondary text-center text-sm flex justify-center items-center mt-12">
          <span className="mr-2">Scroll down for Detailed plan comparison</span>{' '}
          <DoubleArrowDown />
        </div>
      </div>
      <AnimatePresence initial={false}>
        {visible && (
          <ModalConfirmDelete
            modalTitle="Warning"
            confirmText="Are you sure you want downgrade to free plan?"
            warnText="All of your devices and videos will be deleted"
            confirmButtonText="Ok"
            onConfirm={() =>
              navigate('/checkout', {
                state: { type: 'plan', planId: free.id, count: 0 }
              })
            }
            onClose={() => setVisible(false)}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default PlanListCard
