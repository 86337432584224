import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function ChevronDown(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="12"
        height="6"
        viewBox="0 0 12 6"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M5.62525 5.84654L0.905 1.12471C0.698333 0.917524 0.698333 0.581842 0.905 0.374129C1.11167 0.166939 1.44735 0.166939 1.65402 0.374129L5.99974 4.72142L10.3455 0.374652C10.5521 0.167462 10.8878 0.167462 11.095 0.374652C11.3017 0.581843 11.3017 0.918048 11.095 1.12524L6.37479 5.84706C6.17026 6.05107 5.8293 6.05107 5.62525 5.84654Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
