import create from 'zustand'

import authSlice, { AuthSlice } from './authSlice'
import configSlice, { ConfigSlice } from './configSlice'
import deviceSlice, { DeviceSlice } from './deviceSlice'
import bridgeSlice, { BridgeSlice } from './bridgeSlice'
import locationSlice, { LocationSlice } from './locationSlice'
import notifSlice, { NotifSlice } from './notifSlice'
import statSlice, { StatSlice } from './statSlice'
import subscriptionSlice, { SubscriptionSlice } from './subscriptionSlice'
import planSlice, { PlanSlice } from './planSlice'
import productSlice, { ProductSlice } from './productSlice'

export interface Store {
  auth: AuthSlice
  conf: ConfigSlice
  notif: NotifSlice
  stat: StatSlice
  device: DeviceSlice
  bridge: BridgeSlice
  location: LocationSlice
  sub: SubscriptionSlice
  plan: PlanSlice
  product: ProductSlice
}

const useStore = create<Store>((set, get) => ({
  auth: authSlice(set, get),
  conf: configSlice(set),
  device: deviceSlice(set, get),
  bridge: bridgeSlice(set),
  location: locationSlice(set),
  notif: notifSlice(set),
  stat: statSlice(set, get),
  sub: subscriptionSlice(set),
  plan: planSlice(set),
  product: productSlice(set)
}))

export default useStore
