import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Email({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M0.29223 0.851922C2.21368 2.47918 5.58521 5.34201 6.57615 6.23465C6.70918 6.35513 6.85186 6.41638 6.99998 6.41638C7.1478 6.41638 7.29023 6.3557 7.42296 6.2358C8.41475 5.34229 11.7863 2.47918 13.7078 0.851922C13.8274 0.750805 13.8456 0.57307 13.7488 0.449449C13.5249 0.163789 13.1911 0 12.8333 0H1.16666C0.808917 0 0.475078 0.163789 0.251214 0.449477C0.154363 0.57307 0.172601 0.750805 0.29223 0.851922Z"
          fill="url(#paint0_linear_1_605)"
        />
        <path
          d="M13.8308 1.73377C13.7274 1.68564 13.6058 1.70243 13.5198 1.77593C11.3889 3.58231 8.66939 5.89772 7.81375 6.66873C7.33351 7.10224 6.66701 7.10224 6.18565 6.66816C5.27362 5.84643 2.21966 3.25019 0.480211 1.7759C0.393613 1.7024 0.271715 1.68619 0.169176 1.73374C0.0660898 1.78162 0 1.88473 0 1.99837V9.33333C0 9.97678 0.523223 10.5 1.16668 10.5H12.8334C13.4768 10.5 14 9.97678 14 9.33333V1.99837C14 1.88473 13.9339 1.78134 13.8308 1.73377Z"
          fill="url(#paint1_linear_1_605)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_605"
            x1="0.189026"
            y1="0"
            x2="0.189026"
            y2="6.41638"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_605"
            x1="0"
            y1="1.70657"
            x2="0"
            y2="10.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
