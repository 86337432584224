import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatDistanceToNow } from 'date-fns'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import EmptyBox from 'assets/images/EmptyBox.png'
import { NotifIcon } from 'icons/utils'
import { Button, Dot, Popover, Spinner } from 'components'
import { downloadFile } from 'utils/file'
import { SystemNotif, markAllNotifAsRead } from 'services/notifications'
import useStore, { Store } from 'store'

interface Props {
  onClose: () => void
}

const mapState = ({ notif }: Store) => ({
  loading: notif.loading,
  notifs: notif.notifs,
  newNotifs: notif.newNotifs,
  currentPage: notif.currentPage,
  totalPage: notif.totalPage,
  getNotifs: notif.getNotifs,
  markAllAsRead: notif.markAllAsRead
})

function SystemNotifications({ onClose }: Props) {
  const store = useStore(mapState, shallow)

  const handleMarkAllAsRead = () => {
    markAllNotifAsRead()
    store.markAllAsRead()
  }

  const handleLoadMore = () => {
    if (store.currentPage < store.totalPage && !store.loading) {
      store.getNotifs(store.currentPage + 1)
    }
  }

  const renderNotif = (
    { data, read_at, created_at }: SystemNotif,
    idx: number
  ) => (
    <div key={idx} className="grid grid-cols-[2.25rem_auto_10px] gap-3 mb-4">
      <div
        className={cx(
          'w-9 h-9 rounded-full flex justify-center items-center',
          data.level === 'info' && 'bg-info-lighten',
          data.level === 'success' && 'bg-success-lighten',
          data.level === 'warning' && 'bg-warning-lighten'
        )}
      >
        <NotifIcon
          message={data.message}
          level={data.level}
          iconColor={
            data.level === 'info'
              ? '#55F5FF'
              : data.level === 'success'
              ? '#0B9830'
              : '#FFC123'
          }
          className={cx(
            'w-4 h-4',
            data.level === 'info' && 'text-info',
            data.level === 'success' && 'text-success',
            data.level === 'warning' && 'text-warning'
          )}
        />
      </div>
      <div>
        <div className="mb-0.5" style={{ wordBreak: 'break-word' }}>
          {data.message}
        </div>
        <div
          className={cx(
            'text-xs',
            !!read_at ? 'text-light-secondary' : 'text-primary'
          )}
        >
          {formatDistanceToNow(new Date(created_at), { addSuffix: true })}
        </div>
        {!!data.url && (
          <Button
            variant="primary"
            className="mt-[5px] w-[6.25rem]"
            onClick={() => downloadFile(data.url!)}
          >
            Download
          </Button>
        )}
      </div>
      <div>
        {!read_at && (
          <Dot className="w-2.5 h-2.5 bg-danger-gradient mt-[5px]" />
        )}
      </div>
    </div>
  )

  const hasNoti = !!store.notifs.length || !!store.newNotifs.length

  return (
    <Popover.Content onClose={onClose} className="w-[22.5rem] p-0">
      <div className="flex justify-between items-center p-4">
        <h4 className="text-base font-medium">System Notification</h4>
        {hasNoti && (
          <span
            className="text-[0.8125rem] text-primary font-medium cursor-pointer"
            onClick={handleMarkAllAsRead}
          >
            Mark all as read
          </span>
        )}
      </div>
      {hasNoti && (
        <PerfectScrollbar
          className="max-h-[31rem] h-full relative"
          options={{ wheelPropagation: false }}
          onYReachEnd={handleLoadMore}
        >
          {!!store.newNotifs.length && (
            <div className="mb-6 px-4">
              <h4 className="text-[0.8125rem] font-medium mb-2">New</h4>
              {store.newNotifs.map(renderNotif)}
            </div>
          )}
          <div className="px-4">
            <h4 className="text-[0.8125rem] font-medium mb-2">Earlier</h4>
            {store.notifs.map(renderNotif)}
          </div>
          {store.loading && <Spinner size="small" className="mb-2" />}
        </PerfectScrollbar>
      )}
      {!hasNoti && !store.loading && (
        <div className="px-4 py-6 text-center flex flex-col justify-center items-center">
          <img src={EmptyBox} alt="empty" className="w-[5rem]" />
          <div className="text-light-secondary mt-3 text-[0.8125rem]">
            There are no notification <br /> at the moment
          </div>
        </div>
      )}
      {!store.notifs.length && store.loading && (
        <Spinner size="small" className="h-80" />
      )}
    </Popover.Content>
  )
}

export default SystemNotifications
