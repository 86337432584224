import { useEffect, useReducer, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { pick } from 'lodash'
import { AxiosResponse } from 'axios'
import { AnimatePresence } from 'framer-motion'

import { ChevronDown } from 'icons/outline'
import { useAsync } from 'hooks'
import {
  Button,
  Modal,
  ModalProps,
  Tabs,
  Spinner,
  Form,
  Popover
} from 'components'
import PopoverHelpMenu from 'shared/Popovers/HelpMenu'
import ModalGuideBook from 'shared/Modals/GuideBook'
import toast from 'utils/toast'
import { getTagDetail, Tag, updateTag } from 'services/tags'

import TagDetails from './TagDetails'
import AffectedDevices from './AffectedDevices'
import Recipients from './Recipients'
import { FormValues, initFormValues, mapInitFormValues } from './FormHelpers'

interface Props extends ModalProps {
  tagId: number
  onSuccess?: () => void
}

function ModalEditTag({ tagId, onSuccess, ...props }: Props) {
  const { show } = useIntercom()
  const [tagDetailsform] = Form.useForm()

  const [showGuideBook, setShowGuideBook] = useState(false)
  const [formValues, setFormValues] = useReducer(
    (s: FormValues, a: Partial<FormValues>) => ({ ...s, ...a }),
    initFormValues
  )

  const tagAsync = useAsync<AxiosResponse<{ data: Tag }>>({
    status: 'pending',
    showNotifOnError: true
  })
  const updateAsync = useAsync({
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetTagDetail()
  }, [])

  const handleGetTagDetail = async () => {
    try {
      const { data } = await tagAsync.execute(
        getTagDetail(
          tagId,
          'devices,emailRecipients,phoneRecipients,whatsappRecipients'
        )
      )
      setFormValues(mapInitFormValues(data.data))
    } catch (err) {
      console.error(err)
    }
  }

  const handleClose = () => {
    !updateAsync.isLoading && props.onClose()
  }

  const handleSubmit = () => {
    const { tagDetails } = formValues
    let tagDetailErrors = []

    if (!tagDetails.name) {
      tagDetailErrors.push({ name: 'name', errors: ['This field is required'] })
    }
    if (tagDetails.search_in === 'subject_or_body') {
      if (!tagDetails.body_keywords && !tagDetails.subject_keywords) {
        tagDetailErrors.push({
          name: 'body_keywords',
          errors: ['Please choose at least one criteria']
        })
        tagDetailErrors.push({
          name: 'subject_keywords',
          errors: ['Please choose at least one criteria']
        })
      }
    }
    if (tagDetails.search_in === 'subject_and_body') {
      if (!tagDetails.subject_keywords) {
        tagDetailErrors.push({
          name: 'subject_keywords',
          errors: ['This field is required']
        })
      }
      if (!tagDetails.body_keywords) {
        tagDetailErrors.push({
          name: 'body_keywords',
          errors: ['This field is required']
        })
      }
    }
    if (!!tagDetailErrors.length) {
      toast.error({
        title: 'Invalid form',
        description: 'The provided data is not valid'
      })
      return tagDetailsform.setFields(tagDetailErrors)
    }
    handleUpdateTag()
  }

  const handleUpdateTag = async () => {
    try {
      const { tagDetails, affectedDevices, recipients } = formValues
      await updateAsync.execute(
        updateTag(tagId, {
          ...tagDetails,
          apply_to_all: affectedDevices.apply_to_all,
          device_ids: affectedDevices.devices.map(d => d.value),
          email_recipient_ids: recipients.email_recipients.map(r => r.value),
          phone_recipient_ids: recipients.phone_recipients.map(r => r.value),
          whatsapp_recipient_ids: recipients.whatsapp_recipients.map(
            r => r.value
          ),
          ...pick(recipients, [
            'is_blocked',
            'channels',
            'webhook',
            'slack_webhook',
            'teams_webbhook',
            'pagerduty_integration_key'
          ])
        })
      )
      toast.success({ title: 'Tag updated' }, { position: 'bottom-center' })
      onSuccess?.()
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  const renderPopoverHelpMenu = (onClose: () => void) => (
    <PopoverHelpMenu
      onContactSupport={() => show()}
      onOpenGuideBook={() => setShowGuideBook(true)}
      onClose={onClose}
    />
  )

  return (
    <Modal
      {...props}
      wrapperClassName="!p-0"
      className="w-[100vw] min-h-[100vh] rounded-none !p-0"
      onClose={handleClose}
    >
      <div className="pt-3 px-[5.3125rem] flex items-center justify-between gap-4 mb-3">
        <div className="text-base font-medium">Update Tag</div>
        <div className="inline-flex items-center gap-2">
          <Popover
            placement="bottom-end"
            content={onClose => renderPopoverHelpMenu(onClose)}
          >
            <div className="text-primary inline-flex items-center gap-1 mr-4 text-[0.8125rem] font-medium cursor-pointer">
              Help <ChevronDown />
            </div>
          </Popover>
          <Button
            disabled={updateAsync.isLoading}
            className="w-20"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={tagAsync.isLoading || updateAsync.isLoading}
            onClick={handleSubmit}
            className="w-[7.5rem]"
            variant="primary"
          >
            Save
          </Button>
        </div>
      </div>
      {tagAsync.isLoading && <Spinner className="min-h-[90vh]" />}
      {!tagAsync.isLoading && !!formValues.id && (
        <Tabs className="modal-tabs">
          <Tabs.TabPane key={1} tab="Tag Details">
            <TagDetails
              form={tagDetailsform}
              values={formValues.tagDetails}
              setValues={setFormValues}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key={2} tab="Affected Devices">
            <AffectedDevices
              values={formValues.affectedDevices}
              setValues={setFormValues}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key={3} tab="Recipients">
            <Recipients
              values={formValues.recipients}
              setValues={setFormValues}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
      <AnimatePresence initial={false}>
        {showGuideBook && (
          <ModalGuideBook onClose={() => setShowGuideBook(false)} />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default ModalEditTag
