import client from 'services/client'
import { Account } from './accounts'

export interface CurrentUser {
  account: Account
  account_id: number
  avatar: string | null
  avatar_url: string | null
  created_at: string
  email: string
  email_verified_at: string
  enable_promotional_notifications: number
  enable_push_notifications: boolean
  enabled_2fa: boolean
  id: number
  is_admin: number
  is_super: number
  name: string
  unit_distance: 'm' | 'ft'
  unit_temperature: 'c' | 'f'
  updated_at: string
}

export const getCurrentUser = () => {
  return client.get<{ data: CurrentUser }>('/api/users/auth?include=account')
}

export const resendVerify = () => {
  return client.post('/api/email/verification-notification')
}

export const verifyEmail = (payload: { id: string; hash: string }) => {
  return client.get(`/api/email/verify/${payload.id}/${payload.hash}`)
}

export const login = async (payload: { email: string; password: string }) => {
  await client.get('/api/csrf-cookie')
  return await client.post<{ two_factor: boolean }>('/api/login', payload)
}

export const logout = async () => {
  await client.get('/api/csrf-cookie')
  await client.post('/api/logout')
}

export const register = (payload: {
  name: string
  email: string
  password: string
  password_confirmation: string
  planId?: number
}) => {
  return client.post('/api/register', payload)
}

export const forgotPassword = (payload: { email: string }) => {
  return client.post('/api/forgot-password', payload)
}

export const resetPassword = (payload: {
  email: string
  password: string
  password_confirmation: string
  token: string
}) => {
  return client.post('/api/reset-password', payload)
}

export const confirmPassword = (payload: { password: string }) => {
  return client.post('/api/confirm-password', payload)
}

export const changePassword = (payload: { password: string }) => {
  return client.put('/api/users/auth', payload)
}

export const uploadAvatar = (img: File) => {
  const formData = new FormData()
  formData.append('avatar', img, img.name)
  formData.append('_method', 'PUT')
  return client.post<{ data: CurrentUser }>('/api/users/auth', formData)
}

export const updateUsername = (payload: { name: string }) => {
  return client.put('/api/users/auth', payload)
}

export const updatePromoNotif = (payload: number) => {
  return client.put('/api/users/auth', {
    enable_promotional_notifications: payload
  })
}

export const updateUnit = (payload: {
  unit_distance?: CurrentUser['unit_distance']
  unit_temperature?: CurrentUser['unit_temperature']
}) => {
  return client.put('/api/users/auth', payload)
}

export const getUserAccessToken = (payload: {
  email: string
  password: string
  device_name: string
}) => {
  return client.post('/api/token', payload)
}
