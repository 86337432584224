import { forwardRef } from 'react'
import cx from 'classnames'

import { useLockScroll } from 'hooks'

interface Props {
  lockScroll?: boolean
  zIndex?: number
}

export const Overlay = forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements['div'] & Props
>((props, ref) => {
  const { children, className, lockScroll, zIndex, ...rest } = props

  useLockScroll(lockScroll)

  return (
    <div
      {...rest}
      ref={ref}
      style={{ ...props.style, zIndex }}
      className={cx(
        'fixed top-0 right-0 bottom-0 left-0 bg-light-overlay z-overlay',
        className
      )}
    >
      {children}
    </div>
  )
})
