import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import { Close, Plus } from 'icons/outline'
import { useAsync } from 'hooks'
import {
  Button,
  Form,
  FormField,
  Input,
  Modal,
  ModalProps,
  Select
} from 'components'
import ModalCreateUpdateLocation from 'shared/Modals/Location/CreateUpdateLocation'
import { required } from 'utils/rules'
import toast from 'utils/toast'
import { Bridge as IBridge, updateBridge } from 'services/bridges'
import useStore, { Store } from 'store'

interface Props extends ModalProps {
  bridge: IBridge
}

interface FormValues {
  name: string
  location?: { value: number; label: string }
}

const mapState = (state: Store) => ({
  locations: state.location.locations,
  updateBridge: state.bridge.updateBridge
})

function ModalEditBridge({ bridge, ...props }: Props) {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)

  const { locations, ...store } = useStore(mapState, shallow)
  const { execute, isLoading } = useAsync<AxiosResponse<{ data: IBridge }>>({
    showNotifOnError: true
  })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleSubmit = async ({ name, location }: FormValues) => {
    await execute(
      updateBridge({
        id: bridge.id,
        name,
        location_id: location?.value
      })
    )
    store.updateBridge({
      id: bridge.id,
      name,
      location_id: location?.value,
      location: locations.find(l => l.id === location?.value)
    })
    toast.success({ title: 'Bridge updated' }, { position: 'bottom-center' })
    handleClose()
  }

  return (
    <Modal {...props} className="w-[31.25rem] !p-0" onClose={handleClose}>
      <div className="flex items-center justify-between border-b border-light-stroke p-4">
        <div className="text-base font-medium capitalize">Edit Bridge</div>
        <Close className="cursor-pointer" onClick={handleClose} />
      </div>
      <Form
        className="p-4"
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          name: bridge.name,
          location: { label: bridge.location?.name, value: bridge.location?.id }
        }}
      >
        <FormField
          name="name"
          label="Bridge Name"
          rules={[required]}
          requiredMask
        >
          <Input placeholder="Name the Bridge" />
        </FormField>
        <FormField
          name="location"
          label={
            <div className="flex items-center justify-between">
              <div>Device Location</div>
              <div
                className="inline-flex items-center text-primary cursor-pointer gap-1"
                onClick={() => setVisible(true)}
              >
                <Plus className="w-3 h-3" /> Create Location
              </div>
            </div>
          }
        >
          <Select
            placeholder="Select bridge location"
            options={locations.map(l => ({ label: l.name, value: l.id }))}
          />
        </FormField>
        <div className="w-full flex justify-end">
          <Button
            variant="primary"
            className="w-[6.25rem]"
            disabled={isLoading}
          >
            Save
          </Button>
        </div>
      </Form>
      <AnimatePresence initial={false}>
        {visible && (
          <ModalCreateUpdateLocation onClose={() => setVisible(false)} />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default ModalEditBridge
