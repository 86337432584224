import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { AxiosResponse } from 'axios'
import cx from 'classnames'
import shallow from 'zustand/shallow'

import { ArrowFall, ArrowRise, InfoCircle } from 'icons/outline'
import { useAsync } from 'hooks'
import { SelectButton, Select, Button, Spinner } from 'components'
import NotifSummaryChart from 'shared/Chart/NotiSummaryChart'
import { dateTimeFormat } from 'utils/dateTime'
import {
  NotifSumType,
  NotifSumResponse,
  getNotifSum
} from 'services/notifications'
import useStore, { Store } from 'store'

const mapState = ({ device }: Store) => ({
  devices: device.devices
})

function NotifSummary() {
  const [type, setType] = useState<NotifSumType>('inbound')
  const [deviceId, setDeviceId] = useState<number | undefined>(undefined)
  const [expanded, setExpanded] = useState(true)

  const { devices } = useStore(mapState, shallow)
  const notifSum = useAsync<AxiosResponse<NotifSumResponse>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    notifSum
      .execute(getNotifSum(type, deviceId))
      .then(() => window.dispatchEvent(new Event('resize')))
  }, [type, deviceId])

  const selectButtonItem = (value: string, className: string) => {
    const Icon = value === 'inbound' ? ArrowFall : ArrowRise
    const active = value === type
    const itemClass = cx(className, 'capitalize', active && 'border-primary')

    return (
      <SelectButton.Item value={value} className={itemClass}>
        <Icon className={cx('w-3 h-3', !active && 'text-light-secondary')} />
        <span>{value}</span>
      </SelectButton.Item>
    )
  }

  const handleNotifTypeChange = (newType: string) => {
    setType(newType as NotifSumType)
  }

  const sumData = Object.values(notifSum.data?.data.data || {})

  return (
    <div className="mb-10">
      <div className="flex flex-wrap gap-3 justify-between items-center">
        <div className="font-medium text-base">
          Device Notifications (
          {format(new Date(), dateTimeFormat['MMM dd, yyyy'])})
        </div>
        <div className="flex items-center gap-2">
          <SelectButton
            value={type}
            className="gap-0"
            onChange={handleNotifTypeChange}
          >
            {selectButtonItem('inbound', 'rounded-r-none')}
            {selectButtonItem('outbound', 'rounded-l-none')}
          </SelectButton>
          <Select
            isClearable
            className="w-[13rem]"
            placeholder="All devices"
            options={devices.map(d => ({ value: d.id, label: d.name }))}
            onChange={(v: any) => setDeviceId(v?.value)}
          />
        </div>
      </div>
      <div className="mt-3 w-full bg-primary-gradient rounded-lg p-4">
        <div className="flex justify-between">
          <div className="text-white text-[0.9375rem] self-start">
            Total Notifications
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1 bg-white/10 rounded-lg p-2 text-[0.8125rem] text-white">
              <InfoCircle />
              This data represents all notifications from{' '}
              {format(new Date(), dateTimeFormat['MMM dd, yyyy'])}
            </div>
            <Button
              variant="ternary"
              className="w-[5.9375rem]"
              onClick={() => setExpanded(prev => !prev)}
            >
              Show {expanded ? 'Less' : 'More'}
            </Button>
          </div>
        </div>
        <div className="text-white text-[2rem] font-medium leading-none mb-4">
          {notifSum.isLoading
            ? '---'
            : sumData.reduce((acc, cur) => (acc += cur), 0)}
        </div>
        <div
          className="base-transition overflow-hidden"
          style={{ height: expanded ? 300 : 0, opacity: expanded ? 1 : 0 }}
        >
          {notifSum.isLoading ? (
            <Spinner className="!text-white" />
          ) : (
            <NotifSummaryChart data={sumData} />
          )}
        </div>
      </div>
    </div>
  )
}

export default NotifSummary
