import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Pencil(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M0 9.49965V12H2.50034L9.87802 4.62229L7.37767 2.12195L0 9.49965Z"
          fill="currentColor"
        />
        <path
          d="M11.805 1.7519L10.2481 0.195027C9.98808 -0.065009 9.56468 -0.065009 9.30464 0.195027L8.08447 1.4152L10.5848 3.91554L11.805 2.69537C12.0651 2.43534 12.0651 2.01193 11.805 1.7519Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
