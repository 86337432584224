import * as Outline from 'icons/outline'
import * as Filled from 'icons/filled'

export interface Navigation {
  key: string
  label: string
  navLink?: string
  query?: string
  betaFeature?: boolean
  Icon: (props: any) => JSX.Element
  children?: {
    key: string
    label: string
    navLink: string
  }[]
}

export const collapsedMenu: Navigation[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    navLink: '/',
    Icon: Outline.Dashboard
  },
  {
    key: 'livestream',
    label: 'Livestream',
    navLink: '/livestream',
    Icon: Outline.MonitorLive
  },
  {
    key: 'Playback',
    label: 'Playback',
    navLink: '/playback',
    Icon: Outline.ClockCircle
  },
  {
    key: 'devices',
    label: 'Devices',
    navLink: '/devices',
    query: '?tab=device-list',
    Icon: Outline.VideoCamera
  },
  {
    key: 'iot-automation',
    label: 'IoT Automation',
    navLink: '/iot-automation',
    betaFeature: true,
    Icon: Outline.Send
  },
  {
    key: 'notifications',
    label: 'Notifications',
    navLink: '/notifications',
    query: '?tab=all-device-notification',
    Icon: Outline.Notification
  },
  {
    key: 'cloud-storage',
    label: 'Cloud Storage',
    navLink: '/cloud-storage',
    query: '?tab=video-search',
    Icon: Outline.CloudUpload
  },
  {
    key: 'user-recipient',
    label: 'Users & Recipients',
    navLink: '/user-recipient',
    query: '?tab=user-management',
    Icon: Outline.User
  },
  {
    key: 'activity-log',
    label: 'Activity Log',
    navLink: '/activity-log',
    Icon: Outline.UnorderedList
  }
]

export const expandedMenu: Navigation[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    navLink: '/',
    Icon: Outline.Dashboard
  },
  {
    key: 'livestream',
    label: 'Live Stream',
    navLink: '/livestream',
    Icon: Outline.MonitorLive
  },
  {
    key: 'Playback',
    label: 'Playback',
    navLink: '/playback',
    Icon: Outline.ClockCircle
  },
  {
    key: 'devices',
    label: 'Devices',
    Icon: Outline.VideoCamera,
    children: [
      {
        key: 'device-list',
        label: 'Device List',
        navLink: '/devices?tab=device-list'
      },
      {
        key: 'device-location',
        label: 'Device Location',
        navLink: '/devices?tab=device-location'
      }
    ]
  },
  {
    key: 'iot-automation',
    label: 'IoT Automation',
    navLink: '/iot-automation',
    betaFeature: true,
    Icon: Outline.Send
  },
  {
    key: 'notifications',
    label: 'Notifications',
    Icon: Outline.Notification,
    children: [
      {
        key: 'all-device-notification',
        label: 'Device Notifications',
        navLink: '/notifications?tab=all-device-notification'
      },
      {
        key: 'notification-tag',
        label: 'Tag Management',
        navLink: '/notifications?tab=notification-tag'
      }
    ]
  },
  {
    key: 'cloud-storage',
    label: 'Cloud Storage',
    Icon: Outline.CloudUpload,
    children: [
      {
        key: 'clould-storage',
        label: 'Video Search',
        navLink: '/cloud-storage?tab=video-search'
      },
      {
        key: 'my-vault',
        label: 'My Vault',
        navLink: '/cloud-storage?tab=my-vault'
      }
    ]
  },
  {
    key: 'user-recipient',
    label: 'Users & Recipients',
    Icon: Outline.User,
    children: [
      {
        key: 'user-management',
        label: 'User Management',
        navLink: '/user-recipient?tab=user-management'
      },
      {
        key: 'recipient-management',
        label: 'Recipient Management',
        navLink: '/user-recipient?tab=recipient-management'
      }
    ]
  },
  {
    key: 'activity-log',
    label: 'Activity Log',
    navLink: '/activity-log',
    Icon: Outline.UnorderedList
  }
]

export const userMenu: Navigation[] = [
  {
    key: 'settings',
    label: 'Settings',
    navLink: '/settings/general',
    Icon: Outline.Gear
  }
]

export const userSettingsMenu: Navigation[] = [
  {
    key: 'general',
    label: 'General',
    navLink: '/settings/general',
    Icon: Filled.Gear
  },
  {
    key: 'security-login',
    label: 'Security & Login',
    navLink: '/settings/security-login',
    Icon: Filled.Shield
  },
  {
    key: 'billing',
    label: 'Plan & Billing',
    navLink: '/settings/billing',
    Icon: Filled.CreditCard
  },
  {
    key: 'notifications',
    label: 'Notifications',
    navLink: '/settings/notifications',
    Icon: Filled.Bell
  },
  {
    key: 'unit',
    label: 'Unit',
    navLink: '/settings/unit',
    Icon: Filled.Ruler
  }
]
