import { Fragment, useState } from 'react'
import DatePicker, * as DProps from 'react-datepicker'
import { format as formatDate } from 'date-fns'
import cx from 'classnames'

import { Calendar, ChevronLeft, ChevronRight, Close } from 'icons/outline'
import { Button, Popover } from 'components'
import { dateRangePickerPresets, dateTimeFormat } from 'utils/dateTime'

type DateValues = (Date | null)[]
type DateValue = Date | null | undefined

interface Props {
  showPresets?: boolean
  showTime?: boolean
  allowClear?: boolean
  size?: 'small' | 'default' | 'large'
  block?: boolean
  className?: string
  format?: string
  value?: DateValues
  placeholder?: [string, string]
  onChange?: (dateStr: DateValues) => void
  popupContainer?: () => HTMLElement
}

interface IPresetItem {
  label: string
  onClick: () => void
  active?: boolean
}

const sizes = {
  small: 'h-6 py-0 pl-[0.4375rem] text-sm',
  default: 'h-8 py-1 pl-2.5 text-sm',
  large: 'h-10 pl-2.5 py-1.5 text-md'
}

const PresetItem = ({ label, onClick, active }: IPresetItem) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        'text-[0.8125rem] leading-normal p-2 pl-4 pr-0 cursor-pointer relative',
        active && 'font-medium'
      )}
    >
      {active && (
        <div className="absolute w-1 top-0 bottom-0 left-0 rounded bg-primary-gradient"></div>
      )}
      {label}
    </div>
  )
}

export function DoubleRangePicker({
  showPresets,
  showTime,
  allowClear,
  size = 'default',
  block,
  className,
  format,
  value = [null, null],
  placeholder = ['', ''],
  onChange,
  popupContainer,
  ...props
}: Props) {
  const [idxPreset, setPreset] = useState<number>(0)
  const [startDate, setStartDate] = useState<DateValue>(value[0])
  const [endDate, setEndDate] = useState<DateValue>(value[1])

  const handleDateChange = (date: DateValues) => {
    const [start, end] = date
    if (idxPreset !== -1) {
      setPreset(-1)
    }
    setStartDate(start)
    setEndDate(end)
  }

  const handleClear = () => {
    setPreset(-1)
    setStartDate(null)
    setEndDate(null)
  }

  const handleApplyChange = (onClose: Function) => {
    onClose()
    onChange?.([startDate || null, endDate || null])
  }

  const customHeader = ({
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    monthDate
  }: DProps.ReactDatePickerCustomHeaderProps) => (
    <div className="flex justify-between items-center">
      <Button
        className={cx(customHeaderCount === 1 && 'invisible')}
        onClick={decreaseMonth}
      >
        <ChevronLeft />
      </Button>
      <span className="font-medium text-[#303030] text-[0.8125rem] leading-normal">
        {formatDate(monthDate, 'MMM, yyyy')}
      </span>
      <Button
        aria-label="Next Month"
        className={cx(customHeaderCount === 0 && 'invisible')}
        onClick={increaseMonth}
      >
        <ChevronRight />
      </Button>
    </div>
  )

  const renderPresets = (onClose: Function) => {
    return (
      <>
        <PresetItem
          label="Custom"
          onClick={() => {
            setPreset(-1)
            handleClear()
          }}
          active={idxPreset === -1}
        />
        <div className="pl-4">
          <div className="border-b border-[#EDEDED] my-1" />
        </div>
        {dateRangePickerPresets.map(({ label, value: v }, idx) => (
          <Fragment key={label}>
            <PresetItem
              label={label}
              onClick={() => {
                setPreset(idx)
                handlePresetClick(v)
                onClose()
              }}
              active={idxPreset === idx}
            />
            {(idx === 3 || idx === 0) && (
              <div className="pl-4">
                <div className="border-b border-[#EDEDED] my-1" />
              </div>
            )}
          </Fragment>
        ))}
      </>
    )
  }

  const renderInputField = () => {
    if (!startDate && !endDate) {
      return 'All Time'
    }
    return `
      ${
        !!startDate ? formatDate(startDate, dateTimeFormat['MMM dd, yyyy']) : ''
      } - ${
      !!endDate ? formatDate(endDate, dateTimeFormat['MMM dd, yyyy']) : ''
    }
    `
  }
  const handlePresetClick = (range: DateValues) => {
    setStartDate(range[0])
    setEndDate(range[1])
    onChange?.(value)
  }

  return (
    <div
      className={cx(
        'relative border border-light-stroke rounded-lg w-full',
        'base-transition hover:border-primary focus:border-primary outline-0',
        'flex items-center cursor-text bg-white',
        sizes[size],
        block && 'block',
        className
      )}
    >
      <div className="w-full pointer-events-none flex gap-1 text-light-secondary">
        <span>{renderInputField()}</span>
      </div>
      {allowClear && (startDate || endDate) && (
        <div className="w-3 h-full shrink-0 inline-flex justify-center items-center z-[1]">
          <Close
            className="w-3 h-3 rounded-full bg-[#a7a7a7] p-[3px] text-white cursor-pointer"
            onClick={() => {
              handleClear()
              onChange?.([null, null])
            }}
          />
        </div>
      )}
      <div className="w-8 h-full shrink-0 inline-flex justify-center items-center">
        <Calendar className="w-4 h-4 " />
      </div>
      <Popover
        popupContainer={popupContainer}
        placement="bottom-start"
        className="absolute top-0 left-0 w-full h-full"
        content={onClose => (
          <Popover.Content onClose={onClose} className="w-fit pl-0 pr-4 py-4">
            <div className="flex">
              {showPresets === true && (
                <div className="w-[9.375rem] border-r border-[#EDEDED] mr-4 pr-4 rou">
                  {renderPresets(onClose)}
                </div>
              )}
              <div>
                <div className="pb-6 mb-3 border-b-[#ededed] border-b gap">
                  {/* @ts-ignore */}
                  <DatePicker
                    focusSelectedMonth={true}
                    calendarClassName="flex gap-10 double-range-picker"
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateChange}
                    inline
                    selectsRange
                    monthsShown={2}
                    disabledKeyboardNavigation={true}
                    renderCustomHeader={customHeader}
                    minDate={null}
                    {...props}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    {(startDate || endDate) && (
                      <div className="text-[0.8125rem] leading-normal inline-flex gap-1">
                        Selected duration:
                        {startDate && (
                          <p className="font-medium">
                            {formatDate(
                              startDate || new Date(),
                              dateTimeFormat['MMM dd, yyyy']
                            )}
                          </p>
                        )}
                        -
                        {endDate && (
                          <p className="font-medium">
                            {formatDate(
                              endDate || new Date(),
                              dateTimeFormat['MMM dd, yyyy']
                            )}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="flex gap-2">
                    <Button
                      onClick={() => {
                        onClose()
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => handleApplyChange(onClose)}
                    >
                      Apply Filter
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Popover.Content>
        )}
      />
    </div>
  )
}
