import { Fragment, useEffect, useMemo, useState } from 'react'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { AxiosResponse } from 'axios'
import { debounce } from 'lodash'

import { useAsync } from 'hooks'
import { dateTimeFormat } from 'utils/dateTime'
import { getRecordsCalendar } from 'services/recordingPlayback'

import { DatePicker, Props as DatePickerProps } from './index'

interface Props extends DatePickerProps {
  deviceId: number[]
}

export function RecordingCalendar({ deviceId, ...props }: Props) {
  const [viewedDate, setViewedDate] = useState(new Date())
  const [records, setRecords] = useState<{ [key: string]: number }>({})

  const { execute } = useAsync<
    AxiosResponse<{ data: { [key: string]: number } }>
  >({ showNotifOnError: true })

  useEffect(() => {
    if (!deviceId.length) return
    setRecords({})
    handleGetRecordsCalendar(deviceId, viewedDate)
  }, [JSON.stringify(deviceId), viewedDate])

  const handleGetRecordsCalendar = useMemo(() => {
    return debounce((ids: number[], viewedDate: Date) => {
      execute(
        getRecordsCalendar({
          start_date: formatDate(startOfMonth(viewedDate)),
          end_date: formatDate(endOfMonth(viewedDate)),
          device_ids: ids
        })
      ).then(res => setRecords(res.data.data))
    }, 300)
  }, [])

  const formatDate = (date: Date) => {
    return format(date, dateTimeFormat['yyyy-MM-dd'])
  }

  return (
    <DatePicker
      {...props}
      pickerProps={{
        onMonthChange: setViewedDate,
        renderDayContents: (dayOfMonth, date) => {
          const recordCount = records[formatDate(date!)]
          return (
            <Fragment>
              {dayOfMonth}
              {!!recordCount && (
                <div className="absolute top-1 right-1 w-2 h-2 rounded-full bg-success-gradient" />
              )}
            </Fragment>
          )
        }
      }}
    />
  )
}
