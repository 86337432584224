import { Fragment, useEffect, useReducer } from 'react'
import { AxiosResponse } from 'axios'

import { OrderHistoryIcon } from 'icons/utils'
import { useAsync } from 'hooks'
import { Button, Table } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import { dateTimeFormat, formatInTz } from 'utils/dateTime'
import {
  getOrderHistory,
  OrderHistory,
  OrderHistoryResponse
} from 'services/orders'

import TableToolbar from './TableToolbar'

interface State {
  search: string
  dateRange: [Date | null, Date | null]
  currentPage: number
  pageSize: PageSizes
}

const initState: State = {
  search: '',
  dateRange: [null, null],
  currentPage: 1,
  pageSize: 10
}

function TableList() {
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const orderAsync = useAsync<AxiosResponse<OrderHistoryResponse>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    orderAsync.execute(getOrderHistory(state))
  }, [...Object.values(state)])

  const renderProducName = (row: OrderHistory) => {
    return row.order_items.map(({ id, product_name, quantity }) => (
      <div key={id} className="flex items-center gap-2 mb-2.5 last:mb-0">
        <OrderHistoryIcon type={product_name} className="w-9 shrink-0" />
        <div>
          {product_name}{' '}
          <span className="text-success font-medium">x{quantity}</span>
        </div>
      </div>
    ))
  }

  const renderAddress = (row: OrderHistory) => (
    <Fragment>
      <div>{row.street_address}</div>
      {row.street_address_2 && (
        <div className="mt-1">{row.street_address_2}</div>
      )}
    </Fragment>
  )

  const renderOrderDate = ({ created_at }: OrderHistory) => {
    return formatInTz(
      new Date(created_at),
      undefined,
      `${dateTimeFormat['MM/dd/yyyy']}, ${dateTimeFormat['HH:mm']} z`
    )
  }

  const renderActionCol = (row: OrderHistory) => {
    return (
      <div className="flex items-center justify-end gap-2">
        <Button
          variant="ternary"
          onClick={() => window.open(row.receipt_url, '_blank')}
        >
          View Receipt
        </Button>
        <Button
          variant="ternary"
          onClick={() => window.open(row.tracking_url, '_blank')}
        >
          Track Order
        </Button>
      </div>
    )
  }

  const columns = [
    {
      title: 'Order ID',
      render: (row: OrderHistory) => row.id
    },
    {
      title: 'Product name',
      render: renderProducName
    },
    {
      title: 'Price',
      render: (row: OrderHistory) => `$${row.amount}`
    },
    {
      title: 'Status',
      render: (row: OrderHistory) => row.status || '---'
    },
    {
      title: 'Shipping address',
      render: renderAddress
    },
    {
      title: 'Receiver info',
      render: (row: OrderHistory) => `${row.full_name} | ${row.phone_no}`
    },
    {
      title: 'Order date',
      render: renderOrderDate
    },
    {
      width: 240,
      render: renderActionCol
    }
  ]

  return (
    <Fragment>
      <TableToolbar
        loading={orderAsync.isLoading}
        search={state.search}
        onSearch={v => setState({ search: v, currentPage: 1 })}
        dateRange={state.dateRange}
        onDateChange={range => setState({ dateRange: range })}
        currentPage={state.currentPage}
        totalPage={orderAsync.data?.data.meta.last_page || 1}
        onPageChange={p => setState({ currentPage: p })}
      />
      <Table
        rowKey="id"
        columns={columns}
        scroll={{ x: 1400 }}
        loading={orderAsync.isLoading}
        data={orderAsync.data?.data.data || []}
      />
      <TableFooter
        className="mt-4"
        loading={orderAsync.isLoading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={orderAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
    </Fragment>
  )
}

export default TableList
