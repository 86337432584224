import { ReactNode } from 'react'
import ReactSelect, {
  components,
  createFilter,
  Props,
  OptionProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  ClearIndicatorProps,
  NoticeProps
} from 'react-select'
import cx from 'classnames'

import { Close, DropdownDown } from 'icons/outline'

export type Option = {
  htmlTitle?: string
  label: ReactNode
  value: string | number
  [key: string]: any
}

export interface SelectProps extends Props<Option> {
  size?: 'large' | 'default'
}

export const customComponents = {
  Option: (optionProps: OptionProps<Option>) => {
    const { htmlTitle = '', label = '' } = optionProps.data || {}
    return (
      <div className="relative w-full" title={htmlTitle || label?.toString()}>
        <components.Option {...optionProps} />
      </div>
    )
  },
  DropdownIndicator: (dropdownProps: DropdownIndicatorProps<Option>) => {
    return (
      <components.DropdownIndicator {...dropdownProps}>
        <DropdownDown />
      </components.DropdownIndicator>
    )
  },
  MultiValueRemove: (removeProps: MultiValueRemoveProps) => {
    return (
      <components.MultiValueRemove {...removeProps}>
        <div className="text-light-secondary font-bold scale-50">✕</div>
      </components.MultiValueRemove>
    )
  },
  ClearIndicator: (clearProps: ClearIndicatorProps<Option>) => {
    return (
      <components.ClearIndicator {...clearProps}>
        <div className="w-3 h-3 rounded-full bg-[#a7a7a7] flex justify-center items-center p-[3px]">
          <Close className="text-white" />
        </div>
      </components.ClearIndicator>
    )
  },
  NoOptionsMessage: (noticeProps: NoticeProps<Option>) => {
    return (
      <components.NoOptionsMessage {...noticeProps}>
        <div className="h-[4.375rem] flex justify-center items-center text-light-secondary text-sm opacity-60">
          No Data
        </div>
      </components.NoOptionsMessage>
    )
  }
}

export function Select({ size = 'default', className, ...props }: SelectProps) {
  return (
    <ReactSelect
      className={cx(
        'custom-select',
        size,
        props.isSearchable !== false && 'allow-search',
        className
      )}
      classNamePrefix="custom-select"
      menuPlacement="auto"
      closeMenuOnSelect={!!props.isMulti ? false : props.closeMenuOnSelect}
      components={customComponents}
      filterOption={createFilter({
        matchFrom: 'any',
        stringify: option => `${option.label}`
      })}
      {...props}
    />
  )
}
