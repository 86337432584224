import { Dispatch, useState } from 'react'
import { FormInstance } from 'rc-field-form'
import { FieldData } from 'rc-field-form/lib/interface'

import tagColors from 'configs/tagColors'
import { Filter, Tag as TagIcon } from 'icons/filled'
import { Attachment, Checked, InfoCircle } from 'icons/outline'
import {
  Checkbox,
  Form,
  FormField,
  Input,
  SelectButton,
  Tag,
  TextArea
} from 'components'
import { required } from 'utils/rules'

import { FormValues } from './FormHelpers'

interface Props {
  form: FormInstance
  values: FormValues['tagDetails']
  setValues: Dispatch<Partial<FormValues>>
}

function TagDetails({ form, values, setValues }: Props) {
  const [tagColor, setTagColor] = useState({
    strokeColor: values.stroke_color,
    textColor: values.text_color,
    bgColor: values.color
  })

  const handleFieldChange = (field: FieldData[]) => {
    const name = (
      field[0].name as string[]
    )[0] as keyof FormValues['tagDetails']
    setValues({ tagDetails: { ...values, [name]: field[0].value } })
  }

  const handleTagColorChange = (color: typeof tagColor) => {
    setTagColor(color)
    setValues({
      tagDetails: {
        ...values,
        stroke_color: color.strokeColor,
        text_color: color.textColor,
        color: color.bgColor
      }
    })
  }

  return (
    <div className="min-h-[calc(100vh_-_5.15rem)] py-4 flex flex-col justify-center">
      <Form
        form={form}
        initialValues={values}
        onFieldsChange={handleFieldChange}
        className="w-[37.5rem] m-auto"
      >
        <div className="flex items-center gap-2 mb-5">
          <TagIcon type="primary" className="w-4 h-4 shrink-0" />
          <div className="text-base font-medium">Tag Detail</div>
        </div>
        <FormField name="name" label="Tag Name" rules={[required]} requiredMask>
          <Input
            size="large"
            placeholder="Name your tag"
            extra={
              <div className="flex items-center gap-1 text-light-secondary">
                <InfoCircle className="shrink-0 w-3.5 h-3.5" />
                <div className="text-[0.8125rem]">
                  Keep the name short and meaningful for quickly categorize and
                  scan
                </div>
              </div>
            }
          />
        </FormField>
        <FormField name="color" label="Tag Color">
          <div className="flex items-center gap-2">
            {tagColors.map(color => (
              <Tag
                key={color.bgColor}
                {...color}
                className="w-[2.5rem] h-[2.5rem] cursor-pointer"
                onClick={() => handleTagColorChange(color)}
              >
                {tagColor?.bgColor === color.bgColor && <Checked />}
              </Tag>
            ))}
          </div>
        </FormField>
        <div className="border-b border-light-stroke border-dashed my-8" />
        <div className="flex items-center gap-2 mb-5">
          <Filter type="primary" className="w-4 h-4 shrink-0" />
          <div className="text-base font-medium">Matching Criteria</div>
        </div>
        <FormField name="subject_keywords" label="In Title">
          <TextArea rows={5} placeholder="Enter the keyword phrase match that will trigger this rule" />
        </FormField>
        <FormField name="search_in" label="Match Logic">
          <SelectButton>
            <SelectButton.Item
              value="subject_or_body"
              className="w-[3.75rem] h-[2.5rem]"
            >
              OR
            </SelectButton.Item>
            <SelectButton.Item
              value="subject_and_body"
              className="w-[3.75rem] h-[2.5rem]"
            >
              AND
            </SelectButton.Item>
          </SelectButton>
        </FormField>
        <FormField name="body_keywords" label="In Content">
          <TextArea rows={5} placeholder="Enter the keyword phrase match that will trigger this rule" />
        </FormField>
        <FormField
          name="has_attachments"
          label="Other Criteria"
          valuePropName="checked"
        >
          <Checkbox size="large">
            <div className="flex items-center gap-1 text-[0.8125rem]">
              <Attachment className="shrink-0 text-[#BABBBF]" />
              Contains an attachment
            </div>
          </Checkbox>
        </FormField>
      </Form>
    </div>
  )
}

export default TagDetails
