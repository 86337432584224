import { useState } from 'react'

import { ChevronRight } from 'icons/outline'
import { ClockCircle, VideoCamera } from 'icons/filled'
import { Button, Form, FormField, Input } from 'components'
import ExtraFeatureSelectOption from 'shared/ExtraFeatureSelectOption'
import { required } from 'utils/rules'

import { State, useFormCtx } from './FormContext'

interface Props {
  loading: boolean
  onSubmit: (
    key: 'cloudSurveillance',
    values: State['cloudSurveillance']
  ) => void
}

function CloudSurveillance({ loading, onSubmit }: Props) {
  const { onStepChange, isFinalStep, cloudSurveillance, dispatch } =
    useFormCtx()

  const [values, setValues] = useState(cloudSurveillance)

  const handleSubmit = ({ stream_url }: { stream_url: string }) => {
    if (loading) return
    dispatch({ cloudSurveillance: { ...values, stream_url } })
    if (!isFinalStep) return onStepChange(1)
    onSubmit('cloudSurveillance', { ...values, stream_url })
  }

  return (
    <div className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center">
      <div className="w-[28rem] m-auto">
        <div className="text-base font-medium mb-6">
          Set up Cloud Surveillance
        </div>
        <Form
          initialValues={{ stream_url: values.stream_url }}
          onFinish={handleSubmit}
        >
          <FormField
            name="stream_url"
            label="Enter your device RTSP Link"
            rules={[required]}
            requiredMask
          >
            <Input size="large" placeholder="Enter device RTSP link" />
          </FormField>
          <div className="mb-8">
            <div className="block font-medium text-[0.8125rem] mb-1.5">
              More Options
            </div>
            <ExtraFeatureSelectOption
              className="mb-2"
              active={values.is_licensed}
              onChange={v => {
                setValues({
                  ...values,
                  is_licensed: v,
                  enable_recording: v ? values.enable_recording : false
                })
              }}
            >
              <VideoCamera className="w-5 h-5 shrink-0" type="primary" />
              <div className="text-sm">Assign license to this camera</div>
            </ExtraFeatureSelectOption>
            <ExtraFeatureSelectOption
              active={values.enable_recording}
              onChange={v => setValues({ ...values, enable_recording: v })}
            >
              <ClockCircle className="w-5 h-5 shrink-0" type="primary" />
              <div className="text-sm">Start recording after created</div>
            </ExtraFeatureSelectOption>
          </div>
          {isFinalStep && (
            <Button
              type="submit"
              variant="primary"
              block
              size="large"
              disabled={loading}
            >
              Save
            </Button>
          )}
          {!isFinalStep && (
            <Button type="submit" variant="primary" block size="large">
              Next <ChevronRight />
            </Button>
          )}
        </Form>
      </div>
    </div>
  )
}

export default CloudSurveillance
