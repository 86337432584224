import { Fragment, ReactNode } from 'react'
import { useLoadScript } from '@react-google-maps/api'
import cx from 'classnames'

import { libraries } from 'configs/googleMaps'
import { Spinner, SpinnerProps } from 'components'

interface Props {
  spinner?: SpinnerProps
  errorCls?: string
  errorMessage?: ReactNode
  children?: ReactNode
}

function LoadGoogleMaps({ spinner, errorCls, errorMessage, children }: Props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    libraries
  })

  if (!isLoaded) {
    return <Spinner {...spinner} />
  }

  if (!!loadError) {
    return (
      <div className={cx(errorCls, 'text-light-secondary text-[0.8125rem]')}>
        {errorMessage || 'Places cannot be loaded right now, sorry.'}
      </div>
    )
  }

  return <Fragment>{children}</Fragment>
}

export default LoadGoogleMaps
