import { NotifChannel } from 'services/tags'

export const stepLabels = [
  'Enter tag details',
  'Create a Rule',
  'Select Devices',
  'Add Notification Channels'
]

export interface OptionValue {
  label: string
  value: number
}

export interface FormValues {
  tagDetails: {
    name: string
    stroke_color: string
    text_color: string
    color: string
  }
  matchingCriterias: {
    subject_keywords: string // in_title
    body_keywords: string // in_content
    search_in: string
    has_attachments: boolean
  }
  affectedDevices: {
    apply_to_all: boolean
    devices: OptionValue[]
  }
  recipients: {
    is_blocked: boolean
    channels: NotifChannel[]
    phone_recipients: OptionValue[]
    email_recipients: OptionValue[]
    whatsapp_recipients: OptionValue[]
    webhook: string
    slack_webhook: string
    teams_webbhook: string
    pagerduty_integration_key: string
  }
}

export type FormKey = keyof FormValues & string

export const initFormValues: FormValues = {
  tagDetails: {
    name: '',
    stroke_color: '',
    text_color: '',
    color: ''
  },
  matchingCriterias: {
    subject_keywords: '',
    body_keywords: '',
    search_in: 'subject_or_body',
    has_attachments: false
  },
  affectedDevices: {
    apply_to_all: false,
    devices: []
  },
  recipients: {
    is_blocked: false,
    channels: [],
    phone_recipients: [],
    email_recipients: [],
    whatsapp_recipients: [],
    webhook: '',
    slack_webhook: '',
    teams_webbhook: '',
    pagerduty_integration_key: ''
  }
}
