import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function LocationPin({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 6.53972C2 3.48478 4.46767 1 7.5 1C10.5323 1 13 3.48478 13 6.53972C13 10.8804 8.017 15.3623 7.80494 15.5512C7.71756 15.6282 7.60878 15.6667 7.5 15.6667C7.39122 15.6667 7.28244 15.6282 7.19506 15.5506C6.983 15.3623 2 10.8804 2 6.53972ZM4.44426 6.5C4.44426 8.18483 5.81498 9.55556 7.49981 9.55556C9.18465 9.55556 10.5554 8.18483 10.5554 6.5C10.5554 4.81517 9.18465 3.44444 7.49981 3.44444C5.81498 3.44444 4.44426 4.81517 4.44426 6.5Z"
          fill="url(#paint0_linear_7290_423890)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7290_423890"
            x1="2"
            y1="1"
            x2="2"
            y2="15.6667"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
