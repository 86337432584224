import { isCamera, isSensor } from 'utils/device'
import { Device } from 'services/devices'

import CameraDevice from './CameraDevice'
import SensorDevice from './SensorDevice'
import OtherDevice from './OtherDevice'

type Props = {
  device: Device
} & JSX.IntrinsicElements['div']

function DeviceCard(props: Props) {
  const type = props.device.type
  if (isCamera(type)) return <CameraDevice {...props} />
  if (isSensor(type)) return <SensorDevice {...props} />
  return <OtherDevice {...props} />
}

export default DeviceCard
