import shallow from 'zustand/shallow'
import cx from 'classnames'

import NotifEnable from 'assets/images/NotifEnable.png'
import NotifDisable from 'assets/images/NotifDisable.png'

import { useAsync } from 'hooks'
import { Switch } from 'components'
import toast from 'utils/toast'
import { updateDeviceNotif } from 'services/accounts'
import useStore, { Store } from 'store'

const mapState = (state: Store) => ({
  user: state.auth.currentUser,
  updateUser: state.auth.updateUser
})

function DeviceNotif() {
  const { user, updateUser } = useStore(mapState, shallow)
  const notifEnabled = !!user?.account.enabled_notifications

  const notifAsync = useAsync({ showNotifOnError: true })

  const handleNotifChange = (checked: boolean) => {
    const isEnabled = checked ? 1 : 0
    notifAsync.execute(updateDeviceNotif(user!.id, isEnabled))
    updateUser({
      account: { ...user!.account, enabled_notifications: isEnabled }
    })
    toast.success(
      { title: 'Device notification setting updated' },
      { position: 'bottom-center' }
    )
  }

  return (
    <div className="p-6 bg-white rounded-lg border border-[#eee] shadow-[0_1px_2px_rgba(0,0,0,.16)] mb-4">
      <div className="text-[0.9375rem] mb-4 font-medium">
        Device Notifications
      </div>
      <div className="flex items-center gap-2 text-sm">
        <Switch
          className="!gap-4"
          checked={notifEnabled}
          onChange={handleNotifChange}
        >
          <div className={cx('font-semibold', notifEnabled && 'text-success')}>
            {notifEnabled ? 'On' : 'Off'}
          </div>
        </Switch>
        <div className="text-light-secondary">
          {notifEnabled
            ? 'All notifications from devices will be sent to recipients'
            : 'All notifications from devices will NOT be sent to recipients'}
        </div>
      </div>
      <img
        className="w-[14.625rem] mt-3"
        src={notifEnabled ? NotifEnable : NotifDisable}
        alt={notifEnabled ? 'enabled' : 'disabled'}
      />
    </div>
  )
}

export default DeviceNotif
