import { Routes, Route, Navigate } from 'react-router-dom'

import MainLayout from 'layouts/MainLayout'
import FullPageLayout from 'layouts/FullPageLayout'
import BlankLayout from 'layouts/BlankLayout'

// DEV ROUTES
import Dev from 'pages/__DEV'
import Assets from 'pages/__DEV/Assets'
import Icons from 'pages/__DEV/Icons'
import Test from 'pages/__DEV/Test'

import Dashboard from 'pages/Dashboard'
import Livestream from 'pages/Livestream'
import Playback from 'pages/Playback'
import DeviceManagement from 'pages/DeviceManagement'
import DeviceDetail from 'pages/DeviceDetail'
import IotAutomation from 'pages/IotAutomation'
import IotAutomationDetail from 'pages/IotAutomationDetail'
import NotifManagement from 'pages/NotificationManagement'
import CloudStorage from 'pages/CloudStorage'
import UserRecipient from 'pages/UserRecipient'
import ActivityLog from 'pages/ActivityLog'
import Checkout from 'pages/Checkout'
import TransactionHistory from 'pages/TransactionHistory'
import OrderHistory from 'pages/OrderHistory'
import PageNotFound from 'pages/Errors/PageNotFound'
import InternalServerError from 'pages/Errors/InternalServerError'

import Settings from 'pages/Settings'
import General from 'pages/Settings/General'
import SecurityLogin from 'pages/Settings/SecurityLogin'
import Billing from 'pages/Settings/Billing'
import Plans from 'pages/Settings/Plans'
import Notifications from 'pages/Settings/Notifications'
import Unit from 'pages/Settings/Unit'

import Login from 'pages/Auth/Login'
import Register from 'pages/Auth/Register'
import ResetPassword from 'pages/Auth/ResetPassword'
import ResendLink from 'pages/Auth/VerifyEmail/ResendLink'
import VerifyEmail from 'pages/Auth/VerifyEmail'

import VRE from 'pages/External/VerifyRecipientEmail'
import EmbedPlayer from 'pages/External/EmbedPlayer'
import SharedFile from 'pages/External/SharedFile'

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="livestream" element={<Livestream />} />
        <Route path="playback" element={<Playback />} />
        <Route path="devices" element={<DeviceManagement />} />
        <Route path="devices/:id" element={<DeviceDetail />} />
        <Route path="iot-automation" element={<IotAutomation />} />
        <Route path="iot-automation/:id" element={<IotAutomationDetail />} />
        <Route path="notifications" element={<NotifManagement />} />
        <Route path="cloud-storage" element={<CloudStorage />} />
        <Route path="user-recipient" element={<UserRecipient />} />
        <Route path="activity-log" element={<ActivityLog />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="transaction-history" element={<TransactionHistory />} />
        <Route path="order-history" element={<OrderHistory />} />
        <Route path="settings" element={<Settings />}>
          <Route index element={<Navigate to="general" replace />} />
          <Route path="general" element={<General />} />
          <Route path="security-login" element={<SecurityLogin />} />
          <Route path="billing" element={<Billing />} />
          <Route path="billing/plans" element={<Plans />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="unit" element={<Unit />} />
          <Route path="*" element={<Navigate to="general" replace />} />
        </Route>
      </Route>

      <Route path="/" element={<FullPageLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="verify-email" element={<ResendLink />} />
        <Route path="verify-email/:id/:hash" element={<VerifyEmail />} />
        <Route path="recipient/verify-email/:id/:hash" element={<VRE />} />
        <Route path="404" element={<PageNotFound />} />
        <Route path="500" element={<InternalServerError />} />
      </Route>

      <Route path="/" element={<BlankLayout />}>
        <Route path="embed/:url" element={<EmbedPlayer />} />
        <Route path="shared-file/:id" element={<SharedFile />} />
      </Route>

      {/* DEV ONLY, WILL NOT INCLUDE IN PRODUCTION */}
      {process.env.NODE_ENV === 'development' && (
        <Route path="/dev" element={<MainLayout />}>
          <Route index element={<Dev />} />
          <Route path="assets" element={<Assets />} />
          <Route path="icons" element={<Icons />} />
          <Route path="test" element={<Test />} />
        </Route>
      )}

      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  )
}

export default App
