import { Dispatch, Fragment, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'

import { Device } from 'services/devices'
import ModalExportRecord from 'shared/Modals/Playback/ExportRecord'

import PlaybackTabItem from './PlaybackTabItem'
import { usePlaybackCtx } from '../PlaybackContext'
import {
  getSlotsOfLayout,
  renderChildrenClassLayout,
  renderParentClassLayout
} from 'utils/layout'

interface Props {
  tabKey: string
  layout: number
  devices: (Device | null)[]
  onDeviceChange: (
    key: string,
    index: number
  ) => (device: Device | null) => void
  exportTabKey: string
  setExportTabKey: Dispatch<React.SetStateAction<string>>
}

function PlaybackTab({ tabKey, layout, devices, ...props }: Props) {
  const { selectedTime, dispatch } = usePlaybackCtx()
  const slots = getSlotsOfLayout(layout)

  useEffect(() => {
    if (devices.every(d => !d)) {
      dispatch({ type: 'RESET', payload: { layout } })
    }
  }, [devices])
  return (
    <Fragment>
      <div
        className={cx(
          'bg-white gap-1 h-[calc(100vh_-_17rem_-_3.125rem)] grid',
          renderParentClassLayout(layout)
        )}
      >
        {Array.from({ length: slots }).map((_, index) => (
          <div
            key={index}
            className={cx(
              'grid gap-[1px]',
              renderChildrenClassLayout(layout, index + 1)
            )}
          >
            <PlaybackTabItem
              index={index}
              device={devices[index]}
              onDeviceChange={props.onDeviceChange(tabKey, index)}
            />
          </div>
        ))}
      </div>
      <AnimatePresence initial={false}>
        {props.exportTabKey === tabKey && (
          <ModalExportRecord
            timeRange={selectedTime}
            devices={devices.filter(device => !!device) as Device[]}
            onClose={() => props.setExportTabKey('')}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default PlaybackTab
