import { format } from 'date-fns'

import BroadflowFullversion from 'assets/images/BroadflowFullversion.png'
import AppStore from 'assets/images/AppStore.png'
import GooglePlay from 'assets//images/GooglePlay.png'

import { NotifDetailIcon } from 'icons/utils'
import { Switch, Tag } from 'components'
import { dateTimeFormat } from 'utils/dateTime'
import { attachmentUrl, getAttachmentName, isImgFile } from 'utils/file'
import { InboundNotif } from 'services/notifications'

interface Props {
  notif: InboundNotif
  onPreviewModeChange: () => void
}

function PreviewMode({ notif, onPreviewModeChange }: Props) {
  const renderTimestamp = () => {
    return format(
      new Date(notif.created_at),
      `${dateTimeFormat['MMM dd, yyyy']} ${dateTimeFormat['hh:mm a']}`
    )
  }

  const renderTags = () => {
    if (!!notif.tags && !!notif.tags.length) {
      return notif.tags.map(tag => (
        <Tag
          key={tag.id}
          strokeColor={tag.stroke_color}
          textColor={tag.text_color}
          bgColor={tag.color}
        >
          {tag.name}
        </Tag>
      ))
    }
    return '---'
  }

  const renderAttachments = () => {
    if (!!notif.attachments && !!notif.attachments.length) {
      return notif.attachments.map(a => (
        <a
          key={a}
          href={`${attachmentUrl}/${a}`}
          target="_blank"
          rel="noreferrer"
          className="bg-[#efefef] py-1.5 px-2 rounded-lg inline-flex items-center gap-1"
        >
          <NotifDetailIcon
            type="attachment"
            color="#808080"
            className="w-3 h-3"
          />
          <div className="leading-none text-[0.6875rem]">
            {getAttachmentName(a)}
          </div>
        </a>
      ))
    }
    return '---'
  }

  const renderImages = () => {
    return (notif.attachments || [])
      .filter(a => isImgFile(a))
      .map(url => {
        const imgUrl = `${attachmentUrl}/${url}`
        return (
          <div key={url} className="w-[18.125rem]">
            <img
              alt={url}
              className="w-full max-h-[10rem] rounded-lg object-cover"
              src={imgUrl}
            />
            <a
              href={imgUrl}
              target="_blank"
              rel="noreferrer"
              className="block text-[0.8125rem] text-primary font-medium mt-1"
            >
              {getAttachmentName(url)}
            </a>
          </div>
        )
      })
  }

  return (
    <div className="mt-4">
      <div className="mb-6 flex justify-end items-center gap-4">
        <div className="text-light-secondary">
          Below is how the notification looks like when send by email
        </div>
        <Switch
          checked
          onChange={() => onPreviewModeChange()}
          className="text-primary font-medium"
        >
          Preview Mode
        </Switch>
      </div>
      <div className="bg-[#F1F5F6] rounded-lg p-6">
        <div className="bg-white py-6 px-12 rounded-lg">
          <img
            className="block w-[9.375rem] mb-6"
            src={BroadflowFullversion}
            alt="logo"
          />
          <div className="text-2xl font-medium mb-4">{notif.subject}</div>
          <div
            className="mb-8"
            dangerouslySetInnerHTML={{ __html: notif.subject }}
          />
          <div className="flex gap-4 mb-3.5">
            <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
              <NotifDetailIcon
                type="time"
                iconColor="#BABBBF"
                className="w-3.5 h-3.5 shrink-0"
              />
              <div className="font-medium text-[0.8125rem]">Timestamp</div>
            </div>
            <div className="text-[0.8125rem]">{renderTimestamp()}</div>
          </div>
          <div className="flex gap-4 mb-3.5">
            <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
              <NotifDetailIcon
                type="camera"
                className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
              />
              <div className="font-medium text-[0.8125rem]">Device Name</div>
            </div>
            <div className="text-[0.8125rem]">
              {notif.device?.name || '---'}
            </div>
          </div>
          <div className="flex gap-4 mb-3.5">
            <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
              <NotifDetailIcon
                type="location"
                className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
              />
              <div className="font-medium text-[0.8125rem]">Location</div>
            </div>
            <div className="text-[0.8125rem]">
              {notif.device?.location?.name || '---'}
            </div>
          </div>
          <div className="flex gap-4 mb-3.5">
            <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
              <NotifDetailIcon
                type="tag"
                className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
              />
              <div className="font-medium text-[0.8125rem]">Tags</div>
            </div>
            <div className="inline-flex items-center flex-wrap gap-1">
              {renderTags()}
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-[17%] inline-flex items-center self-start gap-2 shrink-0">
              <NotifDetailIcon
                type="attachment"
                className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
              />
              <div className="font-medium text-[0.8125rem]">Attachments</div>
            </div>
            <div className="inline-flex items-center flex-wrap gap-1">
              {renderAttachments()}
            </div>
          </div>
          {!!notif.attachments?.find(a => isImgFile(a)) && (
            <div className="mt-12">
              <div className="text-base font-medium mb-4">Attachment</div>
              <div className="inline-flex flex-wrap gap-x-2 gap-y-4">
                {renderImages()}
              </div>
            </div>
          )}
          <div className="border-b border-light-stroke border-dashed my-6" />
          <div className="text-sm font-medium mb-2">
            Take Broadflow with you everywhere
          </div>
          <div className="flex items-center gap-2">
            <img src={AppStore} className="w-[6.75rem]" alt="app store" />
            <img src={GooglePlay} className="w-[6.75rem]" alt="google play" />
          </div>
          <div className="border-b border-light-stroke border-dashed my-6" />
          <div className="flex items-center gap-3 mb-6">
            <div className="font-medium underline">Home</div>
            <div>|</div>
            <div className="font-medium underline">Help</div>
          </div>
          <div className="text-[0.8125rem] text-light-secondary mb-2">
            You received this email because you are listed as a recipient on
            this account.
          </div>
          <div className="font-medium underline text-[0.8125rem] text-light-secondary">
            Click here to unsubscribe
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewMode
