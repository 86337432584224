import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function WarningCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M7 14C3.11111 14 0 10.8889 0 7C0 3.11111 3.11111 0 7 0C10.8889 0 14 3.11111 14 7C14 10.8889 10.8889 14 7 14ZM7 1.45833C3.98611 1.45833 1.45833 3.98611 1.45833 7C1.45833 10.0139 3.98611 12.5417 7 12.5417C10.0139 12.5417 12.5417 10.0139 12.5417 7C12.5417 3.98611 10.1111 1.45833 7 1.45833Z"
          fill="currentColor"
        />
        <path
          d="M7.00986 9C6.47126 9 6.01978 9.45149 6.01978 9.9901C6.01978 10.5287 6.47126 10.9802 7.00986 10.9802C7.52867 10.9802 7.99995 10.5287 7.97619 10.0139C7.99995 9.44752 7.55243 9 7.00986 9Z"
          fill="currentColor"
        />
        <path
          d="M6.76436 3.02218C6.29307 3.12903 6 3.46842 6 3.8801C6.02376 4.12836 6.04356 4.37977 6.06733 4.62803C6.13465 5.57394 6.20198 6.501 6.26931 7.44691C6.29307 7.76745 6.60594 8 7.0099 8C7.41386 8 7.73069 7.75174 7.75049 7.42805C7.75049 7.23321 7.75049 7.05409 7.77426 6.85611C7.81782 6.24959 7.86535 5.64308 7.90891 5.03656C7.93267 4.64374 7.97624 4.25092 8 3.8581C8 3.71669 7.97624 3.59098 7.90891 3.46528C7.70693 3.11331 7.23564 2.93419 6.76436 3.02218Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
