import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function TemperatureSensor(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="10"
        height="21"
        viewBox="0 0 10 21"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M8.00005 11.0049V3.00488C8.00005 1.34488 6.66005 0.00488281 5.00005 0.00488281C3.34005 0.00488281 2.00005 1.34488 2.00005 3.00488V11.0049C0.790049 11.9149 4.95911e-05 13.3749 4.95911e-05 15.0049C4.95911e-05 17.7649 2.24005 20.0049 5.00005 20.0049C7.76005 20.0049 10 17.7649 10 15.0049C10 13.3749 9.21005 11.9149 8.00005 11.0049ZM4.00005 9.00488V3.00488C4.00005 2.45488 4.45005 2.00488 5.00005 2.00488C5.55005 2.00488 6.00005 2.45488 6.00005 3.00488V4.00488H5.00005V5.00488H6.00005V6.00488V7.00488H5.00005V8.00488H6.00005V9.00488H4.00005Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
