import { cloneElement, FC, ReactElement, ReactNode } from 'react'
import type { FieldProps } from 'rc-field-form/lib/Field'
import { Field } from 'rc-field-form'
import cx from 'classnames'

import { Select } from './Select'
import { AsyncSelect } from './AsyncSelect'

interface Props extends FieldProps {
  label?: string | ReactNode
  requiredMask?: boolean
  className?: string
}

const errorClasses = 'border-danger focus:!border-danger hover:!border-danger'

export const FormField: FC<Props> = ({
  children,
  label,
  name,
  requiredMask,
  className,
  ...props
}) => {
  const renderErrors = (errors: string[]) => (
    <div className="min-h-[1.5rem] pt-[2px]">
      {errors.map(err => (
        <div key={err} className="text-danger text-sm">
          {err}
        </div>
      ))}
    </div>
  )

  const renderChildren = (children: any, controls: any, meta: any) => {
    const c = children as ReactElement
    const isSelectEl = c.type === Select || c.type === AsyncSelect
    return cloneElement(c, {
      name,
      ...controls,
      value: controls.value || (isSelectEl ? undefined : ''),
      className: cx(c.props.className, meta.errors.length && errorClasses),
      onChange: (...args: any[]) => {
        controls.onChange(...args)
        c.props.onChange?.(...args)
      }
    })
  }

  return (
    <Field name={name} {...props}>
      {(controls, meta, form) => (
        <div className={cx('mb-6', className)}>
          {!!label && (
            <label className="block font-medium text-[0.8125rem] mb-1.5">
              {label} {requiredMask && <span className="text-danger">*</span>}
            </label>
          )}
          {typeof children === 'function'
            ? children(controls, meta, form)
            : renderChildren(children, controls, meta)}
          {!!meta.errors.length && renderErrors(meta.errors)}
        </div>
      )}
    </Field>
  )
}
