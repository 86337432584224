export enum PLAN_TYPE {
  FREE = 'free_plan',
  BASIC = 'basic_plan',
  PLUS = 'plus_plan',
  PRO = 'professional_plan',
  ENTERPRISE = 'enterprise'
}

export enum ADD_ON {
  CAMERA_LICENSE = 'cloud_recording_live_streaming',
  TEMPERATURE_SENSOR = 'temperature_sensor'
}

export const getSubIntervalType = (interval: string) => {
  if (interval === 'month') return 'Monthly'
  if (interval === 'year') return 'Yearly'
  return undefined
}
