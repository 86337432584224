import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Emergency({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M17.15 7.69167L15.4833 4.80833L11.4917 7.11667V2.5H8.15833V7.11667L4.16667 4.80833L2.5 7.69167L6.49167 10L2.5 12.3083L4.16667 15.1917L8.15833 12.8833V17.5H11.4917V12.8833L15.4833 15.1917L17.15 12.3083L13.1583 10L17.15 7.69167Z"
          fill="url(#paint0_linear_11328_4099)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_11328_4099"
            x1="9.825"
            y1="2.5"
            x2="9.825"
            y2="17.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
