import { useEffect } from 'react'
import { format } from 'date-fns'
import shallow from 'zustand/shallow'

import { Button } from 'components'
import TableTransactionHistory from 'pages/TransactionHistory/TableList'
import toast from 'utils/toast'
import { PLAN_TYPE } from 'utils/plan'
import { dateTimeFormat } from 'utils/dateTime'
import useStore from 'store'

import CurrentPlanInfo from './CurrentPlanInfo'
import PaymentMethod from './PaymentMethod'
import { AnimatePresence } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

function Billing() {
  const navigate = useNavigate()  
  const sub = useStore(state => state.sub.subscription, shallow)

  const { plan, billing_end } = sub!.subscription!
  const isFreePlan = plan!.name === PLAN_TYPE.FREE

  useEffect(() => {
    const cancelPlan = localStorage.getItem('APP/CANCEL_PLAN')
    if (cancelPlan === 'true') {
      toast.success(
        {
          title: `Plan cancalled. You will continue to have access to your devices and the ${
            plan!.label
          } Plan until ${format(
            new Date(billing_end),
            `${dateTimeFormat['MMM dd, yyyy']} ${dateTimeFormat['hh:mm a']}`
          )}`
        },
        { position: 'bottom-center', style: { maxWidth: 'unset' } }
      )
      localStorage.removeItem('APP/CANCEL_PLAN')
    }
  }, [])

  return (
    <>
    <div className="p-[1.3rem]">
      <div className="flex flex-wrap items-center justify-between gap-3 mb-4">
        <div className="text-xl font-medium">Plan & Bill Management</div>
        {!isFreePlan && (
          <Button
            variant="primary"
            onClick={() => navigate('/settings/billing/plans')}
          >
            Change Plan
          </Button>
        )}
      </div>
      <CurrentPlanInfo isFreePlan={isFreePlan} />
      <PaymentMethod card={sub!.card} />
      <div className="mt-12">
        <div className="text-[0.9375rem] mb-4 font-medium">
          Purchase History
        </div>
        <TableTransactionHistory showTableToolbar={false} />
      </div>
    </div>
    <AnimatePresence initial={false}>
  </AnimatePresence>
  </>
  )
}

export default Billing
