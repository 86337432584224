import { FC, ReactNode, useState } from 'react'
import { motion } from 'framer-motion'
import cx from 'classnames'

import { ChevronRight } from 'icons/outline'

interface Props {
  children?: ReactNode
  defaultActive?: boolean
  active?: boolean
  onChange?: (active: boolean) => void
  className?: string
  panel: ReactNode
  panelClassName?: string
  contentClassName?: string
}

export const Collapse: FC<Props> = ({
  defaultActive = false,
  active: propsActive,
  onChange,
  className,
  panel,
  panelClassName,
  contentClassName,
  children
}) => {
  const [active, setActive] = useState<boolean>(
    () => propsActive || defaultActive
  )

  const toggleCollapse = () => {
    setActive(prev => {
      onChange?.(!prev)
      return !prev
    })
  }

  return (
    <div className={cx('w-full', className)}>
      <div
        onClick={toggleCollapse}
        className={cx(
          'flex justify-between items-center py-3 px-4 cursor-pointer select-none text-sm',
          panelClassName
        )}
      >
        {panel}
        <motion.div
          className="w-4 h-4 flex justify-center items-center"
          transition={{ type: 'tween', duration: 0.2 }}
          animate={{ rotate: active ? 90 : 0 }}
        >
          <ChevronRight
            style={{ width: 12, height: 12 }}
            className="text-light-secondary"
          />
        </motion.div>
      </div>
      <motion.div
        initial={false}
        animate={{ height: active ? 'auto' : 0 }}
        transition={{ type: 'tween', duration: 0.2 }}
        className={cx('w-full text-sm overflow-hidden')}
      >
        <div className={cx('p-4 pt-1 ', contentClassName)}>{children}</div>
      </motion.div>
    </div>
  )
}
