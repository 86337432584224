import Screenshot from 'assets/screenshots/NotificationTag.png'
import { ChevronRight } from 'icons/outline'

function TagManagement() {
  return (
    <div className="p-4 grow">
      <div className="text-xl font-medium mb-4">Tag Management</div>
      <img src={Screenshot} alt="screenshot" className="w-full h-[25.625rem]" />
      <div className="mt-12">
        <div className="font-medium text-base mb-4">What are Tags?</div>
        <div className="text-[0.8125rem] mb-3.5">
          Tag Management allows you to create classification rules based on the
          text found in the subject and/or body of your notifications.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Tagged notifications help keep things organized and provides a
          seamless integration with your cameras in the Streams Cloud VMS.
        </div>
      </div>
      <div className="mt-12">
        <div className="font-medium text-base mb-4">Get Support</div>
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/articles/what-is-a-tag"
          target="_blank"
          rel="noreferrer"
        >
          What is a tag?
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/articles/how-to-create-new-tag"
          target="_blank"
          rel="noreferrer"
        >
          How to create a new tag
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/articles/what-are-tags-used-for"
          target="_blank"
          rel="noreferrer"
        >
          What are tags used for?
          <ChevronRight />
        </a>
      </div>
    </div>
  )
}

export default TagManagement
