import { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import SensorLicenseImg from 'assets/images/SensorLicense.png'

import {
  TemperatureSensor,
  Humidity,
  DropdownUp,
  DropdownDown,
  More,
  EyeOpen,
  Pencil
} from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Dot, Popover } from 'components'
import MenuItem from 'shared/MenuItem'
import SensorChart from 'shared/Chart/SensorChart'
import ModalEditDevice from 'shared/Modals/Device/EditDevice'
import ModalConfirmDeleteWithInput from 'shared/Modals/Confirm/ConfirmDeleteWithInput'
import toast from 'utils/toast'
import { formatSensorTemperature } from 'utils/device'
import { Device, updateDevice } from 'services/devices'
import useStore, { Store } from 'store'

interface Props {
  showDeviceName?: boolean
  className?: string
  device: Device
}

const mapState = (state: Store, deviceId: number) => ({
  stats: state.stat.stats,
  sensor: state.device.sensors.find(({ id }) => deviceId === id),
  user: state.auth.currentUser,
  updateDevice: state.device.updateDevice,
  updateUsedCount: state.stat.updateUsedCount
})

function SensorDevice({ device, showDeviceName, className }: Props) {
  const navigate = useNavigate()
  const [showMore, setShowMore] = useState(false)
  const [visible, setVisible] = useState('')

  const { sensor, user, ...store } = useStore(
    state => mapState(state, device.id),
    shallow
  )

  const { isLoading, execute } = useAsync<AxiosResponse<{ data: Device }>>({
    showNotifOnError: true
  })

  if (!sensor || !user) {
    return null
  }

  const { used = 0, total = 0 } = store.stats?.camera_licenses || {}
  const licenseRemaining = total - used

  const { unit_temperature } = user
  const isLicensed = !!device.is_licensed
  const { temperatureSensorLogs: logs = [] } = sensor
  const [first, last = first] = logs.slice(Math.max(logs.length - 2, 0))

  const handleToggleLicense = (license: boolean) => {
    setVisible('')
    execute(updateDevice(device.id, { is_licensed: license })).then(() => {
      store.updateDevice(device.id, { is_licensed: license ? 1 : 0 })
      store.updateUsedCount({ temperature_sensors: license ? 1 : -1 })
      toast.success(
        {
          title: license
            ? `Assigned Sensor License to “${device.name}”`
            : 'Sensor License Removed'
        },
        { position: 'bottom-center' }
      )
    })
  }

  const renderArrow = (a: number, b: number) => {
    if (a > b) return <DropdownUp className="text-success" />
    if (b > a) return <DropdownDown className="text-danger" />
    return null
  }

  const renderOptions = (onClose: () => void) => {
    const onClick = (fn: Function) => () => {
      fn()
      onClose()
    }
    return (
      <Popover.Content className="!p-2 w-60" onClose={onClose}>
        <MenuItem
          className="text-[0.8125rem]"
          onClick={() => navigate(`/devices/${device.id}?tab=device-details`)}
        >
          <EyeOpen className="w-3.5 h-3.5" /> View Detail
        </MenuItem>
        <MenuItem
          className="text-[0.8125rem]"
          onClick={onClick(() => setVisible('edit'))}
        >
          <Pencil className="w-3.5 h-3.5 scale-[.8]" /> Edit Device
        </MenuItem>
        <div className="border-b border-light-stroke my-[2px]" />
        {!isLicensed && (
          <MenuItem
            className={cx(
              'text-[0.8125rem]',
              !licenseRemaining && 'opacity-40 pointer-events-none'
            )}
            onClick={
              !!licenseRemaining
                ? onClick(() => handleToggleLicense(true))
                : undefined
            }
          >
            <div className="w-3.5" />
            Assign Sensor License
          </MenuItem>
        )}
        {!!isLicensed && (
          <MenuItem
            className="text-[0.8125rem] text-danger"
            onClick={onClick(() => setVisible('remove-license'))}
          >
            <div className="w-3.5" />
            Unassign Sensor License
          </MenuItem>
        )}
      </Popover.Content>
    )
  }

  return (
    <Fragment>
      <div
        className={cx(
          'relative group bg-white rounded-lg p-3 shadow-[0_1px_2px_rgba(0,0,0,0.16)] min-h-[8.8125rem]',
          className
        )}
      >
        {showDeviceName !== false && (
          <div className="flex items-center gap-2 mb-3">
            <Dot
              type={sensor.is_online ? 'success' : 'danger'}
              className="w-3 h-3 shrink-0 border"
            />
            <Link
              to={`/devices/${device.id}?tab=device-details`}
              className="text-light-secondary text-[0.8125rem] hover:underline"
            >
              {device.name}
            </Link>
          </div>
        )}
        {!device.is_licensed && (
          <Fragment>
            <div className="flex items-center gap-2 mb-2">
              <img className="w-6 h-6" src={SensorLicenseImg} alt="license" />
              <div className="text-[0.8125rem] text-light-secondary">
                This sensor is not licensed
              </div>
            </div>
            <Button className="ml-8" onClick={() => handleToggleLicense(true)}>
              Assign License
            </Button>
          </Fragment>
        )}
        <div
          className={cx(
            'base-transition absolute top-2 right-3 opacity-0 group-hover:opacity-100',
            showMore && '!opacity-100'
          )}
        >
          <Popover
            placement="right-start"
            onVisibleChange={setShowMore}
            content={renderOptions}
          >
            <Button children={<More />} />
          </Popover>
        </div>
        {!!device.is_licensed && (
          <div key={last?.created_at || ''} className="flex items-center gap-3">
            <div className="w-full inline-flex flex-col gap-2">
              <div className="inline-flex items-center gap-2">
                <TemperatureSensor className="w-4 h-4 shrink-0 text-[#dedede]" />
                <div className="text-2xl inline-flex items-center gap-1.5 font-medium">
                  {last?.temperature && (
                    <Fragment>
                      {formatSensorTemperature(
                        last.temperature,
                        unit_temperature
                      )}
                      {renderArrow(last.temperature, first?.temperature || 0)}
                    </Fragment>
                  )}
                  {!last?.temperature && '---'}
                </div>
              </div>
              <SensorChart type="temperature" data={logs} />
            </div>
            <div className="w-full inline-flex flex-col gap-2">
              <div className="inline-flex items-center gap-2">
                <Humidity className="w-4 h-4 shrink-0 text-[#dedede]" />
                <div className="text-2xl inline-flex items-center gap-1 font-medium">
                  {last?.humidity && (
                    <Fragment>
                      {last.humidity}%
                      {renderArrow(last.humidity, first?.humidity || 0)}
                    </Fragment>
                  )}
                  {!last?.humidity && '---'}
                </div>
              </div>
              <SensorChart type="humidity" data={logs} />
            </div>
          </div>
        )}
        {isLoading && (
          <div className="absolute top-0 left-0 bg-white/90 w-full h-full rounded-lg">
            <div className="flex h-full justify-center items-center">
              Please wait...
            </div>
          </div>
        )}
      </div>
      <AnimatePresence initial={false}>
        {visible === 'edit' && (
          <ModalEditDevice device={device} onClose={() => setVisible('')} />
        )}
        {visible === 'remove-license' && (
          <ModalConfirmDeleteWithInput
            modalTitle="Remove sensor license"
            value={device.name}
            inputLabel={
              <Fragment>
                Enter device name <b>"{device.name}"</b> to remove license
              </Fragment>
            }
            okText="Remove License"
            onConfirm={() => handleToggleLicense(false)}
            onClose={() => setVisible('')}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default SensorDevice
