import { useState } from 'react'
import cx from 'classnames'

import { User } from 'icons/filled'
import { ResourceIcon } from 'icons/utils'
import MenuItem from 'shared/MenuItem'
import { channelMap } from 'utils/channel'
import { NotifChannel } from 'services/tags'

import SmsRecipients from './SmsRecipients'
import EmailRecipients from './EmailRecipients'
import WhatsAppRecipients from './WhatsAppRecipients'
import WebhookRecipients from './WebhookRecipients'
import SlackRecipients from './SlackRecipients'
import MsRecipients from './MsRecipients'
import PagerRecipients from './PagerRecipients'

function Recipients() {
  const [channel, setChannel] = useState<NotifChannel>('phone')

  const renderChannel = (key: string) => {
    const channelName = key as NotifChannel
    const { label, iconType } = channelMap[channelName]

    return (
      <MenuItem
        key={iconType}
        className={cx('py-2.5', channelName === channel && 'bg-light-hover')}
        onClick={() => setChannel(channelName)}
      >
        <ResourceIcon className="w-5 h-5" iconType="primary" type={iconType} />
        <div className="text-[0.8125rem]">{label}</div>
        <div className="ml-auto inline-flex gap-1 bg-white rounded-3xl border border-light-stroke px-[6px] py-[3px]">
          <User className="w-4 h-4" color="#bdbdbd" />
          <div className="text-sm">0</div>
        </div>
      </MenuItem>
    )
  }

  return (
    <div className="relative w-full min-h-[calc(100vh_-_9.6875rem)] flex bg-[rgba(104,113,116,0.08)]">
      <div className="w-64 shrink-0 bg-white p-4 border-r border-light-stroke">
        {Object.keys(channelMap).map(renderChannel)}
      </div>
      <div className="w-full p-4">
        {channel === 'phone' && <SmsRecipients />}
        {channel === 'email' && <EmailRecipients />}
        {channel === 'whatsapp' && <WhatsAppRecipients />}
        {channel === 'webhook' && <WebhookRecipients />}
        {channel === 'slack' && <SlackRecipients />}
        {channel === 'microsoft_teams' && <MsRecipients />}
        {channel === 'pager_duty' && <PagerRecipients />}
      </div>
    </div>
  )
}

export default Recipients
