import { useState } from 'react'
import { format as formatDate } from 'date-fns'
import cx from 'classnames'

import { ArrowRight, Calendar, Close } from 'icons/outline'
import { Popover } from 'components'
import { dateTimeFormat } from 'utils/dateTime'
import Picker from './Picker'

type DateStringValues = [string | null, string | null]
type DateValues = [Date | null, Date | null]
type DateValue = Date | null | undefined

interface Props {
  showPresets?: boolean
  showTime?: boolean
  allowClear?: boolean
  size?: 'small' | 'default' | 'large'
  block?: boolean
  className?: string
  format?: string
  value?: DateValues
  placeholder?: [string, string]
  onChange?: (dateStr: DateStringValues, date: DateValues) => void
  popupContainer?: () => HTMLElement
}

const sizes = {
  small: 'h-6 py-0 pl-[0.4375rem] text-sm',
  default: 'h-8 py-1 pl-2.5 text-sm',
  large: 'h-10 pl-2.5 py-1.5 text-md'
}

export function RangePicker({
  showPresets,
  showTime,
  allowClear,
  size = 'default',
  block,
  className,
  format,
  value = [null, null],
  placeholder = ['', ''],
  onChange,
  popupContainer
}: Props) {
  const [startDate, setStartDate] = useState<DateValue>(value[0])
  const [endDate, setEndDate] = useState<DateValue>(value[1])

  const defaultFormat =
    format ||
    `${dateTimeFormat['MM-dd-yyyy']} ${showTime ? dateTimeFormat['HH:mm'] : ''}`

  const handleDateChange = (date: DateValues) => {
    const [start, end] = date
    if (!start && !end) return handleClear()
    setStartDate(start)
    setEndDate(end)
    const formattedStart = start ? formatDate(start, defaultFormat) : null
    const formattedEnd = end ? formatDate(end, defaultFormat) : null
    onChange?.([formattedStart, formattedEnd], date)
  }

  const handleClear = () => {
    setStartDate(null)
    setEndDate(null)
    onChange?.([null, null], [null, null])
  }

  return (
    <div
      className={cx(
        'relative border border-light-stroke rounded-lg w-full',
        'base-transition hover:border-primary focus:border-primary outline-0',
        'flex items-center cursor-text bg-white',
        sizes[size],
        block && 'block',
        className
      )}
    >
      <input
        readOnly
        value={!!startDate ? formatDate(startDate, defaultFormat) : ''}
        placeholder={placeholder[0]}
        className={cx(
          'appearance-none outline-0 w-full pointer-events-none',
          'placeholder:text-light-secondary placeholder:font-normal'
        )}
      />
      <ArrowRight className="shrink-0 w-3 h-3 mx-2.5" />
      <input
        readOnly
        value={!!endDate ? formatDate(endDate, defaultFormat) : ''}
        placeholder={placeholder[1]}
        className={cx(
          'appearance-none outline-0 w-full mr-1.5 pointer-events-none',
          'placeholder:text-light-secondary placeholder:font-normal'
        )}
      />
      {allowClear && (startDate || endDate) && (
        <div className="w-3 h-full shrink-0 inline-flex justify-center items-center z-[1]">
          <Close
            className="w-3 h-3 rounded-full bg-[#a7a7a7] p-[3px] text-white cursor-pointer"
            onClick={handleClear}
          />
        </div>
      )}
      <div className="w-8 h-full shrink-0 inline-flex justify-center items-center">
        <Calendar className="w-4 h-4 " />
      </div>
      <Popover
        popupContainer={popupContainer}
        placement="bottom-start"
        className="absolute top-0 left-0 w-full h-full"
        content={onClose => (
          <Picker
            selectsRange
            showTime={showTime}
            showPresets={showPresets}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            selectsStart={!endDate}
            selectsEnd={!!startDate && !!endDate}
            onChange={handleDateChange}
            onClose={onClose}
          />
        )}
      />
    </div>
  )
}
