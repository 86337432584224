import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function User(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="19"
        viewBox="0 0 16 19"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M15.7742 18.5449H13.9964C13.9964 15.5458 11.4809 13.1067 8.38667 13.1067C5.29244 13.1067 2.77778 15.5467 2.77778 18.5449H1C1 14.5645 4.31467 11.3289 8.38756 11.3289C12.4596 11.3289 15.7742 14.5645 15.7742 18.5449Z"
          strokeWidth="0.25"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.51294 5.87556C3.51294 8.56267 5.7005 10.7511 8.38761 10.7511C11.0765 10.7511 13.2641 8.56444 13.2641 5.87556C13.2641 3.18667 11.0765 1 8.38761 1C5.69961 1 3.51205 3.18756 3.51294 5.87556ZM5.28983 5.87556C5.28983 4.16711 6.67916 2.77778 8.38761 2.77778C10.0952 2.77778 11.4863 4.16711 11.4863 5.87556C11.4863 7.584 10.0969 8.97333 8.38761 8.97333C6.67916 8.97333 5.28983 7.584 5.28983 5.87556Z"
        />
        <path d="M3.51294 5.87556H3.63794L3.63794 5.87551L3.51294 5.87556ZM8.38761 10.6261C5.76956 10.6261 3.63794 8.49366 3.63794 5.87556H3.38794C3.38794 8.63167 5.63143 10.8761 8.38761 10.8761V10.6261ZM13.1391 5.87556C13.1391 8.49538 11.0075 10.6261 8.38761 10.6261V10.8761C11.1455 10.8761 13.3891 8.63351 13.3891 5.87556H13.1391ZM8.38761 1.125C11.0075 1.125 13.1391 3.25573 13.1391 5.87556H13.3891C13.3891 3.1176 11.1455 0.875 8.38761 0.875V1.125ZM3.63794 5.87551C3.63707 3.25659 5.76863 1.125 8.38761 1.125V0.875C5.63058 0.875 3.38703 3.11852 3.38794 5.8756L3.63794 5.87551ZM8.38761 2.65278C6.61013 2.65278 5.16483 4.09808 5.16483 5.87556H5.41483C5.41483 4.23615 6.7482 2.90278 8.38761 2.90278V2.65278ZM11.6113 5.87556C11.6113 4.09801 10.1641 2.65278 8.38761 2.65278V2.90278C10.0262 2.90278 11.3613 4.23622 11.3613 5.87556H11.6113ZM8.38761 9.09833C10.166 9.09833 11.6113 7.65306 11.6113 5.87556H11.3613C11.3613 7.51494 10.0279 8.84833 8.38761 8.84833V9.09833ZM5.16483 5.87556C5.16483 7.65304 6.61013 9.09833 8.38761 9.09833V8.84833C6.7482 8.84833 5.41483 7.51497 5.41483 5.87556H5.16483Z" />
      </svg>
    </SVGUniqueID>
  )
}
