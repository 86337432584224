import { useState } from 'react'
import shallow from 'zustand/shallow'

import { Close } from 'icons/outline'
import { Button, Modal, ModalProps } from 'components'
import CreditCardForm from 'shared/Forms/CreditCardForm'
import toast from 'utils/toast'
import { ResponseError } from 'services/client'
import {
  getDefaultCreditCard,
  updateDefaultCreditCard
} from 'services/subscriptions'
import useStore from 'store'

interface Props extends ModalProps {
  type: 'add' | 'edit'
  onSuccess?: () => void
}

function ModalAddEditCreditCard({ type, onSuccess, ...props }: Props) {
  const [loading, setLoading] = useState(false)
  const updateSub = useStore(state => state.sub.updateSubscription, shallow)

  const handleClose = () => {
    !loading && props.onClose()
  }

  const handleUpdateCreditCard = async (paymentToken: string) => {
    try {
      setLoading(true)
      await updateDefaultCreditCard(paymentToken)
      const res = await getDefaultCreditCard()
      updateSub({ card: res.data })
      toast.success(
        { title: 'Payment method updated' },
        { position: 'bottom-center' }
      )
      setLoading(false)
      handleClose()
    } catch (err: any) {
      const { message, errors } = err as ResponseError
      toast.error({ title: message, description: errors })
      setLoading(false)
    }
  }

  return (
    <Modal {...props} className="w-[28.125rem]" onClose={handleClose}>
      <div className="flex items-center gap-4 justify-between mb-6">
        <div className="text-base font-medium capitalize">
          {type} Payment Method
        </div>
        <Close className="shrink-0 cursor-pointer" onClick={handleClose} />
      </div>
      <CreditCardForm onSubmit={handleUpdateCreditCard}>
        {({ disabled, onClick }) => (
          <div className="mt-8 flex items-center justify-between gap-4">
            <Button
              disabled={loading}
              className="w-[5rem]"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={disabled || loading}
              className="min-w-[6.25rem]"
              variant="primary"
              onClick={onClick}
            >
              Save
            </Button>
          </div>
        )}
      </CreditCardForm>
    </Modal>
  )
}

export default ModalAddEditCreditCard
