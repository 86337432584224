import { Fragment, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import { useAsync } from 'hooks'
import { Button } from 'components'
import ModalVerifyPassword from 'shared/Modals/VerifyPassword'
import { enable2FA, disable2FA } from 'services/2fa'
import useStore, { Store } from 'store'

import RecoveryCode from './RecoveryCode'
import BrowserSessions from './BrowserSessions'
import MobileSessions from './MobileSessions'

const mapState = (state: Store) => ({
  user: state.auth.currentUser,
  updateUser: state.auth.updateUser
})

function SecurityLogin() {
  const [showVerify, setShowVerify] = useState(false)

  const { user, updateUser } = useStore(mapState, shallow)
  const enabled = !!user?.enabled_2fa

  const toggle2FAAsync = useAsync()

  const handleToggle2FA = () => {
    toggle2FAAsync
      .execute(enabled ? disable2FA() : enable2FA())
      .then(() => updateUser({ enabled_2fa: !enabled }))
      .catch(err => err.status === 423 && setShowVerify(true))
  }

  return (
    <Fragment>
      <div className="p-[1.3rem]">
        <div className="text-xl mb-5 font-medium">
          Security & Login Settings
        </div>
        <div className="flex flex-wrap items-center justify-between gap-4">
          <div>
            <div className="text-base font-medium mb-2">
              Two-Factor Authentication
            </div>
            <div className="text-[0.8125rem] inline-flex gap-2">
              <div className={cx('font-semibold', enabled && 'text-success')}>
                {enabled ? 'On' : 'Off'}
              </div>
              <div className="text-light-secondary">
                {enabled
                  ? 'We will request an authentication code if we notice a login attempt from an unrecognized device or browser.'
                  : 'We will NOT request an authentication code if we notice a login attempt from an unrecognized device or browser.'}
              </div>
            </div>
          </div>
          <Button
            disabled={toggle2FAAsync.isLoading}
            className="w-[5.625rem]"
            variant={!enabled ? 'primary' : 'secondary'}
            onClick={handleToggle2FA}
          >
            Turn {enabled ? 'Off' : 'On'}
          </Button>
        </div>
        {enabled && <RecoveryCode />}
        <div className="border-b border-light-stroke border-dashed my-8" />
        <BrowserSessions />
        <div className="border-b border-light-stroke border-dashed my-8" />
        <MobileSessions />
      </div>
      <AnimatePresence initial={false}>
        {!!showVerify && (
          <ModalVerifyPassword
            onSuccess={handleToggle2FA}
            onClose={() => setShowVerify(false)}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default SecurityLogin
