import {
  Fragment,
  UIEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import cx from 'classnames'

import { Union } from 'icons/filled'
import { Chart } from 'icons/outline'
import { DeviceIcon } from 'icons/utils'
import { Slider } from 'components'
import { hmsToSeconds, secondsToHms } from 'utils/dateTime'
import {
  getDeviceStatus,
  MAX_VIDEO_LENGTH,
  MAX_VIDEO_LENGTH_SEC
} from 'utils/device'
import { Device } from 'services/devices'

import PlaybackNotif from '../PlaybackNotif'
import { usePlaybackCtx } from '../PlaybackContext'

const DEFAULT_TIMELINE_WIDTH = 1700

interface Props {
  devices: (Device | null)[]
}

function PlaybackTimeline({ devices }: Props) {
  const { currentTime, players, dispatch } = usePlaybackCtx()

  const [timelineWidth, setTimelineWidth] = useState(DEFAULT_TIMELINE_WIDTH)
  const [scale, setScale] = useState(() => (window.innerWidth > 1920 ? 40 : 0))

  const timelineRef = useRef<HTMLDivElement>(null)
  const timeGraphRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimelineWidth(
      (timelineRef.current?.querySelector('.timeline-lines') as HTMLDivElement)
        ?.offsetWidth || DEFAULT_TIMELINE_WIDTH
    )
  }, [scale])

  const timelineWidthPx = useMemo(() => {
    return scale < 30
      ? DEFAULT_TIMELINE_WIDTH
      : scale === 60
      ? 35000
      : DEFAULT_TIMELINE_WIDTH * (scale / 20)
  }, [scale])

  const widthPerSec = (timelineWidthPx - 16) / MAX_VIDEO_LENGTH_SEC
  const indicatorPos = widthPerSec * currentTime

  const handleSeek = (pageX: number) => {
    r.current.lastPageX = pageX
    const { minPageX, maxPageX, scrollX, widthPerSec } = r.current
    if (pageX > maxPageX) {
      pageX = maxPageX
    } else if (pageX < minPageX) {
      pageX = minPageX
    }
    pageX += scrollX
    dispatch({
      type: 'SET_PLAYBACK_TIME',
      payload: (pageX - minPageX) / widthPerSec
    })
  }

  const handleTimelineMouseDown: React.MouseEventHandler<
    HTMLDivElement
  > = e => {
    const isPrimaryClick =
      e.nativeEvent.which === 1 ||
      e.button === 0 ||
      e.buttons === 0 ||
      e.type === 'click'
    const hasSpecialKey = e.ctrlKey || e.altKey || e.shiftKey || e.metaKey
    if (!isPrimaryClick || hasSpecialKey) {
      return
    }
    e.preventDefault()
    r.current.lastPageX = -1
    r.current.widthPerSec = widthPerSec // need to cache?
    const d = e.target as HTMLDivElement
    const bcr = d.getBoundingClientRect()
    r.current.minPageX = bcr.left + 8 // padding
    r.current.maxPageX = bcr.right - 8 // padding
    r.current.scrollX = d.scrollLeft || 0
    onTimelineMouseUp(e.nativeEvent)
    window.addEventListener('mousemove', r.current.onTimelineMouseMove)
    window.addEventListener('mouseup', r.current.onTimelineMouseUp)
  }

  const onTimelineMouseMove = (e: MouseEvent) => {
    e.preventDefault()
    handleSeek(e.pageX)
  }

  const onTimelineMouseUp = (e: MouseEvent) => {
    e.preventDefault()
    window.removeEventListener('mousemove', r.current.onTimelineMouseMove)
    window.removeEventListener('mouseup', r.current.onTimelineMouseUp)
    if (e.pageX !== r.current.lastPageX) {
      handleSeek(e.pageX)
    }
  }

  const r = useRef({
    onTimelineMouseMove,
    onTimelineMouseUp,
    lastPageX: 0,
    widthPerSec: 0,
    minPageX: 0,
    maxPageX: 0,
    scrollX: 0
  })

  const calcSegment = () => {
    const seg = Math.round(MAX_VIDEO_LENGTH / (Math.abs(scale - 60) || 1))
    const widthPerSeg = (timelineWidth - 16) / seg
    return { seg, widthPerSeg }
  }

  const getEndTime = (endTime: string) => {
    const end = hmsToSeconds(endTime)
    return end > MAX_VIDEO_LENGTH_SEC ? MAX_VIDEO_LENGTH_SEC : end
  }

  const renderLines = () => {
    const { seg, widthPerSeg } = calcSegment()
    const lines: JSX.Element[] = []

    for (let i = 1; seg >= i; i++) {
      const totalLine = scale === 60 ? 5 : 10
      const widthPerLine = widthPerSeg / totalLine
      lines.push(
        <div style={{ width: widthPerSeg, display: 'inline-flex' }} key={i}>
          {Array.from({ length: totalLine }).map((_, idx) => (
            <div
              key={idx}
              className="inline-flex"
              style={{
                width: widthPerLine,
                justifyContent:
                  i === 1 && idx === 0 ? 'space-between' : 'flex-end'
              }}
            >
              {i === 1 && idx === 0 && (
                <div
                  className="w-px bg-[#c1c1c1] shrink-0"
                  style={{ height: 12 }}
                />
              )}
              <div
                className="w-px bg-[#c1c1c1] shrink-0"
                style={{ height: idx + 1 === totalLine ? 12 : 5 }}
              />
            </div>
          ))}
        </div>
      )
    }

    return lines
  }

  const renderTimes = () => {
    const { seg, widthPerSeg } = calcSegment()
    const times: JSX.Element[] = []

    for (let i = 1; seg >= i; i++) {
      times.push(
        <div
          key={i}
          style={{
            width: widthPerSeg,
            display: 'inline-flex',
            justifyContent: i === 1 ? 'space-between' : 'flex-end'
          }}
        >
          {i === 1 && <span>00:00:00</span>}
          <span>{secondsToHms(i * 60 * (MAX_VIDEO_LENGTH / seg))}</span>
        </div>
      )
    }

    return times
  }

  const renderSelectedDevices = () => {
    return devices.map((device, idx) => {
      if (!device) return null
      const isOnline = getDeviceStatus(device) === 'online'
      return (
        <div
          key={idx}
          className="flex items-center gap-1 border-r border-light-stroke w-[calc(calc(11.5rem_+_8px))] h-[1.65rem] px-2 py-1 odd:bg-white even:bg-gray-100"
        >
          <DeviceIcon
            type={device.type}
            className={cx(
              'shrink-0 w-4 h-4',
              isOnline ? 'text-success' : 'text-danger'
            )}
          />
          <div className="whitespace-nowrap	overflow-hidden text-ellipsis">
            {device.name}
          </div>
        </div>
      )
    })
  }

  const renderGraph = (device: Device | null, index: number) => {
    if (!device) return null
    return (
      <div
        key={index}
        style={{ width: timelineWidthPx }}
        className="w-full h-[1.65rem] relative bg-white"
      >
        <div
          style={{ width: timelineWidthPx }}
          className="absolute top-0 left-0 h-full z-[3] border-b border-light-stroke"
        >
          <div
            style={{ width: timelineWidthPx - 16 }}
            className="absolute top-0 left-0 h-full mx-2"
          >
            <div
              style={{ left: indicatorPos }}
              className="absolute top-0 left-2 w-[2px] h-[105%] bg-primary pointer-events-none"
            />
          </div>
        </div>
        {players[index]?.segments.map(({ starttime, endtime }) => {
          const start = widthPerSec * hmsToSeconds(starttime) + 8
          const end = widthPerSec * getEndTime(endtime) + 8
          return (
            <div
              key={starttime}
              style={{ left: start, width: end - start }}
              className="absolute z-[1] w-[1.875rem] h-full bg-[linear-gradient(180deg,#efefef_0%,#d6d6d6_100%)]"
            />
          )
        })}
        {players[index]?.notifs.map(notif => (
          <PlaybackNotif
            key={notif.id}
            notif={notif}
            left={widthPerSec * hmsToSeconds(notif.starttime) + 8}
          />
        ))}
      </div>
    )
  }

  const handleScroll: UIEventHandler<HTMLDivElement> = event => {
    const timelineEl = timelineRef.current?.querySelector('.timeline')
    const listEl = timeGraphRef.current?.querySelector('.device-list')
    timelineEl!.scrollLeft = (event.target as HTMLDivElement).scrollLeft
    listEl!.scrollTop = (event.target as HTMLDivElement).scrollTop
  }

  return (
    <Fragment>
      <div
        ref={timelineRef}
        className="playback-timeline w-full h-12 border-t border-light-stroke flex overflow-x-hidden overflow-y-scroll"
      >
        <div className="shrink-0 inline-flex items-center justify-center w-[calc(11.5rem_+_8px)] h-full border-r border-b-2 border-light-stroke p-2 bg-gray-100">
          <Chart className="text-[#AEAEAE] mr-3 w-3 shrink-0" />
          <Slider
            className="mt-2"
            max={60}
            step={10}
            value={scale}
            onChange={setScale}
          />
          <Chart className="text-[#AEAEAE] ml-3 w-4 shrink-0" />
        </div>
        <div
          className="timeline relative w-full overflow-hidden bg-[#f9f9f9]"
          onMouseDown={handleTimelineMouseDown}
        >
          <div className="sticky top-0 left-0 w-full leading-4 text-center text-[0.8125rem] px-2 bg-[linear-gradient(180deg,#f3f3f3_0%,#e1e1e1_100%)] pointer-events-none select-none">
            {secondsToHms(currentTime)}
          </div>
          <div
            style={{ width: timelineWidthPx }}
            className="timeline-lines flex w-full h-3 px-2 pointer-events-none"
          >
            {renderLines()}
          </div>
          <div
            style={{ width: timelineWidthPx }}
            className="h-[calc(2rem_-_12px)] text-xs flex items-center px-2 select-none pointer-events-none"
          >
            {renderTimes()}
          </div>
          <div
            style={{ width: timelineWidthPx }}
            className="absolute top-0 left-0 h-full border-b-2 border-light-stroke pointer-events-none"
          >
            <div
              style={{ width: timelineWidthPx - 16 }}
              className="absolute top-0 left-0 w-full h-[calc(100%_-_1rem)] mt-4 mx-2 cursor-pointer z-[1]"
            >
              <div
                style={{ left: indicatorPos }}
                className="absolute top-0 left-2 w-[2px] h-[110%] bg-primary pointer-events-none"
              />
            </div>
            <Union
              type="primary"
              style={{ left: indicatorPos + 1 }}
              className="absolute top-0 left-[.5px]"
            />
          </div>
        </div>
      </div>
      <div
        ref={timeGraphRef}
        className="playback-timegraph flex w-full h-[6.9375rem] bg-white overflow-hidden"
      >
        <div className="device-list shrink-0 w-[calc(11.5rem_+_8px)] overflow-x-scroll overflow-y-hidden">
          {renderSelectedDevices()}
        </div>
        <div
          className="graph w-full relative overflow-scroll"
          onScroll={handleScroll}
        >
          <div style={{ width: timelineWidthPx }} className="h-px absolute" />
          {devices.map(renderGraph)}
        </div>
      </div>
    </Fragment>
  )
}

export default PlaybackTimeline
