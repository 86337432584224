import { Fragment, useState } from 'react'
import cx from 'classnames'

import { Close } from 'icons/outline'
import { Modal, ModalProps } from 'components'
import MenuItem from 'shared/MenuItem'

import guidebookTabs from './tabs'

function ModalGuideBook(props: ModalProps) {
  const [activeTab, setActiveTab] = useState(guidebookTabs[0])

  return (
    <Modal {...props} className="!p-0 w-[62.5rem]">
      <div className="flex items-center justify-between gap-4 border-b border-light-stroke p-4">
        <div className="text-base font-medium">
          Getting Started with Broadflow Streams
        </div>
        <Close className="cursor-pointer shrink-0" onClick={props.onClose} />
      </div>
      <div className="flex">
        <div className="w-60 border-r border-light-stroke shrink-0 mb-4">
          {guidebookTabs.map((tab, idx) => (
            <Fragment key={tab.label}>
              <MenuItem
                onClick={() => setActiveTab(tab)}
                className={cx(
                  'rounded-none h-10 mb-0.5 px-4 text-[0.8125rem]',
                  activeTab.label === tab.label && 'bg-light-hover font-medium'
                )}
              >
                <tab.Icon className="w-5 h-5 shrink-0" />
                {tab.label}
              </MenuItem>
              {(idx === 2 || idx === 5 || idx === 7) && (
                <div className="border-b border-[#EEEEEE] my-3 mx-2" />
              )}
            </Fragment>
          ))}
        </div>
        <activeTab.Component />
      </div>
    </Modal>
  )
}

export default ModalGuideBook
