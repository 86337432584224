import { Close } from 'icons/outline'
import { Modal, ModalProps, Tag } from 'components'
import { Tag as ITag } from 'services/tags'

interface Props extends ModalProps {
  tags: ITag[]
}

function ModalShowAllTags({ tags, ...props }: Props) {
  return (
    <Modal {...props} className="w-[25rem] !p-0">
      <div className="p-4 flex items-center justify-between gap-4 border-b border-light-stroke">
        <div className="text-base font-medium">All Tags</div>
        <Close onClick={props.onClose} />
      </div>
      <div className="p-4 flex flex-wrap items-center gap-2">
        {tags.map(tag => (
          <Tag
            key={tag.id}
            strokeColor={tag.stroke_color}
            textColor={tag.text_color}
            bgColor={tag.color}
          >
            {tag.name}
          </Tag>
        ))}
      </div>
    </Modal>
  )
}

export default ModalShowAllTags
