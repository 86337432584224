import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function ClockCircleFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M8 1C4.14005 1 1 4.14005 1 8C1 11.86 4.14005 15 8 15C11.86 15 15 11.86 15 8C15 4.14005 11.86 1 8 1ZM11.3291 11.6207C11.2153 11.7345 11.066 11.7917 10.9167 11.7917C10.7674 11.7917 10.618 11.7345 10.5043 11.6207L7.5876 8.7041C7.47791 8.59505 7.4167 8.44679 7.4167 8.2917V4.5C7.4167 4.17743 7.67796 3.9167 8 3.9167C8.32204 3.9167 8.5833 4.17743 8.5833 4.5V8.0502L11.3291 10.7959C11.5571 11.024 11.5571 11.3927 11.3291 11.6207Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
