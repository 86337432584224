import { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import Bridge from 'assets/images/Bridge-lg.png'
import { Close, Plus } from 'icons/outline'
import { InfoCircle } from 'icons/filled'
import { useAsync } from 'hooks'
import {
  Button,
  Form,
  FormField,
  Input,
  Modal,
  ModalProps,
  Select
} from 'components'
import ModalCreateUpdateLocation from 'shared/Modals/Location/CreateUpdateLocation'
import { required } from 'utils/rules'
import { getBridgeName } from 'utils/device'
import toast from 'utils/toast'
import { Bridge as IBridge, updateBridge } from 'services/bridges'
import useStore, { Store } from 'store'

interface Props extends ModalProps {
  bridge: IBridge
}

interface FormValues {
  name: string
  location?: { value: number; label: string }
  attach_id: string
}

const mapState = (state: Store) => ({
  user: state.auth.currentUser,
  locations: state.location.locations,
  updateBridge: state.bridge.updateBridge
})

function ModalSetupBridge({ bridge, ...props }: Props) {
  const [form] = Form.useForm()
  const { show } = useIntercom()
  const [visible, setVisible] = useState(false)

  const { user, locations, ...store } = useStore(mapState, shallow)
  const { execute, isLoading } = useAsync<AxiosResponse<{ data: IBridge }>>({
    showNotifOnError: true
  })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleSubmit = async ({ name, location, attach_id }: FormValues) => {
    await execute(
      updateBridge({
        id: bridge.id,
        name,
        attach_id,
        location_id: location?.value
      })
    )
    store.updateBridge({
      id: bridge.id,
      name,
      location_id: location?.value,
      location: locations.find(l => l.id === location?.value)
    })
    toast.success({ title: 'Bridge updated' }, { position: 'bottom-center' })
    handleClose()
  }

  return (
    <Modal {...props} className="w-[31.25rem]" onClose={handleClose}>
      <div className="flex items-center justify-between mb-6">
        <div className="text-base font-medium capitalize">Set Up Bridge</div>
        <Close className="cursor-pointer" onClick={handleClose} />
      </div>
      <div className="w-64 mx-auto my-8">
        <img src={Bridge} alt="bridge" />
      </div>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          name: getBridgeName(bridge),
          location: { label: bridge.location?.name, value: bridge.location?.id }
        }}
      >
        <FormField
          name="name"
          label="Bridge Name"
          rules={[required]}
          requiredMask
        >
          <Input placeholder="Name the Bridge" />
        </FormField>
        <FormField
          name="location"
          label={
            <div className="flex items-center justify-between">
              <div>Device Location</div>
              <div
                className="inline-flex items-center text-primary cursor-pointer gap-1"
                onClick={() => setVisible(true)}
              >
                <Plus className="w-3 h-3" /> Create Location
              </div>
            </div>
          }
        >
          <Select
            placeholder="Select bridge location"
            options={locations.map(l => ({ label: l.name, value: l.id }))}
          />
        </FormField>
        <FormField
          name="attach_id"
          label="Attach ID"
          rules={[required]}
          requiredMask
        >
          <Input placeholder="Enter bridge attach ID" />
        </FormField>
        <div className="flex gap-2 p-2 rounded-lg mb-6 bg-[#D7F7DF] text-[0.8125rem]">
          <InfoCircle type="success" className="w-4 h-4 shrink-0 mt-[2px]" />
          <div>
            <div className="text-success">
              The license key was sent to{' '}
              <span className="font-medium">{user?.email}</span>
            </div>
            <div className="mt-1 text-light-secondary">
              Can't find your license key?{' '}
              <span
                className="cursor-pointer text-primary font-medium underline"
                onClick={show}
              >
                Contact Support
              </span>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end">
          <Button
            variant="primary"
            className="w-[6.25rem]"
            disabled={isLoading}
          >
            Set Up
          </Button>
        </div>
      </Form>
      <AnimatePresence initial={false}>
        {visible && (
          <ModalCreateUpdateLocation onClose={() => setVisible(false)} />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default ModalSetupBridge
