import { FC } from 'react'
import cx from 'classnames'

import { DEFAULT_TAG_COLOR } from 'configs/tagColors'

type Props = {
  strokeColor?: string | null
  textColor?: string | null
  bgColor?: string | null
  rounded?: string
  innerClassName?: string
} & JSX.IntrinsicElements['div']

export const Tag: FC<Props> = ({
  strokeColor,
  textColor,
  bgColor,
  rounded = 'rounded-[1.25rem]',
  innerClassName,
  children,
  ...props
}) => {
  const color = {
    strokeColor: strokeColor || DEFAULT_TAG_COLOR.strokeColor,
    textColor: textColor || DEFAULT_TAG_COLOR.textColor,
    bgColor: bgColor || DEFAULT_TAG_COLOR.bgColor
  }

  return (
    <div
      {...props}
      style={{ background: color.strokeColor, ...props.style }}
      className={cx('relative inline-block p-px', rounded, props.className)}
    >
      <span
        style={{ background: color.bgColor, color: color.textColor }}
        className={cx(
          rounded,
          'w-full h-full text-[0.8125rem] px-1.5 py-[5px]',
          'flex justify-center items-center text-center leading-none',
          innerClassName
        )}
      >
        {children}
      </span>
    </div>
  )
}
