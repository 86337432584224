import { Fragment, useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'

import * as AuthLogo from 'icons/logos'
import { Copy, Refresh } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Spinner } from 'components'
import ModalVerifyPassword from 'shared/Modals/VerifyPassword'
import { copyToClipBoard } from 'utils/functions'
import {
  checkConfirmPassword,
  getQrCode,
  getRecoveryCodes,
  regenRecoveryCodes
} from 'services/2fa'

function RecoveryCode() {
  const [visible, setVisible] = useState(true)
  const [showVerify, setShowVerify] = useState(false)

  const verifyAsync = useAsync<AxiosResponse<{ confirmed: boolean }>>()
  const qrCodeAsync = useAsync<AxiosResponse<{ svg: string }>>()
  const recoveryCodeAsync = useAsync<AxiosResponse<string[]>>()
  const regenCodeAsync = useAsync<AxiosResponse>()

  useEffect(() => {
    if (visible) handleGetRecoveryCode()
  }, [visible])

  const handleGetRecoveryCode = async () => {
    await Promise.all([
      qrCodeAsync.execute(getQrCode()),
      recoveryCodeAsync.execute(getRecoveryCodes())
    ]).catch(err => {
      if (err.status === 423) {
        setShowVerify(true)
        setVisible(false)
      }
    })
  }

  const handleToggleRecoveryCode = async () => {
    if (!visible) {
      const res = await verifyAsync.execute(checkConfirmPassword())
      if (res.data.confirmed) return setVisible(true)
      setShowVerify(true)
    }
    setVisible(false)
  }

  const handleCopyCode = (str?: string) => {
    copyToClipBoard(
      str ||
        (recoveryCodeAsync.data?.data || []).reduce(
          (acc, cur) => (acc += cur + '\n'),
          ''
        )
    )
  }

  const handleRegenRecoveryCode = async () => {
    try {
      await regenCodeAsync.execute(regenRecoveryCodes())
      await recoveryCodeAsync.execute(getRecoveryCodes())
    } catch (err: any) {
      if (err.status === 423) setShowVerify(true)
    }
  }

  const loading = verifyAsync.isLoading || qrCodeAsync.isLoading

  return (
    <div className="mt-3">
      <Button
        disabled={loading || recoveryCodeAsync.isLoading}
        onClick={handleToggleRecoveryCode}
      >
        {visible ? 'Hide' : 'Show'} Recovery Code
      </Button>
      {visible && (
        <div className="bg-white mt-3 p-4 rounded-lg">
          {loading && <Spinner size="small" className="h-20" />}
          {!loading && (
            <Fragment>
              <div className="text-sm font-semibold mb-2">
                Scan the following QR code using your phone's authenticator
                application.
              </div>
              {qrCodeAsync.data?.data.svg && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: qrCodeAsync.data.data.svg
                  }}
                />
              )}
              <div className="mt-10">
                <div className="text-sm font-semibold mb-2">
                  Don't have an authenticator app?
                </div>
                <div className="text-light-secondary text-[0.8125rem]">
                  The following authenticator apps are available for both iOS
                  and Android. Download one and use it to scan the QR code
                  above.
                </div>
                <div className="flex items-center gap-2 mt-2.5">
                  <div className="inline-flex items-center gap-1 text-[0.8125rem] bg-[#ececec] px-2 py-1.5 rounded-lg border border-light-stroke">
                    <AuthLogo.GoogleAuthenticator className="w-5 h-5 shrink-0" />
                    <div className="pt-px">Google Authenticator</div>
                  </div>
                  <div className="inline-flex items-center gap-1 text-[0.8125rem] bg-[#ececec] px-2 py-1.5 rounded-lg border border-light-stroke">
                    <AuthLogo.MicrosoftAuthenticator className="w-5 h-5 shrink-0" />
                    <div className="pt-px">Microsoft Authenticator</div>
                  </div>
                  <div className="inline-flex items-center gap-1 text-[0.8125rem] bg-[#ececec] px-2 py-1.5 rounded-lg border border-light-stroke">
                    <AuthLogo.Authy className="w-5 h-5 shrink-0" />
                    <div className="pt-px">Authy</div>
                  </div>
                </div>
                <div className="text-success text-[0.8125rem] mt-2.5">
                  Note: Other authenticator apps may work as well but it is
                  recommended to use one of the above.
                </div>
              </div>
              <div className="mt-10">
                <div className="text-sm font-semibold mb-2">
                  Store these recovery codes in a safe place or secure password
                  manager.
                </div>
                <div className="text-light-secondary text-[0.8125rem]">
                  They can be used to recover access to your account if your two
                  factor authentication device is lost.
                </div>
                <div className="mt-4">
                  <Button className="mr-2" onClick={() => handleCopyCode()}>
                    <Copy /> Copy All
                  </Button>
                  <Button
                    disabled={regenCodeAsync.isLoading}
                    onClick={handleRegenRecoveryCode}
                  >
                    <Refresh /> Regenerate Recovery Code
                  </Button>
                </div>
                {!!recoveryCodeAsync.data?.data.length && (
                  <div className="mt-5 flex flex-col gap-3">
                    {recoveryCodeAsync.data.data.map(code => (
                      <div
                        key={code}
                        className="inline-flex items-center gap-4 w-fit text-sm bg-[#fbfbfb] border border-light-stroke px-3 py-1.5 rounded-lg"
                      >
                        <div className="w-64">{code}</div>
                        <div
                          className="text-primary cursor-pointer"
                          onClick={() => handleCopyCode(code)}
                        >
                          Copy
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </div>
      )}
      <AnimatePresence initial={false}>
        {!!showVerify && (
          <ModalVerifyPassword
            onSuccess={() => setVisible(true)}
            onClose={() => setShowVerify(false)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default RecoveryCode
