import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import cx from 'classnames'
import shallow from 'zustand/shallow'

import { Search } from 'icons/outline'
import { DeviceIcon } from 'icons/utils'
import { useAsync } from 'hooks'
import { Input, Popover } from 'components'
import MenuItem from 'shared/MenuItem'
import { getDeviceStatus } from 'utils/device'
import { Device } from 'services/devices'
import { updateFloorPlan } from 'services/floorPlans'
import useStore from 'store'

import { useLocationCtx } from '../LocationContext'

interface Props {
  onClose: () => void
  position: { lat: number; lng: number }
}

function DeviceList({ onClose, position }: Props) {
  const [search, setSearch] = useState('')

  const { execute } = useAsync({ showNotifOnError: true })
  const { floorPlans, activeFloorPlan, dispatch } = useLocationCtx()
  const devices = useStore(
    state =>
      state.device.devices.filter(
        d => !activeFloorPlan?.devices.find(dFp => dFp.id === d.id)
      ),
    shallow
  )

  const handleSelectDevice = (device: Device) => {
    if (!activeFloorPlan) return

    const { lat, lng } = position
    const newPinnedDevices = [
      ...activeFloorPlan.devices,
      { ...device, lat: lat.toString(), lng: lng.toString() }
    ]

    execute(
      updateFloorPlan(activeFloorPlan.id, {
        linkedDevices: newPinnedDevices.map(d => ({
          device_id: d.id,
          lat: d.lat,
          lng: d.lng
        }))
      })
    )

    dispatch({
      floorPlans: floorPlans.map(fp =>
        fp.id === activeFloorPlan.id
          ? { ...activeFloorPlan, devices: newPinnedDevices }
          : fp
      )
    })
    onClose()
  }

  const statusCls = (device: Device) => {
    return getDeviceStatus(device) === 'online' ? 'text-success' : 'text-danger'
  }

  return (
    <Popover.Content onClose={onClose} className="w-[18.75rem] max-h-[20rem]">
      <div className="text-[0.9375rem] font-medium mb-4">Add Device Pin</div>
      <Input
        className="mb-4"
        value={search}
        onChange={e => setSearch(e.target.value)}
        suffix={<Search />}
        placeholder="Search devices"
      />
      <PerfectScrollbar className="max-h-[10.5rem]">
        {devices
          .filter(d => d.name.toLowerCase().includes(search.toLowerCase()))
          .map(d => (
            <MenuItem
              key={d.id}
              className="!gap-1.5"
              onClick={() => handleSelectDevice(d)}
            >
              <DeviceIcon
                type={d.type}
                className={cx('w-4 h-4 shrink-0', statusCls(d))}
              />
              <div className="text-[0.8125rem]">{d.name}</div>
            </MenuItem>
          ))}
      </PerfectScrollbar>
    </Popover.Content>
  )
}

export default DeviceList
