import { Search } from 'icons/outline'
import { Input, Pagination } from 'components'

interface Props {
  loading: boolean
  search: string
  onSearchChange: (s: string) => void
  currentPage: number
  totalPage: number
  onPageChange: (page: number) => void
}

function TableToolbar({
  loading,
  search,
  onSearchChange,
  currentPage,
  totalPage,
  onPageChange
}: Props) {
  const handleSearch: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      value !== search && onSearchChange(value)
    }
  }

  return (
    <div className="my-4 flex flex-wrap justify-between items-center gap-2">
      <Input
        className="w-[22.5rem]"
        suffix={<Search />}
        placeholder="Search records by name"
        onKeyDown={loading ? undefined : handleSearch}
      />
      <Pagination.ButtonGroup
        loading={loading}
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={loading ? undefined : onPageChange}
      />
    </div>
  )
}

export default TableToolbar
