import shallow from 'zustand/shallow'

import NoResult from 'assets/images/NoResult.png'
import CameraDeviceCard from 'shared/DeviceCard/CameraDevice'
import { Bridge } from 'services/bridges'
import useStore from 'store'

interface Props {
  bridge: Bridge
}

function ConnectedCamera({ bridge }: Props) {
  const devices = useStore(state => state.device.devices, shallow)

  const connectedCameras = devices.filter(d =>
    bridge.devices.find(bd => bd.id === d.id)
  )

  return (
    <div className="mt-16">
      <div className="text-sm font-medium mb-3">Connected Camera</div>
      {!!connectedCameras.length && (
        <div className="grid grid-cols-[repeat(auto-fill,minmax(22rem,1fr))] gap-4">
          {connectedCameras.map(camera => (
            <CameraDeviceCard key={camera.id} device={camera} />
          ))}
        </div>
      )}
      {!connectedCameras.length && (
        <div className="select-none flex flex-col justify-center items-center py-8 bg-light-bg rounded-lg">
          <img className="w-[4rem]" src={NoResult} alt="no-result" />
          <div className="mt-3 text-sm text-black/30">No Data</div>
        </div>
      )}
    </div>
  )
}

export default ConnectedCamera
