import { useState } from 'react'
import cx from 'classnames'

import { Close } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Checkbox, Modal, ModalProps, TextArea } from 'components'
import toast from 'utils/toast'
import { addNotifNote } from 'services/notes'

interface Props extends ModalProps {
  notifId: number
}

function ModalAddNotifNote({ notifId, ...props }: Props) {
  const [note, setNote] = useState('')
  const [sendAll, setSendAll] = useState(false)

  const { execute, isLoading } = useAsync({ showNotifOnError: true })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleAddNote = async () => {
    try {
      await execute(addNotifNote(notifId, { body: note, notify: sendAll }))
      toast.success(
        { title: sendAll ? 'Note was added & sent' : 'Note added' },
        { position: 'bottom-center' }
      )
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal {...props} className="px-6 w-[40.625rem]" onClose={handleClose}>
      <div className="flex items-center gap-4 justify-between">
        <div className="text-base font-medium">Add Note</div>
        <Close className="shrink-0 cursor-pointer" onClick={handleClose} />
      </div>
      <div className="mt-6">
        <TextArea
          rows={7}
          value={note}
          className="mb-3"
          onChange={e => setNote(e.target.value)}
          placeholder="Write your note"
        />
        <Checkbox
          size="large"
          checked={sendAll}
          onChange={e => setSendAll(e.target.checked)}
        >
          Send this note to all recipients of this device
        </Checkbox>
        <div className="mt-8 flex items-center justify-between gap-4">
          <Button
            disabled={isLoading}
            className="w-[5rem]"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            className={cx(sendAll ? 'min-w-[7.5rem]' : 'min-w-[6.25rem]')}
            variant="primary"
            onClick={handleAddNote}
          >
            {sendAll ? 'Save & Send' : 'Save'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalAddNotifNote
