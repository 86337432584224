import { Modal, ModalProps } from 'components'
import LivestreamPlayer from 'shared/LivestreamPlayer'
import { Device } from 'services/devices'

interface Props extends ModalProps {
  device: Device
}

function ModalLivestream({ device, ...props }: Props) {
  return (
    <Modal {...props} className="!p-0 w-[57vw] h-[55vh] bg-black">
      <LivestreamPlayer className="rounded-lg" deviceId={device.id} />
    </Modal>
  )
}

export default ModalLivestream
