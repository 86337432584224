import { useState } from 'react'
import { motion } from 'framer-motion'

import { useAsync } from 'hooks'
import { Button, Form, FormField, Input } from 'components'
import { required } from 'utils/rules'
import { verify2FACode } from 'services/2fa'

interface Props {
  onSuccess: () => void
}

function TwoFAForm({ onSuccess }: Props) {
  const [useCode, setUseCode] = useState(true)
  const { execute, isLoading } = useAsync({ showNotifOnError: true })

  const handleSubmit = ({ code }: { code: string }) => {
    execute(verify2FACode({ [useCode ? 'code' : 'recovery_code']: code }))
      .then(() => onSuccess())
      .catch(console.error)
  }

  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      transition={{ type: 'tween', duration: 0.2 }}
      className="w-[25.625rem] m-auto bg-white p-6 rounded-lg"
    >
      <h3 className="text-[1.3125rem] font-medium">
        Enter auth code to continue
      </h3>
      <div className="text-sm mt-2.5 mb-8">
        To continue, use your authenticator application to generate a one time
        code or use one of your original recovery codes.
      </div>
      <Form onFinish={handleSubmit}>
        <FormField name="code" rules={[required]}>
          <Input
            block
            placeholder={useCode ? 'Enter 6-digit code' : 'Enter recovery code'}
          />
        </FormField>
        <Button
          block
          type="submit"
          size="large"
          variant="primary"
          disabled={isLoading}
        >
          Login
        </Button>
      </Form>
      <div
        className="mt-4 text-center text-primary text-sm font-medium cursor-pointer"
        onClick={() => setUseCode(prev => !prev)}
      >
        {useCode ? 'Use a recovery code' : 'Use an auth code'}
      </div>
    </motion.div>
  )
}

export default TwoFAForm
