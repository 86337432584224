import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function EyeClose(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="7"
        viewBox="0 0 16 7"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path d="M15.3898 0.105853C14.8736 -0.141137 14.254 0.105853 14.0475 0.670401C13.0493 3.03445 10.7088 4.55167 8.05854 4.55167C5.33942 4.55167 2.99891 3.03445 2.03517 0.670401C1.82866 0.24699 1.45005 0 1.03701 0C0.899337 0 0.76166 0.0352843 0.658403 0.0705686C0.107694 0.317559 -0.13324 0.917391 0.0732752 1.48194C1.34679 4.62224 4.47894 6.66873 8.02412 6.66873C11.5005 6.66873 14.5982 4.65753 15.9061 1.51722C16.1471 0.952676 15.9061 0.317559 15.3898 0.105853Z" />
      </svg>
    </SVGUniqueID>
  )
}
