import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Authy(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M16 7.99988C16 12.4185 12.4184 16 7.99981 16C3.5815 16 0 12.4185 0 7.99988C0 3.58156 3.5815 0 7.99981 0C12.4184 0 16 3.58156 16 7.99988Z"
          fill="#EC1C24"
        />
        <path
          d="M7.31404 6.30561L9.23223 8.22311C9.5091 8.50036 9.95867 8.50036 10.2362 8.22311C10.5132 7.94592 10.5138 7.49673 10.2362 7.21948L8.31792 5.30123C6.94535 3.92836 4.72698 3.90623 3.32529 5.23148C3.3122 5.24235 3.29959 5.25378 3.28748 5.26573C3.28135 5.27186 3.27635 5.27836 3.27029 5.28386C3.26404 5.28986 3.25767 5.29523 3.25173 5.30123C3.2396 5.3133 3.22854 5.32598 3.21798 5.33854C1.89204 6.74086 1.91485 8.95929 3.28754 10.3321L5.20579 12.25C5.48329 12.527 5.93223 12.527 6.20967 12.25C6.48729 11.9727 6.48729 11.5229 6.21035 11.2462L4.29204 9.32804C3.45467 8.49098 3.44904 7.13248 4.27467 6.28823C5.11879 5.46261 6.47654 5.46861 7.31404 6.30567V6.30561ZM9.78979 3.75104C9.5126 4.02836 9.5126 4.47748 9.79054 4.75473L11.7081 6.67304C12.545 7.51005 12.5504 8.86836 11.7249 9.71279C10.8805 10.5378 9.52317 10.5323 8.68592 9.69517L6.76767 7.77711C6.49029 7.49979 6.0406 7.49979 5.76354 7.77711C5.48585 8.05417 5.48585 8.50473 5.76354 8.78117L7.68117 10.6995C9.05417 12.0724 11.2723 12.0947 12.6742 10.7693C12.6872 10.7578 12.6995 10.7471 12.7124 10.735C12.7182 10.729 12.7237 10.723 12.7294 10.7169C12.7357 10.7109 12.7417 10.7054 12.7475 10.6988C12.7599 10.6874 12.7704 10.6748 12.7813 10.6617C14.1072 9.25992 14.085 7.04223 12.7124 5.66861L10.7942 3.75098C10.5169 3.47329 10.0668 3.47329 9.78979 3.75104Z"
          fill="white"
        />
      </svg>
    </SVGUniqueID>
  )
}
