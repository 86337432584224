import { useEffect, useReducer, useRef } from 'react'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'
import Hls from 'hls.js'
import cx from 'classnames'

import { Close } from 'icons/outline'
import { DeviceIcon } from 'icons/utils'
import { useAsync } from 'hooks'
import { Spinner } from 'components'
import LivestreamUI from 'shared/LivestreamPlayer/LivestreamUI'
import { getDeviceStatus } from 'utils/device'
import { dateTimeFormat, hmsToSeconds } from 'utils/dateTime'
import { Device } from 'services/devices'
import { ResponseError } from 'services/client'
import {
  getRecordingPlayback,
  PlaybackSegment,
  RecordingPlaybackResponse
} from 'services/recordingPlayback'

import { usePlaybackCtx } from './PlaybackContext'

interface Props {
  index: number
  device: Device
  onRemove: () => void
}

interface State {
  loading: boolean
  error: string
  segments: PlaybackSegment[]
  segmentIdx: number
  muted: boolean
}

const initState: State = {
  loading: false,
  error: '',
  segments: [],
  segmentIdx: -1,
  muted: true
}

function PlaybackPlayer({ index, device, onRemove }: Props) {
  const [{ segments, segmentIdx, ...state }, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const recordingAsync = useAsync<AxiosResponse<RecordingPlaybackResponse>>()
  const { selectedTime, currentTime, seekTime, dispatch } = usePlaybackCtx()
  const isOnline = getDeviceStatus(device) === 'online'

  const playerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const hlsRef = useRef<Hls>()

  useEffect(() => {
    if (!selectedTime.length) {
      return setState({ error: 'Please select time range' })
    }
    resetPlayer()
    handleGetRecording()
  }, [selectedTime])

  useEffect(() => {
    removeSource()
    removeHls()
    if (!segments.length) return
    if (segmentIdx === -1) {
      return handlePlayerError({ message: 'No video at this time' })
    }
    loadSource()
  }, [segments, segmentIdx])

  useEffect(() => {
    if (!segments.length || !videoRef.current) return
    handleCurrentTimeChange()
  }, [currentTime])

  useEffect(() => {
    return removeHls
  }, [])

  const formatDateTime = (date: Date) => {
    return format(
      date,
      `${dateTimeFormat['yyyy-MM-dd']} ${dateTimeFormat['HH:mm']}`
    )
  }

  const handleGetRecording = async () => {
    setState({ loading: true })
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: { index, player: { status: 'loading' } }
    })
    try {
      const response = await recordingAsync.execute(
        getRecordingPlayback(device.id, {
          start_timestamp: formatDateTime(selectedTime[0]),
          end_timestamp: formatDateTime(selectedTime[1])
        })
      )

      const data = response.data.data
      // eslint-disable-next-line
      if (!data.playback.length) throw { status: 404 }
      if (hmsToSeconds(data.playback[0].starttime) === 0) {
        setState({ segmentIdx: 0 })
      }

      dispatch({
        type: 'UPDATE_PLAYER',
        payload: {
          index,
          player: { segments: data.playback, notifs: data.notifications }
        }
      })
      setState({ segments: data.playback })
    } catch (err) {
      setState({ loading: false })
      handlePlayerError(err as ResponseError)
    }
  }

  const handlePlayerError = (err: Partial<ResponseError>) => {
    if (err && err.status === 404) {
      setState({ error: 'No video found' })
    } else {
      setState({ error: err?.message || 'Video unavailable' })
    }
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: { index, player: { status: 'idle' } }
    })
  }

  const loadSource = () => {
    setState({ error: '', loading: true })
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: { index, player: { status: 'loading' } }
    })

    const videoEl = videoRef.current!

    if (Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(segments[segmentIdx].url)
      hls.attachMedia(videoEl!)
      hlsRef.current = hls
    } else {
      videoEl.src = segments[segmentIdx].url
    }

    videoEl.currentTime =
      currentTime - hmsToSeconds(segments[segmentIdx].starttime)
  }

  const resetPlayer = () => {
    setState(initState)
    removeHls()
    removeSource()
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: { index, player: { segments: [], notifs: [], status: 'idle' } }
    })
  }

  const removeHls = () => {
    if (hlsRef.current) {
      hlsRef.current.detachMedia()
      hlsRef.current.stopLoad()
      hlsRef.current.destroy()
      hlsRef.current = undefined
    }
  }

  const removeSource = () => {
    if (videoRef.current) {
      videoRef.current.removeAttribute('src')
      videoRef.current.load()
    }
  }

  const handleCurrentTimeChange = () => {
    const videoEl = videoRef.current!
    if (currentTime === 0 && segmentIdx === 0) {
      videoEl.currentTime = 0
    }

    const newSegmentIdx = segments.findIndex(
      seg =>
        hmsToSeconds(seg.starttime) <= currentTime &&
        currentTime < hmsToSeconds(seg.endtime)
    )

    if (newSegmentIdx === -1) {
      return setState({ segmentIdx: -1 })
    }

    if (currentTime === seekTime) {
      videoEl.currentTime =
        currentTime - hmsToSeconds(segments[newSegmentIdx].starttime)
    }
    setState({ segmentIdx: newSegmentIdx })
  }

  const handleCanPlay = () => {
    setState({ loading: false })
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: { index, player: { status: 'ready', video: videoRef.current } }
    })
  }

  const handleWaiting = () => {
    setState({ loading: false })
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: { index, player: { status: 'loading' } }
    })
  }

  return (
    <div
      ref={playerRef}
      className="group relative w-full h-full overflow-hidden"
    >
      <div className="base-transition absolute z-[3] top-0 left-0 bg-black/50 backdrop-blur-[20px] rounded-br-md py-0.5 px-1.5 text-[0.8125rem] opacity-0 group-hover:opacity-100">
        <div className="inline-flex items-center gap-1">
          <DeviceIcon
            type={device.type}
            className={cx(
              'w-4 h-4 shrink-0',
              isOnline ? 'text-success' : 'text-danger'
            )}
          />
          <div>{device.name}</div>
        </div>
      </div>
      <div
        onClick={onRemove}
        className="base-transition absolute z-[3] top-0 right-0 w-10 h-10 bg-black/50 inline-flex items-center justify-center backdrop-blur-[20px] cursor-pointer rounded-bl-md hover:bg-primary opacity-0 group-hover:opacity-100"
      >
        <Close className="w-4 h-4 pointer-events-none" />
      </div>

      {(state.loading || recordingAsync.isLoading) && (
        <Spinner className="absolute z-[4] top-0 left-0 w-full h-full bg-black/70" />
      )}
      {state.error && (
        <div className="absolute z-[2] top-0 left-0 w-full h-full flex justify-center items-center text-center pointer-events-none">
          <div className="text-light-secondary text-base tracking-[.2px]">
            {state.error}
          </div>
        </div>
      )}

      <video
        ref={videoRef}
        className="absolute z-[1] top-0 left-0 w-full h-full"
        muted={state.muted}
        onCanPlay={handleCanPlay}
        onWaiting={handleWaiting}
      />

      {!state.error && !!segments.length && segmentIdx > -1 && (
        <LivestreamUI.Controls
          className="opacity-0 group-hover:opacity-100"
          videoEl={videoRef.current!}
          muted={state.muted}
          onVolumeChange={() => setState({ muted: !state.muted })}
          fullscreenEl={playerRef.current!}
        />
      )}
    </div>
  )
}

export default PlaybackPlayer
