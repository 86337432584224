import { FC } from 'react'

import { Radio as RaDio, Props as RadioProps } from './Radio'

const Radio: FC<RadioProps> & { Group: any; Button: any } = props => (
  <RaDio {...props} />
)

Radio.Group = ''
Radio.Button = ''

export { Radio }
