import RestrictedRoute from 'shared/RestrictedRoute'
import TableList from './TableList'

function MyVault() {
  return (
    <RestrictedRoute
      className="!min-h-[80vh] py-4"
      when="can_access_vault"
      description=""
      title={
        <div className="mt-5">Please upgrade your plan to use this feature</div>
      }
    >
      <TableList />
    </RestrictedRoute>
  )
}

export default MyVault
