import { useNavigate } from 'react-router-dom'

import NotFound from 'assets/images/NotFound.png'
import { Button } from 'components'

function PageNotFound() {
  const navigate = useNavigate()

  return (
    <div className="w-[25rem] m-auto text-center">
      <div className="w-[17.5rem] m-auto">
        <img src={NotFound} alt="404" />
      </div>
      <div className="mt-10 text-[2rem] mb-2">Oops! Page not found</div>
      <div className="text-base text-light-secondary">
        We can't seem to find the page you're looking for.
      </div>
      <div className="mt-6">
        <Button
          variant="primary"
          size="large"
          className="w-60"
          onClick={() => navigate('/')}
        >
          Back to Dashboard
        </Button>
      </div>
    </div>
  )
}

export default PageNotFound
