import { Dispatch } from 'react'
import { FormInstance } from 'rc-field-form'
import { FieldData } from 'rc-field-form/lib/interface'
import shallow from 'zustand/shallow'

import { InfoCircle } from 'icons/filled'
import { Form, FormField, Input, Select } from 'components'
import { timezones } from 'utils/dateTime'
import { required } from 'utils/rules'
import useStore from 'store'

import { FormValues } from './FormHelpers'

interface Props {
  form: FormInstance
  values: FormValues['deviceDetail']
  setValues: Dispatch<Partial<FormValues>>
}

function DeviceDetail({ form, values, setValues }: Props) {
  const locations = useStore(state => state.location.locations, shallow)

  const handleFieldChange = (field: FieldData[]) => {
    const name = (
      field[0].name as string[]
    )[0] as keyof FormValues['deviceDetail']
    setValues({ deviceDetail: { ...values, [name]: field[0].value } })
  }

  return (
    <div
      id="device-detail"
      className="min-h-[calc(100vh_-_5.3125rem)] flex flex-col justify-center"
    >
      <Form
        form={form}
        initialValues={values}
        className="w-[35rem] m-auto"
        onFieldsChange={handleFieldChange}
      >
        <div className="mb-6 flex items-center gap-1.5">
          <InfoCircle className="w-4 h-4 shrink-0" type="primary" />
          <div className="text-base font-medium">Device Detail</div>
        </div>
        <FormField
          name="name"
          label="Device Name"
          rules={[required]}
          requiredMask
        >
          <Input block size="large" placeholder="Name your device" />
        </FormField>
        <FormField name="type" label="Device Type">
          <Input readOnly disabled size="large" />
        </FormField>
        <FormField
          name="timezone"
          label="Device Timezone"
          rules={[required]}
          requiredMask
        >
          <Select
            size="large"
            options={timezones.map(tz => ({ label: tz, value: tz }))}
          />
        </FormField>
        <FormField name="location" label="Device Location">
          <Select
            size="large"
            options={locations.map(l => ({ label: l.name, value: l.id }))}
          />
        </FormField>
      </Form>
    </div>
  )
}

export default DeviceDetail
