import { ResourceIcon } from 'icons/utils'
import { SelectButtonItem } from 'components/SelectButton/SelectButtonItem'
import { channelMap } from 'utils/channel'
import { formatPhone } from 'utils/functions'
import { Schedule } from 'services/schedules'
import { NotifChannel } from 'services/tags'

interface Props {
  schedule: Schedule
}

function Recipients({ schedule }: Props) {
  const renderNotifChannels = () => {
    return Object.keys(channelMap).map(key => {
      const { label, iconType } = channelMap[key as NotifChannel]
      return (
        <SelectButtonItem
          key={key}
          value={key}
          active={schedule.channels.includes(key as NotifChannel)}
          className="px-2"
          disabled
        >
          <ResourceIcon
            type={iconType}
            className="w-4 h-4"
            iconType="primary"
          />
          <div className="font-medium">{label}</div>
        </SelectButtonItem>
      )
    })
  }

  return (
    <div className="pt-4">
      <div className="flex items-center gap-2 mb-3">
        <div className="text-[0.9375rem] font-semibold uppercase">
          Notification Channel
        </div>
      </div>
      <div className="flex flex-wrap items-center gap-2">
        {renderNotifChannels()}
      </div>
      <div className="border-b border-dashed border-light-stroke my-6" />
      <div className="flex items-center gap-2 mb-3">
        <div className="text-[0.9375rem] font-semibold uppercase">
          Recipients
        </div>
      </div>

      {!!schedule?.phone_recipients?.length && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="sms"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">SMS Recipient</div>
          </div>
          <div className="w-full inline-flex flex-wrap gap-2">
            {schedule.phone_recipients.map(({ id, phone }) => (
              <div
                key={id}
                className="bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg"
              >
                {formatPhone(phone)}
              </div>
            ))}
          </div>
        </div>
      )}

      {!!schedule?.whatsapp_recipients?.length && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="whatsapp"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">WhatsApp Recipient</div>
          </div>
          <div className="w-full inline-flex flex-wrap gap-2">
            {schedule.whatsapp_recipients.map(({ id, phone }) => (
              <div
                key={id}
                className="bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg"
              >
                {formatPhone(phone)}
              </div>
            ))}
          </div>
        </div>
      )}

      {!!schedule?.email_recipients?.length && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="email"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">Email Recipient</div>
          </div>
          <div className="w-full inline-flex flex-wrap gap-2">
            {schedule.email_recipients.map(({ id, email }) => (
              <div
                key={id}
                className="bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg"
              >
                {email}
              </div>
            ))}
          </div>
        </div>
      )}

      {!!schedule?.slack_webhook && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="slack"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">Slack Webhook</div>
          </div>
          <div className="w-full bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg">
            {schedule.slack_webhook}
          </div>
        </div>
      )}

      {!!schedule?.webhook && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="webhook"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">Webhook</div>
          </div>
          <div className="w-full bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg">
            {schedule.webhook}
          </div>
        </div>
      )}

      {!!schedule?.teams_webbhook && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="ms-teams"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">Microsoft Team Webhook</div>
          </div>
          <div className="w-full bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg">
            {schedule.teams_webbhook}
          </div>
        </div>
      )}

      {!!schedule?.pagerduty_integration_key && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="pager-duty"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">PagerDuty</div>
          </div>
          <div className="w-full bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg">
            {schedule.pagerduty_integration_key}
          </div>
        </div>
      )}
    </div>
  )
}

export default Recipients
