import { ResourceIconType } from 'icons/utils/ResourceIcon'
import { NotifChannel } from 'services/tags'

type ChannelMap = {
  [key in NotifChannel]: {
    label: string
    iconType: ResourceIconType
  }
}

export const channelMap: ChannelMap = {
  phone: {
    label: 'SMS',
    iconType: 'sms'
  },
  email: {
    label: 'Email',
    iconType: 'email'
  },
  whatsapp: {
    label: 'WhatsApp',
    iconType: 'whatsapp'
  },
  webhook: {
    label: 'Webhook',
    iconType: 'webhook'
  },
  slack: {
    label: 'Slack',
    iconType: 'slack'
  },
  microsoft_teams: {
    label: 'Microsoft Teams',
    iconType: 'ms-teams'
  },
  pager_duty: {
    label: 'PagerDuty',
    iconType: 'pager-duty'
  }
}

export const channelToResource = (channel: NotifChannel) => {
  const r = channelMap[channel]
  return { ...r, iconType: r.iconType as ResourceIconType }
}
