import cx from 'classnames'

type Props = {
  size?: 'small' | 'default' | 'large'
} & JSX.IntrinsicElements['textarea']

const sizes = {
  small: 'py-0 px-[0.4375rem] text-sm',
  default: 'py-1 px-2.5 text-sm',
  large: 'px-2.5 py-1.5 text-md'
}

const disabled = 'pointer-events-none bg-light-disabled !text-light-primary/50'

export function TextArea({ size = 'default', ...props }: Props) {
  return (
    <textarea
      {...props}
      rows={props.rows || 3}
      className={cx(
        'border border-light-stroke rounded-lg w-full',
        'base-transition hover:border-primary focus:border-primary outline-0',
        'placeholder:text-light-secondary placeholder:font-normal',
        sizes[size],
        props.disabled && disabled,
        props.className
      )}
    />
  )
}
