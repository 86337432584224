import { useEffect } from 'react'
import { AxiosResponse } from 'axios'

import { Camera } from 'icons/filled'
import { InfoCircle } from 'icons/outline'
import { useAsync } from 'hooks'
import { Table } from 'components'
import { Tag, getTagDetail } from 'services/tags'
import { Device } from 'services/devices'
import { DeviceIcon } from 'icons/utils'

interface Props {
  tag: Tag
}

function AffectedDevices({ tag }: Props) {
  const tagAsync = useAsync<AxiosResponse<{ data: Tag }>>({
    showNotifOnError: true
  })

  useEffect(() => {
    if (!tag.apply_to_all) {
      tagAsync.execute(getTagDetail(tag.id, 'devices'))
    }
  }, [])

  const columns = [
    {
      title: 'name',
      render: ({ type, name }: Device) => (
        <div className="flex items-center gap-1">
          <DeviceIcon type={type} className="shrink-0 text-[#bdbdbd]" />
          <div>{name}</div>
        </div>
      )
    },
    {
      title: 'device type',
      dataIndex: 'type'
    }
  ]

  if (!!tag.apply_to_all) {
    return (
      <div className="pt-4 flex items-center gap-1 text-light-secondary">
        <InfoCircle className="shrink-0 w-4 h-4" />
        <div className="text-sm">
          This tag is applying to all devices, and also apply to all new devices
          in the future
        </div>
      </div>
    )
  }

  const { isLoading, data } = tagAsync

  return (
    <div className="pt-4">
      <div className="flex items-center gap-2 mb-3">
        <Camera type="primary" className="shrink-0 w-4 h-4 mb-px" />
        <div className="text-[0.9375rem] font-semibold uppercase">
          Device affected by this tag
        </div>
      </div>
      <Table
        rowKey="id"
        loading={isLoading}
        columns={columns}
        data={data?.data.data.devices || []}
      />
      <div className="mt-2.5 text-sm text-right text-light-secondary">
        Total devices: {(data?.data.data.devices || []).length}
      </div>
    </div>
  )
}

export default AffectedDevices
