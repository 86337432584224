import { useState } from 'react'

import { Close } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, InputPassword, Modal, ModalProps } from 'components'
import { verifyPassword } from 'services/2fa'

interface Props extends ModalProps {
  onSuccess?: () => void
}

function ModalVerifyPassword({ onSuccess, ...props }: Props) {
  const [value, setValue] = useState('')
  const { execute, isLoading } = useAsync({ showNotifOnError: true })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleVerify = async () => {
    if (!value) return
    await execute(verifyPassword(value))
    onSuccess?.()
    handleClose()
  }

  return (
    <Modal {...props} className="w-[31.25rem]" onClose={handleClose}>
      <div className="flex items-center gap-4 justify-between mb-6">
        <div className="text-base font-medium">
          Please enter password to continue
        </div>
        <Close className="shrink-0 cursor-pointer" onClick={handleClose} />
      </div>
      <div className="text-light-secondary text-[0.8125rem] mb-4">
        The action you are trying to do requires that you re-enter your
        password.
      </div>
      <InputPassword value={value} onChange={e => setValue(e.target.value)} />
      <div className="mt-4 flex items-center justify-end gap-2">
        <Button className="w-[5rem]" disabled={isLoading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          variant="primary"
          className="w-[6.25rem]"
          onClick={handleVerify}
        >
          Continue
        </Button>
      </div>
    </Modal>
  )
}

export default ModalVerifyPassword
