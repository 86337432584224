import { Attachment } from 'icons/outline'
import { Filter, Tag as TagIcon } from 'icons/filled'
import { Tag } from 'components'
import { Tag as ITag } from 'services/tags'

interface Props {
  tag: ITag
}

function TagDetails({ tag }: Props) {
  return (
    <div className="pt-4">
      <div className="flex items-center gap-1 mb-3">
        <TagIcon type="primary" className="shrink-0 w-4 h-4" />
        <div className="text-[0.9375rem] font-semibold uppercase">Tag Info</div>
      </div>
      <Tag
        strokeColor={tag.stroke_color}
        textColor={tag.text_color}
        bgColor={tag.color}
      >
        {tag.name}
      </Tag>
      <div className="border-b border-dashed my-6 border-light-stroke" />
      <div className="flex items-center gap-1 mb-3">
        <Filter type="primary" className="shrink-0 w-4 h-4" />
        <div className="text-[0.9375rem] font-semibold uppercase">
          Matching Criteria
        </div>
      </div>
      <div className="flex items-center gap-4 mb-3.5">
        <div className="w-[22%] font-medium">In Title</div>
        <div className="bg-[#efefef] py-[5px] px-[6px] rounded-lg">
          {tag.subject_keywords || '---'}
        </div>
      </div>
      <div className="flex items-center gap-4 mb-3.5">
        <div className="w-[22%] font-medium">Match Logic</div>
        <div className="bg-[#efefef] py-[5px] px-[6px] rounded-lg">
          {tag.search_in === 'subject_and_body' ? 'And' : 'Or'}
        </div>
      </div>
      <div className="flex items-center gap-4 mb-3.5">
        <div className="w-[22%] font-medium">In Content</div>
        <div className="bg-[#efefef] py-[5px] px-[6px] rounded-lg">
          {tag.body_keywords || '---'}
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="w-[22%] font-medium">Other Criterias</div>
        {!!tag.has_attachments && (
          <div className="inline-flex items-center gap-1">
            <Attachment className="w-3.5 h-3.5 shrink-0" color="#BABBBF" />
            <div className="text-[0.8125rem]">Contains attachment</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TagDetails
