import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Refresh(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M12.0736 2.15537C11.8158 2.13186 11.5877 2.32182 11.5642 2.57963L11.5021 3.26094C10.4484 1.60611 8.61924 0.5 6.49981 0.5C4.21979 0.5 2.21047 1.8013 1.21301 3.75439C1.09526 3.98495 1.18672 4.2673 1.41727 4.38505C1.64787 4.5028 1.93018 4.41137 2.04793 4.18079C2.89208 2.52788 4.58724 1.4375 6.49981 1.4375C8.24302 1.4375 9.81619 2.34526 10.7175 3.76737L10.0491 3.29002C9.83841 3.13958 9.54565 3.18837 9.39521 3.39903C9.24476 3.60971 9.29356 3.90245 9.50422 4.05291L11.531 5.50044C11.828 5.7122 12.2377 5.51834 12.2702 5.16155L12.4978 2.66476C12.5213 2.40692 12.3314 2.17887 12.0736 2.15537Z"
          fill="currentColor"
        />
        <path
          d="M11.5825 8.61505C11.3519 8.49732 11.0696 8.58875 10.9519 8.8193C10.1077 10.4722 8.41254 11.5626 6.49997 11.5626C4.75676 11.5626 3.18359 10.6548 2.28233 9.23272L2.95069 9.71007C3.16137 9.86051 3.45411 9.81172 3.60458 9.60106C3.75502 9.39038 3.70622 9.09765 3.49557 8.94718L1.46881 7.49966C1.1754 7.29003 0.762381 7.47807 0.729568 7.83854L0.501967 10.3353C0.478459 10.5931 0.66842 10.8212 0.926209 10.8447C1.18435 10.8682 1.41209 10.678 1.43558 10.4204L1.49769 9.73913C2.55139 11.394 4.38054 12.5001 6.49997 12.5001C8.78 12.5001 10.7893 11.1988 11.7868 9.2457C11.9045 9.01515 11.8131 8.7328 11.5825 8.61505Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
