import { format } from 'date-fns'
import shallow from 'zustand/shallow'

import { Close, Crown, Pencil } from 'icons/outline'
import { ClockCircle, Email, Shield, User } from 'icons/filled'
import { Avatar, Button, Modal, ModalProps } from 'components'
import { dateTimeFormat } from 'utils/dateTime'
import useStore from 'store'

interface Props extends ModalProps {
  onEdit: () => void
  onChangePassword: () => void
}

function ModalViewUserProfile({ onEdit, onChangePassword, ...props }: Props) {
  const user = useStore(({ auth }) => auth.currentUser, shallow)

  if (!user) {
    return null
  }

  return (
    <Modal {...props} className="px-6">
      <div className="text-base font-medium flex justify-between items-center">
        <h4>My Profile</h4>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      <div className="mt-6 grid grid-cols-[7.5rem_25rem] gap-4">
        <Avatar size={120} username={user.name} src={user.avatar_url} />
        <div>
          <h4 className="text-base font-medium mb-5">{user.name}</h4>
          <div className="grid grid-cols-[7rem_auto] items-center gap-4">
            <div className="inline-flex items-center gap-1.5">
              <Email className="w-4 h-4" color="#BABBBF" />
              <span className="text-[0.8125rem] font-medium">Email</span>
            </div>
            <div className="text-[0.8125rem]">{user.email}</div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-3" />
          <div className="grid grid-cols-[7rem_auto] items-center gap-4">
            <div className="inline-flex items-center gap-1.5">
              <User className="w-4 h-4" color="#BABBBF" />
              <span className="text-[0.8125rem] font-medium">User Role</span>
            </div>
            <div className="text-[0.8125rem] inline-flex items-center gap-2">
              {(user.is_admin || user.is_super) && (
                <Crown className="text-warning" />
              )}
              {user.is_admin || user.is_super ? 'Admin' : 'Standard User'}
            </div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-3" />
          <div className="grid grid-cols-[7rem_auto] items-center gap-4">
            <div className="inline-flex items-center gap-1.5">
              <ClockCircle className="w-4 h-4" color="#BABBBF" />
              <span className="text-[0.8125rem] font-medium">Create At</span>
            </div>
            <div className="text-[0.8125rem]">
              {format(new Date(user.created_at), dateTimeFormat['MM-dd-yyyy'])}
            </div>
          </div>
          <div className="border-b border-dashed border-light-stroke my-3" />
          <div className="mt-4 flex gap-2">
            <Button className="w-[6.25rem]" onClick={onEdit}>
              <Pencil /> Edit
            </Button>
            <Button className="w-[11rem]" onClick={onChangePassword}>
              <Shield color="#303030" /> Change Password
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalViewUserProfile
