import { useState } from 'react'

import './index.css'
import { Close, InfoCircle, Share } from 'icons/outline'
import { useAsync } from 'hooks'
import {
  AsyncSelect,
  Button,
  Form,
  FormField,
  Input,
  Modal,
  ModalProps,
  Select,
  TextArea
} from 'components'
import toast from 'utils/toast'
import { required } from 'utils/rules'
import { idGenerator } from 'utils/functions'
import { getRecipients, Recipient } from 'services/recipients'
import { getUsers, User } from 'services/users'
import { shareVaultFile } from 'services/vaults'

interface Props extends ModalProps {
  vaultId: number
}

interface FormValues {
  message?: string
  expiry_number: string
  viewers: string[]
}

const expiryTypes = {
  hours: { label: 'hour', value: 'hours' },
  minutes: { label: 'minute', value: 'minutes' },
  days: { label: 'day', value: 'days' }
}

const genUserId = idGenerator('user')

function ModalShareRecord({ vaultId, ...props }: Props) {
  const [form] = Form.useForm()
  const [expiryType, setExpiryType] = useState(expiryTypes.hours)
  const [viewers, setViewers] = useState<any>([])

  const { execute, isLoading } = useAsync({ showNotifOnError: true })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleGetViewers = async (search: string) => {
    if (!search.includes('@')) {
      const [recs, users] = await Promise.all([
        getRecipients({ search }),
        getUsers({ search })
      ])
      return mapViewers([...users.data.data, ...recs.data.data])
    }
    return mapViewers([
      {
        id: genUserId.next().value as string,
        type: 'email',
        email: search,
        name: search
      }
    ])
  }

  const mapViewers = (viewers: User[] | Recipient[] | any[]) => {
    return viewers.map(({ id, type, is_admin, email, name }) => {
      const viewerType = type || (!!is_admin ? 'user' : 'rec')
      return {
        id,
        type: viewerType,
        value: viewerType + '-' + id,
        email,
        label: name
      }
    })
  }

  const handleSubmit = async (values: FormValues) => {
    if (+values.expiry_number < 1) {
      return form.setFields([
        { name: 'expiry_number', errors: ['Time must be greater than 0'] }
      ])
    }
    try {
      await execute(
        shareVaultFile(vaultId, {
          expiry_number: +values.expiry_number,
          expiry_type: expiryType.value,
          viewers: viewers.map(({ email, type, id }: any) => ({
            email: email,
            user_id: type === 'user' ? id : undefined,
            recipient_id: type === 'rec' ? id : undefined
          }))
        })
      )
      toast.success(
        { title: 'Shared successfully' },
        { position: 'bottom-center' }
      )
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal
      {...props}
      onClose={handleClose}
      className="modal-share-record w-[37.5rem] px-6"
    >
      <div className="flex items-center justify-between gap-2 mb-6">
        <div className="text-lg font-medium">Share record with people</div>
        <Close className="cursor-pointer" onClick={handleClose} />
      </div>
      <Form form={form} onFinish={handleSubmit}>
        <FormField
          name="viewers"
          label="People you want to share"
          rules={[required]}
          requiredMask
        >
          <AsyncSelect
            isMulti
            isClearable={false}
            defaultOptions
            loadOptions={handleGetViewers}
            placeholder="Add people by email"
            onChange={v => setViewers(v)}
          />
        </FormField>
        <FormField
          name="expiry_number"
          label="Expiration Time"
          rules={[required]}
          requiredMask
        >
          <Input
            type="number"
            placeholder="Please enter expiration time"
            extra={
              <div className="flex items-center gap-1 text-xs text-light-secondary">
                <InfoCircle className="shrink-0 w-3 h-3" />
                Shared video links will no longer be accessible upon expiration
              </div>
            }
            addonAfter={
              <Select
                className="addon-select"
                isSearchable={false}
                value={expiryType}
                onChange={(v: any) => setExpiryType(v)}
                options={Object.values(expiryTypes)}
              />
            }
          />
        </FormField>
        <FormField name="message" label="Message">
          <TextArea
            rows={5}
            placeholder="Include an optional message with your shared video"
          />
        </FormField>
        <div className="flex items-center justify-between">
          <Button
            type="button"
            className="w-[5rem]"
            disabled={isLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="w-[6.25rem]"
            disabled={isLoading}
          >
            <Share /> Share
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalShareRecord
