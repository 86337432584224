import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Transaction({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M14.6154 2.46149H7.07702C6.31359 2.46149 5.69238 3.08258 5.69238 3.84612V11.3845C5.69238 12.1479 6.31359 12.7691 7.07702 12.7691H9.10837L13.3095 15.1698C13.4649 15.2586 13.663 15.2481 13.8083 15.1435C13.9277 15.0576 14.0001 14.9162 14.0001 14.769V12.769H14.6154C15.379 12.769 16 12.1479 16 11.3845V3.846C16 3.08258 15.379 2.46149 14.6154 2.46149ZM10.3847 7.38454H11.3078C11.9864 7.38454 12.5385 7.93666 12.5385 8.61525C12.5385 9.29395 11.9864 9.84607 11.3078 9.84607V10.3076C11.3078 10.5625 11.1012 10.7692 10.8463 10.7692C10.5914 10.7692 10.3847 10.5625 10.3847 10.3076V9.84607H9.61545C9.36057 9.84607 9.1539 9.63941 9.1539 9.38453C9.1539 9.12965 9.36057 8.92299 9.61545 8.92299H11.3078C11.4774 8.92299 11.6154 8.78492 11.6154 8.61525C11.6154 8.44557 11.4774 8.30763 11.3078 8.30763H10.3847C9.70602 8.30763 9.1539 7.75551 9.1539 7.07681C9.1539 6.39822 9.70602 5.8461 10.3847 5.8461V5.38456C10.3847 5.12968 10.5914 4.92302 10.8463 4.92302C11.1012 4.92302 11.3078 5.12968 11.3078 5.38456V5.8461H12.077C12.3319 5.8461 12.5385 6.05277 12.5385 6.30765C12.5385 6.56253 12.3319 6.76919 12.077 6.76919H10.3847C10.2151 6.76919 10.077 6.90713 10.077 7.07681C10.077 7.24648 10.2151 7.38454 10.3847 7.38454Z"
          fill="url(#paint0_linear_1273_263290)"
        />
        <path
          d="M7.07685 1.53844H10.3077V1.38463C10.3077 0.621089 9.68645 0 8.92303 0H1.38463C0.621089 0 0 0.621089 0 1.38463V8.92303C0 9.68645 0.621089 10.3077 1.38463 10.3077H1.99998V12.3076C1.99998 12.461 2.07872 12.6077 2.20665 12.6924C2.34984 12.7871 2.54136 12.7935 2.69053 12.7083L4.7734 11.5181C4.77084 11.4739 4.76925 3.84616 4.76925 3.84616C4.76925 2.57371 5.8044 1.53844 7.07685 1.53844Z"
          fill="url(#paint1_linear_1273_263290)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1273_263290"
            x1="5.69238"
            y1="2.46149"
            x2="5.69238"
            y2="15.2298"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1273_263290"
            x1="0"
            y1="0"
            x2="0"
            y2="12.7681"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
