import { differenceInHours, isAfter } from 'date-fns'

import { MAX_PLAYBACK_HOUR } from './dateTime'

export const validatePassword = (password: string) => {
  if (password.trim().length < 8) {
    return 'Password must be at least 8 characters'
  }
  if (!password.match(/\d/)) {
    return 'Include at least 1 number'
  }
  if (!password.match(/[A-Z]/)) {
    return 'Include at least 1 upper case character'
  }
  if (!password.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/)) {
    return 'Include at least 1 special character'
  }
  return true
}

export const matchPasswords = (password: string, rePassword: string) => {
  return password.trim() !== rePassword.trim() ? "Password doesn't match" : true
}

export const validateTimeRange = (start: Date | null, end: Date | null) => {
  if (!start || !end) {
    return 'Please select time range'
  }
  if (!isAfter(end, start)) {
    return 'End time must be after start time'
  }
  if (differenceInHours(end, start) > MAX_PLAYBACK_HOUR) {
    return `Maximum time range is ${MAX_PLAYBACK_HOUR} hours`
  }
  return true
}
