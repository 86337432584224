import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function SpeakerMuted(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M8.93423 0.124903L3.26543 4.272H0.67921C0.306951 4.272 0 4.57895 0 4.95121V10.9988C0 11.3711 0.306951 11.678 0.67921 11.678H3.31115L8.93749 15.8218C9.21505 16.0994 10.0249 15.959 10.0249 15.3124V0.67023C10.0216 0.00408154 9.23464 -0.139598 8.93423 0.124903ZM2.81807 10.3522H1.32577V5.5945H2.82133V10.3522H2.81807ZM8.66319 13.9867L4.21241 10.718V5.23204L8.66319 1.96008V13.9867Z"
          fill="currentColor"
        />
        <path
          d="M3.55084 4.94941H0.532288V11.1641H3.55084L9.41039 15.0705V0.865479L3.55084 4.94941Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1635 9.57875C11.0082 9.73413 11.0082 9.98605 11.1635 10.1414C11.3189 10.2968 11.5708 10.2968 11.7262 10.1414L13.4139 8.45373L15.1024 10.1422C15.2578 10.2976 15.5097 10.2976 15.6651 10.1422C15.8205 9.98682 15.8205 9.73489 15.6651 9.57951L13.9766 7.89105L15.665 6.20266C15.8204 6.04727 15.8204 5.79535 15.665 5.63997C15.5096 5.48459 15.2577 5.48459 15.1023 5.63997L13.4139 7.32836L11.7263 5.64074C11.5709 5.48536 11.319 5.48536 11.1636 5.64074C11.0082 5.79612 11.0082 6.04804 11.1636 6.20342L12.8512 7.89105L11.1635 9.57875Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
