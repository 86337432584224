import { useEffect } from 'react'
import shallow from 'zustand/shallow'
import { AxiosResponse } from 'axios'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete'

import { UnorderedList } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Form, FormField, Input, Select } from 'components'
import toast from 'utils/toast'
import { required } from 'utils/rules'
import { createLocation, Location, updateLocation } from 'services/locations'
import useStore, { Store } from 'store'

interface Props {
  loading: boolean
  setLoading: (loading: boolean) => void
  location?: Location
  onSuccess?: (l?: Location) => void
  onCloseModal: () => void
}

interface FormValues {
  name: string
  address: { id: string; label: string; value: string }
  address_2?: string
}

const mapState = (state: Store) => ({
  user: state.auth.currentUser,
  addLocation: state.location.addLocation,
  updateLocation: state.location.updateLocation
})

function LocationForm({ loading, setLoading, location, ...props }: Props) {
  const [form] = Form.useForm()
  const store = useStore(mapState, shallow)

  const createAsync = useAsync<AxiosResponse<{ data: Location }>>({
    showNotifOnError: true
  })
  const updateAsync = useAsync<AxiosResponse<{ data: Location }>>({
    showNotifOnError: true
  })

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearCache
  } = usePlacesAutocomplete({
    debounce: 500,
    defaultValue: location?.address || ''
  })

  useEffect(() => {
    clearCache()
  }, [])

  const mapOptions = () => {
    return data.map(({ place_id, description }) => ({
      id: place_id,
      value: description,
      label: description
    }))
  }

  const handleSubmit = async ({
    name,
    address_2,
    address: { value }
  }: FormValues) => {
    if (name.length > 30) {
      return form.setFields([
        {
          name: 'name',
          errors: ['Location name should not greater than 30 characters']
        }
      ])
    }

    try {
      setLoading(true)
      let latLng = { lat: location?.lat || 0, lng: location?.lng || 0 }
      const geocode = await getGeocode({ address: value })
      latLng = await getLatLng(geocode[0])
      const newLocation = {
        name,
        address: value || location?.address || '',
        address_2,
        lat: +latLng.lat,
        lng: +latLng.lng,
        account_id: store.user!.account_id
      }

      let result: Location | undefined = undefined
      if (location?.id) {
        const res = await updateAsync.execute(
          updateLocation(location.id, newLocation)
        )
        result = res.data.data
        store.updateLocation(result)
      } else {
        const res = await createAsync.execute(createLocation(newLocation))
        result = res.data.data
        store.addLocation(result)
      }
      setLoading(false)
      toast.success(
        { title: `Location ${location?.id ? 'updated' : 'added'}` },
        { position: 'bottom-center' }
      )
      props.onSuccess?.(result || undefined)
      props.onCloseModal()
    } catch (err) {
      console.error(err)
    }
  }

  const initValues = {
    name: location?.name || '',
    address_2: location?.address_2,
    address: !!location
      ? { label: location.address, value: location.address }
      : undefined
  }

  return (
    <Form form={form} initialValues={initValues} onFinish={handleSubmit}>
      <div className="flex items-center gap-2 text-base font-medium mb-8">
        <UnorderedList className="text-primary shrink-0 w-4.5 h-4.5" />
        Enter Location Detail
      </div>
      <FormField
        name="name"
        label="Location Name"
        rules={[required]}
        requiredMask
      >
        <Input size="large" placeholder="Name the location" />
      </FormField>
      <FormField
        name="address"
        label="Street Address"
        className="!mb-0"
        rules={[required]}
        requiredMask
      >
        <Select
          size="large"
          isDisabled={!ready}
          inputValue={value}
          onInputChange={v => setValue(v, !!v.trim().length)}
          options={status !== 'OK' ? [] : mapOptions()}
          placeholder="Enter the location address"
        />
      </FormField>
      <FormField name="address_2" className="mt-2">
        <Input
          size="large"
          placeholder="Apt, suite. unit. building. floor, etc"
        />
      </FormField>
      <Button
        type="submit"
        variant="primary"
        block
        size="large"
        className="mt-8"
        disabled={loading}
      >
        Save
      </Button>
    </Form>
  )
}

export default LocationForm
