import { useState } from 'react'

import { useAsync } from 'hooks'
import { Close } from 'icons/outline'
import {
  Button,
  Form,
  FormField,
  InputPassword,
  Modal,
  ModalProps
} from 'components'
import PasswordRequirements from 'shared/PasswordRequirements'
import toast from 'utils/toast'
import { required } from 'utils/rules'
import { matchPasswords, validatePassword } from 'utils/validation'
import { changePassword, confirmPassword } from 'services/auth'

type FormValues = {
  currentPassword: string
  newPassword: string
  rePassword: string
}

function ModalChangePassword(props: ModalProps) {
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()
  const { execute } = useAsync({ showNotifOnError: true })

  const handleValidate = (values: FormValues) => {
    const { currentPassword, newPassword, rePassword } = values

    const currentPasswordResult = validatePassword(currentPassword)
    if (currentPasswordResult !== true) {
      return form.setFields([
        { name: 'currentPassword', errors: [currentPasswordResult] }
      ])
    }
    if (currentPassword === newPassword) {
      return form.setFields([
        {
          name: 'currentPassword',
          errors: ['Current password and new password are the same']
        },
        {
          name: 'newPassword',
          errors: ['Current password and new password are the same']
        }
      ])
    }
    const matchPasswordsResult = matchPasswords(newPassword, rePassword)
    if (matchPasswordsResult !== true) {
      return form.setFields([
        { name: 'newPassword', errors: [matchPasswordsResult] },
        { name: 'rePassword', errors: [matchPasswordsResult] }
      ])
    }

    const newPasswordResult = validatePassword(newPassword)
    if (newPasswordResult !== true) {
      return form.setFields([
        { name: 'newPassword', errors: [newPasswordResult] }
      ])
    }
    const rePasswordResult = validatePassword(rePassword)
    if (rePasswordResult !== true) {
      return form.setFields([
        { name: 'rePassword', errors: [rePasswordResult] }
      ])
    }

    handleSubmit(values)
  }

  const handleSubmit = async ({ currentPassword, newPassword }: FormValues) => {
    try {
      setLoading(true)
      await execute(confirmPassword({ password: currentPassword }))
      await execute(changePassword({ password: newPassword }))
      toast.success(
        { title: 'Password updated' },
        { position: 'bottom-center' }
      )
      setLoading(false)
      props.onClose()
    } catch {
      setLoading(false)
    }
  }

  return (
    <Modal {...props} className="px-6">
      <div className="text-base font-medium flex justify-between items-center">
        <h4>Change Password</h4>
        <Close
          className="cursor-pointer"
          onClick={loading ? undefined : props.onClose}
        />
      </div>
      <PasswordRequirements className="mt-4 mb-6" />
      <Form form={form} onFinish={handleValidate}>
        <FormField
          name="currentPassword"
          label="Current Password"
          rules={[required]}
          requiredMask
        >
          <InputPassword block placeholder="Enter your current password" />
        </FormField>
        <FormField
          name="newPassword"
          label="New Password"
          rules={[required]}
          requiredMask
        >
          <InputPassword block placeholder="Enter your new password" />
        </FormField>
        <FormField
          name="rePassword"
          label="Re-enter Password"
          rules={[required]}
          requiredMask
        >
          <InputPassword block placeholder="Re-enter your new password" />
        </FormField>
        <Button
          block
          type="submit"
          size="large"
          variant="primary"
          disabled={loading}
        >
          Update Password
        </Button>
      </Form>
    </Modal>
  )
}

export default ModalChangePassword
