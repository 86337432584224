import { useEffect } from 'react'
import { formatDistanceToNow } from 'date-fns'
import { AxiosResponse } from 'axios'

import { Plus, Refresh } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Spinner } from 'components'
import { Device, getDeviceDetails } from 'services/devices'

interface Props {
  deviceId: number
  assignLicense: () => void
}

function CameraStats({ deviceId, assignLicense }: Props) {
  const deviceAsync = useAsync<AxiosResponse<{ data: Device }>>({
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetDeviceDetails()
  }, [])

  const handleGetDeviceDetails = () => {
    deviceAsync.execute(getDeviceDetails(deviceId))
  }

  const getFormatDistanceToNow = (dateStr: string | null) => {
    if (!dateStr) return 'down'
    return 'up for about ' + formatDistanceToNow(new Date(dateStr))
  }

  const getFrameRate = (frameRate?: string | null) => {
    return frameRate === 'NaN' ? 'Unavailable' : frameRate || '0.00'
  }

  const { data, isLoading } = deviceAsync
  const { deviceLiveStream } = data?.data.data || {}
  const camStats = (deviceLiveStream || {}) as Device['deviceLiveStream']

  if (isLoading) {
    return (
      <div className="bg-[#f6f6f6] rounded-lg p-4">
        <Spinner size="small" className="h-full" />
      </div>
    )
  }

  if (!data?.data.data.is_licensed) {
    return (
      <div className="bg-[#f6f6f6] rounded-lg p-4">
        <div className="text-base font-medium">Camera Stats</div>
        <div className="text-light-secondary my-4 text-[0.8125rem]">
          This device is not using live streaming license
        </div>
        <a
          className="text-primary font-medium text-[0.8125rem] underline"
          href="https://broadflow.co/support"
          target="_blank"
          rel="noreferrer"
        >
          What is "Live streaming License"?
        </a>
        <div className="mt-6">
          <Button variant="secondary-lighten" onClick={assignLicense}>
            <Plus /> Assign License
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-[#f6f6f6] rounded-lg p-4">
      <div className="mb-4 flex justify-between items-center gap-4">
        <div className="text-base font-medium">Camera Stats</div>
        <Button
          disabled={isLoading}
          variant="secondary-lighten"
          className="w-[6.25rem]"
          onClick={isLoading ? undefined : handleGetDeviceDetails}
        >
          <Refresh /> Refresh
        </Button>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <div className="w-28 font-light shrink-0">Streaming</div>
        <div className="grow font-medium break-all">
          {camStats!.is_streaming
            ? getFormatDistanceToNow(camStats!.streaming_start_timestamp)
            : getFormatDistanceToNow(camStats!.streaming_stop_timestamp)}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <div className="w-28 font-light shrink-0">Recording</div>
        <div className="grow font-medium break-all">
          {camStats!.is_recording
            ? getFormatDistanceToNow(camStats!.recording_start_timestamp)
            : getFormatDistanceToNow(camStats!.recording_stop_timestamp)}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <div className="w-28 font-light shrink-0">Bit Rate</div>
        <div className="grow font-medium break-all">
          {camStats!.bitrate} Mbits / sec
        </div>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <div className="w-28 font-light shrink-0">Video codec</div>
        <div className="grow font-medium break-all">
          {camStats!.video_codec || '---'}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <div className="w-28 font-light shrink-0">Profile</div>
        <div className="grow font-medium break-all">
          {camStats!.meta?.profile || '---'}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <div className="w-28 font-light shrink-0">Resolution</div>
        <div className="grow font-medium break-all">
          {camStats!.meta?.displaySize || '---'}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <div className="w-28 font-light shrink-0">Frame Rate</div>
        <div className="grow font-medium break-all">
          {getFrameRate(camStats!.meta?.frameRate)}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <div className="w-28 font-light shrink-0">Level</div>
        <div className="grow font-medium break-all">
          {getFrameRate(camStats!.meta?.level || '---')}
        </div>
      </div>
    </div>
  )
}

export default CameraStats
