import { Attachment, ChevronRight } from 'icons/outline'
import {
  Button,
  Checkbox,
  Form,
  FormField,
  SelectButton,
  TextArea
} from 'components'
import { FormValues } from './FormHelpers'

interface Props {
  values: FormValues['matchingCriterias']
  onSubmit: (values: FormValues['matchingCriterias']) => void
  onStepChange: (step: number) => void
}

function MatchingCriterias({ values, onSubmit, onStepChange }: Props) {
  const [form] = Form.useForm()

  const handleSubmit = (v: FormValues['matchingCriterias']) => {
    const { search_in, subject_keywords, body_keywords } = v

    if (search_in === 'subject_or_body') {
      if (!body_keywords && !subject_keywords) {
        return form.setFields([
          {
            name: 'body_keywords',
            errors: ['Please choose at least one criteria']
          },
          {
            name: 'subject_keywords',
            errors: ['Please choose at least one criteria']
          }
        ])
      }
    }

    if (search_in === 'subject_and_body') {
      if (!subject_keywords) {
        return form.setFields([
          { name: 'subject_keywords', errors: ['This field is required'] }
        ])
      }
      if (!body_keywords) {
        return form.setFields([
          { name: 'body_keywords', errors: ['This field is required'] }
        ])
      }
    }

    onSubmit(v)
    onStepChange(1)
  }

  return (
    <div className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center">
      <div className="w-[37.5rem] m-auto">
        <div className="text-base font-medium mb-6">
          Create a Rule
        </div>
        <Form form={form} initialValues={values} onFinish={handleSubmit}>
          <FormField name="subject_keywords" label="In Title">
            <TextArea rows={7} placeholder="Enter the keyword phrase match that will trigger this rule" />
          </FormField>
          <FormField name="search_in" label="Match Logic">
            <SelectButton>
              <SelectButton.Item
                value="subject_or_body"
                className="w-[3.75rem] h-[2.5rem]"
              >
                OR
              </SelectButton.Item>
              <SelectButton.Item
                value="subject_and_body"
                className="w-[3.75rem] h-[2.5rem]"
              >
                AND
              </SelectButton.Item>
            </SelectButton>
          </FormField>
          <FormField name="body_keywords" label="In Content">
            <TextArea rows={7} placeholder="Enter the keyword phrase match that will trigger this rule" />
          </FormField>
          <FormField
            name="has_attachments"
            label="Other Criteria"
            valuePropName="checked"
          >
            <Checkbox size="large">
              <div className="flex items-center gap-1 text-[0.8125rem]">
                <Attachment className="shrink-0 text-[#BABBBF]" />
                Contains an attachment
              </div>
            </Checkbox>
          </FormField>
          <Button
            type="submit"
            variant="primary"
            size="large"
            className="w-40 mt-4"
          >
            Next <ChevronRight />
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default MatchingCriterias
