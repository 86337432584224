import { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import { DeviceIcon } from 'icons/utils'
import { EyeOpen, More, Pencil } from 'icons/outline'
import { Button, Dot, Popover } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalEditDevice from 'shared/Modals/Device/EditDevice'
import ModalConfirmDeleteWithInput from 'shared/Modals/Confirm/ConfirmDeleteWithInput'
import toast from 'utils/toast'
import { getDeviceStatus } from 'utils/device'
import { deleteDevice, Device } from 'services/devices'
import useStore, { Store } from 'store'

type Props = {
  device: Device
} & JSX.IntrinsicElements['div']

const mapState = (state: Store) => ({
  removeDevice: state.device.removeDevice
})

function OtherDevice({ device, ...props }: Props) {
  const navigate = useNavigate()
  const [showMore, setShowMore] = useState(false)
  const [visible, setVisible] = useState('')
  const store = useStore(mapState, shallow)
  const status = getDeviceStatus(device)
  const detailPath = `/devices/${device.id}?tab=device-details`

  const handleDelete = () => {
    setVisible('')
    deleteDevice(device.id)
    store.removeDevice(device.id)
    toast.success({ title: 'Device Deleted' }, { position: 'bottom-center' })
  }

  const renderOptions = (onClose: () => void) => {
    const onClick = (fn: Function) => () => {
      fn()
      onClose()
    }
    return (
      <Popover.Content className="!p-2 w-60" onClose={onClose}>
        <MenuItem
          className="text-[0.8125rem]"
          onClick={() => navigate(detailPath)}
        >
          <EyeOpen className="w-3.5 h-3.5" /> View Detail
        </MenuItem>
        <MenuItem
          className="text-[0.8125rem]"
          onClick={onClick(() => setVisible('edit'))}
        >
          <Pencil className="w-3.5 h-3.5 scale-[.8]" /> Edit Device
        </MenuItem>
        <div className="border-b border-light-stroke my-[2px]" />
        <MenuItem
          className="text-[0.8125rem] text-danger"
          onClick={onClick(() => setVisible('delete'))}
        >
          <div className="w-3.5" />
          Delete Device
        </MenuItem>
      </Popover.Content>
    )
  }

  return (
    <Fragment>
      <div
        {...props}
        className={cx(
          'group relative w-full rounded-lg shadow-[0_1px_2px_rgba(0,0,0,0.16)]',
          props.className
        )}
      >
        <div className="w-full h-full relative">
          <div
            className={cx(
              'base-transition absolute top-2 right-3 opacity-0 group-hover:opacity-100',
              showMore && '!opacity-100'
            )}
          >
            <div className="flex items-center gap-2">
              <Popover
                placement="right-start"
                onVisibleChange={setShowMore}
                content={onClose => renderOptions(onClose)}
              >
                <Button children={<More />} />
              </Popover>
            </div>
          </div>
          <Link
            to={detailPath}
            className={cx(
              'flex p-3 rounded-lg bg-white gap-5  h-full border',
              status === 'offline' ? 'bg-[#FFEDED] border-danger' : 'bg-white'
            )}
          >
            <div className="relative">
              <div className="absolute top-[_-0.125rem] right-[_-0.25rem]">
                <Dot
                  className={cx(
                    'w-3 h-3 border border-white',
                    status === 'online' && 'bg-success',
                    status === 'offline' && 'bg-danger'
                  )}
                />
              </div>
              <DeviceIcon
                type={device.type}
                className="h-6 w-6 text-[#dedede]"
              />
            </div>
            <div>
              <p className="text-[#303030] text-sm mb-1">{device.name}</p>
              <p className="text-[0.8125rem] leading-normal text-[#808080]">
                {device.type}s
              </p>
            </div>
          </Link>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {visible === 'edit' && (
          <ModalEditDevice device={device} onClose={() => setVisible('')} />
        )}
        {visible === 'delete' && (
          <ModalConfirmDeleteWithInput
            modalTitle="Delete Device"
            warnText="After removed the device, all data of this device will be deleted"
            value={device.name}
            inputLabel={
              <Fragment>
                Enter device name <b>"{device.name}"</b> to delete
              </Fragment>
            }
            onConfirm={handleDelete}
            onClose={() => setVisible('')}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default OtherDevice
