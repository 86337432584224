import { ReactNode, useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import { Spinner } from 'components'
import toast from 'utils/toast'
import useStore from 'store'

import CreditCard from './CreditCard'

interface Props {
  className?: string
  children: (props: { disabled: boolean; onClick: () => void }) => ReactNode
  onSubmit: (paymentToken: string) => void
}

function CreditCardForm({ className, ...props }: Props) {
  const [initLoading, setInitLoading] = useState(true)
  const [stripe, setStripe] = useState<Stripe | null>(null)

  const { config } = useStore(state => state.conf, shallow)

  useEffect(() => {
    initStripe()
  }, [])

  const initStripe = async () => {
    try {
      setInitLoading(true)
      const stripe = await loadStripe(config!.stripe_key)
      setStripe(stripe)
    } catch (err: any) {
      const { message, errors } = err
      toast.error({ title: message, description: errors })
    } finally {
      setInitLoading(false)
    }
  }

  if (initLoading) return <Spinner />
  if (!stripe) return null

  return (
    <div className={cx('relative', className)}>
      <Elements stripe={stripe}>
        <CreditCard {...props} />
      </Elements>
    </div>
  )
}

export default CreditCardForm
