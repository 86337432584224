import { useEffect, useState } from 'react'
import { FormInstance } from 'rc-field-form'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'

import { LocationPin } from 'icons/filled'
import { FormField, Input, Select } from 'components'
import { SHIPPING_COUNTRIES } from 'utils/device'
import { required } from 'utils/rules'

interface Props {
  form: FormInstance
}

type Option = { id?: string; value: string; label: string }
type AddressComponent = google.maps.places.PlaceResult['address_components']
type DetailType = 'locality' | 'administrative_area_level_1' | 'postal_code'

function LocationForm({ form }: Props) {
  const [selectedCountry, setSelectedCountry] = useState(SHIPPING_COUNTRIES[0])

  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    clearCache
  } = usePlacesAutocomplete({
    debounce: 500,
    requestOptions: {
      componentRestrictions: { country: selectedCountry.value }
    }
  })

  useEffect(() => {
    form.setFieldsValue({ country: selectedCountry })
  }, [])

  useEffect(() => {
    clearSuggestions()
    clearCache()
  }, [selectedCountry.value])

  const mapOptions = () => {
    return data.map(({ place_id, description }) => ({
      id: place_id,
      value: description,
      label: description
    }))
  }

  const getAddressDetail = (addresseComponents: AddressComponent) => {
    return (type: DetailType) => {
      const result = addresseComponents!.find(comp => comp.types[0] === type)
      return result?.long_name || ''
    }
  }

  const handleAddressChange = async ({ id }: Option) => {
    const result = await getDetails({
      placeId: id!,
      fields: ['address_component']
    })
    if (typeof result !== 'string' && !!result.address_components) {
      const details = getAddressDetail(result.address_components)
      form.setFieldsValue({
        city: details('locality'),
        state: details('administrative_area_level_1'),
        zipcode: details('postal_code')
      })
    }
  }

  return (
    <div className="mt-10 w-[25rem]">
      <div className="flex items-center gap-2 mb-4">
        <LocationPin type="primary" className="w-4 h-4 shrink-0" />
        <div className="text-base font-medium">Delivery Detail</div>
      </div>
      <FormField name="name" label="Full Name" requiredMask rules={[required]}>
        <Input />
      </FormField>
      <FormField
        name="phone"
        label="Phone number"
        requiredMask
        rules={[required]}
      >
        <Input />
      </FormField>
      <FormField
        name="country"
        label="Country/Region"
        requiredMask
        rules={[required]}
      >
        <Select
          options={SHIPPING_COUNTRIES}
          onChange={v => setSelectedCountry(v as Option)}
        />
      </FormField>
      <FormField
        name="address"
        label="Address"
        requiredMask
        rules={[required]}
        className="!mb-0"
      >
        <Select
          isDisabled={!ready}
          onInputChange={v => setValue(v, !!v.trim().length)}
          options={status !== 'OK' ? [] : mapOptions()}
          onChange={v => handleAddressChange(v as Option)}
          placeholder="Enter the location address"
        />
      </FormField>
      <FormField name="address2" className="mt-2">
        <Input placeholder="Apt, suite. unit. building. floor, etc" />
      </FormField>
      <FormField name="city" label="City" requiredMask rules={[required]}>
        <Input />
      </FormField>
      <div className="flex gap-2">
        <FormField
          className="w-full shink-0 mb-0"
          name="state"
          label="State"
          requiredMask
          rules={[required]}
        >
          <Input />
        </FormField>
        <FormField
          className="w-full shink-0 mb-0"
          name="zipcode"
          label="Zip Code"
          requiredMask
          rules={[required]}
        >
          <Input />
        </FormField>
      </div>
    </div>
  )
}

export default LocationForm
