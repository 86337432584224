import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function CloudUpload(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="24"
        height="19"
        viewBox="0 0 24 19"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M19.33 6.31622C18.3132 2.26565 14.2054 -0.193698 10.1548 0.82309C6.98937 1.61771 4.69092 4.35353 4.4541 7.60854C2.20652 7.97919 0.68495 10.1017 1.0556 12.3492C1.38509 14.3474 3.11646 15.8107 5.14153 15.8026H8.57865V14.4278H5.14153C3.62292 14.4278 2.39183 13.1967 2.39183 11.6781C2.39183 10.1595 3.62292 8.92839 5.14153 8.92839C5.5212 8.92839 5.82895 8.62064 5.82895 8.24097C5.82552 4.82408 8.5927 2.05135 12.0096 2.04795C14.9674 2.04499 17.5136 4.13605 18.0857 7.03797C18.1422 7.32772 18.3774 7.54907 18.6701 7.58791C20.5494 7.85554 21.8559 9.59597 21.5883 11.4753C21.348 13.1628 19.9072 14.4193 18.2026 14.4278H15.4529V15.8026H18.2026C20.8602 15.7946 23.0081 13.6337 23 10.9761C22.9933 8.76388 21.479 6.8412 19.33 6.31622Z"
          strokeWidth="0.5"
        />
        <path
          d="M11.5278 9.12777L8.77808 11.8775L9.74735 12.8467L11.3284 11.2725V17.8649H12.7033V11.2725L14.2775 12.8467L15.2467 11.8775L12.497 9.12777C12.2289 8.86122 11.7959 8.86122 11.5278 9.12777Z"
          strokeWidth="0.5"
        />
      </svg>
    </SVGUniqueID>
  )
}
