import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function CreditCard({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M20 3.74219V2.6875C20 1.47938 19.0206 0.5 17.8125 0.5H2.1875C0.979375 0.5 0 1.47938 0 2.6875V3.74219C0 3.85004 0.0874609 3.9375 0.195312 3.9375H19.8047C19.9125 3.9375 20 3.85004 20 3.74219Z"
          fill="url(#paint0_linear_8709_375)"
        />
        <path
          d="M0 5.38281V12.0625C0 13.2706 0.979375 14.25 2.1875 14.25H17.8125C19.0206 14.25 20 13.2706 20 12.0625V5.38281C20 5.27496 19.9125 5.1875 19.8047 5.1875H0.195312C0.0874609 5.1875 0 5.27496 0 5.38281ZM5 10.5C5 10.8452 4.72016 11.125 4.375 11.125H3.75C3.40484 11.125 3.125 10.8452 3.125 10.5V9.875C3.125 9.52984 3.40484 9.25 3.75 9.25H4.375C4.72016 9.25 5 9.52984 5 9.875V10.5Z"
          fill="url(#paint1_linear_8709_375)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8709_375"
            x1="0"
            y1="0.5"
            x2="0"
            y2="3.9375"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8709_375"
            x1="0"
            y1="5.1875"
            x2="0"
            y2="14.25"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
