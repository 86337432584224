import { ADD_ON, PLAN_TYPE } from 'utils/plan'

import FreePlan from 'assets/images/Plan_Free.png'
import BasicPlan from 'assets/images/Plan_Basic.png'
import PlusPlan from 'assets/images/Plan_Plus.png'
import ProPlan from 'assets/images/Plan_Pro.png'
import EnterprisePlan from 'assets/images/Plan_Enterprise.png'

type Props = { type: PLAN_TYPE | ADD_ON } & JSX.IntrinsicElements['img']

export function PlanIcon({ type, ...props }: Props) {
  const getImageSrc = () => {
    if (type === 'free_plan') return FreePlan
    if (type === 'basic_plan') return BasicPlan
    if (type === 'plus_plan') return PlusPlan
    if (type === 'professional_plan') return ProPlan
    if (type === 'enterprise') return EnterprisePlan
  }

  return <img {...props} alt={type || props.alt} src={getImageSrc()} />
}
