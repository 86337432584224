import { useState } from 'react'
import shallow from 'zustand/shallow'

import { DeviceIcon } from 'icons/utils'
import { CheckedCircle, WarningCircle } from 'icons/filled'
import { ArrowRight, Close, MinusCircle } from 'icons/outline'
import {
  Button,
  DatePicker,
  Modal,
  ModalProps,
  Select,
  Table,
  Tooltip
} from 'components'
import { validateTimeRange } from 'utils/validation'
import toast from 'utils/toast'
import { Device } from 'services/devices'
import { searchRecords } from 'services/recordingDownloads'
import useStore from 'store'

interface DeviceWithStatus extends Device {
  status?: 'success' | 'error'
  message?: string
}

interface Props extends ModalProps {
  timeRange: Date[]
  devices: DeviceWithStatus[]
}

function ModalExportRecord({ timeRange, devices: pDevices, ...props }: Props) {
  const [loading, setLoading] = useState(false)
  const [startTime, setStartTime] = useState<Date | null>(timeRange[0] || null)
  const [endTime, setEndTime] = useState<Date | null>(timeRange[1] || null)
  const [devices, setDevices] = useState(pDevices)

  const store = useStore(state => ({ devices: state.device.devices }), shallow)

  const handleClose = () => {
    !loading && props.onClose()
  }

  const handleAddDevice = ({ value }: any) => {
    setDevices(prev => {
      if (prev.find(d => d.id === value)) return prev
      return [...prev, store.devices.find(d => d.id === value)!]
    })
  }

  const handleExport = async () => {
    if (!startTime || !endTime || !devices.length) {
      return
    }

    const validateResult = validateTimeRange(startTime, endTime)
    if (validateResult !== true) {
      return toast.error({ title: validateResult })
    }

    setLoading(true)
    setDevices(prev => {
      return prev.map(d => ({ ...d, status: undefined, message: undefined }))
    })

    const statuses: boolean[] = []
    await Promise.all(
      devices.map(d =>
        searchRecords({
          device_id: d.id,
          start_timestamp: startTime,
          end_timestamp: endTime
        })
          .then(() => {
            statuses.push(true)
            setDevices(prev =>
              prev.map(device =>
                device.id === d.id
                  ? { ...device, status: 'success', message: 'Success' }
                  : device
              )
            )
          })
          .catch(err => {
            statuses.push(false)
            setDevices(prev =>
              prev.map(device =>
                device.id === d.id
                  ? { ...device, status: 'error', message: err.message }
                  : device
              )
            )
          })
      )
    )

    if (statuses.every(s => !s)) {
      toast.error({
        title: 'Opps! Your record(s) were failed to process',
        description:
          "We're so sorry for this inconvenient. You can try with a different time range"
      })
    } else {
      toast.success(
        {
          title: 'Your record(s) are being prepared',
          description: "We will let you know right after it's completed"
        },
        { position: 'bottom-center' }
      )
    }

    setLoading(false)
  }

  const columns = [
    {
      title: 'name',
      render: (device: DeviceWithStatus) => (
        <div className="flex items-center gap-1">
          <DeviceIcon
            type={device.type}
            className="w-5 shrink-0 text-[#bdbdbd]"
          />
          <div>{device.name}</div>
          {device.status && (
            <Tooltip content={device.message}>
              {device.status === 'success' && (
                <CheckedCircle className="w-3 h-3" type="success" />
              )}
              {device.status === 'error' && (
                <WarningCircle className="w-3 h-3" type="danger" />
              )}
            </Tooltip>
          )}
        </div>
      )
    },
    {
      title: 'type',
      dataIndex: 'type'
    },
    {
      width: 120,
      render: ({ id }: DeviceWithStatus) => (
        <Button
          className="w-full"
          onClick={() => setDevices(prev => prev.filter(d => d.id !== id))}
        >
          <MinusCircle /> Remove
        </Button>
      )
    }
  ]

  return (
    <Modal
      {...props}
      className="export-record w-[40.625rem]"
      onClose={handleClose}
    >
      <div className="flex items-center justify-between gap-4 mb-6">
        <div className="text-base font-medium">Export Records</div>
        <Close className="cursor-pointer shrink-0" onClick={props.onClose} />
      </div>
      <div className="mb-6">
        <label className="block font-medium text-[0.8125rem] mb-1.5">
          Select time range
          <span className="text-danger"> *</span>
        </label>
        <div className="flex items-center gap-2">
          <DatePicker
            showTime
            value={startTime}
            onChange={(_, v) => setStartTime(v)}
            placeholder="Start time"
          />
          <ArrowRight className="w-4 h-4 shrink-0 text-light-secondary" />
          <DatePicker
            showTime
            value={endTime}
            onChange={(_, v) => setEndTime(v)}
            placeholder="End time"
          />
        </div>
      </div>
      <div className="mb-6">
        <label className="block font-medium text-[0.8125rem] mb-1.5">
          Choose device to export
          <span className="text-danger"> *</span>
        </label>
        <Select
          placeholder="Select device to add, type to search"
          options={store.devices.map(d => ({ label: d.name, value: d.id }))}
          onChange={handleAddDevice}
        />
      </div>
      <Table rowKey="id" columns={columns} data={devices} />
      <div className="mt-4 flex items-center justify-between">
        <Button className="w-20" onClick={handleClose} disabled={loading}>
          Close
        </Button>
        <Button
          className="w-20"
          variant="primary"
          disabled={!startTime || !endTime || !devices.length || loading}
          onClick={loading ? undefined : handleExport}
        >
          Export
        </Button>
      </div>
    </Modal>
  )
}

export default ModalExportRecord
