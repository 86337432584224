import { ReactNode, useState } from 'react'
import shallow from 'zustand/shallow'

import { AsyncSelect, Button, Popover, RangePicker, Select } from 'components'
import { getPopupContainer } from 'components/Portal'
import { getTags } from 'services/tags'
import useStore from 'store'

interface Props {
  onClose: () => void
  filters: {
    device?: { value: number; label: ReactNode }
    dateRange?: [Date | null, Date | null]
    tags?: { value: number; label: string }[]
  }
  onChange: (filters: Props['filters']) => void
}

function FilterForm({ filters: propFilters, onChange, onClose }: Props) {
  const [filters, setFilters] = useState(propFilters)
  const devices = useStore(state => state.device.devices, shallow)

  const handleGetTags = async (search: string) => {
    const { data } = await getTags({ search })
    return data.data.map(tag => ({ value: tag.id, label: tag.name }))
  }

  const handleFilterChange = (isReset = false) => {
    onChange(
      isReset
        ? { device: undefined, dateRange: undefined, tags: undefined }
        : filters
    )
    onClose()
  }

  return (
    <Popover.Content
      id="filter-notifs"
      className="!p-0 w-[550px]"
      onClose={onClose}
    >
      <div className="p-4 text-base font-medium">Quick Filters</div>
      <div className="flex px-4 items-center gap-5 mb-4">
        <div className="text-[0.8125rem] font-medium w-[6.875rem]">Device</div>
        <Select
          value={filters.device}
          placeholder="Choose devices to filter, type to search"
          options={devices.map(d => ({ value: d.id, label: d.name }))}
          onChange={(v: any) => setFilters(prev => ({ ...prev, device: v }))}
        />
      </div>
      <div className="flex px-4 items-center gap-5 mb-4">
        <div className="text-[0.8125rem] font-medium w-[6.875rem]">
          Duration
        </div>
        <RangePicker
          showPresets
          value={filters.dateRange}
          placeholder={['From', 'To']}
          onChange={(_, v) => setFilters(prev => ({ ...prev, dateRange: v }))}
          popupContainer={() => getPopupContainer('#filter-notifs')}
        />
      </div>
      <div className="flex px-4 items-center gap-5 mb-6">
        <div className="text-[0.8125rem] font-medium w-[6.875rem]">Tags</div>
        <AsyncSelect
          isMulti
          isClearable={false}
          defaultOptions
          loadOptions={handleGetTags}
          value={filters.tags}
          placeholder="Select tags to filter"
          onChange={v => setFilters(prev => ({ ...prev, tags: v as any }))}
        />
      </div>
      <div className="border-b border-light-stroke my-3" />
      <div className="px-4 pb-3 flex items-center gap-2">
        <Button className="w-20" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="w-[6.25rem]"
          onClick={() => handleFilterChange(true)}
        >
          Reset Filter
        </Button>
        <Button
          variant="primary"
          className="ml-auto w-[7.5rem]"
          onClick={() => handleFilterChange()}
        >
          Apply Filter
        </Button>
      </div>
    </Popover.Content>
  )
}

export default FilterForm
