import { useEffect, useReducer, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { format } from 'date-fns'
import { AxiosResponse } from 'axios'

import { Note } from 'icons/filled'
import { EyeOpen } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Table, Tag, Tooltip } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import ModalShowAllTags from 'shared/Modals/Tag/ShowAllTags'
import ModalViewInboundNotifDetail from 'shared/Modals/Notification/InboundNotifDetail'
import ModalAddNotifNote from 'shared/Modals/Notification/AddNotifNote'
import { isImgFile } from 'utils/file'
import { dateTimeFormat } from 'utils/dateTime'
import { Tag as ITag } from 'services/tags'
import {
  getInboundNotifs,
  InboundNotif,
  InboundNotifResponse
} from 'services/notifications'

import ResourcesSummary from './ResourcesSummary'
import TableToolbar from './TableToolbar'

interface Props {
  deviceId: number
}

interface State {
  tags?: { value: number; label: string }[]
  dateRange?: [Date | null, Date | null]
  sortBy: string
  sortDirection: string
  currentPage: number
  pageSize: PageSizes
  search: string
}

const initState: State = {
  tags: undefined,
  dateRange: undefined,
  sortBy: 'id',
  sortDirection: 'desc',
  currentPage: 1,
  pageSize: 10,
  search: ''
}

function InboundNotifications({ deviceId }: Props) {
  const [showAllTags, setShowAllTags] = useState<ITag[]>([])
  const [addNote, setAddNote] = useState<number | null>(null)
  const [viewNotif, setViewNotif] = useState<InboundNotif | null>(null)

  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const notifAsync = useAsync<AxiosResponse<InboundNotifResponse>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetInboundNotifs()
  }, [...Object.values(state)])

  const handleGetInboundNotifs = () => {
    return notifAsync.execute(
      getInboundNotifs({ ...state, device: { value: deviceId, label: '' } })
    )
  }

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const renderTimestampCol = ({ created_at }: InboundNotif) => {
    return format(
      new Date(created_at),
      `${dateTimeFormat['MM-dd-yyyy']} ${dateTimeFormat['hh:mm a']}`
    )
  }

  const renderNotifContentCol = ({ notes, subject, ...rest }: InboundNotif) => {
    const imgIdx = (rest.attachments || []).findIndex(isImgFile)
    const length = notes.length
    return (
      <div className="flex items-center gap-2">
        {imgIdx > -1 && (
          <img
            src={rest.attachment_urls[imgIdx]}
            alt="attachment"
            className="shrink-0 w-20 max-h-[2.8125rem] rounded-sm"
          />
        )}
        <div>{subject}</div>
        {!!length && (
          <Tooltip content={`${length} note${length > 1 ? 's' : ''} added`}>
            <Note className="w-4 h-4 shrink-0" type="warning" />
          </Tooltip>
        )}
      </div>
    )
  }

  const renderTagCol = (notif: InboundNotif) => {
    const tags = notif.tags || []
    const length = tags.length

    if (!length) {
      return <div className="text-light-secondary">No tags</div>
    }

    return (
      <div className="flex items-center gap-2">
        {tags!.slice(0, 2).map(tag => (
          <Tag
            key={tag.id}
            strokeColor={tag.stroke_color}
            textColor={tag.text_color}
            bgColor={tag.color}
          >
            {tag.name}
          </Tag>
        ))}
        {length > 2 && (
          <div
            className="text-primary text-sm font-medium cursor-pointer"
            onClick={() => setShowAllTags(tags)}
          >
            +{length - 2} more
          </div>
        )}
      </div>
    )
  }

  const renderActionCol = (notif: InboundNotif) => (
    <div className="flex justify-end items-center gap-1">
      <Button variant="ternary" onClick={() => setViewNotif(notif)}>
        <EyeOpen /> Preview
      </Button>
      <Button variant="ternary" onClick={() => setAddNote(notif.id)}>
        <Note color="#303030" className="scale-75" /> Add Note
      </Button>
    </div>
  )

  const columns = [
    {
      key: 'created_at',
      title: 'timestamp',
      sorter: true,
      render: renderTimestampCol
    },
    {
      title: 'notification content',
      render: renderNotifContentCol
    },
    {
      title: 'recipients',
      render: (notif: InboundNotif) => notif.recipients_count || 0
    },
    {
      title: 'tag',
      render: renderTagCol
    },
    {
      width: 220,
      render: renderActionCol
    }
  ]

  return (
    <div className="p-4">
      <ResourcesSummary />
      <TableToolbar
        loading={notifAsync.isLoading}
        search={state.search}
        onSearchChange={s => setState({ search: s, currentPage: 1 })}
        currentPage={state.currentPage}
        totalPage={notifAsync.data?.data.meta.last_page || 1}
        onPageChange={p => setState({ currentPage: p })}
        filters={{ dateRange: state.dateRange, tags: state.tags }}
        onFilter={filters => setState(filters)}
        onReload={handleGetInboundNotifs}
      />
      <Table
        rowKey="id"
        columns={columns}
        scroll={{ x: 1400 }}
        loading={notifAsync.isLoading}
        data={notifAsync.data?.data.data}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={notifAsync.isLoading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={notifAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
      <AnimatePresence initial={false}>
        {!!showAllTags.length && (
          <ModalShowAllTags
            tags={showAllTags}
            onClose={() => setShowAllTags([])}
          />
        )}
        {!!viewNotif && (
          <ModalViewInboundNotifDetail
            notif={viewNotif}
            onClose={() => setViewNotif(null)}
          />
        )}
        {!!addNote && (
          <ModalAddNotifNote
            notifId={addNote}
            onClose={() => setAddNote(null)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default InboundNotifications
