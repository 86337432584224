import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import {
  DropdownDown,
  DropdownUp,
  EyeOpen,
  Humidity,
  More,
  Pencil,
  TemperatureSensor
} from 'icons/outline'
import { Button, Popover } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalEditDevice from 'shared/Modals/Device/EditDevice'
import { formatSensorTemperature } from 'utils/device'
import { Device } from 'services/devices'
import useStore, { Store } from 'store'

interface Props {
  className?: string
  device: Device
}

const mapState = (state: Store, deviceId: number) => ({
  sensor: state.device.sensors.find(({ id }) => deviceId === id),
  user: state.auth.currentUser
})

function SensorDevice({ device, className }: Props) {
  const navigate = useNavigate()
  const [visible, setVisible] = useState('')

  const { sensor, user } = useStore(
    state => mapState(state, device.id),
    shallow
  )

  if (!sensor || !user) {
    return null
  }

  const renderMoreOptions = (onClose: () => void) => {
    const onClick = (fn: Function) => () => {
      onClose()
      fn()
    }
    return (
      <Popover.Content className="w-48" onClose={onClose}>
        <MenuItem
          className="mb-1 text-[0.8125rem]"
          onClick={onClick(() =>
            navigate(`/devices/${device.id}?tab=device-details`)
          )}
        >
          <EyeOpen className="w-3.5 h-3.5 shrink-0" /> View Device Detail
        </MenuItem>
        <MenuItem
          className="text-[0.8125rem]"
          onClick={onClick(() => setVisible('edit'))}
        >
          <Pencil className="w-3.5 h-3.5 shrink-0" /> Edit Device
        </MenuItem>
      </Popover.Content>
    )
  }

  const renderArrow = (a: number, b: number) => {
    if (a > b) return <DropdownUp className="text-success" />
    if (b > a) return <DropdownDown className="text-danger" />
    return null
  }

  const { unit_temperature } = user
  const { temperatureSensorLogs: logs = [] } = sensor
  const [first, last = first] = logs.slice(Math.max(logs.length - 2, 0))

  return (
    <Fragment>
      <MenuItem
        className={cx(
          'relative w-full flex items-center justify-between',
          className
        )}
      >
        <div>
          <div className="mb-1.5 text-[0.8125rem]">{device.name}</div>
          <div className="flex items-center gap-6">
            <div className="inline-flex items-center gap-1.5">
              <TemperatureSensor className="w-4 h-4 shrink-0 text-[#dedede]" />
              <div className="text-sm inline-flex items-center gap-1.5 font-medium">
                {last?.temperature && (
                  <Fragment>
                    {formatSensorTemperature(
                      last.temperature,
                      unit_temperature
                    )}
                    {renderArrow(last.temperature, first?.temperature || 0)}
                  </Fragment>
                )}
                {!last?.temperature && '---'}
              </div>
            </div>
            <div className="inline-flex items-center">
              <Humidity className="w-4 h-4 shrink-0 text-[#dedede]" />
              <div className="text-sm inline-flex items-center gap-1 font-medium">
                {last?.humidity && (
                  <Fragment>
                    {last.humidity}%
                    {renderArrow(last.humidity, first?.humidity || 0)}
                  </Fragment>
                )}
                {!last?.humidity && '---'}
              </div>
            </div>
          </div>
        </div>
        <Popover
          className="ml-auto"
          placement="bottom-end"
          content={onClose => renderMoreOptions(onClose)}
        >
          <Button>
            <More />
          </Button>
        </Popover>
      </MenuItem>
      <AnimatePresence initial={false}>
        {visible === 'edit' && (
          <ModalEditDevice device={device} onClose={() => setVisible('')} />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default SensorDevice
