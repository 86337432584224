import { Fragment, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { Plus, Search } from 'icons/outline'
import { Button, Input, Pagination } from 'components'
import ModalCreateUser from 'shared/Modals/UserRecipient/CreateUser'

interface Props {
  loading: boolean
  search: string
  onSearchChange: (s: string) => void
  currentPage: number
  totalPage: number
  onPageChange: (p: number) => void
  onRefetch: () => void
}

function TableToolbar({
  loading,
  search,
  onSearchChange,
  currentPage,
  totalPage,
  onPageChange,
  onRefetch
}: Props) {
  const [visible, setVisible] = useState(false)

  const handleSearch: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      value !== search && onSearchChange(value)
    }
  }

  return (
    <Fragment>
      <div className="mb-4 flex flex-wrap justify-between items-center gap-4">
        <Input
          suffix={<Search />}
          className="w-[22.5rem]"
          onKeyDown={loading ? undefined : handleSearch}
          placeholder="Search activity by name, user, device"
        />
        <div className="inline-flex gap-2">
          <Pagination.ButtonGroup
            loading={loading}
            currentPage={currentPage}
            totalPage={totalPage}
            onPageChange={loading ? undefined : onPageChange}
          />
          <Button
            variant="primary"
            className="w-[9.375rem]"
            onClick={loading ? undefined : () => setVisible(true)}
          >
            <Plus /> Create User
          </Button>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {!!visible && (
          <ModalCreateUser
            onSuccess={onRefetch}
            onClose={() => setVisible(false)}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default TableToolbar
