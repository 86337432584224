import { Fragment } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Pencil, Search } from 'icons/outline'
import { ResourceIcon } from 'icons/utils'
import { Avatar, Button, Input } from 'components'

function WhatsAppRecipients() {
  return (
    <Fragment>
      <div className="flex items-center justify-between gap-4 mb-4">
        <div className="inline-flex items-center gap-2">
          <ResourceIcon
            type="whatsapp"
            className="w-5 h-5 shrink-0"
            iconType="primary"
          />
          <div className="text-base font-medium">WhatsApp Recipient</div>
        </div>
        <div className="inline-flex items-center gap-2">
          <Input
            suffix={<Search />}
            className="w-80"
            placeholder="Search by name, phone"
          />
          <Button variant="ternary" className="w-20">
            <Pencil /> Edit
          </Button>
        </div>
      </div>
      <PerfectScrollbar className="max-h-[calc(100vh_-_14.5625rem)] overflow-x-hidden">
        <div className="grid grid-cols-[repeat(auto-fill,minmax(22rem,1fr))] gap-4">
          <div className="bg-white shadow-[0px_1px_2px_rgba(0,0,0,0.16)] rounded-lg p-2 flex items-center gap-3">
            <Avatar username="Eric" className="w-6 h-6 ml-2" />
            <div>
              <div className="text-[0.8125rem] mb-1">Eric Zimerman</div>
              <div className="text-xs text-light-secondary">(651)394-4786</div>
            </div>
          </div>
          <div className="bg-white shadow-[0px_1px_2px_rgba(0,0,0,0.16)] rounded-lg p-2 flex items-center gap-3">
            <Avatar username="Eric" className="w-6 h-6 ml-2" />
            <div>
              <div className="text-[0.8125rem] mb-1">Eric Zimerman</div>
              <div className="text-xs text-light-secondary">(651)394-4786</div>
            </div>
          </div>
          <div className="bg-white shadow-[0px_1px_2px_rgba(0,0,0,0.16)] rounded-lg p-2 flex items-center gap-3">
            <Avatar username="Eric" className="w-6 h-6 ml-2" />
            <div>
              <div className="text-[0.8125rem] mb-1">Eric Zimerman</div>
              <div className="text-xs text-light-secondary">(651)394-4786</div>
            </div>
          </div>
          <div className="bg-white shadow-[0px_1px_2px_rgba(0,0,0,0.16)] rounded-lg p-2 flex items-center gap-3">
            <Avatar username="Eric" className="w-6 h-6 ml-2" />
            <div>
              <div className="text-[0.8125rem] mb-1">Eric Zimerman</div>
              <div className="text-xs text-light-secondary">(651)394-4786</div>
            </div>
          </div>
          <div className="bg-white shadow-[0px_1px_2px_rgba(0,0,0,0.16)] rounded-lg p-2 flex items-center gap-3">
            <Avatar username="Eric" className="w-6 h-6 ml-2" />
            <div>
              <div className="text-[0.8125rem] mb-1">Eric Zimerman</div>
              <div className="text-xs text-light-secondary">(651)394-4786</div>
            </div>
          </div>
          <div className="bg-white shadow-[0px_1px_2px_rgba(0,0,0,0.16)] rounded-lg p-2 flex items-center gap-3">
            <Avatar username="Eric" className="w-6 h-6 ml-2" />
            <div>
              <div className="text-[0.8125rem] mb-1">Eric Zimerman</div>
              <div className="text-xs text-light-secondary">(651)394-4786</div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </Fragment>
  )
}

export default WhatsAppRecipients
