import { useEffect, useReducer, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'
import { pick } from 'lodash'

import { ChevronDown } from 'icons/outline'
import { useAsync } from 'hooks'
import {
  Button,
  Form,
  Modal,
  ModalProps,
  Popover,
  Spinner,
  Tabs
} from 'components'
import ModalGuideBook from 'shared/Modals/GuideBook'
import PopoverHelpMenu from 'shared/Popovers/HelpMenu'
import toast from 'utils/toast'
import { dateTimeFormat, days, formatTime } from 'utils/dateTime'
import {
  getScheduleDetails,
  Schedule,
  updateSchedule
} from 'services/schedules'

import { FormValues, initFormValues, mapInitFormValues } from './FormHelpers'
import ScheduleDetails from './ScheduleDetails'
import Recipients from './Recipients'

interface Props extends ModalProps {
  scheduleId: number
  onSuccess?: () => void
}

function ModalEditSchedule({ scheduleId, onSuccess, ...props }: Props) {
  const { show } = useIntercom()
  const [sheduleDetailsForm] = Form.useForm()

  const [showGuideBook, setShowGuideBook] = useState(false)
  const [formValues, setFormValues] = useReducer(
    (s: FormValues, a: Partial<FormValues>) => ({ ...s, ...a }),
    initFormValues
  )

  const scheduleAsync = useAsync<AxiosResponse<{ data: Schedule }>>({
    status: 'pending',
    showNotifOnError: true
  })
  const updateAsync = useAsync({
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetScheduleDetail()
  }, [])

  const handleGetScheduleDetail = async () => {
    try {
      const { data } = await scheduleAsync.execute(
        getScheduleDetails(scheduleId)
      )
      setFormValues(mapInitFormValues(data.data))
    } catch (err) {
      console.error(err)
    }
  }

  const handleClose = () => {
    !updateAsync.isLoading && props.onClose()
  }

  const handleSubmit = () => {
    const { scheduleDetails, recipients } = formValues
    let errors = []

    if (!scheduleDetails.name) {
      errors.push({ name: 'name', errors: ['This field is required'] })
    }

    const { is_24_7, start_time, end_time, days } = scheduleDetails
    if (!is_24_7 && (!start_time || !end_time || !days.length)) {
      errors.push({
        name: 'dateTime',
        errors: ['Please tell us when you can received notification']
      })
    }

    if (errors.length) {
      toast.error({
        title: 'Invalid form',
        description: 'The provided data is not valid'
      })
      return sheduleDetailsForm.setFields(errors)
    }

    if (!recipients.channels.length) {
      return toast.error({
        title: 'Invalid form',
        description: 'Please select atleast 1 channel'
      })
    }

    handleUpdateSchedule()
  }

  const handleUpdateSchedule = async () => {
    const { id, scheduleDetails, recipients: recs } = formValues
    const { is_24_7, start_time, end_time, ...s } = scheduleDetails
    const selectedDays = (is_24_7 ? days : s.days).map(d => d.toUpperCase())

    try {
      await updateAsync.execute(
        updateSchedule(id, {
          name: s.name,
          is_24_7,
          days: selectedDays,
          start_time: is_24_7
            ? undefined
            : formatTime(start_time, dateTimeFormat['HH:mm']),
          end_time: is_24_7
            ? undefined
            : formatTime(end_time, dateTimeFormat['HH:mm']),
          email_recipient_ids: recs.email_recipients.map(r => r.value),
          phone_recipient_ids: recs.phone_recipients.map(r => r.value),
          whatsapp_recipient_ids: recs.whatsapp_recipients.map(r => r.value),
          ...pick(recs, [
            'channels',
            'webhook',
            'slack_webhook',
            'teams_webbhook',
            'pagerduty_integration_key'
          ])
        })
      )
      toast.success(
        { title: 'Schedule updated' },
        { position: 'bottom-center' }
      )
      onSuccess?.()
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  const renderPopoverHelpMenu = (onClose: () => void) => (
    <PopoverHelpMenu
      onContactSupport={() => show()}
      onOpenGuideBook={() => setShowGuideBook(true)}
      onClose={onClose}
    />
  )

  return (
    <Modal
      {...props}
      wrapperClassName="!p-0"
      className="w-[100vw] min-h-[100vh] rounded-none !p-0"
      onClose={handleClose}
    >
      <div className="pt-3 px-[5.3125rem] flex items-center justify-between gap-4 mb-3">
        <div className="text-base font-medium">Update Schedule</div>
        <div className="inline-flex items-center gap-2">
          <Popover
            placement="bottom-end"
            content={onClose => renderPopoverHelpMenu(onClose)}
          >
            <div className="text-primary inline-flex items-center gap-1 mr-4 text-[0.8125rem] font-medium cursor-pointer">
              Help <ChevronDown />
            </div>
          </Popover>
          <Button
            className="w-20"
            disabled={updateAsync.isLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="w-[7.5rem]"
            disabled={scheduleAsync.isLoading || updateAsync.isLoading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
      {scheduleAsync.isLoading && <Spinner className="min-h-[90vh]" />}
      {!scheduleAsync.isLoading && !!formValues.id && (
        <Tabs className="modal-tabs">
          <Tabs.TabPane key={1} tab="Schedule Details">
            <ScheduleDetails
              form={sheduleDetailsForm}
              values={formValues.scheduleDetails}
              setValues={setFormValues}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key={2} tab="Recipients">
            <Recipients
              values={formValues.recipients}
              setValues={setFormValues}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
      <AnimatePresence initial={false}>
        {showGuideBook && (
          <ModalGuideBook onClose={() => setShowGuideBook(false)} />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default ModalEditSchedule
