import { Fragment, useEffect, useReducer } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { AxiosResponse } from 'axios'
import shallow from 'zustand/shallow'

import { useAsync } from 'hooks'
import { ActivityLogIcon } from 'icons/utils'
import { Table } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import { dateTimeFormat } from 'utils/dateTime'
import {
  ActivityLog as IActivityLog,
  ActivityLogResponse,
  getAllLogs
} from 'services/activityLogs'
import useStore from 'store'

import TableToolbar from './TableToolbar'

interface RouteState {
  userId: number
  username: string
}

interface State {
  user?: { value: number; label: string }
  sortBy: string
  sortDirection: string
  search: string
  dateRange: [Date | null, Date | null]
  currentPage: number
  pageSize: PageSizes
}

const initState: State = {
  user: undefined,
  sortBy: 'id',
  sortDirection: 'desc',
  search: '',
  dateRange: [null, null],
  currentPage: 1,
  pageSize: 10
}

function TableList() {
  const navigate = useNavigate()
  const location = useLocation()

  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const devices = useStore(state => state.device.devices, shallow)

  const logAsync = useAsync<AxiosResponse<ActivityLogResponse>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    if (!!location.state) {
      const { userId, username } = location.state as RouteState
      setState({ user: { label: username, value: userId } })
      navigate('', { replace: true, state: undefined })
    } else {
      logAsync.execute(getAllLogs({ ...state, userId: state.user?.value }))
    }
  }, [...Object.values(state)])

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const renderCreatedAtCol = ({ created_at }: IActivityLog) => {
    return format(
      new Date(created_at),
      `${dateTimeFormat['MM-dd-yyyy']} ${dateTimeFormat['hh:mm a']}`
    )
  }

  const renderActivityCol = (log: IActivityLog) => {
    const device =
      log.subject_type === 'device' && log.subject_id
        ? devices.find(d => d.id === log.subject_id)
        : undefined

    return (
      <div className="flex items-center gap-2 capitalize">
        <ActivityLogIcon
          log={log.description}
          className="w-4 h-4 text-light-secondary shrink-0"
        />
        <span>
          {log.description}{' '}
          {!!device && (
            <Link
              to={`/devices/${device.id}?tab=device-details`}
              className="text-primary font-medium underline hover:no-underline"
            >
              {device.name}
            </Link>
          )}
        </span>
      </div>
    )
  }

  const columns = [
    {
      key: 'created_at',
      sorter: true,
      title: 'happened at',
      width: 300,
      render: renderCreatedAtCol
    },
    {
      key: 'causer_type',
      sorter: true,
      title: 'initiated by',
      width: 300,
      render: (row: IActivityLog) => row.causer_name || row.causer_type || '---'
    },
    {
      key: 'causer_ip',
      title: 'ip address',
      width: 300,
      render: (row: IActivityLog) => row.causer_ip || '---'
    },
    {
      title: 'activity',
      render: renderActivityCol
    }
  ]

  return (
    <Fragment>
      <TableToolbar
        loading={logAsync.isLoading}
        user={state.user}
        onUserChange={user => setState({ user })}
        search={state.search}
        onSearch={v => setState({ search: v, currentPage: 1 })}
        dateRange={state.dateRange}
        onDateChange={range => setState({ dateRange: range })}
        currentPage={state.currentPage}
        totalPage={logAsync.data?.data.meta.last_page || 1}
        onPageChange={p => setState({ currentPage: p })}
      />
      <Table
        rowKey="id"
        columns={columns}
        scroll={{ x: 1400 }}
        loading={logAsync.isLoading}
        data={logAsync.data?.data.data || []}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={logAsync.isLoading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={logAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
    </Fragment>
  )
}

export default TableList
