const GRADIENTS = {
  primary: ['#6065EA', '#3A3EA6'],
  secondary: ['#DDDDDD', '#B5B5B5'],
  success: ['#46D86C', '#0B9830'],
  warning: ['#FFC96A', '#F98F14'],
  danger: ['#F96E6E', '#CC2020'],
  info: ['#55F5FF', '#1DADCD']
}

export { GRADIENTS }
