import { PLAN_TYPE, ADD_ON } from 'utils/plan'
import client from './client'

export interface AccountCredit {
  account_id: number
  cloud_storage: number
  created_at: string
  email_count: number
  id: number
  sms_count: number
  updated_at: string
  vault_storage: number
  whatsapp_count: number
}

export interface CreditCard {
  brand: string
  last_4: string
}

export interface Plan {
  description: string
  device_count: number
  email_count: number
  handler: string
  has_audit_trails: boolean
  has_user_mgmt: boolean
  has_vault: boolean
  id: number
  inbound_log_days: number
  interval: string
  interval_count: number
  label: string
  name: PLAN_TYPE | ADD_ON
  payment_type: string
  price: number
  price_in_dollars: number | null
  recording_count: number
  show_counters: number
  sms_count: number
  third_party_integrations: boolean
  type: 'plan' | 'add_on'
  use_brand_logo: number
  whatsapp_count: number
}

export interface AccountSubscription {
  account_id: 39
  billing_end: string
  billing_end_date: string
  billing_start: string
  billing_start_date: string
  camera_licenses: number
  created_at: string
  deleted_at: string | null
  ends_at: string | null
  handler: string
  id: number
  is_active: number
  is_on_trial: boolean
  payment_mode: string
  plan: Plan | undefined
  plan_id: number
  status: string
  subscription_id: string
  updated_at: string
  used_camera_licenses: number
}

export interface Subscription {
  account_credit: AccountCredit | undefined
  billing_id: string | null
  brand_logo: string | null
  card: CreditCard | null
  created_at: string
  customer: { balance: number } | undefined
  email: string
  enabled_notifications: number
  id: number
  is_active: number
  is_new_account: boolean
  is_unlimited: boolean
  name: string
  owner_id: number
  pm_last_four: string
  pm_type: string
  stripe_id: string
  subscription: AccountSubscription | null
  trial_ends_at: null
  upcoming_invoice: { amount: number; date: string } | undefined
  updated_at: string
  use_custom_smtp: number
}

export const getSubscriptionDetails = () => {
  return client.get<Subscription>('/api/billing/subscription')
}

export const createSubscription = (payload: {
  payment_token: string
  plan_id: number
  camera_licenses: number
  coupon?: string
}) => {
  return client.post('/api/billing/subscription', payload)
}

export const updateSubscription = (payload: {
  plan_id: number
  camera_licenses: number
  coupon?: string
}) => {
  return client.put('/api/billing/subscription', payload)
}

export const cancelSubscription = () => {
  return client.delete('/api/billing/subscription')
}

export const getDefaultCreditCard = () => {
  return client.get<CreditCard>('/api/billing/card')
}

export const updateDefaultCreditCard = (payload: string) => {
  return client.post('/api/billing/card', { payment_token: payload })
}

export const getPlans = () => {
  return client.get<{ data: Plan[] }>('/api/plans?sort=asc')
}

// ------------------------------------------------------------------------- //

export interface Coupon {
  amount_off: number | null
  camera_licenses: number | null
  code: string | null
  message: string | null
  percent_off: number | null
  plan_id: number | null
}

export const getCouponInfo = (coupon: string) => {
  return client.get(`/api/billing/coupons/${coupon}`)
}
