import { Fragment, useEffect, useReducer } from 'react'
import { AxiosResponse } from 'axios'
import { format } from 'date-fns'

import { EyeOpen } from 'icons/outline'
import { useAsync, usePageTitle } from 'hooks'
import { Button, Pagination, Table } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import { dateTimeFormat } from 'utils/dateTime'
import {
  Transaction,
  getTransactionHistory,
  TransactionResponse
} from 'services/transactions'

interface Props {
  showTableToolbar?: boolean
}

interface State {
  sortBy: string
  sortDirection: string
  currentPage: number
  pageSize: PageSizes
}

const initState: State = {
  sortBy: 'id',
  sortDirection: 'desc',
  currentPage: 1,
  pageSize: 10
}

function TableList({ showTableToolbar }: Props) {
  usePageTitle('Transaction History | Broadflow Streams')

  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const historyAsync = useAsync<AxiosResponse<TransactionResponse>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    historyAsync.execute(getTransactionHistory(state))
  }, [...Object.values(state)])

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const columns = [
    {
      title: 'transaction id',
      key: 'transaction_id',
      dataIndex: 'transaction_id',
      sorter: true
    },
    {
      title: 'date paid',
      key: 'date',
      render: (row: Transaction) => {
        return format(
          new Date(row.date),
          `${dateTimeFormat['MMM dd, yyyy']} ${dateTimeFormat['hh:mm a']}`
        )
      }
    },
    {
      title: 'amount paid',
      key: 'amount',
      sorter: true,
      render: (row: Transaction) => `$${row.amount.toFixed(2)}`
    },
    {
      title: 'CARD NUMBER',
      render: (row: Transaction) => `**********${row.card_last_four}`
    },
    {
      render: (row: Transaction) => (
        <div className="text-right">
          <Button
            variant="ternary"
            onClick={() => window.open(row.receipt_url, '_blank')}
          >
            <EyeOpen />
            View Receipt Details
          </Button>
        </div>
      )
    }
  ]

  return (
    <Fragment>
      {showTableToolbar !== false && (
        <div className="mb-4 flex justify-end">
          <Pagination.ButtonGroup
            loading={historyAsync.isLoading}
            currentPage={state.currentPage}
            totalPage={historyAsync.data?.data.meta.last_page || 1}
            onPageChange={p => setState({ currentPage: p })}
          />
        </div>
      )}
      <Table
        rowKey="id"
        columns={columns}
        scroll={{ x: 1400 }}
        loading={historyAsync.isLoading}
        data={historyAsync.data?.data.data || []}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={historyAsync.isLoading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={historyAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
    </Fragment>
  )
}

export default TableList
