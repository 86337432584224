import { Recipient } from './recipients'
import client, { ResponseMeta } from './client'

export interface Schedule {
  channels: string[]
  created_at: string
  days: string[]
  device_id: number
  end_time: null | string
  id: number
  is_24_7: number
  is_active: number
  name: string
  pagerduty_integration_key: string | null
  recipients_count: number
  slack_webhook: string | null
  start_time: null | string
  teams_webbhook: string | null
  updated_at: string
  webhook: string | null
  email_recipients?: Recipient[]
  phone_recipients?: Recipient[]
  whatsapp_recipients?: Recipient[]
}

export type ScheduleResponse = {
  data: Schedule[]
  meta: ResponseMeta
}

export const getSchedules = (
  id: number,
  {
    sortBy = 'id',
    sortDirection = 'desc',
    search = '',
    pageSize = 10,
    currentPage = 1
  }: {
    sortBy?: string
    sortDirection?: string
    pageSize?: number
    search?: string
    currentPage?: number
  }
) => {
  return client.get<ScheduleResponse>(
    `/api/deviceSchedules?filters[device_id]=${id}&order_by=${sortBy}&sort=${sortDirection}&limit=${pageSize}&filters[search]=${search}&page=${currentPage}`
  )
}

export const getScheduleDetails = (id: number) => {
  return client.get<{ data: Schedule }>(
    `api/deviceSchedules/${id}?include=emailRecipients,phoneRecipients,whatsappRecipients`
  )
}

export const createSchedule = async (schedule: {
  is_active: boolean
  device_id: number
  name: string
  is_24_7: boolean
  start_time?: string
  end_time?: string
  days?: string[]
  channels: string[]
  webhook?: string
  slack_webhook?: string
  teams_webbhook?: string
  pagerduty_integration_key?: string
  email_recipient_ids?: number[]
  phone_recipient_ids?: number[]
  whatsapp_recipient_ids?: number[]
}) => {
  await client.get('/api/csrf-cookie')
  return client.post('/api/deviceSchedules', schedule)
}

export const updateSchedule = async (
  id: number,
  payload: {
    device_id?: number
    is_active?: boolean
    name?: string
    is_24_7?: boolean
    start_time?: string
    end_time?: string
    days?: string[]
    channels?: string[]
    webhook?: string
    slack_webhook?: string
    teams_webbhook?: string
    pagerduty_integration_key?: string
    email_recipient_ids?: number[]
    phone_recipient_ids?: number[]
    whatsapp_recipient_ids?: number[]
  }
) => {
  await client.get('/api/csrf-cookie')
  return client.put(`api/deviceSchedules/${id}`, payload)
}

export const deleteSchedule = (id: number) => {
  return client.delete(`api/deviceSchedules/${id}}`)
}
