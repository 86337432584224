import { useEffect, useReducer, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'
import cx from 'classnames'

import {
  CheckedCircle,
  Delete,
  EyeOpen,
  IgnoreCircle,
  Pencil
} from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Switch, Table, Tag } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import ModalViewTagDetail from 'shared/Modals/Tag/ViewTagDetail'
import ModalConfirmDelete from 'shared/Modals/Confirm/ConfirmDelete'
import toast from 'utils/toast'
import {
  getTags,
  TagResponse,
  Tag as ITag,
  deleteTag,
  updateTag
} from 'services/tags'

import TableToolbar from './TableToolbar'
import ModalEditTag from 'shared/Modals/Tag/EditTag'

interface State {
  sortBy: string
  sortDirection: string
  search: string
  currentPage: number
  pageSize: PageSizes
}

const initState: State = {
  sortBy: 'id',
  sortDirection: 'desc',
  search: '',
  currentPage: 1,
  pageSize: 10
}

function TagManagement() {
  const [viewTag, setViewTag] = useState<ITag | null>(null)
  const [editTagId, setEditTagId] = useState<number | null>(null)
  const [deleteTagId, setDeleteTagId] = useState<number | null>(null)
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const updateAsync = useAsync({ showNotifOnError: true })
  const deleteAsync = useAsync({ showNotifOnError: true })
  const tagAsync = useAsync<AxiosResponse<TagResponse>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetTags()
  }, [...Object.values(state)])

  const handleGetTags = () => {
    return tagAsync.execute(getTags(state))
  }

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const handleDeleteTag = async () => {
    if (!deleteTagId) return
    const id = deleteTagId
    setDeleteTagId(null)

    try {
      await deleteAsync.execute(deleteTag(id))
      toast.success({ title: 'Tag deleted' }, { position: 'bottom-center' })
      if (tagAsync.data?.data.data.length === 1 && state.currentPage > 1) {
        return setState({ currentPage: state.currentPage - 1 })
      }
      handleGetTags()
    } catch (err) {
      console.error(err)
    }
  }

  const handleActiveStatusChange = async (id: number, isActive: boolean) => {
    try {
      await updateAsync.execute(updateTag(id, { is_active: isActive }))
      handleGetTags()
    } catch (err) {
      console.error(err)
    }
  }

  const renderTagNameCol = (tag: ITag) => (
    <Tag
      strokeColor={tag.stroke_color}
      textColor={tag.text_color}
      bgColor={tag.color}
    >
      {tag.name}
    </Tag>
  )

  const renderActiveStatusCol = ({ id, is_active }: ITag) => (
    <Switch
      checked={!!is_active}
      onChange={checked => handleActiveStatusChange(id, checked)}
      className={cx('font-medium', is_active && 'text-primary')}
    >
      {is_active ? 'Active' : 'Inactive'}
    </Switch>
  )

  const renderApplyToAllCol = ({ apply_to_all }: ITag) => (
    <div
      className={cx(
        'flex items-center gap-1',
        !apply_to_all ? 'text-danger' : 'text-success'
      )}
    >
      {!apply_to_all ? (
        <IgnoreCircle className="shrink-0 w-3.5 h-3.5" />
      ) : (
        <CheckedCircle className="shrink-0 w-3.5 h-3.5" />
      )}
      {!apply_to_all ? 'No' : 'Yes'}
    </div>
  )

  const renderForwardMatchesCol = ({ is_blocked }: ITag) => (
    <div
      className={cx(
        'flex items-center gap-1',
        is_blocked ? 'text-danger' : 'text-success'
      )}
    >
      {is_blocked ? (
        <IgnoreCircle className="shrink-0 w-3.5 h-3.5" />
      ) : (
        <CheckedCircle className="shrink-0 w-3.5 h-3.5" />
      )}
      {is_blocked ? 'No' : 'Yes'}
    </div>
  )

  const renderActionCol = (tag: ITag) => (
    <div className="flex items-center justify-end gap-1">
      <Button variant="ternary" onClick={() => setViewTag(tag)}>
        <EyeOpen /> View
      </Button>
      <Button variant="ternary" onClick={() => setEditTagId(tag.id)}>
        <Pencil className="scale-80" /> Edit
      </Button>
      <Button variant="ternary" onClick={() => setDeleteTagId(tag.id)}>
        <Delete /> Delete
      </Button>
    </div>
  )

  const columns = [
    {
      key: 'name',
      title: 'tag name',
      sorter: true,
      render: renderTagNameCol
    },
    {
      title: 'title match',
      render: (tag: ITag) => tag.subject_keywords || '---'
    },
    {
      title: 'content match',
      render: (tag: ITag) => tag.body_keywords || '---'
    },
    {
      key: 'has_attachments',
      title: 'has attachments',
      sorter: true,
      render: (tag: ITag) => (!!tag.has_attachments ? 'Yes' : 'No')
    },
    {
      key: 'is_active',
      title: 'active status',
      sorter: true,
      render: renderActiveStatusCol
    },
    {
      key: 'apply_to_all',
      title: 'apply to all',
      sorter: true,
      render: renderApplyToAllCol
    },
    {
      key: 'is_blocked',
      title: 'forward if matches',
      sorter: true,
      render: renderForwardMatchesCol
    },
    {
      width: 250,
      render: renderActionCol
    }
  ]

  const loading =
    tagAsync.isLoading || updateAsync.isLoading || deleteAsync.isLoading

  return (
    <div className="p-4">
      <TableToolbar
        loading={loading}
        search={state.search}
        onSearchChange={s => setState({ search: s, currentPage: 1 })}
        currentPage={state.currentPage}
        totalPage={tagAsync.data?.data.meta.last_page || 1}
        onPageChange={p => setState({ currentPage: p })}
        onRefetch={handleGetTags}
      />
      <Table
        rowKey="id"
        loading={loading}
        data={tagAsync.data?.data.data}
        columns={columns}
        scroll={{ x: 1400 }}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={loading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={tagAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
      <AnimatePresence initial={false}>
        {!!viewTag && (
          <ModalViewTagDetail
            tag={viewTag}
            onEdit={() => {
              setEditTagId(viewTag.id)
              setViewTag(null)
            }}
            onDelete={() => {
              setDeleteTagId(viewTag.id)
              setViewTag(null)
            }}
            onClose={() => setViewTag(null)}
          />
        )}
        {!!editTagId && (
          <ModalEditTag
            tagId={editTagId}
            onSuccess={handleGetTags}
            onClose={() => setEditTagId(null)}
          />
        )}
        {!!deleteTagId && (
          <ModalConfirmDelete
            modalTitle="Delete Tag"
            confirmText="Are you sure you want to delete this tag?"
            warnText="All notifcations matching this tag will NOT be deleted"
            onConfirm={handleDeleteTag}
            onClose={() => setDeleteTagId(null)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default TagManagement
