import { Fragment, useEffect, useReducer, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { AxiosResponse } from 'axios'
import cx from 'classnames'

import { ResourceIcon } from 'icons/utils'
import { Delete, EyeOpen, Pencil } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Switch, Table } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import ModalScheduleDetail from 'shared/Modals/Schedule/ScheduleDetail'
import ModalConfirmDelete from 'shared/Modals/Confirm/ConfirmDelete'
import toast from 'utils/toast'
import { formatTime, shortDay } from 'utils/dateTime'
import { channelToResource } from 'utils/channel'
import { NotifChannel } from 'services/tags'
import {
  Schedule,
  ScheduleResponse,
  getSchedules,
  deleteSchedule,
  updateSchedule
} from 'services/schedules'

import TableToolbar from './TableToolbar'
import ModalEditSchedule from 'shared/Modals/Schedule/EditSchedule'

interface Props {
  deviceId: number
}

interface State {
  sortBy: string
  sortDirection: string
  search: string
  currentPage: number
  pageSize: PageSizes
}

const initState: State = {
  sortBy: 'id',
  sortDirection: 'desc',
  search: '',
  currentPage: 1,
  pageSize: 10
}

function DeviceSchedules({ deviceId }: Props) {
  const [viewScheduleId, setViewScheduleId] = useState<number>()
  const [editScheduleId, setEditScheduleId] = useState<number>()
  const [deleteScheduleId, setDeleteScheduleId] = useState<number>()

  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const updateAsync = useAsync({ showNotifOnError: true })
  const deleteAsync = useAsync({ showNotifOnError: true })
  const scheduleAsync = useAsync<AxiosResponse<ScheduleResponse>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    handleGetDeviceSchedules()
  }, [...Object.values(state)])

  const handleGetDeviceSchedules = () => {
    return scheduleAsync.execute(getSchedules(deviceId, state))
  }

  const handleActiveStatusChange = async (id: number, isActive: boolean) => {
    try {
      await updateAsync.execute(updateSchedule(id, { is_active: isActive }))
      handleGetDeviceSchedules()
    } catch (err) {
      console.error(err)
    }
  }

  const handleDeleteSchedule = async () => {
    if (!deleteScheduleId) return
    const id = deleteScheduleId
    setDeleteScheduleId(undefined)

    try {
      await deleteAsync.execute(deleteSchedule(id))
      toast.success(
        { title: 'Schedule deleted' },
        { position: 'bottom-center' }
      )
      if (scheduleAsync.data?.data.data.length === 1 && state.currentPage > 1) {
        return setState({ currentPage: state.currentPage - 1 })
      }
      handleGetDeviceSchedules()
    } catch (err) {
      console.error(err)
    }
  }

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const renderScheduleTimeCol = (schedule: Schedule) => {
    const { is_24_7, start_time, end_time, days } = schedule
    if (is_24_7) return 'All the time (24/7)'

    return (
      <Fragment>
        <div className="mb-px">
          {formatTime(start_time!)} - {formatTime(end_time!)}
        </div>
        <div>Every {days.map(shortDay).join(', ')}</div>
      </Fragment>
    )
  }

  const renderChannelCol = ({ channels }: Schedule) => (
    <div className="flex flex-wrap gap-3">
      {channels.map(c => {
        const { iconType, label } = channelToResource(c as NotifChannel)
        return (
          <div key={iconType} className="inline-flex items-center gap-1">
            <ResourceIcon
              type={iconType}
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            {label}
          </div>
        )
      })}
    </div>
  )

  const renderActiveStatusCol = ({ id, is_active }: Schedule) => (
    <Switch
      checked={!!is_active}
      className={cx('font-medium', is_active && 'text-primary')}
      onChange={checked => handleActiveStatusChange(id, checked)}
    >
      {is_active ? 'Active' : 'Inactive'}
    </Switch>
  )

  const renderActionCol = ({ id }: Schedule) => (
    <div className="flex justify-end items-center gap-2">
      <Button variant="ternary" onClick={() => setViewScheduleId(id)}>
        <EyeOpen /> View
      </Button>
      <Button variant="ternary" onClick={() => setEditScheduleId(id)}>
        <Pencil /> Edit
      </Button>
      <Button variant="ternary" onClick={() => setDeleteScheduleId(id)}>
        <Delete /> Delete
      </Button>
    </div>
  )

  const columns = [
    {
      key: 'name',
      title: 'schedule name',
      sorter: true,
      render: (row: Schedule) => row.name
    },
    {
      title: 'schedule time',
      render: renderScheduleTimeCol
    },
    {
      key: 'recipients_count',
      title: 'recipients',
      sorter: true,
      dataIndex: 'recipients_count'
    },
    {
      key: 'channel',
      title: 'channel',
      width: 500,
      sorter: true,
      render: renderChannelCol
    },
    {
      key: 'active_status',
      title: 'active status',
      sorter: true,
      render: renderActiveStatusCol
    },
    {
      width: 260,
      render: renderActionCol
    }
  ]

  const loading =
    scheduleAsync.isLoading || updateAsync.isLoading || deleteAsync.isLoading

  return (
    <div className="p-4">
      <TableToolbar
        deviceId={deviceId}
        loading={loading}
        search={state.search}
        onSearchChange={s => setState({ search: s, currentPage: 1 })}
        currentPage={state.currentPage}
        totalPage={scheduleAsync.data?.data.meta.last_page || 1}
        onPageChange={p => setState({ currentPage: p })}
        onRefetch={handleGetDeviceSchedules}
      />
      <Table
        rowKey="id"
        columns={columns}
        scroll={{ x: 1400 }}
        loading={loading}
        data={scheduleAsync.data?.data.data || []}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={loading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={scheduleAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
      <AnimatePresence initial={false}>
        {!!viewScheduleId && (
          <ModalScheduleDetail
            scheduleId={viewScheduleId}
            onEdit={() => {
              setEditScheduleId(viewScheduleId)
              setViewScheduleId(undefined)
            }}
            onDelete={() => {
              setDeleteScheduleId(viewScheduleId)
              setViewScheduleId(undefined)
            }}
            onClose={() => setViewScheduleId(undefined)}
          />
        )}
        {!!editScheduleId && (
          <ModalEditSchedule
            scheduleId={editScheduleId}
            onSuccess={handleGetDeviceSchedules}
            onClose={() => setEditScheduleId(undefined)}
          />
        )}
        {!!deleteScheduleId && (
          <ModalConfirmDelete
            modalTitle="Delete Tag"
            confirmText="Are you sure you want to delete this notification schedule?"
            warnText="This action cannot be undone"
            onConfirm={handleDeleteSchedule}
            onClose={() => setDeleteScheduleId(undefined)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default DeviceSchedules
