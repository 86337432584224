import shallow from 'zustand/shallow'

import { Button, Form, FormField, Input, Select } from 'components'
import { timezones } from 'utils/dateTime'
import { required } from 'utils/rules'
import { OnvifDevice } from 'services/bridges'
import useStore from 'store'

interface Props {
  onvifDevice: OnvifDevice
  loading: boolean
  handleConnect: (payload: {
    name: string
    timezone: string
    location_id: number
    ip_address?: string
    manufacturer?: string
  }) => void
}

type FormValues = {
  name: string
  timezone: { label: string; value: string }
  location: { label: string; value: number }
  manufacturer?: string
  ip_address?: string
}

function DeviceDetailForm({ onvifDevice, loading, handleConnect }: Props) {
  const locations = useStore(state => state.location.locations, shallow)

  const handleSubmit = async ({ timezone, location, ...v }: FormValues) => {
    if (loading) return
    handleConnect({
      ...v,
      timezone: timezone.value,
      location_id: location.value
    })
  }

  return (
    <div className="w-[33rem] mx-auto">
      <div className="text-base font-medium mb-6">Enter camera detail</div>
      <Form
        onFinish={handleSubmit}
        initialValues={{
          manufacturer: onvifDevice.meta.manufacturer,
          ip_address: onvifDevice.ip_address
        }}
      >
        <FormField
          name="name"
          label="Camera Name"
          rules={[required]}
          requiredMask
        >
          <Input size="large" placeholder="Name your camera" />
        </FormField>
        <FormField
          name="timezone"
          label="Camera Timezone"
          rules={[required]}
          requiredMask
        >
          <Select
            size="large"
            placeholder="Select camera timezone"
            options={timezones.map(tz => ({ label: tz, value: tz }))}
          />
        </FormField>
        <FormField name="location" label="Location">
          <Select
            size="large"
            placeholder="Select camera location"
            options={locations.map(l => ({ label: l.name, value: l.id }))}
          />
        </FormField>
        <FormField name="manufacturer" label="Manufacturer">
          <Input size="large" placeholder="Enter camera manufacturer" />
        </FormField>
        <FormField name="ip_address" label="IP Address">
          <Input size="large" placeholder="Enter cameera ip address" />
        </FormField>
        <div className="mt-8">
          <Button disabled={loading} block variant="primary" size="large">
            Save
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default DeviceDetailForm
