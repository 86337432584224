import { Dispatch, useState } from 'react'

import { Checkbox } from 'components'
import ChannelRecipientForm from 'shared/Forms/ChannelRecipientForm'

import { FormValues } from './FormHelpers'
import { NotifChannel } from 'services/tags'

interface Props {
  values: FormValues['recipients']
  setValues: Dispatch<Partial<FormValues>>
}

function Recipients({ values, setValues }: Props) {
  const [isBlocked, setIsBlocked] = useState(values.is_blocked || false)

  const handleBlockChange = (checked: boolean) => {
    setIsBlocked(checked)
    setValues({ recipients: { ...values, is_blocked: checked } })
  }

  const handleRecipientChange = (
    c: NotifChannel[],
    recs: Omit<FormValues['recipients'], 'is_blocked' | 'channels'>
  ) => {
    setValues({ recipients: { ...values, channels: c, ...recs } })
  }

  return (
    <div className="min-h-[calc(100vh_-_5.15rem)] py-4 flex flex-col justify-center">
      <div className="w-[55rem] m-auto">
        <div className="text-base font-medium mb-6">Notification channels</div>
        <div className="mb-4">
          <Checkbox
            size="large"
            checked={isBlocked}
            onChange={e => handleBlockChange(e.target.checked)}
          >
            Do <span className="text-danger">NOT</span> forward any notifications
            matching this tag
          </Checkbox>
        </div>
        {!isBlocked && (
          <ChannelRecipientForm
            values={values}
            onChange={handleRecipientChange}
          />
        )}
      </div>
    </div>
  )
}

export default Recipients
