export const attachmentUrl = 'https://dicyrhl19tt67.cloudfront.net'

export function downloadFile(url: string, filename?: string) {
  const a = document.createElement('a')
  a.href = url
  a.download = filename || url
  a.click()
}

export const isImgFile = (url: string) => {
  return !!url.match(/(.png|.jpg|.jpeg|.svg|.gif|.webp)$/i)
}

export const getAttachmentName = (url: string) => {
  const paths = url.split('/')
  const name = paths[paths.length - 1]
  return name
}

export function videoCapture(videoEl?: HTMLVideoElement) {
  if (!videoEl) {
    return
  }

  const w = 1920
  const h = 1080

  const canvas = document.createElement('canvas')
  canvas.width = w
  canvas.height = h
  const ctx = canvas.getContext('2d')!
  ctx.fillRect(0, 0, w, h)
  ctx.drawImage(videoEl, 0, 0, w, h)

  const imgUrl = canvas.toDataURL('image/jpg')
  downloadFile(imgUrl, 'screenshot.jpg')
}
