import { Fragment, useEffect } from 'react'
import { AxiosResponse } from 'axios'

import { Close, Delete, Pencil } from 'icons/outline'
import { useAsync } from 'hooks'
import { Modal, ModalProps, Tabs, Spinner, Button } from 'components'
import { getScheduleDetails, Schedule } from 'services/schedules'

import ScheduleDetails from './ScheduleDetails'
import Recipients from './Recipients'

interface Props extends ModalProps {
  onEdit: () => void
  onDelete: () => void
  scheduleId: number
}

function ModalScheduleDetail({
  onEdit,
  onDelete,
  scheduleId,
  ...props
}: Props) {
  const scheduleAsync = useAsync<AxiosResponse<{ data: Schedule }>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    scheduleAsync.execute(getScheduleDetails(scheduleId))
  }, [])

  const { isLoading, data } = scheduleAsync

  return (
    <Modal {...props} className="w-[43.75rem]">
      <div className="flex items-center justify-between gap-4 mb-6">
        <div className="text-base font-medium">
          Notification Schedule Details
        </div>
        <Close className="shrink-0 cursor-pointer" onClick={props.onClose} />
      </div>
      {isLoading && <Spinner className="h-40" />}
      {!isLoading && !!data && (
        <Fragment>
          <Tabs>
            <Tabs.TabPane key={1} tab="Schedule Details">
              <ScheduleDetails schedule={data.data.data} />
            </Tabs.TabPane>
            <Tabs.TabPane key={2} tab="Recipients">
              <Recipients schedule={data.data.data} />
            </Tabs.TabPane>
          </Tabs>
          <div className="border-b border-light-stroke my-4" />
          <div className="flex items-center justify-between gap-4">
            <Button className="w-20" onClick={onDelete}>
              <Delete /> Delete
            </Button>
            <div className="inline-flex items-center gap-1">
              <Button className="w-20" onClick={onEdit}>
                <Pencil /> Edit
              </Button>
              <Button
                variant="primary"
                className="w-[7.5rem]"
                onClick={props.onClose}
              >
                Close
              </Button>
            </div>
          </div>
        </Fragment>
      )}
    </Modal>
  )
}

export default ModalScheduleDetail
