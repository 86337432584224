import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import NoResult from 'assets/images/NoResult.png'

import { DeviceIcon } from 'icons/utils'
import { Plus, Refresh, Search } from 'icons/outline'
import { Alert, Button, Collapse, Input } from 'components'
import ModalCreateDevice from 'shared/Modals/Device/CreateDevice'
import { groupDevicesByLocation, isCamera } from 'utils/device'
import { Device } from 'services/devices'
import useStore, { Store } from 'store'

import DeviceMenuItem from './DeviceItem'

interface Props {
  expanded: boolean
  selectedDevices: (Device | null)[]
}

const mapState = ({ stat, device }: Store) => ({
  stats: stat.stats,
  devices: device.devices.filter(d => isCamera(d.type))
})

function DeviceMenuList({ expanded, selectedDevices }: Props) {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [search, setSearch] = useState('')

  const { devices, stats } = useStore(mapState, shallow)
  const { total = 0, used = 0 } = stats?.camera_licenses || {}
  const allOffline = devices.every(d => !d.is_licensed)
  const canSearch = !!devices.length && !!total && !allOffline

  const groupedDevices = groupDevicesByLocation(
    devices.filter(d => d.name.toLowerCase().includes(search.toLowerCase()))
  )
  const groupKeys = Object.keys(groupedDevices)

  const renderEmptySearchMessage = () => (
    <div className="text-center mt-8">
      <img src={NoResult} alt="empty" className="w-[4.5625rem] mx-auto" />
      <div className="text-sm font-medium mt-6">
        No result matches your search
      </div>
      <div className="mt-1.5 text-[0.8125rem] text-light-secondary">
        You can try other keywords, filters or
      </div>
      <Button className="mt-6" variant="primary" onClick={() => setSearch('')}>
        <Refresh /> Reset All Filters
      </Button>
    </div>
  )

  const renderNoLicenseMessage = (type: 'assign' | 'buy') => {
    const remaning = total - used
    return (
      <div className="mt-[6.25rem] text-center">
        <DeviceIcon
          type="Security Camera"
          className="mx-auto w-[3.5rem] h-[3.5rem] text-[#D1D1D1]"
        />
        <div className="text-light-secondary text-[0.8125rem] mt-3">
          You need to assign licenses to your cameras to stream them
        </div>
        {type === 'assign' && (
          <Alert
            type="success"
            className="!gap-1.5 mx-auto w-fit mt-2 font-medium"
          >
            {remaning} license{remaning > 1 ? 's' : ''} available
          </Alert>
        )}
        <Button
          // prettier-ignore
          onClick={() => navigate(type === 'buy' ? '/settings/billing/plans' : '/devices?tab=device-list')}
          variant="primary"
          className={cx('w-[10rem]', type === 'buy' ? 'mt-4' : 'mt-8')}
        >
          {type === 'buy' ? 'Get Camera License' : 'Assign License'}
        </Button>
      </div>
    )
  }

  const renderDevices = () => {
    return groupKeys.map((key, idx, self) => (
      <Collapse
        key={key}
        defaultActive
        panel={groupedDevices[key].locationName}
        className={cx(idx + 1 < self.length && 'border-b border-[#ededed]')}
        panelClassName="!px-0 !pb-1.5 font-medium"
        contentClassName="!px-0 !pb-3"
      >
        {groupedDevices[key].devices.map(device => (
          <DeviceMenuItem
            key={device.id}
            device={device}
            selectedDevices={selectedDevices}
          />
        ))}
      </Collapse>
    ))
  }

  return (
    <div
      className={cx(
        'relative w-[16.25rem] h-full border-r border-[#ededed] shrink-0',
        !expanded && 'hidden w-0'
      )}
    >
      <div className="p-3 w-full">
        <div className="text-sm font-medium mb-4">All Devices</div>
        <Button block className="mb-4" onClick={() => setVisible(true)}>
          <Plus /> Create New Device
        </Button>
        {canSearch && (
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            suffix={<Search />}
            placeholder="Search devices"
          />
        )}
      </div>
      <PerfectScrollbar
        className="p-3 pt-0 h-[calc(100%_-_11.6875rem)]"
        options={{ wheelPropagation: false }}
      >
        {!total
          ? renderNoLicenseMessage('buy')
          : allOffline && renderNoLicenseMessage('assign')}
        {!!search && !groupKeys.length && renderEmptySearchMessage()}
        {canSearch && renderDevices()}
      </PerfectScrollbar>
      <div className="absolute w-full left-0 bottom-0 p-3 border-t border-[#ededed]">
        <div className="text-[0.8125rem] text-light-secondary flex items-center gap-1">
          <div>Camera License:</div>
          <div className="font-medium">
            {used}/{total}
          </div>
          <Link
            to="/settings/billing/plans"
            className="ml-auto underline font-medium"
          >
            Upgrade
          </Link>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {visible && <ModalCreateDevice onClose={() => setVisible(false)} />}
      </AnimatePresence>
    </div>
  )
}

export default DeviceMenuList
