import cx from 'classnames'

import { Spinner } from 'icons/outline'

const sizes = {
  small: 'w-6 h-6',
  default: 'w-8 h-8',
  large: 'w-12 h-12'
}

export interface Props {
  className?: string
  size?: 'small' | 'default' | 'large' | number
  fullscreen?: boolean
}

export function BaseSpinner({ className, size = 'default' }: Props) {
  return (
    <div
      className={cx(
        'w-full h-full flex justify-center items-center text-primary',
        className
      )}
    >
      <Spinner
        className={cx('animate-spin', typeof size === 'string' && sizes[size])}
        style={{
          width: Number(size) || undefined,
          height: Number(size) || undefined,
          animationDuration: '0.7s'
        }}
      />
    </div>
  )
}
