import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Play(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="19"
        height="22"
        viewBox="0 0 19 22"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M18.5 10.134C19.1667 10.5189 19.1667 11.4811 18.5 11.866L2 21.3923C1.33333 21.7772 0.5 21.2961 0.5 20.5263V1.47372C0.5 0.703919 1.33333 0.222795 2 0.607695L18.5 10.134Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
