import Screenshot from 'assets/screenshots/VideoVault.png'
import { ChevronRight } from 'icons/outline'

function VideoVault() {
  return (
    <div className="p-4 grow">
      <div className="text-xl font-medium mb-4">Video Vault</div>
      <img src={Screenshot} alt="screenshot" className="w-full h-[25.625rem]" />
      <div className="mt-12">
        <div className="text-[0.8125rem] mb-3.5">
          Cloud recording uses a fixed capacity of storage per camera. The video
          is overwritten once the storage is full. There may be times where
          video must be retained indefinitely. One option is to simply download
          your video and store it somewhere safe.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          To provide a more convenient experience, we have provided all accounts
          with a 2GB Video Vault. Use the Video Vault as permanent storage for
          the clips that must be kept for the long term. To add a surveillance
          video clip to the Video Vault, begin by using Cloud Search to find the
          segment for the relevant camera. The system will prepare the clip and
          notify you when it is ready. Once ready, click to add it to your Video
          Vault. Now your video is securely stored for as long as the account
          retains active camera licenses.
        </div>
      </div>
      <div className="mt-12">
        <div className="font-medium text-base mb-4">Get Support</div>
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/cloud-recording/video-vault"
          target="_blank"
          rel="noreferrer"
        >
          How the Video Vault Works
          <ChevronRight />
        </a>
      </div>
    </div>
  )
}

export default VideoVault
