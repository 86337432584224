import { useEffect } from 'react'
import { AxiosResponse } from 'axios'

import { useAsync } from 'hooks'
import { Spinner } from 'components'
import ResourceSummaryAlt from 'shared/Cards/ResourceSummaryAlt'
import {
  Device,
  DeviceCloudStorage,
  getDeviceCloudStorage
} from 'services/devices'

import SmtpConfig from './SmtpConfig'
import CameraStats from './CameraStats'
import DeviceDetails from './DeviceDetails'
import { isCamera } from 'utils/device'

interface Props {
  device: Device
  toggleLicense: (enabled: boolean) => void
}

function DeviceDetailTab({ device, toggleLicense }: Props) {
  const storageAsync = useAsync<AxiosResponse<{ data: DeviceCloudStorage }>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    if (isCamera(device.type)) {
      storageAsync.execute(getDeviceCloudStorage(device.id))
    } else {
      storageAsync.setState({ status: 'idle' })
    }
  }, [])

  if (storageAsync.isLoading) {
    return <Spinner className="h-[calc(100vh_-_10rem_-_3.125rem)]" />
  }

  const storage = storageAsync.data?.data.data
  const { id, type } = device

  return (
    <div className="p-4">
      {!!storage && isCamera(type) && (
        <div className="flex flex-wrap gap-2 mb-4">
          <ResourceSummaryAlt
            type="cloud-storage"
            iconType="primary"
            title="Cloud Storage:"
            summary={`${storage.cloud_storage_gb.used}GB/${storage.cloud_storage_gb.total}GB`}
          />
          <ResourceSummaryAlt
            type="time"
            iconType="primary"
            title="Est. Retention Time:"
            summary={storage.estimated_retention_time || '---'}
          />
        </div>
      )}
      <div className="grid 2xl:grid-cols-3 gap-4">
        <SmtpConfig deviceId={id} />
        {isCamera(type) && (
          <CameraStats
            deviceId={id}
            assignLicense={() => toggleLicense(true)}
          />
        )}
        <DeviceDetails device={device} />
      </div>
    </div>
  )
}

export default DeviceDetailTab
