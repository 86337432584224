import { FC, ReactNode } from 'react'
import cx from 'classnames'

import { ResourceIcon } from 'icons/utils'
import type {
  Props as ResourceIconProps,
  ResourceIconType
} from 'icons/utils/ResourceIcon'

type Props = {
  title: ReactNode
  summary: ReactNode
  iconClassName?: string
} & ResourceIconProps<ResourceIconType>

const ResourceSummaryAlt: FC<Props> = ({
  type,
  title,
  summary,
  iconClassName,
  children,
  className,
  ...props
}) => {
  return (
    <div className={cx('bg-[#F5F6FF] px-4 py-3 rounded-lg text-sm', className)}>
      <div className="flex items-center gap-3">
        <ResourceIcon
          {...props}
          type={type}
          className={cx('w-5 h-5 shrink-0', iconClassName)}
        />
        <div className="w-full inline-flex items-center gap-1">
          <div className="font-normal">{title}</div>
          <div className="font-medium">{summary}</div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default ResourceSummaryAlt
