import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import EmptyBoxAlt from 'assets/images/EmptyBoxAlt.png'
import { ArrowRight, EyeOpen, Filter, More, Plus, Search } from 'icons/outline'
import { Share, ClockCircle, User, VideoCamera, Tag } from 'icons/filled'
import { Button, Dot, Input, Popover, Tag as CTag } from 'components'
import TableFooter from 'shared/TableFooter'
import MenuItem from 'shared/MenuItem'
import ModalConfirmDeleteWithIcon from 'shared/Modals/Confirm/ConfirmDeleteWithIcon'

function IotAutomation() {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)

  const renderMenu = (onClose: () => void) => {
    const onClick = (fn: () => void) => () => {
      fn()
      onClose()
    }
    return (
      <Popover.Content className="!p-2 !w-[13.75rem]" onClose={onClose}>
        <MenuItem>Turn OFF</MenuItem>
        <div className="my-1 border-b border-light-stroke" />
        <MenuItem
          className="text-danger"
          onClick={onClick(() => setVisible(true))}
        >
          Delete
        </MenuItem>
        <div className="my-1 border-b border-light-stroke" />
        <MenuItem className="flex-col">
          <div className="text-xs w-full text-light-secondary">
            To edit this Automation
          </div>
          <Link
            to={`/iot-automation/${1}`}
            className="text-primary font-medium underline w-full"
          >
            Go to Automation Detail
          </Link>
        </MenuItem>
      </Popover.Content>
    )
  }

  if ([].length > 0) {
    return (
      <div className="p-4 h-[calc(100vh_-_3.125rem)] bg-[#fafafa]">
        <div className="flex flex-col w-[23.75rem] mx-auto h-full justify-center items-center">
          <img
            src={EmptyBoxAlt}
            alt="empty"
            className="w-[7.5rem] h-[7.5rem]"
          />
          <div className="mt-6 mb-8 text-center">
            <div className="font-medium text-base mb-2">
              You have no Streams at the moment
            </div>
            <div className="text-light-secondary text-sm">
              You can decide what to do with the notifications from your devices
              by Streams
            </div>
          </div>
          <Button variant="primary" size="large">
            <Plus /> Create Automation
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="p-4 min-h-[calc(100vh_-_3.125rem)] bg-[#fafafa]">
      <div className="flex flex-wrap gap-4 justify-between items-center mb-6">
        <div className="text-2xl font-medium mt-2">IoT Automations</div>
        <div className="inline-flex flex-wrap gap-2">
          <Input
            className="w-[22.5rem]"
            placeholder="Search stream by name"
            suffix={<Search />}
          />
          <Popover content="Filter">
            <Button className="w-[5.5rem]">
              <Filter /> Filter
            </Button>
          </Popover>
          <Button variant="primary">
            <Plus /> Create Automation
          </Button>
        </div>
      </div>
      <div className="p-4 bg-white rounded-lg border border-[#eee] shadow-[0px_1px_2px_rgba(0,0,0,0.16)]">
        <div className="flex flex-wrap justify-between items-center gap-4 mb-3">
          <div className="inline-flex items-center gap-2">
            <Dot type="success" className="w-2.5 h-2.5" />
            <div className="font-medium text-[0.9375rem]">Face Detected</div>
          </div>
          <div className="inline-flex flex-wrap items-center gap-2">
            <Button
              variant="ternary"
              onClick={() => navigate(`/iot-automation/${1}`)}
            >
              <EyeOpen /> View Detail
            </Button>
            <Popover placement="left-start" content={renderMenu}>
              <Button variant="ternary">
                <More />
              </Button>
            </Popover>
          </div>
        </div>
        <div className="pl-4 flex flex-wrap items-center gap-4">
          <div className="bg-[#fafafa] py-3 px-4 rounded-lg border border-dashed border-light-stroke inline-flex flex-wrap items-center gap-2 text-[0.8125rem]">
            <div className="p-1.5 bg-white shadow-[inset_0px_-1px_0px_rgba(0,0,0,0.16)] rounded-lg">
              Any new notification
            </div>
            from specified
            <div className="p-1.5 bg-white shadow-[inset_0px_-1px_0px_rgba(0,0,0,0.16)] rounded-lg">
              devices
            </div>
            during specified
            <div className="p-1.5 bg-white shadow-[inset_0px_-1px_0px_rgba(0,0,0,0.16)] rounded-lg">
              durations
            </div>
          </div>
          <ArrowRight />
          <div className="bg-[#fafafa] py-3 px-4 rounded-lg border border-dashed border-light-stroke inline-flex flex-wrap items-center gap-2 text-[0.8125rem]">
            <Share className="shrink-0 w-4 h-4" type="success" />
            Forward this notification to the recipients
          </div>
        </div>
        <div className="my-4 border border-dashed border-[#eee]" />
        <div className="pl-4 flex flex-wrap items-center gap-4 text-[0.8125rem]">
          <div className="flex items-center gap-2">
            <div className="inline-flex items-center gap-1 text-light-secondary">
              <Tag className="w-4 h-4" color="#dedede" />
              Tags
            </div>
            <div className="inline-flex items-center gap-2">
              <CTag>Face Recognized</CTag>
              <CTag>Motion</CTag>
              <div className="border border-light-stroke px-2 py-[5px] rounded-[1.25rem] cursor-pointer">
                +3 more
              </div>
            </div>
          </div>
          <div className="text-[#eee]">|</div>
          <div className="flex items-center gap-2">
            <div className="inline-flex items-center gap-1 text-light-secondary">
              <ClockCircle className="w-4 h-4" color="#dedede" />
              Duration
            </div>
            <div className="font-medium underline text-light-secondary cursor-pointer">
              Hover to view
            </div>
          </div>
          <div className="text-[#eee]">|</div>
          <div className="flex items-center gap-2">
            <div className="inline-flex items-center gap-1 text-light-secondary">
              <User className="w-4 h-4" color="#dedede" />
              No. of recipient
            </div>
            <div className="">15</div>
          </div>
          <div className="text-[#eee]">|</div>
          <div className="flex items-center gap-2">
            <div className="inline-flex items-center gap-1 text-light-secondary">
              <VideoCamera className="w-4 h-4" color="#dedede" />
              No. of affected device
            </div>
            <div className="">All devices</div>
          </div>
        </div>
      </div>

      <TableFooter
        className="mt-6"
        loading={false}
        currentPage={1}
        onPageChange={console.log}
        onPageSizeChange={console.log}
        pageSize={10}
        totalItem={1}
      />
      <AnimatePresence initial={false}>
        {visible && (
          <ModalConfirmDeleteWithIcon
            confirmText="Are you sure you want to delete this Stream?"
            warnText="Action can't be undone"
            onConfirm={console.log}
            onClose={() => setVisible(false)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default IotAutomation
