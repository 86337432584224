import { Fragment, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { format } from 'date-fns'
import { AxiosResponse } from 'axios'

import { Bell } from 'icons/filled'
import { NotifDetailIcon } from 'icons/utils'
import { useAsync } from 'hooks'
import { Button, Popover, Spinner, Tag } from 'components'
import ModalViewInboundNotifDetail from 'shared/Modals/Notification/InboundNotifDetail'
import { dateTimeFormat } from 'utils/dateTime'
import { attachmentUrl, getAttachmentName, isImgFile } from 'utils/file'
import { Tag as Itag } from 'services/tags'
import { getInboundNotifDetails, InboundNotif } from 'services/notifications'
import { PlaybackNotif as IPlaybackNotif } from 'services/recordingPlayback'

interface Props {
  left: number
  notif: IPlaybackNotif
}

function PlaybackNotif({ left, notif }: Props) {
  const [visible, setVisible] = useState(false)
  const notifAsync = useAsync<AxiosResponse<{ data: InboundNotif }>>({
    showNotifOnError: true
  })

  const { execute, isLoading, data } = notifAsync

  const handleGetDeviceDetails = async (visible: boolean) => {
    if (visible && !isLoading && !data?.data.data) {
      execute(getInboundNotifDetails(notif.id))
    }
  }

  const renderImages = (imgs: string[]) => {
    return (imgs || [])
      .filter(a => isImgFile(a))
      .slice(0, 2)
      .map(url => (
        <img
          key={url}
          alt={url}
          className="w-full max-w-[8.75rem] max-h-[5rem] rounded-lg object-cover"
          src={url}
        />
      ))
  }

  const renderTimestamp = (date: string) => {
    return format(
      new Date(date),
      `${dateTimeFormat['MMM dd, yyyy']} ${dateTimeFormat['hh:mm a']}`
    )
  }

  const renderTags = (tags?: Itag[]) => {
    if (!!tags && !!tags.length) {
      return tags.map(tag => (
        <Tag
          key={tag.id}
          strokeColor={tag.stroke_color}
          textColor={tag.text_color}
          bgColor={tag.color}
        >
          {tag.name}
        </Tag>
      ))
    }
    return '---'
  }

  const renderAttachments = (attachments: string[] | null) => {
    if (!!attachments && !!attachments.length) {
      return attachments.slice(0, 2).map(a => (
        <a
          key={a}
          href={`${attachmentUrl}/${a}`}
          target="_blank"
          rel="noreferrer"
          className="bg-[#efefef] py-1.5 px-2 rounded-lg inline-flex items-center gap-1"
        >
          <NotifDetailIcon
            type="attachment"
            color="#808080"
            className="w-3 h-3"
          />
          <div className="leading-none text-[0.6875rem]">
            {getAttachmentName(a)}
          </div>
        </a>
      ))
    }
    return '---'
  }

  const renderPreviewPopover = (onClose: () => void) => (
    <Popover.Content onClose={onClose} className="w-[31.25rem]">
      <div className="flex items-center gap-1.5 mb-2">
        <Bell type="primary" className="w-4 h-4 shrink-0" />
        <div className="uppercase font-semibold text-[0.8125rem]">
          Notification Detail
        </div>
      </div>
      {isLoading && <Spinner size="small" className="h-[10rem]" />}
      {!isLoading && !!data?.data.data && (
        <Fragment>
          {renderNotifDetails(data?.data.data)}
          <div className="mt-4 flex justify-end items-center gap-2">
            <Button onClick={onClose} className="w-20">
              Close
            </Button>
            <Button
              variant="primary"
              className="w-[8.125rem]"
              onClick={() => {
                setVisible(true)
                onClose()
              }}
            >
              View Full Detail
            </Button>
          </div>
        </Fragment>
      )}
    </Popover.Content>
  )

  const renderNotifDetails = (data: InboundNotif) => (
    <div className="p-4 bg-[#fafafa]">
      {!!data.attachments?.find(a => isImgFile(a)) && (
        <div className="flex gap-4 mb-5">
          <div className="w-[26%] inline-flex items-center self-start gap-2 shrink-0">
            <NotifDetailIcon
              type="image"
              className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
            />
            <div className="font-medium text-[0.8125rem]">Images</div>
          </div>
          <div className="w-full inline-flex flex-wrap gap-2">
            {renderImages(data.attachments)}
          </div>
        </div>
      )}
      <div className="flex gap-4 mb-5">
        <div className="w-[26%] inline-flex items-center self-start gap-2 shrink-0">
          <NotifDetailIcon
            type="text"
            className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
          />
          <div className="font-medium text-[0.8125rem]">Title</div>
        </div>
        <div className="text-[0.8125rem]">{data.subject}</div>
      </div>
      <div className="flex gap-4 mb-5">
        <div className="w-[26%] inline-flex items-center self-start gap-2 shrink-0">
          <NotifDetailIcon
            type="text"
            className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
          />
          <div className="font-medium text-[0.8125rem]">Content</div>
        </div>
        <div
          className="text-[0.8125rem]"
          dangerouslySetInnerHTML={{ __html: data.message }}
        />
      </div>
      <div className="flex gap-4 mb-5">
        <div className="w-[26%] inline-flex items-center self-start gap-2 shrink-0">
          <NotifDetailIcon
            type="time"
            iconColor="#BABBBF"
            className="w-3.5 h-3.5 shrink-0"
          />
          <div className="font-medium text-[0.8125rem]">Timestamp</div>
        </div>
        <div className="text-[0.8125rem]">
          {renderTimestamp(data.created_at)}
        </div>
      </div>
      <div className="flex gap-4 mb-5">
        <div className="w-[26%] inline-flex items-center self-start gap-2 shrink-0">
          <NotifDetailIcon
            type="camera"
            className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
          />
          <div className="font-medium text-[0.8125rem]">Device Name</div>
        </div>
        <div className="text-[0.8125rem]">{data.device?.name || '---'}</div>
      </div>
      <div className="flex gap-4 mb-5">
        <div className="w-[26%] inline-flex items-center self-start gap-2 shrink-0">
          <NotifDetailIcon
            type="location"
            className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
          />
          <div className="font-medium text-[0.8125rem]">Location</div>
        </div>
        <div className="text-[0.8125rem]">
          {data.device?.location?.name || '---'}
        </div>
      </div>
      <div className="flex gap-4 mb-5">
        <div className="w-[26%] inline-flex items-center self-start gap-2 shrink-0">
          <NotifDetailIcon
            type="tag"
            className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
          />
          <div className="font-medium text-[0.8125rem]">Tags</div>
        </div>
        <div className="inline-flex items-center flex-wrap gap-1">
          {renderTags(data.tags)}
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-[26%] inline-flex items-center self-start gap-2 shrink-0">
          <NotifDetailIcon
            type="attachment"
            className="w-3.5 h-3.5 text-[#BABBBF] shrink-0"
          />
          <div className="font-medium text-[0.8125rem]">Attachments</div>
        </div>
        <div className="w-full inline-flex items-center flex-wrap gap-1">
          {renderAttachments(data.attachments)}
        </div>
      </div>
    </div>
  )

  return (
    <Fragment>
      <Popover
        style={{ left }}
        placement="top"
        onVisibleChange={handleGetDeviceDetails}
        content={onClose => renderPreviewPopover(onClose)}
        className="absolute top-0 z-[3] w-[.3125rem] h-[calc(100%_-_1px)] rounded-[1.75rem] cursor-pointer flex flex-col"
      >
        {notif.tags.map(tag => (
          <span
            key={tag.id}
            style={{ background: tag.color }}
            className="w-full h-full first:rounded-t-[1.25rem] last:rounded-b-[1.25rem]"
          />
        ))}
      </Popover>
      <AnimatePresence initial={false}>
        {visible && !!data?.data.data && (
          <ModalViewInboundNotifDetail
            notif={data.data.data}
            onClose={() => setVisible(false)}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default PlaybackNotif
