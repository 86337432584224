import { Fragment, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { Pencil } from 'icons/outline'
import { Button } from 'components'
import ModalEditDevice from 'shared/Modals/Device/EditDevice'
import { isCamera } from 'utils/device'
import { Device } from 'services/devices'

interface Props {
  device: Device
}

function DeviceDetails({ device }: Props) {
  const [showEdit, setShowEdit] = useState(false)
  const [showStreamUrl, setShowStreamUrl] = useState(false)

  const isCamDevice = isCamera(device.type)

  return (
    <Fragment>
      <div className="bg-[#f6f6f6] rounded-lg p-4">
        <div className="mb-4 flex justify-between items-center gap-4">
          <div className="text-base font-medium">Device Details</div>
          <Button
            variant="secondary-lighten"
            className="w-[4.7rem]"
            onClick={() => setShowEdit(true)}
          >
            <Pencil className="scale-90" /> Edit
          </Button>
        </div>
        <div className="flex items-center gap-2 mb-4">
          <div className="w-32 font-light shrink-0">Name</div>
          <div className="grow font-medium break-all">{device.name}</div>
        </div>
        <div className="flex items-center gap-2 mb-4">
          <div className="w-32 font-light shrink-0">Type</div>
          <div className="grow font-medium break-all">{device.type}</div>
        </div>
        {isCamDevice && (
          <div className="flex items-center gap-2 mb-4">
            <div className="w-32 font-light shrink-0">RTSP Link</div>
            {!device.stream_url && <div className="grow font-medium">---</div>}
            {device.stream_url && (
              <div className="grow font-medium break-all inline-flex gap-2">
                {showStreamUrl ? device.stream_url : '*****'}
                <div
                  onClick={() => setShowStreamUrl(prev => !prev)}
                  className="cursor-pointer shrink-0 text-primary"
                >
                  {showStreamUrl ? 'Hide' : 'Show'}
                </div>
              </div>
            )}
          </div>
        )}
        <div className="flex items-center gap-2 mb-4">
          <div className="w-32 font-light shrink-0">Timezone</div>
          <div className="grow font-medium break-all">{device.timezone}</div>
        </div>
        {isCamDevice && (
          <Fragment>
            <div className="flex items-center gap-2 mb-4">
              <div className="w-32 font-light shrink-0">Manufacturer</div>
              <div className="grow font-medium break-all">
                {device.manufacturer || '---'}
              </div>
            </div>
            <div className="flex items-center gap-2 mb-4">
              <div className="w-32 font-light shrink-0">IP Address</div>
              <div className="grow font-medium break-all">
                {device.ip_address || '---'}
              </div>
            </div>
          </Fragment>
        )}
        <div className="flex items-center gap-2 mb-4">
          <div className="w-32 font-light shrink-0">Location</div>
          <div className="grow font-medium break-all">
            {device.location?.name || '---'}
          </div>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {showEdit && (
          <ModalEditDevice device={device} onClose={() => setShowEdit(false)} />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default DeviceDetails
