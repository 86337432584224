import { SetState } from 'zustand'

import { Location } from 'services/locations'
import type { Store } from './'

export interface LocationSlice {
  locations: Location[]
  setLocations: (payload: Location[]) => void
  addLocation: (payload: Location) => void
  updateLocation: (payload: Location) => void
  removeLocation: (payload: number) => void
}

const slice = (set: SetState<Store>) => ({
  locations: [],
  setLocations: (payload: Location[]) => {
    return set(({ location }) => ({
      location: { ...location, locations: payload }
    }))
  },
  addLocation: (payload: Location) => {
    const newLocation = {
      ...payload,
      devices_count: 0,
      location_floor_plans: []
    }
    return set(({ location }) => ({
      location: { ...location, locations: [newLocation, ...location.locations] }
    }))
  },
  updateLocation: (payload: Location) => {
    return set(({ location }) => ({
      location: {
        ...location,
        locations: location.locations.map(l =>
          l.id === payload.id ? { ...l, ...payload } : l
        )
      }
    }))
  },
  removeLocation: (payload: number) => {
    return set(({ location }) => ({
      location: {
        ...location,
        selectedLocation: undefined,
        showLocationDetail: false,
        locations: location.locations.filter(l => l.id !== payload)
      }
    }))
  }
})

export default slice
