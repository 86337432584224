import { FC, ReactNode } from 'react'
import cx from 'classnames'

import { ResourceIcon } from 'icons/utils'
import type {
  Props as ResourceIconProps,
  ResourceIconType
} from 'icons/utils/ResourceIcon'

type Props = {
  title: ReactNode
  summary: ReactNode
  action?: ReactNode
} & ResourceIconProps<ResourceIconType>

const ResourceSummary: FC<Props> = ({
  type,
  title,
  action,
  summary,
  children,
  className,
  ...props
}) => {
  return (
    <div className={cx('bg-white p-4 rounded-lg', className)}>
      <div className="flex items-center gap-4">
        <div className="w-6 h-6 shrink-0">
          <ResourceIcon
            {...props}
            type={type}
            className="w-full h-full shrink-0"
          />
        </div>
        <div className="w-full grow">
          <div className="w-full text-[0.8125rem] text-light-secondary mb-[4px] flex justify-between items-center">
            <span>{title}</span>
            {action}
          </div>
          <div className="text-base font-medium">{summary}</div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default ResourceSummary
