import { FC, Children, ReactElement, useState, cloneElement } from 'react'
import cx from 'classnames'

import { SelectButtonItem, Props as ButtonItemProps } from './SelectButtonItem'

type Props = {
  name?: string
  value?: string
  onChange?: (value: string) => void
} & Omit<JSX.IntrinsicElements['div'], 'onChange'>

const SelectButton: FC<Props> & { Item: FC<ButtonItemProps> } = ({
  name,
  value: propValue,
  onChange,
  children,
  ...props
}) => {
  const [value, setValue] = useState(propValue || '')

  const handleChildClick = (v: string) => {
    setValue(v)
    onChange?.(v)
  }

  return (
    <div
      {...props}
      className={cx(
        'flex flex-wrap items-center gap-1.5 relative border-0 border-transparent',
        props.className
      )}
    >
      {Children.map(children, child => {
        const childComp = child as ReactElement
        return cloneElement(childComp, {
          onClick: () => handleChildClick(childComp.props.value),
          active: childComp.props.value === value
        })
      })}
    </div>
  )
}

SelectButton.Item = SelectButtonItem

export { SelectButton }
