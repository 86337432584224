import { useEffect, useState } from 'react'

import { DeviceIconType } from 'icons/utils/DeviceIcon'

import SideList from './SideList'
import CameraList from './CameraList'
import SensorList from './SensorList'
import OtherList from './OtherList'
import { useLocationCtx } from '../LocationContext'

function DeviceList({ activeKey }: { activeKey: string }) {
  const [type, setType] = useState<DeviceIconType>('Security Camera')
  const { dispatch } = useLocationCtx()

  useEffect(() => {
    if (activeKey === 'device-list') {
      dispatch({
        selectedLocation: undefined,
        showLocationDetail: false,
        activePinId: undefined
      })
    }
  }, [activeKey])

  return (
    <div className="flex h-[calc(100vh_-_6.75rem_-_3.125rem)]">
      <SideList selectedType={type} onTypeChange={setType} />
      {type === 'Security Camera' && <CameraList />}
      {type === 'Temperature Sensor' && <SensorList />}
      {type === 'Other' && <OtherList />}
    </div>
  )
}

export default DeviceList
