import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { Checkbox } from 'components'

interface Props {
  children?: ReactNode
  active: boolean
  onChange: (active: boolean) => void
  disabled?: boolean
  className?: string
  showUpgrade?: boolean
}

const ExtraFeatureSelectOption: FC<Props> = ({
  active,
  onChange,
  className,
  disabled,
  showUpgrade,
  children
}) => {
  return (
    <div className={cx(className)}>
      <div
        onClick={disabled ? undefined : () => onChange(!active)}
        className={cx(
          'base-transition border border-light-stroke py-2.5 px-2 rounded-lg cursor-pointer',
          active && 'bg-primary-lighten',
          disabled && 'pointer-events-none bg-[#ececec]'
        )}
      >
        <div
          className={cx('flex items-center gap-1', disabled && 'opacity-50')}
        >
          <Checkbox
            size="large"
            checked={active}
            className="pointer-events-none"
          />
          <div className="inline-flex gap-1.5 items-center">{children}</div>
        </div>
      </div>
      {disabled && showUpgrade !== false && (
        <Link
          to="/settings/billing/plans"
          className="inline-block text-primary mt-1 text-sm font-medium underline"
        >
          Upgrade to activate
        </Link>
      )}
    </div>
  )
}

export default ExtraFeatureSelectOption
