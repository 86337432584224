import { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { AnimatePresence } from 'framer-motion'
import { pick } from 'lodash'

import { ChevronDown, ChevronLeft, Close } from 'icons/outline'
import { useAsync } from 'hooks'
import { Modal, ModalProps, Popover } from 'components'
import ModalGuideBook from 'shared/Modals/GuideBook'
import { days } from 'utils/dateTime'
import toast from 'utils/toast'
import { createSchedule } from 'services/schedules'

import { FormKey, FormValues, initFormValues, stepLabels } from './FormHelpers'
import ScheduleDetails from './ScheduleDetails'
import Recipients from './Recipients'
import PopoverHelpMenu from 'shared/Popovers/HelpMenu'

interface Props extends ModalProps {
  deviceId: number
  onSuccess?: () => void
}

function ModalCreateSchedule({ deviceId, onSuccess, ...props }: Props) {
  const { show } = useIntercom()

  const [showGuideBook, setShowGuideBook] = useState(false)
  const [formValues, setFormValues] = useState(initFormValues)
  const [currentStep, setCurrentStep] = useState(0)

  const { execute, isLoading } = useAsync({
    showNotifOnError: true
  })

  const handleClose = () => {
    !false && props.onClose()
  }

  const handleStepChange = (step: number) => {
    setCurrentStep(prev => prev + step)
  }

  const handleGoBack = () => {
    currentStep === 0 ? handleClose() : handleStepChange(-1)
  }

  const handleSetValues = (key: FormKey) => (values: FormValues[FormKey]) => {
    setFormValues(prev => ({
      ...prev,
      [key]: values
    }))
  }

  const handleCreateSchedule = async (recs: FormValues['recipients']) => {
    handleSetValues('recipients')(recs)
    try {
      const { is_24_7, start_time, end_time, ...s } = formValues.scheduleDetails
      const selectedDays = (is_24_7 ? days : s.days).map(d => d.toUpperCase())
      await execute(
        createSchedule({
          is_active: true,
          device_id: deviceId,
          name: s.name,
          is_24_7,
          days: selectedDays,
          start_time: is_24_7 ? undefined : start_time,
          end_time: is_24_7 ? undefined : end_time,
          email_recipient_ids: recs.email_recipients.map(r => r.value),
          phone_recipient_ids: recs.phone_recipients.map(r => r.value),
          whatsapp_recipient_ids: recs.whatsapp_recipients.map(r => r.value),
          ...pick(recs, [
            'channels',
            'webhook',
            'slack_webhook',
            'teams_webbhook',
            'pagerduty_integration_key'
          ])
        })
      )
      toast.success(
        { title: 'Schedule created' },
        { position: 'bottom-center' }
      )
      onSuccess?.()
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  const renderPopoverHelpMenu = (onClose: () => void) => (
    <PopoverHelpMenu
      onContactSupport={() => show()}
      onOpenGuideBook={() => setShowGuideBook(true)}
      onClose={onClose}
    />
  )

  return (
    <Modal
      {...props}
      wrapperClassName="!p-0"
      className="w-[100vw] min-h-[100vh] rounded-none !p-0"
      onClose={handleClose}
    >
      <div className="flex items-center gap-4 justify-between px-[5.3125rem] py-2 border-b border-light-stroke">
        <div className="inline-flex items-center gap-3">
          <ChevronLeft
            className="shrink-0 cursor-pointer"
            onClick={handleGoBack}
          />
          <div>
            <div className="text-base font-medium">Create Schedule</div>
            <div className="text-xs text-light-secondary mt-[2px]">
              Step {currentStep + 1}: {stepLabels[currentStep]}
            </div>
          </div>
        </div>
        <div className="text-[0.8125rem] inline-flex items-center gap-4">
          <Popover
            placement="bottom-end"
            content={onClose => renderPopoverHelpMenu(onClose)}
          >
            <div className="text-primary inline-flex items-center gap-1 mr-4 text-[0.8125rem] font-medium cursor-pointer">
              Help <ChevronDown />
            </div>
          </Popover>
          <Close className="shrink-0 cursor-pointer" onClick={handleClose} />
        </div>
      </div>
      {currentStep === 0 && (
        <ScheduleDetails
          values={formValues.scheduleDetails}
          onSubmit={handleSetValues('scheduleDetails')}
          onStepChange={handleStepChange}
        />
      )}
      {currentStep === 1 && (
        <Recipients
          loading={isLoading}
          values={formValues.recipients}
          onSubmit={handleCreateSchedule}
        />
      )}
      <AnimatePresence initial={false}>
        {showGuideBook && (
          <ModalGuideBook onClose={() => setShowGuideBook(false)} />
        )}
      </AnimatePresence>
    </Modal>
  )
}

export default ModalCreateSchedule
