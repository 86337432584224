import { FC, Children } from 'react'
import cx from 'classnames'

const CreditCard: FC<JSX.IntrinsicElements['div']> = ({
  children,
  ...props
}) => {
  const [top, bottom] = Children.toArray(children)

  return (
    <div
      {...props}
      className={cx(
        'group base-transition cursor-pointer hover:bg-[#f5f6ff]',
        'min-w-[7.5rem] bg-[#f7f7f7] border-2 border-[#f7f7f7] rounded-lg',
        props.className
      )}
    >
      <div className="p-2 text-[0.8125rem]">{top}</div>
      <div className="base-transition h-3 bg-[#efefef] group-hover:bg-[#a8abe9]" />
      <div className="p-2 text-[0.8125rem]">{bottom}</div>
    </div>
  )
}

export default CreditCard
