import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AxiosResponse } from 'axios'
import { formatDistanceToNow } from 'date-fns'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'

import EmptyBox from 'assets/images/EmptyBox.png'

import { useAsync } from 'hooks'
import { ChevronLeft, EyeOpen, More, TextFile } from 'icons/outline'
import { Button, Drawer, Popover, Spinner, Tag } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalViewInboundNotifDetail from 'shared/Modals/Notification/InboundNotifDetail'
import ModalAddNotifNote from 'shared/Modals/Notification/AddNotifNote'
import { Tag as ITag } from 'services/tags'
import { getLastestInboundNotifs, InboundNotif } from 'services/notifications'

function LatestNotifs() {
  const [visible, setVisible] = useState(false)
  const [showMore, setShowMore] = useState<number | null>(null)
  const [viewNotif, setViewNotif] = useState<InboundNotif | null>(null)
  const [addNote, setAddNote] = useState<number | null>(null)

  const inboundNotifs = useAsync<AxiosResponse<{ data: InboundNotif[] }>>({
    status: 'pending'
  })

  useEffect(() => {
    inboundNotifs.execute(getLastestInboundNotifs())
  }, [])

  const renderMoreOptions = (onClose: () => void, notif: InboundNotif) => {
    const handleClick = (fn: Function) => () => {
      fn()
      onClose()
    }
    return (
      <Popover.Content onClose={onClose}>
        <MenuItem onClick={handleClick(() => setViewNotif(notif))}>
          <EyeOpen
            className="w-3 h-3 text-light-secondary"
            style={{ transform: 'scale(1.2)' }}
          />
          <span className="text-[0.8125rem]">Preview Notification</span>
        </MenuItem>
        <MenuItem onClick={handleClick(() => setAddNote(notif.id))}>
          <TextFile className="w-3 h-3 text-light-secondary" />
          <span className="text-[0.8125rem]">Add Note</span>
        </MenuItem>
      </Popover.Content>
    )
  }

  const renderDeviceNotifs = (notifs: InboundNotif[]) => {
    return notifs.map(notif => (
      <div key={notif.id} className="group bg-light-bg p-3 rounded-lg">
        <div className="text-xs text-light-secondary normal-case mb-[3px] flex justify-between items-center">
          <span>
            {formatDistanceToNow(new Date(notif.created_at), {
              addSuffix: true
            })}
          </span>
          <Popover
            placement="left-start"
            onVisibleChange={visible => setShowMore(visible ? notif.id : null)}
            content={c => renderMoreOptions(c, notif)}
          >
            <Button
              size="small"
              innerClassName="p-1"
              className={cx(
                'base-transition w-6 opacity-0 group-hover:opacity-100',
                showMore === notif.id && '!opacity-100'
              )}
            >
              <More className="cursor-pointer" />
            </Button>
          </Popover>
        </div>
        <div className="flex items-center gap-2">
          {!!notif.tags && !!notif.tags.length && (
            <div className="w-8 h-8 shrink-0">
              {renderNotifTags(notif.tags)}
            </div>
          )}
          <div className="text-[0.8125rem]">{notif.subject}</div>
        </div>
        {notif.device && (
          <div className="text-xs mt-3 text-light-secondary">
            {notif.device.type} &gt; {notif.device.name}
          </div>
        )}
      </div>
    ))
  }

  const renderNotifTags = (tags: ITag[]) => {
    const length = tags.length
    return tags.map((tag, idx) => (
      <Tag
        key={tag.id}
        bgColor={tag.color}
        textColor={tag.text_color}
        strokeColor={tag.stroke_color}
        className="w-full !block"
        innerClassName="!p-0"
        style={{ height: `calc(100% / ${length})` }}
        rounded={
          length === 1
            ? 'rounded-lg'
            : idx === 0
            ? 'rounded-t-lg'
            : idx + 1 === length
            ? 'rounded-b-lg'
            : 'rounded-none'
        }
      />
    ))
  }

  const { isLoading, data } = inboundNotifs

  return (
    <Fragment>
      <div
        onClick={() => setVisible(true)}
        className="fixed top-[3.5rem] right-0 bg-white border border-light-stroke py-2.5 pl-[4px] pr-[2px] border-r-0 rounded-l-lg cursor-pointer"
      >
        <ChevronLeft className="w-2.5 h-2.5" />
      </div>
      <AnimatePresence initial={false}>
        {visible && (
          <Drawer
            key="drawer"
            width={350}
            placement="right"
            onClose={() => setVisible(false)}
          >
            <div className="h-screen overflow-hidden">
              <div className="p-4 flex items-center justify-between">
                <div className="text-[0.9375rem] font-medium">
                  Latest Device Notifications
                </div>
                <Link
                  to="/notifications?tab=all-device-notification"
                  className="text-[0.8125rem] text-primary cursor-pointer font-medium"
                >
                  View All
                </Link>
              </div>
              {isLoading && <Spinner className="h-[90vh]" />}
              {!isLoading && !data?.data.data.length && (
                <div className="text-center flex flex-col items-center px-6 mt-48">
                  <img className="w-[4.0625rem]" src={EmptyBox} alt="Empty" />
                  <div className="text-light-secondary text-[0.8125rem] mt-4">
                    There is no device notification at the moment
                  </div>
                </div>
              )}
              {!isLoading && !!data?.data.data.length && (
                <PerfectScrollbar
                  style={{ height: 'calc(100% - 4rem)' }}
                  options={{ wheelPropagation: false }}
                  className="flex flex-col gap-2 overflow-x-hidden px-4 mb-4"
                >
                  {renderDeviceNotifs(data.data.data)}
                </PerfectScrollbar>
              )}
            </div>
          </Drawer>
        )}
        {!!viewNotif && (
          <ModalViewInboundNotifDetail
            key="view-notif"
            notif={viewNotif}
            onClose={() => setViewNotif(null)}
          />
        )}
        {!!addNote && (
          <ModalAddNotifNote
            key="add-note"
            notifId={addNote}
            onClose={() => setAddNote(null)}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default LatestNotifs
