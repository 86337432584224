import { useState } from 'react'
import { Link } from 'react-router-dom'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import { Checked, LocationPin, Search } from 'icons/outline'
import { Input, Popover } from 'components'
import MenuItem from 'shared/MenuItem'
import { Location } from 'services/locations'
import useStore from 'store'

import { useLocationCtx } from '../LocationContext'

interface Props {
  onClose: () => void
}

function LocationList({ onClose }: Props) {
  const [search, setSearch] = useState('')
  const { selectedLocation, floorPlans, dispatch } = useLocationCtx()

  const locations = useStore(
    state =>
      state.location.locations.filter(l =>
        l.name.toLowerCase().includes(search.toLowerCase())
      ),
    shallow
  )

  const handleSelectLocation = (location: Location) => {
    dispatch({
      selectedLocation: location,
      activeFloorPlanId: floorPlans.find(fp => fp.location_id === location.id)
        ?.id
    })
    onClose()
  }

  return (
    <Popover.Content className="!p-4 w-[20rem]" onClose={onClose}>
      <div className="flex items-center justify-between font-medium">
        <div className="text-[0.9375rem]">Change Location</div>
        <Link
          to="/devices?tab=device-location"
          className="text-[0.8125rem] text-primary"
          onClick={onClose}
        >
          View All Location
        </Link>
      </div>
      <div className="my-4">
        <Input
          suffix={<Search />}
          value={search}
          placeholder="Search location"
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      {!locations.length && (
        <div className="text-[0.8125rem] text-light-secondary">
          No result match your search. Please try other keyword
        </div>
      )}
      {!!locations.length &&
        locations.map(l => {
          const active = selectedLocation?.id === l.id
          const fpCount = floorPlans.filter(fp => +fp.location_id === l.id)
          return (
            <MenuItem
              key={l.id}
              onClick={() => handleSelectLocation(l)}
              className="!rounded-lg border-b border-dashed border-light-stroke last:border-none h-10"
            >
              <div className="inline-flex items-center gap-1.5">
                <LocationPin className={cx(active && 'text-primary')} />
                <div
                  className={cx(
                    'text-[0.8125rem]',
                    active && 'text-primary font-medium'
                  )}
                >
                  {l.name}
                </div>
              </div>
              <div className="ml-auto inline-flex items-center gap-2">
                {active && <Checked className="text-primary w-4 h-4" />}
                <div
                  className={cx(
                    'text-xs py-[3px] px-1.5 border rounded-full',
                    active
                      ? 'light-primary-stroke bg-primary-lighten'
                      : 'border-light-stroke bg-white'
                  )}
                >
                  {fpCount.length}
                </div>
              </div>
            </MenuItem>
          )
        })}
    </Popover.Content>
  )
}

export default LocationList
