import { format } from 'date-fns'

import CancelPlanImg from 'assets/images/CancelPlan.png'
import { Close } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Modal, ModalProps } from 'components'
import { dateTimeFormat } from 'utils/dateTime'
import { cancelSubscription } from 'services/subscriptions'

interface Props extends ModalProps {
  billingEndDate: string
}

function ModalCancelPlan({ billingEndDate, ...props }: Props) {
  const { execute, isLoading } = useAsync({ showNotifOnError: true })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleCancelPlan = () => {
    execute(cancelSubscription()).then(() => {
      localStorage.setItem('APP/CANCEL_PLAN', 'true')
      window.location.reload()
    })
  }

  return (
    <Modal {...props} className="w-[19.375rem]" onClose={handleClose}>
      <div className="flex justify-end mb-3">
        <Close className="cursor-pointer" onClick={handleClose} />
      </div>
      <div className="w-[6.25rem] mx-auto mb-6">
        <img className="w-full" src={CancelPlanImg} alt="cancel-plan" />
      </div>
      <div className="text-center">
        <div className="text-base font-medium mb-2">
          Are you sure you want to cancel your subscription?
        </div>
        <div className="text-[0.8125rem]">
          You will be downgraded to a Free Plan and lose all additional device
          data/video on{' '}
          <span className="font-medium">
            {format(
              new Date(billingEndDate),
              `${dateTimeFormat['MMM dd, yyyy']} ${dateTimeFormat['hh:mm a']}`
            )}
          </span>
        </div>
      </div>
      <div className="mt-8">
        <Button
          variant="primary"
          size="large"
          block
          className="mb-3"
          onClick={handleClose}
          disabled={isLoading}
        >
          No
        </Button>
        <Button
          block
          size="large"
          disabled={isLoading}
          onClick={handleCancelPlan}
        >
          Cancel Plan
        </Button>
      </div>
    </Modal>
  )
}

export default ModalCancelPlan
