import { useState } from 'react'
import shallow from 'zustand/shallow'

import { ChevronRight } from 'icons/outline'
import { Button, Checkbox, Select } from 'components'
import useStore from 'store'

import { FormValues, OptionValue } from './FormHelpers'

interface Props {
  values: FormValues['affectedDevices']
  onSubmit: (values: FormValues['affectedDevices']) => void
  onStepChange: (step: number) => void
}

function AffectedDevices({ values, onSubmit, onStepChange }: Props) {
  const [applyAll, setApplyAll] = useState(values.apply_to_all || false)
  const [selectedDevices, setSelectedDevices] = useState(values.devices || [])

  const devices = useStore(state => state.device.devices, shallow)

  const handleSubmit = () => {
    onSubmit({ apply_to_all: applyAll, devices: selectedDevices })
    onStepChange(1)
  }

  return (
    <div
      id="affected-devices"
      className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center"
    >
      <div className="w-[37.5rem] m-auto">
        <div className="text-base font-medium mb-6">
          Choose which devices to apply this Tag to
        </div>
        <div className="mb-4">
          <Checkbox
            size="large"
            checked={applyAll}
            onChange={e => setApplyAll(e.target.checked)}
          >
            Apply to all devices
          </Checkbox>
        </div>
        {!applyAll && (
          <Select
            isMulti
            size="large"
            value={selectedDevices}
            onChange={v => setSelectedDevices(v as OptionValue[])}
            placeholder="Begin typing to search..."
            options={devices.map(d => ({ value: d.id, label: d.name }))}
          />
        )}
        <Button
          variant="primary"
          size="large"
          className="w-40 mt-10"
          onClick={handleSubmit}
        >
          Next <ChevronRight />
        </Button>
      </div>
    </div>
  )
}

export default AffectedDevices
