import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Forward(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M18.5 9.13397C19.1667 9.51887 19.1667 10.4811 18.5 10.866L6.5 17.7942C5.83333 18.1791 5 17.698 5 16.9282L5 3.0718C5 2.302 5.83333 1.82087 6.5 2.20577L18.5 9.13397Z"
          fill="currentColor"
        />
        <rect
          width="2"
          height="16"
          rx="1"
          transform="matrix(1 0 0 -1 20 18)"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
