export const getSlotsOfLayout = (layout: number) => {
  switch (layout) {
    case 2:
      return 4
    case 3:
      return 5
    case 4:
    case 5:
      return 6
    case 6:
      return 7
    case 7:
      return 8
    case 8:
    case 9:
      return 9
    case 10:
      return 16
    case 1:
    default:
      return 1
  }
}

export const renderParentClassLayout = (layout: number) => {
  switch (layout) {
    case 2:
      return 'grid-cols-2 auto-rows-fr'
    case 3:
    case 5:
    case 7:
    case 10:
      return 'grid-cols-4 auto-rows-fr'
    case 6:
      return 'grid-cols-3 grid-rows-6'
    case 4:
    case 8:
      return 'grid-cols-3 auto-rows-fr'
    case 9:
      return 'grid-cols-4 grid-rows-3'
    case 1:
    default:
      return 'grid-cols-1 grid-rows-1'
  }
}

export const renderChildrenClassLayout = (layout: number, index: number) => {
  switch (layout) {
    case 3: {
      switch (index) {
        case 2:
          return 'row-start-1 col-start-4 row-end-2 col-end-5'
        case 3:
          return 'row-start-2 col-start-4 row-end-3 col-end-5'
        case 4:
          return 'row-start-3 col-start-4 row-end-4 col-end-5'
        case 5:
          return 'row-start-4 col-start-4 row-end-5 col-end-5'
        case 1:
        default:
          return 'row-start-1 col-start-1 row-end-5 col-end-4'
      }
    }
    case 4: {
      switch (index) {
        case 2:
          return 'row-start-1 col-start-3 row-end-2 col-end-4'
        case 3:
          return 'row-start-2 col-start-3 row-end-3 col-end-4'
        case 4:
          return 'row-start-3 col-start-1 row-end-4 col-end-2'
        case 5:
          return 'row-start-3 col-start-2 row-end-4 col-end-3'
        case 6:
          return 'row-start-3 col-start-3 row-end-4 col-end-4'
        case 1:
        default:
          return 'row-start-1 col-start-1 row-end-3 col-end-3'
      }
    }
    case 5: {
      switch (index) {
        case 2:
          return 'row-start-1 col-start-3 row-end-4 col-end-5'
        case 3:
          return 'row-start-4 col-start-1 row-end-5 col-end-2'
        case 4:
          return 'row-start-4 col-start-2 row-end-5 col-end-3'
        case 5:
          return 'row-start-4 col-start-3 row-end-5 col-end-4'
        case 6:
          return 'row-start-4 col-start-4 row-end-5 col-end-5'
        case 1:
        default:
          return 'row-start-1 col-start-1 row-end-4 col-end-3'
      }
    }
    case 6: {
      switch (index) {
        case 2:
          return 'row-start-1 col-start-2 row-end-4 col-end-3'
        case 3:
          return 'row-start-4 col-start-1 row-end-7 col-end-2'
        case 4:
          return 'row-start-4 col-start-2 row-end-7 col-end-3'
        case 5:
          return 'row-start-1 col-start-3 row-end-3 col-end-4'
        case 6:
          return 'row-start-3 col-start-3 row-end-5 col-end-4'
        case 7:
          return 'row-start-5 col-start-3 row-end-7 col-end-4'
        case 1:
        default:
          return 'row-start-1 col-start-1 row-end-4 col-end-2'
      }
    }
    case 7: {
      switch (index) {
        case 2:
          return 'row-start-1 col-start-4 row-end-2 col-end-5'
        case 3:
          return 'row-start-2 col-start-4 row-end-3 col-end-5'
        case 4:
          return 'row-start-3 col-start-4 row-end-4 col-end-5'
        case 5:
          return 'row-start-4 col-start-1 row-end-5 col-end-2'
        case 6:
          return 'row-start-4 col-start-2 row-end-5 col-end-3'
        case 7:
          return 'row-start-4 col-start-3 row-end-5 col-end-4'
        case 8:
          return 'row-start-4 col-start-4 row-end-5 col-end-5'
        case 1:
        default:
          return 'row-start-1 col-start-1 row-end-4 col-end-4'
      }
    }
    case 9: {
      switch (index) {
        case 2:
          return 'row-start-1 col-start-3 row-end-2 col-end-4'
        case 3:
          return 'row-start-1 col-start-4 row-end-2 col-end-5'
        case 4:
          return 'row-start-2 col-start-1 row-end-3 col-end-3'
        case 5:
          return 'row-start-2 col-start-3 row-end-3 col-end-4'
        case 6:
          return 'row-start-2 col-start-4 row-end-3 col-end-5'
        case 7:
          return 'row-start-3 col-start-1 row-end-4 col-end-3'
        case 8:
          return 'row-start-3 col-start-3 row-end-4 col-end-4'
        case 9:
          return 'row-start-3 col-start-4 row-end-4 col-end-5'
        case 1:
        default:
          return 'row-start-1 col-start-1 row-end-2 col-end-3'
      }
    }
    case 1:
    case 2:
    case 8:
    case 10:
    default:
      return ''
  }
}
