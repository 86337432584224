import { ReactNode } from 'react'

import { Close } from 'icons/outline'
import { Alert, Button, Modal, ModalProps } from 'components'

interface Props extends ModalProps {
  modalTitle: ReactNode
  confirmText?: ReactNode
  warnText?: ReactNode
  onConfirm: () => void
  confirmButtonText?: string
}

function ModalConfirmDelete({
  modalTitle,
  confirmText,
  warnText,
  onConfirm,
  confirmButtonText = 'Delete',
  ...props
}: Props) {
  return (
    <Modal {...props} className="w-[25rem] px-6">
      <div className="flex items-center justify-between mb-6">
        <div className="text-base font-medium capitalize">{modalTitle}</div>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      {confirmText && <div className="text-sm mb-2 ">{confirmText}</div>}
      {warnText && (
        <Alert type="warning" className="">
          <div className="">{warnText}</div>
        </Alert>
      )}
      <div className="mt-6 flex items-center justify-end gap-2">
        <Button className="w-[5.3125rem]" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="primary" className="w-[6.25rem]" onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  )
}

export default ModalConfirmDelete
