import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Scanner(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M4.83334 2.5048H2.33334V5.0048H0.666672V0.838135H4.83334V2.5048ZM17.3333 5.0048V0.838135H13.1667V2.5048H15.6667V5.0048H17.3333ZM4.83334 17.5048H2.33334V15.0048H0.666672V19.1715H4.83334V17.5048ZM15.6667 15.0048V17.5048H13.1667V19.1715H17.3333V15.0048H15.6667ZM13.1667 5.0048H4.83334V15.0048H13.1667V5.0048ZM14.8333 15.0048C14.8333 15.9215 14.0833 16.6715 13.1667 16.6715H4.83334C3.91667 16.6715 3.16667 15.9215 3.16667 15.0048V5.0048C3.16667 4.08813 3.91667 3.33813 4.83334 3.33813H13.1667C14.0833 3.33813 14.8333 4.08813 14.8333 5.0048V15.0048ZM11.5 6.67147H6.50001V8.33813H11.5V6.67147ZM11.5 9.17147H6.50001V10.8381H11.5V9.17147ZM11.5 11.6715H6.50001V13.3381H11.5V11.6715Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
