import client, { ResponseMeta } from './client'

export interface User {
  account_id: number
  avatar: string | null
  avatar_url: string | null
  created_at: string
  email: string
  email_verified_at: string | null
  id: number
  is_admin: number
  name: string
  enabled_2fa: boolean
  two_factor_recovery_codes: string | null
  two_factor_secret: string | null
  updated_at: string
}

export type UserResponse = {
  data: User[]
  meta: ResponseMeta
}

export const getUsers = ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = 10,
  currentPage = 1
}: {
  search?: string
  sortBy?: string
  sortDirection?: string
  pageSize?: number
  currentPage?: number
}) => {
  return client.get<UserResponse>(
    `/api/users?filters[search]=${search}&order_by=${sortBy}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}`
  )
}

// ------------------------------------------------------------------------- //

export type CreateUserResponse = {
  data: User
}

export const createUser = (payload: {
  name: string
  email: string
  password: string
  is_admin: boolean
  is_super_admin: boolean
  account_id: number
}) => {
  return client.post<CreateUserResponse>('/api/users/', payload)
}

// ------------------------------------------------------------------------- //

export type EditUserResponse = {
  data: User
}

export const editUser = (
  userId: number,
  payload: { name: string; is_admin: number; avatar?: File }
) => {
  const { name, is_admin, avatar } = payload

  const formData = new FormData()
  formData.append('name', name)
  formData.append('is_admin', is_admin.toString())

  if (avatar) {
    formData.append('avatar', avatar, avatar.name)
  }

  formData.append('_method', 'PUT')
  return client.post<EditUserResponse>(`/api/users/${userId}`, formData)
}

// ------------------------------------------------------------------------- //

export const deleteUser = (id: number) => {
  return client.delete(`/api/users/${id}`)
}
