import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Video(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M13.5 2H1.5C0.675 2 0 2.675 0 3.5V12.5C0 13.325 0.675 14 1.5 14H13.5C14.325 14 15 13.325 15 12.5V3.5C15 2.675 14.325 2 13.5 2ZM5.625 11.375V4.625L10.875 8L5.625 11.375Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
