import client, { ResponseMeta } from './client'

export interface Transaction {
  amount: number
  card_last_four: number
  date: string
  id: number
  receipt_url: string
  transaction_id: string
}

export type TransactionResponse = {
  data: Transaction[]
  meta: ResponseMeta
}

export const getTransactionHistory = ({
  pageSize = 10,
  sortBy = 'id',
  sortDirection = 'desc',
  currentPage = 1
}: {
  pageSize?: number
  sortBy?: string
  sortDirection?: string
  currentPage?: number
}) => {
  return client.get<TransactionResponse>(
    `/api/transactions?page=${currentPage}&limit=${pageSize}&sort=${sortDirection}&order_by=${sortBy}`
  )
}
