import ReactApexChart from 'react-apexcharts'
import { format, set } from 'date-fns'

import { dateTimeFormat } from 'utils/dateTime'

const options = {
  redrawOnWindowResize: true,
  colors: ['#FFC123'],
  chart: { toolbar: { show: false } },
  grid: {
    borderColor: 'rgba(0, 0, 0, 0.25)',
    strokeDashArray: 5,
    xaxis: { lines: { show: true } }
  },
  tooltip: {
    enabled: true,
    custom({ series, _, dataPointIndex }: any) {
      return `
        <div class="p-3">
          <div class="text-xs mb-[7px] text-light-primary">${format(
            set(new Date(), {
              hours: dataPointIndex,
              minutes: 0,
              seconds: 0
            }),
            dateTimeFormat['MMM dd, yyyy'] + ' | ' + dateTimeFormat['hh:mm a']
          )}</div>
          <div class="text-sm font-medium">
            ${series[0][dataPointIndex]} notifications
          </div>
        </div>
      `
    }
  },
  dataLabels: { enabled: false },
  legend: { show: false },
  xaxis: {
    categories: Array.from({ length: 24 }).map((_, idx) => idx),
    axisBorder: { color: 'transparent', offsetY: -0.5 },
    axisTicks: { show: false },
    labels: { style: { colors: '#fff', fontSize: '12px' } }
  },
  yaxis: {
    labels: {
      style: { colors: '#fff', fontSize: '12px' },
      formatter: (val: number) => val.toFixed(0)
    }
  }
}

interface Props {
  data: number[]
}

function NotifSummaryChart({ data }: Props) {
  return (
    <ReactApexChart
      type="bar"
      series={[{ data }]}
      height={300}
      options={{
        ...options,
        yaxis: { ...options.yaxis, show: !data.every(d => d === 0) }
      }}
    />
  )
}

export default NotifSummaryChart
