import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function EyeOpen(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="10"
        viewBox="0 0 16 10"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path d="M8 0C4.94303 0 2.17081 1.6725 0.125192 4.38908C-0.0417306 4.61164 -0.0417306 4.92257 0.125192 5.14514C2.17081 7.86499 4.94303 9.53749 8 9.53749C11.057 9.53749 13.8292 7.86499 15.8748 5.14841C16.0417 4.92585 16.0417 4.61491 15.8748 4.39235C13.8292 1.6725 11.057 0 8 0ZM8.21929 8.12683C6.19004 8.25447 4.51427 6.58198 4.64191 4.54945C4.74665 2.87368 6.10494 1.51539 7.78071 1.41066C9.80996 1.28301 11.4857 2.95551 11.3581 4.98803C11.2501 6.66053 9.89179 8.01882 8.21929 8.12683ZM8.11783 6.57543C7.02465 6.64416 6.1213 5.74409 6.19331 4.65092C6.24895 3.74757 6.9821 3.01769 7.88545 2.95878C8.97862 2.89005 9.88197 3.79012 9.80996 4.8833C9.75105 5.78992 9.0179 6.51979 8.11783 6.57543Z" />
      </svg>
    </SVGUniqueID>
  )
}
