import { useEffect, useRef, useState } from 'react'
import { AxiosResponse } from 'axios'
import { motion } from 'framer-motion'

import { Download } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Spinner } from 'components'
import { downloadFile } from 'utils/file'
import { getSharedVaultFile, SharedVaultFile } from 'services/vaults'
import { Navigate } from 'react-router-dom'

interface Props {
  id: number
  email: string
  signature: string
}

function ViewSharedFile({ id, email, signature }: Props) {
  const [visible, setVisible] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout>()

  const fileAsync = useAsync<AxiosResponse<{ data: SharedVaultFile }>>({
    status: 'pending'
  })

  useEffect(() => {
    fileAsync.execute(getSharedVaultFile({ id, email, signature }))
  }, [])

  useEffect(() => {
    if (visible) {
      timeoutRef.current && clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        setTimeout(() => setVisible(false), 3000)
      })
    }
    return () => timeoutRef.current && clearTimeout(timeoutRef.current)
  }, [visible])

  if (fileAsync.isLoading) {
    return <Spinner className="w-screen h-screen bg-black" />
  }

  if (fileAsync.isError || !fileAsync.data?.data.data) {
    return <Navigate to="/404" replace />
  }

  const data = fileAsync.data.data.data

  return (
    <div
      className="relative w-screen h-screen bg-black"
      onMouseEnter={() => setVisible(true)}
      onMouseMove={() => setVisible(true)}
      onMouseOut={() => setVisible(false)}
    >
      <motion.div
        className="base-transition absolute top-0 h-0 w-full z-[1]"
        animate={{ opacity: visible ? 1 : 0 }}
      >
        <div
          className="p-6 flex flex-wrap items-center justify-between gap-4"
          style={{
            background:
              'linear-gradient(180deg,#000 0%,rgba(0, 0, 0, 0.515625) 66.67%,rgba(0, 0, 0, 0) 100%)'
          }}
        >
          <div className="text-xl text-white">
            {data.device_name} - {data.file_name}
          </div>
          <Button variant="ternary" onClick={() => downloadFile(data.file_url)}>
            <Download /> Download
          </Button>
        </div>
      </motion.div>
      <video controls muted src={data.file_url} className="w-full h-full" />
    </div>
  )
}

export default ViewSharedFile
