import { SVGProps } from 'react'

import {
  VideoCameraFilled,
  DigitalVideoRecorder,
  NetworkVideoRecorder,
  Emergency,
  TemperatureSensor,
  Scanner,
  SurgeProtector,
  IoTGateway,
  WifiOn,
  FireAlarm,
  IDS,
  OtherDevice
} from 'icons/outline'

export type DeviceIconType =
  | 'Security Camera'
  | 'Network Video Recorder'
  | 'Digital Video Recorder'
  | 'Emergency Button'
  | 'Temperature Sensor'
  | 'Scanner'
  | 'Surge Protector'
  | 'IoT Gateway'
  | 'Network Appliance'
  | 'Fire Alarm'
  | 'IDS'
  | 'Other'

type Props = {
  type: DeviceIconType
} & SVGProps<SVGSVGElement>

export type DeviceIconProps = Props

const getIconByType = (type: DeviceIconType) => {
  switch (type) {
    case 'Security Camera':
      return VideoCameraFilled
    case 'Digital Video Recorder':
      return DigitalVideoRecorder
    case 'Network Video Recorder':
      return NetworkVideoRecorder
    case 'Network Appliance':
      return WifiOn
    case 'Emergency Button':
      return Emergency
    case 'Temperature Sensor':
      return TemperatureSensor
    case 'Scanner':
      return Scanner
    case 'Surge Protector':
      return SurgeProtector
    case 'IoT Gateway':
      return IoTGateway
    case 'Fire Alarm':
      return FireAlarm
    case 'IDS':
      return IDS
    default:
      return OtherDevice
  }
}

export function DeviceIcon({ type, ...props }: Props) {
  const Icon = getIconByType(type)
  return <Icon {...props} />
}
