import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function FireAlarm(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M13.2333 9.29162C11.9249 5.89162 7.26658 5.70829 8.39158 0.766621C8.47492 0.399954 8.08325 0.116621 7.76658 0.308288C4.74158 2.09162 2.56658 5.66662 4.39158 10.35C4.54158 10.7333 4.09158 11.0916 3.76658 10.8416C2.25825 9.69995 2.09992 8.05829 2.23325 6.88329C2.28325 6.44995 1.71659 6.24162 1.47492 6.59995C0.908252 7.46662 0.333252 8.86662 0.333252 10.975C0.649919 15.6416 4.59159 17.075 6.00825 17.2583C8.03325 17.5166 10.2249 17.1416 11.7999 15.7C13.5333 14.0916 14.1666 11.525 13.2333 9.29162ZM5.49992 13.4833C6.69992 13.1916 7.31658 12.325 7.48325 11.5583C7.75825 10.3666 6.68325 9.19995 7.40825 7.31662C7.68325 8.87495 10.1333 9.84995 10.1333 11.55C10.1999 13.6583 7.91658 15.4666 5.49992 13.4833Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
