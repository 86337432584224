import { useEffect } from 'react'

import SideList from './SideList'
import LocationMap from './LocationMap'
import LocationDetail from './LocationDetail'
import { useLocationCtx } from '../LocationContext'

function DeviceLocations({ activeKey }: { activeKey: string }) {
  const { dispatch } = useLocationCtx()

  useEffect(() => {
    if (activeKey === 'device-location') {
      dispatch({ activePinId: undefined })
    }
  }, [activeKey])

  return (
    <div className="flex h-[calc(100vh_-_6.75rem_-_3.125rem)]">
      <SideList />
      <LocationMap />
      <LocationDetail />
    </div>
  )
}

export default DeviceLocations
