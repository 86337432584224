import { InfoCircle } from 'icons/outline'
import { ResourceIcon } from 'icons/utils'
import { ResourceIconType } from 'icons/utils/ResourceIcon'
import { Input } from 'components'

interface Props {
  type: ResourceIconType
  label: string
  value: string
  onChange: (value: string) => void
  placeholder: string
  extraUrl: string
  extraText: string
}

function InputRecipient({
  type,
  label,
  value,
  onChange,
  placeholder,
  extraUrl,
  extraText
}: Props) {
  return (
    <div className="w-full">
      <div className="flex items-center gap-2 mb-4">
        <ResourceIcon
          type={type}
          iconType="primary"
          className="w-5 h-5 shrink-0"
        />
        <div className="text-base font-medium">{label}</div>
      </div>
      <Input
        size="large"
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      <div className="mt-2 flex items-center gap-1 text-light-secondary">
        <InfoCircle className="w-3 h-3 shrink-0" />
        <a
          target="_blank"
          rel="noreferrer"
          href={extraUrl}
          className="text-[0.8125rem] font-medium underline"
        >
          {extraText}
        </a>
      </div>
    </div>
  )
}

export default InputRecipient
