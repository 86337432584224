import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function CheckedCircle({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M14 0C6.2804 0 0 6.2804 0 14C0 21.7196 6.2804 28 14 28C21.7196 28 28 21.7196 28 14C28 6.2804 21.7196 0 14 0Z"
          fill="url(#paint0_linear_875_309)"
        />
        <path
          d="M19.2498 9.05023L12.0501 16.2499L8.75031 12.95C8.25335 12.4531 7.44746 12.453 6.95042 12.95C6.45338 13.447 6.45338 14.2528 6.95042 14.7498L11.1501 18.9497C11.3888 19.1884 11.7125 19.3225 12.05 19.3225C12.0501 19.3225 12.05 19.3225 12.0501 19.3225C12.3876 19.3225 12.7113 19.1884 12.95 18.9498L21.0497 10.8502C21.5467 10.3532 21.5467 9.54736 21.0497 9.05031C20.5526 8.55327 19.7468 8.55319 19.2498 9.05023Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_875_309"
            x1="14"
            y1="0"
            x2="14"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
