import { useMemo, useState } from 'react'
import cx from 'classnames'
import RcTable from 'rc-table'
import { TableProps } from 'rc-table/lib/Table'
import { DefaultRecordType } from 'rc-table/lib/interface'

import NoResult from 'assets/images/NoResult.png'
import { DropdownDown, DropdownUp } from 'icons/outline'
import { Spinner } from 'components'

interface Props extends TableProps<DefaultRecordType> {
  loading?: boolean
  tableClassName?: string
  onSort?: (key: string, direction: string) => void
}

export function Table({
  loading,
  tableClassName,
  onSort,
  className,
  ...props
}: Props) {
  const [{ key, direction }, setSort] = useState({ key: '', direction: '' })

  const handleSort = (key: string) => {
    setSort(prev => {
      const direction =
        key !== prev.key
          ? 'ascending'
          : prev.direction === ''
          ? 'ascending'
          : prev.direction === 'ascending'
          ? 'descending'
          : prev.direction === 'descending'
          ? ''
          : ''
      onSort?.(key, direction)
      return { key, direction }
    })
  }

  const columns = useMemo(() => {
    return (props.columns || ([] as any)).map(
      ({ sorter = false, ...column }) => ({
        ...column,
        title: (
          <div className="select-none flex items-center justify-start text-sm gap-2">
            <div>{column.title}</div>
            {sorter && (
              <div className="inline-flex flex-col gap-[2px]">
                <DropdownUp
                  className={cx(
                    'base-transition w-[9px]',
                    key === column.key && direction === 'ascending'
                      ? 'text-primary'
                      : 'text-light-secondary/50'
                  )}
                />
                <DropdownDown
                  className={cx(
                    'base-transition w-[9px]',
                    key === column.key && direction === 'descending'
                      ? 'text-primary'
                      : 'text-light-secondary/50'
                  )}
                />
              </div>
            )}
          </div>
        ),
        onHeaderCell: () => ({
          className: cx(sorter && 'cursor-pointer hover:bg-[rgb(0,0,0,.04)]'),
          onClick: sorter ? () => handleSort(column.key) : undefined
        })
      })
    )
  }, [key, direction, props.columns])

  return (
    <div className={cx('relative', className)}>
      <RcTable
        {...props}
        columns={columns}
        className={tableClassName}
        emptyText={
          <div className="select-none flex flex-col justify-center items-center my-8">
            <img
              className="w-[4rem] grayscale opacity-90"
              src={NoResult}
              alt="no-result"
            />
            <div className="mt-3 text-sm text-black/30">No Data</div>
          </div>
        }
      />
      {loading && <Spinner className="absolute top-0 left-0 bg-white/50" />}
    </div>
  )
}
