import { DragEventHandler } from 'react'

import { DragDrop } from 'icons/outline'
import LivestreamPlayer from 'shared/LivestreamPlayer'

interface Props {
  index: number
  selectedDeviceId: number | null
  onDeviceIdChange: (deviceId: number | null) => void
}

function LivestreamTabItem({ index, selectedDeviceId, ...props }: Props) {
  const handleDragOver: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragEnter: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    ;(e.target as HTMLElement).classList.add('outline', 'outline-primary')
  }

  const handleDragLeave: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    ;(e.target as HTMLElement).classList.remove('outline', 'outline-primary')
  }

  const handleDrop: DragEventHandler<HTMLDivElement> = e => {
    ;(e.target as HTMLElement).classList.remove('outline', 'outline-primary')
    const deviceId = +e.dataTransfer.getData('deviceId')
    if (selectedDeviceId !== deviceId) {
      props.onDeviceIdChange(deviceId)
    }
  }

  return (
    <div
      className="relative w-full h-full bg-black text-white select-none overflow-hidden"
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {!selectedDeviceId && (
        <div className="w-full h-full flex flex-col justify-center items-center gap-4 pointer-events-none text-light-secondary">
          <DragDrop className="w-12 h-12 shrink-0" />
          <div className="text-base text-center tracking-[.2px]">
            Drag & drop your device here to view
          </div>
        </div>
      )}
      {selectedDeviceId && (
        <LivestreamPlayer
          key={selectedDeviceId}
          deviceId={selectedDeviceId}
          onRemove={() => props.onDeviceIdChange(null)}
        />
      )}
    </div>
  )
}

export default LivestreamTabItem
