import Bridge from 'assets/images/Bridge.png'
import Sensor from 'assets/images/Sensors.png'

type Props = {
  type: string
} & JSX.IntrinsicElements['img']

export function OrderHistoryIcon({ type, ...props }: Props) {
  let imgSrc: string | null = null
  const itemType = type.toLowerCase()

  if (itemType.includes('bridge')) {
    imgSrc = Bridge
  } else {
    // temperature sensor
    imgSrc = Sensor
  }

  return <img src={imgSrc} alt={type} {...props} />
}
