import { useState } from 'react'

import { EyeClose, EyeOpen } from 'icons/outline'
import { Input, Props } from './Input'

export function InputPassword(props: Props) {
  const [type, setType] = useState('password')

  return (
    <Input
      type={type}
      {...props}
      suffix={
        type !== 'password' ? (
          <EyeOpen
            className="cursor-pointer"
            onClick={() => setType('password')}
          />
        ) : (
          <EyeClose
            className="cursor-pointer"
            onClick={() => setType('text')}
          />
        )
      }
    />
  )
}
