import { Device } from './devices'
import client, { ResponseMeta } from './client'

type FloorPlanDevice = Omit<Device, 'deviceLiveStream' | 'location'> & {
  lat: string
  lng: string
}

export interface FloorPlan {
  created_at: string
  id: number
  image: string
  image_url: string
  location_id: number
  name: string
  updated_at: string
  devices: FloorPlanDevice[]
}

export interface FloorPlanResponse {
  data: FloorPlan[]
  meta: ResponseMeta
}

export const getAllFloorPlans = () => {
  return client.get<FloorPlanResponse>(
    '/api/locationFloorPlans?limit=99999&include=devices'
  )
}

// ------------------------------------------------------------------------- //

interface LinkedDevice {
  device_id: number
  lat: string
  lng: string
}

export const createFloorPlan = (payload: {
  location_id: number
  name: string
  image: File
  linkedDevices?: LinkedDevice[]
}) => {
  const { location_id, name, image, linkedDevices } = payload
  const formData = new FormData()

  formData.append('location_id', location_id.toString())
  formData.append('name', name)
  formData.append('image', image, image.name)

  if (linkedDevices) {
    formData.append('linkedDevices', JSON.stringify(linkedDevices))
  }

  return client.post<{ data: FloorPlan }>(`/api/locationFloorPlans`, formData)
}

export const updateFloorPlan = (
  id: number,
  payload: {
    name?: string
    image?: File
    linkedDevices?: LinkedDevice[]
  }
) => {
  const { name, image, linkedDevices } = payload
  const formData = new FormData()

  if (name) formData.append('name', name)
  if (image) formData.append('image', image, image.name)

  if (linkedDevices) {
    formData.append('linkedDevices', JSON.stringify(linkedDevices))
  }

  formData.append('_method', 'PUT')
  return client.post<{ data: FloorPlan }>(
    `/api/locationFloorPlans/${id}`,
    formData
  )
}

export const deleteFloorPlan = (id: number) => {
  return client.delete(`/api/locationFloorPlans/${id}`)
}
