import { useState, FormEventHandler, FC, ReactNode } from 'react'
import { omit } from 'lodash'
import cx from 'classnames'

import { channelMap } from 'utils/channel'
import { NotifChannel } from 'services/tags'

import RecipientChannels from './RecipientChannels'
import InputRecipient from './InputRecipient'
import SelectRecipient, { OptionValue } from './SelectRecipient'

type FormValues = {
  phone_recipients: OptionValue[]
  email_recipients: OptionValue[]
  whatsapp_recipients: OptionValue[]
  webhook: string
  slack_webhook: string
  teams_webbhook: string
  pagerduty_integration_key: string
}

interface Props {
  children?: ReactNode
  className?: string
  values?: FormValues
  submitId?: string
  onChange?: (channels: (keyof typeof channelMap)[], recs: FormValues) => void
  onSubmit?: (channels: (keyof typeof channelMap)[], recs: FormValues) => void
}

const initFormValues: FormValues = {
  phone_recipients: [],
  email_recipients: [],
  whatsapp_recipients: [],
  webhook: '',
  slack_webhook: '',
  teams_webbhook: '',
  pagerduty_integration_key: ''
}

const ChannelRecipientForm: FC<Props> = ({
  className,
  values,
  submitId = 'btn-submit',
  onChange,
  onSubmit,
  children
}) => {
  const [activeChannel, setActiveChannel] = useState<NotifChannel>('phone')
  const [formValues, setFormValues] = useState({ ...initFormValues, ...values })

  const getChannel = (values: FormValues) => {
    let channels: NotifChannel[] = []
    if (!!values.phone_recipients.length) channels.push('phone')
    if (!!values.email_recipients.length) channels.push('email')
    if (!!values.whatsapp_recipients.length) channels.push('whatsapp')
    if (!!values.webhook) channels.push('webhook')
    if (!!values.slack_webhook) channels.push('slack')
    if (!!values.teams_webbhook) channels.push('microsoft_teams')
    if (!!values.pagerduty_integration_key) channels.push('pager_duty')
    return channels
  }

  const handleFieldChange = (
    key: keyof FormValues,
    value: OptionValue[] | string
  ) => {
    setFormValues(prev => {
      const newValues = { ...prev, [key]: value }
      onChange?.(getChannel(newValues), omit(newValues, 'channels'))
      return newValues
    })
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    onSubmit?.(getChannel(formValues), omit(formValues, 'channels'))
  }

  return (
    <form className={cx(className)} onSubmit={handleSubmit}>
      <div className="flex w-full h-full relative">
        <RecipientChannels
          activeChannel={activeChannel}
          onChannelChange={setActiveChannel}
        />
        <div className="p-6 pb-4 grow">
          {activeChannel === 'phone' && (
            <SelectRecipient
              type="sms"
              label="SMS Recipient"
              placeholder="Select recipients to receive notifications via SMS"
              value={formValues.phone_recipients}
              onChange={v => handleFieldChange('phone_recipients', v)}
            />
          )}

          {activeChannel === 'email' && (
            <SelectRecipient
              type="email"
              label="Email Recipient"
              placeholder="Select recipients to receive notifications via Email"
              value={formValues.email_recipients}
              onChange={v => handleFieldChange('email_recipients', v)}
            />
          )}

          {activeChannel === 'whatsapp' && (
            <SelectRecipient
              type="whatsapp"
              label="WhatsApp Recipient"
              placeholder="Select recipients to receive notifications via WhatsApp"
              value={formValues.whatsapp_recipients}
              onChange={v => handleFieldChange('whatsapp_recipients', v)}
            />
          )}

          {activeChannel === 'webhook' && (
            <InputRecipient
              type="webhook"
              label="Webhook"
              value={formValues.webhook}
              placeholder="Enter Webhook URL"
              onChange={v => handleFieldChange('webhook', v)}
              extraUrl="https://developers.facebook.com/docs/messenger-platform/webhook"
              extraText="See How to get Webhook URL"
            />
          )}

          {activeChannel === 'slack' && (
            <InputRecipient
              type="slack"
              label="Slack Webhook"
              value={formValues.slack_webhook}
              placeholder="Enter Slack Webhook URL"
              onChange={v => handleFieldChange('slack_webhook', v)}
              extraUrl="https://api.slack.com/messaging/webhooks"
              extraText="See How to get Slack Webhook URL"
            />
          )}

          {activeChannel === 'microsoft_teams' && (
            <InputRecipient
              type="ms-teams"
              label="Microsoft Teams Webhook"
              value={formValues.teams_webbhook}
              placeholder="Enter Mircosoft Team Webhook URL"
              onChange={v => handleFieldChange('teams_webbhook', v)}
              extraUrl="https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook"
              extraText="See how to create a Mircosoft Teams Webhook"
            />
          )}

          {activeChannel === 'pager_duty' && (
            <InputRecipient
              type="pager-duty"
              label="PagerDuty"
              value={formValues.pagerduty_integration_key}
              placeholder="Enter PagerDuty integration key"
              onChange={v => handleFieldChange('pagerduty_integration_key', v)}
              extraUrl="https://support.pagerduty.com/docs/services-and-integrations"
              extraText="See how to create a PagerDuty integration key"
            />
          )}
        </div>
        <button
          type="submit"
          id={submitId}
          className="absolute w-0 h-0 top-0 left-0 invisible pointer-events-none opacity-0"
        />
      </div>
      {children && <div className="w-full block">{children}</div>}
    </form>
  )
}

export default ChannelRecipientForm
