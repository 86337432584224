import { Fragment, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import LocationImg from 'assets/images/Location.png'
import { Plus, Search } from 'icons/outline'
import { Button, Input } from 'components'
import ModalCreateUpdateLocation from 'shared/Modals/Location/CreateUpdateLocation'
import useStore from 'store'

import LocationListItem from './LocationListItem'

function SideList() {
  const [visible, setVisible] = useState(false)
  const [search, setSearch] = useState('')

  const locations = useStore(state => state.location.locations, shallow)

  return (
    <div className="w-[19.5625rem] shrink-0  border-r border-light-stroke p-4">
      {!locations.length && (
        <div className="flex flex-col items-center justify-center text-center w-[12.8125rem] m-auto h-full">
          <img
            src={LocationImg}
            alt="location"
            className="w-20 h-[3.4375rem] mb-[1.3125rem]"
          />
          <div className="text-base font-medium mb-2">
            Group your devices geographically
          </div>
          <div className="text-light-secondary text-[0.8125rem] mb-8">
            Added locations will be pinned to the adjacent map view
          </div>
          <Button variant="primary" onClick={() => setVisible(true)}>
            <Plus /> Create Location
          </Button>
        </div>
      )}
      {!!locations.length && (
        <Fragment>
          <Button block className="mb-4" onClick={() => setVisible(true)}>
            <Plus /> Create Location
          </Button>
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            className="mb-4"
            suffix={<Search />}
            placeholder="Search location by name"
          />
          <PerfectScrollbar
            options={{ wheelPropagation: false }}
            className="!overflow-x-hidden h-[calc(100%_-_6rem)]"
          >
            {locations
              .filter(d => d.name.toLowerCase().includes(search.toLowerCase()))
              .map((location, idx, self) => (
                <Fragment key={location.id}>
                  <LocationListItem location={location} />
                  {idx + 1 < self.length && (
                    <div className="border-b border-[#ededed] border-dashed py-px" />
                  )}
                </Fragment>
              ))}
          </PerfectScrollbar>
        </Fragment>
      )}
      <AnimatePresence initial={false}>
        {!!visible && (
          <ModalCreateUpdateLocation onClose={() => setVisible(false)} />
        )}
      </AnimatePresence>
    </div>
  )
}

export default SideList
