import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Tag({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="11"
        height="15"
        viewBox="0 0 11 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M10.4738 14.9622C10.3216 14.9622 10.1733 14.8961 10.0713 14.7752L5.5 9.35524L0.928703 14.7752C0.786208 14.9442 0.553263 15.006 0.345711 14.9303C0.138116 14.8544 0 14.6569 0 14.4359V1.46276C0 1.17208 0.235681 0.936401 0.526356 0.936401H10.4736C10.7643 0.936401 11 1.17208 11 1.46276V14.4358C11 14.6568 10.8619 14.8543 10.6543 14.9302C10.5953 14.9517 10.5342 14.9622 10.4738 14.9622ZM5.5 8.01244C5.65513 8.01244 5.80234 8.08086 5.90235 8.19944L9.94733 12.9953V1.98911H1.05271V12.9953L5.09765 8.19944C5.19766 8.08086 5.34487 8.01244 5.5 8.01244Z"
          fill="url(#paint0_linear_8447_59008)"
        />
        <path
          d="M0.678802 1.37469H10.3215V14.5239L5.50017 8.8259L0.678802 14.0856V1.37469Z"
          fill="url(#paint1_linear_8447_59008)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8447_59008"
            x1="0"
            y1="0.936401"
            x2="0"
            y2="14.9622"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8447_59008"
            x1="0.678802"
            y1="1.37469"
            x2="0.678802"
            y2="14.5239"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
