import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function ChevronLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M0.204618 7.49966L6.50038 13.7933C6.77663 14.0689 7.22421 14.0689 7.50116 13.7933C7.77742 13.5178 7.77742 13.0702 7.50116 12.7946L1.70477 7.00035L7.50046 1.20605C7.77672 0.930496 7.77672 0.482921 7.50046 0.206667C7.22421 -0.0688891 6.77594 -0.0688891 6.49968 0.206667L0.20392 6.50028C-0.0680896 6.77299 -0.0680896 7.2276 0.204618 7.49966Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
