import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Tag(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="11"
        height="15"
        viewBox="0 0 11 15"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M10.4738 14.0258C10.3216 14.0258 10.1733 13.9597 10.0713 13.8388L5.5 8.41883L0.928703 13.8388C0.786208 14.0078 0.553263 14.0696 0.345711 13.9939C0.138116 13.918 0 13.7205 0 13.4995V0.526356C0 0.235681 0.235681 0 0.526356 0H10.4736C10.7643 0 11 0.235681 11 0.526356V13.4994C11 13.7204 10.8619 13.9179 10.6543 13.9938C10.5953 14.0153 10.5342 14.0258 10.4738 14.0258ZM5.5 7.07604C5.65513 7.07604 5.80234 7.14446 5.90235 7.26304L9.94733 12.0589V1.05271H1.05271V12.0589L5.09765 7.26304C5.19766 7.14446 5.34487 7.07604 5.5 7.07604Z"
          fill="currentColor"
        />
        <path
          d="M0.678772 0.438293H10.3215V13.5875L5.50014 7.88949L0.678772 13.1492V0.438293Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
