import { Suspense } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import cx from 'classnames'

import { userSettingsMenu } from 'configs/navigation'
import { usePageTitle } from 'hooks'
import { Spinner } from 'components'
import MenuItem from 'shared/MenuItem'

function Settings() {
  usePageTitle('Settings | Broadflow Streams')

  const { pathname } = useLocation()

  return (
    <div className="relative w-full">
      <div className="fixed top-[3.125rem] left-[3.4375rem] bg-white z-[1] w-[15rem] min-h-[calc(100vh_-_3.125rem)] border-r border-[#e7e7e7]">
        <div className="text-2xl font-medium p-4 pb-5">Settings</div>
        {userSettingsMenu.map(({ key, label, Icon, navLink }) => {
          const active = pathname.includes(navLink!)
          return (
            <Link key={key} to={navLink!}>
              <MenuItem
                className={cx('p-4 !rounded-none', active && 'bg-light-hover')}
              >
                <Icon
                  className="w-5 h-5 shrink-0"
                  color={['#DDDDDD', '#B5B5B5']}
                />
                <div className={cx(active && 'font-medium')}>{label}</div>
              </MenuItem>
            </Link>
          )
        })}
      </div>
      <div className="ml-[15rem] bg-[#fafafa] min-h-[calc(100vh_-_3.125rem)] flex-1">
        <Suspense fallback={<Spinner className="w-full h-screen" />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}

export default Settings
