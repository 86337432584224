import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import shallow from 'zustand/shallow'

import ImgSensors from 'assets/images/Sensors.png'
import { InfoCircle } from 'icons/filled'
import { Close, Minus, Plus } from 'icons/outline'
import { Button, Modal, ModalProps, Tooltip, Spinner } from 'components'
import { SENSOR_PRODUCT_NAME } from 'utils/device'
import { ADD_ON } from 'utils/plan'
import useStore, { Store } from 'store'

const mapState = ({ product, plan }: Store) => ({
  planLoading: plan.loading,
  plans: plan.plans,
  productLoading: product.loading,
  products: product.products,
  getPlans: plan.getPlans,
  getProducts: product.getProducts
})

function ModalSelectSensorCount(props: ModalProps) {
  const navigate = useNavigate()
  const [count, setCount] = useState(1)
  const store = useStore(mapState, shallow)

  useEffect(() => {
    if (!store.plans.length && !store.planLoading) store.getPlans()
    if (!store.products.length && !store.productLoading) store.getProducts()
  }, [])

  const sensor = store.products.find(p => p.name === SENSOR_PRODUCT_NAME)
  const addOn = store.plans.find(p => p.name === ADD_ON.TEMPERATURE_SENSOR)

  const handleCountChange = (num: number) => () => {
    setCount(prev => {
      const newCount = prev + num
      return newCount < 1 ? 1 : newCount
    })
  }

  const handleCheckout = () => {
    navigate('/checkout', { state: { type: 'sensor', count, id: sensor!.id } })
  }

  const renderBillSummary = () => (
    <Fragment>
      <div className="text-sm text-light-primary font-medium mb-4">
        Bill Summary
      </div>
      <div className="flex gap-4 mb-3">
        <span className="text-light-secondary text-[0.8125rem] leading-normal basis-5/12">
          Price
        </span>
        <span className="text-[0.8125rem] font-semibold leading-normal">
          {count} x ${sensor!.price_in_dollars}
        </span>
      </div>
      <div className="flex gap-4">
        <span className="text-light-secondary text-[0.8125rem] leading-normal basis-5/12 inline-flex gap-1 items-center">
          Monthly monitoring
          <Tooltip content="Cloud monitoring subscription" placement="top">
            <InfoCircle className="cursor-pointer w-[0.875rem] h-[0.875rem]" />
          </Tooltip>
        </span>
        <span className="text-[0.8125rem] font-semibold leading-normal">
          {count} x ${addOn!.price_in_dollars} / month
        </span>
      </div>
      <div className="border-b border-light-stroke my-2" />
      <div className="flex gap-4 items-center">
        <span className="text-light-secondary text-[0.8125rem] leading-normal basis-5/12">
          Total
        </span>
        <span className="text-xl font-medium leading-normal">
          ${count * (sensor!.price_in_dollars + (addOn!.price_in_dollars || 1))}
        </span>
      </div>
    </Fragment>
  )

  return (
    <Modal {...props} className="!p-0 w-[25rem]">
      <div className="font-medium flex justify-between items-center px-4 py-3 border-b border-light-stroke mb-6">
        <div>Buy Temperature & Humidity Sensor</div>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      {(store.planLoading || store.productLoading) && (
        <Spinner size="small" className="h-40 mb-6" />
      )}
      {!!sensor && !!addOn && (
        <div className="px-4 pb-6">
          <div className="flex justify-between mb-12">
            <div>
              <h4 className="text-sm font-medium mb-2">Select quantity</h4>
              <div className="flex gap-2">
                <div className="w-[5rem] h-[4.7rem] p-1 rounded-lg border border-light-stroke flex items-center justify-center">
                  <span className="text-5xl leading-normal">{count}</span>
                </div>
                <div className="flex flex-col justify-between">
                  <Button className="w-8 h-8" onClick={handleCountChange(1)}>
                    <Plus />
                  </Button>
                  <Button className="w-8 h-8" onClick={handleCountChange(-1)}>
                    <Minus />
                  </Button>
                </div>
              </div>
            </div>
            <img
              src={ImgSensors}
              alt="sensor"
              className="w-[7.5rem] shrink-0"
            />
          </div>
          {renderBillSummary()}
          <Button
            size="large"
            variant="primary"
            className="mt-12 w-full"
            onClick={handleCheckout}
          >
            Checkout
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default ModalSelectSensorCount
