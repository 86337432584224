import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function PagerDuty(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="11"
        height="15"
        viewBox="0 0 11 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M2.14115 10.715H0V14.608H2.14115V10.715Z" fill="#06AC38" />
        <path
          d="M8.06406 0.713716C6.9147 0.101959 6.11756 0 4.23595 0H0V8.8612H2.14115H2.41922H4.21741C5.89511 8.8612 7.14643 8.75924 8.24944 8.02698C9.45442 7.22985 10.0754 5.90438 10.0754 4.37498C10.0754 2.71583 9.30611 1.39036 8.06406 0.713716ZM4.70867 7.00739H2.14115V1.90015L4.56037 1.88161C6.7664 1.85381 7.86941 2.63241 7.86941 4.39352C7.86941 6.28441 6.50686 7.00739 4.70867 7.00739Z"
          fill="#06AC38"
        />
      </svg>
    </SVGUniqueID>
  )
}
