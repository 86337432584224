import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function New({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_10941_3904)">
          <path
            d="M28 4.69208V26.0802C27.9378 26.5014 27.8776 26.9164 27.5556 27.2459C27.2179 27.5919 26.8624 27.8708 26.3537 27.9221C25.9842 27.9581 25.6122 27.9613 25.242 27.9316C24.7142 27.8913 24.3382 27.5693 23.9889 27.224C22.534 25.7883 21.0928 24.3362 19.6461 22.8895L1.11849 4.36186C0.92569 4.16998 0.737221 3.9733 0.553084 3.77185C-0.122389 3.03416 -0.0731623 2.14743 0.162023 1.30651C0.343881 0.641973 0.847749 0.15998 1.59979 0.0519594C1.70508 0.0369185 1.81584 0.0478574 1.91634 0H23.3072C23.4159 0.0628983 23.5376 0.0287144 23.6491 0.0376022C25.1204 0.154511 26.1842 0.951679 27.0675 2.05992C27.3587 2.42569 27.5495 2.84547 27.7443 3.26319C27.9316 3.66656 27.9289 4.10617 27.9706 4.53688C27.9774 4.58953 27.9583 4.64627 28 4.69208Z"
            fill="url(#paint0_linear_10941_3904)"
          />
          <path
            d="M21.4374 15.7273C22.5313 15.1804 23.5636 14.6628 24.5953 14.148C24.6916 14.0974 24.7915 14.0537 24.8941 14.0174C25.1921 13.9176 25.4752 14.0318 25.6071 14.2984C25.7391 14.565 25.6516 14.8597 25.3931 15.0382C25.3248 15.0829 25.2535 15.1231 25.1798 15.1585L20.8385 17.3257C20.7017 17.3941 20.565 17.4707 20.4009 17.4549C19.9962 17.4167 19.7692 17.057 19.9032 16.6345C20.124 15.9427 20.3578 15.2556 20.5869 14.5664C20.6081 14.498 20.6744 14.4256 20.6122 14.3668C20.55 14.308 20.4754 14.3764 20.4112 14.3975C19.7475 14.6163 19.0844 14.8369 18.4217 15.0593C18.1434 15.1537 17.8822 15.1626 17.6696 14.9226C17.457 14.6826 17.5131 14.4256 17.645 14.1637C18.3834 12.6952 19.119 11.2257 19.8519 9.75538C19.949 9.5619 20.068 9.39098 20.2895 9.33834C20.3912 9.30912 20.4992 9.31004 20.6004 9.34098C20.7016 9.37192 20.7917 9.43157 20.8597 9.51268C20.9964 9.67197 21.0579 9.8593 20.9738 10.0699C20.9369 10.1622 20.9014 10.2552 20.8569 10.3433C20.3346 11.3921 19.8109 12.4402 19.2804 13.5019C19.3925 13.5341 19.4773 13.476 19.5634 13.4479C20.15 13.2565 20.7357 13.0621 21.3205 12.8647C21.5714 12.78 21.8059 12.782 22.0041 12.9789C22.2024 13.1758 22.201 13.4117 22.1176 13.6626C21.8968 14.3319 21.6773 15.0012 21.4374 15.7273Z"
            fill="#F9F9F9"
          />
          <path
            d="M12.4976 4.69138C11.8037 5.38326 11.1617 6.02455 10.5149 6.66379C10.4329 6.74922 10.3411 6.82471 10.2414 6.88872C10.1319 6.95553 10.0026 6.98237 9.87544 6.96467C9.74833 6.94698 9.63126 6.88584 9.5441 6.79164C9.45719 6.70239 9.40248 6.58673 9.38862 6.46292C9.37475 6.33912 9.40251 6.21423 9.46753 6.10796C9.50527 6.04491 9.551 5.987 9.60358 5.93567C10.7335 4.8035 11.8654 3.6727 12.9994 2.54327C13.238 2.30603 13.5053 2.2739 13.7432 2.41815C13.9812 2.56241 14.07 2.78802 14.0071 3.10183C13.7679 4.29416 13.5286 5.48673 13.2893 6.67951C13.2803 6.74138 13.2746 6.80369 13.2722 6.86616C13.3959 6.83061 13.4493 6.73968 13.5176 6.67199C14.1179 6.07947 14.717 5.48285 15.315 4.88213C15.4928 4.70505 15.6972 4.60455 15.9536 4.68454C16.0547 4.71319 16.1456 4.77008 16.2156 4.84855C16.2856 4.92701 16.3318 5.0238 16.3487 5.12757C16.3959 5.34634 16.3098 5.52547 16.1655 5.67451C15.0261 6.82172 13.8813 7.96597 12.7314 9.10725C12.5106 9.32671 12.2385 9.3609 12.0108 9.23373C11.7831 9.10657 11.669 8.87822 11.7339 8.55689C11.9787 7.29141 12.2323 6.02934 12.4976 4.69138Z"
            fill="#F9F9F9"
          />
          <path
            d="M19.856 8.75242C19.8519 8.85245 19.8219 8.9497 19.769 9.03467C19.7161 9.11964 19.642 9.18941 19.554 9.23716C19.466 9.2849 19.3671 9.309 19.267 9.30708C19.1669 9.30516 19.069 9.2773 18.983 9.22621C18.8544 9.14046 18.7378 9.03791 18.6363 8.92129C18.3143 8.60065 17.9889 8.28342 17.6758 7.95457C17.5589 7.8322 17.485 7.83288 17.3722 7.95457C17.1689 8.17563 16.9568 8.38757 16.7357 8.59039C16.6161 8.70115 16.6338 8.77294 16.7425 8.87412C16.9292 9.04709 17.1076 9.22895 17.2827 9.41354C17.5773 9.72256 17.5971 10.0596 17.3414 10.3023C17.0858 10.545 16.7644 10.5204 16.467 10.2381C16.2763 10.0569 16.0883 9.87092 15.9091 9.67812C15.8114 9.57421 15.7437 9.57352 15.6452 9.67812C15.4333 9.90237 15.2159 10.1232 14.9896 10.3324C14.87 10.4432 14.8905 10.5129 14.9971 10.6161C15.3561 10.9641 15.7075 11.3189 16.0589 11.6751C16.1436 11.7567 16.2178 11.8486 16.2797 11.9486C16.339 12.0567 16.3614 12.1812 16.3437 12.3032C16.3259 12.4252 16.2689 12.5381 16.1813 12.6248C16.0928 12.7102 15.9794 12.7652 15.8576 12.7818C15.7358 12.7984 15.6118 12.7757 15.5037 12.7171C15.4184 12.6656 15.3398 12.6036 15.2699 12.5325C14.7476 12.0142 14.2271 11.494 13.7084 10.9716C13.37 10.6298 13.3666 10.3167 13.7022 9.97894C14.8121 8.86455 15.9242 7.75334 17.0386 6.64533C17.3661 6.3199 17.6819 6.32264 18.0115 6.64533C18.5488 7.17518 19.0787 7.71118 19.6126 8.24445C19.757 8.3755 19.8443 8.55782 19.856 8.75242Z"
            fill="#F9F9F9"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_10941_3904"
            x1="0.00354004"
            y1="0"
            x2="0.00354004"
            y2="27.9517"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <clipPath id="clip0_10941_3904">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
