import { Fragment } from 'react'

import { usePageTitle } from 'hooks'
import RouteTabs from 'shared/RouteTabs'

import DeviceNotifications from './DeviceNotifications'
import TagManagement from './TagManagement'

const tabs = [
  {
    key: 'all-device-notification',
    tab: 'Device Notifications',
    Component: DeviceNotifications
  },
  {
    key: 'notification-tag',
    tab: 'Tag Management',
    Component: TagManagement
  }
]

function NotificationManagement() {
  usePageTitle('Notification Management | Broadflow Streams')

  return (
    <Fragment>
      <div className="text-2xl font-medium p-4 pt-6">Notifications</div>
      <RouteTabs destroyInactiveTabPane className="mt-2" tabs={tabs} />
    </Fragment>
  )
}

export default NotificationManagement
