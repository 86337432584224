import { Close } from 'icons/outline'
import { Modal, ModalProps, Tabs } from 'components'
import { InboundNotif } from 'services/notifications'

import DetailAndNote from './DetailAndNote'
import OutboundNotification from './OutboundNotification'

interface Props extends ModalProps {
  notif: InboundNotif
}

function ModalViewInboundNotifDetail({ notif, ...props }: Props) {
  return (
    <Modal {...props} className="w-[59.375rem] inbound-notification">
      <div className="flex items-center justify-between gap-4 mb-6">
        <div className="text-base font-medium">Notification Detail</div>
        <Close className="cursor-pointer" onClick={props.onClose} />
      </div>
      <Tabs>
        <Tabs.TabPane key="1" tab="Detail & Note">
          <DetailAndNote notif={notif} />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="Outbound Notification">
          <OutboundNotification inboundNotifId={notif.id} />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  )
}

export default ModalViewInboundNotifDetail
