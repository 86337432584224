import * as Filled from 'icons/filled'

import DeviceManagement from './DeviceManagement'
import Notifications from './Notifications'
import TagManagement from './TagManagement'
import CloudRecording from './CloudRecording'
import CloudSearch from './CloudSearch'
import VideoVault from './VideoVault'
import LiveViewing from './LiveViewing'
import Playback from './Playback'
import UserManagement from './UserManagement'
import RecipientManagement from './RecipientManagement'

const guidebookTabs = [
  {
    label: 'Device Management',
    Icon: Filled.VideoCamera,
    Component: DeviceManagement
  },
  {
    label: 'Notifications',
    Icon: Filled.Bell,
    Component: Notifications
  },
  {
    label: 'Tag Management',
    Icon: Filled.Tag,
    Component: TagManagement
  },
  {
    label: 'Cloud Recording',
    Icon: Filled.Cloud,
    Component: CloudRecording
  },
  {
    label: 'Cloud Search',
    Icon: Filled.Search,
    Component: CloudSearch
  },
  {
    label: 'Video Vault',
    Icon: Filled.Vault,
    Component: VideoVault
  },
  {
    label: 'Live Viewing',
    Icon: Filled.Live,
    Component: LiveViewing
  },
  {
    label: 'Playback',
    Icon: Filled.ClockCircle,
    Component: Playback
  },
  {
    label: 'User Management',
    Icon: Filled.User,
    Component: UserManagement
  },
  {
    label: 'Recipient Management',
    Icon: Filled.User,
    Component: RecipientManagement
  }
]

export default guidebookTabs
