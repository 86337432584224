import { Fragment, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import cx from 'classnames'

import { Copy, InfoCircle } from 'icons/outline'
import { Share } from 'icons/filled'
import { useAsync } from 'hooks'
import { Spinner } from 'components'
import { copyToClipBoard } from 'utils/functions'
import { DeviceSMTP, getDeviceSMTP } from 'services/devices'

interface Props {
  deviceId: number
}

function SmtpConfig({ deviceId }: Props) {
  const [showPassword, setShowPassword] = useState(false)

  const smtpAsync = useAsync<AxiosResponse<{ data: DeviceSMTP }>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    smtpAsync.execute(getDeviceSMTP(deviceId))
  }, [])

  const renderCopy = (className: string, text: string) => (
    <div
      onClick={() => copyToClipBoard(text)}
      className={cx(
        'shrink-0 self-center w-[2.125rem] h-8 inline-flex justify-center items-center bg-[rgba(255,255,255,0.2)] rounded-lg cursor-pointer',
        className
      )}
    >
      <Copy />
    </div>
  )

  const { isLoading, data } = smtpAsync
  const smtp = data?.data.data

  return (
    <div className="p-4 bg-[#7c80de] rounded-lg text-white">
      <div className="mb-4 text-base font-medium">SMTP Configuration</div>
      {isLoading && <Spinner size="small" className="h-40 !text-white" />}
      {!isLoading && !!smtp && (
        <Fragment>
          <div className="bg-primary mb-4 px-4 py-3.5 rounded-lg flex items-center gap-2">
            <InfoCircle className="shrink-0 w-4 h-4" />
            <div className="font-medium">
              Enter the following credentials in the SMTP settings for your
              device
            </div>
          </div>
          <div className="bg-[rgba(81,86,211,.5)] p-4 rounded-2xl flex gap-2 mb-4">
            <Share color="#ffffff" className="w-4 h-4 mt-[3px] shrink-0" />
            <div className="grow">
              <div className="mb-[5px]">To Address</div>
              <div className="font-medium">{smtp.to_email}</div>
            </div>
            {renderCopy('', smtp.to_email)}
          </div>
          <div className="flex items-center gap-2 mb-2">
            <div className="w-32 font-light shrink-0">Host</div>
            <div className="grow font-medium break-all mr-3">{smtp.host}</div>
            {renderCopy('mr-4', smtp.host)}
          </div>
          <div className="flex items-center gap-2 mb-2">
            <div className="w-32 font-light shrink-0">Port</div>
            <div className="grow font-medium break-all mr-3">{smtp.port}</div>
            {renderCopy('mr-4', smtp.port)}
          </div>
          <div className="flex items-center gap-2 mb-2">
            <div className="w-32 font-light shrink-0">Username</div>
            <div className="grow font-medium break-all mr-3">
              {smtp.username}
            </div>
            {renderCopy('mr-4', smtp.username)}
          </div>
          <div className="flex items-center gap-2 mb-2">
            <div className="w-32 font-light shrink-0">Password</div>
            <div className="grow font-medium break-all mr-3 inline-flex gap-2">
              {showPassword ? smtp.password : '*****'}
              <div
                className="underline cursor-pointer shrink-0"
                onClick={() => setShowPassword(prev => !prev)}
              >
                {showPassword ? 'Hide' : 'Show'}
              </div>
            </div>
            {renderCopy('mr-4', smtp.password)}
          </div>
          <div className="flex items-center gap-2">
            <div className="w-32 font-light shrink-0">From Address</div>
            <div className="grow font-medium break-all mr-3">
              {smtp.sender_email}
            </div>
            {renderCopy('mr-4', smtp.sender_email)}
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default SmtpConfig
