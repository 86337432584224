import { useNavigate } from 'react-router-dom'
import { FieldData } from 'rc-field-form/lib/interface'
import { omit } from 'lodash'

import { useAsync } from 'hooks'
import { Button, Form, FormField, Input, InputPassword } from 'components'
import PasswordRequirements from 'shared/PasswordRequirements'
import { matchPasswords, validatePassword } from 'utils/validation'
import { email, required } from 'utils/rules'
import { resetPassword } from 'services/auth'

type FormValues = {
  email: string
  newPassword: string
  rePassword: string
}

interface Props {
  token: string
}

function ResetPasswordForm({ token }: Props) {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { execute, isLoading } = useAsync({ showNotifOnError: true })

  const handleValidate = (values: FormValues) => {
    let errors: FieldData[] = []
    const { newPassword, rePassword } = values

    const passwordResult = validatePassword(newPassword)
    if (passwordResult !== true) {
      errors.push({ name: 'newPassword', errors: [passwordResult] })
    }

    const rePasswordResult = validatePassword(rePassword)
    if (rePasswordResult !== true) {
      errors.push({ name: 'rePassword', errors: [rePasswordResult] })
    }

    const matchPasswordResult = matchPasswords(newPassword, rePassword)
    if (matchPasswordResult !== true) {
      errors.push({ name: 'rePassword', errors: [matchPasswordResult] })
    }

    !!errors.length ? form.setFields(errors) : handleSubmit(values)
  }

  const handleSubmit = async (values: FormValues) => {
    await execute(
      resetPassword({
        token,
        ...omit(values, ['newPassword', 'rePassword']),
        password: values.newPassword,
        password_confirmation: values.rePassword
      }).catch(console.error)
    )
    return navigate('/login')
  }

  return (
    <div className="m-auto w-[28rem] bg-white rounded-lg p-6">
      <h4 className="text-base font-medium mb-3">Change Password</h4>
      <PasswordRequirements className="mb-6" />
      <Form form={form} onFinish={handleValidate}>
        <FormField
          name="email"
          label="Email"
          rules={[required, email]}
          requiredMask
        >
          <Input block placeholder="Enter your email" />
        </FormField>
        <FormField
          name="newPassword"
          label="New Password"
          rules={[required]}
          requiredMask
        >
          <InputPassword block placeholder="Enter your new password" />
        </FormField>
        <FormField
          name="rePassword"
          label="Re-enter Password"
          rules={[required]}
          requiredMask
        >
          <InputPassword block placeholder="Re-enter your new password" />
        </FormField>
        <Button
          block
          type="submit"
          size="large"
          variant="primary"
          disabled={isLoading}
        >
          Change password
        </Button>
      </Form>
    </div>
  )
}

export default ResetPasswordForm
