import { SetState } from 'zustand'

import toast from 'utils/toast'
import { ResponseError } from 'services/client'
import { getProducts, Product } from 'services/products'

import { Store } from './'

export interface ProductSlice {
  loading: boolean
  products: Product[]
  getProducts: () => void
  setProducts: (payload: Product[]) => void
}

const slice = (set: SetState<Store>) => ({
  loading: false,
  products: [],
  getProducts: async () => {
    try {
      set(({ product }) => ({ product: { ...product, loading: true } }))
      const response = await getProducts()
      set(({ product }) => ({
        product: { ...product, products: response.data.data, loading: false }
      }))
    } catch (e: any) {
      const error = e as ResponseError
      toast.error({ title: error.message, description: error.errors })
      set(({ product }) => ({ product: { ...product, loading: false } }))
    }
  },
  setProducts: (payload: Product[]) => {
    return set(({ product }) => ({
      product: { ...product, products: payload }
    }))
  }
})

export default slice
