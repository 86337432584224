import { FieldData } from 'rc-field-form/lib/interface'

import {
  Button,
  Checkbox,
  Form,
  FormField,
  Input,
  InputPassword
} from 'components'
import PasswordRequirements from 'shared/PasswordRequirements'
import { matchPasswords, validatePassword } from 'utils/validation'
import { email, required } from 'utils/rules'

const termsRequired = { required: true, message: 'Please accept our terms' }

export type FormValues = {
  acceptTerms: boolean
  email: string
  name: string
  password: string
  rePassword: string
}

interface Props {
  loading: boolean
  onSubmit: (values: FormValues) => void
}

function RegisterForm({ loading, onSubmit }: Props) {
  const [form] = Form.useForm()

  const validateForm = (values: FormValues) => {
    let errors: FieldData[] = []
    const { acceptTerms, password, rePassword } = values

    const passwordResult = validatePassword(password)
    if (passwordResult !== true) {
      errors.push({ name: 'password', errors: [passwordResult] })
    }

    const rePasswordResult = validatePassword(rePassword)
    if (rePasswordResult !== true) {
      errors.push({ name: 'rePassword', errors: [rePasswordResult] })
    }

    const matchPasswordResult = matchPasswords(password, rePassword)
    if (matchPasswordResult !== true) {
      errors.push({ name: 'rePassword', errors: [matchPasswordResult] })
    }

    if (!acceptTerms) {
      errors.push({ name: 'acceptTerms', errors: [termsRequired.message] })
    }

    !!errors.length ? form.setFields(errors) : onSubmit(values)
  }

  return (
    <div className="m-auto bg-white p-6 rounded-lg">
      <h3 className="text-[1.3125rem] font-medium mb-2">Sign Up</h3>
      <div className="mb-3 text-sm">
        Create your free account to start monitoring your business today.
      </div>
      <PasswordRequirements className="mb-6 pr-6" />
      <Form form={form} onFinish={validateForm}>
        <FormField
          name="name"
          label="Your Name"
          rules={[required]}
          requiredMask
        >
          <Input block placeholder="Enter your name" />
        </FormField>
        <FormField
          name="email"
          label="Email Address"
          rules={[required, email]}
          requiredMask
        >
          <Input block placeholder="Enter user email address" />
        </FormField>
        <FormField
          name="password"
          label="Password"
          rules={[required]}
          requiredMask
        >
          <InputPassword block placeholder="Enter your password" />
        </FormField>
        <FormField
          name="rePassword"
          label="Re-enter Password"
          rules={[required]}
          requiredMask
        >
          <InputPassword block placeholder="Re-enter your password" />
        </FormField>
        <FormField
          name="acceptTerms"
          valuePropName="checked"
          rules={[termsRequired]}
        >
          <Checkbox>
            I agree to the Broadflow{' '}
            <a
              className="text-primary"
              href="https://broadflow.co/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              className="text-primary"
              href="https://broadflow.co/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </Checkbox>
        </FormField>
        <Button
          block
          type="submit"
          size="large"
          variant="primary"
          disabled={loading}
        >
          Register
        </Button>
      </Form>
    </div>
  )
}

export default RegisterForm
