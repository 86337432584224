import { RefObject, useEffect } from 'react'

export function useOutsideClick(
  ref: RefObject<HTMLElement>,
  handler?: (e: MouseEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        handler?.(event)
      }
    }
    window.addEventListener('mousedown', listener, true)
    return () => window.removeEventListener('mousedown', listener)
  }, [])
}
