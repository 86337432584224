import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Search(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8125 10.9926L15.7567 15.0949C16.0904 15.442 16.0794 15.9953 15.7326 16.329C15.5694 16.4861 15.3544 16.5725 15.1278 16.5725C14.8883 16.5725 14.6649 16.4773 14.4988 16.3048L10.5246 12.1714C9.39609 12.9645 8.07619 13.3823 6.69113 13.3823C3.0017 13.3823 0 10.3806 0 6.69113C0 3.0017 3.0017 0 6.69113 0C10.3806 0 13.3823 3.0017 13.3823 6.69113C13.3823 8.27023 12.8266 9.78708 11.8125 10.9926ZM11.6367 6.69113C11.6367 3.96406 9.41814 1.74552 6.69107 1.74552C3.964 1.74552 1.74546 3.96406 1.74546 6.69113C1.74546 9.4182 3.964 11.6367 6.69107 11.6367C9.41814 11.6367 11.6367 9.4182 11.6367 6.69113Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
