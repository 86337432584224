import { usePageTitle } from 'hooks'
import TableList from './TableList'

function OrderHistory() {
  usePageTitle('Order History | Broadflow Streams')

  return (
    <div className="p-4">
      <div className="text-2xl font-medium mt-2 mb-4">Order History</div>
      <TableList />
    </div>
  )
}

export default OrderHistory
