import client, { ResponseMeta } from './client'

export interface Recipient {
  email: string
  email_verified_at: string | string
  id: number
  name: string
  phone: string | null
  phone_verified_at: string | null
  phone_verify_token: string | null
}

export type RepicientResponse = {
  data: Recipient[]
  meta: ResponseMeta
}

export const getRecipients = ({
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = 10,
  search = '',
  currentPage = 1,
  hasPhone = 0,
  hasEmail = 0
}: {
  sortBy?: string
  sortDirection?: string
  pageSize?: number
  search?: string
  currentPage?: number
  hasPhone?: number
  hasEmail?: number
}) => {
  return client.get<RepicientResponse>(
    `/api/recipients?limit=${pageSize}&order_by=${sortBy}&sort=${sortDirection}&filters[has_phone]=${hasPhone}&filters[has_email]=${hasEmail}&filters[search]=${search}&page=${currentPage}`
  )
}

// ------------------------------------------------------------------------- //

export type CreateRecipientResponse = {
  data: Recipient
}

export const createRecipient = (payload: {
  name: string
  email: string
  phone?: string
  account_id: number
  wants_email: boolean
  wants_sms: boolean
  wants_whatsapp: boolean
}) => {
  return client.post<CreateRecipientResponse>('api/recipients', payload)
}

// ------------------------------------------------------------------------- //

export type EditRecipientResponse = {
  data: Recipient
}

export const editRecipient = (
  id: number,
  payload: {
    name: string
    email: string
    phone?: string
    account_id: number
  }
) => {
  return client.put<EditRecipientResponse>(`/api/recipients/${id}`, payload)
}

// ------------------------------------------------------------------------- //

export const deleteRecipient = (id: number) => {
  return client.delete(`/api/recipients/${id}`)
}

export const verifyRecipientEmail = (payload: { id: string; hash: string }) => {
  return client.get(`/api/recipient/email/verify/${payload.id}/${payload.hash}`)
}
