import cx from 'classnames'

import { Popover } from 'components'
import {
  getSlotsOfLayout,
  renderChildrenClassLayout,
  renderParentClassLayout
} from 'utils/layout'

interface Props {
  selectedLayout: number
  onClose: () => void
  onLayoutSelect: (layout: number) => void
}
export const ChangeLayout = ({
  selectedLayout,
  onClose,
  onLayoutSelect
}: Props) => {
  return (
    <Popover.Content className="p-0 w-[15.75rem]" onClose={onClose}>
      <div className="grid grid-cols-4 auto-rows-fr p-2">
        {Array.from({ length: 10 }).map((_, index) => {
          const currentLayout = index + 1
          const active = selectedLayout === currentLayout
          const slots = getSlotsOfLayout(currentLayout)
          return (
            <div
              key={index}
              className="p-2 hover:bg-[#e6e7ff] rounded-lg group hover:cursor-pointer"
              onClick={() => {
                onLayoutSelect(currentLayout)
                onClose()
              }}
            >
              <div
                className={cx(
                  'p-[1px] border border-[#dedede]  h-[1.875rem] bg-white group-hover:bg-[#dbdcff]',
                  active && 'bg-[#dbdcff]'
                )}
              >
                <div
                  className={cx(
                    'grid gap-[1px] h-full',
                    renderParentClassLayout(currentLayout)
                  )}
                >
                  {Array.from({ length: slots }).map((__, idx) => (
                    <div
                      key={idx}
                      className={cx(
                        'bg-[#dedede] group-hover:bg-[#acb0f8]',
                        active && 'bg-[#acb0f8]',
                        renderChildrenClassLayout(currentLayout, idx + 1)
                      )}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Popover.Content>
  )
}
