import { Navigate, useLocation } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { usePageTitle } from 'hooks'
import useStore from 'store'

import ResetPasswordForm from './ResetPasswordForm'
import SendResetLinkForm from './SendResetLinkForm'

function ResetPassword() {
  usePageTitle('Reset Password | Broadflow Streams Cloud Video Surveillance')

  const user = useStore(({ auth }) => auth.currentUser, shallow)
  const { search } = useLocation()

  if (!!user) {
    return <Navigate to="/" />
  }

  const token = search.includes('?token=') ? search.replace('?token=', '') : ''
  return token ? <ResetPasswordForm token={token} /> : <SendResetLinkForm />
}

export default ResetPassword
