import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Live({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="20"
        height="11"
        viewBox="0 0 20 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x="0.667236"
          y="1.08679"
          width="18.6813"
          height="8.00627"
          fill="white"
        />
        <path
          d="M18.4929 0H1.50742C0.674905 0 6.10352e-05 0.674844 6.10352e-05 1.5072V8.92006C6.10352e-05 9.75257 0.674905 10.4274 1.50742 10.4274H18.4927C19.3252 10.4274 20.0001 9.75257 20.0001 8.92006V1.5072C20.0001 0.674844 19.3252 0 18.4929 0ZM4.9569 8.49345H2.58281C2.22706 8.49345 1.93859 8.20498 1.93859 7.84907V2.57835C1.93859 2.2226 2.22706 1.93397 2.58281 1.93397C2.93872 1.93397 3.22719 2.22244 3.22719 2.57835V7.20468H4.9569C5.31281 7.20468 5.60128 7.49316 5.60128 7.84907C5.60128 8.20498 5.31281 8.49345 4.9569 8.49345ZM7.59763 7.84907C7.59763 8.20498 7.30916 8.49345 6.95325 8.49345C6.59734 8.49345 6.30886 8.20498 6.30886 7.84907V2.57835C6.30886 2.2226 6.59734 1.93397 6.95325 1.93397C7.30916 1.93397 7.59763 2.22244 7.59763 2.57835V7.84907ZM13.0192 2.76469L11.4279 8.03525C11.4278 8.03574 11.4276 8.03623 11.4274 8.03672C11.2546 8.62653 10.3736 8.61203 10.1947 8.03672C10.1945 8.03623 10.1944 8.03574 10.1942 8.03525L8.60297 2.76469C8.50019 2.42393 8.69289 2.06444 9.03365 1.96149C9.37408 1.85871 9.7339 2.05141 9.83669 2.39217L10.8111 5.61946L11.7855 2.39217C11.8884 2.05141 12.2479 1.85855 12.5887 1.96149C12.9293 2.06444 13.1221 2.42393 13.0192 2.76469ZM16.9834 4.23752C17.3393 4.23752 17.6278 4.526 17.6278 4.88191C17.6278 5.23765 17.3393 5.52629 16.9834 5.52629H15.1548V7.20468H17.3305C17.6864 7.20468 17.9749 7.49316 17.9749 7.84907C17.9749 8.20498 17.6864 8.49345 17.3305 8.49345H14.5104C14.1545 8.49345 13.866 8.20498 13.866 7.84907V2.57835C13.866 2.2226 14.1545 1.93397 14.5104 1.93397H17.4173C17.7731 1.93397 18.0617 2.22244 18.0617 2.57835C18.0617 2.93426 17.7731 3.22273 17.4173 3.22273H15.1546V4.23752H16.9834Z"
          fill="url(#paint0_linear_8557_2062)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8557_2062"
            x1="6.10352e-05"
            y1="0"
            x2="6.10352e-05"
            y2="10.4274"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
