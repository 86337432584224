import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function WifiOn(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <g clipPath="url(#clip0_1_1977)">
          <path
            d="M4.60806 11.048C3.73606 10.184 3.20006 8.98403 3.20006 7.65603C3.20006 6.32803 3.73606 5.12803 4.60806 4.26403L5.74406 5.40003C5.16006 5.97603 4.80006 6.77603 4.80006 7.65603C4.80006 8.53603 5.16006 9.33603 5.73606 9.92003L4.60806 11.048ZM11.3921 11.048C12.2641 10.184 12.8001 8.98403 12.8001 7.65603C12.8001 6.32803 12.2641 5.12803 11.3921 4.26403L10.2561 5.40003C10.8401 5.97603 11.2001 6.77603 11.2001 7.65603C11.2001 8.53603 10.8401 9.33603 10.2641 9.92003L11.3921 11.048ZM8.00006 6.05603C7.12006 6.05603 6.40006 6.77603 6.40006 7.65603C6.40006 8.53603 7.12006 9.25603 8.00006 9.25603C8.88006 9.25603 9.60006 8.53603 9.60006 7.65603C9.60006 6.77603 8.88006 6.05603 8.00006 6.05603ZM14.4001 7.65603C14.4001 9.42403 13.6801 11.024 12.5201 12.176L13.6561 13.312C15.1041 11.864 16.0001 9.86403 16.0001 7.65603C16.0001 5.44803 15.1041 3.44803 13.6561 2.00003L12.5201 3.13603C13.6801 4.28803 14.4001 5.88803 14.4001 7.65603ZM3.48006 3.13603L2.34406 2.00003C0.896061 3.44803 6.10352e-05 5.44803 6.10352e-05 7.65603C6.10352e-05 9.86403 0.896061 11.864 2.34406 13.312L3.48006 12.176C2.32006 11.024 1.60006 9.42403 1.60006 7.65603C1.60006 5.88803 2.32006 4.28803 3.48006 3.13603Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_1977">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
