import { FC } from 'react'
import cx from 'classnames'

type Props = {
  type?: 'primary' | 'success' | 'danger'
} & JSX.IntrinsicElements['div']

export const Dot: FC<Props> = ({ type, className, children, ...props }) => {
  return (
    <div
      {...props}
      className={cx(
        'min-w-[0.25rem] min-h-[0.25rem] rounded-full text-center',
        `bg-${type || 'white'}`,
        className
      )}
    >
      {children}
    </div>
  )
}
