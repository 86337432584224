import { ReactNode } from 'react'

import { Close, Delete } from 'icons/outline'
import { Button, Modal, ModalProps } from 'components'

interface Props extends ModalProps {
  confirmText: ReactNode
  warnText?: ReactNode
  onConfirm: () => void
  confirmButtonText?: ReactNode
}

function ModalConfirmDeleteWithIcon({
  confirmText,
  warnText,
  onConfirm,
  confirmButtonText = 'Delete',
  ...props
}: Props) {
  return (
    <Modal {...props} className="w-[19.37500rem]">
      <div className="w-full flex justify-end">
        <Close className="w-3.5 h-3.5 cursor-pointer" onClick={props.onClose} />
      </div>
      <div className="mt-2 mb-6 flex justify-center">
        <Delete className="w-16 h-16 text-danger" />
      </div>
      <div className="w-full text-center mb-8">
        <div className="text-base font-medium mb-2">{confirmText}</div>
        <div className="text-[0.8125rem] text-light-secondary">{warnText}</div>
      </div>
      <div className="flex flex-col gap-3">
        <Button variant="primary" size="large" block>
          Cancel
        </Button>
        <Button size="large" block onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  )
}

export default ModalConfirmDeleteWithIcon
