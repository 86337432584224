import { Fragment, useState } from 'react'
import { Link, useNavigate, useParams, Navigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import { AxiosResponse } from 'axios'

import { LICENSE_TAG_COLOR } from 'configs/tagColors'
import {
  ChevronLeft,
  ChevronRight,
  Delete,
  MinusCircle,
  More,
  Pencil,
  Plus
} from 'icons/outline'
import { DeviceIcon } from 'icons/utils'
import { useAsync, usePageTitle } from 'hooks'
import { Button, Popover, Spinner, Tabs, Tag } from 'components'
import MenuItem from 'shared/MenuItem'
import RouteTabs, { RouteTab } from 'shared/RouteTabs'
import ModalConfirmDeleteWithInput from 'shared/Modals/Confirm/ConfirmDeleteWithInput'
import ModalLivestream from 'shared/Modals/Players/Livestream'
import ModalEditDevice from 'shared/Modals/Device/EditDevice'
import toast from 'utils/toast'
import { isCamera, isSensor } from 'utils/device'
import { Device, deleteDevice, updateDevice } from 'services/devices'
import useStore, { Store } from 'store'

import ActivityLog from './ActivityLog'
import DeviceDetails from './DeviceDetails'
import DeviceSchedules from './DeviceSchedules'
import InboundNotifications from './InboundNotifications'
import VideoVault from './VideoVault'
import TemperatureReport from './TemperatureReport'

const tabs = [
  {
    key: 'device-details',
    tab: 'Device Details',
    Component: DeviceDetails
  },
  {
    key: 'device-schedules',
    tab: 'Device Schedules',
    Component: DeviceSchedules
  },
  {
    key: 'inbound-notifications',
    tab: 'Inbound Notifications',
    Component: InboundNotifications
  },
  {
    key: 'alarms',
    tab: 'Alarms',
    Component: InboundNotifications
  },
  {
    key: 'temperature-report',
    tab: 'Temperature Report',
    Component: TemperatureReport
  },
  {
    key: 'video-vault',
    tab: 'Video Vault',
    Component: VideoVault
  },
  {
    key: 'activity-log',
    tab: 'Activity Log',
    Component: ActivityLog
  }
]

const mapState = (state: Store, deviceId?: string) => ({
  device: state.device.devices.find(d => d.id === +(deviceId || 0)),
  updateDevice: state.device.updateDevice,
  updateUsedCount: state.stat.updateUsedCount,
  removeDevice: state.device.removeDevice
})

function DeviceDetail() {
  usePageTitle('Broadflow')

  const navigate = useNavigate()
  const { id } = useParams() as { id?: string }
  const [visible, setVisible] = useState('')
  const [viewLivestream, setViewLivestream] = useState<Device>()

  const deleteAsync = useAsync({ showNotifOnError: true })
  const updateAsync = useAsync<AxiosResponse<{ data: Device }>>({
    showNotifOnError: true
  })
  const store = useStore(state => mapState(state, id), shallow)

  const handleDeleteDevice = async () => {
    setVisible('')
    await deleteAsync.execute(deleteDevice(+id!))
    store.removeDevice(+id!)
    toast.success({ title: 'Device deleted' }, { position: 'bottom-center' })
  }

  const handleToggleLicense = (license: boolean) => {
    const type = isCamera(store.device!.type) ? 'Camera' : 'Sensor'
    setVisible('')
    updateAsync
      .execute(updateDevice(store.device!.id, { is_licensed: license }))
      .then(() => {
        store.updateDevice(store.device!.id, { is_licensed: license ? 1 : 0 })
        store.updateUsedCount({ camera_licenses: license ? 1 : -1 })
        toast.success(
          {
            title: license
              ? `Assigned ${type} License to “${store.device!.name}”`
              : `${type} License Removed`
          },
          { position: 'bottom-center' }
        )
      })
  }

  const renderTab = (tab: RouteTab) => {
    const Tab = (
      <Tabs.TabPane tab={tab.tab} key={tab.key}>
        <tab.Component
          deviceId={id}
          device={store.device}
          toggleLicense={handleToggleLicense}
        />
      </Tabs.TabPane>
    )
    if (tab.key === 'video-vault') return isCamera(type) ? Tab : null
    if (tab.key === 'temperature-report') return isSensor(type) ? Tab : null
    if (tab.key === 'inbound-notifications') return !isSensor(type) ? Tab : null
    if (tab.key === 'alarms') return isSensor(type) ? Tab : null
    return Tab
  }

  if (deleteAsync.isLoading || updateAsync.isLoading) {
    return <Spinner className="h-[calc(100vh_-_3.125rem)]" />
  }

  if (deleteAsync.isSuccess) {
    return <Navigate to="/devices/?tab=device-list" replace />
  }

  if (!store.device) {
    return <Navigate to="/404" replace />
  }

  const { name, is_licensed, type } = store.device

  const renderMoreOptions = (onClose: () => void) => {
    const handleClick = (fn: () => void) => () => {
      fn()
      onClose()
    }
    return (
      <Popover.Content className="min-w-[14rem] !py-1 !px-2" onClose={onClose}>
        {(isCamera(type) || isSensor(type)) && !!!is_licensed && (
          <MenuItem
            className="mb-1"
            onClick={handleClick(() => handleToggleLicense(true))}
          >
            <Plus className="w-3.5 h-3.5" /> Assign License
          </MenuItem>
        )}
        <MenuItem onClick={handleClick(() => setVisible('edit'))}>
          <Pencil className="w-3.5 h-3.5" /> Edit Device
        </MenuItem>
        {(!isSensor(type) || !!is_licensed) && (
          <div className="border-b border-light-stroke my-1" />
        )}
        {(isCamera(type) || isSensor(type)) && !!is_licensed && (
          <MenuItem
            className="mb-1 text-danger"
            onClick={handleClick(() => setVisible('remove-license'))}
          >
            <MinusCircle className="w-3.5 h-3.5" /> Unassign Camera License
          </MenuItem>
        )}
        {!isSensor(type) && (
          <MenuItem
            className="text-danger"
            onClick={handleClick(() => setVisible('delete'))}
          >
            <Delete className="w-3.5 h-3.5" /> Delete Device
          </MenuItem>
        )}
      </Popover.Content>
    )
  }

  return (
    <Fragment>
      <div className="p-4 pb-6 flex items-center gap-2 text-sm">
        <Link
          to="/devices/?tab=device-list"
          className="text-primary font-medium"
        >
          Device Management
        </Link>
        <ChevronRight className="text-light-secondary w-2.5 h-2.5 shrink-0" />
        <div>{name}</div>
      </div>
      <div className="px-4 pb-6 flex flex-wrap justify-between items-center gap-4">
        <div className="inline-flex items-center gap-2">
          <Button onClick={() => navigate('/devices/?tab=device-list')}>
            <ChevronLeft />
          </Button>
          <div className="text-xl font-medium">{name}</div>
          {!!is_licensed && <Tag {...LICENSE_TAG_COLOR}>Licensed</Tag>}
        </div>
        <div className="inline-flex items-center gap-2.5">
          <Popover placement="bottom-end" content={renderMoreOptions}>
            <Button children={<More />} />
          </Popover>
          {isCamera(type) && (
            <Button
              disabled={!is_licensed}
              onClick={
                !is_licensed ? undefined : () => setViewLivestream(store.device)
              }
            >
              <DeviceIcon type="Security Camera" className="shrink-0" />
              View Livestream
            </Button>
          )}
        </div>
      </div>
      <RouteTabs tabs={tabs} className="mt-2">
        {routeTabs => routeTabs.map(renderTab)}
      </RouteTabs>
      <AnimatePresence initial={false}>
        {visible === 'edit' && (
          <ModalEditDevice
            device={store.device}
            onClose={() => setVisible('')}
          />
        )}
        {!!viewLivestream && (
          <ModalLivestream
            device={viewLivestream}
            onClose={() => setViewLivestream(undefined)}
          />
        )}
        {visible === 'remove-license' && (
          <ModalConfirmDeleteWithInput
            modalTitle={`Remove ${
              isCamera(type) ? 'camera' : 'sensor'
            } license`}
            warnText={
              isCamera(type)
                ? "After removed the license, this device's recorded videos will be deleted"
                : undefined
            }
            value={store.device.name}
            inputLabel={
              <Fragment>
                Enter device name <b>"{store.device.name}"</b> to remove license
              </Fragment>
            }
            okText="Remove License"
            onConfirm={() => handleToggleLicense(false)}
            onClose={() => setVisible('')}
          />
        )}
        {visible === 'delete' && (
          <ModalConfirmDeleteWithInput
            modalTitle="Delete Device"
            warnText="After removed the device, all data of this device will be deleted"
            value={name}
            inputLabel={
              <Fragment>
                Enter device name <b>"{name}"</b> to delete
              </Fragment>
            }
            onConfirm={handleDeleteDevice}
            onClose={() => setVisible('')}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default DeviceDetail
