import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function Bridge(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="15"
        height="12"
        viewBox="0 0 15 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M0.649212 5.27309H4.69833C4.89231 5.27309 5.04956 5.43034 5.04956 5.62431V7.43941C5.04956 7.63339 5.2068 7.79064 5.40078 7.79064H8.99866C9.19264 7.79064 9.34989 7.63339 9.34989 7.43941V5.62431C9.34989 5.43034 9.50714 5.27309 9.70111 5.27309H13.7502C14.0049 5.27309 14.1749 5.01049 14.0707 4.77812L12.2462 0.711138C12.1613 0.521801 11.9731 0.399963 11.7656 0.399963H2.6339C2.42637 0.399963 2.23819 0.521801 2.15322 0.711138L0.328784 4.77812C0.224525 5.01049 0.394512 5.27309 0.649212 5.27309Z"
          fill="currentColor"
        />
        <path
          d="M14.0487 5.97552H10.4038C10.2098 5.97552 10.0526 6.13277 10.0526 6.32675V8.14185C10.0526 8.33583 9.89534 8.49307 9.70136 8.49307H4.69861C4.50463 8.49307 4.34739 8.33583 4.34739 8.14185V6.32675C4.34739 6.13277 4.19014 5.97552 3.99616 5.97552H0.351224C0.157247 5.97552 0 6.13274 0 6.32672V10.6597C0 10.9507 0.235884 11.1865 0.526836 11.1865H13.8732C14.1641 11.1865 14.4 10.9507 14.4 10.6597V6.32672C14.4 6.13274 14.2427 5.97552 14.0487 5.97552Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
