import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function ArrowRise(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M2.9998 0L6.43946 3.43946L0 9.87893L2.12107 12L8.56054 5.56054L12 9.0002V0L2.9998 0Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
