import { useReducer } from 'react'

import { useLocalStorage, usePageTitle } from 'hooks'
import { idGenerator } from 'utils/functions'
import { Device } from 'services/devices'

import DeviceMenuList from './DeviceMenuList'
import PlaybackTabs from './PlaybackTabs'
import PlaybackTab from './PlaybackTabs/PlaybackTab'
import PlaybackControls from './PlaybackControls'
import PlaybackTimeline from './PlaybackControls/PlaybackTimeline'
import PlaybackContextProvider from './PlaybackContext'

interface State {
  expanded: boolean
  activeKey: string
  devices: { [key: string]: (Device | null)[] }
}

interface Tab {
  key: string
  name: string
  layout: number
}

const genPlaybackId = idGenerator('playback')

const newTab = (name = 'Page 1', layout = 2): Tab => ({
  key: genPlaybackId.next().value as string,
  name,
  layout
})

const fillEmptyDevices = (
  layout: number,
  prevDevices: (Device | null)[] = []
) => {
  return Array.from({ length: layout * layout }).map(
    (_, index) => prevDevices[index] || null
  )
}

const initState = (tabs: Tab[]): State => ({
  expanded: true,
  activeKey: tabs[0].key,
  devices: tabs.reduce((acc, tab) => {
    acc[tab.key] = fillEmptyDevices(tab.layout)
    return acc
  }, {} as State['devices'])
})

function Livestream() {
  usePageTitle('Playback | Broadflow Streams')

  const [tabs, setTabs] = useLocalStorage<Tab[]>('APP/PLAYBACK', [newTab()])
  const [{ expanded, activeKey, devices }, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState(tabs)
  )

  const handleCreateTab = () => {
    const tab = newTab(`Page ${tabs.length + 1}`)
    setTabs([...tabs, tab])
    setState({
      activeKey: tab.key,
      devices: { ...devices, [tab.key]: fillEmptyDevices(tab.layout) }
    })
  }

  const handleEditTab = (key: string, tab: Partial<Tab>) => {
    setTabs(prev => prev.map(t => (t.key === key ? { ...t, ...tab } : t)))
  }

  const handleTabLayoutChange = (key: string, layout: number) => {
    handleEditTab(key, { layout })
    const activeTab = tabs.find(tab => tab.key === activeKey)!
    setState({
      devices: {
        ...devices,
        [key]: fillEmptyDevices(
          layout,
          activeTab.layout < layout ? devices[key] : []
        )
      }
    })
  }

  const handleDeleteTab = (key: string) => {
    const newTabs = tabs.filter(tab => tab.key !== key)
    setTabs(newTabs)
    setState({ activeKey: newTabs[newTabs.length - 1].key })
  }

  const handleDeviceChange = (key: string, index: number) => {
    return (device: Device | null) => {
      setState({
        devices: {
          ...devices,
          [key]: devices[key].map((d, idx) => (idx === index ? device : d))
        }
      })
    }
  }

  return (
    <div className="relative flex h-[calc(100vh_-_3.125rem)] overflow-hidden">
      <DeviceMenuList
        expanded={expanded}
        selectedDevices={devices[activeKey]}
      />
      <PlaybackTabs
        expanded={expanded}
        onToggleExpand={() => setState({ expanded: !expanded })}
        tabs={tabs}
        activeTabKey={activeKey}
        onTabChange={(key: string) => setState({ activeKey: key })}
        onCreateTab={handleCreateTab}
        onEditTab={handleEditTab}
        onTabLayoutChange={handleTabLayoutChange}
        onDeleteTab={handleDeleteTab}
      >
        {props => (
          <PlaybackContextProvider layout={props.layout}>
            <PlaybackTab
              {...props}
              devices={devices[props.tabKey]}
              onDeviceChange={handleDeviceChange}
            />
            <PlaybackControls devices={devices[props.tabKey]} />
            <PlaybackTimeline devices={devices[props.tabKey]} />
          </PlaybackContextProvider>
        )}
      </PlaybackTabs>
    </div>
  )
}

export default Livestream
