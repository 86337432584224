import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { Plus, Search } from 'icons/outline'
import { Button, Input, Pagination } from 'components'
import ModalCreateNewTag from 'shared/Modals/Tag/CreateNewTag'

interface Props {
  loading: boolean
  search: string
  onSearchChange: (s: string) => void
  currentPage: number
  totalPage: number
  onPageChange: (p: number) => void
  onRefetch: () => void
}

function TableToolbar({
  loading,
  search,
  onSearchChange,
  currentPage,
  totalPage,
  onPageChange,
  onRefetch
}: Props) {
  const [createTag, setCreateTag] = useState(false)

  const handleSearch: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      value !== search && onSearchChange(value)
    }
  }

  return (
    <div className="flex items-center justify-between mb-4">
      <Input
        suffix={<Search />}
        className="w-[22.5rem]"
        onKeyDown={loading ? undefined : handleSearch}
        placeholder="Search tag by name"
      />
      <div className="inline-flex gap-2">
        <Pagination.ButtonGroup
          loading={loading}
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={loading ? undefined : onPageChange}
        />
        <Button
          variant="primary"
          className="w-[9rem]"
          onClick={() => setCreateTag(true)}
        >
          <Plus /> Create Tag
        </Button>
      </div>
      <AnimatePresence initial={false}>
        {!!createTag && (
          <ModalCreateNewTag
            onSuccess={onRefetch}
            onClose={() => setCreateTag(false)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default TableToolbar
