import { FC, ReactNode } from 'react'
import cx from 'classnames'

export type Props = {
  children?: ReactNode
  className?: string
  value: string | number
  disabled?: boolean
  active?: boolean
}

const activeClassName =
  '!bg-primary-lighten !border-light-primary-stroke !text-primary'

export const SelectButtonItem: FC<Props> = ({
  className,
  value,
  disabled,
  children,
  active,
  ...props
}) => {
  return (
    <button
      type="button"
      value={value}
      className={cx(
        'base-transition border border-light-stroke rounded-lg min-h-[2rem] outline-0 bg-white',
        disabled &&
          'bg-light-disabled pointer-events-none text-light-primary/50',
        active && activeClassName,
        className
      )}
      {...props}
    >
      <div className="w-full h-full flex justify-center items-center p-2 text-sm gap-1.5">
        {children}
      </div>
    </button>
  )
}
