import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'
import cx from 'classnames'

import { Delete, EyeOpen, More, Pencil } from 'icons/outline'
import { DeviceIcon } from 'icons/utils'
import { useAsync } from 'hooks'
import { Button, Popover } from 'components'
import MenuItem from 'shared/MenuItem'
import ModalConfirmDeleteWithInput from 'shared/Modals/Confirm/ConfirmDeleteWithInput'
import ModalEditDevice from 'shared/Modals/Device/EditDevice'
import toast from 'utils/toast'
import { deleteDevice, Device } from 'services/devices'
import useStore, { Store } from 'store'

interface Props {
  className?: string
  device: Device
}

const mapState = (state: Store) => ({
  removeDevice: state.device.removeDevice,
  updateUsedCount: state.stat.updateUsedCount
})

function OtherDevice({ device, className }: Props) {
  const navigate = useNavigate()
  const [visible, setVisible] = useState('')

  const deleteDeviceAsync = useAsync({ showNotifOnError: true })
  const { removeDevice, updateUsedCount } = useStore(mapState, shallow)

  const { id, type, name, is_licensed } = device

  const handleDeleteDevice = () => {
    setVisible('')
    deleteDeviceAsync.execute(deleteDevice(+id!))
    removeDevice(+id!)
    updateUsedCount({ devices: -1, camera_licenses: is_licensed ? -1 : 0 })
    toast.success({ title: 'Device deleted' }, { position: 'bottom-center' })
  }

  const renderMoreOptions = (onClose: () => void) => {
    const onClick = (fn: Function) => () => {
      onClose()
      fn()
    }
    return (
      <Popover.Content className="w-48" onClose={onClose}>
        <MenuItem
          className="mb-1 text-[0.8125rem]"
          onClick={onClick(() => navigate(`/devices/${id}?tab=device-details`))}
        >
          <EyeOpen className="w-3.5 h-3.5 shrink-0" /> View Device Detail
        </MenuItem>
        <MenuItem
          className="text-[0.8125rem]"
          onClick={onClick(() => setVisible('edit'))}
        >
          <Pencil className="w-3.5 h-3.5 shrink-0" /> Edit Device
        </MenuItem>
        <div className="border-b border-light-stroke my-2" />
        <MenuItem
          className="text-[0.8125rem] text-danger"
          onClick={onClick(() => setVisible('delete'))}
        >
          <Delete className="w-3.5 h-3.5 shrink-0" /> Delete Device
        </MenuItem>
      </Popover.Content>
    )
  }

  return (
    <Fragment>
      <MenuItem className={cx('flex items-center gap-2 w-full', className)}>
        <DeviceIcon
          type={type}
          className="w-3.5 h-3.5 shrink-0 text-[#BDBDBD]"
        />
        <div className="text-[0.8125rem]">{name}</div>
        <Popover
          className="ml-auto"
          placement="bottom-end"
          content={onClose => renderMoreOptions(onClose)}
        >
          <Button>
            <More />
          </Button>
        </Popover>
      </MenuItem>
      <AnimatePresence initial={false}>
        {visible === 'edit' && (
          <ModalEditDevice device={device} onClose={() => setVisible('')} />
        )}
        {visible === 'delete' && (
          <ModalConfirmDeleteWithInput
            modalTitle="Delete Device"
            warnText="All data related to this device will also be deleted"
            value={name}
            inputLabel={
              <Fragment>
                Enter device name <b>"{name}"</b> to delete
              </Fragment>
            }
            onConfirm={handleDeleteDevice}
            onClose={() => setVisible('')}
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default OtherDevice
