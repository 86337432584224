import { Pencil } from 'icons/outline'
import { InfoCircle } from 'icons/filled'
import { ResourceIcon } from 'icons/utils'
import { Button, Table } from 'components'

const data = [
  { id: 1, name: 'test', deviceType: 'camera' },
  { id: 2, name: 'test', deviceType: 'camera' },
  { id: 3, name: 'test', deviceType: 'camera' },
  { id: 4, name: 'test', deviceType: 'camera' },
  { id: 5, name: 'test', deviceType: 'camera' }
]

function AffectedDevices() {
  const columns = [
    {
      title: 'id',
      render: (row: any) => row.id
    },
    {
      title: 'name',
      render: (row: any) => row.name
    },
    {
      title: 'device type',
      render: (row: any) => row.deviceType
    }
  ]

  return (
    <div className="relative w-full p-4">
      <div className="flex items-center justify-between gap-4 mb-4">
        <div className="inline-flex items-center gap-2">
          <ResourceIcon
            iconType="primary"
            type="device"
            className="w-4 h-4 shrink-0"
          />
          <div className="font-medium">
            Devices affected by this IoT Automation
          </div>
        </div>
        <Button variant="ternary" className="w-20">
          <Pencil /> Edit
        </Button>
      </div>
      {true && <Table rowKey="id" columns={columns} data={data} />}
      {false && (
        <div className="text-[0.8125]rem">
          <div className="mb-2">This IoT Automation applies on all devices</div>
          <div className="flex items-center gap-2 text-success">
            <InfoCircle className="w-4 h-4 shrink-0" type="success" /> It will
            also apply to all new devices in the future
          </div>
        </div>
      )}
    </div>
  )
}

export default AffectedDevices
