import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function TextFile(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="11"
        height="12"
        viewBox="0 0 11 12"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M3.05176e-05 -1.52588e-05V12H5.6263V7.42871H10.1976V-1.52588e-05H3.05176e-05ZM4.94642 8.13184H2.30539V7.42871H4.94642V8.13184ZM7.89992 6.10592H2.29773V5.4028H7.89992V6.10592ZM7.89992 4.05153H2.29773V3.34841H7.89992V4.05153Z"
          fill="currentColor"
        />
        <path
          d="M6.32928 8.13187V11.7941L9.99146 8.13187H6.32928Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
