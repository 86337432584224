import { Device } from 'services/devices'
import { Recipient } from 'services/recipients'
import { NotifChannel, Tag } from 'services/tags'

export interface OptionValue {
  label: string
  value: number
}

export interface FormValues {
  id: number
  tagDetails: {
    name: string
    stroke_color: string
    text_color: string
    color: string
    subject_keywords: string
    body_keywords: string
    search_in: string
    has_attachments: boolean
  }
  affectedDevices: {
    apply_to_all: boolean
    devices: OptionValue[]
  }
  recipients: {
    is_blocked: boolean
    channels: NotifChannel[]
    phone_recipients: OptionValue[]
    email_recipients: OptionValue[]
    whatsapp_recipients: OptionValue[]
    webhook: string
    slack_webhook: string
    teams_webbhook: string
    pagerduty_integration_key: string
  }
}

export type FormKey = keyof FormValues & string

export const initFormValues: FormValues = {
  id: 0,
  tagDetails: {
    name: '',
    stroke_color: '',
    text_color: '',
    color: '',
    subject_keywords: '',
    body_keywords: '',
    search_in: 'subject_or_body',
    has_attachments: false
  },
  affectedDevices: {
    apply_to_all: false,
    devices: []
  },
  recipients: {
    is_blocked: false,
    channels: [],
    phone_recipients: [],
    email_recipients: [],
    whatsapp_recipients: [],
    webhook: '',
    slack_webhook: '',
    teams_webbhook: '',
    pagerduty_integration_key: ''
  }
}

export const mapDevices = (devices: Device[]) => {
  return devices.map(({ id, name }) => ({ value: id, label: name }))
}

export const mapRecipients = (recs: Recipient[]) => {
  return recs.map(({ id, name }) => ({ value: id, label: name }))
}

export const mapInitFormValues = (data: Tag): FormValues => ({
  id: data.id,
  tagDetails: {
    name: data.name,
    stroke_color: data.stroke_color!,
    text_color: data.text_color!,
    color: data.color!,
    subject_keywords: data.subject_keywords,
    body_keywords: data.body_keywords,
    search_in: data.search_in,
    has_attachments: !!data.has_attachments
  },
  affectedDevices: {
    apply_to_all: !!data.apply_to_all,
    devices: mapDevices(data.devices || [])
  },
  recipients: {
    is_blocked: !!data.is_blocked,
    channels: data.channels,
    phone_recipients: mapRecipients(data.phone_recipients || []),
    email_recipients: mapRecipients(data.email_recipients || []),
    whatsapp_recipients: mapRecipients(data.whatsapp_recipients || []),
    webhook: data.webhook || '',
    slack_webhook: data.slack_webhook || '',
    teams_webbhook: data.teams_webbhook || '',
    pagerduty_integration_key: data.pagerduty_integration_key || ''
  }
})
