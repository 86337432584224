import { Dispatch, useEffect } from 'react'
import { FieldData } from 'rc-field-form/lib/interface'

import { Cloud } from 'icons/filled'
import { Form, FormField, Input } from 'components'
import toast from 'utils/toast'

import { FormValues } from './FormHelpers'

interface Props {
  loading: boolean
  values: FormValues['cloudSurveillance']
  setValues: Dispatch<Partial<FormValues>>
}

function CloudSurveillance({ loading, values, setValues }: Props) {
  useEffect(() => {
    if (loading) {
      toast.info(
        {
          title: 'Checking for RTSP link, this progress might take a while...'
        },
        { position: 'bottom-center', autoClose: 10000 }
      )
    }
  }, [loading])

  const handleFieldChange = (field: FieldData[]) => {
    const name = (
      field[0].name as string[]
    )[0] as keyof FormValues['cloudSurveillance']
    setValues({ cloudSurveillance: { ...values, [name]: field[0].value } })
  }

  return (
    <div className="min-h-[calc(100vh_-_5.3125rem)] flex flex-col justify-center">
      <Form
        initialValues={values}
        className="w-[35rem] m-auto"
        onFieldsChange={handleFieldChange}
      >
        <div className="mb-6 flex items-center gap-1.5">
          <Cloud className="w-4 h-4 shrink-0" type="primary" />
          <div className="text-base font-medium">Cloud Surveillance</div>
        </div>
        <FormField name="stream_url" label="Enter your device RTSP Link">
          <Input
            block
            size="large"
            placeholder="Enter device RTSP link"
            extra={
              <a
                className="text-xs text-light-secondary underline"
                href="https://broadflow.co/support/notifications"
                target="_blank"
                rel="noreferrer"
              >
                See how to get device RTSP Link
              </a>
            }
          />
        </FormField>
      </Form>
    </div>
  )
}

export default CloudSurveillance
