import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function AddPeople({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_8250_14107)">
          <path
            d="M3.33347 4.5C2.2308 4.5 1.33347 3.60267 1.33347 2.5C1.33347 1.39733 2.2308 0.5 3.33347 0.5C4.43613 0.5 5.33347 1.39733 5.33347 2.5C5.33347 3.60267 4.43613 4.5 3.33347 4.5Z"
            fill="url(#paint0_linear_8250_14107)"
          />
          <path
            d="M4.16688 16.5H2.50021C1.85688 16.5 1.33355 15.9766 1.33355 15.3333V11.7453C1.33355 11.682 1.29821 11.6246 1.24155 11.596L0.64488 11.298C0.247547 11.0993 0.000213623 10.6993 0.000213623 10.2546V7.66663C0.000213623 6.28796 1.12155 5.16663 2.50021 5.16663H4.16688C5.54555 5.16663 6.66688 6.28796 6.66688 7.66663V10.2546C6.66688 10.6993 6.41955 11.0993 6.02221 11.298L5.42555 11.596C5.36888 11.6246 5.33355 11.682 5.33355 11.7453V15.3333C5.33355 15.9766 4.81021 16.5 4.16688 16.5Z"
            fill="url(#paint1_linear_8250_14107)"
          />
          <path
            d="M12.1669 16.5H11.8335C11.1902 16.5 10.6669 15.9767 10.6669 15.3333V13.8333H9.16688C8.52355 13.8333 8.00021 13.31 8.00021 12.6667V12.3333C8.00021 11.69 8.52355 11.1667 9.16688 11.1667H10.6669V9.66667C10.6669 9.02333 11.1902 8.5 11.8335 8.5H12.1669C12.8102 8.5 13.3335 9.02333 13.3335 9.66667V11.1667H14.8335C15.4769 11.1667 16.0002 11.69 16.0002 12.3333V12.6667C16.0002 13.31 15.4769 13.8333 14.8335 13.8333H13.3335V15.3333C13.3335 15.9767 12.8102 16.5 12.1669 16.5Z"
            fill="url(#paint2_linear_8250_14107)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_8250_14107"
            x1="1.33347"
            y1="0.5"
            x2="1.33347"
            y2="4.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8250_14107"
            x1="0.000213623"
            y1="5.16663"
            x2="0.000213623"
            y2="16.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_8250_14107"
            x1="8.00021"
            y1="8.5"
            x2="8.00021"
            y2="16.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <clipPath id="clip0_8250_14107">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.000213623 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
