import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function DoubleArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <g clipPath="url(#clip0_506_1198)">
          <path
            d="M14.9469 7.50034L8.65117 1.20667C8.37492 0.93111 7.92734 0.93111 7.65039 1.20667C7.37414 1.48222 7.37414 1.9298 7.65039 2.20535L13.4468 7.99965L7.65109 13.7939C7.37483 14.0695 7.37483 14.5171 7.65109 14.7933C7.92734 15.0689 8.37561 15.0689 8.65187 14.7933L14.9476 8.49972C15.2196 8.22701 15.2196 7.7724 14.9469 7.50034Z"
            fill="currentColor"
          />
          <path
            d="M8.5037 7.50034L2.20793 1.20667C1.93168 0.93111 1.4841 0.93111 1.20715 1.20667C0.930899 1.48222 0.930899 1.9298 1.20715 2.20535L7.00354 7.99965L1.20785 13.7939C0.931596 14.0695 0.931596 14.5171 1.20785 14.7933C1.4841 15.0689 1.93238 15.0689 2.20863 14.7933L8.50439 8.49972C8.7764 8.22701 8.7764 7.7724 8.5037 7.50034Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_506_1198">
            <rect
              width="14.1516"
              height="14"
              fill="white"
              transform="translate(15.1516 15) rotate(-180)"
            />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
