import { useState } from 'react'
import cx from 'classnames'

import { ClockCircle, Close } from 'icons/outline'
import { Popover } from 'components'
import { dateTimeFormat, formatTime } from 'utils/dateTime'

import Picker from './Picker'
import { lead0 } from 'utils/functions'

interface Props {
  allowClear?: boolean
  size?: 'small' | 'default' | 'large'
  block?: boolean
  className?: string
  format?: string
  value?: string
  placeholder?: string
  onChange?: (timeStr: string) => void
  popupContainer?: () => HTMLElement
}

const sizes = {
  small: 'h-6 py-0 pl-[0.4375rem] text-sm',
  default: 'h-8 py-1 pl-2.5 text-sm',
  large: 'h-10 pl-2.5 py-1.5 text-md'
}

export function TimePicker({
  allowClear,
  size = 'default',
  block,
  className,
  value: propValue = '',
  placeholder,
  onChange,
  popupContainer
}: Props) {
  const [value, setValue] = useState(() => {
    const [h = '', m = ''] = propValue.split(':')
    return { hour: h, minute: m }
  })

  const format = () => {
    return formatTime(value.hour + ':' + value.minute, dateTimeFormat['HH:mm'])
  }

  const handleChange = (value: number, type: 'hour' | 'minute') => {
    setValue(({ hour, minute }) => {
      const newVal = lead0(value).toString()
      const defaultVal = lead0(0).toString()
      const output = {
        hour: type === 'hour' ? newVal : hour || defaultVal,
        minute: type === 'minute' ? newVal : minute || defaultVal
      }
      onChange?.(`${output.hour}:${output.minute}`)
      return output
    })
  }

  const handleClear = () => {
    setValue({ hour: '', minute: '' })
    onChange?.('')
  }

  const hour = !!value.hour ? +value.hour : undefined
  const minute = !!value.minute ? +value.minute : undefined

  return (
    <div
      className={cx(
        'relative border border-light-stroke rounded-lg w-full',
        'base-transition hover:border-primary focus:border-primary outline-0',
        'flex items-center cursor-text bg-white',
        sizes[size],
        block && 'block',
        className
      )}
    >
      <input
        readOnly
        value={hour !== undefined && minute !== undefined ? format() : ''}
        placeholder={placeholder}
        className={cx(
          'appearance-none outline-0 w-full mr-1.5 pointer-events-none',
          'placeholder:text-light-secondary placeholder:font-normal'
        )}
      />
      {allowClear && hour !== undefined && minute !== undefined && (
        <div className="w-3 h-full shrink-0 inline-flex justify-center items-center z-[1]">
          <Close
            className="w-3 h-3 rounded-full bg-[#a7a7a7] p-[3px] text-white cursor-pointer"
            onClick={handleClear}
          />
        </div>
      )}
      <div className="w-8 h-full shrink-0 inline-flex justify-center items-center">
        <ClockCircle className="w-4 h-4 " />
      </div>
      <Popover
        popupContainer={popupContainer}
        placement="bottom-start"
        className="absolute top-0 left-0 w-full h-full"
        content={onClose => (
          <Picker
            hour={hour}
            minute={minute}
            onChange={handleChange}
            onClose={onClose}
          />
        )}
      />
    </div>
  )
}
