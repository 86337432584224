import { ChangeEventHandler, useState } from 'react'
import { AxiosResponse } from 'axios'

import { Attachment, Close } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Modal, ModalProps } from 'components'
import toast from 'utils/toast'
import { FloorPlan, updateFloorPlan } from 'services/floorPlans'

interface Props extends ModalProps {
  floorPlan: FloorPlan
  onSuccess?: (floorPlan: FloorPlan) => void
}

function ModalReplaceFloorPlanImage({ floorPlan, onSuccess, ...props }: Props) {
  const [imgFile, setImgFile] = useState<File>()

  const { isLoading, execute } = useAsync<AxiosResponse<{ data: FloorPlan }>>({
    showNotifOnError: true
  })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleImgChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { files } = e.target
    if (!files || !files.length) {
      return
    }
    setImgFile(files[0])
  }

  const handleSubmit = async () => {
    const res = await execute(updateFloorPlan(floorPlan.id, { image: imgFile }))
    onSuccess?.(res.data.data)
    toast.success(
      { title: 'Floor plan name updated' },
      { position: 'bottom-center' }
    )
    handleClose()
  }

  return (
    <Modal className="!p-0 w-[33.125rem]" {...props} onClose={handleClose}>
      <div className="p-4 flex items-center justify-between border-b border-light-stroke mb-1">
        <div className="text-base font-medium">Replace Floor Plan</div>
        <Close className="shrink-0 cursor-pointer" onClick={handleClose} />
      </div>
      <div className="h-[12.5rem] border border-dashed border-light-stroke rounded-lg bg-light-bg m-4">
        {!imgFile && (
          <div className="relative w-full h-full flex items-center justify-center">
            <input
              type="file"
              accept="image/*"
              onChange={handleImgChange}
              className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
            />
            <div className="text-[0.8125rem] text-light-secondary">
              Click or drag & drop your floor plan here
            </div>
          </div>
        )}
        {!!imgFile && (
          <div className="relative w-full h-full flex flex-col items-center justify-center p-4">
            <div
              className="text-[0.8125rem] inline-flex items-center justify-center gap-2"
              style={{ wordBreak: 'break-word' }}
            >
              <Attachment className="shrink-0 w-3.5 h-3.5 text-light-secondary" />
              <div className="font-medium">{imgFile.name}</div>
            </div>
            <Button
              type="button"
              className="mt-3 w-[7.5rem]"
              variant="secondary-lighten"
              onClick={() => setImgFile(undefined)}
            >
              Remove
            </Button>
          </div>
        )}
      </div>
      <div className="px-4 pb-4 text-right">
        <Button
          variant="primary"
          className="w-[7.5rem]"
          disabled={isLoading || !imgFile}
          onClick={!isLoading && !!imgFile ? handleSubmit : undefined}
        >
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default ModalReplaceFloorPlanImage
