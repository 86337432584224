import shallow from 'zustand/shallow'

import { useAsync } from 'hooks'
import { SelectButton } from 'components'
import { upperFirstLetter } from 'utils/functions'
import toast from 'utils/toast'
import { CurrentUser, updateUnit } from 'services/auth'
import useStore, { Store } from 'store'

type UnitType = 'unit_distance' | 'unit_temperature'

const units = {
  c: 'Celsius (°C)',
  f: 'Fahrenheit (°F)',
  ft: 'Feet (ft)',
  m: 'Meters (m)'
}

const mapState = (state: Store) => ({
  user: state.auth.currentUser,
  updateUser: state.auth.updateUser
})

function UnitSettings() {
  const { user, updateUser } = useStore(mapState, shallow)
  const { execute } = useAsync({ showNotifOnError: true })

  if (!user) return null
  const { unit_temperature, unit_distance } = user

  const handleUnitChange = (key: UnitType, value: CurrentUser[UnitType]) => {
    execute(updateUnit({ [key]: value })).then(() => {
      updateUser({ [key]: value })
      toast.success(
        {
          title: `${upperFirstLetter(
            key.replace('unit_', '')
          )} unit changed to '${units[value]}'`
        },
        { position: 'bottom-center' }
      )
    })
  }

  return (
    <div className="p-[1.3rem]">
      <div className="text-xl mb-5 font-medium">Unit Settings</div>
      <div className="p-6 bg-white rounded-lg border border-[#eee] shadow-[0_1px_2px_rgba(0,0,0,.16)] mb-4">
        <div className="text-[0.9375rem] mb-2 font-medium">Unit Settings</div>
        <div className="text-light-secondary text-[0.8125rem] mb-2">
          Temperature Units
        </div>
        <SelectButton
          className="mb-4"
          value={unit_temperature}
          onChange={value =>
            handleUnitChange('unit_temperature', value as CurrentUser[UnitType])
          }
        >
          <SelectButton.Item value="c">{units.c}</SelectButton.Item>
          <SelectButton.Item value="f">{units.f}</SelectButton.Item>
        </SelectButton>
        <div className="text-light-secondary text-[0.8125rem] mb-2">
          Distance Units
        </div>
        <SelectButton
          className="mb-4"
          value={unit_distance}
          onChange={value =>
            handleUnitChange('unit_distance', value as CurrentUser[UnitType])
          }
        >
          <SelectButton.Item value="ft">{units.ft}</SelectButton.Item>
          <SelectButton.Item value="m">{units.m}</SelectButton.Item>
        </SelectButton>
      </div>
    </div>
  )
}

export default UnitSettings
