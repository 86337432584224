import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Shield({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M15.0914 2.23928C15.0627 2.03299 14.9143 1.8633 14.7136 1.80752L8.28291 0.0191374C8.19123 -0.00637913 8.0944 -0.00637913 8.00265 0.0191374L1.57193 1.80752C1.37128 1.8633 1.22286 2.03292 1.19413 2.23928C1.15683 2.50748 0.305934 8.8445 2.48843 11.997C4.66835 15.1457 7.8842 15.9526 8.02001 15.9854C8.06037 15.9952 8.10151 16 8.14278 16C8.18405 16 8.22518 15.9951 8.26555 15.9854C8.40143 15.9526 11.6173 15.1457 13.7971 11.997C15.9796 8.84457 15.1287 2.50755 15.0914 2.23928ZM12.2898 5.9389L7.90337 10.3253C7.8013 10.4274 7.66745 10.4785 7.53366 10.4785C7.39987 10.4785 7.26602 10.4274 7.16395 10.3253L4.45188 7.61323C4.35379 7.5152 4.29871 7.38218 4.29871 7.24352C4.29871 7.10485 4.35386 6.97183 4.45188 6.87381L4.99037 6.33531C5.19458 6.13118 5.52566 6.13111 5.7298 6.33531L7.53366 8.13917L11.0119 4.66091C11.1099 4.56282 11.2429 4.50774 11.3816 4.50774C11.5202 4.50774 11.6533 4.56282 11.7513 4.66091L12.2898 5.19941C12.494 5.40361 12.494 5.7347 12.2898 5.9389Z"
          fill="url(#paint0_linear_1273_263295)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1273_263295"
            x1="1"
            y1="0"
            x2="1"
            y2="16"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
