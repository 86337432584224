import Screenshot from 'assets/screenshots/UserManagement.png'
import { ChevronRight } from 'icons/outline'

function UserManagement() {
  return (
    <div className="p-4 grow">
      <div className="text-xl font-medium mb-4">User Management</div>
      <img src={Screenshot} alt="screenshot" className="w-full h-[25.625rem]" />
      <div className="mt-12">
        <div className="text-[0.8125rem] mb-3.5">
          User Management is supported in all paid plans. This feature allows
          creation of separate user accounts for anyone using the system.
          Coupled with the Activity Log, User Management makes it easy to audit
          changes made to the account - who made the changes and when.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          Decide whether to assign a new user the role of “Standard User” or
          “Admin.” An admin user has full access to billing settings and can
          delete devices.
        </div>
        <div className="text-[0.8125rem] mb-3.5">
          User Management also provides transparency around 2FA (Two Factor
          Authentication), i.e. who is using it and who is not.
        </div>
      </div>
      <div className="mt-12">
        <div className="font-medium text-base mb-4">Get Support</div>
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href=" https://broadflow.co/support/user-management/crud"
          target="_blank"
          rel="noreferrer"
        >
          How to Add a User
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href=" https://broadflow.co/support/user-management/security"
          target="_blank"
          rel="noreferrer"
        >
          How to Enable 2FA
          <ChevronRight />
        </a>
        <div className="my-3 border-b border-light-stroke border-dashed" />
        <a
          className="flex items-center justify-between text-[0.8125rem] gap-4"
          href="https://broadflow.co/support/user-management/activity-log-audit"
          target="_blank"
          rel="noreferrer"
        >
          Auditing the Activity Log to Blame Properly
          <ChevronRight />
        </a>
      </div>
    </div>
  )
}

export default UserManagement
