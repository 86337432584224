import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function CheckedCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 7C0 3.1402 3.1402 0 7 0C10.8598 0 14 3.1402 14 7C14 10.8598 10.8598 14 7 14C3.1402 14 0 10.8598 0 7ZM1.27273 7C1.27273 10.158 3.84194 12.7273 7 12.7273C10.158 12.7273 12.7273 10.158 12.7273 7C12.7273 3.84198 10.1581 1.27273 7 1.27273C3.84194 1.27273 1.27273 3.84198 1.27273 7Z"
          fill="currentColor"
        />
        <path
          d="M9.6249 4.52515L6.02503 8.12497L4.37515 6.47505C4.12668 6.22657 3.72373 6.22653 3.47521 6.47501C3.22669 6.72353 3.22669 7.12643 3.47521 7.37495L5.57504 9.47487C5.69438 9.59421 5.85623 9.66128 6.02499 9.66128C6.02503 9.66128 6.02499 9.66128 6.02503 9.66128C6.1938 9.66128 6.35565 9.59421 6.47499 9.47491L10.5248 5.42513C10.7734 5.17661 10.7734 4.77371 10.5248 4.52519C10.2763 4.27667 9.87342 4.27662 9.6249 4.52515Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
