import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useAsync, usePageTitle } from 'hooks'
import { Button, Spinner } from 'components'
import { EmailVerificationLink } from 'shared/EmailVerificationLink'
import { verifyRecipientEmail } from 'services/recipients'

type RouteParams = {
  id: string
  hash: string
}

function VerifyRecipientEmail() {
  usePageTitle(
    'Verify Recipient Email | Broadflow Streams Cloud Video Surveillance'
  )

  const { id, hash } = useParams<RouteParams>()
  const { search } = useLocation()
  const navigate = useNavigate()

  const { execute, isLoading, isError } = useAsync({
    status: 'pending'
  })

  useEffect(() => {
    execute(verifyRecipientEmail({ id: id!, hash: hash + search }))
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    return <EmailVerificationLink.Expired />
  }

  return (
    <EmailVerificationLink.Success
      title="Your email address has been successfully verified."
      description="You may now receive device notifications."
      action={
        <Button
          variant="primary"
          size="large"
          className="w-[16.875rem]"
          onClick={() => navigate('/')}
        >
          Back to Dashboard
        </Button>
      }
    />
  )
}

export default VerifyRecipientEmail
