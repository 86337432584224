import { usePageTitle } from 'hooks'
import RouteTabs from 'shared/RouteTabs'

import MyVault from './MyVault'
import VideoSearch from './VideoSearch'

const tabs = [
  {
    key: 'video-search',
    tab: 'Video Search',
    Component: VideoSearch
  },
  {
    key: 'my-vault',
    tab: 'My Vault',
    Component: MyVault
  }
]

function CloudStorage() {
  usePageTitle('Cloud Storage & Video Search | Broadflow Streams')

  return (
    <div className="overflow-hidden">
      <div className="text-2xl font-medium p-4 pt-6">Cloud Storage</div>
      <RouteTabs destroyInactiveTabPane tabs={tabs} className="mt-2" />
    </div>
  )
}

export default CloudStorage
