import { cloneElement, FC, ReactElement, ReactNode } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import cx from 'classnames'

interface Props {
  children?: ReactNode
  className?: string
  type?: 'primary' | 'danger' | 'info' | 'success'
  offset?: { x?: string | number; y?: string | number }
  count?: number
  dot?: boolean
}

const variant = (offset: { x: string | number; y: string | number }) => ({
  initial: { x: offset.x, y: offset.y, scale: 0 },
  animate: { x: offset.x, y: offset.y, scale: 1 },
  exit: { x: offset.x, y: offset.y, scale: 0 }
})

export const Badge: FC<Props> = ({
  children,
  className,
  type = 'danger',
  offset: propOffset,
  count,
  dot
}) => {
  const offset = {
    x: propOffset?.x || '50%',
    y: propOffset?.y || '-50%'
  }

  return (
    <div
      className={cx(
        'inline-block pointer-events-none m-0 p-0 box-border relative leading-none',
        className
      )}
    >
      {cloneElement(children as ReactElement, {
        className: cx(
          (children as ReactElement).props.className,
          'pointer-events-auto'
        )
      })}
      <AnimatePresence initial={false}>
        {!!dot && (
          <motion.sup
            variants={variant(offset)}
            initial="initial"
            animate="animate"
            exit="exit"
            className={cx(
              'w-2.5 h-2.5 rounded-full absolute top-0 right-0',
              `bg-${type}-gradient`
            )}
          />
        )}
        {!!count && (
          <motion.sup
            variants={variant(offset)}
            initial="initial"
            animate="animate"
            exit="exit"
            className={cx(
              `bg-${type}-gradient`,
              'min-w-[1.25rem] h-5 text-xs leading-5 whitespace-nowrap rounded-[0.625rem] px-[5px]',
              'absolute top-0 right-0 text-white text-center'
            )}
          >
            <span>{count > 9 ? '9+' : count}</span>
          </motion.sup>
        )}
      </AnimatePresence>
    </div>
  )
}
