import { useEffect } from 'react'
import { AxiosResponse } from 'axios'

import { ResourceIcon } from 'icons/utils'
import { useAsync } from 'hooks'
import { Spinner } from 'components'
import { SelectButtonItem } from 'components/SelectButton/SelectButtonItem'
import { channelMap } from 'utils/channel'
import { formatPhone } from 'utils/functions'
import { getTagDetail, NotifChannel, Tag } from 'services/tags'

interface Props {
  tag: Tag
}

function Recipients({ tag }: Props) {
  const tagAsync = useAsync<AxiosResponse<{ data: Tag }>>({
    showNotifOnError: true
  })

  useEffect(() => {
    if (!tag.is_blocked) {
      tagAsync.execute(
        getTagDetail(
          tag.id,
          'emailRecipients,phoneRecipients,whatsappRecipients'
        )
      )
    }
  }, [])

  const renderNotifChannels = () => {
    return Object.keys(channelMap).map(key => {
      const { label, iconType } = channelMap[key as NotifChannel]
      return (
        <SelectButtonItem
          key={key}
          value={key}
          active={tag.channels.includes(key as NotifChannel)}
          className="px-2"
          disabled
        >
          <ResourceIcon
            type={iconType}
            className="w-4 h-4"
            iconType="primary"
          />
          <div className="font-medium">{label}</div>
        </SelectButtonItem>
      )
    })
  }

  if (!!tag.is_blocked) {
    return (
      <div className="pt-4 flex items-center gap-1">
        All notifications matching this tag will NOT be forwarded
      </div>
    )
  }

  if (tagAsync.isLoading) {
    return <Spinner className="h-40" size="small" />
  }

  const data = tagAsync.data?.data.data

  return (
    <div className="pt-4">
      <div className="flex items-center gap-2 mb-3">
        <div className="text-[0.9375rem] font-semibold uppercase">
          Notification Channel
        </div>
      </div>
      <div className="flex flex-wrap items-center gap-2">
        {renderNotifChannels()}
      </div>
      <div className="border-b border-dashed border-light-stroke my-6" />
      <div className="flex items-center gap-2 mb-3">
        <div className="text-[0.9375rem] font-semibold uppercase">
          Recipients
        </div>
      </div>

      {!!data?.phone_recipients?.length && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="sms"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">SMS Recipient</div>
          </div>
          <div className="w-full inline-flex flex-wrap gap-2">
            {data.phone_recipients.map(({ id, phone }) => (
              <div
                key={id}
                className="bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg"
              >
                {formatPhone(phone)}
              </div>
            ))}
          </div>
        </div>
      )}

      {!!data?.whatsapp_recipients?.length && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="whatsapp"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">WhatsApp Recipient</div>
          </div>
          <div className="w-full inline-flex flex-wrap gap-2">
            {data.whatsapp_recipients.map(({ id, phone }) => (
              <div
                key={id}
                className="bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg"
              >
                {formatPhone(phone)}
              </div>
            ))}
          </div>
        </div>
      )}

      {!!data?.email_recipients?.length && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="email"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">Email Recipient</div>
          </div>
          <div className="w-full inline-flex flex-wrap gap-2">
            {data.email_recipients.map(({ id, email }) => (
              <div
                key={id}
                className="bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg"
              >
                {email}
              </div>
            ))}
          </div>
        </div>
      )}

      {!!data?.slack_webhook && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="slack"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">Slack Webhook</div>
          </div>
          <div className="w-full bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg">
            {data.slack_webhook}
          </div>
        </div>
      )}

      {!!data?.webhook && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="webhook"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">Webhook</div>
          </div>
          <div className="w-full bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg">
            {data.webhook}
          </div>
        </div>
      )}

      {!!data?.teams_webbhook && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="ms-teams"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">Microsoft Team Webhook</div>
          </div>
          <div className="w-full bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg">
            {data.teams_webbhook}
          </div>
        </div>
      )}

      {!!data?.pagerduty_integration_key && (
        <div className="flex gap-4 mb-6">
          <div className="w-[13.75rem] shrink-0 inline-flex self-start items-center gap-2 pt-2">
            <ResourceIcon
              type="pager-duty"
              className="w-4 h-4 shrink-0"
              iconType="primary"
            />
            <div className="font-medium">PagerDuty</div>
          </div>
          <div className="w-full bg-[#efefef] text-[0.8125rem] px-3 py-2 rounded-lg">
            {data.pagerduty_integration_key}
          </div>
        </div>
      )}
    </div>
  )
}

export default Recipients
