import RcPagination, { PaginationProps } from 'rc-pagination'
// @ts-ignore
import EN_US from 'rc-pagination/lib/locale/en_US'

import { ChevronLeft, ChevronRight, More } from 'icons/outline'
import { Button } from 'components'

import { ButtonGroup } from './ButtonGroup'

interface Props
  extends Omit<
    PaginationProps,
    'prevIcon' | 'nextIcon' | 'jumpPrevIcon' | 'jumpNextIcon'
  > {}

function Pagination(props: Props) {
  return (
    <RcPagination
      {...props}
      locale={EN_US}
      prevIcon={
        <Button>
          <ChevronLeft />
        </Button>
      }
      nextIcon={
        <Button>
          <ChevronRight />
        </Button>
      }
      jumpPrevIcon={<More />}
      jumpNextIcon={<More />}
    />
  )
}

Pagination.ButtonGroup = ButtonGroup
export { Pagination }
