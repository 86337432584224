import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function DragDrop(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <g opacity="0.5">
          <path d="M65 25V30H75V75H30V65H25V80H80V25H65Z" fill="currentColor" />
          <path d="M55 0H50V5H55V0Z" fill="currentColor" />
          <path d="M15 0H10V5H15V0Z" fill="currentColor" />
          <path d="M25 0H20V5H25V0Z" fill="currentColor" />
          <path d="M15 50H10V55H15V50Z" fill="currentColor" />
          <path d="M35 0H30V5H35V0Z" fill="currentColor" />
          <path d="M45 0H40V5H45V0Z" fill="currentColor" />
          <path d="M5 0H0V5H5V0Z" fill="currentColor" />
          <path d="M5 30H0V35H5V30Z" fill="currentColor" />
          <path d="M5 40H0V45H5V40Z" fill="currentColor" />
          <path d="M5 20H0V25H5V20Z" fill="currentColor" />
          <path d="M5 10H0V15H5V10Z" fill="currentColor" />
          <path d="M55 10H50V15H55V10Z" fill="currentColor" />
          <path d="M5 50H0V55H5V50Z" fill="currentColor" />
          <path
            d="M39.7328 60.5151L46.2128 67.0001L69.1953 44.0175L62.5403 37.365L59.0003 26.75L46.8028 14.5525L22.1453 24.7075C18.3145 26.2833 16.4865 30.6661 18.0623 34.4967C18.1148 34.6244 18.1707 34.7505 18.2303 34.875L17.9303 35.1775C14.0249 39.0827 14.0248 45.4143 17.9299 49.3197C17.9301 49.3199 17.9303 49.32 17.9303 49.32L27.3553 58.7476L39.7328 60.5151ZM20.0003 42.25C19.9967 40.9233 20.5242 39.6503 21.4653 38.715L30.3053 47.555L33.8403 44.0175L23.2328 33.41C22.256 32.4341 22.2556 30.8511 23.2315 29.8745C23.4649 29.641 23.7424 29.4558 24.0478 29.33L45.6253 20.4475L54.6253 29.4475L58.1603 40.055L62.1153 44.0075L46.2028 59.9201L42.0778 55.7951L29.7053 54.0275L21.4553 45.7775C20.519 44.8429 19.9953 43.5728 20.0003 42.25Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
