import client from './client'

export interface Account {
  access: {
    can_access_vault: boolean
    can_see_counters: boolean
    can_use_activity_log: boolean
    can_use_brand_logo: boolean
    can_use_third_party_integrations: boolean
    can_use_user_mgmt: boolean
    is_on_free_plan: boolean
    is_on_trial: boolean
    is_subscribed: boolean
  }
  beta_features: boolean
  brand_logo: string | null
  brand_logo_url: string | null
  created_at: string
  email: string
  enabled_notifications: number
  id: number
  is_active: number
  is_super: boolean
  name: string
  owner_id: number
  updated_at: string
  use_custom_smtp: number
}

export const uploadBrandLogo = (id: number, img: File) => {
  const formData = new FormData()
  formData.append('brand_logo', img, img.name)
  formData.append('_method', 'PUT')
  return client.post<{ data: Account }>(`/api/accounts/${id}`, formData)
}

export const changeSMTPServer = (id: number, payload: number) => {
  return client.put<{ data: Account }>(`/api/accounts/${id}`, {
    use_custom_smtp: payload
  })
}

export const updateDeviceNotif = (id: number, payload: number) => {
  return client.put(`/api/accounts/${id}`, { enabled_notifications: payload })
}
