import { ChangeEventHandler, useState } from 'react'
import { AxiosResponse } from 'axios'

import { Attachment, Close } from 'icons/outline'
import { useAsync } from 'hooks'
import { Button, Form, FormField, Input, Modal, ModalProps } from 'components'
import toast from 'utils/toast'
import { required } from 'utils/rules'
import { createFloorPlan, FloorPlan } from 'services/floorPlans'

interface Props extends ModalProps {
  locationId: number
  onSuccess?: (floorPlan: FloorPlan) => void
}

function ModalCreateFloorPlan({ locationId, onSuccess, ...props }: Props) {
  const [form] = Form.useForm()
  const [name, setName] = useState('')
  const [imgFile, setImgFile] = useState<File>()

  const { isLoading, execute } = useAsync<AxiosResponse<{ data: FloorPlan }>>({
    showNotifOnError: true
  })

  const handleClose = () => {
    !isLoading && props.onClose()
  }

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value
    setName(prev => {
      if (value.length > 30) {
        form.setFieldsValue({ name: prev })
        return prev
      }
      form.setFieldsValue({ name: value })
      return value
    })
  }

  const handleImgChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { files } = e.target
    if (!files || !files.length) {
      return
    }
    setImgFile(files[0])
  }

  const handleRemoveImage = () => {
    setImgFile(undefined)
    form.setFields([{ name: 'image', value: undefined }])
  }

  const handleSubmit = async () => {
    if (!imgFile || isLoading) return
    const response = await execute(
      createFloorPlan({ location_id: locationId, name, image: imgFile })
    )
    toast.success({ title: `"${name}" created` }, { position: 'bottom-center' })
    onSuccess?.(response.data.data)
    handleClose()
  }

  return (
    <Modal className="w-[33.125rem] !p-0" {...props} onClose={handleClose}>
      <div className="p-4 border-b border-light-stroke flex items-center justify-between">
        <div className="text-base font-medium">Create New Floor Plan</div>
        <Close className="shrink-0 cursor-pointer" onClick={handleClose} />
      </div>
      <Form form={form} className="p-4" onFinish={handleSubmit}>
        <FormField
          name="name"
          label="Floor Plan Name"
          rules={[required]}
          requiredMask
        >
          <Input
            onChange={handleNameChange}
            placeholder="Name your floor plan"
            extra={
              <div className="text-right text-[0.8125rem] text-light-secondary">
                {30 - name.length}
              </div>
            }
          />
        </FormField>
        <FormField
          name="image"
          label="Upload Floor Plan"
          rules={[required]}
          requiredMask
        >
          <div className="w-full h-[12.5rem] border border-dashed border-light-stroke rounded-lg bg-light-bg">
            {!imgFile && (
              <div className="relative w-full h-full flex items-center justify-center">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImgChange}
                  className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
                <div className="text-[0.8125rem] text-light-secondary">
                  Click or drag & drop your floor plan here
                </div>
              </div>
            )}
            {!!imgFile && (
              <div className="relative w-full h-full flex flex-col items-center justify-center p-4">
                <div
                  className="text-[0.8125rem] inline-flex items-center justify-center gap-2"
                  style={{ wordBreak: 'break-word' }}
                >
                  <Attachment className="shrink-0 w-3.5 h-3.5 text-light-secondary" />
                  <div className="font-medium">{imgFile.name}</div>
                </div>
                <Button
                  type="button"
                  className="mt-3 w-[7.5rem]"
                  variant="secondary-lighten"
                  onClick={handleRemoveImage}
                >
                  Remove
                </Button>
              </div>
            )}
          </div>
        </FormField>
        <div className="flex items-center justify-between">
          <Button className="w-20" disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={isLoading}
            className="w-[7.5rem]"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalCreateFloorPlan
