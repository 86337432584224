import { useEffect } from 'react'
import shallow from 'zustand/shallow'

import { Spinner } from 'components'
import { ADD_ON, PLAN_TYPE } from 'utils/plan'
import useStore, { Store } from 'store'

import PlanList from './PlanList'
import Features from './Features'
import PlanListCard from './PlanListCard'

const mapState = ({ plan }: Store) => ({
  loading: plan.loading,
  plans: plan.plans,
  getPlans: plan.getPlans
})

function Plans() {
  const { plans, loading, getPlans } = useStore(mapState, shallow)

  useEffect(() => {
    if (!plans.length && !loading) getPlans()
  }, [])

  if (loading) {
    return <Spinner className="h-screen" />
  }

  const free = plans.find(p => p.name === PLAN_TYPE.FREE)
  const basic = plans.find(p => p.name === PLAN_TYPE.BASIC)
  const plus = plans.find(p => p.name === PLAN_TYPE.PLUS)
  const pro = plans.find(p => p.name === PLAN_TYPE.PRO)
  const enterprise = plans.find(p => p.name === PLAN_TYPE.ENTERPRISE)
  const addOn = plans.find(p => p.name === ADD_ON.CAMERA_LICENSE)

  if (!free || !basic || !plus || !pro || !enterprise || !addOn) {
    return null
  }

  return (
    <div className="p-6">
      <div className="text-[2rem] font-medium text-center mb-9 mt-10">
        Update Subscription Plan
      </div>
      <PlanListCard free={free} basic={basic} plus={plus} pro={pro} />
      <div className="text-[2rem] font-medium text-center mb-14">
        Compare features
      </div>
      <PlanList free={free} basic={basic} plus={plus} pro={pro} addOn={addOn} />
      <Features
        free={free}
        basic={basic}
        plus={plus}
        pro={pro}
        enterprise={enterprise}
      />
    </div>
  )
}

export default Plans
