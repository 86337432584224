import { GetState, SetState } from 'zustand'

import { CurrentUser } from 'services/auth'
import type { Store } from 'store'

export interface AuthSlice {
  currentUser: CurrentUser | null
  accessBetaFeatures: () => boolean
  setCurrentUser: (user: CurrentUser) => void
  updateUser: (user: Partial<CurrentUser>) => void
  updateBrandLogo: (payload: { logo: string; logoUrl: string }) => void
  updateUseCustomSMTP: (payload: number) => void
}

const slice = (set: SetState<Store>, get: GetState<Store>) => ({
  currentUser: null,
  accessBetaFeatures: () => {
    return !!get().auth.currentUser?.account.beta_features
  },
  setCurrentUser: (user: CurrentUser) => {
    return set(({ auth }) => ({ auth: { ...auth, currentUser: user } }))
  },
  updateUser: (user: Partial<CurrentUser>) => {
    return set(({ auth }) => ({
      auth: {
        ...auth,
        currentUser: { ...(auth.currentUser || {}), ...user } as CurrentUser
      }
    }))
  },
  updateBrandLogo: ({ logo, logoUrl }: { logo: string; logoUrl: string }) => {
    return set(({ auth }) => ({
      auth: {
        ...auth,
        currentUser: {
          ...(auth.currentUser || {}),
          account: {
            ...auth.currentUser?.account,
            brand_logo: logo,
            brand_logo_url: logoUrl
          }
        } as CurrentUser
      }
    }))
  },
  updateUseCustomSMTP: (payload: number) => {
    return set(({ auth }) => ({
      auth: {
        ...auth,
        currentUser: {
          ...(auth.currentUser || {}),
          account: { ...auth.currentUser?.account, use_custom_smtp: payload }
        } as CurrentUser
      }
    }))
  }
})

export default slice
