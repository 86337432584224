import { SVGProps } from 'react'
import SVGUniqueID from 'react-svg-unique-id'

import { GRADIENTS } from 'configs/iconColors'

type Props = {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
} & Omit<SVGProps<SVGSVGElement>, 'color'>

export function Bell({ color, type, ...props }: Props) {
  const c = color || GRADIENTS[type || 'secondary']
  const fill = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M13.2528 11.2753C12.2448 10.4233 11.6668 9.178 11.6668 7.85867V6C11.6668 3.654 9.92417 1.712 7.66683 1.38667V0.666667C7.66683 0.298 7.36817 0 7.00016 0C6.63216 0 6.3335 0.298 6.3335 0.666667V1.38667C4.0755 1.712 2.3335 3.654 2.3335 6V7.85867C2.3335 9.178 1.7555 10.4233 0.741496 11.2807C0.482163 11.5027 0.333496 11.8253 0.333496 12.1667C0.333496 12.81 0.85683 13.3333 1.50016 13.3333H12.5002C13.1435 13.3333 13.6668 12.81 13.6668 12.1667C13.6668 11.8253 13.5182 11.5027 13.2528 11.2753Z"
          fill="url(#paint0_linear_8392_3122)"
        />
        <path
          d="M7.00011 16C8.20745 16 9.21745 15.1393 9.44945 14H4.55078C4.78278 15.1393 5.79278 16 7.00011 16Z"
          fill="url(#paint1_linear_8392_3122)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8392_3122"
            x1="0.333496"
            y1="0"
            x2="0.333496"
            y2="13.3333"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8392_3122"
            x1="4.55078"
            y1="14"
            x2="4.55078"
            y2="16"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={fill[0]} />
            <stop offset="1" stopColor={fill[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
