import { useEffect, useReducer } from 'react'
import { format } from 'date-fns'
import { AxiosResponse } from 'axios'

import { ActivityLogIcon } from 'icons/utils'
import { useAsync } from 'hooks'
import { Table } from 'components'
import TableFooter, { PageSizes } from 'shared/TableFooter'
import { dateTimeFormat } from 'utils/dateTime'
import {
  ActivityLog as IActivityLog,
  ActivityLogResponse,
  getDeviceLogs
} from 'services/activityLogs'

import TableToolbar from './TableToolbar'

interface Props {
  deviceId: number
}

interface State {
  sortBy: string
  sortDirection: string
  search: string
  dateRange: [Date | null, Date | null]
  currentPage: number
  pageSize: PageSizes
}

const initState: State = {
  sortBy: 'id',
  sortDirection: 'desc',
  search: '',
  dateRange: [null, null],
  currentPage: 1,
  pageSize: 10
}

function ActivityLog({ deviceId }: Props) {
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const logAsync = useAsync<AxiosResponse<ActivityLogResponse>>({
    status: 'pending',
    showNotifOnError: true
  })

  useEffect(() => {
    logAsync.execute(getDeviceLogs(deviceId, state))
  }, [...Object.values(state)])

  const handleSort = (key: string, direction: string) => {
    const dir = direction.replace('ending', '')
    setState({ sortBy: !dir ? 'id' : key, sortDirection: dir || 'desc' })
  }

  const renderCreatedAtCol = ({ created_at }: IActivityLog) => {
    return format(
      new Date(created_at),
      `${dateTimeFormat['MM-dd-yyyy']} ${dateTimeFormat['hh:mm a']}`
    )
  }

  const RenderActivityCol = ({ description }: IActivityLog) => (
    <div className="flex items-center gap-2 capitalize">
      <ActivityLogIcon
        log={description}
        className="w-4 h-4 text-light-secondary shrink-0"
      />
      <span>{description}</span>
    </div>
  )

  const columns = [
    {
      key: 'created_at',
      title: 'happened at',
      sorter: true,
      render: renderCreatedAtCol
    },
    {
      title: 'Username',
      render: (row: IActivityLog) => row.causer_name || '---'
    },
    {
      title: 'ip address',
      render: (row: IActivityLog) => row.causer_ip || '---'
    },
    {
      title: 'activity',
      render: RenderActivityCol
    }
  ]

  return (
    <div className="p-4">
      <TableToolbar
        loading={logAsync.isLoading}
        search={state.search}
        onSearch={v => setState({ search: v, currentPage: 1 })}
        dateRange={state.dateRange}
        onDateChange={range => setState({ dateRange: range })}
        currentPage={state.currentPage}
        totalPage={logAsync.data?.data.meta.last_page || 1}
        onPageChange={p => setState({ currentPage: p })}
      />
      <Table
        rowKey="id"
        columns={columns}
        scroll={{ x: 1400 }}
        loading={logAsync.isLoading}
        data={logAsync.data?.data.data || []}
        onSort={handleSort}
      />
      <TableFooter
        className="mt-4"
        loading={logAsync.isLoading}
        pageSize={state.pageSize}
        currentPage={state.currentPage}
        totalItem={logAsync.data?.data.meta.total || 1}
        onPageChange={p => setState({ currentPage: p })}
        onPageSizeChange={s => setState({ pageSize: s, currentPage: 1 })}
      />
    </div>
  )
}

export default ActivityLog
