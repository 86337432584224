import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { ChevronRight } from 'icons/outline'
import { Button, Form, FormField, Input } from 'components'
import ResourceSummaryAlt from 'shared/Cards/ResourceSummaryAlt'
import ScheduleDateTimeInput from 'shared/ScheduleDateTimeInput'
import ChannelRecipientForm from 'shared/Forms/ChannelRecipientForm'
import { required } from 'utils/rules'
import toast from 'utils/toast'
import useStore from 'store'

import { State, useFormCtx } from './FormContext'

interface Props {
  loading: boolean
  onSubmit: (key: 'schedule', values: State['schedule']) => void
}

function NotifSchedule({ loading, onSubmit }: Props) {
  const stats = useStore(state => state.stat.stats, shallow)
  const { email, sms, whatsapp } = stats || {}

  const [form] = Form.useForm()
  const { isFinalStep, onStepChange, schedule, dispatch } = useFormCtx()

  const [dateTime, setDateTime] = useState({
    is247: schedule.is_24_7,
    startTime: schedule.start_time,
    endTime: schedule.end_time,
    days: schedule.days
  })

  const [recs, setRecs] = useState({
    channels: schedule.channels,
    webhook: schedule.webhook,
    slack_webhook: schedule.slack_webhook,
    teams_webbhook: schedule.teams_webbhook,
    pagerduty_integration_key: schedule.pagerduty_integration_key,
    email_recipients: schedule.email_recipients,
    phone_recipients: schedule.phone_recipients,
    whatsapp_recipients: schedule.whatsapp_recipients
  })

  const handleSubmit = ({ name }: { name: string }) => {
    if (loading) return

    const { is247, startTime, endTime, days } = dateTime
    if (!is247 && (!startTime || !endTime || !days.length)) {
      return form.setFields([
        {
          name: 'dateTime',
          errors: ['Please tell us when you can received notification']
        }
      ])
    }

    if (!recs.channels.length) {
      return toast.error({ title: 'Please select atleast 1 channel' })
    }

    const values = {
      name,
      is_24_7: is247,
      start_time: startTime,
      end_time: endTime,
      days,
      ...recs
    }

    dispatch({ schedule: values })
    if (!isFinalStep) return onStepChange(1)
    onSubmit('schedule', values)
  }

  return (
    <div className="min-h-[calc(100vh_-_3.75rem)] py-4 flex flex-col justify-center">
      <div className="w-[55rem] m-auto">
        <div className="text-base font-medium mb-1">
          Enter notification schedule detail
        </div>
        <div className="text-[0.8125rem] text-success">
          Number of notification you have used
        </div>
        <div className="flex items-center gap-1 mt-2">
          <ResourceSummaryAlt
            type="sms"
            title={<div className="text-light-secondary">SMS</div>}
            iconType="primary"
            iconClassName="!w-3.5 !h-3.5"
            className="!p-2 !text-[0.8125rem]"
            summary={`${Math.abs(sms?.used || 0)}/${sms?.total || 0}`}
          />
          <ResourceSummaryAlt
            type="email"
            title={<div className="text-light-secondary">Email</div>}
            iconType="primary"
            iconClassName="!w-3.5 !h-3.5"
            className="!p-2 !text-[0.8125rem]"
            summary={`${Math.abs(email?.used || 0)}/${email?.total || 0}`}
          />
          <ResourceSummaryAlt
            type="whatsapp"
            title={<div className="text-light-secondary">WhatsApp</div>}
            iconClassName="!w-3.5 !h-3.5"
            className="!p-2 !text-[0.8125rem]"
            summary={`${Math.abs(whatsapp?.used || 0)}/${whatsapp?.total || 0}`}
          />
          <Link
            to="/settings/billing/plans"
            className="ml-1 text-sm text-primary"
          >
            Upgrade plan
          </Link>
        </div>
        <div className="border-b border-light-stroke my-8" />
        <Form
          form={form}
          initialValues={{ name: schedule.name }}
          onFinish={handleSubmit}
        >
          <FormField
            name="name"
            label="Schedule name"
            rules={[required]}
            requiredMask
          >
            <Input
              size="large"
              placeholder="Name your schedule"
              className="w-[50%]"
            />
          </FormField>
          <FormField
            name="dateTime"
            label="We will send notifications during the following times"
            requiredMask
          >
            <ScheduleDateTimeInput {...dateTime} onChange={setDateTime} />
          </FormField>
          <div className="block font-medium text-[0.8125rem] mb-1.5">
            We will send notification through{' '}
            <span className="text-danger">*</span>
          </div>
          <ChannelRecipientForm
            values={recs}
            onChange={(channels, recs) => setRecs({ channels, ...recs })}
          />
          <Button
            variant="primary"
            size="large"
            className="mt-8 w-[10rem]"
            type="submit"
            disabled={loading}
          >
            {isFinalStep ? (
              'Save'
            ) : (
              <Fragment>
                Next <ChevronRight />
              </Fragment>
            )}
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default NotifSchedule
