import cx from 'classnames'

import {
  Fullscreen,
  FullscreenExit,
  Screenshot,
  SpeakerLouder,
  SpeakerMuted
} from 'icons/outline'
import { Live } from 'icons/filled'
import { useFullscreen } from 'hooks'
import { formatInTz } from 'utils/dateTime'
import { videoCapture } from 'utils/file'

function LiveIcon({ className }: { className?: string }) {
  return (
    <div
      className={cx(
        'absolute top-0 right-0 z-[2] px-2 py-1.5 bg-black/50 backdrop-blur-[20px] rounded-tr-lg',
        className
      )}
    >
      <Live type="danger" />
    </div>
  )
}

function CurrentDateTime({ className }: { className?: string }) {
  return (
    <div
      className={cx(
        'absolute bottom-0 left-0 z-[2] bg-black/50 backdrop-blur-[20px] text-white text-xs rounded-tr-lg px-2 py-1',
        className
      )}
    >
      {formatInTz(new Date(), 'America/New_York', 'MM/dd/yyyy HH:mm:ss zzz')}
    </div>
  )
}

interface ControlProps {
  className?: string
  muted: boolean
  onVolumeChange: () => void
  videoEl: HTMLVideoElement
  fullscreenEl: HTMLElement
}

function Controls({
  className,
  videoEl,
  muted,
  onVolumeChange,
  fullscreenEl
}: ControlProps) {
  const { isEnabled, isFullscreen, toggle } = useFullscreen({})

  return (
    <div
      className={cx(
        'absolute bottom-2 right-2 z-[2] bg-black/50 backdrop-blur-[40px] flex items-center text-white gap-1 rounded-lg base-transition',
        className
      )}
    >
      <div
        title="Capture"
        className="w-[2.5rem] h-[2.5rem] inline-flex items-center justify-center cursor-pointer base-transition hover:bg-primary rounded-l-lg"
        onClick={() => videoCapture(videoEl)}
      >
        <Screenshot className="w-4 h-4 shrink-0" />
      </div>
      <div
        title={muted ? 'Unmute' : 'Mute'}
        className="w-[2.5rem] h-[2.5rem] inline-flex items-center justify-center cursor-pointer base-transition hover:bg-primary"
        onClick={onVolumeChange}
      >
        {muted && <SpeakerMuted className="w-4 h-4 shrink-0" />}
        {!muted && <SpeakerLouder className="w-4 h-4 shrink-0" />}
      </div>
      <div
        title={isFullscreen ? 'Exit full screen' : 'Full screen'}
        className={cx(
          'w-[2.5rem] h-[2.5rem] inline-flex items-center justify-center cursor-pointer base-transition hover:bg-primary rounded-r-lg',
          !isEnabled && 'pointer-events-none'
        )}
        onClick={() => toggle(fullscreenEl)}
      >
        {isFullscreen && <FullscreenExit className="w-4 h-4 shrink-0" />}
        {!isFullscreen && (
          <Fullscreen
            className={cx('w-4 h-4 shrink-0', !isEnabled && 'opacity-40')}
          />
        )}
      </div>
    </div>
  )
}

const LivestreamUI = {
  LiveIcon,
  CurrentDateTime,
  Controls
}

export default LivestreamUI
