import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import { WifiOff, WifiOn } from 'icons/outline'
import ResourceSummaryCard from 'shared/Cards/ResourceSummary'
import ModalViewCameraDevice from 'shared/Modals/Device/ViewCameraDevice'
import { getDeviceStatus, isCamera } from 'utils/device'
import { AccountStats } from 'services/accountStats'
import useStore, { Store } from 'store'

type ResourceKey = keyof AccountStats & string
type Resource = Partial<AccountStats['whatsapp']>

const mapState = ({ stat, device }: Store) => ({
  stats: stat.stats,
  devices: device.devices
})

function ResourceSummary() {
  const [viewDeviceStatus, setViewDeviceStatus] = useState('')
  const { stats, devices } = useStore(mapState, shallow)

  const renderCountableStats = (key: ResourceKey, suffix = '') => {
    const { total = 0, used = 0 } = (stats?.[key] as Resource) || {}
    return (
      <Fragment>
        {Math.abs(used)}/{total}
        {suffix}
      </Fragment>
    )
  }

  const renderUncountableStats = (key: ResourceKey) => {
    const enabled = stats?.[key]
    return !enabled ? (
      <Link to="/settings/billing/plans" className="text-primary">
        Upgrade to use
      </Link>
    ) : (
      'Unlimited'
    )
  }

  const cameraDevices = devices.filter(d => isCamera(d.type))
  const online = cameraDevices.filter(d => getDeviceStatus(d) === 'online')
  const offline = cameraDevices.length - online.length

  return (
    <div className="mb-10">
      <h4 className="text-base font-medium flex justify-between items-center">
        <span>Resource Summary</span>
      </h4>
      <div className="mt-4 grid md:grid-cols-2 grid-cols-1 gap-2">
        <ResourceSummaryCard
          type="device"
          title="Devices"
          summary={renderCountableStats('devices')}
          iconType="primary"
          className="border border-[#eeeeee]"
          action={
            <Link
              to="/devices?tab=device-list"
              className="text-primary font-medium"
            >
              Manage
            </Link>
          }
        >
          <div className="md:pl-10">
            <hr className="border-dashed my-3 border-light-stroke" />
            <div className="flex items-center gap-2 text-sm text-success">
              <WifiOn />
              <span>Streaming devices online</span>
              <span className="font-medium">{online.length}</span>
              {!!online.length && (
                <div
                  className="ml-auto text-primary font-medium cursor-pointer"
                  onClick={() => setViewDeviceStatus('online')}
                >
                  View
                </div>
              )}
            </div>
            <hr className="border-dashed my-3 border-light-stroke" />
            <div className="flex items-center gap-2 text-sm text-danger">
              <WifiOff />
              <span>Streaming devices offline</span>
              <span className="font-medium">{offline}</span>
              {!!offline && (
                <div
                  className="ml-auto text-primary font-medium cursor-pointer"
                  onClick={() => setViewDeviceStatus('offline')}
                >
                  View
                </div>
              )}
            </div>
          </div>
        </ResourceSummaryCard>
        <div>
          <ResourceSummaryCard
            type="cloud-storage"
            title="Cloud Storage"
            summary={renderCountableStats('cloud_storage', 'GB')}
            iconType="primary"
            className="mb-2 border border-[#eeeeee]"
            action={
              <Link
                to="/cloud-storage?tab=video-search"
                className="text-primary font-medium"
              >
                View Cloud Storage
              </Link>
            }
          />
          <ResourceSummaryCard
            type="vault-storage"
            title="Vault Storage"
            summary={renderCountableStats('vault', 'GB')}
            iconType="primary"
            className="border border-[#eeeeee]"
            action={
              <Link
                to="/cloud-storage?tab=my-vault"
                className="text-primary font-medium"
              >
                View Vault Storage
              </Link>
            }
          />
        </div>
      </div>
      <hr className="border-dashed my-3 border-light-stroke" />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        <ResourceSummaryCard
          type="camera-license"
          title="Camera Licenses"
          summary={renderCountableStats('camera_licenses')}
          iconType="primary"
          className="border border-[#eeeeee]"
        />
        <ResourceSummaryCard
          type="email"
          title="Email"
          summary={renderCountableStats('email')}
          iconType="primary"
          className="border border-[#eeeeee]"
        />
        <ResourceSummaryCard
          type="sms"
          title="SMS"
          summary={renderCountableStats('sms')}
          iconType="primary"
          className="border border-[#eeeeee]"
        />
        <ResourceSummaryCard
          type="whatsapp"
          title="WhatsApp"
          summary={renderCountableStats('whatsapp')}
          iconType="primary"
          className="border border-[#eeeeee]"
        />
        <ResourceSummaryCard
          type="webhook"
          title="Webhook"
          summary={renderUncountableStats('webhook_enabled')}
          iconType="primary"
          className="border border-[#eeeeee]"
        />
        <ResourceSummaryCard
          type="slack"
          title="Slack"
          summary={renderUncountableStats('slack_enabled')}
          iconType="primary"
          className="border border-[#eeeeee]"
        />
        <ResourceSummaryCard
          type="ms-teams"
          title="Microsoft Teams"
          summary={renderUncountableStats('teams_enabled')}
          iconType="primary"
          className="border border-[#eeeeee]"
        />
        <ResourceSummaryCard
          type="pager-duty"
          title="PagerDuty"
          summary={renderUncountableStats('pagerduty_enabled')}
          iconType="primary"
          className="border border-[#eeeeee]"
        />
      </div>
      <AnimatePresence>
        {!!viewDeviceStatus && (
          <ModalViewCameraDevice
            status={viewDeviceStatus as 'online' | 'offline'}
            onClose={() => setViewDeviceStatus('')}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default ResourceSummary
