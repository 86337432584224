import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function MicrosoftAuthenticator(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1_2124)">
          <path
            d="M15.0101 15.9808H0.989846C0.446643 15.9808 0.00958252 15.5406 0.00958252 15.0005V0.980264C0.00958252 0.43706 0.449765 0 0.989846 0H15.0101C15.5533 0 15.9904 0.440182 15.9904 0.980264V15.0005C15.9904 15.5437 15.5533 15.9808 15.0101 15.9808Z"
            fill="#0078D7"
          />
          <path
            d="M11.0017 4.99173C11.0017 5.03856 11.0017 5.08227 10.9985 5.12909C10.6364 5.05105 10.2587 4.98861 9.86844 4.93866C9.84034 3.93031 9.01617 3.12176 8.00156 3.12176C6.98696 3.12176 6.15967 3.93031 6.13469 4.93866C5.74446 4.98861 5.36671 5.05105 5.00458 5.12909C5.00146 5.08227 5.00146 5.03544 5.00146 4.98861C5.00146 3.33092 6.34386 1.98541 8.00469 1.98541C9.65615 1.99166 11.0017 3.33404 11.0017 4.99173ZM8.01405 13.9951C13.315 11.7661 12.5064 6.09062 12.5064 6.09062C11.2702 5.62859 9.70922 5.35386 8.00781 5.35386C6.30639 5.35386 4.74546 5.62859 3.50921 6.09062C3.50921 6.09062 2.70065 11.7661 8.00156 13.9951H8.01405Z"
            fill="white"
          />
          <path
            d="M9.47505 10.4209C9.47505 10.4864 9.47193 10.5489 9.46257 10.6113H6.54984C6.54048 10.5489 6.53735 10.4864 6.53735 10.4209C6.53735 9.75903 6.97442 9.20022 7.57694 9.01603C7.1711 8.84745 6.88388 8.44785 6.88388 7.97957C6.88388 7.35832 7.38651 6.85883 8.00464 6.85883C8.62278 6.85883 9.1254 7.36145 9.1254 7.97957C9.1254 8.44785 8.83819 8.84745 8.43234 9.01603C9.03799 9.1971 9.47505 9.75903 9.47505 10.4209Z"
            fill="#0078D7"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_2124">
            <rect width="16" height="15.9808" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
