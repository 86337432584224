import { SVGProps } from 'react'
import { SVGUniqueID } from 'react-svg-unique-id'

export function ArrowDown(props: SVGProps<SVGSVGElement>) {
  return (
    <SVGUniqueID>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth="0"
        stroke="currentColor"
        fill="currentColor"
        {...props}
      >
        <path
          d="M8.99984 1.33341L8.99984 14.6331L13.8505 9.54041C14.1045 9.27375 14.5265 9.26341 14.7932 9.51741C15.0595 9.77108 15.0702 10.1931 14.8162 10.4601L9.27617 16.2761C9.02417 16.5277 8.6895 16.6667 8.33317 16.6667C7.97717 16.6667 7.64217 16.5277 7.37917 16.2644L1.8505 10.4597C1.7275 10.3307 1.6665 10.1654 1.6665 10.0001C1.6665 9.82408 1.73584 9.64808 1.8735 9.51708C2.14017 9.26308 2.56184 9.27341 2.81584 9.54008L7.6665 14.6541L7.6665 1.33341C7.6665 0.965414 7.96517 0.666748 8.33317 0.666748C8.70117 0.666748 8.99984 0.965414 8.99984 1.33341Z"
          fill="currentColor"
        />
      </svg>
    </SVGUniqueID>
  )
}
