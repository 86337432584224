import { Fragment, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import shallow from 'zustand/shallow'

import ThankYou from 'assets/images/ThankYou.png'

import { InfoCircle } from 'icons/filled'
import { PlanIcon } from 'icons/utils'
import { useAsync } from 'hooks'
import { Tooltip } from 'components'
import Popular from 'shared/Cards/Popular'
import CheckoutForm, { FormValues } from 'shared/Forms/CheckoutForm'
import ModalCheckoutSuccess from 'shared/Modals/Billing/CheckoutSuccess'
import { PLAN_TYPE } from 'utils/plan'
import { Coupon, Plan, updateSubscription } from 'services/subscriptions'
import useStore, { Store } from 'store'

interface Props {
  plan: Plan
  count: number
  cameraLicensePrice: number
}

const mapState = ({ sub, stat, plan }: Store) => ({
  sub: sub.subscription,
  stats: stat.stats,
  plans: plan.plans
})

function CheckoutPlan({ plan, count, cameraLicensePrice }: Props) {
  const [visible, setVisible] = useState(false)
  const [coupon, setCoupon] = useState<Coupon>()

  const { sub, stats, plans } = useStore(mapState, shallow)
  const { execute, isLoading } = useAsync({ showNotifOnError: true })
  const totalCamLicense = stats?.camera_licenses.total || 0

  if (!sub || !sub.subscription || !plans?.length) {
    return null
  }

  const handleSubmit = async (values: FormValues) => {
    await execute(
      updateSubscription({
        plan_id: plan.id,
        camera_licenses: count,
        coupon: values.coupon || ''
      })
    )
    localStorage.removeItem('APP/SELECTED_PLAN')
    setVisible(true)
  }

  const updatePlanOnly = () => (
    <Fragment>
      <div className="flex items-center justify-between gap-1">
        <div className="text-[0.8125rem] text-light-secondary">Base Plan</div>
        <div className="text-[0.8125rem] text-light-secondary inline-flex">
          <span className="font-medium text-light-primary">
            ${plan.price / 100}
          </span>
          /month
          <Tooltip content="Your billing cycle is being reset. Existing notification quotas will carry over.">
            <InfoCircle type="success" className="w-4 h-4 shrink-0 ml-1" />
          </Tooltip>
        </div>
      </div>
      <div className="border-b border-light-stroke my-2.5" />
      <div className="flex items-center justify-between gap-1">
        <div className="text-[0.8125rem] text-light-secondary">Total</div>
        <div className="text-[0.8125rem] text-light-secondary">
          <span className="font-medium text-light-primary text-[1.3rem]">
            ${plan.price / 100}
          </span>
          /month
        </div>
      </div>
    </Fragment>
  )

  const updatePlanWithcount = () => (
    <Fragment>
      <div className="flex items-center justify-between gap-1">
        <div className="text-[0.8125rem] text-light-secondary">
          Camera Licenses
        </div>
        <div className="text-[0.8125rem] text-light-secondary">
          <span className="font-medium text-light-primary">
            {count} x ${cameraLicensePrice}
          </span>
          /month
        </div>
      </div>
      <div className="border-b border-dashed border-light-stroke my-2.5" />
      <div className="flex items-center justify-between gap-1">
        <div className="text-[0.8125rem] text-light-secondary">Base Plan</div>
        <div className="text-[0.8125rem] text-light-secondary">
          <span className="font-medium text-light-primary">
            ${plan.price / 100}
          </span>
          /month
        </div>
      </div>
      <div className="border-b border-dashed border-light-stroke my-2.5" />
      <div className="flex items-center justify-between gap-1">
        <div className="text-[0.8125rem] text-light-secondary">
          Monthly Total
        </div>
        <div className="text-[0.8125rem] text-light-secondary">
          <span className="font-medium text-light-primary">
            ${plan.price / 100 + count * cameraLicensePrice}
          </span>
          /month
        </div>
      </div>
      <div className="border-b border-dashed border-light-stroke my-2.5" />
      {renderDifference()}
    </Fragment>
  )

  const calcDiscount = (total: number) => {
    if (coupon && total > 0) {
      const { percent_off, amount_off } = coupon
      return percent_off
        ? Math.round(total * (percent_off / 100))
        : amount_off || 0
    }
    return 0
  }

  const getDifference = () => {
    const currentPlan = sub.subscription!.plan!
    if (currentPlan.id === 1) return 0
    if (currentPlan.id === plan.id) {
      return (count - totalCamLicense) * cameraLicensePrice
    }
    const planDiff = (plan.price - currentPlan.price) / 100
    const camDiff = (count - totalCamLicense) * cameraLicensePrice
    return planDiff + camDiff
  }

  const renderDifference = () => {
    const currentPlan = sub.subscription!.plan!
    const diff = getDifference()
    const discount = calcDiscount(diff)
    // not show diffirent on initial purchase
    if (currentPlan.id === 1) return null

    // CASE 1 & 2
    if (currentPlan.id === plan.id) {
      return (
        <Fragment>
          {coupon && (
            <Fragment>
              <div className="flex items-center justify-between gap-1">
                <div className="text-[0.8125rem] text-light-secondary">
                  Discount
                </div>
                <div className="text-[0.8125rem] text-light-secondary flex items-center">
                  <span className="font-medium text-light-primary mr-[0.375rem] ">
                    ${discount}
                  </span>
                  <Tooltip content={coupon?.message || ''} placement="right">
                    <InfoCircle
                      type="success"
                      className="w-4 h-4 hover:cursor-pointer"
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="border-b border-dashed border-light-stroke my-2.5" />
            </Fragment>
          )}
          <div className="flex items-center justify-between gap-1">
            <div className="text-[0.8125rem] text-light-secondary">
              Difference
            </div>
            <div className="text-[0.8125rem] text-light-secondary inline-flex">
              <span className="font-medium text-light-primary">
                {diff > -1
                  ? `+$${Math.abs(diff) - discount}`
                  : `-$${Math.abs(diff)}`}
              </span>
              <Tooltip
                content={
                  count > totalCamLicense
                    ? 'You will be charged a prorated amount today'
                    : 'You will be credited towards your next billing cycle on a prorated basis'
                }
              >
                <InfoCircle type="success" className="w-4 h-4 shrink-0 ml-1" />
              </Tooltip>
            </div>
          </div>
        </Fragment>
      )
    }

    // CASE 3, 4, 5 & 6
    return (
      <Fragment>
        {coupon && (
          <Fragment>
            <div className="flex items-center justify-between gap-1">
              <div className="text-[0.8125rem] text-light-secondary">
                Discount
              </div>
              <div className="text-[0.8125rem] text-light-secondary flex items-center">
                <span className="font-medium text-light-primary mr-[0.375rem] ">
                  ${discount}
                </span>
                <Tooltip content={coupon?.message || ''} placement="right">
                  <InfoCircle
                    type="success"
                    className="w-4 h-4 hover:cursor-pointer"
                  />
                </Tooltip>
              </div>
            </div>
            <div className="border-b border-dashed border-light-stroke my-2.5" />
          </Fragment>
        )}
        <div className="flex items-center justify-between gap-1">
          <div className="text-[0.8125rem] text-light-secondary">
            Difference
          </div>
          <div className="text-[0.8125rem] text-light-secondary inline-flex">
            <span className="font-medium text-light-primary">
              {diff > -1
                ? `+$${Math.abs(diff) - discount}`
                : `-$${Math.abs(diff)}`}
            </span>
            <Tooltip content="Your billing cycle will be reset and you will be credited on a prorated basis">
              <InfoCircle type="success" className="w-4 h-4 shrink-0 ml-1" />
            </Tooltip>
          </div>
        </div>
      </Fragment>
    )
  }

  const isUpdatePlanOnly =
    sub.subscription!.plan_id !== plan.id && count === totalCamLicense

  return (
    <Fragment>
      <div className="grid grid-cols-[27rem_1rem_auto]">
        <div className="bg-white p-6 rounded-lg border border-[#eee] shadow-[0px_1px_2px_rgba(0,0,0,0.16)] self-start">
          <div className="text-base font-medium mb-6">Summary</div>
          <div className="flex items-center gap-2 mb-6 bg-[#fafafa] p-4 rounded-lg gap-4">
            <PlanIcon type={plan.name} className="w-12 shrink-0" />
            <div className="inline-flex flex-col gap-1">
              <div className="inline-flex font-medium text-sm items-center gap-2">
                <div>{plan.label} Plan</div>
                {plan.name === PLAN_TYPE.PLUS && <Popular />}
              </div>
              <div className="text-[0.8125rem] text-light-secondary">
                Monthly Subscription
              </div>
            </div>
          </div>
          {isUpdatePlanOnly ? updatePlanOnly() : updatePlanWithcount()}
        </div>
        <div />
        <div className="bg-white p-6 rounded-lg border border-[#eee] shadow-[0px_1px_2px_rgba(0,0,0,0.16)]">
          <CheckoutForm
            disabled={isLoading}
            onCouponChange={setCoupon}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
      <img
        src={ThankYou}
        alt="ty"
        className="w-0 h-0 fixed bottom-0 right-0 z-[-9999] invisible pointer-events-none"
      />
      <AnimatePresence initial={false}>
        {visible && (
          <ModalCheckoutSuccess
            imageUrl={ThankYou}
            onClose={() => void 0}
            title="Your plan has been updated"
            description={
              isUpdatePlanOnly
                ? `You are now subscribed to the ${plan.label} plan.`
                : `You have updated to the ${plan.label} plan and ${
                    count - totalCamLicense > 0 ? 'added' : 'removed'
                  } ${Math.abs(count - totalCamLicense)} camera licenses.`
            }
          />
        )}
      </AnimatePresence>
    </Fragment>
  )
}

export default CheckoutPlan
